<div class="time-out text-center">
    <div>
        <h1><strong>Session</strong> Timeout</h1>
        <img src="../../assets/images/timed-out.jpg" alt="">
        <div class="loginBtn text-center">
            <mat-error class="msg">Your session has timed out and you must login to access your account.</mat-error>
            <div class="">
                <button mat-flat-button class="btn mat-accent" color="primary" (click)="login()">Login</button>
            </div>
        </div>
    </div>
</div>