import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
class DataTablesResponse {
    data: any[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
    Result: any[];
}
@Injectable()
export class DatatableService {
    tableSetting = {
        pagingType: 'full_numbers',
        retrieve: true,
        paging: true,
        bInfo: false,
        info: false,
        bLengthChange: true,
        pageLength: 10,
        lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, 'All']],
        order: [[0, 'asc']],
        searching: true,
        dom: 'Bfrt<"dataTables_footer"li<"pagination-custom"p>>',
        language: {
            lengthMenu: ' _MENU_ ',
            zeroRecords: 'No data available.',
            info: 'Showing _START_ to _END_ of _TOTAL_ ',
        },
        oLanguage: {
            info: 'Showing _START_ to _END_ of _TOTAL_',
            lengthMenu: ' _MENU_ ',
            sEmptyTable: 'No data available.',
            sInfo: 'Showing _START_ to _END_ of _TOTAL_',
            sLengthMenu: ' _MENU_ ',
            sLoadingRecords: 'No data available.',
            sZeroRecords: 'No data available.',
            zeroRecords: 'No data available.',
            sSearch: '', sSearchPlaceholder: 'Search by keyword',
        },
        buttons: [
            'colvis',
        ]
    };

    datatable = {
        pagination: {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            processing: true,
        },

        tableResponse: {
            data: [],
            draw: 0,
            recordsFiltered: 0,
            recordsTotal: 0,
            Result: []
        },
        language: {searchPlaceholder: 'Search...'},
        domSetting: {
            order: [[ 0, 'desc' ]],
            bLengthChange: true,
            dom: 'Bfrt<"dataTables_footer"li<"pagination-custom"p>>',
            buttons: ['colvis']
        },
        dataTables: null

    };

    tablePagination() {
        const currentToken = localStorage.getItem('token');
        const auth = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentToken
        };
        this.datatable.dataTables = auth;
        return this.datatable;
    }


    updateTableSetting(title: string, tblLength: number): void {
        const info = 'Showing _START_ to _END_ of _TOTAL_ ' + title;
        this.tableSetting.oLanguage.info = info;
        this.tableSetting.oLanguage.sInfo = info;

        if (tblLength <= 10) {
            this.tableSetting.paging = false;
             this.tableSetting.info = false;
            this.tableSetting.bInfo = false;
        } else {
            this.tableSetting.paging = true;
            this.tableSetting.bInfo = false;
        }
    }
}
