import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss']
})
export class CommonModalComponent implements OnInit {
  dialogAction: any;
  confirmMessage: any;
  statusText: any;
  getData: any;
  
  constructor(
    public dialogRef: MatDialogRef<CommonModalComponent>,
    public dialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,) {

    this.dialogAction = data.action;
    this.statusText = data.statusText;
    this.getData = data.getData;


  }

  ngOnInit(): void {
    // console.log(this.getData);
  }
}
