<div class="content apprem">
    <div class="page-heading padd_bo d-flex align-items-center justify-content-between">
        <div fxFlex fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
                <h1>Add Entitlements Codes</h1>
            </div>
        </div>
        <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <button class="back_row" mat-button routerLink="/app/core/common/settings/entitlements">
                <mat-icon class="back_arrow">keyboard_arrow_left</mat-icon> Back to List
            </button>
        </div>
    </div>
    <div class="content_body entitform">
        <div class="frmdtl epica-accordion">
            <form #f1="ngForm">
                <div class="row">
                    <div class="col-6">
                        <mat-form-field appearance="outline"
                            [class.mat-form-field-invalid]="f1.submitted && EntitlementCodeid.invalid">
                            <mat-label>Entitlements Codes</mat-label>
                            <mat-select class="form-control" [(ngModel)]="saveExpenseEntitlementInput.EntitlementCodeid"
                                name="EntitlementCodeid" #EntitlementCodeid="ngModel" required>
                                <fuse-mat-select-search name="search" [(ngModel)]="EntitlementCodes">
                                </fuse-mat-select-search>
                                <mat-option *ngFor="let d_list of EntitlementCode | filter: EntitlementCodes"
                                    [value]="d_list.EntitlementCodeid">
                                    {{d_list?.EntitlementCodeName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <mat-accordion>
                    <mat-expansion-panel 
                        *ngFor="let list of saveExpenseEntitlementInput.CityLevelTear;let i = index">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="tr-heading">
                                <p>
                                    <span>City Level</span> Tear {{i + 1}}
                                </p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <h3 style="font-weight: 700;" class="mb-2">Set Limits</h3>
                        <div *ngFor="let data of list.LimitDuration;let lastIndex = last; let inx = index">
                            <form #f3="ngForm" (ngSubmit)="f3.form.valid && addLimitDuration(list.LimitDuration)">
                                <div class="row">
                                    <div class="col-6">
                                        <mat-form-field appearance="outline"
                                            [class.mat-form-field-invalid]="(f1.submitted || f3.submitted) && LimitDurationName.invalid">
                                            <mat-label>Select</mat-label>
                                            <mat-select class="form-control" [compareWith]="compareFn"
                                                [(ngModel)]="data.LimitDurationName"
                                                name="LimitDurationName_{{i}}_{{inx}}" #LimitDurationName="ngModel"
                                                required>
                                                <mat-option *ngFor="let dur of typeOfDuration" [value]="dur.keyName">
                                                    {{dur.keyName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-6 d-flex pl-0">
                                        <button class="link-addmore" *ngIf="lastIndex">
                                            <mat-icon>add_circle</mat-icon>
                                        </button>
                                        <button class="link-addmore" *ngIf="list.LimitDuration.length > 1"
                                            (click)="removeLimitDuration(list.LimitDuration, inx)">
                                            <mat-icon>remove_circle</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div *ngFor="let childList of data.Expense; let end = last;  let inx1 = index">
                                    <form class="row" #f2="ngForm"
                                        (ngSubmit)="f2.form.valid && addCategory(data.Expense)">
                                        <div class="col-6">
                                            <mat-form-field appearance="outline"
                                                [class.mat-form-field-invalid]="(f1.submitted || f2.submitted || f3.submitted) && ExpenseCategoryName.invalid">
                                                <mat-label>Category</mat-label>
                                                <mat-select [(ngModel)]="childList.ExpenseCategoryName"
                                                    name="ExpenseCategoryName_{{i}}_{{inx}}_{{inx1}}" #ExpenseCategoryName="ngModel"
                                                    required>
                                                    <mat-option *ngFor="let list of ExpMonthlyCategoryDropDown"
                                                        [value]="list.Monthlylimitcategoryname">
                                                        {{list.Monthlylimitcategoryname}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-5">
                                            <mat-form-field appearance="outline"
                                                [class.mat-form-field-invalid]="(f2.submitted || f1.submitted || f3.submitted) && ExpenseCategoryAmount.invalid">
                                                <span matPrefix class="price-prefix">INR</span>
                                                <input matInput type="text" [(ngModel)]="childList.ExpenseCategoryAmount"
                                                    name="ExpenseCategoryAmount_{{i}}_{{inx}}_{{inx1}}"
                                                    class="form-control" #ExpenseCategoryAmount="ngModel" required>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-1 d-flex pl-0">
                                            <button class="link-addmore" *ngIf="end">
                                                <mat-icon>add_circle</mat-icon>
                                            </button>
                                            <button class="link-addmore" *ngIf="data.Expense.length > 1"
                                                (click)="removeCategory(childList, data.Expense, inx1)" type="button">
                                                <mat-icon>remove_circle</mat-icon>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <h3 style="font-weight: 700;" class="mb-2">Local Conveyance(Per/KM)</h3>
                                        <mat-form-field appearance="outline"
                                            [class.mat-form-field-invalid]="(f1.submitted || f3.submitted) && LocalConveyanceAmount.invalid">
                                            <span matPrefix class="price-prefix">INR</span>
                                            <input matInput type="text" [(ngModel)]="data.LocalConveyanceAmount"
                                                name="LocalConveyanceAmount_{{i}}_{{inx}}" class="form-control"
                                                #LocalConveyanceAmount="ngModel" required>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-6">
                                        <h3 style="font-weight: 700;" class="mb-2">Intercity Travels</h3>
                                        <mat-form-field appearance="outline"
                                            [class.mat-form-field-invalid]="(f1.submitted || f3.submitted) && IntercityTravelsAmount.invalid">
                                            <span matPrefix class="price-prefix">INR</span>
                                            <input matInput type="text" [(ngModel)]="data.IntercityTravelsAmount"
                                                name="IntercityTravelsAmount_{{i}}_{{inx}}" class="form-control"
                                                #IntercityTravelsAmount="ngModel" required>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-6">
                                        <h3 style="font-weight: 700;" class="mb-2">Accommodation Limit / Per Night</h3>
                                        <mat-form-field appearance="outline"
                                            [class.mat-form-field-invalid]="(f1.submitted || f3.submitted) && AccommodationLimitAmount.invalid">
                                            <span matPrefix class="price-prefix">INR</span>
                                            <input matInput type="text" [(ngModel)]="data.AccommodationLimitAmount"
                                                name="AccommodationLimitAmount_{{i}}_{{inx}}" class="form-control"
                                                #AccommodationLimitAmount="ngModel" required>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-6">
                                        <h3 style="font-weight: 700;" class="mb-2">Own Arrangement</h3>
                                        <mat-form-field appearance="outline"
                                            [class.mat-form-field-invalid]="(f1.submitted || f3.submitted) && OwnArrangementAmount.invalid">
                                            <span matPrefix class="price-prefix">INR</span>
                                            <input matInput type="text" [(ngModel)]="data.OwnArrangementAmount"
                                                name="OwnArrangementAmount_{{i}}_{{inx}}" class="form-control"
                                                #OwnArrangementAmount="ngModel" required>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <button *ngIf="!editShow" mat-flat-button color="primary" class="add-lead-btn btn-lg" type="submit"
                    (click)="entitlmentSave(f1.form, saveExpenseEntitlementInput)">
                    Submit
                </button>
                <button *ngIf="editShow" mat-flat-button color="primary" class="add-lead-btn btn-lg" type="submit"
                    (click)="entitlmentSave(f1.form, saveExpenseEntitlementInput)">
                    Update
                </button>
            </form>
        </div>
    </div>
</div>