<div class="taskdetail-dv">
    <h2>{{inventdata?.ItemName}}</h2>
    <div class="task_detail_box">
        <div class="row">
            <div class="col-md-6 dtl_text">
                <label class="mb-3">{{inventdata?.AssetItemName}}</label>
                <img [src]="ImagePath" alt="" style="    height: 300px;">
                <label class="mb-3 mt-5">Description</label>
                <p>{{inventdata?.ItemDescription}}</p>
            </div>
            <div class="col-md-6 dtl_text">
                <label class="mb-3">Specification</label>
                <table class="table table-striped table-bordered">
                    <tbody>
                        <tr *ngFor="let item of inventdata?.Attributes">
                            <td><strong>{{item?.AssetAttributeName}}:</strong></td>
                            <td>{{item?.AssetAttributeValue}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
