import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ExpenseComponent } from './expense/expense.component';
import { DisposeApprovalComponent } from './dispose-approval/dispose-approval.component';
import { PlanworkSharedModule } from '@planwork/planwork.shared.module';
import { ConsumableRequisitionsComponent } from './consumable-requisitions/consumable-requisitions.component';

// import { ConsumableComponent } from './consumable/consumable.component';
import { AssetApprovalComponent } from './asset-approval/asset-approval.component';
import { PettyCashApprovalComponent } from './petty-cash-approval/petty-cash-approval.component';
import { ServicesUtilityApprovalComponent } from './services-utility-approval/services-utility-approval.component';
import { AdvanceAmountApprovalComponent } from './advance-amount-approval/advance-amount-approval.component';



const routes = [
  {
    path: 'advance-amount-approval',
    component: AdvanceAmountApprovalComponent
  },
  {
    path: 'asset-approval',
    component: AssetApprovalComponent
  },
  {
    path: 'expense',
    component: ExpenseComponent
  },
  {
    path: 'dispose-approval',
    component: DisposeApprovalComponent
  },
  {
    path: 'consumable-requisitions',
    component: ConsumableRequisitionsComponent
  },
  {
    path: 'petty-cash-approval',
    component: PettyCashApprovalComponent
  },
  {
    path: 'services-utility-approval',
    component: ServicesUtilityApprovalComponent
  },
]
@NgModule({
  declarations: [ExpenseComponent, DisposeApprovalComponent, AssetApprovalComponent, ConsumableRequisitionsComponent, PettyCashApprovalComponent, ServicesUtilityApprovalComponent, AdvanceAmountApprovalComponent],
  imports: [RouterModule.forChild(routes),
    CommonModule, PlanworkSharedModule
  ]
})
export class MyapprovalModule { }
