import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expense-type',
  templateUrl: './expense-type.component.html',
  styleUrls: ['./expense-type.component.scss']
})
export class ExpenseTypeComponent {

  obj = {
    heading: "Expense Type",
    addModalTitle: "Expense Type",
    addModelAction: "AddExpenseType",
    baseUrlType: 'Expense',
    editModalTitle: "Expense Type",
    editModelAction: "EditExpenseType",
    endPoint: "ExpenseTypeList",
    addColumns: 1,
    colHeadings: { col1: 'Expense Type Name' },
    sortableColumns: [
      { data: '0', Title: '#', width: 25 },
      { data: 'Expense Type', Title: 'Expense Type' },
      // { data: '	2', Title: 'Monthly Mobile Limit'},
      // { data: '	3', Title: 'Local Conveyance (Per/KM)'},
      // { data: '	4', Title: 'DA/Per Day'},
      // { data: '	5', Title: 'Hotel Limit/ Per Night'},
      // { data: '	6', Title: 'Own Arrangement'},
      { data: '2', Title: 'Created on' },
      { data: '3', Title: 'Action', width: 60 },
    ],
    deleteEndPoint: 'ExpenseTypeDelete',
    getListObj: {
      Entitlementcodename: '_blank'
    }
  }

}
