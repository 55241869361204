import { Injectable, Injector } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, of, Subject, throwError } from "rxjs";
import { catchError, map } from 'rxjs/operators';
import { Router } from "@angular/router";
import { PlanworkConfigService } from "./config.service";
import { NgxSpinnerService } from "ngx-spinner";
import { CommonModalComponent } from "app/main/core/common/common-modal/common-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { ClientipService } from "./clientip.service";
declare let ClientIP: any;

@Injectable()
export class AuthenticatedHttpService implements HttpInterceptor {

  dialogRef: any;
  exceptionText = 'There are some exception error, Please contact to support team.';
  IPAddress: any;
  authService: any

  constructor(private router: Router,
    private plService: PlanworkConfigService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private http: HttpClient,
    private injector: Injector
  ) {
    setTimeout(() => {
      this.authService = this.injector.get(ClientipService);
    })
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req) {
      this.plService.setHrsCount.next(3600);
      // req.body.IpAddress = this.authService?.IPAddress;
    }
    const currentToken = localStorage.getItem('token');

    if (req.url.indexOf('https://jsonip.com/') === 0 && currentToken) {
      req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + currentToken) });
    }
    return next.handle(req).pipe(
      catchError((error) => {
        if (error?.status == 401 || error?.status == 403) {
          localStorage.clear();
          this.router.navigate(['/time-out'])
            .then(() => { });
        } else {
          this.spinner.hide();
          // this.dialogRef = this.dialog.open(CommonModalComponent, {
          //   width: '300px',
          //   disableClose: true,
          //   autoFocus: false,
          //   panelClass: 'statusClass',
          //   data: { action: 'delete', statusText: this.exceptionText }
          // });
        }
        return throwError(error?.message);
      })
    )
  }
}

