import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatatableService } from '@planwork/datatable-settings';
import { DataTableDirective } from 'angular-datatables';
import { ServiceService } from 'app/main/core/masterservice/service.service';
import { planworkConfig } from 'app/planwork.config';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddContactModalComponent } from '../../add-contact-modal/add-contact-modal.component';
import { CommonModalComponent } from '../../common-modal/common-modal.component';
import { CommonService } from '../../common.service';
import { ModalpopupComponent } from '../../modalpopup/modalpopup.component';
import { TableColumn } from './Table.Column';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  Result: any[];
}
@Component({
  selector: 'app-uom',
  templateUrl: './uom.component.html',
  styleUrls: ['./uom.component.scss']
})
export class UomComponent {

//   obj = {
//     heading: "Unit Of Measurements",
//     addModalTitle: "Unit Of Measurements",
//     addModelAction: "AddUom",
//     baseUrlType: 'GlobalCore',
//     editModalTitle: "Unit Of Measurements",
//     editModelAction: "EditUom",
//     endPoint: "UnitOfMeasurementList",
//     addColumns: 1,
//     colHeadings: { col1: 'Abbreviation Name' ,col2: 'UOM Name' },
//     sortableColumns: [
//       { data: '0', Title: '#' },
//       { data: 'Abbreviation', Title: 'Abbreviation' },
//       { data: 'Name', Title: 'Name' },
//       { data: '2', Title: 'Created on' },
//       { data: '3', Title: 'Action' },
//     ],
//     deleteEndPoint: 'UnitOfMeasurementDelete',
//     getListObj: {
//       Abbreviation: '_blank'
//     }
//   }

// }
dialogRef: any;
@ViewChild(DataTableDirective, { static: false })
dtElement: DataTableDirective;
dtOptions: DataTables.Settings = {};
tableSetting: any = {};
search = new Object();
searchText: any;
tblClm: any[] = [];
supplierInput: any
tabledata: any = {};
sortItem = 1;
subOfferList: any[] = [];
exceptionText = 'Are you sure you want to delete record'
public timing
constructor(
  private http: HttpClient,
  private vendorService: CommonService,
  private ServiceService: ServiceService,
  private spinner: NgxSpinnerService,
  public dialog: MatDialog,
  private router: Router,
  private datatableServise: DatatableService,
) {
  this.tableSetting = this.datatableServise.tablePagination();
  this.tblClm = TableColumn;
  //this.getDataTableSettings();
  localStorage.setItem('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VyTmFtZSI6IlByYXNoYW50IFBhcmVlayIsImp0aSI6IjNmNWZkZDcyLTNkYTUtNGZjYi1iOGJkLTE0NTA3NTNkZmI2MSIsIkVtcGxveWVlQ29kZSI6IkVNUDAzLzIxLzAyMDA5IiwiVXNlcklkIjoiMTQ4MCIsIkVtYWlsSWQiOiJwcmFzaGFudC5wYXJlZWtAdmVydGV4cGx1cy5jb20iLCJMb2dnZWRPbiI6IjMvMTQvMjIgODozNzo0NSBBTSIsIkNvbXBhbnlJZCI6IjEiLCJuYmYiOjE2NDcyNDcwNjUsImV4cCI6MTczMzY0NzA2NSwiaWF0IjoxNjQ3MjQ3MDY1LCJpc3MiOiJodHRwOi8vdGVjaC5jb20iLCJhdWQiOiJodHRwOi8vdGVjaC5jb20ifQ.TL0_cj3ZTLsfNeg0oYPzl9WCjdSSPyP6n_0Mn9S-lOg')
}
ngOnInit(): void {
  this.getList()
}
getList(): void {
  this.spinner.show();
  const that = this;
  this.dtOptions = {
    language: { searchPlaceholder: 'Search by keyword' },
    ...this.tableSetting.pagination,
    ajax: (dataTablesParameters: any, callback) => {
      clearTimeout(this.timing)
      this.timing = setTimeout(() => {
        that.http.post<DataTablesResponse>(
          planworkConfig.baseUrl + 'GlobalCore/api/' + 'Configuration/UnitOfMeasurementList',
          {
            Search:
            {
              ...dataTablesParameters,
              value: dataTablesParameters.search.value,
              regex: dataTablesParameters.search.regex,
            },
            ...this.supplierInput
          }, { headers: this.tableSetting.dataTables }
        ).subscribe(resp => {
          that.spinner.hide();
          that.searchText = dataTablesParameters.search.value;
          that.tabledata = dataTablesParameters;
          let vendor:any = resp.Result
          that.subOfferList = vendor.ItemList;

          callback({
            recordsTotal: vendor.FilteredRecordCount  ,
            recordsFiltered: vendor.FilteredRecordCount,
            data: []
          });

        });
      }, 900);
    },
    columns: this.tblClm,
    ...this.tableSetting.domSetting
  };
}
addSubOffering() {
      this.dialogRef = this.dialog.open(ModalpopupComponent, {
        autoFocus: false,
        disableClose: true,
        data: { modalTitle: 'Unit Of Measurements', action: 'AddUom', baseUrlType: 'GlobalCore' },
      });
      this.dialogRef.afterClosed().subscribe(async (response) => {
        if (response?.body) {
          this.spinner.hide();
          $('#table1').DataTable().ajax.reload();
        }
      });
}
editSubOffering(id) {
  let inputData = { UnitOfMeasurementId: id }
  this.dialogRef = this.dialog.open(ModalpopupComponent, {
    autoFocus: false,
    disableClose: true,
    data: {
      modalTitle: 'Unit Of Measurements',
      action: 'EditUom',
      Data: inputData,
      baseUrlType: 'GlobalCore'
    },
  });
  this.dialogRef.afterClosed().subscribe(async (response) => {
    if (response?.body) {
      this.spinner.hide();
      $('#table1').DataTable().ajax.reload();
    }
  });
}

resultPopup(action, message) {
  this.dialogRef = this.dialog.open(CommonModalComponent, {
    width: '300px',
    disableClose: true,
    autoFocus: false,
    panelClass: 'statusClass',
    data: { action: action, statusText: message }
  });
}
deleteSubOffering(id) {
  const dialogRef = this.dialog.open(CommonModalComponent, {
    width: '300px',
    disableClose: true,
    autoFocus: false,
    panelClass: 'statusClass',
    data: { action: 'delete', statusText: this.exceptionText }
  });
  dialogRef.afterClosed().subscribe(async (result) => {
    if (result) {
      this.ServiceService.globalcore({ UnitOfMeasurementId: id }, 'UnitOfMeasurementDelete').subscribe(response => {
        if (response) {
          $('#table1').DataTable().ajax.reload();
          const dialogRef = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'success', statusText: response?.body?.Message}
          });
        } else {
          const dialogRef = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'delete', statusText: response?.body?.Message}
          });
        }
      })
    }
  });
}
}

