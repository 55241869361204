import { NgModule } from '@angular/core';
import { MainComponent } from './main.component';
import { RouterModule } from '@angular/router';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { PlanworkSharedModule } from '@planwork/planwork.shared.module';
// import { MatToolbarModule } from '@angular/material/toolbar';
// import { MatListModule } from '@angular/material/list';
// import { MatTreeModule } from '@angular/material/tree';
// import { MatTableModule } from '@angular/material/table';
// import { MatCardModule } from '@angular/material/card';
import { SearchfilterPipe } from './pipe/searchfilter.pipe';
import { CoresidebarComponent } from './coresidebar/coresidebar.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AuthGuard } from 'app/_guards/auth.guard';
import { NotificationComponent } from './toolbar/notification/notification.component';
import { ProfileComponent } from './toolbar/profile/profile.component';
import { FuseMatSelectSearchModule } from '@planwork/mat-select-search/mat-select-search.module';
import { TaxInvoiceComponent } from './tax-invoice/tax-invoice.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticatedHttpService } from '@planwork/services/authenticated-http.service';
import { ItemSidebarComponent } from './common/item-sidebar/item-sidebar.component';
import { CKEditorModule } from 'ckeditor4-angular';


const routes = [
    {
        path: 'core',
        loadChildren: () => import('./core/core.module').then(m => m.CoreModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'notification',
        component: NotificationComponent,
    },
    {
        path: 'profile',
        component: ProfileComponent,
    },
    {
        path: 'common',
        loadChildren: () => import('./core/common/common.module').then(m => m.CommonModule),
    },
    {
        path: 'campaign',
        loadChildren: () => import('./campaign/campaign.module').then(m => m.PlanworkCampainModule),
        canActivate: [AuthGuard]
    }, {
        path: 'vendors',
        loadChildren: () => import('./vendor-compliance/vendor-compliance.module').then(m => m.VendorComplianceModule),
        canActivate: [AuthGuard]
    }, {
        path: 'lead',
        loadChildren: () => import('./lead/lead.module').then(m => m.LeadModule),
        canActivate: [AuthGuard]
    }, {
        path: 'billing',
        loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule),
        canActivate: [AuthGuard]
    }, {
        path: 'services',
        loadChildren: () => import('./services/services.module').then(m => m.ServicesModule),
        canActivate: [AuthGuard]
    }, {
        path: 'projects',
        loadChildren: () => import('./project/project.module').then(m => m.ProjectModule),
        canActivate: [AuthGuard]
    }, {
        path: 'team',
        loadChildren: () => import('./team/team.module').then(m => m.TeamModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'report',
        loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'assignments',
        loadChildren: () => import('./tasks/tasks.module').then(m => m.TasksModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'toolbar',
        loadChildren: () => import('./navigation/navigation.module').then(m => m.NavigationModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'my-activity',
        loadChildren: () => import('./my-activity/my-activity.module').then(m => m.MyActivityModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'attendence-log',
        loadChildren: () => import('./attendence-log/attendence-log.module').then(m => m.AttendenceLogModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'support',
        loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'tax-invoice/:id',
        component: TaxInvoiceComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'doc-print',
        loadChildren: () => import('./doc-print/doc-print.module').then(m => m.DocPrintModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'compliances',
        loadChildren: () => import('./compliances/compliances.module').then(m => m.CompliancesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'services-and-utility',
        loadChildren: () => import('./services-and-utility/services-and-utility.module').then(m => m.ServicesAndUtilityModule),
        canActivate: [AuthGuard]
    },
    {
        path: '**',
        redirectTo: '/'
    }
];

@NgModule({
    declarations: [
        MainComponent,
        ToolbarComponent,
        SidebarComponent, SearchfilterPipe, CoresidebarComponent, NotificationComponent, TaxInvoiceComponent, ProfileComponent],
    imports: [
        // MatToolbarModule,
        // MatListModule,
        PlanworkSharedModule,
        // MatTreeModule,
        FuseMatSelectSearchModule,
        // MatTableModule,
        // MatCardModule,
        MatPaginatorModule,
        RouterModule.forChild(routes),
        CKEditorModule
    ],
    providers: [AuthGuard,
        { provide: HTTP_INTERCEPTORS, useClass: AuthenticatedHttpService, multi: true }
    ],
    exports: [MainComponent, PlanworkSharedModule],
})

export class PlanworkMainModule { }
