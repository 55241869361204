import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-asset-classes',
  templateUrl: './asset-classes.component.html',
  styleUrls: ['./asset-classes.component.scss']
})
export class AssetClassesComponent implements OnInit {
  obj = {
    heading: "Asset Class",
    addModalTitle: "Asset Class",
    addModelAction: "Addassetclass",
    baseUrlType: 'Asset',
    editModalTitle: "Asset Class",
    editModelAction: "Editassetclass",
    endPoint: "AssetClassList",
    addColumns: 1,
    colHeadings: { col1: 'Asset Class Name' },
    sortableColumns: [
      { data: '0', Title: '#' },
      { data: 'assetclassName', Title: 'Asset Class' },
      { data: 'createddate', Title: 'Created on' },
      { data: '3', Title: 'Action' },
    ],
    deleteEndPoint: 'AssetClassDelete',
    getListObj: {
      assetclassName: '_blank'
    }
  }
  constructor() {

  }

  ngOnInit(): void {
  }

}
