<div class="content mt-5 addattri-pge" style="width: 100%;">
    <div class="leadinfo-outer select-skiils leadtask-all">
        <div class="selsave d-flex">
            <div class="search_form position-relative">
                <mat-icon>search</mat-icon>
                <input type="text" matInput placeholder="Search Attributes" [(ngModel)]="searchText" autocomplete="off">
            </div>
        </div>
    </div>

    <!-- <div class="skill-search d-flex flex-wrap">
        <mat-chip-list #chipList aria-label="Fruit selection" *ngFor="let main of checkedList; let i = index"
            class="mr-2">
            <mat-chip>
                {{main.AssetAttributeName}}
                <mat-icon matChipRemove (click)="remove(main, i)">close
                </mat-icon>
            </mat-chip>
        </mat-chip-list>
    </div> -->

    <div class="grybx">
        <div class="inbx" *ngIf="attributesList?.length > 0">
            <h4>Unused Attributes</h4>
            <ul class="d-flex flex-wrap ng-star-inserted">
                <li *ngFor="let list of attributesList | filter:searchText; let i = index">
                    <!-- (change)="isAllSelected(attributesList, list)" -->
                    <mat-checkbox [(ngModel)]="list.isSelected" name="isSelected_{{i}}">{{list?.AssetAttributeName}}</mat-checkbox>
                </li>
            </ul>
        </div>
        <!-- Class -->
        <div class="inbx" *ngIf="itemList?.length > 0">
            <h4>Used Attributes for Item ({{attributeItemName}})</h4>
            <ul class="d-flex flex-wrap ng-star-inserted">
                <li *ngFor="let list of itemList | filter:searchText; let i = index">
                    <!-- (change)="isAllSelected(itemList, list)" -->
                    <mat-checkbox [(ngModel)]="list.isSelected" name="isSelected_{{i}}">{{list?.AssetAttributeName}}</mat-checkbox>
                </li>
            </ul>
        </div>
        <!-- Category -->
        <div class="inbx" *ngIf="categoryList?.length > 0">
            <h4>Used Attributes for Category ({{attributeCategorieName}})</h4>
            <ul class="d-flex flex-wrap ng-star-inserted">
                <li *ngFor="let list of categoryList | filter:searchText; let i = index">
                    <!-- (change)="isAllSelected(categoryList, list)" -->
                    <mat-checkbox [(ngModel)]="list.isSelected" name="isSelected_{{i}}" [disabled]="attributeKeyList == 'assetItem'">{{list?.AssetAttributeName}}</mat-checkbox>
                </li>
            </ul>
        </div>
        <!-- Class -->
        <div class="inbx" *ngIf="classList?.length > 0">
            <h4>Used Attributes for Class ({{attributeClassName}})</h4>
            <ul class="d-flex flex-wrap ng-star-inserted">
                <li *ngFor="let list of classList | filter:searchText; let i = index">
                    <!-- (change)="isAllSelected(classList, list)" -->
                    <mat-checkbox [(ngModel)]="list.isSelected" name="isSelected_{{i}}" [disabled]="attributeKeyList == 'assetCategory' || attributeKeyList == 'assetItem'">{{list?.AssetAttributeName}}</mat-checkbox>
                </li>
            </ul>
        </div>
    </div>
    <div class="d-flex justify-content-end" style="margin: 30px 0 38px;">
        <!-- [disabled]="checkedList.length == 0" -->
        <button mat-raised-button color="primary" (click)="attributeSave()">Save</button>
    </div>
</div>