import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss']
})
export class TermsConditionComponent implements OnInit {
  obj = {
    heading: "Asset Class",
    addModalTitle: "Terms & Conditions",
    addModelAction: "AddTerms&Conditions",
    baseUrlType: 'Asset',
    editModalTitle: "Terms & Conditions",
    editModelAction: "EditTerms&Conditions",
    endPoint: "AssetClassList",
    addColumns: 1,
    colHeadings: { col1: 'Terms & Conditions' },
    sortableColumns: [
      { data: '0', Title: '#' },
      { data: 'Terms&Conditions', Title: 'Terms & Conditions' },
      { data: 'createddate', Title: 'Created on' },
      { data: '3', Title: 'Action' },
    ],
    deleteEndPoint: 'AssetClassDelete',
    getListObj: {
      assetclassName: '_blank'
    }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
