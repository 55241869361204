import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '@planwork/services/api.service';
import { DropdownService } from '@planwork/services/dropdown.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonModalComponent } from '../../common-modal/common-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { F } from '@angular/cdk/keycodes';
import { element } from 'protractor';

@Component({
  selector: 'app-add-entitlements',
  templateUrl: './add-entitlements.component.html',
  styleUrls: ['./add-entitlements.component.scss']
})
export class AddEntitlementsComponent implements OnInit {
  saveExpenseEntitlementInput: any = {
    CityLevelTear: [{
      cityleveltearname: 'Tear1',
      LimitDuration: [{
        Expense: [{}]
      }]
    },
    {
      cityleveltearname: 'Tear2',
      LimitDuration: [{
        Expense: [{}]
      }]

    },
    {
      cityleveltearname: 'Tear3',
      LimitDuration: [{
        Expense: [{}]
      }]
    }
    ]
  };
  editShow = false
  EntitlementCode: any;
  EntitlementCodes = '';
  ExpMonthlyCategoryDropDown: any;
  typeOfDuration: any[] = [
    { LimitDurationId: 119, keyName: "Daily" },
    { LimitDurationId: 120, keyName: "Weekly" },
    { LimitDurationId: 121, keyName: "Monthly" },
  ]
  @ViewChild('f1', { static: false }) first: any;
  @ViewChild('f2', { static: false }) SecondForm: any;
  @ViewChild('f3', { static: false }) thirdForm: any;
  dialogRef: any;
  constructor(
    private dropdownService: DropdownService,
    private service: ApiService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(res => {
      let id = parseInt(res.id)
      if (id) {
        this.editShow = !this.editShow
        this.getById(id);
      }
    })
    let EntitlementCodeDropDown: any = await this.EntitlementCodeDropDown();
    this.EntitlementCode = EntitlementCodeDropDown.Result;
    //
    let ExpMonthlyCategoryList = await this.ExpMonthlyCategoryDropDownList();
    this.ExpMonthlyCategoryDropDown = ExpMonthlyCategoryList.Result;
  }
  EntitlementCodeDropDown(): Promise<any> {
    return this.dropdownService.EntitlementCodeDropDownList({}).toPromise()
  }
  ExpMonthlyCategoryDropDownList(): Promise<any> {
    return this.dropdownService.MonthlyCategoryDropDownList({}).toPromise()
  }
  compareFn(a: any, b: any) {
    if (b) {
      const d = a[Object.keys(a)[0]];
      const e = b[Object.keys(b)[0]];
      return d === e;
    }
  }
  addCategory(data) {
    data.push({})
  }
  removeCategory(list, array, i) {
    this.dialogRef = this.dialog.open(CommonModalComponent, {
      disableClose: true,
      data: { action: 'delete', statusText: 'Are you sure you want to delete' }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (list.ExpenseId) {
          this.service.postService({}, 'Expense/api/Expense/ExpenseDeleteById?ExpenseId=' + list.ExpenseId).subscribe(res => {
            if (res.body.Success) {
              const dialogRef = this.dialog.open(CommonModalComponent, {
                width: '300px',
                disableClose: true,
                autoFocus: false,
                panelClass: 'statusClass',
                data: { action: 'success', statusText: res.body.Message }
              });
              dialogRef.afterClosed().subscribe(async (result) => {
                array.splice(i, 1)
              })
            }
          })
        }
        else {
          array.splice(i, 1)
        }
      }
      else {
      }
    })


  }
  addLimitDuration(limitDuration) {
    if (this.SecondForm.valid) {
      limitDuration.push({
        Expense: [{}]
      })
    }

  }
  removeLimitDuration(limitDuration, i) {
    limitDuration.splice(i, 1)
  }
  entitlmentSave(e, data) {
    let validation = e.valid
    if (data.CityLevelTear[0].LimitDuration[0].Expense[0].ExpenseCategoryName) {
      validation = true
    }
    if (validation && this.first.valid && this.SecondForm.valid && this.thirdForm.valid) {
      data.CityLevelTear.forEach(element => {
        element.LimitDuration.forEach(element2 => {
          if (!element2.Expense[0].ExpenseCategoryName) {
            element.LimitDuration = []
          }
        });
      });
      const a = this.EntitlementCode.filter(x => x.EntitlementCodeid == data.EntitlementCodeid)[0];
      if (a) { data.EntitlementCode = a.EntitlementCodeName }
      this.spinner.show()
      this.service.postService(data, 'Expense/api/Expense/ExpenseEntitlementsCodeSave').subscribe(res => {
        this.spinner.hide()
        if (res.body.Success) {
          const dialogRef = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'success', statusText: res.body.Message }
          });
          dialogRef.afterClosed().subscribe(async (result) => {
            this.router.navigate(['/app/core/common/settings/entitlements']);
          })
        } else {
          const dialogRef = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'delete', statusText: res.body.Message }
          });
        }

      })
    }
  }
  getById(id) {
    this.spinner.show();
    this.service.postService({ ExpenseEntitlementCodeid: id }, 'Expense/api/Expense/ExpenseEntitlementsCodeGetById').subscribe(response => {
      this.spinner.hide();
      this.saveExpenseEntitlementInput = response.body.Item2
      this.saveExpenseEntitlementInput.CityLevelTear.forEach(element => {
        if (element.LimitDuration.length == 0) {
          element.LimitDuration = [{
            Expense: [{}]
          }]
        }
        element.LimitDuration.forEach(element1 => {
          if(element1.Expense.length == 0){
            element1.Expense = [{}]
          }          
        });
      });


    })
  }
}
