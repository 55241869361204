import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consumable-items',
  templateUrl: './consumable-items.component.html',
  styleUrls: ['./consumable-items.component.scss']
})
export class ConsumableItemsComponent implements OnInit {

  obj = {
    heading: "Consumable Item",
    addModalTitle: "Consumable Item",
    addModelAction: "Addconsumableitem",
    baseUrlType: 'Consumable',
    editModalTitle: "Consumable Item",
    editModelAction: "Editconsumableitem",
    endPoint: "ConsumableItemList",
    addColumns: 1,
    colHeadings: { col1: 'Consumable Category Name', col2: 'Consumable Item Name', },
    sortableColumns: [
      { data: '0', Title: '#' },
      { data: 'assetitemName', Title: 'Item' },
      { data: 'assetcategoryname', Title: 'Consumable Category' },
      { data: 'createddate', Title: 'Created on' },
      { data: '4', Title: 'Action' },
    ],
    deleteEndPoint: 'ConsumableItemDelete',
    getListObj: {
      AssetClassName: '_blank'
    }
  }

  constructor() { }

  ngOnInit(): void {
  }


}
