<div class="content pettycash-pge">
    <div class="page-heading">
        <div fxFlex fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
                <h1>Petty Cash Approval</h1>
            </div>
            <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            </div>
        </div>
    </div>

    <div class="content_body">
        <div class="filter_area pb-20">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex="1 0 auto">
                    <div class="top_drop" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                        <div class="input_group filter_box">
                            <p class="left_label">Status</p>
                            <mat-form-field appearance="outline">
                                <mat-select placeholder="All Status"  (selectionChange)="getFilterData($event)">
                                    <mat-option [value]="0">All</mat-option>
                                    <mat-option [value]="list"
                                        *ngFor="let list of StatusList">{{list.StatusName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <div class="filter-calendar input_group filter_box">
                                <p class="left_label">From</p>
                                <mat-form-field appearance="outline">
                                    <input matInput [matDatepicker]="picker1" readonly (click)="picker1.open()"
                                        name="StartDate" [(ngModel)]="businessHead.StartDate"
                                        (dateChange)="getFilterData1($event); businessHead.EndDate = businessHead.StartDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker1">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker1>
                                    </mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="filter-calendar input_group filter_box">
                                <p class="left_label">To</p>
                                <mat-form-field appearance="outline">
                                    <input matInput [matDatepicker]="picker2" readonly (click)="picker2.open()"
                                        [min]="businessHead.StartDate" name="EndDate" [(ngModel)]="businessHead.EndDate"
                                        (dateChange)="getFilterData1($event)">
                                    <mat-datepicker-toggle matSuffix [for]="picker2">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker2>
                                    </mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <table id="table1" datatable [dtOptions]="dtOptions"
            class="row-border hover list_table table table-bordered table-hover table-compact">
            <thead>
                <tr>
                    <th *ngFor="let item of tblClm" (click)="sortItem = item.position">
                        <div>
                            <span>{{item.Title}}</span>
                        </div>
                    </th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let list of DataOutput; let i = index">
                    <td>{{i + 1+tabledata.start}}</td>
                    <td><strong>{{list.CreatedDate | date}}</strong></td>
                    <td>
                        <p class="d-flex mb-0">
                            <img src="{{list?.EmployeeImage}}"
                                style="width: 26px; height: 26px; margin-right: 8px; margin-top: 2px; border-radius:50%">
                            <span>
                                <strong>{{list?.EmployeeName}}</strong><br>
                                <small>{{list?.EmployeeCode}}</small>
                            </span>
                        </p>
                    </td>
                    <td>{{list?.CurrencySymbol}} {{list?.RequestAmount}}</td>
                    
                    <td>
                        <strong class="text-success">
                            {{list?.ApprovedAmount == 0 ? '' : list?.CurrencySymbol}}  {{list?.ApprovedAmount == 0 ? '-' : list?.ApprovedAmount}}
                        </strong>
                    </td>
                    <td><strong>{{list?.RequiredBefore | date}}</strong></td>
                    <td><mat-icon class="mticon" (click)="sidenav1.toggle(); openPopup(list)">description</mat-icon>
                    </td>
                    <td>
                        <span *ngIf="list?.StatusId == 1" class="text-warning">Pending</span>
                        <span *ngIf="list?.StatusId == 2" class="text-success">Approved</span>
                        <span *ngIf="list?.StatusId == 3" class="text-success">Received</span>
                    </td>
                    <td>
                        <div class="action_icons">
                            <div class="icon" matTooltip="View" (click)="sidenav.toggle()" (click)="sidemenu(list)" >
                                <mat-icon>visibility</mat-icon>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="DataOutput?.length == 0">
                    <td align="center" colspan="9">No matching records found</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<mat-drawer-container class="example-container epica-sidebar">
    <mat-drawer #sidenav1 class="sidebar taskdetail-sidebar" mode="over" position="end">
        <mat-icon class="sidebar-closeicon" (click)="sidenav1.toggle()">highlight_off</mat-icon>

        <div class="taskdetail-dv">
            <h2>Remark</h2>
            <div class="task_detail_box">
                <div class="row">
                    <div class="col-md-12 dtl_text">
                        <p>{{discripation}}</p>
                    </div>
                </div>
            </div>
        </div>

    </mat-drawer>
</mat-drawer-container>
<!-- Mat Drawer -->
<mat-drawer-container class="example-container epica-sidebar">
    <mat-drawer #sidenav class="sidebar taskdetail-sidebar" mode="over" position="end">
        <mat-icon class="sidebar-closeicon" (click)="sidenav.toggle()">highlight_off</mat-icon>
        <form class="deduwidth" autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">
        <div class="taskdetail-dv">
            <h2>Detail</h2>
            <div class="task_detail_box">
                <div class="row">
                    <div class="col-md-6 dtl_text">
                        <label>Requested By</label>
                        <p class="d-flex mb-0">
                            <img src="{{getdata?.EmployeeImage}}"
                                style="width: 26px; height: 26px; margin-right: 8px; margin-top: 2px; border-radius:50%">
                            <span>
                                <strong>{{getdata?.EmployeeName}}</strong><br>
                                <small>{{getdata?.EmployeeCode}}</small>
                            </span>
                        </p>
                    </div>
                    <div class="col-md-6 dtl_text">
                        <label>Date</label>
                        <p>{{getdata?.CreatedDate | date}}</p>
                    </div>
                    <div class="col-md-12 dtl_text">
                        <label>Remark</label>
                        <p>{{getdata?.Remark}}</p>
                    </div>
                    <div class="col-md-12 dtl_text">
                        <label>Amount Requested</label>
                        <p>{{getdata?.CurrencySymbol}} {{getdata?.RequestAmount}}</p>
                    </div>
                    <div class="col-md-12 dtl_text" *ngIf="getdata?.ApprovedAmount">
                        <label>Approved Amount</label>
                        <p>{{getdata?.CurrencySymbol}} {{getdata?.ApprovedAmount}}</p>
                    </div>
                    <div class="col-md-12 dtl_text" *ngIf="getdata?.ApprovedAmount < 1">
                        <div class="bgorbx">
                            <label>Approved Amount</label>
                            <mat-form-field appearance="outline" style="width:200px; margin-bottom: 10px;" [class.errorborder]="pettycash.ApprovedAmount < 1 || pettycash.ApprovedAmount > getdata?.RequestAmount">
                                <span matPrefix class="price-prefix">INR</span>
                                <input type="number" matInput [(ngModel)]="pettycash.ApprovedAmount" name="ApprovedAmount" required [max]="getdata?.RequestAmount">
                            </mat-form-field>
                            <mat-error *ngIf="pettycash.ApprovedAmount < 1 || pettycash.ApprovedAmount > getdata?.RequestAmount">Invalid Amount</mat-error>
                        </div>
                    </div>
                    <div class="col-md-12"  *ngIf="getdata?.ApprovedAmount < 1">
                        <button mat-flat-button mat-primary color="primary"
                                [disabled]="myForm.form.invalid || pettycash.ApprovedAmount < 1 || pettycash.ApprovedAmount > getdata?.RequestAmount" (click)="onSubmit()"
                                class="btn-lg mt-3" >Approve</button>
                   
                    </div>
                </div>
            </div>
        </div>

</form>
    </mat-drawer>
</mat-drawer-container>