import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { DatatableService } from '@planwork/datatable-settings';
import { DataTableDirective } from 'angular-datatables';
import { CommonModalComponent } from 'app/main/core/common/common-modal/common-modal.component';
import { ServiceService } from 'app/main/core/masterservice/service.service';
import { planworkConfig } from 'app/planwork.config';
import { NgxSpinnerService } from 'ngx-spinner';
import { TableColumn1 } from './Table.Column';
class DataTablesResponse {
  data: any[];
  draw: number;
  tblClm: any[] = [];
  recordsFiltered: number;
  recordsTotal: number;
  Result: any[];
}
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-advance-amount-approval',
  templateUrl: './advance-amount-approval.component.html',
  styleUrls: ['./advance-amount-approval.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})

export class AdvanceAmountApprovalComponent implements OnInit {
  searchText:any;
  tblClm: any[] = [];
  selected = '0';
  pettycash:any={};
  @ViewChild('myForm', { static: false }) form: any;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  tableSetting: any = {};
  startdate= new Date();
  enddate= new Date();
  businessHead: any={StatusId:0};
  sortItem = 1;
  selected3= 0;
  DataOutput: any;
  StatusList:any=[
    {StatusName : 'Pending',StatusId:1},
    {StatusName : 'Approved',StatusId:2}
  ]
  tabledata: any = {};
  discripation: any;
  getdata: any;
  userinput: any;
  @ViewChild('sidenav') sidenav: MatSidenav;
  constructor( private spinner: NgxSpinnerService,
    private datatableServise: DatatableService,
    private masterService: ServiceService,
    private http: HttpClient,
    private dialog: MatDialog) { 
      this.tableSetting = this.datatableServise.tablePagination();
      this.tblClm = TableColumn1;
      this.userinput = JSON.parse(localStorage.getItem("userid"));
    }

  ngOnInit(): void {
    this.businessHead.StartDate = new Date()
    this.businessHead.EndDate = new Date()
    this.businessHead.StartDate = new Date(this.businessHead.StartDate.getFullYear(), this.businessHead.StartDate.getMonth(),2);
    this.getList()
  }

  onSubmit(){
    if (this.form.valid) {      
    this.pettycash.StatusId = 2
    this.pettycash.AdvanceCashId = this.getdata.AdvanceCashId
      console.log(this.pettycash);
      this.spinner.show();
      this.masterService.AdvancePayment(this.pettycash, 'AdvanceCashRequestStatusUpdate').subscribe((response) => {
        console.log(response);
        if (response.body.Success) {
          this.spinner.hide();  
          const dialogRef = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'success', statusText: response.body.Result.Message }  
          })
          dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
              this.spinner.show();
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.draw();
              });
              this.form.reset();
              this.sidenav.toggle();
              // setTimeout(() => {
              // this.selectedIndex =3
              // }, 1000);
              // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              //   dtInstance.draw();
              // });
            }
          })
        }
        else {
          this.spinner.hide();
          const dialogRef = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'Alert', statusText: response.body.Result.Message }
  
          })  
        }
      })
      }
  }

  changeDate(){
    this.spinner.show()
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });    
  }

  getFilterData(event) { 
    this.businessHead.StatusId = 0;
    if(event.value != 0){
        this.businessHead.StatusId = parseInt(event.value.StatusId);
    }
    this.spinner.show();
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  getList(): void {
    this.businessHead.EmployeeReportingPersonId = 1
    this.spinner.show();
    const that = this;
    this.dtOptions = {
      language: { searchPlaceholder: 'Search by keyword' },
      ...this.tableSetting.pagination,
      ajax: (dataTablesParameters: any, callback) => {
        that.http.post<DataTablesResponse>(
          planworkConfig.baseUrl + planworkConfig.PettyCashBaseUrl + 'AdvancePayment/GetAdvanceCashListByUserId',
          {
            ...this.businessHead,
            Search:
            {
              ...dataTablesParameters,
              value: dataTablesParameters.search.value,
              regex: dataTablesParameters.search.regex,
            },
            // IsForFc: true,
          }, { headers: this.tableSetting.dataTables }
        ).subscribe(resp => {
          console.log(resp);

          that.spinner.hide();
          that.searchText = dataTablesParameters.search.value;
          that.tabledata = dataTablesParameters;
        let DataOutput:any = resp.Result;
          this.DataOutput = DataOutput.ItemList

          callback({
            recordsTotal: DataOutput?.recordsTotal,
            recordsFiltered: DataOutput?.FilteredRecordCount,
            data: []
          });

        });
      },
      columns: this.tblClm,
      ...this.tableSetting.domSetting
    };
  }

  openPopup(list){
    this.discripation =  list.Remark 
  }

  sidemenu(ele){
    this.getdata = ele
  }
}
