import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PlanworkModule } from '@planwork/planwork.module';
import { PlanworkSharedModule } from '@planwork/planwork.shared.module';
import { planworkConfig } from './planwork.config';
import { PlanworkMainModule } from './main/main.module';
import { MatTreeModule } from '@angular/material/tree';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './_guards/auth.guard';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FuseMatSelectSearchModule } from '@planwork/mat-select-search/mat-select-search.module';
import { RegisterCompanyModule } from './register-company/register-company.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'; // a plugin!
import { DashboardComponent } from './dashboard/dashboard.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { AuthenticatedHttpService } from '@planwork/services/authenticated-http.service';
import { TimeOutComponent } from './time-out/time-out.component';
import { SettingsModule } from './main/core/common/settings/settings.module';
import { MyapprovalModule } from './main/my-approval/my-approval.module';

const routes = [
  {
    path: 'core',
    loadChildren: () => import('./main/core/core.module').then(m => m.CoreModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    AppComponent,
    TimeOutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    PlanworkSharedModule,
    PlanworkMainModule,
    MatTreeModule,
    MatTableModule,
    MatCardModule,
    HttpClientModule,
    NgxSpinnerModule,
    FuseMatSelectSearchModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    RegisterCompanyModule,
    SettingsModule,
    MyapprovalModule,
    RouterModule.forChild(routes),
    PlanworkModule.forRoot(planworkConfig),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  providers: [AuthGuard,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticatedHttpService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
