import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { planworkConfig } from 'app/planwork.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RegistercompanyService {
  config: any;
  SpinnerTimeout = 500;
  ClientIP: any;
  IPAddress: any;
  apiUrl: any; 
  private baseUrl: any;
  private coreBaseUrl: any;
  constructor(
    private http: HttpClient    
  ) { 

    // this.http.get<any>('https://jsonip.com')
    //   .subscribe(data => {
    //     const serverIp = data.ip;
    //     const privateIP = this.ClientIP;
    //     console.log(privateIP);
    //     const IP = serverIp + ':' + privateIP;
    //     this.IPAddress = IP;
    //   });
    this.apiUrl = planworkConfig;
    /* this.baseUrl = this.apiUrl.baseUrl + 'Campaign/api/campaigns/';
    this.baseUrlConfigration = this.apiUrl.baseUrl + 'Campaign/api/Configuration/'; */ 
    this.baseUrl = this.apiUrl.baseUrl + this.apiUrl.LicenseBaseUrl + 'Company/';     
  }

  CommonService(saveData: any, currentUrl: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',     
    });
    return this.http.post(this.baseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  postService(saveData: any, currentUrl: any): Observable<any> {  
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',     
    });
    return this.http.post(this.baseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
}
