import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'app/main/core/masterservice/service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';

@Component({
  selector: 'app-time-out',
  templateUrl: './time-out.component.html',
  styleUrls: ['./time-out.component.scss']
})
export class TimeOutComponent implements OnInit {
  logoutInput: any = {}
  user: any = {}

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private dashboardService: ServiceService,

  ) {
    // this.user = JSON.parse(localStorage.getItem('userid'));
  }

  ngOnInit(): void {
  }
  login() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

}
