<!-- <master-compnent-app [obj]="obj"></master-compnent-app> -->


<div class="content show-search " id="dataFilterTop">
    <div class="page-heading">
        <div fxFlex fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
                <h1>Unit Of Measurements</h1>
            </div>
            <button mat-flat-button color="accent" class="add-lead-btn" (click)="addSubOffering()">
                    <mat-icon>add</mat-icon> Add New
                </button>
        </div>
    </div>

    <div class="content_body">
        <div class="table-white table-responsive list_view server_side_page">
            <table id="table1" datatable [dtOptions]="dtOptions" class="row-border hover list_table table table-bordered table-hover table-compact">
                <thead>
                    <tr>
                        <th *ngFor="let item of tblClm" (click)="sortItem = item.position">
                            <div>
                                <span>{{item.Title}}</span>
                            </div>
                        </th>

                    </tr>
                </thead>
                <tbody [attr.data_sort]="'sort_' + sortItem">
                    <tr *ngFor=" let element of subOfferList; let i = index">
                        <td>{{i+1+tabledata.start}}</td>
                        <td>{{element.UnitOfMeasurementAbbreviationName? element.UnitOfMeasurementAbbreviationName:'NA'}}</td>
                        <td>{{element.UnitOfMeasurementName? element.UnitOfMeasurementName:'NA' }}</td>
                        <td>{{element.CreatedDate | date}}</td>
                        <td>
                            <div class="action_icons">
                                <div class="icon" matTooltip="Edit">
                                    <mat-icon (click)="editSubOffering(element.UnitOfMeasurementId)">edit</mat-icon>
                                </div>
                                <div class="icon" matTooltip="Delete">
                                    <mat-icon (click)="deleteSubOffering(element.UnitOfMeasurementId)">delete
                                    </mat-icon>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="subOfferList?.length == 0">
                        <td colspan="5" class="text-center">No Record Found</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>