import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './_guards/auth.guard';


const routes: Routes = [
  {
    path: 'app',
    loadChildren: () => import('./main/main.module').then(m => m.PlanworkMainModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'company-list',
    loadChildren: () => import('./register-company/register-company.module').then(m => m.RegisterCompanyModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'time-out',
    loadChildren: () => import('./time-out/time-out.module').then(m => m.TimeOutModule),

  },
  {
    path: 'my-approval',
    loadChildren: () => import('./main/my-approval/my-approval.module').then(m => m.MyapprovalModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'my-requisition',
    loadChildren: () => import('./main/my-requisition/my-requisition.module').then(m => m.MyRequisitionModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'asset-management',
    loadChildren: () => import('./main/asset-management/asset-management.module').then(m => m.AssetManagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'payment/petty-cash',
    loadChildren: () => import('./main/petty-cash/petty-cash.module').then(m => m.PettyCashModule),
    canActivate: [AuthGuard]
},
  {
    path: 'repair-maintenance',
    loadChildren: () => import('./main/repair-maintenance/repair-maintenance.module').then(m => m.RepairMaintenanceModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'consumable',
    loadChildren: () => import('./main/consumable/consumable.module').then(m => m.ConsumableModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'my-reimbursement',
    loadChildren: () => import('./main/my-reimbursement/my-reimbursement.module').then(m => m.MyReimbursementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'payment',
    loadChildren: () => import('./main/payment/payment.module').then(m => m.PaymentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'accounts',
    loadChildren: () => import('./main/accounts/accounts.module').then(m => m.AccountsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'purchase-and-procurement',
    loadChildren: () => import('./main/purchase-and-procurement/purchase-and-procurement.module').then(m => m.PurchaseAndProcurementModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/',
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
