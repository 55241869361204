import { Component, OnInit, Inject, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LeadService } from '../../lead.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpRequest, HttpEventType, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { map, tap, last, catchError } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { CommonModalComponent } from 'app/main/core/common/common-modal/common-modal.component';
import { DropdownService } from '@planwork/services/dropdown.service';
import { PlanworkConfigService } from '@planwork/services/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmPasswordValidator } from 'app/main/core/common/common-modal/confirm-password.validators';
import { ServiceService } from 'app/main/core/masterservice/service.service';
import { L } from '@angular/cdk/keycodes';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-add-contact-modal',
  templateUrl: './add-contact-modal.component.html',
  styleUrls: ['./add-contact-modal.component.scss']
})
export class AddContactModalComponent implements OnInit {
  addContactDetail: any = {
    ContactPerson: {
      Title: {}
    },
    // Department: '',
    // Designation: '',
    Mobile: {}
  }
  dialogAction: any;
  rawData_id: any;
  prospectId: any;
  fileSizeError: any;
  personContact: any;
  reminderBefore: any;
  DepartmentDropDown: any = [];
  DesignationList: any = [];
  CountryDropDownlist: any = [];
  DocumentTypeList: any = [];
  newContact: any = {
    ContactDetails: [
      {
        DecisionMaker: {},
        Designation: {},
        Mobile: {}
      }
    ]
  }

  leadnewContact: any = {
    RegisteredAddress: {
      ContactDetailList: [
        {
          ContactPerson: {},
          Department: {},
          Designation: {},
          MobileNumber: {}
        }
      ]
    }
  }

  /*   leadContacts: {
      ContactPerson : {},
      Department: {},
      Designation: {},
      MobileNumber: {}
    } */


  addDocumentDetail: any = {
    DocumentFile: {
      DocumentFileName: '',
      DocumentPath: '',
      FileData: '',
      imagePreview: '',
      FileExe: ''
    },
    valid :true
  };
  Imageshow = false;
  FileUpload: any;
  dialogReference: any;
  finalFile: any[] = [];
  dropDown: any = [];
  ReasonDropDown: any = [];
  DeclineDetail: any = {
    PMRejectReason: {}
  };
  LeadId: any;
  user: any;
  @ViewChild('f', { static: false }) form: any;
  countryCodeSearch = '';
  countryId: any;
  mobileDigit: any;
  DocumentDropDownList: any[] = [];
  registerForm: FormGroup;
  changePassword: any = {};
  responseDropdown: any;
  submitted = false;
  ReferenceModeDropDown: any[] = [];
  @ViewChild('myFileInput') myFileInput;
  constructor(
    private masterService: LeadService, private route: ActivatedRoute, private router: Router,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public _http: HttpClient,
    private dropdownService: DropdownService,
    private loginService: ServiceService,
    private appService: PlanworkConfigService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<'prsn_dialog'>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.dialogAction = data.action;
    this.rawData_id = data.RawData_Id;
    this.prospectId = data.prospect;
    console.log(data)
    this.user = JSON.parse(localStorage.getItem('userid'));
    if (this.dialogAction == 'add-document') {
      this.DocumentTypeList = data.DocumentType;
    }
    if (this.dialogAction == 'edit-document') {
      this.DocumentTypeList = data.DocumentType;
      this.addDocumentDetail = data.Data;
      console.log(this.addDocumentDetail)
      this.finalFile.push(this.addDocumentDetail.DocumentFile);
      console.log(this.finalFile);
      if (this.finalFile != null) {
        this.finalFile.forEach(element => {
          element.name = element?.DocumentFileName;
          if (element?.DocumentFileName) {
            element.fileType = element?.DocumentFileName.split('.').pop();
          }
        });
        this.Imageshow = true;
      }
    }
    if (this.dialogAction == 'edit-contact' || this.dialogAction == 'edit-contact-client') {
      this.addContactDetail = data.Data;
      console.log(this.addContactDetail);
    }
    if (this.dialogAction == 'Decline-Lead') {
      this.LeadId = data.id;
    }
    if (this.dialogAction == 'add-contact-client') {
      // this.addContactDetail.Mobile.ISDCode = data.data.Mobile.ISDCode;
      if (data.country) {
        console.log(data.country);

        this.addContactDetail.Mobile.ISDCode = data.country.CountryCode;
        this.countryId = data.country.CountryId;
        this.mobileDigit = data.country.MobileDigit;
      }
    }
    if (this.dialogAction == 'Decline-Lead-head') {
      this.LeadId = data.id;
    }
    if (this.dialogAction == 'add-contact') {
      if (data.country) {
        this.addContactDetail.Mobile.ISDCode = data.country.CountryCode;
        this.countryId = data.country.CountryId;
        this.mobileDigit = data.country.MobileDigit;
      }
    }
  }

  async ngOnInit() {
    this.spinner.show()
    this.registerForm = this.formBuilder.group({
      currentPassword: ["", Validators.required],
      password: ["", Validators.required],
      confirmPassword: ["", Validators.required]
    },
      {
        validator: ConfirmPasswordValidator("password", "confirmPassword")
      })
    this.feedbackDetail();
    // if(this.feedbackDetail == '')
    // {
    //   console.log('hiiiiiiiii');
    // }

    // Dropdown
    if (this.dialogAction == 'add-document' || this.dialogAction == 'edit-document') {
      if (this.DocumentDropDownList.length == 0) {
        let ducumentList = await this.DocumentTypeDropDownList();
        this.DocumentDropDownList = ducumentList.Result;
      }


    }
    // if (this.dialogAction == 'edit-document'){
    //   let ducumentList = await this.DocumentTypeDropDownList();
    //   this.DocumentDropDownList = ducumentList.Result;

    // }
    // if(this.ReferenceModeDropDown.length == 0){
    //   let ReferenceModelist = await this.ReferenceModeList();
    //   this.ReferenceModeDropDown = ReferenceModelist.Result;
    // }


    if (this.dialogAction != 'edit-document' && this.dialogAction != 'add-document') {
      if ((this.dialogAction == 'add-contact-client' || this.dialogAction == 'edit-contact-client') || (this.dialogAction == 'add-contact' || this.dialogAction == 'edit-contact')) {
        if (this.DesignationList.length == 0) {
          let Designation = await this.DesignationDropDownList();
          this.DesignationList = Designation.Result;
        }
        if (this.DepartmentDropDown.length == 0) {
          let DepartmentList = await this.ClientDepartmentList();
          this.DepartmentDropDown = DepartmentList.Result;
        }
        if (this.CountryDropDownlist.length == 0) {
          let country = await this.Countrylist();
          this.CountryDropDownlist = country;
          console.log(this.CountryDropDownlist);

        }
        this.getDropdownList();
      }


    }
    if (this.dialogAction == 'Decline-Lead') {
      this.getResonList();
    }
    if (this.dialogAction == 'Decline-Lead-head') {
      this.getResonList();
    }
    await this.spinner.hide()
  }
  //dropdown
  DocumentTypeDropDownList(): Promise<any> {
    return this.dropdownService.DocumentTypeList({}).toPromise()
  }
  ClientDepartmentList(): Promise<any> {
    return this.dropdownService.GetClientDepartmentList({}).toPromise()
  }
  DesignationDropDownList(): Promise<any> {
    return this.dropdownService.DesignationList({}).toPromise()
  }
  Countrylist(): Promise<any> {
    return this.dropdownService.CountryDropDownlist({}).toPromise()
  }
  ReferenceModeList(): Promise<any> {
    return this.dropdownService.ReferenceModeDropDown({}).toPromise();
  }

  deviceclose() {
    this.dialogRef.close()
  }
  getDropdownList() {
    this.dropdownService.CountryDropDownlist({})
      .subscribe(response => {
        console.log(response)
        this.dropDown = response;
        if (response.Success) {
          if (this.dialogAction == 'edit-contact' || this.dialogAction == 'edit-contact-client') {
            this.mobileDigit = this.dropDown.Result.filter(x => x.CountryCode == this.addContactDetail.Mobile.ISDCode)[0].MobileDigit;
          }
        }
      });
  }
  getResonList() {
    this.masterService.businessHeadServicelead({}, 'LeadDeclineDropDownList')
      .subscribe(response => {
        console.log(response)
        if (response.body) {
          this.ReasonDropDown = response.body;
        }
      });
  }
  compareFn(a: any, b: any) {
    if (b) {
      const d = a[Object.keys(a)[0]];
      const e = b[Object.keys(b)[0]];
      return d === e;
    }
  }
  feedbackDetail = () => {
    // this.route.queryParams.subscribe(params => {
    //   console.log(params)
    //   this.masterService.postService({ RawDataId: this.rawData_id }, 'FeedBackDropdown')
    //     .subscribe(response => {
    //       console.log(response)
    //       this.personContact = response.body.ContactPersonList;
    //       this.reminderBefore = response.body.DesignationList
    //     });
    // });
  }
  addContactDetails() {
    console.log(this.addContactDetail)
    this.dialogRef.close(this.addContactDetail)

  }
  addDocumentDetails() {
    console.log(this.addDocumentDetail)
    if(this.addDocumentDetail.DocumentFile?.DocumentFileName){

      this.dialogRef.close(this.addDocumentDetail)
      this.addDocumentDetail.valid =true
    }
    else{
      this.addDocumentDetail.valid =false
    }
  }
  saveContactLead() {
    if (this.form.valid) {
      this.leadnewContact.prospectId = this.prospectId;
      //this.leadnewContact.RegisteredAddress.ContactDetailList.push(this.leadContacts)
      console.log(this.leadnewContact)
      this.masterService.leadSalesService(this.leadnewContact, 'AddNewContact')
        .subscribe(response => {
          console.log(response)
          if (response.body.Status = 200) {
            console.log('hello')
            this.dialogRef.close();
          }
        });
    }
  }
  saveContact() {
    if (this.form.valid) {
      /*     this.newContact.prospectId = this.prospectId */
      this.newContact.RawDataId = this.rawData_id
      console.log(this.newContact)
      this.masterService.postService(this.newContact, 'AddNewContact')
        .subscribe(response => {
          console.log(response)
          if (response.body.Status = 200) {
            console.log('hello')
            this.dialogRef.close();
          }
        });
    }
  }
  DeclineLeadbyhead() {
    this.DeclineDetail.LeadId = parseInt(this.LeadId);
    this.DeclineDetail.UserRoleId = this.user.UserRole.UserRoleId;
    console.log(this.DeclineDetail)
    this.dialogReference = this.dialog.open(CommonModalComponent, {
      width: "300px",
      disableClose: true,
      data: { action: "delete", statusText: "Are you sure you want to decline this lead?" },
    });
    this.dialogReference.afterClosed().subscribe((result) => {
      if (result) {
        this.masterService.leadSalesManager(this.DeclineDetail, 'LeadDecline')
          .subscribe(response => {
            console.log(response)
            if (response.body.Success) {
              this.dialogRef.close(true);
            }
          });
      }
    });
  }
  fileUploadRemove(data) {
    data.DocumentFileName = '';
    data.imagePreview = '';
    data.BusinessCards = '';
    data.FileExe = '';
    this.myFileInput.nativeElement.value = '';
  }
  fileUpload(event, data) {
    this.appService.filesizeValidation(event).subscribe(res => this.fileSizeError = res);

    if (this.fileSizeError == '') {

      const files = event.target.files;

      const file = files[0];
      if (files && file) {
        const fileName = file.name;
        this.FileUpload = fileName;


        const fileExe = fileName.split('.').pop();
        let reader = new FileReader();
        reader.onload = (e: any) => {
          let imagePreview = e.target.result;
          let b;
          b = imagePreview.split('base64,');
          data.DocumentFileName = fileName;
          data.imagePreview = imagePreview;
          data.FileData = b[1];
          data.FileExe = fileExe;
        }
        reader.readAsDataURL(file);
      }
    }
  }
  DeclineLead() {
    this.DeclineDetail.LeadId = parseInt(this.LeadId);
    this.DeclineDetail.UserRoleId = this.user.UserRole.UserRoleId;
    console.log(this.DeclineDetail)
    this.dialogReference = this.dialog.open(CommonModalComponent, {
      width: "300px",
      disableClose: true,
      data: { action: "delete", statusText: "Are you sure you want to decline this lead?" },
    });
    this.dialogReference.afterClosed().subscribe((result) => {
      if (result) {
        this.masterService.leadSalesManager(this.DeclineDetail, 'LeadDecline')
          .subscribe(response => {
            console.log(response)
            if (response.body.Success) {
              this.dialogRef.close(true);
            }
          });
      }
    });
  }
  findCountryCode(event) {
    const id = this.dropDown.filter(x => x.CountryCode == event)[0];
    console.log(id);
    this.countryId = id.CountryId;
    this.mobileDigit = id.MobileDigit;
    console.log(this.mobileDigit);

  }

  downloadMyFile(id, data, b) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    if (data?.FileData) {
      link.setAttribute('href', data?.imagePreview);
    } else {
      this.loginService.getDocument({ DocumentId: id?.DocumentId }).subscribe(res => {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'data:image/png;base64,' + res?.body?.documentData);
        // link.setAttribute('href', data.DocumentPath);
        link.setAttribute('download', id?.DocumentFile?.DocumentFileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
    }
    link.setAttribute('download', id?.DocumentFile?.imagePreview);
    document.body.appendChild(link)
    link.click();
    link.remove();

  }
  onClick(event) {
    this.appService.filesizeValidation(event).subscribe(res => this.fileSizeError = res);
    console.log(this.fileSizeError);

    if (this.fileSizeError == '') {

      const files = event.target.files;
      console.log(event)
      const fileUploaded = document.getElementById('fileUploaded') as HTMLInputElement;
      // fileUploaded.onchange = () => {
      for (let index = 0; index < fileUploaded.files.length; index++) {
        const file = fileUploaded.files[index];
        console.log(file)
        this.files.push({
          data: file, state: 'in',
          inProgress: false, progress: 0, canRetry: false, canCancel: true
        });
      }
      this.uploadFiles();
      // };
      //fileUploaded.click();
    }
  }
  public uploadFiles() {
    const fileUploaded = document.getElementById('fileUploaded') as HTMLInputElement;
    fileUploaded.value = '';

    this.files.forEach(file => {
      this.uploadFile(file);
      console.log(file)
    });
  }
  //Upload ,add & remove files
  /** Link text */
  @Input() text = 'Upload';
  /** Name used in form which will be sent in HTTP request. */
  @Input() param = 'file';
  /** Target URL for file uploading. */
  @Input() target = 'https://file.io';
  /** File extension that accepted, same as 'accept' of <input type="file" />.
      By the default, it's set to 'image/*'. */
  @Input() accept = 'image/*';
  /** Allow you to add handler after its completion. Bubble up response text from remote. */
  @Output() complete = new EventEmitter<string>();
  folder: any[] = [];
  public files: Array<FileUploadModel> = [];

  public uploadFile(file: FileUploadModel) {
    console.log(file)
    const f = new FormData();
    f.append(this.param, file.data);

    const req = new HttpRequest('POST', this.target, f, {
      reportProgress: true
    });

    file.inProgress = true;
    file.sub = this._http.request(req).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      tap(message => { }),
      last(),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        file.canRetry = true;
        return of(`${file.data.name} upload failed.`);
      })
    ).subscribe(
      (event: any) => {
        if (typeof (event) === 'object') {
          const type = file.data.name.split('.').pop();
          this.folder.push(Object.assign(file.data, { fileType: type }));
          const finelLenght = this.folder.length;
          if (finelLenght > 0) {

            this.finalFile[0] = this.folder[finelLenght - 1];
            console.log(this.finalFile[0])
            this.addDocumentDetail.DocumentFile.DocumentFileName = this.finalFile[0].name;
            this.addDocumentDetail.fileType = type;
            console.log(this.finalFile[0])
            const reader = new FileReader();
            reader.onload = this._documentLogo.bind(this);
            reader.readAsBinaryString(this.finalFile[0]);
            console.log(reader)
          } else {
            this.finalFile[0] = this.folder;
          }
          this.removeFileFromArray(file);
          this.complete.emit(event.body);
        }
      }
    );
  }
  _documentLogo(readerEvt: any) {
    const binaryString = readerEvt.target.result;
    const base64textString = btoa(binaryString);
    this.addDocumentDetail.DocumentFile.FileData = base64textString;
    if (base64textString) {
      this.Imageshow = false;
    }
  }

  public removeFileFromArray(file: FileUploadModel) {
    const index = this.files.indexOf(file);
    if (index > -1) {
      this.files.splice(index, 1);
    }
  }
  retryFile(file: FileUploadModel) {
    this.uploadFile(file);
    file.canRetry = false;
  }
  deleteFile(i) {
    this.finalFile.splice(i, 1);
  }
  async onSubmit() {
    this.submitted = true;
    if (this.registerForm.get('confirmPassword').errors) {
      return;

    } else {

      this.changePassword.EmployeeOldPassword = this.registerForm.value.currentPassword;
      this.changePassword.EmployeeNewPassword = this.registerForm.value.password;
      await this.loginService.postEmployee(this.changePassword, 'ChangeEmployeePassword').subscribe(res => {
        this.responseDropdown = res.body;
        this.successOrFailureMsg()
      })
    }
  }
  successOrFailureMsg() {
    if (this.responseDropdown.Result.TransactionMessage.Status == 200) {
      this.dialogRef.close(this.responseDropdown);
      const dialogRef = this.dialog.open(CommonModalComponent, {
        width: "300px",
        disableClose: true,
        data: { action: "success", statusText: this.responseDropdown.Result.TransactionMessage.Message },
      });
    } else {
      const dialogRef = this.dialog.open(CommonModalComponent, {
        width: "300px",
        disableClose: true,
        data: { action: "delete", statusText: this.responseDropdown.Result.TransactionMessage.Message },
      });
    }
  }
}
export class FileUploadModel {
  data: File;
  state: string;
  inProgress: boolean;
  progress: number;
  canRetry: boolean;
  canCancel: boolean;
  sub?: Subscription;
}
