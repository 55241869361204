<div class="content new-content">
        <div class="page-heading">
            <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
                    <h1>Notification</h1>
                </div>
                <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
                    <button class="back_row" mat-button (click)="goBack()">
                        <mat-icon class="back_arrow">keyboard_arrow_left</mat-icon> Back 
                    </button>
                </div>
            </div>
        </div>

        <div class="content_body">
                <div class="border">
                  <div class="owner_details">
                    <div *ngFor="let alert of alertList.NotificationList">
                      <div fxLayout="row" class="d-flex bd-highlight mb-3 rowborder">
                        <div class="bd-highlight d-flex align-items-center">
                          <img *ngIf="!alert.AssignedToImageM?.ImageFilePath" src="assets/images/avatars/alice.jpg"
                            class="avatar img">
                          <img *ngIf="alert.AssignedToImageM?.ImageFilePath" [src]="alert.AssignedToImageM.ImageFilePath"
                            class="avatar img">
                        </div>
                        <div style="margin: 0 10px"> 
                          <p class="head">
                            <strong *ngIf="alert.BusinessProcessMaster.BusinessProcessMasterId === 2">Lead: </strong>
                            <strong *ngIf="alert.BusinessProcessMaster.BusinessProcessMasterId === 3">Project: </strong>
                            <strong *ngIf="alert.BusinessProcessMaster.BusinessProcessMasterId === 4">Service: </strong> <span class="pcontent"
                              *ngIf="alert.BusinessProcess">{{alert.BusinessProcess.BusinessProcessName}}</span></p>
                          <p class="mb-0 subhead">{{alert.Description}}</p>
                          <!-- <p class="head"><strong>Deadline:</strong> <span class="pcontent">{{alert.Deadline | dateFormat}}</span>
                          </p> -->
                        </div>
          
                        <div class="ml-auto timeago">
                          <p>{{alert.TimeAgo}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center mt-16">
                  <button *ngIf="end < alertList.NotificationList?.length && alertList.NotificationList?.length > 10" (click)="loadmoreAlert()" mat-raised-button
                    color="accent" type="button">
                    Load More Alerts
                  </button>
                </div>
              </div>

</div>