<div class="content">
    <div class="content_body">
        <div class="tax-invoice">
            <table class="table">
                <tr>
                    <td class="text-heading"><strong class="d-flex align-items-center justify-content-center"><img
                                src="/assets/images/vertexLogo.svg" alt=""> VERTEXPLUS
                            TECHNOLOGIES PRIVATE LIMITED</strong></td>
                </tr>
                <tr>
                    <td class="text-bold text-black">Employee's Income Tax Declaration Form for the Financial Year 2021-22
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="d-flex">
                            <p class="mr-5"><span class="text-black font-weight-bold">Name:</span> {{getModal?.EmployeeName}}
                            </p>
                            <p><span class="text-black font-weight-bold">Employee Code:</span> {{getModal?.EmployeeOfficialDetail?.EmployeeCodeNew}}
                            </p>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="inside-td">
                        <table class="inside-table">
                            <tr>
                                <td class="text-black">
                                    <span class="text-danger">*</span>Name as per PAN Card
                                </td>
                                <td width="400">{{getModal?.EmployeeName}}</td>
                                <td class="text-black">
                                    <span class="text-danger">*</span>PAN No
                                </td>
                                <td>{{getModal?.PANNo}}</td>
                            </tr>
                            <tr>
                                <td class="text-black">
                                    <span class="text-danger">*</span>Department
                                </td>
                                <td width="400">{{getModal?.EmployeeOfficialDetail?.Department?.DepartmentName}}</td>
                                <td class="text-black">
                                    <span class="text-danger">*</span>Current Working Location
                                </td>
                                <td *ngIf="getModal?.EmployeeOfficialDetail?.EmploymentLocation?.BillingFrom">{{getModal?.EmployeeOfficialDetail?.EmploymentLocation?.BillingFrom}}</td>
                                <td *ngIf="!getModal?.EmployeeOfficialDetail?.EmploymentLocation?.BillingFrom">NA</td>
                            </tr>
                            <tr>
                                <td class="text-black">
                                    <span class="text-danger">*</span>DOJ
                                </td>
                                <td width="400" *ngIf="getModal?.EmployeeOfficialDetail?.DateOfJoining">{{getModal?.EmployeeOfficialDetail?.DateOfJoining}}</td>
                                <td width="400" *ngIf="!getModal?.EmployeeOfficialDetail?.DateOfJoining">NA</td>
                                <td class="text-black">
                                    <span class="text-danger">*</span>DOB
                                </td>
                                <td>{{getModal?.DOB}}</td>
                            </tr>
                            <tr>
                                <td class="text-black">
                                    <span class="text-danger">*</span>Email
                                </td>
                                <td width="400">{{getModal?.OfficialEmailId}}</td>
                                <td class="text-black">
                                    <span class="text-danger">*</span>Contact No.
                                </td>
                                <td>{{getModal?.OfficialContactNo?.ISDCode}} {{getModal?.OfficialContactNo?.MobileNo}}</td>
                            </tr>
                            <tr>
                                <td class="text-black">
                                    <span class="text-danger">*</span>Tax Regime
                                </td>
                                <td width="400">
                                    <mat-radio-group [(ngModel)]="modalsave.taxRegime" name="taxRegime">
                                        <mat-radio-button value="1">Old</mat-radio-button>
                                        <mat-radio-button value="2" class="ml-3">New</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td class="text-black">
                                    &nbsp;
                                </td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="text-black">&nbsp;</td>
                                <td width="400">&nbsp;</td>
                                <td class="text-black">&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td class="text-black">As per {{modalsave.taxRegime == '1' ? 'Old' : 'New'}} Tax Regime, alternative tax rates slab for individuals have been intoduced.Any individual or HUF who exercises such option shall not be eligible to claim various exemptions or deductions
                        available under the Act including the following:-
                    </td>
                </tr>
                <tr>
                    <td class="inside-td">
                        <table class="inside-table">
                            <tr>
                                <td class="text-center text-black" width="100">1</td>
                                <td>Standard deduction of Rs.50,000.</td>
                            </tr>
                            <tr>
                                <td class="text-center text-black" width="100">2</td>
                                <td>Leave Travel Allowance under Section 10(5).</td>
                            </tr>
                            <tr>
                                <td class="text-center text-black" width="100">3</td>
                                <td>House Rent Allowance under Section 10(13A).</td>
                            </tr>
                            <tr>
                                <td class="text-center text-black" width="100">4</td>
                                <td>Certain allowances under Section 10(14) as will be prescribed.</td>
                            </tr>
                            <tr>
                                <td class="text-center text-black" width="100">5</td>
                                <td>Deduction of interest up to Rs.2,00,000/- allowable under Section 24(b) in respect of self occupied property.</td>
                            </tr>
                            <tr>
                                <td class="text-center text-black" width="100">6</td>
                                <td>Deduction of 1/3rd of family pension allowable under Section 57(iia).</td>
                            </tr>
                            <tr>
                                <td class="text-center text-black" width="100">7</td>
                                <td>All deductions allowed under Chapter VI-A (except the deduction under Section 80 CCD(2) and Section 80 JJAA ) including of Rs. 1,50,000/- under Section 80C in respect of contribution to provident fund, life insurance premium
                                    and deduction of Rs.50,000/- as contribution to NPS under Section 80CCD (1B).</td>
                            </tr>
                            <tr>
                                <td class="text-center text-black" width="100">8</td>
                                <td>Allowance for Minor Child Income allowable under Section 10(32) on clubbing of minor income.
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td class="inside-td">
                        <table class="inside-table">
                            <tr class="thead-bg">
                                <td class="text-center text-black text-bold" width="100">S. No.</td>
                                <td class="text-center text-black text-bold">Details</td>
                                <td class="text-center text-black text-bold">AMOUNT(RS.)</td>
                                <td class="text-center text-black text-bold">Proof Document Required</td>
                            </tr>
                            <tr>
                                <td height="20" align="center" valign=middle>A</td>
                                <td align="left" valign=middle>
                                    HRA: RENT PAID PER MONTH ( Please mention the starting and ending month)
                                </td>
                                <td valign=middle>Rent (p.m.)</td>
                                <td align="left" valign=middle></td>
                            </tr>
                            <tr>
                                <td height="25" align="center" valign=middle>(if any)</td>
                                <td align="left" valign=middle>Period:</td>
                                <td align="left" valign=middle><br></td>
                                <td rowspan=3 align="left" valign=top>Original Rent Receipts every month (with Revenue Stamp above Rs. 4999/-) or Rent Agreement. Receipt should contain PAN of Landlord if Rent for the year exceeds one Lakh.</td>
                            </tr>
                            <tr>
                                <td height="18" align="center" valign=middle>
                                    <br>
                                </td>
                                <td align="left" valign=middle>
                                    <br>
                                </td>
                                <td align="left" valign=middle>
                                    <br>
                                </td>
                            </tr>
                            <tr>
                                <td height="21" align="center" valign=middle>
                                    <br>
                                </td>
                                <td align="left" valign=middle>Total Rent Paid (per annum)</td>
                                <td align="left" valign=middle><br></td>
                            </tr>
                            <tr>
                                <td>B</td>
                                <td>INVESTMENTS U/S 80C, capped at Rs 1.5 Lac</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Employees Contribution under New Pension Scheme 80CCD(1)(max upto Rs. 1,50,000)</td>
                                <td><input type="text" [(ngModel)]="modalsave.employeesContribution" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>Receipts / Statement</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Public Provident Fund(ppf/rpf)</td>
                                <td><input type="text" [(ngModel)]="modalsave.providentFund" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>Receipts / Statement</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Contribution to Certain Pension Funds</td>
                                <td><input type="text" [(ngModel)]="modalsave.pensionFunds" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>Certificate</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Principal Repayment of Loan (payable in F.Y. 2021-22)</td>
                                <td><input type="text" [(ngModel)]="modalsave.principalRepaymentofLoan" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>As per Provisional Bank Certificate</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Life Insurance Premium (including GIS contribtuion)(for self/spouce/children)</td>
                                <td><input type="text" [(ngModel)]="modalsave.lifeInsurancePremium" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>Receipts / Statement, </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Allowable Term Deposit with Schedule Bank(more then 5 years)</td>
                                <td><input type="text" [(ngModel)]="modalsave.termDeposit" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>Certificate</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>National Saving Scheme / Certificate</td>
                                <td><input type="text" [(ngModel)]="modalsave.nationalSaving" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>Certificate</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>Mutual Fund (ELSS) (eligible fund under this section)</td>
                                <td><input type="text" [(ngModel)]="modalsave.mutualFund" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>Certificate / Acknowledgement</td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>Children Tuition Fees (max 2 children)</td>
                                <td><input type="text" [(ngModel)]="modalsave.childrenTuitionFees" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>Certificate / Copy of vouchers</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>Subscription to Infrastructure Bond/Notified bonds of NABARD</td>
                                <td><input type="text" [(ngModel)]="modalsave.subscriptiontoInfrastructureBondNotified" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>Deposite in sukanya samridhi account for girl child.</td>
                                <td><input type="text" [(ngModel)]="modalsave.depositeinSukanyaSamridhiAccount" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>Sum deposited in 10 year/15 year account of Post Office Saving Bank</td>
                                <td><input type="text" [(ngModel)]="modalsave.postOfficeSavingBank" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>13</td>
                                <td>Sec 80CCG - Investment in Rajiv Gandhi Equity Savings Scheme (RGESS), 2014, (max.- Rs. 25000)</td>
                                <td><input type="text" [(ngModel)]="modalsave.sec80CCGInvestmentinRajivGandhiEquity" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>Certficate from the depository.</td>
                            </tr>
                            <tr>
                                <td>14</td>
                                <td>Rajiv Gandhi Equity Scheme for investments in Equities- Lower of 50% of Amount invested or Rs. 25000 ( Only for Resident Indivisual whose Gross Total Income is less than 12 lakhs)</td>
                                <td><input type="text" [(ngModel)]="modalsave.rajivGandhiEquityScheme" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>Certificate / Receipts</td>
                            </tr>
                            <tr>
                                <td>15</td>
                                <td>Others, (Please specify if any)</td>
                                <td><input type="text" [(ngModel)]="modalsave.others" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>Certificate / Receipts</td>
                            </tr>
                            <tr>
                                <td class="text-bold text-black" colspan="2">Total Investments U/S 80C - limited to Rs 1,50,000/- only</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>16</td>
                                <td>Employees Contribution under New Pension Scheme 80CCD(1B) (Max Rs.50000.00 In Addition to u/s 80C)</td>
                                <td><input type="text" [(ngModel)]="modalsave.employeesContributionUnderNewPensionScheme80CCD" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>Receipts / Statement</td>
                            </tr>
                            <tr>
                                <td class="text-bold text-black">C</td>
                                <td class="text-bold text-black">OTHER PERMITTED DEDUCTIONS
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>80D - Medical Insurance Premium (Maximum Rs. 25,000 for insurance of self,spouce,dependent children)((Rs. 50,000 for senior citizens) And (In case o single Insurance Premium policy cover more then one year then Proportionate
                                    basis)
                                </td>
                                <td><input type="text" [(ngModel)]="modalsave.medicalInsurancePremium" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>Receipts / Statement</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>80 DD- Medical expense of medical disability of dependent family member(75000 in case of normal disability/1.25lakh in case of sever disability)</td>
                                <td><input type="text" [(ngModel)]="modalsave.medicalexpense80DD" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>medical certificate of disability from govt hospital+self declaration+ provide us Form no 10-IA.</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>80U-Permanent Physical Disability of individual himself (Normal Rs. 75000/- and Severe Rs.1,25,000/-)</td>
                                <td><input type="text" [(ngModel)]="modalsave.permanentPhysicalDisability80U" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>Certificate</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>80DDB - Expenditure on Medical Treatment for specified disease for self or dependent lower of Actual or Rs.40000(case of senior citizen Rs. 100000)</td>
                                <td><input type="text" [(ngModel)]="modalsave.expenditureonMedicalTreatment80DDB" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>For specific diseases only CERTIFICATE + expense vouchers</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>80E - Repayment of Interest against Educational Loan </td>
                                <td><input type="text" [(ngModel)]="modalsave.repaymentofInterestagainstEducationalLoan80E" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>Bank Certificate</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>80EEA Deduction in respect of Interest paid on Loan Taken for Acquisition of Residential House Property (Affordable Housing)</td>
                                <td><input type="text" [(ngModel)]="modalsave.housingDeduction80EEA" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>Interest Certificates from Bank</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>80G - Donations (only for Prime Minister’s National Relief Fund, the Chief Minister’s Relief Fund or the Lieutenant Governor’s Relief Fund)</td>
                                <td><input type="text" [(ngModel)]="modalsave.primeMinisterNationalReliefFundtheChiefMinisterReliefFundtheLieutenantGovernorReliefFund" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>Receipts/ 100% only if Proof submitted</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>80GGC- Contribution by Indivisual to Political Parties ( Amount Contributed But not allowed if amount paid in cash)</td>
                                <td><input type="text" [(ngModel)]="modalsave.contributionbyIndivisualtoPoliticalParties" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>SEC- 24 Interest on housing loan (self occupied property) max Rs 200000.</td>
                                <td><input type="text" [(ngModel)]="modalsave.seC24InterestonhousingLoan" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>Copy of provisional certificate received from the financial institution.</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>Others - (Please specify if any)</td>
                                <td><input type="text" [(ngModel)]="modalsave.othersPERMITTED" numbersOnly class="wid-100" placeholder="Write Here"></td>
                                <td>Related documents</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong class="text-black">Please Note:</strong><br><br>
                        <p>1. The date of Investments must be between 01-04-2021 to 31-03-2022.</p>
                        <p>2.Employees contribution to Provident Fund. - Employee's contribution is eligible for deduction u/s 80C which is included in the Rs.1,50,000 cap. You do not have to mention the PF contribution in the Investment cover sheet.</p>
                        <p>3. Deduction u/s 10(13A) for HRA is available only where employees have actually paid the rent. Original rent receipts may be retained at your end while xerox copies can be provided to Office.</p>
                        <p>4. Declaration should have PAN Number. Those who do not have PAN are requested to apply for it immediately and submit, otherwise deduction of TDS will be on higher side as per I T Act.
                        </p>
                        <p>5. Deduction under section 80C+80CCC+80CCD(1) can’t excedds Rs 150,000/- ( Section 80CCE)</p>
                        <p>6. Deduction u/s 80D shall be allowed only if the payment is made by any mode other than cash with proper documents.</p>
                        <p>7. The maximum deduction allowable u/s 80EEA is ₹150000/-. The deduction of up to ₹150000/- under section 80EEA is over and above the deduction available under section 24(b) in respect of interest payable on loan borrowed for acquisition
                            of a residential house property.Conditions to be fulfiiled for claimimg deduction under this section are:
                            <br> a) Stamp duty Value of house should be
                            <=R s 45 lakhs. b) Individual should not own any Residential house on the date of sanction of loan. c) Individual should not be eligible to claim deduction u/s 80EE. d) Loan should be sanctioned by the financial institution till 31-03-2022.</p>
                                <p>8. Maximum Deduction u/s 80D shall be allowed only upto RS 30,000/- in case of parents (Senior Citizen) & RS 25,000/- for Family, himself & parents (Non-Senior Citizen).
                                </p>
                                <p>9. Taxes withheld once, cannot be refunded. The Tax refund may be claimed from I-T authorities at the time of filing your return of income.</p>
                                <p>10. Kindly send all the supporting document(s), attached together serially as per the covering sheet, not later than 31 December 2021. (Do not send any document(s) separately)If the proof(s) of investment(s) do not reach
                                    till Payroll by due date, we shall be forced to consider only the proof(s) submitted (if any) till 31 December 2021 and re-compute the tax to be deducted for Jan 2022, February 2022 and March 2022.
                                </p>
                                <p>11. Copy of Form 16 or a certified computation of taxable income for previous employment period (during 2019-20) issued by the concerned employer along with signed form 12B.</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong class="text-black">DECLARATION</strong><br><br>
                        <p>I , declare that the above statement is true to the best of my knowledge and belief. In the event of any change that may occur during the year pertaining to the information given in the form, I undertake to inform the same to the
                            company , income tax arising due to failure , if any, for not making/not intimating payment/investment made or proposed to be made by me and/or any wrong declaration would be my responsibility.</p>
                        <p>I further undertake to provide all documentry proofs of payments / investments by 31 december 2021 and if I fail to do so , the company can make full deduction of income tax dues from my Jan 2022, Feb. 2022 and March 2022 salary.</p>
                    </td>
                </tr>
            </table>
            <button color="accent" (click)="submit()" mat-raised-button color="accent" class="mat_btn mt-20">Submit</button>
        </div>
    </div>
</div>