import { Component, Input, OnInit } from '@angular/core';
import { ServiceService } from 'app/main/core/masterservice/service.service';

@Component({
  selector: 'app-item-sidebar',
  templateUrl: './item-sidebar.component.html',
  styleUrls: ['./item-sidebar.component.scss']
})
export class ItemSidebarComponent implements OnInit {
  @Input() inventdata: any;
  ImagePath: any = '';
  constructor(
    private CoreService: ServiceService,
  ) { }

  ngOnInit(): void {

  }
  ngOnChanges() {
    console.log(this.inventdata);
    this.CoreService.getDocument({ DocumentId: this.inventdata?.PhotoDocumentId }).subscribe(res => {
      console.log(res);
      if (!res.body.documentData) {
        this.ImagePath = '/assets/images/no-image.jpg'
      } else {
        this.ImagePath = 'data:image/png;base64,' + res.body.documentData;
      }
    });
  }
}
