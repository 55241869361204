import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'app/main/core/masterservice/service.service';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataStorage } from 'app/store/storage';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  user: any;
  alertList: any = {};
  end = 10;

  constructor(
    private dashboardService: ServiceService,
    private locationn: Location,
    private spinner: NgxSpinnerService,
    private store: DataStorage 
  ) {
    this.user = JSON.parse(localStorage.getItem('userid'));
   }

  ngOnInit(): void {
    // this.alertsList();
    this.store.userRoleList();
    this.store.masterRoleList();
  }
  alertsList = () => {
    this.spinner.show();
    this.dashboardService.DashboardService({ IsView: true }, 'NotificationList') 
      .subscribe(response => {
    this.spinner.hide();
    this.alertList = response.body.Result;
      });
  }
  goBack(): void {
    this.locationn.back();
  }
  loadmoreAlert = () => {
    this.end = this.end + 10;
  }
}
