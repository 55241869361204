import { ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { PlanworkConfigService } from "@planwork/services/config.service";
import { Router } from '@angular/router';

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnDestroy {
  onConfigChanged: Subscription;
  planworkSettings: any;
  isLoad = false;
  loginData: any;
  normalView: boolean;
  constructor(private planworkConfig: PlanworkConfigService, public router: Router, private cdRef: ChangeDetectorRef) {
    // if (window.location.pathname === '/app/core') {
    //   this.isLoad = false;
    // }


    if (localStorage.getItem('token')) {
      this.loginData = localStorage.getItem('token');
      if (window.location.pathname == '/login') {
        localStorage.clear();
        // window.location.reload();
      }
      this.onConfigChanged = this.planworkConfig.onConfigChanged.subscribe(
        (newSettings) => {
          this.planworkSettings = newSettings;
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.onConfigChanged.unsubscribe();
  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit() {
    this.normalView = true;
    this.viewSet();
    $('#main_content_main_page').click(function () {
      $('.submenu').removeClass('d-block');
    })
  }
  sidebarClose() {
    this.planworkConfig.sidebarHide.next(1);
  }
  viewSet() {
    this.planworkConfig.viewType.subscribe(
      (view) => {
        view
        if (view == 'normal') {
          this.normalView = true;
          this.cdRef.detectChanges();
        }
        else {
          this.normalView = false;
        }
      }
    )
  }
}
