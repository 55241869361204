import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceService } from '../core/masterservice/service.service';

@Component({
  selector: 'app-tax-invoice',
  templateUrl: './tax-invoice.component.html',
  styleUrls: ['./tax-invoice.component.scss']
})
export class TaxInvoiceComponent implements OnInit {

  // taxRegime = '1';
  getModal: any = {};
  modalsave:any={
      taxRegime: '1',
      employeesContribution: null,
      providentFund: null,
      pensionFunds: null,
      principalRepaymentofLoan: null,
      lifeInsurancePremium: null,
      termDeposit: null,
      nationalSaving: null,
      mutualFund: null,
      childrenTuitionFees: null,
      subscriptiontoInfrastructureBondNotified: null,
      depositeinSukanyaSamridhiAccount: null,
      postOfficeSavingBank: null,
      sec80CCGInvestmentinRajivGandhiEquity: null,
      rajivGandhiEquityScheme: null,
      others: null,
      employeesContributionUnderNewPensionScheme80CCD: null,
      medicalInsurancePremium: null,
      medicalexpense80DD: null,
      permanentPhysicalDisability80U: null,
      expenditureonMedicalTreatment80DDB: null,
      repaymentofInterestagainstEducationalLoan80E: null,
      housingDeduction80EEA: null,
      primeMinisterNationalReliefFundtheChiefMinisterReliefFundtheLieutenantGovernorReliefFund: null,
      contributionbyIndivisualtoPoliticalParties: null,
      seC24InterestonhousingLoan: null,
      othersPERMITTED: null
    }

    ;
  constructor(
    private route: ActivatedRoute,
    private masterService: ServiceService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.getData(params.id);
    });
  }



  getData(id) {
    this.spinner.show();
    id = JSON.parse(id)
    this.masterService.postEmpTaxInvoice({ EmployeeId: id }, 'EmployeeGetByIdForTax').subscribe(response => {
      this.spinner.hide();
      this.getModal = response.body.Result;

     let DOB = moment(this.getModal.DOB).utcOffset(this.getModal.DOB)
      this.getModal.DOB = DOB.format('DD-MM-YYYY')
     let DOJ = moment(this.getModal.EmployeeOfficialDetail.DateOfJoining).utcOffset(this.getModal.EmployeeOfficialDetail.DateOfJoining)
      this.getModal.EmployeeOfficialDetail.DateOfJoining = DOJ.format('DD-MM-YYYY')


    });
  }
  submit(){
    console.log(this.modalsave);

    this.spinner.show();
    this.masterService.postEmpTaxInvoice(this.modalsave,'SaveEmployeeTaxDeatils').subscribe(response =>{
      this.spinner.hide();
      console.log(response.body.Result);

    })


  }

}
