import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mode-of-conveyance',
  templateUrl: './mode-of-conveyance.component.html',
  styleUrls: ['./mode-of-conveyance.component.scss']
})
export class ModeOfConveyanceComponent {

  obj = {
    heading: "Mode of Conveyance",
    addModalTitle: "Mode of Conveyance",
    addModelAction: "AddModeofConveyance",
    baseUrlType: 'Expense',
    editModalTitle: "Mode of Conveyance",
    editModelAction: "EditModeofConveyance",
    endPoint: "ModeofConveyanceList",
    addColumns: 1,
    colHeadings: { col1: 'Conveyance Name', col2:'conveyanceTypeName' },
    sortableColumns: [
      { data: '0', Title: '#' },
      { data: 'ConveyanceName', Title: 'Conveyance Name' },
      { data: 'ConveyanceName', Title: 'Conveyance Mode Type' },
      { data: '3', Title: 'Created on' },
      { data: '4', Title: 'Action' },
    ],
    deleteEndPoint: 'ModeofConveyanceDelete',
    getListObj: {
      ConveyanceName: '_blank'
    }
  }
}
