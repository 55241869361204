import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonModalComponent } from 'app/main/core/common/common-modal/common-modal.component';
import { planworkConfig } from 'app/planwork.config';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ClientipService } from './clientip.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiUrl: any;
  dialogRef: any;
  exceptionText = 'There are some exception error, Please contact to support team.';
  assetCoreSerivceUrl: any;
  constructor(
    public http: HttpClient,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private clientipService: ClientipService
  ) {
    this.apiUrl = planworkConfig;
    this.assetCoreSerivceUrl = this.apiUrl.baseUrl + this.apiUrl.AssetBaseUrl + 'Asset/';
  }
  //post
  postService(input, url): Observable<any> {
    input.IpAddress = this.clientipService.IPAddress;
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.apiUrl.baseUrl + url, input, {
      headers: httpHeaders,
      observe: 'response'
    }).pipe(
      catchError(err => {
        this.spinner.hide();
        this.dialogRef = this.dialog.open(CommonModalComponent, {
          width: '300px',
          disableClose: true,
          autoFocus: false,
          panelClass: 'statusClass',
          data: { action: 'delete', statusText: this.exceptionText }
        });
        return throwError(err);
      })
    );
  }
  assetCoreSerivce(input, url): Observable<any> {
    input.IpAddress = this.clientipService.IPAddress;
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.assetCoreSerivceUrl + url, input, {
      headers: httpHeaders,
      observe: 'response'
    }).pipe(
      catchError(err => {
        this.spinner.hide();
        this.dialogRef = this.dialog.open(CommonModalComponent, {
          width: '300px',
          disableClose: true,
          autoFocus: false,
          panelClass: 'statusClass',
          data: { action: 'delete', statusText: this.exceptionText }
        });
        return throwError(err);
      })
    );
  }
  //post
  postSimpleService(input, url): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.apiUrl.baseUrl + url, input, {
      headers: httpHeaders,
      observe: 'response'
    }).pipe(
      catchError(err => {
        this.spinner.hide();
        this.dialogRef = this.dialog.open(CommonModalComponent, {
          width: '300px',
          disableClose: true,
          autoFocus: false,
          panelClass: 'statusClass',
          data: { action: 'delete', statusText: this.exceptionText }
        });
        return throwError(err);
      })
    );
  }
}
