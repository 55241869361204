export const TableColumn1 = [
    { data: '0', Title: '#', width: 20, position: 1 },
    { data: 'Requestedon', Title: 'Requested on', position: 2 },
    { data: 'RequestedBy', Title: 'Requested By', position: 3 },
    { data: 'RequestedAmount', Title: 'Requested Amount', position: 4 },
    { data: 'ApprovedAmount', Title: 'Approved Amount', position: 6},
    { data: 'RequestedBefore', Title: 'Required Before', position: 5 },
    { data: 'Remark', Title: 'Remark', position:7},
    { data: 'Status', Title: 'Status', position: 8 },
    { data: '8', Title: 'Action', position: 9 }
];