<div *ngIf="dialogAction == 'add-Module'" class="prsn_dialog">
    <button class="closeModal" (click)="deviceclose()"><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <h2>Add New Module</h2>
        <div class="form_layout">
            <div fxLayout="row wrap">
                <div class="flex-item" fxFlex="0 1 100">
                    <mat-form-field appearance="outline">
                        <mat-label>Module Name</mat-label>
                        <input matInput>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Description</mat-label>
                        <textarea matInput></textarea>
                    </mat-form-field>
                </div>
                <div class="flex-item" fxFlex="0 1 100">
                    <button mat-flat-button color="primary">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="dialogAction == 'consumableIssue'" class="prsn_dialog">
    <button class="closeModal" (click)="deviceclose()"><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <form #f="ngForm">
    <div class="modal_section">
        <div class="d-flex justify-content-between">
            <h2>Issue</h2>
            <h3 style="font-weight: 600;">Required Qty. {{ModalData?.Data?.Quantity}} {{ModalData?.Data?.UOMName ? ModalData?.Data?.UOMName : 'NA'}}</h3>
        </div>
        <div class="form_layout">
            <div fxLayout="row wrap">
                <div class="flex-item" fxFlex="0 1 100">
                    <table cellspacing="0" cellpadding="0" class="table random_detail mt-0">
                        <thead>
                            <th>Warehouse</th>
                            <th>Available Qty.</th>
                            <th>Qty.(To be Issued)</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let list of companybranch">
                                <td>{{list?.BranchName}}</td>
                                <td>0000</td>
                                <td>
                                    <div class="formprefix position-relative">
                                        <input type="number" class="form-control-table" [(ngModel)]="list.QuantityToBeIssuue" name="QuantityToBeIssuue"
                                        #QuantityToBeIssuue="ngModel" required (ngModelChange)="getTotalIssueQty()">
                                        <span class="position-absolute">{{ModalData?.Data?.UOMName ? ModalData?.Data?.UOMName : 'NA'}}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="flex-item" fxFlex="0 1 100">
                    <button mat-flat-button color="primary"  [disabled]="f.form.invalid"
                    (click)="SaveConsumableInput()">Save</button>
                    <mat-error *ngIf="this.totalIssuesQty > this.ModalData?.Data?.Quantity">Issued qty can't be more than required qty</mat-error>
                </div>
            </div>
        </div>
    </div>
</form>
</div>

<div *ngIf="dialogAction == 'add-document'" class="prsn_dialog document_add">
    <button class="closeModal" (click)="deviceclose()"><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <h2>Add Document</h2>
        <div class="form_layout">
            <div fxLayout="column wrap">
                <div class="flex-item" fxFlex="0 1 100">
                    <mat-form-field appearance="outline">
                        <mat-label>Document Type</mat-label>
                        <mat-select>
                            <mat-option value="1">Word</mat-option>
                            <mat-option value="2">PDF</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Content</mat-label>
                        <textarea matInput type="textarea" name="Content" style="height: 102px;"></textarea>
                    </mat-form-field>
                    <div class="form-section">
                        <h5>Attach File</h5>
                        <div class="attach_file">
                            <img src="/assets/icons/attach_icon.svg" alt=""> Browse or drop file here
                        </div>
                        <div class="files_add">
                            <div class="add_file">
                                <div class="left_file">
                                    <span class="icon"><img src="/assets/icons/xls_icon.svg" alt=""></span>
                                    <span class="name">Records.xlsx</span>
                                </div>
                                <div class="right_file">
                                    <mat-progress-bar mode="determinate" value="60"></mat-progress-bar>
                                </div>
                            </div>
                            <div class="add_file">
                                <div class="left_file">
                                    <span class="icon"><img src="/assets/icons/xls_icon.svg" alt=""></span>
                                    <span class="name">Records.xlsx</span>
                                </div>
                                <div class="right_file">
                                    <span class="size">498 kb</span>
                                    <span class="remove">
                                        <mat-icon>close</mat-icon>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-item" fxFlex="0 1 100">
                    <button mat-flat-button color="primary">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="dialogAction == 'add-deliverable' || dialogAction == 'edit-deliverable'"
    class="prsn_dialog deliverable_modal">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section add-deliverableouter">
            <h2>Add Deliverable</h2>
            <div class="form_layout">
                <div fxLayout="row wrap" fxLayoutAlign="start start">
                    <div class="flex-item" fxFlex="0 0 70">
                        <mat-form-field appearance="outline">
                            <mat-label>Enter Deliverable Name</mat-label>
                            <input matInput placeholder="Enter Deliverable Name"
                                [class.error]="f.submitted && DeliverableName.invalid"
                                [(ngModel)]="DeliverableInput.DeliverableName" name="DeliverableName"
                                #DeliverableName="ngModel" required>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start start" class="deltype-add">
                    <div class="flex-item" fxFlex="0 0 100">
                        <mat-radio-group [(ngModel)]="DeliverableInput.DeliverableType" name="DeliverableType"
                            (change)="delTypeChange($event)">
                            <mat-radio-button *ngFor="let list of deliverableType" [value]="list.name">
                                {{list.name}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start start">
                    <div class="flex-item" fxFlex="0 0 100">
                        <h3>Deliverable Detail</h3>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start start">
                    <div class="flex-item" fxFlex="0 0 35">
                        <div class="unit_box">
                            <mat-form-field appearance="outline">
                                <mat-label>No. of Unit</mat-label>
                                <input matInput placeholder="Enter Deliverable Name"
                                    [class.error]="f.submitted && DeliverableUnits.invalid"
                                    [(ngModel)]="DeliverableInput.DeliverableUnits" name="DeliverableUnits"
                                    #DeliverableUnits="ngModel" required disabled>
                            </mat-form-field>
                            <span>Unit</span>
                        </div>
                    </div>
                </div>
                <div fxLayout="row wrap">
                    <div class="flex-item exception_mat_form" fxFlex="0 0 35">
                        <mat-form-field appearance="outline">
                            <mat-label>Duration</mat-label>
                            <input matInput type="number" [(ngModel)]="DeliverableInput.Duration" name="Duration"
                                disabled [class.error]="f.submitted && Duration.invalid" #Duration="ngModel" required
                                (change)="delstartDateCheck(DeliverableInput)">
                            <mat-select [compareWith]="compareFn" [(ngModel)]="DeliverableInput.DurationUnit"
                                name="DurationUnit" [class.error]="f.submitted && DurationUnit.invalid" disabled
                                placeholder="Select" #DurationUnit="ngModel" class="form-control" required
                                (selectionChange)="delstartDateCheck(DeliverableInput)">
                                <mat-option *ngFor="let customer of DeliverabledropDownList?.DurationUnitList"
                                    [value]="customer">
                                    {{customer.DurationUnitName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="15px">
                    <div class="flex-item" fxFlex="0 0 35">
                        <mat-form-field appearance="outline">
                            <mat-label>Start Date & Time</mat-label>
                            <input matInput [matDatepicker]="picker1" [(ngModel)]="DeliverableInput.StartDate"
                                name="StartDate" #StartDate="ngModel" required
                                (dateChange)="delstartDateCheck(DeliverableInput)">
                            <mat-datepicker-toggle matSuffix [for]="picker1">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 0 35">
                        <mat-form-field appearance="outline">
                            <mat-label>End Date & Time</mat-label>
                            <input matInput [matDatepicker]="picker2" [(ngModel)]="DeliverableInput.EndDate"
                                name="EndDate" #EndDate="ngModel" disabled>
                            <mat-datepicker-toggle matSuffix [for]="picker2">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="15px">
                    <div class="flex-item" fxFlex="0 0 35">
                        <mat-form-field appearance="outline">
                            <mat-label>Delivery Date</mat-label>
                            <input matInput [matDatepicker]="picker3" [(ngModel)]="DeliverableInput.DeliveryDate"
                                name="DeliveryDate" #DeliveryDate="ngModel">
                            <mat-datepicker-toggle matSuffix [for]="picker3">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker3></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 0 35">
                        <mat-form-field appearance="outline">
                            <mat-label>Delivery Cycle</mat-label>
                            <mat-select placeholder="Department" [compareWith]="compareFn"
                                [(ngModel)]="DeliverableInput.DeliveryCycle" name="DeliveryCycle"
                                *ngIf="DeliverableInput.DeliverableType != 'One Time'" disabled #DeliveryCycle="ngModel"
                                class="form-control" (selectionChange)="delstartDateCheck(DeliverableInput)">
                                <mat-option *ngFor="let Uni of DeliverabledropDownList?.PaymentTypeList " [value]="Uni">
                                    {{Uni?.PaymentTypeName}}</mat-option>
                            </mat-select>
                            <input matInput placeholder="Delivery Cycle" [(ngModel)]="DeliverableInput.DeliveryCycle"
                                name="DeliveryCycle" #DeliveryCycle="ngModel" disabled
                                *ngIf="DeliverableInput.DeliverableType == 'One Time'">
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 0 20">
                        <mat-form-field appearance="outline">
                            <mat-label>No. of Cycle</mat-label>
                            <input matInput placeholder="Enter Deliverable Name"
                                [(ngModel)]="DeliverableInput.NumberOfCycles" name="NumberOfCycles"
                                #NumberOfCycles="ngModel" disabled>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="15px">
                    <div class="flex-item exception_mat_form" fxFlex="0 0 35">
                        <mat-form-field appearance="outline">
                            <mat-label>Renewal Alert Before</mat-label>
                            <input matInput type="number" [(ngModel)]="DeliverableInput.RenewalAlertBefore"
                                name="RenewalAlertBefore" [class.error]="f.submitted && RenewalAlertBefore.invalid"
                                #RenewalAlertBefore="ngModel" required>
                            <mat-select [compareWith]="compareFn" [(ngModel)]="DeliverableInput.RenewalAlertBeforeUnit"
                                name="RenewalAlertBeforeUnit"
                                [class.error]="f.submitted && RenewalAlertBeforeUnit.invalid" placeholder="Select"
                                #RenewalAlertBeforeUnit="ngModel" class="form-control" required>
                                <mat-option *ngFor="let customer of DeliverabledropDownList?.DurationUnitList "
                                    [value]="customer">
                                    {{customer.DurationUnitName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="flex-item exception_mat_form" fxFlex="0 0 35">
                        <mat-form-field appearance="outline">
                            <mat-label>Delivery Reminder</mat-label>
                            <input matInput type="number" [(ngModel)]="DeliverableInput.DeliveryReminderBefore"
                                name="DeliveryReminderBefore"
                                [class.error]="f.submitted && DeliveryReminderBefore.invalid"
                                #DeliveryReminderBefore="ngModel" required>
                            <mat-select [compareWith]="compareFn"
                                [(ngModel)]="DeliverableInput.DeliveryReminderBeforeUnit"
                                name="DeliveryReminderBeforeUnit"
                                [class.error]="f.submitted && DeliveryReminderBeforeUnit.invalid" placeholder="Select"
                                #DeliveryReminderBeforeUnit="ngModel" class="form-control" required>
                                <mat-option *ngFor="let customer of DeliverabledropDownList?.DurationUnitList "
                                    [value]="customer">
                                    {{customer.DurationUnitName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="15px">
                    <div class="flex-item exception_mat_form" fxFlex="0 0 35">
                        <mat-form-field appearance="outline">
                            <mat-label>Commencement Time</mat-label>
                            <input matInput type="number" [(ngModel)]="DeliverableInput.CommencementTime"
                                name="CommencementTime" [class.error]="f.submitted && CommencementTime.invalid"
                                #CommencementTime="ngModel" required>
                            <mat-select [compareWith]="compareFn" [(ngModel)]="DeliverableInput.CommencementTimeUnit"
                                name="CommencementTimeUnit" [class.error]="f.submitted && CommencementTimeUnit.invalid"
                                placeholder="Select" #CommencementTimeUnit="ngModel" class="form-control" required>
                                <mat-option *ngFor="let customer of DeliverabledropDownList?.DurationUnitList "
                                    [value]="customer">
                                    {{customer.DurationUnitName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="flex-item exception_mat_form" fxFlex="0 0 35">
                        <mat-form-field appearance="outline">
                            <mat-label>Suspension Time</mat-label>
                            <input matInput type="number" [(ngModel)]="DeliverableInput.SuspensionTime"
                                name="SuspensionTime" [class.error]="f.submitted && SuspensionTime.invalid"
                                #SuspensionTime="ngModel" required>
                            <mat-select [compareWith]="compareFn" [(ngModel)]="DeliverableInput.SuspensionTimeUnit"
                                name="SuspensionTimeUnit" [class.error]="f.submitted && SuspensionTimeUnit.invalid"
                                placeholder="Select" #SuspensionTimeUnit="ngModel" class="form-control" required>
                                <mat-option *ngFor="let customer of DeliverabledropDownList?.DurationUnitList "
                                    [value]="customer">
                                    {{customer.DurationUnitName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="d-flex" fxLayoutGap="15px">
                    <div class="adddel-ofpercentage" class="flex-item exception_mat_form" fxFlex="0 0 45">
                        <div class="flex-item management  mr-5">
                            <h6>Additonal Charges</h6>
                            <div class="radio_box"
                                *ngIf="DeliverableInput.ManagementShow && DeliverableInput.Management.AdditionalChargeDigit">
                                {{DeliverableInput.Management.AdditionalChargeLabel}}
                            </div>
                            <div class="leinfo-notapp"
                                *ngIf="!DeliverableInput.ManagementShow || (DeliverableInput.Management.AdditionalChargeDigit == null ||  DeliverableInput.Management.AdditionalChargeDigit == 0)">
                                Not Applicable
                            </div>
                        </div>
                        <div class="flex-item management"
                            *ngIf="DeliverableInput.ManagementShow && DeliverableInput.Management.AdditionalChargeDigit"
                            fxFlex="0 0 40">
                            <div class="add">
                                <span>
                                    <input matInput type="number" placeholder="0.00"
                                        [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="DeliverableInput.Management.AdditionalChargeDigit"
                                        [name]="DeliverableInput.Management.AdditionalChargeDigit"></span>
                                <span>%</span>
                            </div>
                        </div>
                    </div>
                    <div class="adddel-ofpercentage ml-5" class="flex-item exception_mat_form" fxFlex="0 0 45">
                        <div class="flex-item management pb-0 mr-5">
                            <h6>Discounts</h6>
                            <div class="radio_box"
                                *ngIf="DeliverableInput.DiscountShow  && DeliverableInput.Discount.DiscountDigit">
                                {{DeliverableInput.Discount?.DiscountLabel}}
                            </div>
                            <div class="leinfo-notapp"
                                *ngIf="!DeliverableInput.DiscountShow || (DeliverableInput.Discount.DiscountDigit == null ||  DeliverableInput.Discount.DiscountDigit == 0)">
                                Not Applicable</div>
                        </div>
                        <div class="flex-item management"
                            *ngIf="DeliverableInput.DiscountShow && DeliverableInput.Discount.DiscountDigit"
                            fxFlex="0 0 40">
                            <div class="add">
                                <span>
                                    <input matInput type="number" placeholder="0.00"
                                        [(ngModel)]="DeliverableInput.Discount.DiscountDigit"
                                        [ngModelOptions]="{standalone: true}"
                                        [name]="DeliverableInput.Discount.DiscountDigit">
                                </span>
                                <span>%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button color="primary" [disabled]="f.form.invalid"
                            (click)="SaveDeliverableInput()">
                            <span *ngIf="dialogAction == 'add-deliverable'">Save</span>
                            <span *ngIf="dialogAction == 'edit-deliverable'">Update</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="dialogAction == 'add-category' || dialogAction == 'edit-category'" class="prsn_dialog add_category">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <div class="tag_name">{{SubHeading}}</div>
            <h2>Add Category</h2>
            <div class="form_layout">
                <div fxLayout="row wrap" fxLayoutAlign="start start">
                    <div class="flex-item" fxFlex="0 0 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Category Name</mat-label>
                            <input matInput placeholder="Enter Category Name"
                                [class.error]="f.submitted && ActivityName.invalid"
                                [(ngModel)]="CategoryInput.ActivityName" name="ActivityName" #ActivityName="ngModel"
                                required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Note</mat-label>
                            <textarea matInput placeholder="Note" [class.error]="f.submitted && Note.invalid"
                                [(ngModel)]="CategoryInput.Note" name="Note" #Note="ngModel" required></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button color="primary" [disabled]="f.form.invalid"
                            (click)="SaveCategoryInput()">
                            <span *ngIf="dialogAction == 'add-category'">Save</span>
                            <span *ngIf="dialogAction == 'edit-category'">Update</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="dialogAction == 'add-sub-category' || dialogAction == 'edit-sub-category'"
    class="prsn_dialog add_sub_category">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <div class="tag_name">{{SubHeading}}</div>
            <h2>Add Sub Category</h2>
            <div class="form_layout">
                <div fxLayout="row wrap" fxLayoutAlign="start start">
                    <div class="flex-item" fxFlex="0 0 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Sub Category Name</mat-label>
                            <input matInput placeholder="Enter Sub Category Name"
                                [class.error]="f.submitted && SubActivityName.invalid"
                                [(ngModel)]="SubCategoryInput.SubActivityName" name="SubActivityName"
                                #SubActivityName="ngModel" required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Note</mat-label>
                            <textarea matInput placeholder="Note" [class.error]="f.submitted && Note.invalid"
                                [(ngModel)]="SubCategoryInput.Note" name="Note" #Note="ngModel" required></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button color="primary" [disabled]="f.form.invalid"
                            (click)="SaveSubCategoryInput()">
                            <span *ngIf="dialogAction == 'add-sub-category'">Save</span>
                            <span *ngIf="dialogAction == 'edit-sub-category'">Update</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="dialogAction == 'add-activity' || dialogAction == 'edit-activity'" class="prsn_dialog add_activity">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="top_tag">Please add at least one activity to estimate the {{SubHeading}}</div>
        <div class="modal_section">
            <div class="tag_name">{{SubHeading}}</div>
            <h2>Add Activity</h2>
            <div class="form_layout">
                <div fxLayout="row wrap" fxLayoutAlign="start start">
                    <div class="flex-item" fxFlex="0 0 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Activity Name</mat-label>
                            <mat-select placeholder="Activity Name" required [compareWith]="compareFn"
                                [(ngModel)]="ActivityInput.ActivityName" name="ActivityName" #ActivityName="ngModel"
                                class="form-control" [class.error]="f.submitted && ActivityName.invalid">
                                <mat-option *ngFor="let Uni of ActivitydropDownList?.ActivityList"
                                    [value]="Uni.ActivityName">
                                    {{Uni?.ActivityName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Skill Category</mat-label>
                            <mat-select placeholder="Skill Category" required [compareWith]="compareFn"
                                [(ngModel)]="ActivityInput.SkillCategoryList" name="SkillCategoryList"
                                #SkillCategoryList="ngModel" class="form-control" multiple
                                [class.error]="f.submitted && SkillCategoryList.invalid">
                                <mat-option *ngFor="let Uni of DeliverabledropDownList.SkillCategoryList "
                                    [value]="Uni">
                                    {{Uni?.SkillCategoryName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="choose_catgry" *ngIf="ActivityInput?.SkillCategoryList">
                            <span
                                *ngFor="let item of ActivityInput.SkillCategoryList; let i=index">{{item.SkillCategoryName}}
                                <mat-icon (click)="removeSkills(ActivityInput.SkillCategoryList, i)">clear</mat-icon>
                            </span>
                        </div>
                        <mat-form-field appearance="outline">
                            <mat-label>Key Skills</mat-label>
                            <mat-select placeholder="Key Skills" required [compareWith]="compareFn"
                                [(ngModel)]="ActivityInput.KeySkillList" name="KeySkillList" #KeySkillList="ngModel"
                                class="form-control" multiple [class.error]="f.submitted && KeySkillList.invalid">
                                <mat-option *ngFor="let Uni of DeliverabledropDownList.KeySkillList " [value]="Uni">
                                    {{Uni?.KeySkillName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Dependency</mat-label>
                            <mat-select placeholder="Dependency" required [compareWith]="compareFn"
                                [(ngModel)]="ActivityInput.DependencyList" name="DependencyList"
                                #DependencyList="ngModel" class="form-control" multiple
                                [class.error]="f.submitted && DependencyList.invalid">
                                <mat-option *ngFor="let Uni of ActivitydropDownList?.ActivityList" [value]="Uni">
                                    {{Uni?.ActivityName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-checkbox color="primary" [(ngModel)]="ActivityInput.IsClientApproval"
                            name="IsClientApproval"
                            (change)="IsClientApprovalCheck($event, ActivityInput.IsClientApproval)">Approval Required
                            by Client</mat-checkbox>

                        <mat-form-field appearance="outline">
                            <mat-label>Description</mat-label>
                            <textarea matInput placeholder="Description" [(ngModel)]="ActivityInput.Note" name="Note"
                                #Note="ngModel" class="form-control" required
                                [class.error]="f.submitted && Note.invalid"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button color="primary" *ngIf="dialogAction == 'add-activity'"
                            [disabled]="f.form.invalid" (click)="SaveActivityInput()">Save</button>
                        <button mat-flat-button color="primary" *ngIf="dialogAction == 'edit-activity'"
                            [disabled]="f.form.invalid" (click)="SaveActivityInput()">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="dialogAction == 'add-sub-activity' || dialogAction == 'edit-sub-activity'" class="prsn_dialog add_activity">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="top_tag">Please add at least one activity to estimate the {{SubHeading}}</div>
        <div class="modal_section">
            <div class="tag_name">{{SubHeading}}</div>
            <h2>Add Sub Activity</h2>
            <div class="form_layout">
                <div fxLayout="row wrap" fxLayoutAlign="start start">
                    <div class="flex-item" fxFlex="0 0 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Sub Activity Name</mat-label>
                            <mat-select placeholder="Dependency" required [compareWith]="compareFn"
                                [(ngModel)]="SubActivityInput.SubActivityName" name="SubActivityName"
                                #SubActivityName="ngModel" class="form-control"
                                [class.error]="f.submitted && SubActivityName.invalid">
                                <mat-option *ngFor="let Uni of SubActivitydropDownList?.SubActivityList "
                                    [value]="Uni.SubActivityName">
                                    {{Uni?.SubActivityName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Dependency</mat-label>
                            <mat-select placeholder="Dependency" required [compareWith]="compareFn"
                                [(ngModel)]="SubActivityInput.DependencyList" name="DependencyList"
                                #DependencyList="ngModel" class="form-control" multiple
                                [class.error]="f.submitted && DependencyList.invalid">
                                <mat-option *ngFor="let Uni of ActivitydropDownList?.ActivityList" [value]="Uni">
                                    {{Uni?.ActivityName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-checkbox color="primary" [(ngModel)]="SubActivityInput.IsClientApproval"
                            name="IsClientApproval"
                            (change)="IsClientApprovalCheck($event, SubActivityInput.IsClientApproval)">
                            Approval Required by Client</mat-checkbox>
                        <mat-form-field appearance="outline">
                            <mat-label>Description</mat-label>
                            <textarea matInput placeholder="Description" [(ngModel)]="SubActivityInput.Note" name="Note"
                                #Note="ngModel" class="form-control" required
                                [class.error]="f.submitted && Note.invalid"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'add-sub-activity'" color="primary"
                            [disabled]="f.form.invalid" (click)="SaveSubActivityInput()">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'edit-sub-activity'" color="primary"
                            [disabled]="f.form.invalid" (click)="SaveSubActivityInput()">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="dialogAction == 'add-resource' || dialogAction == 'edit-resource'" class="prsn_dialog add_resource">
    <button class="closeModal" (click)="dialogRef.close(false)"> <img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <div class="tag_name">{{SubHeading}}</div>
            <h2>Add {{SubTitle}}</h2>
            <div class="form_layout">
                <div fxLayout="row wrap" fxLayoutAlign="start start">
                    <div class="flex-item" fxFlex="0 0 100">
                        <mat-form-field appearance="outline">
                            <mat-label>{{SubTitle}} Name</mat-label>
                            <input matInput placeholder="Enter {{SubTitle}} Name"
                                [class.error]="f.submitted && TaskName.invalid" [(ngModel)]="ResourceInput.TaskName"
                                name="TaskName" #TaskName="ngModel" required>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start start">
                    <div class="flex-item" fxFlex="0 0 60">
                        <mat-checkbox color="primary" [(ngModel)]="ResourceInput.IsClientApproval"
                            name="IsClientApproval"
                            (change)="IsClientApprovalCheck($event, ResourceInput.IsClientApproval)">
                            Approval Required by Client</mat-checkbox>
                    </div>
                    <div class="flex-item" fxFlex="0 0 40">
                        <mat-checkbox color="primary" [(ngModel)]="ResourceInput.IsInvoice" name="IsInvoice"
                            (change)="IsClientApprovalCheck($event, ResourceInput.IsInvoice)">
                            Print on Invoice</mat-checkbox>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start start" style="margin-bottom:10px">
                    <div class="flex-item" fxFlex="0 0 100">
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            [(ngModel)]="ResourceInput.TaskType" name="TaskType">
                            <mat-radio-button class="example-radio-button" color="primary"
                                *ngFor="let season of seasons" [value]="season">
                                {{season}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="onetime_part" *ngIf="ResourceInput.TaskType == 'One Time'">
                    <div fxLayout="row wrap" fxLayoutAlign="start center">
                        <div class="flex-item qty_detail" fxFlex="0 0 28">
                            <mat-form-field>
                                <label for="">Quantity/Unit</label>
                                <div class="qnty_box">
                                    <input matInput [class.error]="f.submitted && Quantity.invalid"
                                        [(ngModel)]="ResourceInput.Quantity" name="Quantity" #Quantity="ngModel"
                                        required>
                                    <mat-select required [compareWith]="compareFn"
                                        [(ngModel)]="ResourceInput.QuantityUnit" name="QuantityUnit"
                                        #QuantityUnit="ngModel" class="form-control"
                                        [class.error]="f.submitted && QuantityUnit.invalid">
                                        <mat-option *ngFor="let Uni of DeliverabledropDownList?.DurationUnitList "
                                            [value]="Uni">
                                            {{Uni?.DurationUnitName}}</mat-option>
                                    </mat-select>

                                </div>
                            </mat-form-field>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 10">
                            <div class="equal">
                                <mat-icon>clear</mat-icon>
                            </div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 28">

                            <label for="">Units</label>
                            <div class="disableArea">
                                <span class="disable">{{ResourceInput.DeliverableUnits}} Unit</span>
                            </div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 10">
                            <div class="equal">=</div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 24">

                            <label for="">Total Order Qty</label>
                            <div class="disableArea">
                                <span class="disable" *ngIf="ResourceInput.Quantity && ResourceInput.DeliverableUnits">
                                    {{getTotalorderQuantity(ResourceInput.Quantity, ResourceInput.DeliverableUnits)}}
                                    {{ResourceInput?.QuantityUnit?.DurationUnitName}}</span>
                                <span class="disable"
                                    *ngIf="!ResourceInput.Quantity || !ResourceInput.DeliverableUnits">
                                    0 {{ResourceInput?.QuantityUnit?.DurationUnitName}}</span>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="start center">
                        <div class="flex-item qty_detail" fxFlex="0 1 28">

                            <label for="">Total Order Qty</label>
                            <div class="disableArea">
                                <span class="disable" *ngIf="ResourceInput.Quantity && ResourceInput.DeliverableUnits">
                                    {{getTotalorderQuantity(ResourceInput.Quantity, ResourceInput.DeliverableUnits)}}
                                    {{ResourceInput?.QuantityUnit?.DurationUnitName}}</span>
                                <span class="disable"
                                    *ngIf="!ResourceInput.Quantity || !ResourceInput.DeliverableUnits">
                                    0 {{ResourceInput?.QuantityUnit?.DurationUnitName}}</span>
                            </div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 10">
                            <div class="equal">
                                <mat-icon>clear</mat-icon>
                            </div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 0 28">
                            <mat-form-field>
                                <label for="">Estimation Amount</label>
                                <div class="qnty_box">
                                    <input matInput [class.error]="f.submitted && Amount.invalid"
                                        [(ngModel)]="ResourceInput.Amount" name="Amount" #Amount="ngModel" required>
                                    <span class="disable">{{ResourceInput?.Currency?.CurrencySymbol}}</span>
                                    <!-- <mat-select placeholder="Unit" required  [compareWith]="compareFn"
                                    [(ngModel)]="ResourceInput.Currency" name="Currency"
                                    #Currency="ngModel" class="form-control"
                                    [class.error]="f.submitted && Currency.invalid">
                                    <mat-option *ngFor="let Uni of DeliverabledropDownList?.CurrencyList " [value]="Uni">
                                            {{Uni?.CurrencyName}}</mat-option>
                                    </mat-select> -->
                                </div>
                            </mat-form-field>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 10">
                            <div class="equal">=</div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 24">

                            <label for="">Total Efforts</label>
                            <div class="disableArea">
                                <span class="disable"
                                    *ngIf="ResourceInput.Quantity && ResourceInput.DeliverableUnits && ResourceInput.Amount">
                                    {{getTotalEfforts(ResourceInput.Quantity, ResourceInput.DeliverableUnits,
                                    ResourceInput.Amount)}}
                                    {{ResourceInput?.Currency?.CurrencySymbol}}
                                </span>
                                <span class="disable"
                                    *ngIf="!ResourceInput.Quantity || !ResourceInput.DeliverableUnits || !ResourceInput.Amount">
                                    0 {{ResourceInput?.Currency?.CurrencySymbol}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="start center">
                        <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                            <div class="requirement_section">
                                <input matInput placeholder="Requirements from Client"
                                    [(ngModel)]="ResourceInput.ClientRequirement" name="ClientRequirement"
                                    #ClientRequirement="ngModel">
                                <mat-icon (click)="AddClientRequirement(ResourceInput.ClientRequirement)">add</mat-icon>
                            </div>
                            <div class="require_tag">
                                <span *ngFor="let item of ResourceInput.ClientRequirementList; let i=index">{{item}}
                                    <mat-icon (click)="removeSkills(ResourceInput.ClientRequirementList, i)">clear
                                    </mat-icon>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="repeat_part" *ngIf="ResourceInput.TaskType == 'Repeat'">
                    <div fxLayout="row wrap" fxLayoutAlign="start center">
                        <div class="flex-item qty_detail" fxFlex="0 0 28">
                            <mat-form-field>
                                <label for="">Quantity/Unit</label>
                                <div class="qnty_box">
                                    <input matInput [class.error]="f.submitted && Quantity.invalid"
                                        [(ngModel)]="ResourceInput.Quantity" name="Quantity" #Quantity="ngModel"
                                        required>
                                    <mat-select required [compareWith]="compareFn"
                                        [(ngModel)]="ResourceInput.QuantityUnit" name="QuantityUnit"
                                        #QuantityUnit="ngModel" class="form-control"
                                        [class.error]="f.submitted && QuantityUnit.invalid">
                                        <mat-option *ngFor="let Uni of DeliverabledropDownList?.DurationUnitList "
                                            [value]="Uni">
                                            {{Uni?.DurationUnitName}}</mat-option>
                                    </mat-select>

                                </div>
                            </mat-form-field>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 10">
                            <div class="equal">
                                <mat-icon>clear</mat-icon>
                            </div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 28">

                            <label for="">Units</label>
                            <div class="disableArea">
                                <span class="disable">{{ResourceInput.DeliverableUnits}} Unit</span>
                            </div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 10">
                            <div class="equal">=</div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 24">
                            <label for="">Total Order Qty</label>
                            <div class="disableArea">
                                <span class="disable" *ngIf="ResourceInput.Quantity && ResourceInput.DeliverableUnits">
                                    {{getTotalorderQuantity(ResourceInput.Quantity, ResourceInput.DeliverableUnits)}}
                                    {{ResourceInput?.QuantityUnit?.DurationUnitName}}</span>
                                <span class="disable"
                                    *ngIf="!ResourceInput.Quantity || !ResourceInput.DeliverableUnits">
                                    0 {{ResourceInput?.QuantityUnit?.DurationUnitName}}</span>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="start center">
                        <div class="flex-item qty_detail" fxFlex="0 0 28">
                            <label for="">Total Qty/Cycle</label>
                            <div class="disableArea">
                                <span class="disable" *ngIf="ResourceInput.Quantity && ResourceInput.DeliverableUnits">
                                    {{getTotalorderQuantity(ResourceInput.Quantity, ResourceInput.DeliverableUnits)}}
                                    {{ResourceInput?.QuantityUnit?.DurationUnitName}}</span>
                                <span class="disable"
                                    *ngIf="!ResourceInput.Quantity || !ResourceInput.DeliverableUnits">
                                    0 {{ResourceInput?.QuantityUnit?.DurationUnitName}}</span>
                            </div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 10">
                            <div class="equal">
                                <mat-icon>clear</mat-icon>
                            </div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 28">

                            <label for="">No. Of Cycles</label>
                            <div class="disableArea">
                                <span class="disable">{{ResourceInput?.NumberOfCycles}}
                                    {{ResourceInput?.DeliveryCycle?.PaymentTypeName}}</span>
                            </div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 10">
                            <div class="equal">=</div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 24">

                            <label for="">Total Order Qty</label>
                            <div class="disableArea">
                                <span class="disable"
                                    *ngIf="ResourceInput.Quantity && ResourceInput.DeliverableUnits && ResourceInput.NumberOfCycles">
                                    {{getTotalorderQuantityRepeat(ResourceInput.Quantity,
                                    ResourceInput.DeliverableUnits,ResourceInput.NumberOfCycles)}}
                                    {{ResourceInput?.QuantityUnit?.DurationUnitName}}</span>
                                <span class="disable"
                                    *ngIf="!ResourceInput.Quantity || !ResourceInput.DeliverableUnits || !ResourceInput.NumberOfCycles">
                                    0 {{ResourceInput?.QuantityUnit?.DurationUnitName}}</span>

                                <!-- <span class="disable" *ngIf="ResourceInput.Quantity && ResourceInput.DeliverableUnits">
                                        {{getTotalorderQuantity(ResourceInput.Quantity, ResourceInput.DeliverableUnits)}} {{ResourceInput?.QuantityUnit?.DurationUnitName}}</span>
                                        <span class="disable" *ngIf="!ResourceInput.Quantity || !ResourceInput.DeliverableUnits">
                                                0 {{ResourceInput?.QuantityUnit?.DurationUnitName}}</span> -->
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="start center">
                        <div class="flex-item qty_detail" fxFlex="0 1 28">

                            <label for="">Total Order Qty</label>
                            <div class="disableArea">
                                <span class="disable"
                                    *ngIf="ResourceInput.Quantity && ResourceInput.DeliverableUnits && ResourceInput.NumberOfCycles">
                                    {{getTotalorderQuantityRepeat(ResourceInput.Quantity,
                                    ResourceInput.DeliverableUnits,ResourceInput.NumberOfCycles)}}
                                    {{ResourceInput?.QuantityUnit?.DurationUnitName}}</span>
                                <span class="disable"
                                    *ngIf="!ResourceInput.Quantity || !ResourceInput.DeliverableUnits || !ResourceInput.NumberOfCycles">
                                    0 {{ResourceInput?.QuantityUnit?.DurationUnitName}}</span>
                            </div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 10">
                            <div class="equal">
                                <mat-icon>clear</mat-icon>
                            </div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 0 28">
                            <mat-form-field>
                                <label for="">Amount</label>
                                <div class="qnty_box">
                                    <input matInput [class.error]="f.submitted && Amount.invalid"
                                        [(ngModel)]="ResourceInput.Amount" name="Amount" #Amount="ngModel" required>
                                    <span class="disable">{{ResourceInput?.Currency?.CurrencySymbol}}</span>
                                    <!-- <mat-select placeholder="Unit" required  [compareWith]="compareFn"
                                    [(ngModel)]="ResourceInput.Currency" name="Currency"
                                    #Currency="ngModel" class="form-control"
                                    [class.error]="f.submitted && Currency.invalid">
                                    <mat-option *ngFor="let Uni of DeliverabledropDownList?.CurrencyList " [value]="Uni">
                                            {{Uni?.CurrencyName}}</mat-option>
                                    </mat-select> -->
                                </div>
                            </mat-form-field>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 10">
                            <div class="equal">=</div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 24">

                            <label for="">Total Amount</label>
                            <div class="disableArea">
                                <span class="disable"
                                    *ngIf="ResourceInput.Quantity && ResourceInput.DeliverableUnits && ResourceInput.Amount && ResourceInput.NumberOfCycles">
                                    {{getTotalEffortsRepeat(ResourceInput.Quantity, ResourceInput.DeliverableUnits,
                                    ResourceInput.Amount, ResourceInput?.NumberOfCycles)}}
                                    {{ResourceInput?.Currency?.CurrencySymbol}}
                                </span>
                                <span class="disable"
                                    *ngIf="!ResourceInput.Quantity || !ResourceInput.DeliverableUnits || !ResourceInput.Amount || !ResourceInput.NumberOfCycles">
                                    0 {{ResourceInput?.Currency?.CurrencySymbol}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="start center">
                        <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                            <div class="requirement_section">
                                <input matInput placeholder="Requirements from Client"
                                    [(ngModel)]="ResourceInput.ClientRequirement" name="ClientRequirement"
                                    #ClientRequirement="ngModel">
                                <mat-icon (click)="AddClientRequirement(ResourceInput.ClientRequirement)">add</mat-icon>
                            </div>
                            <div class="require_tag">
                                <span *ngFor="let item of ResourceInput.ClientRequirementList; let i=index">{{item}}
                                    <mat-icon (click)="removeSkills(ResourceInput.ClientRequirementList, i)">clear
                                    </mat-icon>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="divider"></div>
                    <div fxLayout="row wrap" fxLayoutAlign="start start">
                        <div class="flex-item qty_detail" fxFlex="0 0 100">
                            <!-- <mat-form-field appearance="outline" class="delivery_section">
                                <select name="Skill Category" id="">
                                    <option value="0">Skill Category</option>
                                    <option value="0">IT Support</option>
                                    <option value="0">Service Management</option>
                                </select>
                            </mat-form-field>
                            <div class="require_tag">
                                <span>IT Support <mat-icon>clear</mat-icon></span>
                                <span>Service Management <mat-icon>clear</mat-icon></span>
                            </div> -->

                            <mat-form-field appearance="outline">
                                <mat-label>Skill Category</mat-label>
                                <mat-select placeholder="Skill Category" required [compareWith]="compareFn"
                                    [(ngModel)]="ResourceInput.SkillCategoryList" name="SkillCategoryList"
                                    #SkillCategoryList="ngModel" class="form-control" multiple
                                    [class.error]="f.submitted && SkillCategoryList.invalid">
                                    <mat-option *ngFor="let Uni of DeliverabledropDownList.SkillCategoryList "
                                        [value]="Uni">
                                        {{Uni?.SkillCategoryName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="choose_catgry" *ngIf="ResourceInput?.SkillCategoryList">
                                <span
                                    *ngFor="let item of ResourceInput.SkillCategoryList; let i=index">{{item.SkillCategoryName}}
                                    <mat-icon (click)="removeSkills(ResourceInput.SkillCategoryList, i)">clear
                                    </mat-icon>
                                </span>
                            </div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 0 100">
                            <!-- <mat-form-field appearance="outline" class="delivery_section">
                                <select name="Key Skills" id="">
                                    <option value="0">Key Skills</option>
                                    <option value="0">IT Support</option>
                                    <option value="0">Service Management</option>
                                </select>
                            </mat-form-field> -->
                            <mat-form-field appearance="outline">
                                <mat-label>Key Skills</mat-label>
                                <mat-select placeholder="Key Skills" required [compareWith]="compareFn"
                                    [(ngModel)]="ResourceInput.KeySkillList" name="KeySkillList" #KeySkillList="ngModel"
                                    class="form-control" multiple [class.error]="f.submitted && KeySkillList.invalid">
                                    <mat-option *ngFor="let Uni of DeliverabledropDownList.KeySkillList " [value]="Uni">
                                        {{Uni?.KeySkillName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="choose_catgry" *ngIf="ResourceInput?.KeySkillList">
                                <span *ngFor="let item of ResourceInput.KeySkillList; let i=index">{{item.KeySkillName}}
                                    <mat-icon (click)="removeSkills(ResourceInput.KeySkillList, i)">clear</mat-icon>
                                </span>
                            </div>
                        </div>



                        <!-- <div class="flex-item qty_detail" fxFlex="0 0 100">
                            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <div class="flex-item qty_detail" fxFlex="0 1 100">
                                    <label class="exp_range" for="">Experience Range</label>
                                </div>
                                <div class="flex-item qty_detail" fxFlex="0 0 47">
                                    <mat-form-field appearance="outline" class="delivery_section">
                                        <select name="From" id="">
                                            <option value="0">From</option>
                                        </select>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item qty_detail" fxFlex="0 0 50">
                                    <mat-form-field appearance="outline" class="delivery_section">
                                        <select name="To" id="">
                                            <option value="0">To</option>
                                        </select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 0 100">
                            <mat-form-field appearance="outline" class="delivery_section">
                                <input class="deliver_input" placeholder="Location" type="text">
                            </mat-form-field>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 0 100">
                            <mat-form-field appearance="outline" class="delivery_section">
                                <input class="deliver_input" placeholder="Salary Range Upto" type="text">
                            </mat-form-field>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 0 100">
                            <mat-form-field appearance="outline" class="delivery_section">
                                <textarea class="descrip_text" placeholder="Description/Notes" name="" id="" cols="30" rows="10"></textarea>
                            </mat-form-field>
                        </div> -->
                    </div>
                    <!-- <div fxLayout="row wrap" fxLayoutAlign="start center">
                        <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                            <div class="estimate_text">
                                <div class="total_qty">Estimated Amount <span>2000 INR</span></div>
                                <div class="total_qty">Total Qty <span>150 Days</span></div>
                            </div>
                        </div>
                        <table cellspacing="0" cellpadding="0" class="table random_detail">
                            <thead>
                                <tr>
                                    <th>Cycle</th>
                                    <th>Total Qty</th>
                                    <th>Total Qty/Cycle</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="date">
                                            <span>From</span> Apr 01, 2020
                                        </div>
                                        <div class="date">
                                            <span>To</span> Apr 30, 2020
                                        </div>
                                    </td>
                                    <td>30 Days</td>
                                    <td rowspan="3"><b>90 Days</b></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="date">
                                            <span>From</span> Apr 01, 2020
                                        </div>
                                        <div class="date">
                                            <span>To</span> Apr 30, 2020
                                        </div>
                                    </td>
                                    <td>30 Days</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="date">
                                            <span>From</span> Apr 01, 2020
                                        </div>
                                        <div class="date">
                                            <span>To</span> Apr 30, 2020
                                        </div>
                                    </td>
                                    <td>30 Days</td>
                                </tr>
                            </tbody>
                        </table>
                        <table cellspacing="0" cellpadding="0" class="table random_detail">
                            <tbody>
                                <tr>
                                    <td width="175">
                                        <div class="date">
                                            <span>From</span> Apr 01, 2020
                                        </div>
                                        <div class="date">
                                            <span>To</span> Apr 30, 2020
                                        </div>
                                    </td>
                                    <td width="99">30 Days</td>
                                    <td rowspan="3"><b>90 Days</b></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="date">
                                            <span>From</span> Apr 01, 2020
                                        </div>
                                        <div class="date">
                                            <span>To</span> Apr 30, 2020
                                        </div>
                                    </td>
                                    <td>30 Days</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="date">
                                            <span>From</span> Apr 01, 2020
                                        </div>
                                        <div class="date">
                                            <span>To</span> Apr 30, 2020
                                        </div>
                                    </td>
                                    <td>30 Days</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>  -->
                </div>
                <!-- <div class="random_part" *ngIf="ResourceInput.TaskType == 'Random'">
                    <div fxLayout="row wrap" fxLayoutAlign="start center">
                        <div class="flex-item qty_detail" fxFlex="0 0 30">
                            <mat-form-field appearance="outline">
                                <label for="">Total Qty/Cycle</label>
                                <div class="qnty_box">
                                    <input type="text" value="50" matInput>
                                    <mat-select placeholder="No.">
                                        <mat-option value="1">No.</mat-option>
                                    </mat-select>
                                </div>
                            </mat-form-field>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 10">
                            <div class="equal">
                                <mat-icon>clear</mat-icon>
                            </div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 25">
                            <mat-form-field>
                                <label for="">No. Of Cycles</label>
                                <span class="disable">6  Month</span>
                            </mat-form-field>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 10">
                            <div class="equal">=</div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 25">
                            <mat-form-field>
                                <label for="">Total Quantity</label>
                                <span class="disable">300 No.</span>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="start center">
                        <div class="flex-item qty_detail" fxFlex="0 1 25">
                            <mat-form-field>
                                <label for="">Quantity/Unit</label>
                                <span class="disable">300 No.</span>
                            </mat-form-field>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 10">
                            <div class="equal">
                                <mat-icon>clear</mat-icon>
                            </div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 0 30">
                            <mat-form-field>
                                <label for="">Units</label>
                                <span class="disable">6 Unit</span>
                            </mat-form-field>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 10">
                            <div class="equal">=</div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 25">
                            <mat-form-field>
                                <label for="">Total Order Qty</label>
                                <span class="disable">900 No.</span>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="start center">
                        <div class="flex-item qty_detail" fxFlex="0 1 25">
                            <mat-form-field>
                                <label for="">Total Order Qty</label>
                                <span class="disable">900 No.</span>
                            </mat-form-field>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 10">
                            <div class="equal">
                                <mat-icon>clear</mat-icon>
                            </div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 0 30">
                            <mat-form-field appearance="outline">
                                <label for="">Estimated Amount</label>
                                <div class="qnty_box">
                                    <input type="text" value="02" matInput>
                                    <mat-select placeholder="Hour">
                                        <mat-option value="1">Hour</mat-option>
                                    </mat-select>
                                </div>
                            </mat-form-field>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 1 10">
                            <div class="equal">=</div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 025">
                            <mat-form-field>
                                <label for="">Total Estimate</label>
                                <span class="disable">9,00,000 INR</span>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="start center">
                        <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                            <div class="requirement_section">
                                <input type="text" matInput placeholder="Requirements from Client">
                                <mat-icon>add</mat-icon>
                            </div>
                            <div class="require_tag">
                                <span>Product Photos <mat-icon>clear</mat-icon></span>
                                <span>Client Logos <mat-icon>clear</mat-icon></span>
                            </div>
                        </div>
                    </div>
                    <div class="divider"></div>
                    <div fxLayout="row wrap" fxLayoutAlign="start start">
                        <div class="flex-item qty_detail" fxFlex="0 0 100">
                            <mat-form-field appearance="outline" class="delivery_section">
                                <select name="Skill Category" id="">
                                    <option value="0">Skill Category</option>
                                    <option value="0">IT Support</option>
                                    <option value="0">Service Management</option>
                                </select>
                            </mat-form-field>
                            <div class="require_tag">
                                <span>IT Support <mat-icon>clear</mat-icon></span>
                                <span>Service Management <mat-icon>clear</mat-icon></span>
                            </div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 0 100">
                            <mat-form-field appearance="outline" class="delivery_section">
                                <select name="Key Skills" id="">
                                    <option value="0">Key Skills</option>
                                    <option value="0">IT Support</option>
                                    <option value="0">Service Management</option>
                                </select>
                            </mat-form-field>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 0 100">
                            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <div class="flex-item qty_detail" fxFlex="0 1 100">
                                    <label class="exp_range" for="">Experience Range</label>
                                </div>
                                <div class="flex-item qty_detail" fxFlex="0 0 47">
                                    <mat-form-field appearance="outline" class="delivery_section">
                                        <select name="From" id="">
                                            <option value="0">From</option>
                                        </select>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item qty_detail" fxFlex="0 0 50">
                                    <mat-form-field appearance="outline" class="delivery_section">
                                        <select name="To" id="">
                                            <option value="0">To</option>
                                        </select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 0 100">
                            <mat-form-field appearance="outline" class="delivery_section">
                                <input class="deliver_input" placeholder="Location" type="text">
                            </mat-form-field>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 0 100">
                            <mat-form-field appearance="outline" class="delivery_section">
                                <input class="deliver_input" placeholder="Salary Range Upto" type="text">
                            </mat-form-field>
                        </div>
                        <div class="flex-item qty_detail" fxFlex="0 0 100">
                            <mat-form-field appearance="outline" class="delivery_section">
                                <textarea class="descrip_text" placeholder="Description/Notes" name="" id="" cols="30" rows="10"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="start center">
                        <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                            <div class="estimate_text">
                                <div class="total_qty">Estimated Amount <span>2000 INR</span></div>
                                <div class="total_qty">Total Qty <span>150 Days</span></div>
                            </div>
                        </div>
                        <table cellspacing="0" cellpadding="0" class="table random_detail">
                            <thead>
                                <tr>
                                    <th>Cycle</th>
                                    <th>Total Qty</th>
                                    <th>Total Qty/Cycle</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="date">
                                            <span>From</span> Apr 01, 2020
                                        </div>
                                        <div class="date">
                                            <span>To</span> Apr 30, 2020
                                        </div>
                                    </td>
                                    <td>30 Days</td>
                                    <td rowspan="3"><b>90 Days</b></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="date">
                                            <span>From</span> Apr 01, 2020
                                        </div>
                                        <div class="date">
                                            <span>To</span> Apr 30, 2020
                                        </div>
                                    </td>
                                    <td>30 Days</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="date">
                                            <span>From</span> Apr 01, 2020
                                        </div>
                                        <div class="date">
                                            <span>To</span> Apr 30, 2020
                                        </div>
                                    </td>
                                    <td>30 Days</td>
                                </tr>
                            </tbody>
                        </table>
                        <table cellspacing="0" cellpadding="0" class="table random_detail">
                            <tbody>
                                <tr>
                                    <td width="175">
                                        <div class="date">
                                            <span>From</span> Apr 01, 2020
                                        </div>
                                        <div class="date">
                                            <span>To</span> Apr 30, 2020
                                        </div>
                                    </td>
                                    <td width="99">30 Days</td>
                                    <td rowspan="3"><b>90 Days</b></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="date">
                                            <span>From</span> Apr 01, 2020
                                        </div>
                                        <div class="date">
                                            <span>To</span> Apr 30, 2020
                                        </div>
                                    </td>
                                    <td>30 Days</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="date">
                                            <span>From</span> Apr 01, 2020
                                        </div>
                                        <div class="date">
                                            <span>To</span> Apr 30, 2020
                                        </div>
                                    </td>
                                    <td>30 Days</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> -->
                <div fxLayout="row wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'add-resource'" color="primary"
                            [disabled]="f.form.invalid" (click)="SaveResource()">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'edit-resource'" color="primary"
                            [disabled]="f.form.invalid" (click)="SaveResource()">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="dialogAction == 'add-task'" class="prsn_dialog add_task">
    <button class="closeModal" (click)="deviceclose()">
        <img src="/assets/icons/close_mpdal_icon.svg" alt="">
    </button>
    <div class="top_tag">Please add at least one activity to estimate the efforts</div>
    <div class="modal_section">
        <h2>Add Task</h2>
        <div class="form_layout">
            <div fxLayout="row wrap" fxLayoutAlign="start start">
                <div class="flex-item" fxFlex="0 0 100">
                    <mat-form-field appearance="outline">
                        <mat-label>Task Name</mat-label>
                        <input matInput type="text">
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="start start">
                <div class="flex-item" fxFlex="0 0 60">
                    <mat-checkbox color="primary">Approval Required by Client</mat-checkbox>
                </div>
                <div class="flex-item" fxFlex="0 0 40">
                    <mat-checkbox color="primary">Print on Invoice</mat-checkbox>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="start start">
                <div class="flex-item" fxFlex="0 0 100">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                        [(ngModel)]="favoriteSeason">
                        <mat-radio-button class="example-radio-button" color="primary" *ngFor="let season of seasons"
                            [value]="season">
                            {{season}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="onetime_part" *ngIf="favoriteSeason == 'One Time'">
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field appearance="outline">
                            <label for="">Quantity</label>
                            <div class="qnty_box">
                                <input type="text" value="01" matInput>
                                <mat-select placeholder="Unit">
                                    <mat-option value="1">Unit</mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Units</label>
                            <span class="disable">02 Unit</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Order Qty</label>
                            <span class="disable">02 Unit</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Order Qty</label>
                            <span class="disable">02 Unit</span>
                        </mat-form-field>
                    </div>

                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field appearance="outline">
                            <label for="">Efforts</label>
                            <div class="qnty_box">
                                <input type="text" value="01" matInput>
                                <mat-select placeholder="Hour">
                                    <mat-option value="1">Hour</mat-option>
                                    <mat-option value="2">Unit</mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Efforts</label>
                            <span class="disable">04 Hours</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                        <div class="requirement_section">
                            <input type="text" matInput placeholder="Requirements from Client">
                            <mat-icon>add</mat-icon>
                        </div>
                        <div class="require_tag">
                            <span>Product Photos <mat-icon>clear</mat-icon></span>
                            <span>Client Logos <mat-icon>clear</mat-icon></span>
                        </div>
                    </div>
                </div>

            </div>
            <div class="repeat_part" *ngIf="favoriteSeason == 'Repeat'">
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field appearance="outline">
                            <label for="">Total Qty/Cycle</label>
                            <div class="qnty_box">
                                <input type="text" value="50" matInput>
                                <mat-select placeholder="No.">
                                    <mat-option value="1">No.</mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">No. Of Cycles</label>
                            <span class="disable">6 Month</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Quantity</label>
                            <span class="disable">300 No.</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Quantity/Unit</label>
                            <span class="disable">300 No.</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field>
                            <label for="">Units</label>
                            <span class="disable">6 Unit</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Order Qty</label>
                            <span class="disable">900 No.</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Order Qty</label>
                            <span class="disable">900 No.</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field appearance="outline">
                            <label for="">Efforts</label>
                            <div class="qnty_box">
                                <input type="text" value="02" matInput>
                                <mat-select placeholder="Hour">
                                    <mat-option value="1">Hour</mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 025">
                        <mat-form-field>
                            <label for="">Total Efforts</label>
                            <span class="disable">1800 Hours</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                        <div class="requirement_section">
                            <input type="text" matInput placeholder="Requirements from Client">
                            <mat-icon>add</mat-icon>
                        </div>
                        <div class="require_tag">
                            <span>Product Photos <mat-icon>clear</mat-icon></span>
                            <span>Client Logos <mat-icon>clear</mat-icon></span>
                        </div>
                    </div>
                </div>
                <div class="divider"></div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                        <div class="total_qty">Total Qty <span>300 Days</span></div>
                    </div>
                    <table cellspacing="0" cellpadding="0" class="table random_detail">
                        <thead>
                            <tr>
                                <th>Cycle</th>
                                <th>Total Qty</th>
                                <th>Total Qty/Cycle</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>50 No.</td>
                                <td rowspan="3"><b>150 Days</b></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>50 No.</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>50 No.</td>
                            </tr>
                        </tbody>
                    </table>
                    <table cellspacing="0" cellpadding="0" class="table random_detail">
                        <tbody>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>50 No.</td>
                                <td rowspan="3"><b>150 Days</b></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>50 No.</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>50 No.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="random_part" *ngIf="favoriteSeason == 'Random'">
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field appearance="outline">
                            <label for="">Total Qty/Cycle</label>
                            <div class="qnty_box">
                                <input type="text" value="50" matInput>
                                <mat-select placeholder="No.">
                                    <mat-option value="1">No.</mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">No. Of Cycles</label>
                            <span class="disable">6 Month</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Quantity</label>
                            <span class="disable">300 No.</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Quantity/Unit</label>
                            <span class="disable">300 No.</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field>
                            <label for="">Units</label>
                            <span class="disable">6 Unit</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Order Qty</label>
                            <span class="disable">900 No.</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Order Qty</label>
                            <span class="disable">900 No.</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field appearance="outline">
                            <label for="">Efforts</label>
                            <div class="qnty_box">
                                <input type="text" value="02" matInput>
                                <mat-select placeholder="Hour">
                                    <mat-option value="1">Hour</mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 025">
                        <mat-form-field>
                            <label for="">Total Efforts</label>
                            <span class="disable">1800 Hours</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                        <div class="requirement_section">
                            <input type="text" matInput placeholder="Requirements from Client">
                            <mat-icon>add</mat-icon>
                        </div>
                        <div class="require_tag">
                            <span>Product Photos <mat-icon>clear</mat-icon></span>
                            <span>Client Logos <mat-icon>clear</mat-icon></span>
                        </div>
                    </div>
                </div>
                <div class="divider"></div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                        <div class="total_qty">Total Qty <span>300 Days</span></div>
                    </div>
                    <table cellspacing="0" cellpadding="0" class="table random_detail">
                        <thead>
                            <tr>
                                <th>Cycle</th>
                                <th>Total Qty</th>
                                <th>Total Qty/Cycle</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>50 No.</td>
                                <td rowspan="3"><b>150 Days</b></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>50 No.</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>50 No.</td>
                            </tr>
                        </tbody>
                    </table>
                    <table cellspacing="0" cellpadding="0" class="table random_detail">
                        <tbody>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>50 No.</td>
                                <td rowspan="3"><b>150 Days</b></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>50 No.</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>50 No.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div fxLayout="row wrap">
                <div class="flex-item" fxFlex="0 1 100">
                    <button mat-flat-button color="primary">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="dialogAction == 'add-item'" class="prsn_dialog add_item">
    <button class="closeModal" (click)="deviceclose()">
        <img src="/assets/icons/close_mpdal_icon.svg" alt="">
    </button>
    <div class="modal_section">
        <div class="tag_name">Material</div>
        <h2>Add Item</h2>
        <div class="form_layout">
            <div fxLayout="row wrap" fxLayoutAlign="start start">
                <div class="flex-item" fxFlex="0 0 100">
                    <mat-form-field appearance="outline">
                        <mat-label>Item Name</mat-label>
                        <input matInput type="text">
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="start start">
                <div class="flex-item" fxFlex="0 0 60">
                    <mat-checkbox color="primary">Approval Required by Client</mat-checkbox>
                </div>
                <div class="flex-item" fxFlex="0 0 40">
                    <mat-checkbox color="primary">Print on Invoice</mat-checkbox>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="start start">
                <div class="flex-item" fxFlex="0 0 100">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                        [(ngModel)]="favoriteSeason">
                        <mat-radio-button class="example-radio-button" color="primary" *ngFor="let season of seasons"
                            [value]="season" checked="checked">
                            {{season}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="onetime_part" *ngIf="favoriteSeason == 'One Time'">
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field appearance="outline">
                            <label for="">Quantity</label>
                            <div class="qnty_box">
                                <input type="text" value="01" matInput>
                                <mat-select placeholder="Unit">
                                    <mat-option value="1">Unit</mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Units</label>
                            <span class="disable">02 Unit</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Order Qty</label>
                            <span class="disable">02 Unit</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Order Qty</label>
                            <span class="disable">02 Unit</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field appearance="outline">
                            <label for="">Efforts</label>
                            <div class="qnty_box">
                                <input type="text" value="01" matInput>
                                <mat-select placeholder="Hour">
                                    <mat-option value="1">Hour</mat-option>
                                    <mat-option value="2">Unit</mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Efforts</label>
                            <span class="disable">04 Hours</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                        <div class="requirement_section">
                            <input type="text" matInput placeholder="Requirements from Client">
                            <mat-icon>add</mat-icon>
                        </div>
                        <div class="require_tag">
                            <span>Product Photos <mat-icon>clear</mat-icon></span>
                            <span>Client Logos <mat-icon>clear</mat-icon></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="repeat_part" *ngIf="favoriteSeason == 'Repeat'">
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field appearance="outline">
                            <label for="">Total Qty/Cycle</label>
                            <div class="qnty_box">
                                <input type="text" value="50" matInput>
                                <mat-select placeholder="No.">
                                    <mat-option value="1">No.</mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">No. Of Cycles</label>
                            <span class="disable">6 Month</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Quantity</label>
                            <span class="disable">300 No.</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Quantity/Unit</label>
                            <span class="disable">300 No.</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field>
                            <label for="">Units</label>
                            <span class="disable">6 Unit</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Order Qty</label>
                            <span class="disable">900 No.</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Order Qty</label>
                            <span class="disable">900 No.</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field appearance="outline">
                            <label for="">Efforts</label>
                            <div class="qnty_box">
                                <input type="text" value="02" matInput>
                                <mat-select placeholder="Hour">
                                    <mat-option value="1">Hour</mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 025">
                        <mat-form-field>
                            <label for="">Total Efforts</label>
                            <span class="disable">1800 Hours</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                        <div class="requirement_section">
                            <input type="text" matInput placeholder="Requirements from Client">
                            <mat-icon>add</mat-icon>
                        </div>
                        <div class="require_tag">
                            <span>Product Photos <mat-icon>clear</mat-icon></span>
                            <span>Client Logos <mat-icon>clear</mat-icon></span>
                        </div>
                    </div>
                </div>
                <div class="divider"></div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                        <div class="requirement_section">
                            <input type="text" matInput placeholder="Specifications">
                            <mat-icon>add</mat-icon>
                        </div>
                        <div class="require_tag">
                            <span>2 GB RAM <mat-icon>clear</mat-icon></span>
                            <span>2 TB Storage <mat-icon>clear</mat-icon></span>
                        </div>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start start">
                    <div class="flex-item qty_detail" fxFlex="0 0 100">
                        <mat-form-field appearance="outline" class="delivery_section">
                            <input class="deliver_input" placeholder="Delivery Location" type="text">
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 0 100">
                        <mat-form-field appearance="outline" class="delivery_section">
                            <textarea class="descrip_text" placeholder="Description/Notes" name="" id="" cols="30"
                                rows="10"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                        <div class="estimate_text">
                            <div class="total_qty">Estimated Amount <span>100 INR</span></div>
                            <div class="total_qty">Total Qty <span>300 Days</span></div>
                        </div>
                    </div>
                    <table cellspacing="0" cellpadding="0" class="table random_detail">
                        <thead>
                            <tr>
                                <th>Cycle</th>
                                <th>Total Qty</th>
                                <th>Total Qty/Cycle</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>01 Pcs</td>
                                <td rowspan="3"><b>03 Pcs</b></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>01 Pcs</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>01 Pcs</td>
                            </tr>
                        </tbody>
                    </table>
                    <table cellspacing="0" cellpadding="0" class="table random_detail">
                        <tbody>
                            <tr>
                                <td width="175">
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td width="99">01 Pcs</td>
                                <td rowspan="3"><b>03 Pcs</b></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>01 Pcs</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>01 Pcs</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="random_part" *ngIf="favoriteSeason == 'Random'">
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field appearance="outline">
                            <label for="">Total Qty/Cycle</label>
                            <div class="qnty_box">
                                <input type="text" value="50" matInput>
                                <mat-select placeholder="No.">
                                    <mat-option value="1">No.</mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">No. Of Cycles</label>
                            <span class="disable">6 Month</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Quantity</label>
                            <span class="disable">300 No.</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Quantity/Unit</label>
                            <span class="disable">300 No.</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field>
                            <label for="">Units</label>
                            <span class="disable">6 Unit</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Order Qty</label>
                            <span class="disable">900 No.</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Order Qty</label>
                            <span class="disable">900 No.</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field appearance="outline">
                            <label for="">Efforts</label>
                            <div class="qnty_box">
                                <input type="text" value="02" matInput>
                                <mat-select placeholder="Hour">
                                    <mat-option value="1">Hour</mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 025">
                        <mat-form-field>
                            <label for="">Total Efforts</label>
                            <span class="disable">1800 Hours</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                        <div class="requirement_section">
                            <input type="text" matInput placeholder="Requirements from Client">
                            <mat-icon>add</mat-icon>
                        </div>
                        <div class="require_tag">
                            <span>Product Photos <mat-icon>clear</mat-icon></span>
                            <span>Client Logos <mat-icon>clear</mat-icon></span>
                        </div>
                    </div>
                </div>
                <div class="divider"></div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                        <div class="requirement_section">
                            <input type="text" matInput placeholder="Specifications">
                            <mat-icon>add</mat-icon>
                        </div>
                        <div class="require_tag">
                            <span>2 GB RAM <mat-icon>clear</mat-icon></span>
                            <span>2 TB Storage <mat-icon>clear</mat-icon></span>
                        </div>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start start">
                    <div class="flex-item qty_detail" fxFlex="0 0 100">
                        <mat-form-field appearance="outline" class="delivery_section">
                            <input class="deliver_input" placeholder="Delivery Location" type="text">
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 0 100">
                        <mat-form-field appearance="outline" class="delivery_section">
                            <textarea class="descrip_text" placeholder="Description/Notes" name="" id="" cols="30"
                                rows="10"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                        <div class="estimate_text">
                            <div class="total_qty">Estimated Amount <span>100 INR</span></div>
                            <div class="total_qty">Total Qty <span>300 Days</span></div>
                        </div>
                    </div>
                    <table cellspacing="0" cellpadding="0" class="table random_detail">
                        <thead>
                            <tr>
                                <th>Cycle</th>
                                <th>Total Qty</th>
                                <th>Total Qty/Cycle</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>01 Pcs</td>
                                <td rowspan="3"><b>03 Pcs</b></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>01 Pcs</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>01 Pcs</td>
                            </tr>
                        </tbody>
                    </table>
                    <table cellspacing="0" cellpadding="0" class="table random_detail">
                        <tbody>
                            <tr>
                                <td width="175">
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td width="99">01 Pcs</td>
                                <td rowspan="3"><b>03 Pcs</b></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>01 Pcs</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>01 Pcs</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div fxLayout="row wrap">
                <div class="flex-item" fxFlex="0 1 100">
                    <button mat-flat-button color="primary">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="dialogAction == 'add-expense'" class="prsn_dialog add_expense">
    <button class="closeModal" (click)="deviceclose()">
        <img src="/assets/icons/close_mpdal_icon.svg" alt="">
    </button>
    <div class="modal_section">
        <div class="tag_name">Expense</div>
        <h2>Add Expense</h2>
        <div class="form_layout">
            <div fxLayout="row wrap" fxLayoutAlign="start start">
                <div class="flex-item" fxFlex="0 0 100">
                    <mat-form-field appearance="outline">
                        <mat-label>Expense Name</mat-label>
                        <input matInput type="text">
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="start start">
                <div class="flex-item" fxFlex="0 0 60">
                    <mat-checkbox color="primary">Approval Required by Client</mat-checkbox>
                </div>
                <div class="flex-item" fxFlex="0 0 40">
                    <mat-checkbox color="primary">Print on Invoice</mat-checkbox>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="start start">
                <div class="flex-item" fxFlex="0 0 100">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                        [(ngModel)]="favoriteSeason">
                        <mat-radio-button class="example-radio-button" color="primary" *ngFor="let season of seasons"
                            [value]="season">
                            {{season}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="onetime_part" *ngIf="favoriteSeason == 'One Time'">
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field appearance="outline">
                            <label for="">Quantity</label>
                            <div class="qnty_box">
                                <input type="text" value="01" matInput>
                                <mat-select placeholder="Unit">
                                    <mat-option value="1">Unit</mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Units</label>
                            <span class="disable">02 Unit</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Order Qty</label>
                            <span class="disable">02 Unit</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Order Qty</label>
                            <span class="disable">02 Unit</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field appearance="outline">
                            <label for="">Efforts</label>
                            <div class="qnty_box">
                                <input type="text" value="01" matInput>
                                <mat-select placeholder="Hour">
                                    <mat-option value="1">Hour</mat-option>
                                    <mat-option value="2">Unit</mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Efforts</label>
                            <span class="disable">04 Hours</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                        <div class="requirement_section">
                            <input type="text" matInput placeholder="Requirements from Client">
                            <mat-icon>add</mat-icon>
                        </div>
                        <div class="require_tag">
                            <span>Product Photos <mat-icon>clear</mat-icon></span>
                            <span>Client Logos <mat-icon>clear</mat-icon></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="repeat_part" *ngIf="favoriteSeason == 'Repeat'">
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field appearance="outline">
                            <label for="">Total Qty/Cycle</label>
                            <div class="qnty_box">
                                <input type="text" value="50" matInput>
                                <mat-select placeholder="No.">
                                    <mat-option value="1">No.</mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">No. Of Cycles</label>
                            <span class="disable">6 Month</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Quantity</label>
                            <span class="disable">300 No.</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Quantity/Unit</label>
                            <span class="disable">300 No.</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field>
                            <label for="">Units</label>
                            <span class="disable">6 Unit</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Order Qty</label>
                            <span class="disable">900 No.</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Order Qty</label>
                            <span class="disable">900 No.</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field appearance="outline">
                            <label for="">Efforts</label>
                            <div class="qnty_box">
                                <input type="text" value="02" matInput>
                                <mat-select placeholder="Hour">
                                    <mat-option value="1">Hour</mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 025">
                        <mat-form-field>
                            <label for="">Total Efforts</label>
                            <span class="disable">1800 Hours</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                        <div class="requirement_section">
                            <input type="text" matInput placeholder="Requirements from Client">
                            <mat-icon>add</mat-icon>
                        </div>
                        <div class="require_tag">
                            <span>Product Photos <mat-icon>clear</mat-icon></span>
                            <span>Client Logos <mat-icon>clear</mat-icon></span>
                        </div>
                    </div>
                </div>
                <div class="divider"></div>
                <div fxLayout="row wrap" fxLayoutAlign="start start">
                    <div class="flex-item qty_detail" fxFlex="0 0 100">
                        <mat-form-field appearance="outline" class="delivery_section">
                            <input class="deliver_input" placeholder="Location" type="text">
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 0 100">
                        <mat-form-field appearance="outline" class="delivery_section">
                            <input class="deliver_input" placeholder="Cost Range Upto" type="text">
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 0 100">
                        <mat-form-field appearance="outline" class="delivery_section">
                            <textarea class="descrip_text" placeholder="Description/Notes" name="" id="" cols="30"
                                rows="10"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                        <div class="estimate_text">
                            <div class="total_qty">Estimated Amount <span>100 INR</span></div>
                            <div class="total_qty">Total Qty <span>300 Days</span></div>
                        </div>
                    </div>
                    <table cellspacing="0" cellpadding="0" class="table random_detail">
                        <thead>
                            <tr>
                                <th>Cycle</th>
                                <th>Total Qty</th>
                                <th>Total Qty/Cycle</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>30 Days</td>
                                <td rowspan="3"><b>90 Days</b></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>30 Days</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>30 Days</td>
                            </tr>
                        </tbody>
                    </table>
                    <table cellspacing="0" cellpadding="0" class="table random_detail">
                        <tbody>
                            <tr>
                                <td width="175">
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td width="99">30 Days</td>
                                <td rowspan="3"><b>90 Days</b></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>30 Days</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>30 Days</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="random_part" *ngIf="favoriteSeason == 'Random'">
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field appearance="outline">
                            <label for="">Total Qty/Cycle</label>
                            <div class="qnty_box">
                                <input type="text" value="50" matInput>
                                <mat-select placeholder="No.">
                                    <mat-option value="1">No.</mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">No. Of Cycles</label>
                            <span class="disable">6 Month</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Quantity</label>
                            <span class="disable">300 No.</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Quantity/Unit</label>
                            <span class="disable">300 No.</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field>
                            <label for="">Units</label>
                            <span class="disable">6 Unit</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Order Qty</label>
                            <span class="disable">900 No.</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail" fxFlex="0 1 25">
                        <mat-form-field>
                            <label for="">Total Order Qty</label>
                            <span class="disable">900 No.</span>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 0 30">
                        <mat-form-field appearance="outline">
                            <label for="">Efforts</label>
                            <div class="qnty_box">
                                <input type="text" value="02" matInput>
                                <mat-select placeholder="Hour">
                                    <mat-option value="1">Hour</mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 1 10">
                        <div class="equal">=</div>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 025">
                        <mat-form-field>
                            <label for="">Total Efforts</label>
                            <span class="disable">1800 Hours</span>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                        <div class="requirement_section">
                            <input type="text" matInput placeholder="Requirements from Client">
                            <mat-icon>add</mat-icon>
                        </div>
                        <div class="require_tag">
                            <span>Product Photos <mat-icon>clear</mat-icon></span>
                            <span>Client Logos <mat-icon>clear</mat-icon></span>
                        </div>
                    </div>
                </div>
                <div class="divider"></div>
                <div fxLayout="row wrap" fxLayoutAlign="start start">
                    <div class="flex-item qty_detail" fxFlex="0 0 100">
                        <mat-form-field appearance="outline" class="delivery_section">
                            <input class="deliver_input" placeholder="Location" type="text">
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 0 100">
                        <mat-form-field appearance="outline" class="delivery_section">
                            <input class="deliver_input" placeholder="Cost Range Upto" type="text">
                        </mat-form-field>
                    </div>
                    <div class="flex-item qty_detail" fxFlex="0 0 100">
                        <mat-form-field appearance="outline" class="delivery_section">
                            <textarea class="descrip_text" placeholder="Description/Notes" name="" id="" cols="30"
                                rows="10"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div class="flex-item qty_detail req_detail" fxFlex="0 1 100">
                        <div class="estimate_text">
                            <div class="total_qty">Estimated Amount <span>100 INR</span></div>
                            <div class="total_qty">Total Qty <span>300 Days</span></div>
                        </div>
                    </div>
                    <table cellspacing="0" cellpadding="0" class="table random_detail">
                        <thead>
                            <tr>
                                <th>Cycle</th>
                                <th>Total Qty</th>
                                <th>Total Qty/Cycle</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>30 Days</td>
                                <td rowspan="3"><b>90 Days</b></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>30 Days</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>30 Days</td>
                            </tr>
                        </tbody>
                    </table>
                    <table cellspacing="0" cellpadding="0" class="table random_detail">
                        <tbody>
                            <tr>
                                <td width="175">
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td width="99">30 Days</td>
                                <td rowspan="3"><b>90 Days</b></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>30 Days</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="date">
                                        <span>From</span> Apr 01, 2020
                                    </div>
                                    <div class="date">
                                        <span>To</span> Apr 30, 2020
                                    </div>
                                </td>
                                <td>30 Days</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div fxLayout="row wrap">
                <div class="flex-item" fxFlex="0 1 100">
                    <button mat-flat-button color="primary">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="dialogAction == 'rework-ballpark'" class="prsn_dialog ballpark_rework">
    <button class="closeModal" (click)="deviceclose()"><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <h2>Rework on Ballpark</h2>
        <div class="form_layout">
            <div fxLayout="row wrap">
                <div class="flex-item" fxFlex="0 1 100">
                    <mat-form-field appearance="outline">
                        <mat-label>Justification</mat-label>
                        <textarea matInput></textarea>
                    </mat-form-field>
                </div>
                <div class="flex-item" fxFlex="0 1 100">
                    <button mat-flat-button color="primary">Save</button>
                </div>
            </div>
        </div>
        <div class="ballpark_comment">
            <div class="comnt_box">
                <div class="top_cmnt">
                    <div class="user_cmnt">
                        <img src="/assets/icons/user_image.png" alt=""> Prashant Pareek
                    </div>
                    <div class="date_time">Apr 20, 2020 | 03:30 PM</div>
                </div>
                <div class="cmnt_text">
                    <p>Mauris porttitor massa sit amet est blandit, vel posuere ligula scelerisque. Mauris pellentesque
                        leo a libero scelerisque condimentum sit amet sit amet est.</p>
                </div>
            </div>
            <div class="sub_cmnt_box">
                <div class="comnt_box">
                    <div class="top_cmnt">
                        <div class="user_cmnt">
                            <img src="/assets/icons/user_image.png" alt=""> Prashant Pareek
                            <mat-icon>error_outline</mat-icon>
                        </div>
                        <div class="date_time">Apr 20, 2020 | 03:30 PM</div>
                    </div>
                    <div class="cmnt_text">
                        <p>Mauris porttitor massa sit amet est blandit, vel posuere ligula scelerisque. Mauris
                            pellentesque leo a libero scelerisque condimentum sit amet sit amet est.</p>
                        <div class="reply_btn">
                            <mat-icon>reply</mat-icon> Reply
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="dialogAction == 'rework-cost'" class="prsn_dialog cost_rework">
    <button class="closeModal" (click)="deviceclose()"><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <h2>Comment on Manpower</h2>
        <div class="form_layout">
            <div fxLayout="row wrap">
                <div class="flex-item" fxFlex="0 1 100">
                    <mat-form-field appearance="outline">
                        <mat-label>Start writing comment</mat-label>
                        <textarea matInput></textarea>
                    </mat-form-field>
                </div>
                <div class="flex-item" fxFlex="0 1 100">
                    <button mat-flat-button color="primary">Save</button>
                </div>
            </div>
        </div>
        <div class="ballpark_comment">
            <div class="comnt_box">
                <div class="top_cmnt">
                    <div class="user_cmnt">
                        <img src="/assets/icons/user_image.png" alt=""> Prashant Pareek
                    </div>
                    <div class="date_time">Apr 20, 2020 | 03:30 PM</div>
                </div>
                <div class="cmnt_text">
                    <p>Mauris porttitor massa sit amet est blandit, vel posuere ligula scelerisque. Mauris pellentesque
                        leo a libero scelerisque condimentum sit amet sit amet est.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="dialogAction == 'comments-finance-controller'" class="prsn_dialog cmnts_finance_controller">
    <button class="closeModal" (click)="deviceclose()"><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <h2>Comments by Finance Controller</h2>
        <div class="ballpark_comment">
            <div class="mnts_status">
                <span>Deliverable</span>
                <b>Search Engine Optimization</b>
            </div>
            <h4>Manpower</h4>
            <div class="comnt_box">
                <div class="top_cmnt">
                    <div class="user_cmnt">
                        <img src="/assets/icons/user_image.png" alt=""> Prashant Pareek
                        <mat-icon>error_outline</mat-icon>
                    </div>
                    <div class="date_time">Apr 20, 2020 | 03:30 PM</div>
                </div>
                <div class="cmnt_text">
                    <p>Mauris porttitor massa sit amet est blandit, vel posuere ligula scelerisque. Mauris pellentesque
                        leo a libero scelerisque condimentum sit amet sit amet est.</p>
                    <div class="reply_btn">
                        <mat-icon>reply</mat-icon> Reply
                    </div>
                </div>
            </div>
            <div class="comnt_box">
                <div class="top_cmnt">
                    <div class="user_cmnt">
                        <img src="/assets/icons/user_image.png" alt=""> Prashant Pareek
                        <mat-icon>error_outline</mat-icon>
                    </div>
                    <div class="date_time">Apr 20, 2020 | 03:30 PM</div>
                </div>
                <div class="cmnt_text">
                    <p>Mauris porttitor massa sit amet est blandit, vel posuere ligula scelerisque. Mauris pellentesque
                        leo a libero scelerisque condimentum sit amet sit amet est.</p>
                    <div class="reply_btn">
                        <mat-icon>reply</mat-icon> Reply
                    </div>
                </div>
            </div>
            <div class="comnt_box">
                <div class="top_cmnt">
                    <div class="user_cmnt">
                        <img src="/assets/icons/user_image.png" alt=""> Prashant Pareek
                        <mat-icon>error_outline</mat-icon>
                    </div>
                    <div class="date_time">Apr 20, 2020 | 03:30 PM</div>
                </div>
                <div class="cmnt_text">
                    <p>Mauris porttitor massa sit amet est blandit, vel posuere ligula scelerisque. Mauris pellentesque
                        leo a libero scelerisque condimentum sit amet sit amet est.</p>
                    <div class="form-layout">
                        <div class="flex-item">
                            <mat-form-field appearance="outline">
                                <mat-label>Comment</mat-label>
                                <textarea matInput></textarea>
                            </mat-form-field>
                        </div>
                        <div class="flex-item">
                            <button class="btn" color="primary" mat-flat-button>Save</button>
                            <button class="cancel">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="comnt_box">
                <div class="top_cmnt">
                    <div class="user_cmnt">
                        <img src="/assets/icons/user_image.png" alt=""> Prashant Pareek
                        <mat-icon>error_outline</mat-icon>
                    </div>
                    <div class="date_time">Apr 20, 2020 | 03:30 PM</div>
                </div>
                <div class="cmnt_text">
                    <p>Mauris porttitor massa sit amet est blandit, vel posuere ligula scelerisque. Mauris pellentesque
                        leo a libero scelerisque condimentum sit amet sit amet est.</p>
                </div>
            </div>
            <div class="sub_cmnt_box">
                <div class="comnt_box">
                    <div class="top_cmnt">
                        <div class="user_cmnt">
                            <img src="/assets/icons/user_image.png" alt=""> Prashant Pareek
                            <mat-icon>error_outline</mat-icon>
                        </div>
                        <div class="date_time">Apr 20, 2020 | 03:30 PM</div>
                    </div>
                    <div class="cmnt_text">
                        <p>Mauris porttitor massa sit amet est blandit, vel posuere ligula scelerisque. Mauris
                            pellentesque leo a libero scelerisque condimentum sit amet sit amet est.</p>
                    </div>
                </div>
            </div>
            <div class="divider">&nbsp;</div>
            <h4>Expenses</h4>
            <div class="comnt_box">
                <div class="top_cmnt">
                    <div class="user_cmnt">
                        <img src="/assets/icons/user_image.png" alt=""> Prashant Pareek
                        <mat-icon>error_outline</mat-icon>
                    </div>
                    <div class="date_time">Apr 20, 2020 | 03:30 PM</div>
                </div>
                <div class="cmnt_text">
                    <p>Mauris porttitor massa sit amet est blandit, vel posuere ligula scelerisque. Mauris pellentesque
                        leo a libero scelerisque condimentum sit amet sit amet est.</p>
                    <div class="reply_btn">
                        <mat-icon>reply</mat-icon> Reply
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="dialogAction == 'reject-ballpark'" class="prsn_dialog reject_dialog">
    <button class="closeModal" (click)="deviceclose()"><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <div class="form_layout">
            <div fxLayout="row wrap">
                <div class="flex-item" fxFlex="0 1 100">
                    <div class="alert_div">
                        <div class="alert_icon">
                            <mat-icon>error_outline</mat-icon>
                        </div>
                        <h4>Alert!</h4>
                        <p>Are you sure you want to approve <br />the Ballpark Estimation?</p>
                    </div>
                </div>
                <div class="flex-item" fxFlex="0 1 100">
                    <button class="cancel" mat-flat-button color="">Cancel</button>
                    <button mat-flat-button color="primary">Save</button>
                </div>
            </div>
        </div>
        <div class="success_dialog">
            <div fxLayout="column wrap">
                <div class="flex-item" fxFlex="0 1 100">
                    <div class="alert_div">
                        <div class="success_icon">
                            <mat-icon>done</mat-icon>
                        </div>
                        <h4>Success!</h4>
                        <p>Ballpark Estimation has been <br />approved successfully</p>
                    </div>
                </div>
                <div class="flex-item" fxFlex="0 1 100">
                    <button mat-flat-button color="primary">Continue</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="dialogAction == 'rework-cost-new'" class="prsn_dialog cost_rework">
    <button class="closeModal" (click)="deviceclose()"><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <h2>Rework on Ballpark</h2>
        <div class="form_layout">
            <div fxLayout="row wrap">
                <div class="flex-item" fxFlex="0 1 100">
                    <mat-form-field appearance="outline">
                        <mat-label>Justification</mat-label>
                        <textarea matInput></textarea>
                    </mat-form-field>
                </div>
                <div class="flex-item" fxFlex="0 1 100">
                    <button mat-flat-button color="primary">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="dialogAction == 'accepted-client'" class="prsn_dialog cost_rework">
    <button class="closeModal" (click)="deviceclose()"><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <h2>Accepted by Client</h2>
        <div class="form_layout">
            <form fxLayout="row wrap" #acceptedClientForm="ngForm">
                <div class="flex-item" fxFlex="0 1 100">
                    <mat-form-field appearance="outline">
                        <mat-label>Comment</mat-label>
                        <textarea matInput [(ngModel)]="addDocumentDetail.Remark" name="Remark" required></textarea>
                    </mat-form-field>
                </div>
                <div class="flex-item" fxFlex="0 1 100">
                    <div class="attach_section">
                        <h5>Attach File</h5>
                        <div class="attach_file">
                            <img src="/assets/icons/attach_icon.svg" alt=""> Browse or drop file here
                            <input type="file" id="fileUploaded" name="fileUpload" class="upload"
                                (change)="onClick($event)">
                        </div>
                        <div class="col-sm-12 p-0 pull-left">
                            <ul class="bar-text list-inline" style="list-style:none">
                                <li class="mt-3" *ngFor="let file of files">
                                    <mat-progress-bar [value]="file.progress"></mat-progress-bar>
                                    <span class="bar-value">Uploading .... {{file.progress}}%</span>
                                    <span id="file-label">
                                        <a title="Retry" class="retry" (click)="retryFile(file)"
                                            *ngIf="file.canRetry">Retry</a>
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div class="col-sm-12 pull-left p-0 file-info">
                            <ul style="list-style: none;padding: 0px;width:100%; margin-bottom: 15px;"
                                *ngIf="finalFile?.length > 0">
                                <li class="col-sm-12 p-0 pull-left mt-3 d-flex align-items-center"
                                    *ngFor="let file of finalFile; let i = index">
                                    <span class="col-sm-9 pull-left pl-0">
                                        <span class="mr-3">
                                            <!--  <img src="/src/assets/images/{{file.fileType}}-icon.png" /> -->
                                            <img src="/assets/images/{{file.fileType}}-icon.png" />
                                        </span>
                                        <span>{{file.name}}</span>
                                    </span>
                                    <span class="col-sm-3 pull-left txt-right pr-0">
                                        <span><a title="Delete" class="retry" (click)="deleteFile(i)">
                                                <span class="material-icons">clear</span>
                                            </a>
                                        </span>
                                        <span style="display:none;">{{file.type}}</span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <p style="cursor:pointer" *ngIf="addDocumentDetail.ImageList.length>0 && Imageshow">
                            <a
                                (click)="downloadMyFile(addDocumentDetail.DocumentFile.DocumentPath, addDocumentDetail.DocumentFile.DocumentFileName)">{{addDocumentDetail.DocumentFile.DocumentFileName}}</a>
                        </p>
                    </div>
                </div>
                <div class="flex-item" fxFlex="0 1 100">
                    <button mat-flat-button color="primary" [disabled]="!acceptedClientForm.valid"
                        (click)="acceptedClientSubmit()">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div *ngIf="dialogAction == 'deliverableDetail'" class="prsn_dialog cost_rework delivdet-all">
    <button class="closeModal" (click)="deviceclose()"><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <h2>Deliverable Detail</h2>
        <h3>{{DeliverableInput.DeliverableName}}</h3>
        <div class="row">
            <div class="col-md-12">
                <p>
                    <span>Duration</span> {{DeliverableInput.Duration}}
                    {{DeliverableInput.DurationUnit.DurationUnitName}}
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    <span>Start Date & Time</span> {{DeliverableInput.StartDate | date}} |
                    {{DeliverableInput.StartTime}}
                </p>
            </div>
            <div class="col-md-8">
                <p>
                    <span>End Date & Time</span> {{DeliverableInput.EndDate | date}} | {{DeliverableInput.EndTime}}
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    <span>Delivery Cycle</span> {{DeliverableInput.DeliveryCycle == null ? 'NA' :
                    DeliverableInput.DeliveryCycle?.PaymentTypeName}}
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    <span>No. of Cycle</span> {{DeliverableInput?.NumberOfCycles ? DeliverableInput?.NumberOfCycles :
                    'NA'}}
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    <span>Delivery Date</span> {{DeliverableInput?.DeliveryDate | date}}
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    <span>Renewal Alert Before</span> {{DeliverableInput?.RenewalAlertBefore}}
                    {{DeliverableInput?.RenewalAlertBeforeUnit?.DurationUnitName}}
                </p>
            </div>
            <div class="col-md-8">
                <p>
                    <span>Delivery Reminder Before</span> {{DeliverableInput?.DeliveryReminderBefore}}
                    {{DeliverableInput?.DeliveryReminderBeforeUnit?.DurationUnitName}}
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    <span>Commencement Time</span> {{DeliverableInput?.CommencementTime}}
                    {{DeliverableInput?.CommencementTimeUnit?.DurationUnitName}}
                </p>
            </div>
            <div class="col-md-8">
                <p>
                    <span>Suspension Time</span> {{DeliverableInput?.SuspensionTime}}
                    {{DeliverableInput?.SuspensionTimeUnit?.DurationUnitName}}
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    <span>Additonal Charges</span> {{DeliverableInput?.Management?.AdditionalChargeLabel ?
                    DeliverableInput?.Management?.AdditionalChargeLabel : 'NA'}}
                    ({{DeliverableInput?.Management?.AdditionalChargeDigit ?
                    DeliverableInput.Management?.AdditionalChargeDigit
                    : 0}}%)
                </p>
            </div>
            <div class="col-md-8">
                <p>
                    <span>Discounts</span> {{DeliverableInput?.Discount?.DiscountLabel ?
                    DeliverableInput?.Discount?.DiscountLabel : 'NA'}} ({{DeliverableInput?.Discount?.DiscountDigit ?
                    DeliverableInput.Discount?.DiscountDigit : 0}}%)
                </p>
            </div>
        </div>
    </div>
</div>

<div *ngIf="dialogAction == 'locaConDetail'" class="prsn_dialog cost_rework delivdet-all">
    <button class="closeModal" (click)="deviceclose()"><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <h2>View Detail</h2>
        <div class="row">
            <div class="col-md-4">
                <p>
                    <span>Conveyance Type</span> Public
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    <span>Mode Of Conveyance</span> Train
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    <span>Date</span> 25 July, 2022
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    <span>Client Name</span> Nokia
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    <span>Visit From</span> Jaipur
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    <span>Visit To</span> Noida
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    <span>Purpose</span> Client Meting
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    <span>Distance in KM</span> 12
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    <span>Claimed Amount</span> INR 52000
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    <span>Bills</span> NA.pdf
                </p>
            </div>
            <div class="col-md-12">
                <p>
                    <span>Remarks</span> Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p>
            </div>
        </div>
    </div>
</div>

<div *ngIf="dialogAction == 'GRNdata'" class="prsn_dialog cost_rework delivdet-all">
    <button class="closeModal" (click)="deviceclose()"><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <h2>{{ModalData?.data.title}}
            <!-- <p><strong></strong></p> -->
        </h2>
        <mat-dialog-content class="mat-typography">
          <div class="row">
              <div class="col-md-6">
                  <p>
                      <span>Vendor Name</span> {{ ModalData?.data?.VendorName ? ModalData?.data?.VendorName : 'NA' }} <br>
                      <small>{{ ModalData?.data?.VendorCode ? ModalData?.data?.VendorCode : 'NA' }}</small>
                  </p>
              </div>
              <div class="col-md-6">
                  <p>
                      <span>{{ ModalData?.data?.IsRepairAndMaintenance ? "WO NO" : "PO NO" }}</span> {{ ModalData?.data?.IsRepairAndMaintenance ? ModalData?.data?.WoNumber : ModalData?.data?.PONumber }}
                  </p>
              </div>
              <div class="col-md-6">
                  <p>
                      <span>{{ ModalData?.data?.IsRepairAndMaintenance ? "WO " : "PO " }}Validity</span> {{ ModalData?.data?.POValidityDate ? (ModalData?.data?.POValidityDate | date) : 'NA' }}
                  </p>
              </div>
              <div class="col-md-6">
                  <p>
                      <span>Vendor Contact Person</span> {{ ModalData?.data?.KeyContactPersonName ? ModalData?.data?.KeyContactPersonName : 'NA' }}
                  </p>
              </div>
              <!-- <div class="col-md-6">
                  <p>
                      <span>Taxation</span> {{ ModalData?.data?.TaxId == 1 ? 'CGST, SGST' : ModalData?.data?.TaxId == 2 ? 'GST' : 'IGST' }}
                  </p>
              </div> -->
              <div class="col-md-6">
                  <p>
                      <span>Delivery Date</span> {{ ModalData?.data?.DeliveryDate ? (ModalData?.data?.DeliveryDate | date) : 'NA' }}
                  </p>
              </div>
              <div class="col-md-6">
                  <p>
                      <span>Delivery Terms</span> {{ ModalData?.data?.DeliveryTerms ? ModalData?.data?.DeliveryTerms : 'NA' }}
                  </p>
              </div>
              <div class="col-md-6">
                  <p>
                      <span>Currency</span> {{ currencysymbol?.CompanyCurrencySymbol ? currencysymbol?.CompanyCurrencySymbol : 'NA' }}
                  </p>
              </div>
              <div class="col-md-6">
                  <p>
                      <span>Quotation No</span> {{ ModalData?.data?.QuotationNumber ? ModalData?.data?.QuotationNumber : 'NA' }}
                  </p>
              </div>
              <div class="col-md-6">
                  <p>
                      <span>Contract Date</span> {{ ModalData?.data?.ContractDate ? (ModalData?.data?.ContractDate | date) : 'NA' }}
                  </p>
              </div>
              <div class="col-md-12">
                  <p>
                      <span>Billing Address</span> {{ ModalData?.data?.BillAddress ? ModalData?.data?.BillAddress : 'NA' }}
                  </p>
              </div>
              <div class="col-md-12">
                  <p>
                      <span>Shipping Address</span> {{ ModalData?.data?.ShippAddress ? ModalData?.data?.ShippAddress : (ModalData?.data?.ShippingAddressName ? ModalData?.data?.ShippingAddressName : 'NA') }}
                  </p>
              </div>
              <div class="col-md-12">
                  <p>
                      <span>Key content from the Service Agreement</span> {{ ModalData?.data?.KeyContent ? ModalData?.data?.KeyContent : 'NA' }}
                  </p>
              </div>
          </div>
      </mat-dialog-content>

    </div>
</div>

<div *ngIf="dialogAction == 'rework' || dialogAction == 'remarkEpica'" class="prsn_dialog cost_rework">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2>{{ModalData?.title}}</h2>
            <div class="form_layout">
                <div fxLayout="row wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline" class="h_auto">
                            <mat-label>Justification</mat-label>
                            <textarea matInput required [(ngModel)]="DeclineDetail.PMRejectReason.Description"
                                name="Description" #Description="ngModel"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button color="primary" [disabled]="f.form.invalid"
                            (click)="dialogRef.close(DeclineDetail)">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div *ngIf="dialogAction == 'remarkrequsotion'" class="prsn_dialog cost_rework">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2>{{ModalData?.title}}</h2>
            <div class="form_layout">
                <div fxLayout="row wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline" class="h_auto">
                            <mat-label>Justification</mat-label>
                            <textarea matInput required [(ngModel)]="ReworkReq.ReworkJustification"
                                name="Description" #Description="ngModel"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button color="primary" [disabled]="f.form.invalid"
                            (click)="dialogRef.close(ReworkReq)" (click)="reworksave()" >Save</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div *ngIf="dialogAction == 'remarkDeclinerequsotion'" class="prsn_dialog cost_rework">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2>{{ModalData?.title}}</h2>
            <div class="form_layout">
                <div fxLayout="row wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline" class="h_auto">
                            <mat-label>Justification</mat-label>
                            <textarea matInput required [(ngModel)]="ReworkReq.DeclineJustification"
                                name="Description" #Description="ngModel"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button color="primary" [disabled]="f.form.invalid"
                            (click)="dialogRef.close(ReworkReq)" (click)="reworksave()" >Save</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div *ngIf="dialogAction == 'rejectExpense'" class="prsn_dialog cost_rework">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2>{{ModalData?.title}}</h2>
            <div class="form_layout">
                <div fxLayout="row wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline" class="h_auto">
                            <mat-label>Remark</mat-label>
                            <textarea matInput required [(ngModel)]="ReworkReq.Reason"
                                name="Description" #Description="ngModel"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button color="primary" [disabled]="f.form.invalid"
                            (click)="dialogRef.close(ReworkReq)">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="dialogAction == 'viewCodeasset'" class="prsn_dialog cost_rework">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2>{{ModalData?.title}}</h2>
            <div class="form_layout">
                <div fxLayout="row wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <p class="resource-title">
                            <b>Description</b> Data Description
                        </p>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <p class="resource-title">
                            <b>Specification</b> Data Specification
                        </p>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button color="primary">Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="dialogAction == 'reworkAll'" class="prsn_dialog cost_rework">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <div class="modal_section">
        <h2>Comment for Rework</h2>
        <div class="form_layout">
            <div fxLayout="row wrap">
                <div class="flex-item" fxFlex="0 1 100">
                    <mat-form-field appearance="outline">
                        <mat-label>Write here</mat-label>
                        <textarea matInput></textarea>
                    </mat-form-field>
                </div>
                <div class="flex-item" fxFlex="0 1 100">
                    <button mat-flat-button color="primary">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="dialogAction == 'viewAcceptence'" class="prsn_dialog cost_rework">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <div class="modal_section">
        <h2>Client Acceptence</h2>
        <div class="form_layout">
            <div class="insbox">
                <div class="text">
                    <p>{{ClientAcceptenceData?.Remark ? ClientAcceptenceData?.Remark : 'NA'}}</p>
                </div>
                <div *ngIf="ClientAcceptenceData?.DocumentFile?.DocumentFileName">
                    <span style="cursor: pointer; color: #2075e9;"
                        (click)="downloadMyFile(ClientAcceptenceData?.DocumentFile.DocumentPath, ClientAcceptenceData?.DocumentFile.DocumentFileName)">
                        Download Acceptence Proof </span>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="dialogAction == 'viewDetail'" class="prsn_dialog cost_rework delivdet-all">
    <button class="closeModal" (click)="deviceclose()"><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <div class="row">
            <div class="col-md-12">
                <p class="resource-title">
                    <span>Title</span> {{leadView?.ResourceName}}
                </p>
            </div>
            <div class="col-md-3">
                <p>
                    <span>Delivery Cycle</span> {{leadView?.NumberOfCycles == 1 ? 'NA' :
                    leadView?.DeliveryCycle?.PaymentTypeName}}
                </p>
            </div>
            <div class="col-md-2">
                <p>
                    <span>Unit</span> {{leadView?.DeliverableUnits}}
                </p>
            </div>
            <div class="col-md-3" *ngIf="leadView?.categoryText != 'efforts'">
                <p>
                    <span>Quantity per Unit</span> {{leadView?.Quantity}} {{leadView?.QuantityUnit?.DurationUnitName}}
                </p>
            </div>
            <div class="col-md-3" *ngIf="leadView?.categoryText == 'efforts'">
                <p>
                    <span>Task per Unit</span> {{leadView?.Quantity}} {{leadView?.QuantityUnit?.DurationUnitName}}
                </p>
            </div>
            <div class="col-md-2">
                <p>
                    <span>No. of Cycles</span> {{leadView?.NumberOfCycles}}
                </p>
            </div>
            <div class="col-md-2" *ngIf="leadView?.categoryText != 'efforts'">
                <p>
                    <span>Total Quantity</span> {{leadView?.TotalQuantity}} {{leadView?.QuantityUnit?.DurationUnitName}}
                </p>
            </div>
            <div class="col-md-2" *ngIf="leadView?.categoryText == 'efforts'">
                <p>
                    <span>Total Efforts</span> {{leadView?.TotalEfforts}} {{leadView?.EffortFromUnit?.DurationUnitName}}
                </p>
            </div>
            <div class="col-md-3">
                <p>
                    <span>Amount</span> {{leadViewCurrency}} {{leadView?.Amount ? leadView?.Amount :
                    leadView?.Rate}}/{{leadView?.QuantityUnit?.DurationUnitName}}
                </p>
            </div>
            <div class="col-md-2">
                <p>
                    <span>Total Amount</span> {{leadViewCurrency}} {{leadView?.TotalAmount ? leadView?.TotalAmount :
                    leadView?.SubTotal}}
                </p>
            </div>
            <div class="col-md-3" *ngIf="leadView?.ExperienceFrom">
                <p>
                    <span>Experience Range</span> {{leadView?.ExperienceFrom}}
                    {{leadView?.ExperienceFromUnit?.DurationUnitName}} to {{leadView?.ExperienceTo}}
                    {{leadView?.ExperienceToUnit?.DurationUnitName}}
                </p>
            </div>
            <div class="col-md-4" *ngIf="leadView?.QualificationList?.length > 0">
                <p>
                    <span>Qualification</span>
                    <ng-container *ngFor="let qua of leadView?.QualificationList;let l=last">
                        {{qua.QualificationName}}
                        <ng-container *ngIf="!l">,
                        </ng-container>
                    </ng-container>
                </p>
            </div>
            <div class="col-md-5" *ngIf="leadView?.KeySkillList?.length > 0">
                <p>
                    <span>Skills</span>
                    <ng-container *ngFor="let skill of leadView?.KeySkillList;let l=last">
                        {{skill.KeySkillName}}
                        <ng-container *ngIf="!l">,
                        </ng-container>
                    </ng-container>
                </p>
            </div>
            <div class="col-md-7" *ngIf="leadView?.City?.CityName">
                <p>
                    <span>Location</span> {{leadView?.City?.CityName}}, {{leadView?.State?.StateName}},
                    {{leadView?.Country?.CountryName}}
                </p>
            </div>
        </div>
    </div>
</div>

<div *ngIf="dialogAction == 'addModulePhase' || dialogAction == 'editModulePhase'" class="prsn_dialog cost_rework">
    <button class="closeModal" (click)="devicecloseFalse(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <div class="modal_section">
        <h2>Add New Module/Phase</h2>
        <form class="form_layout" #f="ngForm" (ngSubmit)="f.form.valid && onSubmitPhase()">
            <div fxLayout="row wrap">
                <div class="flex-item" fxFlex="0 1 100">
                    <mat-radio-group [(ngModel)]="leadProjectModel.IsModule" name="IsModule">
                        <mat-radio-button [value]="true">Module</mat-radio-button>
                        <mat-radio-button [value]="false">Phase</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="flex-item" fxFlex="0 1 100">
                    <mat-form-field appearance="outline">
                        <mat-label>Module Name</mat-label>
                        <input matInput [(ngModel)]="leadProjectModel.ModuleName" name="ModuleName" required>
                    </mat-form-field>
                </div>
                <div class="flex-item" fxFlex="0 1 100">
                    <mat-form-field appearance="outline">
                        <mat-label>Description</mat-label>
                        <textarea matInput [(ngModel)]="leadProjectModel.Description" name="Description"
                            required></textarea>
                    </mat-form-field>
                </div>
                <div class="flex-item" fxFlex="0 1 100">
                    <button mat-flat-button color="primary" [disabled]="!f.valid">{{dialogAction == 'addModulePhase' ?
                        'Save' : 'Update'}}</button>
                </div>
            </div>
        </form>
    </div>
</div>
