import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-entitlements',
  templateUrl: './entitlements.component.html',
  styleUrls: ['./entitlements.component.scss']
})
export class EntitlementsComponent {

  obj = {
    heading: "Entitlements Codes",
    addModalTitle: "Entitlements Codes",
    addModelAction: "AddEntitlements",
    baseUrlType: 'Expense',
    editModalTitle: "Entitlements Codes",
    editModelAction: "EditEntitlements",
    endPoint: "ExpenseEntitlementsCodeList",
    addColumns: 1,
    colHeadings: { col1: 'Entitlementcode Name'},
    sortableColumns: [
      { data: '0', Title: '#', width:25},
      { data: '	EntitlementsCode', Title: 'Entitlements Code'},
      // { data: '	2', Title: 'Monthly Mobile Limit'},
      // { data: '	3', Title: 'Local Conveyance (Per/KM)'},
      // { data: '	4', Title: 'DA/Per Day'},
      // { data: '	5', Title: 'Hotel Limit/ Per Night'},
      // { data: '	6', Title: 'Own Arrangement'},
      { data: '2', Title: 'Created on'},
      { data: '3', Title: 'Action', width:60},
    ],
    deleteEndPoint: 'ExpenseEntitlementsCodeDelete',
    getListObj: {
      Entitlementcodename: '_blank'
    }
  }

}
