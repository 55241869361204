import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { HttpClientModule } from '@angular/common/http';
import { MatCheckboxModule, MatCheckbox } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule, MatRadioGroup } from '@angular/material/radio';
import { DataTablesModule } from 'angular-datatables';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule, MatSlideToggle } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatChipsModule } from '@angular/material/chips';
import { FusePipesModule } from './pipes/pipes.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { AlphabateOnlyDirective } from './directives/alphabate-only.directive';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBadgeModule } from '@angular/material/badge';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ItemSidebarComponent } from 'app/main/common/item-sidebar/item-sidebar.component';




@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatChipsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatToolbarModule,
        MatListModule,
        MatTreeModule,
        FlexLayoutModule,
        MatIconModule,
        MatButtonModule,
        MatTableModule,
        MatCardModule,
        MatInputModule,
        MatSelectModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatRadioModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatExpansionModule,
        MatStepperModule,
        MatProgressBarModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatDialogModule,
        MatDatepickerModule,
        MatGridListModule,
        FusePipesModule,
        DataTablesModule,
        MatAutocompleteModule,
        MatRippleModule,
        MomentDateModule,
        MatSidenavModule,
        MatProgressSpinnerModule,
        MatBadgeModule,
        ClipboardModule
    ],
    declarations: [NumberOnlyDirective, AlphabateOnlyDirective, ItemSidebarComponent],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        FlexLayoutModule,
        MatChipsModule,
        MatIconModule,
        MatButtonModule,
        MatTableModule,
        MatInputModule,
        MatSelectModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatToolbarModule,
        MatListModule,
        MatTreeModule,
        MatCheckbox,
        MatTooltipModule,
        MatDatepickerModule,
        MatRadioGroup,
        MatFormFieldModule,
        MatNativeDateModule,
        MatExpansionModule,
        MatStepperModule,
        MatRadioModule,
        MatProgressBarModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatCardModule,
        MatDialogModule,
        MatSortModule,
        MatPaginatorModule,
        DataTablesModule,
        MatGridListModule,
        FusePipesModule,
        MatAutocompleteModule,
        MatRippleModule,
        MomentDateModule,
        NumberOnlyDirective,
        AlphabateOnlyDirective,
        MatSidenavModule,
        MatProgressSpinnerModule,
        MatBadgeModule,
        ClipboardModule,
        ItemSidebarComponent
    ]
    /*  declarations: [FilterPipe] */
})
export class PlanworkSharedModule { }
