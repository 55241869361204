<div class="content">
    <div class="page-heading">
        <div fxFlex fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
                <h1>Register Company</h1>
            </div>
        </div>
    </div>

    <div class="content_body" *ngIf="ShowBasic">
        <div class="designStep">
            <!--  linear="true" -->
            <mat-horizontal-stepper #stepper>
                <mat-step [stepControl]="f">
                    <form class="add_form_step1" #f="ngForm" (ngSubmit)="f.form.invalid" (ngSubmit)="f.form.valid">
                        <ng-template matStepLabel>Company Detail</ng-template>
                        <div class="form_details">
                            <div fxLayout="row wrap" class="grid_row" fxLayoutWrap fxLayoutAlign="start start">
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Name of Company</mat-label>
                                        <input matInput placeholder=""
                                            [class.error]="f.submitted && CompanyName.invalid"
                                            [(ngModel)]="RegisterCompanyInput.CompanyName" name="CompanyName"
                                            #CompanyName="ngModel" required>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Nick Name of Company</mat-label>
                                        <input matInput placeholder=""
                                            [class.error]="f.submitted && CompanyNickName.invalid"
                                            [(ngModel)]="RegisterCompanyInput.CompanyNickName" name="CompanyNickName"
                                            #CompanyNickName="ngModel" required>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Company Type</mat-label>
                                        <mat-select placeholder="" (selectionChange)="CompanyTypeSelect($event)"
                                            [class.error]="f.submitted && CompanyTypeId.invalid"
                                            [(ngModel)]="RegisterCompanyInput.CompanyTypeId" name="CompanyTypeId"
                                            #CompanyTypeId="ngModel" required>
                                            <mat-option *ngFor="let company of CompanyTypeList"
                                                [value]="company.CompanyTypeId">{{company.CompanyTypeName}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Industry Type</mat-label>
                                        <mat-select placeholder="" (selectionChange)="IndustryTypeSelect($event)"
                                            [class.error]="f.submitted && IndustryTypeId.invalid"
                                            [(ngModel)]="RegisterCompanyInput.IndustryTypeId" name="IndustryTypeId"
                                            #IndustryTypeId="ngModel" required>
                                            <mat-option *ngFor="let industry of IndustryTypeList"
                                                [value]="industry.IndustryTypeId">{{industry.IndustryTypeName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Registration No.</mat-label>
                                        <input matInput placeholder=""
                                            [class.error]="f.submitted && RegistrationNo.invalid"
                                            [(ngModel)]="RegisterCompanyInput.RegistrationNo" name="RegistrationNo"
                                            #RegistrationNo="ngModel" required>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Date of Incorporation</mat-label>
                                        <input matInput [matDatepicker]="picker" (click)="picker.open()"
                                            [class.error]="f.submitted && DateOfInCorporation.invalid"
                                            [(ngModel)]="RegisterCompanyInput.DateOfInCorporation"
                                            name="DateOfInCorporation" #DateOfInCorporation="ngModel" required>
                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                            <mat-icon matDatepickerToggleIcon>
                                                <img src="assets/images/date.svg" alt="date">
                                            </mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Email ID</mat-label>
                                        <input type="email" matInput placeholder=""
                                            pattern='^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
                                            [class.error]="f.submitted && EmailId.invalid"
                                            [(ngModel)]="RegisterCompanyInput.EmailId" name="EmailId" #EmailId="ngModel"
                                            required>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Website</mat-label>
                                        <input matInput placeholder="" [class.error]="f.submitted && Website.invalid"
                                            [(ngModel)]="RegisterCompanyInput.Website" name="Website" #Website="ngModel"
                                            required>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Nature of Business</mat-label>
                                        <mat-select placeholder="" (selectionChange)="NatureOfBusinessSelect($event)"
                                            [class.error]="f.submitted && NatureOfBusinessId.invalid"
                                            [(ngModel)]="RegisterCompanyInput.NatureOfBusinessId"
                                            name="NatureOfBusinessId" #NatureOfBusinessId="ngModel" required>
                                            <mat-option *ngFor="let nature of NatureOfBusinessList"
                                                [value]="nature.NatureOfBusinessId">{{nature.NatureOfBusinessName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div fxLayout="row wrap" class="grid_row" fxLayoutWrap fxLayoutAlign="start start">
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Registered Address</mat-label>
                                        <textarea matInput placeholder=""
                                            [class.error]="f.submitted && RegisteredAddress.invalid"
                                            [(ngModel)]="RegisterCompanyInput.RegisteredAddress"
                                            name="RegisteredAddress" #RegisteredAddress="ngModel" required></textarea>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Communication Address*</mat-label>
                                        <textarea matInput placeholder=""
                                            [class.error]="f.submitted && CommunicationAddress.invalid"
                                            [(ngModel)]="RegisterCompanyInput.CommunicationAddress"
                                            name="CommunicationAddress" #CommunicationAddress="ngModel"
                                            required></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="btnSubmit">
                                <button mat-flat-button mat-primary color="primary" type="submit" matStepperNext
                                    (click)="firstSaveData()">Save & Proceed</button>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="f2">
                    <form class="add_form_step1" #f2="ngForm" (ngSubmit)="f2.form.invalid" (ngSubmit)="f2.form.valid">
                        <ng-template matStepLabel>License Detail</ng-template>
                        <div class="form_details">
                            <div fxLayout="row wrap" class="grid_row" fxLayoutWrap fxLayoutAlign="start start">
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>User ID</mat-label>
                                        <input matInput placeholder="" [class.error]="f2.submitted && LoginEmailId.invalid"
                                            [(ngModel)]="RegisterCompanyInput.LoginEmailId" name="LoginEmailId" #LoginEmailId="ngModel"
                                            required>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Password</mat-label>
                                        <input matInput placeholder="" [class.error]="f2.submitted && Password.invalid"
                                            [(ngModel)]="RegisterCompanyInput.Password" name="Password"
                                            #Password="ngModel" required>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Date of Creation</mat-label>
                                        <input matInput [matDatepicker]="picker3" required
                                            [class.error]="f2.submitted && DateOfCreation.invalid"
                                            [(ngModel)]="RegisterCompanyInput.DateOfCreation" name="DateOfCreation"
                                            #DateOfCreation="ngModel">
                                        <mat-datepicker-toggle matSuffix [for]="picker3">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker3></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>License No.</mat-label>
                                        <input matInput placeholder="" [class.error]="f2.submitted && LicenseNo.invalid"
                                            [(ngModel)]="RegisterCompanyInput.LicenseNo" name="LicenseNo"
                                            #LicenseNo="ngModel" required>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Name</mat-label>
                                        <input matInput placeholder=""
                                            [class.error]="f2.submitted && LicenceName.invalid"
                                            [(ngModel)]="RegisterCompanyInput.LicenceName" name="LicenceName"
                                            #LicenceName="ngModel" required>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Phone No.</mat-label>
                                        <input matInput placeholder=""
                                            [class.error]="f2.submitted && LicencePhoneNo.invalid"
                                            [(ngModel)]="RegisterCompanyInput.LicencePhoneNo" name="LicencePhoneNo"
                                            #LicencePhoneNo="ngModel" required>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Email ID</mat-label>
                                        <input matInput placeholder="" placeholder=""
                                            pattern='^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
                                            [class.error]="f.submitted && LicenceEmailId.invalid"
                                            [(ngModel)]="RegisterCompanyInput.LicenceEmailId" name="LicenceEmailId"
                                            #LicenceEmailId="ngModel" required>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Designation</mat-label>
                                        <input matInput placeholder=""
                                            [class.error]="f2.submitted && LicenceDesignation.invalid"
                                            [(ngModel)]="RegisterCompanyInput.LicenceDesignation"
                                            name="LicenceDesignation" #LicenceDesignation="ngModel" required>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item " fxLayout="column" fxFlex="0 1 38">
                                    <div class="upload_work">
                                        <label for="">Upload Certificate of Incorporation</label>
                                        <div class="attach_file mb-0">
                                            <img src="/assets/icons/attach_icon.svg" alt=""> Browse or drop file
                                            here
                                            <input type="file"
                                                (change)="resumeUpload($event, RegisterCompanyInput)"
                                                class="upload">
                                        </div>
                                        <span class="file-uploadname"
                                            (click)="downloadMyFile(RegisterCompanyInput.imagePreview, RegisterCompanyInput.CertificateImageName)">{{RegisterCompanyInput.CertificateImageName}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="btnSubmit">
                                <button mat-flat-button mat-primary color="primary" matStepperNext
                                    (click)="secondSaveData()">Save & Proceed</button>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="f3">
                    <form class="add_form_step1" #f3="ngForm" (ngSubmit)="f3.form.invalid" (ngSubmit)="f3.form.valid">
                        <ng-template matStepLabel>Owner Info</ng-template>
                        <div class="form_details">
                            <div fxLayout="row wrap" class="grid_row" fxLayoutWrap fxLayoutAlign="start start">
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Name</mat-label>
                                        <input matInput placeholder="" [class.error]="f3.submitted && OwnerName.invalid"
                                            [(ngModel)]="RegisterCompanyInput.OwnerName" name="OwnerName"
                                            #OwnerName="ngModel" required>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Email ID</mat-label>
                                        <input matInput placeholder="" placeholder=""
                                            pattern='^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
                                            [class.error]="f.submitted && OwnerEmailId.invalid"
                                            [(ngModel)]="RegisterCompanyInput.OwnerEmailId" name="OwnerEmailId"
                                            #OwnerEmailId="ngModel" required>
                                    </mat-form-field>
                                </div>
                                <div class="flex-item" fxLayout="column" fxFlex="0 1 38">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Phone No.</mat-label>
                                        <input matInput placeholder=""
                                            [class.error]="f.submitted && OwnerPhoneNo.invalid"
                                            [(ngModel)]="RegisterCompanyInput.OwnerPhoneNo" name="OwnerPhoneNo"
                                            #OwnerPhoneNo="ngModel" required>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="btnSubmit">
                                <button mat-flat-button mat-primary type="submit" (click)="thirdSaveData()"
                                    color="primary">Sign In</button>
                            </div>
                        </div>
                    </form>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>

    <div class="innerPart" *ngIf="ShowConfiguration">
        <div class="row">
            <div class="col-sm-7">
                <div class="servBox">
                    <div class="welcomeDiv">
                        <h1>Hi {{RegisterCompanyInput.OwnerName}}</h1>
                        <h2>Welcome to the PlanWork</h2>
                        <h3>What would you like to use/purchase?</h3>
                        <p>Please select the modules which you would like to use/purchase.</p>
                    </div>
                    <ul class="pikServItem">
                        <li *ngFor="let item of BusinessProcessList; let i = index;">
                            <mat-checkbox (change)="checkOption($event,item,i)">
                                <img src="assets/icons/planIcon01.svg" alt="Services">
                            </mat-checkbox>
                            <span class="servName">{{item.BusinessProcessName}}</span>
                        </li>
                    </ul>
                    <div class="submitBtn">
                        <button mat-flat-button mat-primary color="primary" (click)="finalSubmit()">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>