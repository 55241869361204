<div class="content changePadding inputFilter" [class.dateFilter]="obj.endPoint == 'WorkingScheduleList' "
  id="dataFilterTop" [class.allattributes]="isAttributesShow"
  [class.employee-outpage]="obj.endPoint == 'EmployeeListNew' || obj.endPoint == 'ExistEmployeeList' || obj.endPoint == 'ServiceActivityList' || obj.endPoint == 'ServiceSubActivityList' || obj.endPoint == 'ServiceTaskList'">
  <div class="page-heading">
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
        <h1 *ngIf="!isAttributesShow">{{obj.heading}}</h1>
        <div class="lead_info" *ngIf="isAttributesShow">
          <h1>Attributes</h1>
          <p>{{attributeNameList}}</p>
        </div>
      </div>
      <div class="mini_btn" fxFlex="0 1 auto" *ngIf="!isAttributesShow">
        <ng-container *ngIf="obj.endPoint != 'ExistEmployeeList'">
          <!-- <a routerLink="." class="otheremp-link" (click)="formerEmpPage('forEmp')"
                        *ngIf="obj.endPoint == 'EmployeeListNew'">Former Employees</a>
                    <a routerLink="." class="otheremp-link" (click)="formerEmpPage('curEmp')"
                        *ngIf="obj.endPoint == 'ExistEmployeeList'">Current Employees</a> (dateChange)="ChangeDate($event)" -->

          <div fxFlex="auto" fxLayout="row" style="width: 50px; justify-content: end;" class="mr-2"
            *ngIf="obj.endPoint == 'WorkingScheduleList'">
            <input matInput [matDatepicker]="picker" [(ngModel)]="dateSearch" name="dateSearch"
              (dateChange)="ChangeDate(dateSearch)" style="visibility: hidden;">
            <mat-datepicker #picker></mat-datepicker>
            <button mat-mini-fab color="accent" class="gray_btn" (click)="picker.open()">
              <mat-icon>calendar_today</mat-icon>
            </button>
          </div>
          <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="!obj?.IsShow">
            <button class="add-lead-btn" mat-flat-button color="accent" (click)="openDialog()">
              <mat-icon>add</mat-icon> Add New
            </button>
          </div>
        </ng-container>
        <!-- <button *ngIf="obj.endPoint == 'ExistEmployeeList'" mat-mini-fab color="primary" #tooltip="matTooltip"
                    matTooltip="Exit Employee" class="emp-rightfilterbtn" (click)="prsnDialog()">
                    Exit Employee
                </button> -->
      </div>
      <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center" *ngIf="isAttributesShow">
        <button (click)="isAttributesShow = false" class="back_row" mat-button>
          <mat-icon class="back_arrow">keyboard_arrow_left</mat-icon> Back to List
        </button>
      </div>
    </div>
  </div>


  <div class="content_body server_side_page" [class.h-auto]="isAttributesShow">
    <!-- tab for employee -->
    <mat-tab-group color="primary" *ngIf="obj.endPoint == 'EmployeeListNew'">
      <mat-tab label="Current">
        <div *ngIf="obj.endPoint == 'EmployeeListNew' || obj.endPoint == 'ExistEmployeeList'">
          <div class="filter_area" style="position: relative; z-index: 1;">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div fxFlex="1 0 auto">
                <div class="top_drop filter_box1" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                  <div class="input_group filter_box">
                    <p class="left_label">Filter By</p>
                    <mat-form-field appearance="outline">
                      <mat-select placeholder="All Type" name="BusinessProcess"
                        (selectionChange)="empFilter($event.value)" [(value)]="selected">
                        <mat-option value="all">
                          All
                        </mat-option>
                        <mat-option *ngFor="let item of employeeCategorys" [value]="item">
                          {{item.EmployeeCategoryName}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="mini_btn" fxFlex="0 1 auto">
                <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <button mat-mini-fab color="primary" #tooltip="matTooltip" matTooltip="Exit Employee"
                    class="emp-rightfilterbtn" (click)="prsnDialog()" *ngIf="obj.endPoint == 'ExistEmployeeList'">
                    Exit Employee
                  </button>
                  <button mat-mini-fab color="accent" class="gray_btn">
                    <img src="assets/icons/table.svg" alt="">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <table id="table1" datatable [dtOptions]="dtOptions" class="row-border hover list_table">
          <thead>
            <tr *ngIf="tblClm?.length != 0">

              <th *ngFor="let colHead of tblClm">{{colHead.Title}}</th>
            </tr>
          </thead>
          <tbody *ngIf="persons?.length !== 0">
            <tr *ngFor="let element of persons, let i = index" [class.active]="element.Ischecked">

              <td>{{i+1+tabledata.start}}
              </td>
              <td *ngIf="colHeadings.col1">
                <span *ngIf="obj.endPoint == 'EmployeeListNew' && element?.IsVerified">
                  <img width="20px" src="./assets/images/updated.svg" />
                </span>
                <span *ngIf="obj.endPoint == 'ExistEmployeeList' && element?.IsExitEmployeeVerified">
                  <img width="20px" src="./assets/images/updated.svg" />
                </span> {{ element.EmployeeCodeNew }}


              </td>
              <td *ngIf="colHeadings.col2"
                [class.d-none]="obj.endPoint == 'EmployeeListNew' || obj.endPoint == 'ExistEmployeeList'">
                {{ element.EmployeeCategoryCode || element?.StrategicBusinessHead?.EmployeeName }}
                <span *ngIf="element?.CostCenterCategory?.StrategicBusinessHead?.EmployeeName">
                  ({{element?.CostCenterCategory?.StrategicBusinessHead?.EmployeeName}})</span>
              </td>
              <td *ngIf="colHeadings.col3">
                {{ element?.StrategicBusinessHead?.EmployeeName ||
                element?.CostCenterList?.CostCenterName?.CostCenterName || element?.EmployeeName}}
              </td>
              <td *ngIf="colHeadings.col4">{{ element?.Type?.TypeName || element?.ServiceTaskCode ||
                element?.Employee?.EmployeeName || element?.Type || element.ShiftToTime ||
                element?.Department?.DepartmentName}}
                <ng-container *ngIf="element?.CostCenterList?.length > 0">
                  <span class="d-block" *ngFor="let cost of element.CostCenterList; let l = last">
                    {{cost?.CostCenterName?.CostCenterName}}<ng-container *ngIf="!l">,
                    </ng-container>
                  </span>
                </ng-container>

              </td>
              <td *ngIf="colHeadings.col5">{{ element.OfficialEmailId ||
                element?.Department.DepartmentName }}</td>
              <td *ngIf="colHeadings.col6">{{element?.Department.DepartmentName}} </td>
              <td>{{(element?.CreatedBy?.CreatedDate | date:'MMM d, yyyy')}}</td>
              <td>
                <div class="action_icons">
                  <ng-container *ngIf="obj.endPoint != 'ExistEmployeeList' ">
                    <div *ngIf="showEdit" class="icon" (click)="editMaster(element, obj.endPoint)" matTooltip="Edit">
                      <mat-icon>edit</mat-icon>
                    </div>
                    <div
                      *ngIf="showEdit && element?.EmployeeCategory?.EmployeeCategoryId == 2 || element?.EmployeeCategory?.EmployeeCategoryId == 3"
                      class="icon" (click)="editMigrate(element)" matTooltip="Migrate to Employee">
                      <mat-icon>manage_accounts</mat-icon>
                    </div>
                  </ng-container>
                  <div class="icon" (click)="deleteMaster(element, obj.deleteEndPoint)" matTooltip="Delete"
                    *ngIf="obj.endPoint != 'EmployeeListNew' && obj.endPoint != 'ExistEmployeeList'">
                    <mat-icon>delete</mat-icon>
                  </div>
                  <ng-container *ngIf="obj.endPoint == 'ExistEmployeeList' && !element.IsEditable">
                    <div class="icon" (click)="viewEmployee(element, 'view')" matTooltip="View">
                      <mat-icon>visibility</mat-icon>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="obj.endPoint == 'ExistEmployeeList' && element.IsEditable">
                    <div class="icon" (click)="viewEmployee(element, 'edit')" matTooltip="Edit">
                      <mat-icon>edit</mat-icon>
                    </div>
                  </ng-container>
                  <!-- <div class="icon" (click)="feedbackInfo(element.Remark)" matTooltip="Feedback"
                                    *ngIf="obj.endPoint == 'ExistEmployeeList'">
                                    <mat-icon>chat</mat-icon>
                                </div> -->
                </div>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="persons?.length == 0">
            <tr class="text-center">
              <td [attr.colspan]="tblClmLength" class="no-data-available">No data!</td>
            </tr>
          </tbody>
        </table>

      </mat-tab>
      <mat-tab label="Past">

      </mat-tab>

    </mat-tab-group>
    <div
      *ngIf="obj.endPoint != 'EmployeeListNew' && obj.endPoint != 'ModeOfConveyanceList' && obj.endPoint != 'EntitlementsList' && obj.endPoint != 'ConsumableList' && obj.endPoint != 'UomList'">
      <div *ngIf="obj.endPoint == 'EmployeeListNew' || obj.endPoint == 'ExistEmployeeList'">
        <div class="filter_area" style="position: relative; z-index: 1;">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="1 0 auto">
              <div class="top_drop filter_box1" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                <div class="input_group filter_box">
                  <p class="left_label">Filter By</p>
                  <mat-form-field appearance="outline">
                    <mat-select placeholder="All Type" name="BusinessProcess"
                      (selectionChange)="empFilter($event.value)" [(value)]="selected">
                      <mat-option value="all">
                        All
                      </mat-option>
                      <mat-option *ngFor="let item of employeeCategorys" [value]="item">
                        {{item.EmployeeCategoryName}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="mini_btn" fxFlex="0 1 auto">
              <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <button mat-mini-fab color="primary" #tooltip="matTooltip" matTooltip="Exit Employee"
                  class="emp-rightfilterbtn" (click)="prsnDialog()" *ngIf="obj.endPoint == 'ExistEmployeeList'">
                  Exit Employee
                </button>
                <button mat-mini-fab color="accent" class="gray_btn">
                  <img src="assets/icons/table.svg" alt="">
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- shift filter -->

      <div *ngIf="obj.endPoint == 'WorkingScheduleList'" class="d-flex justify-content-between"
        style="margin-top:30px;">
        <div class="filter_area" style="position: relative; z-index: 1;">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="1 0 auto">
              <div class="top_drop filter_box1" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                <div class="input_group filter_box">
                  <p class="left_label">Shift By</p>
                  <mat-form-field appearance="outline">
                    <mat-select placeholder="All Type" name="BusinessProcess"
                      (selectionChange)="empFilter($event.value)" [(value)]="selected">
                      <mat-option value="all">
                        All
                      </mat-option>
                      <mat-option *ngFor="let item of GetCompanyShiftList" [value]="item">
                        {{item.ShiftName}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <!-- <span *ngIf="masterSelected==true" class="mr-3">Select {{ persons?.length }} record</span>
                    <span class="mr-5" *ngIf="masterSelected==false">Select {{ workingSheDeleteIds?.length }} record </span> -->
          <button style="padding: 0 12px; color:#fff!important" mat-flat-button color="warn" [disabled]="disable"
            (click)="deleteSelectall()">
            Delete Selected
          </button>
        </div>
      </div>

      <!-- City list-->
      <div class="d-flex" *ngIf="obj.endPoint == 'CityList'">
        <div *ngIf="obj.endPoint == 'CityList'" class="d-flex justify-content-between" style="margin-bottom: -15px;">
          <div class="filter_area" style="position: relative; z-index: 1;">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div fxFlex="1 0 auto">
                <div class="top_drop filter_box1" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                  <div class="input_group filter_box">
                    <p class="left_label">Country </p>
                    <mat-form-field appearance="outline">
                      <mat-select [(value)]="CountryName" name="Country"
                        (selectionChange)="getStateDropDown($event.value.CountryId)" placeholder="Select"
                        [(ngModel)]="Country">
                        <fuse-mat-select-search name="search" [(ngModel)]="CountrySearch">
                        </fuse-mat-select-search>
                        <!-- <mat-option value="all">
                                                All
                                            </mat-option> -->
                        <mat-option *ngFor="let country of CountryDropDownlist | filter: CountrySearch"
                          [value]="country">
                          {{country.CountryName}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="obj.endPoint == 'CityList'" style="margin-bottom: -15px;" class="ml-3">
          <div class="filter_area" style="position: relative; z-index: 1;">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div fxFlex="1 0 auto">
                <div class="top_drop filter_box1" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                  <div class="input_group filter_box">
                    <p class="left_label">State </p>
                    <mat-form-field appearance="outline">
                      <mat-select [(value)]="selected" placeholder="Select"
                        (selectionChange)="getCityList($event.value.StateId, Country)">
                        <fuse-mat-select-search name="search" [(ngModel)]="StateSearch">
                        </fuse-mat-select-search>
                        <!-- <mat-option value="all">
                                                All
                                            </mat-option> -->
                        <mat-option *ngFor="let state of StateLists | filter: StateSearch" [value]="state">
                          {{state.StateName}} </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- State -->
      <div class="d-flex" *ngIf="obj.endPoint == 'StateList'">
        <div *ngIf="obj.endPoint == 'StateList'" class="d-flex justify-content-between" style="margin-bottom: -15px;">
          <div class="filter_area" style="position: relative; z-index: 1;">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div fxFlex="1 0 auto">
                <div class="top_drop filter_box1" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                  <div class="input_group filter_box">
                    <p class="left_label">Country </p>
                    <mat-form-field appearance="outline">
                      <mat-select [(value)]="CountryName" name="Country"
                        (selectionChange)="getstateList($event.value.CountryId)" placeholder="Select"
                        [(ngModel)]="Country">
                        <fuse-mat-select-search name="search" [(ngModel)]="CountrySearch">
                        </fuse-mat-select-search>
                        <!-- <mat-option value="all">
                                                All
                                            </mat-option> -->
                        <mat-option *ngFor="let country of CountryDropDownlist | filter: CountrySearch"
                          [value]="country">
                          {{country.CountryName}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- service Category -->
      <div class="d-flex" *ngIf="obj.endPoint == 'ServiceCategoryList'">
        <div *ngIf="obj.endPoint == 'ServiceCategoryList'" class="d-flex justify-content-between"
          style="margin-bottom: -15px;">
          <div class="filter_area" style="position: relative; z-index: 1;">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div fxFlex="1 0 auto">
                <div class="top_drop filter_box1" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                  <div class="input_group filter_box mt-5">
                    <p class="left_label">SBU</p>
                    <mat-form-field appearance="outline">
                      <mat-select placeholder="select" (selectionChange)='empFilter($event.value)'>
                        <fuse-mat-select-search name="search" [(ngModel)]="SBU">
                        </fuse-mat-select-search>
                        <mat-option value="all">
                          All
                        </mat-option>
                        <mat-option *ngFor="let item of SbuList | filter : SBU" [value]="item">
                          {{item.CostCenterCategoryName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- service type -->
      <div class="d-flex" *ngIf="obj.endPoint == 'ServiceTypeList'">
        <div *ngIf="obj.endPoint == 'ServiceTypeList'" class="d-flex justify-content-between"
          style="margin-bottom: -15px;">
          <div class="filter_area" style="position: relative; z-index: 1;">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div fxFlex="1 0 auto">
                <div class="top_drop filter_box1" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                  <div class="input_group filter_box mt-5">
                    <p class="left_label">Service Category </p>
                    <mat-form-field appearance="outline">
                      <mat-select class="form-control" name="ServiceCategory" placeholder="Select"
                        (selectionChange)='empFilter($event.value)'>
                        <fuse-mat-select-search name="search" [(ngModel)]="ServiceCategoryName">
                        </fuse-mat-select-search>
                        <mat-option value="all">
                          All
                        </mat-option>
                        <mat-option *ngFor="let d_list of serviceList | filter : ServiceCategoryName" [value]="d_list">
                          {{d_list.ServiceCategoryName}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Asset Class-->
      <div class="d-flex mt-3" *ngIf="obj.endPoint == 'AssetCategoryList'">
        <div class="d-flex justify-content-between" style="margin-bottom: -15px;">
          <div class="filter_area" style="position: relative; z-index: 1;">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div fxFlex="1 0 auto">
                <div class="top_drop mr-3" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center"
                  *ngIf="obj.endPoint == 'AssetCategoryList'">
                  <div class="input_group filter_box">
                    <p class="left_label">Asset Class</p>
                    <mat-form-field appearance="outline">
                      <mat-select placeholder="All Type" name="BusinessProcess"
                        (selectionChange)="empFilter($event.value)" [(value)]="selected">
                        <mat-option value="all">
                          All
                        </mat-option>
                        <mat-option *ngFor="let item of AssetClassDropdown" [value]="item">
                          {{item.AssetClassName}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex mt-3" *ngIf="obj.endPoint == 'AssetItemList'">
        <div class="d-flex justify-content-between" style="margin-bottom: -15px;">
          <div class="filter_area" style="position: relative; z-index: 1;">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div fxFlex="1 0 auto">
                <div class="top_drop mr-3" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center"
                  *ngIf="obj.endPoint == 'AssetItemList'">
                  <div class="input_group filter_box">
                    <p class="left_label">Asset Class</p>
                    <mat-form-field appearance="outline" class="mb-0 mt-15">
                      <mat-select placeholder="All Type" (selectionChange)="Assetfilter($event.value,'assetclass')"
                        [(value)]="selected5">
                        <mat-option value="all"> All </mat-option>
                        <mat-option *ngFor="let item of AssetClassDropdown" [value]="item">
                          {{item.AssetClassName}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div fxFlex="1 0 auto">
                <div class="top_drop mr-3" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center"
                  *ngIf="obj.endPoint == 'AssetItemList'">
                  <div class="input_group filter_box">
                    <p class="left_label">Asset Category</p>
                    <mat-form-field appearance="outline" class="mb-0 mt-15">
                      <mat-select placeholder="All Type" (selectionChange)="Assetfilter($event.value,'assetcategory')"
                        [(value)]="selected4">
                        <mat-option value="all">All Category</mat-option>
                        <mat-option *ngFor="let list of AssetCategoryDropdown" [value]="list">
                          {{list?.AssetCategoryName}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="obj.endPoint == 'ServiceActivityList' || obj.endPoint == 'ServiceSubActivityList' || obj.endPoint == 'ServiceTaskList'">
        <div class="filter_area" style="position: relative; z-index: 1;">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div>
              <div class="top_drop mr-3" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                <div class="input_group filter_box">
                  <p class="left_label">Tabs</p>
                  <mat-form-field appearance="outline">
                    <mat-select class="form-control" name="Category"
                      (selectionChange)="CatServiceActivityDrpList($event); empFilter($event.value)"
                      [(value)]="CategoryName" placeholder="Select" [(ngModel)]="CategoryName">
                      <mat-option [value]="0">All</mat-option>
                      <mat-option *ngFor="let Category of ServiceActivityDropDownList" [value]="Category">
                        {{Category.CategoryName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="top_drop mr-3" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center"
                *ngIf="obj.endPoint == 'ServiceSubActivityList' || obj.endPoint == 'ServiceTaskList'">
                <div class="input_group filter_box">
                  <p class="left_label">Category</p>
                  <mat-form-field appearance="outline">
                    <mat-select placeholder="Select" class="form-control" name="Category"
                      (selectionChange)="CatServiceSubActivityDrpList($event); empFilter($event.value)"
                      [(value)]="ServiceActivityName" [(ngModel)]="ServiceActivityName">
                      <mat-option value="all">All</mat-option>
                      <mat-option *ngFor="let Category of ServiceCatActivityDropDownList" [value]="Category">
                        {{Category.ServiceActivityName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>


              <div class="top_drop" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center"
                *ngIf="obj.endPoint == 'ServiceTaskList'">
                <div class="input_group filter_box">
                  <p class="left_label">Sub-Category</p>
                  <mat-form-field appearance="outline">
                    <mat-select placeholder="Select" class="form-control" name="SubCategory"
                      [(value)]="ServiceSubActivityName" [(ngModel)]="ServiceSubActivityName"
                      (selectionChange)="empFilter($event.value)">
                      <mat-option value="all">All</mat-option>
                      <mat-option *ngFor="let Category of ServiceCatSubActivityDropDownList" [value]="Category">
                        {{Category.ServiceSubActivityName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="obj.endPoint == 'DocumentPrintTypeList'">
        <div class="filter_area" style="position: relative; z-index: 1;">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div>
              <div class="top_drop mr-3" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                <div class="input_group filter_box">
                  <p class="left_label">DocPrint Category </p>
                  <mat-form-field appearance="outline">
                    <mat-select class="form-control" name="Category" (selectionChange)='docfilter($event.value)'
                      placeholder="Select">
                      <fuse-mat-select-search name="docPrint" [(ngModel)]="docPrint">
                      </fuse-mat-select-search>
                      <mat-option [value]="0">All</mat-option>
                      <mat-option *ngFor="let Category of docPrintCategoryList | filter : docPrint" [value]="Category">
                        {{Category.DocPrintCategorieName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="obj.deleteEndPoint == 'WorkingScheduleDelete'" class="workingCheckbox">
        <mat-checkbox [(ngModel)]="masterSelected" name="list_name" value="m1" (change)="checkUncheckAll(persons)">
        </mat-checkbox>
      </div>


      <table id="table1" datatable [dtOptions]="dtOptions" class="row-border hover list_table">
        <!-- <div>
                    <ng-container *ngIf="obj.deleteEndPoint == 'WorkingScheduleDelete'" class="workingCheckbox">
                        <mat-checkbox [(ngModel)]="masterSelected" name="list_name" value="m1" (change)="checkUncheckAll(persons)">
                        </mat-checkbox>
                    </ng-container>
                  </div> -->
        <thead>
          <tr *ngIf="tblClm?.length != 0">
            <!-- <th *ngIf="obj.deleteEndPoint == 'WorkingScheduleDelete'">

                        </th> -->

            <th *ngFor="let colHead of tblClm">{{colHead.Title}}</th>
          </tr>
        </thead>
        <tbody *ngIf="persons?.length !== 0">
          <tr *ngFor="let element of persons, let i = index" [class.active]="element.Ischecked">
            <td *ngIf="obj.deleteEndPoint == 'WorkingScheduleDelete'">
              <mat-checkbox [(ngModel)]="element.Ischecked" (change)="isAllSelected(element, $event, i)">
              </mat-checkbox>
            </td>
            <td>{{i+1+tabledata.start}}
            </td>
            <td *ngIf="colHeadings.col1">
              <span *ngIf="obj.endPoint == 'EmployeeListNew' && element?.IsVerified">
                <img width="20px" src="./assets/images/updated.svg" />
              </span>
              <span *ngIf="obj.endPoint == 'ExistEmployeeList' && element?.IsExitEmployeeVerified">
                <img width="20px" src="./assets/images/updated.svg" />
              </span>
              <!-- <ng-container *ngIf="obj.endPoint == 'AttributeList'">
                <span *ngFor="let list of element?.AssetAttributeNames;let i = index">
                  {{list?.AssetAttributeName}}{{element?.AssetAttributeNames.length > 1 &&
                  element?.AssetAttributeNames[i]!= element?.AssetAttributeNames[element?.AssetAttributeNames.length -1]
                  ? ',' : ''}}
                </span>
              </ng-container> -->

              {{element?.EntitlementCode || element?.EntitlementCodeName || element?.ComplianceCategoryName
              ||element?.Entitlementcodename || element?.BackgroundInfoName || element?.YearName ||
              element?.OccupationName || element?.WorkingShift?.ShiftName ||
              element.ServiceSubActivityName
              || element.QualificationName || element?.ServiceTaskName || element?.UnitName ||
              element?.ServiceSubActivityName || element?.BusinessProcessName || element?.StatusName ||
              element?.CategoryName || element.DurationUnitName ||
              element.TaxPercent ||element.NumberSystemName || element.ServiceCategoryName ||
              element?.OrganisationTypeName || element.KeyFeatureName || element.ProjectActivityName ||
              element?.DocumentCategoryName|| element?.IndustryTypeName
              || element.ReferenceModeName || element.CustomerSegmentName || element?.OrganisationTypeList
              || element?.ProjectCategoryName || element?.ProjectCategory?.ProjectCategoryName ||
              element.ProjectSubActivityName || element?.GenericCategory?.GenericCategoryName
              || element.GenericCategoryName || element?.CostCenterCategoryName || element.CurrencyName||
              element?.BankName || element.BankAccountTypeName || element.DocumentTypeName ||
              element.CityAreaName || element.CityName || element.StateName
              || element?.CountryName || element?.ReasonName ||
              element.LabelName||element?.AllAssetAttribute || element?.AssetItemName ||
              element?.AssetCategoryName || element?.AssetClassName || element?.BufferPercentageName
              || element.EstimationBufferName || element.FinancialYearName || element?.CostCenterTypeName
              || element.CostCenterName || element.PaymentTypeName || element.ProfitCenterName ||
              element.TaxTypeName || element.DepartmentName ||
              element.ShiftName || element.UserRoleName || element.DesignationName ||
              element.SkillCategoryName || element.EmployeeCategoryName || element.LevelName ||
              element.CadreName || element.KeySkillName || element.PaymentModeName
              || element.NationalityName || element.EmployeeCodeNew || element.ProductCategoryName ||
              element.ProductCategory?.ProductCategoryName || element.ServiceActivityName ||
              element.ProjectActivity?.ProjectActivityName || element.ModeOfPOName
              || element.PaymentTermName || element.GeneralTermName || element.InvoicePaymentTermName ||
              element?.ResignationReasonName || element?.TerminationReasonName ||
              element?.ServiceKeyFeatureName || element?.ProjectKeyFeatureName||
              element?.OtherActivityName || element.ServiceTypeName || element.ConsumableClassName ||
              element?.UnitOfMeasurementAbbreviationName || element?.ConveyanceName ||
              element?.CostCenterMasterName ||element?.CodeFormat|| element?.DocPrintCategorieName ||
              element?.AccomodationName || element?.ExpenseName || element?.ConsumableItemName ||
              element?.ConsumableCategoryName || element?.AssetAttributeName}}
              <ng-container *ngIf="element?.HSNCode && element?.HSNPercent">
                {{element?.HSNCode}} - {{element?.HSNPercent}}%
              </ng-container>
            </td>

            <td *ngIf="colHeadings.col2"
              [class.d-none]="obj.endPoint == 'EmployeeListNew' || obj.endPoint == 'ExistEmployeeList'">
              {{element.EmployeeCode ||element?.DocumentCategory?.DocumentCategoryName|| element?.BusinessProcessName ||
              element.CityAreaCode ||
              (element.WorkingScheduleStartDate | date) || element.TotalMonth ||
              element?.ServiceSubActivity?.ServiceSubActivityName
              || element?.CategoryName || (element.AllAssetAttribute ? element?.AssetItemName : element?.AssetItemName ?
              element?.AssetCategoryName :
              element?.AssetClassName) || element?.ServiceActivity?.ServiceActivityName
              || element.Category?.CategoryName || element.ProductTypeName ||
              (element?.HolidayStartDate | date) || (element?.FromTime
              | date) || element.QualificationCode ||
              element?.ServiceSubActivity?.ServiceSubActivityName || element.BusinessProcessCode ||
              element.CategoryCode || element.FrequencyName || element.TaxName || element.AssetItemCode
              || element.ServiceCategoryCode || (element?.StartDate | date) ||
              element.KeyFeatureCode || element.ProjectActivityCode || element.IndustryTypeCode ||
              element.CustomerSegmentCode || element.ProjectCategoryCode
              || element.OrganisationTypeCode || element.ProjectTypeName || element.GenericTypeName ||
              element.GenericCategoryCode || element?.DepartmentCategory?.Type || element.CurrencyCode ||
              element.BankCode || element.BankAccountTypeCode
              || element?.Category?.CategoryName || element.CityCode || element.StateCode ||
              element.CountryCode || element?.BusinessProcess?.BusinessProcessName
              || element.FinancialYearCode || element.CostCenterTypeCode
              || element.CostCenterMasterCode || element.DepartmentCategoryName || element.ShiftCode ||
              element.UserRoleCode || element.DesignationCode || element.SkillCategoryCode ||
              element.EmployeeCategoryCode || element.LevelCode ||
              element.CadreCode || element.KeySkillCode || element.PaymentModeCode ||
              element?.StrategicBusinessHead?.EmployeeName || element?.DepartmentCategory?.Type ||
              element.DesignationCategory?.Type || element?.CostCenterCategory?.CostCenterCategoryName||
              element?.Department?.DepartmentName || element?.ServiceCategory?.ServiceCategoryName ||
              element?.DesignationCategoryName||element?.UnitOfMeasurementName || element?.ConveyanceTypeName ||
              element?.DocPrintTypeName || element?.ConsumableCategoryName}}
              <ng-container *ngIf="element?.MonthlyMobileLimit">
                <span *ngFor="let list of element?.MonthlyMobileLimit">
                  {{list}}
                </span>
              </ng-container>
              <span *ngIf="element?.CostCenterCategory?.StrategicBusinessHead?.EmployeeName">
                ({{element?.CostCenterCategory?.StrategicBusinessHead?.EmployeeName}})</span>
              <ng-container *ngIf="obj.heading == 'Compliances'">
                {{element?.ISGoverment == true? 'Government' : 'Non Government'}}
              </ng-container>
            </td>

            <td *ngIf="colHeadings.col3"> {{ (element?.ToTime | date) || element?.HolidayName
              || element?.DayName || element?.ServiceActivity?.ServiceActivityName || element?.IsLead ||
              element?.ProjectTypeCode || element?.ServiceTypeCode || element?.GenericTypeCode
              || element?.StrategicBusinessHead?.EmployeeName || element?.CurrencySymbol ||
              element?.UnitCode || element?.CityName ||colHeadings.col4 =="State Name" ? element?.State?.StateName :
              element?.Country?.CountryName
              || element?.MobileDigit || element?.ReasonCode
              || (element?.StartDate | date) || element?.PaymentTypeCode ||
              element?.Employee?.EmployeeName ||(element?.AllAssetAttribute ?element?.AssetCategoryName
              :element?.AssetClassName)||
              element?.DepartmentCategory?.Type || element.FromTime || element?.DesignationCategory?.Type
              || element?.Weightage || element?.SkillCategory?.SkillCategoryName ||
              element?.CostCenterList?.CostCenterName?.CostCenterName || element.ShiftFromTime ||
              element.WorkingScheduleTimeStr || element.Type || element.ProductTypeCode
              || element.ProjectTaskName || element.MobileDigit || element?.SACCode ||
              element?.EmployeeName || element?.DepartmentHead || element?.DocPrintCategorieName}}
              <span *ngIf="colHeadings.col3 =='Planning Restriction' ">
                <mat-checkbox [checked]="element.IsPlanningRestriction" (change)="restriction($event,element)">
                </mat-checkbox>
              </span>
              <ng-container *ngIf="element?.LocalCurrencySymbol">
                {{element?.LocalCurrencySymbol}} {{element?.LocalConveyanceAmount}}
              </ng-container>
            </td>


            <td *ngIf="colHeadings.col4">
              {{element?.Country?.CountryName|| element?.Type?.TypeName || element?.ServiceTaskCode || colHeadings.col4
              =="Country Name" ? element?.Country?.CountryName : element?.State?.StateName ||
              colHeadings.col4 =="State Name" ? element?.State?.StateName : element?.Country?.CountryName ||
              element?.AssetClassName|| element?.CountryName || (element?.EndDate
              | date) || element?.Employee?.EmployeeName || (element?.StartDate | date: 'd
              MMM yyyy') || (element?.ToTime | date) || element?.AssetItemName ||
              element?.Type || element.ShiftToTime || element.WorkingScheduleBreakTimeStr
              || element?.Department?.DepartmentName}}
              <ng-container *ngIf="element?.TravelCurrencysymbol">
                {{element?.TravelCurrencysymbol}} {{element?.TravelAmount}}
              </ng-container>
              <ng-container *ngIf="element?.CostCenterList?.length > 0">
                <span class="d-block" *ngFor="let cost of element.CostCenterList; let l = last">
                  {{cost?.CostCenterName?.CostCenterName}}<ng-container *ngIf="!l">,
                  </ng-container>
                </span>
              </ng-container>
              <ng-container *ngIf="element?.SACCode?.HSNCode">
                {{element?.SACCode?.HSNCode}} - {{element?.SACCode?.HSNPercent}}%
              </ng-container>
            </td>
            <td *ngIf="colHeadings.col5">{{element?.CountryName || element?.Country?.CountryName || (element?.StartDate
              | date)
              || element.OfficialEmailId || element?.Department?.DepartmentName ||
              element?.Employee?.EmployeeName}}
              <ng-container *ngIf="element?.HotelCurrencySymbol">
                {{element?.HotelCurrencySymbol}} {{element?.HotelAmount}}
              </ng-container>
            </td>
            <td *ngIf="colHeadings.col6">
              {{element?.Department?.DepartmentName}}
              <ng-container *ngIf="element?.OwnCurrencySymbol">
                {{element?.OwnCurrencySymbol}} {{element?.OwnAmount}}
              </ng-container>
            </td>
            <td *ngIf="element?.CreatedDate">{{(element?.CreatedDate | date:'MMM d, yyyy')}} </td>
            <td *ngIf="element?.CreatedBy">
              {{(element?.CreatedBy?.CreatedDate | date:'MMM d, yyyy')}} </td>
            <td *ngIf="obj.endPoint != 'UnitList'">
              <div class="action_icons">
                <ng-container *ngIf="obj.endPoint != 'ExistEmployeeList' && !obj?.IsShow">
                  <div *ngIf="showEdit" class="icon" (click)="editMaster(element, obj.endPoint)" matTooltip="Edit">
                    <mat-icon>edit</mat-icon>
                  </div>
                  <div
                    *ngIf="showEdit && element?.EmployeeCategory?.EmployeeCategoryId == 2 || element?.EmployeeCategory?.EmployeeCategoryId == 3 "
                    class="icon" (click)="editMigrate(element)" matTooltip="Migrate to Employee">
                    <mat-icon>manage_accounts</mat-icon>
                  </div>
                </ng-container>
                <div class="icon" (click)="deleteMaster(element, obj.deleteEndPoint)" matTooltip="Delete"
                  *ngIf="obj.endPoint != 'EmployeeListNew' && obj.endPoint != 'ExistEmployeeList' && !obj?.IsShow">
                  <mat-icon>delete</mat-icon>
                </div>
                <ng-container *ngIf="obj.endPoint == 'ExistEmployeeList' && !element.IsEditable">
                  <div class="icon" (click)="viewEmployee(element, 'view')" matTooltip="View">
                    <mat-icon>visibility</mat-icon>
                  </div>
                </ng-container>
                <ng-container *ngIf="obj.endPoint == 'ExistEmployeeList' && !element.IsEditable">
                  <div class="icon" (click)="viewEmployee(element, 'employee')" matTooltip="Employee Detail">
                    <mat-icon>person</mat-icon>
                  </div>
                </ng-container>
                <ng-container *ngIf="obj.endPoint == 'ExistEmployeeList' && element.IsEditable">
                  <div class="icon" (click)="viewEmployee(element, 'edit')" matTooltip="Edit">
                    <mat-icon>edit</mat-icon>
                  </div>
                </ng-container>
                <div class="icon" matTooltip="Add Attributes"
                  *ngIf="obj.endPoint == 'AssetClassList' || obj.endPoint == 'AssetCategoryList' || obj.endPoint == 'AssetItemList'"
                  (click)="isAttributesShow = true; addAttributes(element)">
                  <mat-icon>format_list_bulleted</mat-icon>
                </div>
                <!-- <div class="icon" (click)="feedbackInfo(element.Remark)" matTooltip="Feedback"
                                *ngIf="obj.endPoint == 'ExistEmployeeList'">
                                <mat-icon>chat</mat-icon>
                            </div> -->
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="persons?.length == 0">
          <tr class="text-center">
            <td [attr.colspan]="tblClmLength" class="no-data-available">No data!</td>
          </tr>
        </tbody>
      </table>
    </div>

    <ng-container *ngIf="obj.endPoint == 'ModeOfConveyanceList'">
      <table class="table mt-5">
        <thead>
          <tr>
            <th>#</th>
            <th>Conveyance Mode</th>
            <th>Type</th>
            <th>Created on</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let list of [0,1,2,3,4,5,6,7,8,9]">
            <td>1</td>
            <td>Auto</td>
            <td>Local Conveyance</td>
            <td>May 18, 2019</td>
            <td>
              <div class="action_icons">
                <div class="icon" matTooltip="Edit">
                  <mat-icon>edit</mat-icon>
                </div>
                <div class="icon" matTooltip="Delete">
                  <mat-icon>delete</mat-icon>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>

    <ng-container *ngIf="obj.endPoint == 'EntitlementsList'">
      <table class="table mt-5">
        <thead>
          <tr>
            <th>#</th>
            <th>Entitlements Code</th>
            <th>Monthly Mobile Limit</th>
            <th>Local Conveyance (Per/KM)</th>
            <th>DA/Per Day</th>
            <th>Hotel Limit/ Per Night</th>
            <th>Own Arrangement </th>
            <th>Created on</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let list of [0,1,2,3,4,5,6,7,8,9]">
            <td>1</td>
            <td>E1</td>
            <td>INR 300</td>
            <td>INR 3</td>
            <td>INR 350</td>
            <td>INR 650</td>
            <td>INR 500</td>
            <td>May 18, 2019</td>
            <td>
              <div class="action_icons">
                <div class="icon" matTooltip="Edit">
                  <mat-icon>edit</mat-icon>
                </div>
                <div class="icon" matTooltip="Delete">
                  <mat-icon>delete</mat-icon>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>

    <ng-container *ngIf="obj.endPoint == 'ConsumableList'">
      <table class="table mt-5">
        <thead>
          <tr>
            <th>#</th>
            <th>Consumable</th>
            <th>Created on</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let list of [0,1,2,3,4,5,6,7,8,9]; let i = index">
            <td>{{i + 1}}</td>
            <td>Cleaning & Washing Supplies</td>
            <td>May 18, 2019</td>
            <td>
              <div class="action_icons">
                <div class="icon" matTooltip="Edit">
                  <mat-icon>edit</mat-icon>
                </div>
                <div class="icon" matTooltip="Delete">
                  <mat-icon>delete</mat-icon>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
    <!-- <ng-container *ngIf="obj.endPoint == 'UomList'">
            <table class="table mt-5">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Abbreviation</th>
                        <th>Name</th>
                        <th>Created on</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let list of [0,1,2,3,4,5,6,7,8,9]; let i = index">
                        <td>{{i + 1}}</td>
                        <td>cm.</td>
                        <td>Centimetres</td>
                        <td>May 18, 2019</td>
                        <td>
                            <div class="action_icons">
                                <div class="icon" matTooltip="Edit">
                                    <mat-icon>edit</mat-icon>
                                </div>
                                <div class="icon" matTooltip="Delete">
                                    <mat-icon>delete</mat-icon>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container> -->

    <ng-container *ngIf="isAttributesShow">
      <app-add-attributes class="attributeadd-sec" [attributeIdList]="attributeIdList"
        [attributeKeyList]="attributeKeyList" [attributeClassId]="attributeClassId"
        [attributeCategorieId]="attributeCategorieId" [attributeItemId]="attributeItemId"
        [attributeClassName]="attributeClassName" [attributeCategorieName]="attributeCategorieName"
        [attributeItemName]="attributeItemName" (saveAtt)="saveAtt($event)"></app-add-attributes>
    </ng-container>


  </div>
</div>