import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { PLANWORK_CONFIG, PlanworkConfigService } from './services/config.service';

@NgModule({
    providers: [PlanworkConfigService]
})

export class PlanworkModule {
    constructor(@Optional() @SkipSelf() parentModule: PlanworkModule) {
        if (parentModule) {
            throw new Error('PlanworkModule is already loaded');
        }
    }

    static forRoot(config): ModuleWithProviders<PlanworkModule> {
        return {
            ngModule: PlanworkModule,
            providers: [{
                provide: PLANWORK_CONFIG,
                useValue: config
            }]
        };
    }
}
