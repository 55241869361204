let URL = window.location.href;
let leadBaseUrl =  'lead/api/';
if (window.location.protocol === 'http:') {
  URL = URL.replace('http:', 'https:');
}
const currentUrl = URL.split("/");

console.log('currentUrl: ', currentUrl);
if (currentUrl[2] == "sdm.vertexplus.com") {
  currentUrl[2] = "sdmapi.vertexplus.com";
}
if (currentUrl[2] == "sdmold.vertexplus.com") {
  currentUrl[2] = "sdmoldapi.vertexplus.com";
}
if (currentUrl[2] == "sdmbeta.vertexplus.com") {
  currentUrl[2] = "sdmapibeta.vertexplus.com";
}
if (currentUrl[2] == "syncbeta.vertexplus.com") {
  currentUrl[2] = "sdmapibeta.vertexplus.com";
}
if (currentUrl[2] == "sdmdev.vertexplus.com") {
  currentUrl[2] = "sdmapidev.vertexplus.com";
}
if (currentUrl[2] == "syncdev.vertexplus.com") {
  currentUrl[2] = "sdmapidev.vertexplus.com";
}
if (currentUrl[2] == "sync.vertexplus.com") {
  currentUrl[2] = "syncapi.vertexplus.com";
  leadBaseUrl =  'Lead/api/';
}

if (currentUrl[2] == "isynk.vertexplus.com") {
  currentUrl[2] = "syncapi.vertexplus.com";
  leadBaseUrl =  'Lead/api/';
}

if(window.location.href.includes('localhost')){
  currentUrl[2] = "sdmapibeta.vertexplus.com";
}

console.log('currentUrl',currentUrl[2])
const apiUrl = currentUrl[0] + "//" + currentUrl[2] + "/";

export const planworkConfig = {
  baseUrl: apiUrl,
  // baseUrl: 'http://planwork.vertexplus.com/',
  // baseUrl: 'http://planwork03.vertexplus.com/',
  // baseUrl: 'http://sdmoldapi.vertexplus.com/',
  // baseUrl: 'http://sdmapi.vertexplus.com/',
  // baseUrl: 'https://sdmapibeta.vertexplus.com/',
  // baseUrl: 'https://syncapi.vertexplus.com/',
  // baseUrl: 'http://sdmapidev.vertexplus.com/',

  // Common Sub base Url
  //Beta
  // LeadBaseUrl: 'Lead/api/',
  LeadBaseUrl: leadBaseUrl,
  //Live(Sync)
  // LeadBaseUrl: apiUrl.includes('sdmapibeta' || 'sdmbeta' || 'sdmoldapi' || 'sdmold') ? 'lead/api/' : 'Lead/api/',
  CampaignBaseUrl: 'Campaign/api/',
  EmployeeBaseUrl: 'EmployeeCore/api/',
  CompanyBaseUrl: 'CompanyCore/api/',
  ClientBaseUrl: 'ClientCore/api/',
  GlobalBaseUrl: 'GlobalCore/api/',
  ServiceBaseUrl: 'Service/api/',
  BillingBaseUrl: 'Billing/api/',
  TaskBaseUrl: 'Task/api/',
  AssetBaseUrl: 'Asset/api/',
  ExpenseBaseUrl: 'Expense/api/',
  PettyCashBaseUrl: 'Payment/api/',
  SupportBaseUrl: 'Support/api/',
  VendorBaseUrl: 'VendorCore/api/',
  ConsumableBaseUrl: 'Consumable/api/',
  LicenseBaseUrl: 'License/api/',
  PurchaseBaseUrl: 'Purchases/api/',

  layout: {
    sidebar: "show", // 'show', 'none'
    toolbar: "show", // 'show', 'none'
    footer: "show", // 'show', 'none'
  },
  colorClasses: {
    toolbar: "mat-white-500-bg",
    navbar: "mat-fuse-dark-700-bg",
    footer: "mat-fuse-dark-900-bg",
  },
  customScrollbars: true,
  routerAnimation: "fadeIn", // fadeIn, slideUp, slideDown, slideRight, slideLeft, none
};
