import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatOption, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ApiService } from '@planwork/services/api.service';
import { PlanworkConfigService } from '@planwork/services/config.service';
import { DropdownService } from '@planwork/services/dropdown.service';
import { CampaignService } from 'app/main/campaign/campaign.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceService } from '../../masterservice/service.service';
import { CommonService } from '../common.service';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};
@Component({
  selector: 'app-add-contact-modal',
  templateUrl: './add-contact-modal.component.html',
  styleUrls: ['./add-contact-modal.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ],
})
export class AddContactModalComponent implements OnInit {
  @ViewChild('select') select: MatSelect;
  SupportSubCategory: any = {}
  SupportLevel: any = {
  }
  allSelected = false;
  categoryList: any = []
  SupportsCat: ''
  SupportsCat1: ''
  DemoList: any = [{ Name: 'Demo 1' }, { Name: 'Demo 2' }, { Name: 'Demo 3' }, { Name: 'Demo 4' }]
  EmployeeList: any = []
  minuteList: any = [
    { time: '0 Minute', id: 1, value: 0 },
    { time: '15 Minute', id: 2, value: 15 },
    { time: '30 Minute', id: 3, value: 30 },
    { time: '45 Minute', id: 4, value: 45 },
  ]
  addContactDetail: any = {}
  dialogAction: any;
  modalData: any;

  searchText: ''
  selected: ''
  vendordocfilter: ''
  selected2: ''
  majorOfferinglist: any = []
  @ViewChild('f', { static: false }) form: any;
  SupportCategory: any = {
    SupportEmployeeLevel: [],
    ResolutionTimeMinute: 0
  };
  SupportMember: any;
  Saveteamsupport: any = [];
  srassignto: any;
  levelshow: any;
  modalData2: any;
  vendordoctype: any = {};
  VenderType: any = [];
  CompanyBillingDropDownList: any = [];
  AssetBlock: any = {}
  assetDropdownlist: any = [];
  FloorDropdownlist: any;
  AssetItemDropdown: any;
  SectionDropdownlist: any;
  empLevelDropList: any[] = [];
  constructor(
    public dialog: MatDialog,
    public _http: HttpClient,
    private service: PlanworkConfigService,
    private spinner: NgxSpinnerService,
    private apiService: CommonService,
    private campaignService: CampaignService,
    private dropdownService: DropdownService,
    private CoreService: ServiceService,
    private ApiCoreService: ApiService,
    public dialogRef: MatDialogRef<'prsn_dialog'>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.dialogAction = data.action;
    this.modalData = data.data;
    this.modalData2 = data.data2;
    console.log(data);
    console.log(this.modalData);

    if (this.dialogAction == 'Editvendordoc') {
      let input = { DocumentTypeId: this.modalData }
      this.actionGetById(input, 'DocumentTypeGetById')
    }
    if (this.dialogAction == 'Edit-Offering') {
      let input = { MajorOfferingId: this.modalData }
      this.actionGetById(input, 'VendorMajorOfferingGetById')
    }

    if (this.dialogAction == 'EditSub-Offering') {
      let input = { SubOfferingId: this.modalData }
      this.actionGetById(input, 'VendorOfferingSubGetById')
    }
    if (this.dialogAction == 'EditSub-Offering') {
      let input = { SubOfferingId: this.modalData }
      this.actionGetById(input, 'VendorOfferingSubGetById')
    }
    if (this.dialogAction == 'EditFacility') {
      let input = { FacilityId: this.modalData }
      this.actionGetById(input, 'VendorFacilityGetById')
    }
    if (this.dialogAction == 'edit-vendor-supplier') {
      let input = { suppliercategoryid: this.modalData }
      this.actionGetById(input, 'VendorSupplierCategoryGetById')
    }
    if (this.dialogAction == 'edit-vendor-compliance') {
      let input = { complianceid: this.modalData }
      this.actionGetById(input, 'VendorComplianceGetById')
    }
    if (this.dialogAction == 'EditServiceProvider') {
      let input = { servicecategoryid: this.modalData }
      this.actionGetById(input, 'VendorServiceCategoryGetById')
    }


  }

  async ngOnInit() {
    if (this.dialogAction == 'add-Floor' || this.dialogAction == 'edit-floor' || this.dialogAction == 'add-Section' || this.dialogAction == 'edit-Section' || this.dialogAction == 'add-Storage' || this.dialogAction == 'edit-Storage') {
      if (this.dialogAction == 'edit-Section') {
        let input = { AssetSectionId: this.modalData.AssetSectionId }
        this.actionassetGetById(input, 'AssetSectionById')
        console.log(this.modalData);
        let assetDropdowndata = await this.assetDropdownblock(this.modalData.LocationId);
        this.assetDropdownlist = assetDropdowndata;
        let Floordrop = await this.FloorDropdown(this.modalData?.AssetBlockId);
        this.FloorDropdownlist = Floordrop;
      }
      if (this.dialogAction == 'edit-Storage') {
        let input = { AssetStorageId: this.modalData.AssetStorageId }
        this.actionassetGetById(input, 'StorageGetById')
        console.log(this.modalData);
        let assetDropdowndata = await this.assetDropdownblock(this.modalData.LocationId);
        this.assetDropdownlist = assetDropdowndata;
        let Floordrop = await this.FloorDropdown(this.modalData?.AssetBlockId);
        this.FloorDropdownlist = Floordrop;
        let Sectiondrop = await this.SectionDropdown(this.modalData?.AssetFloorId);
        this.SectionDropdownlist = Sectiondrop;
      }

      let CompanyBillingDropListemp = await this.CompanyBillingDropList();
      this.CompanyBillingDropDownList = CompanyBillingDropListemp.Result;

    }


    if (this.dialogAction == 'edit-floor') {
      let input = { AssetFloorId: this.modalData.AssetFloorId }

      this.actionassetGetById(input, 'AssetFloorById')
      let assetDropdowndata = await this.assetDropdownblock(this.modalData.LocationId);
      this.assetDropdownlist = assetDropdowndata;

    }

    if (this.dialogAction == 'Addvendordoc' || this.dialogAction == 'Editvendordoc') {
      let venType = await this.vendorType();

      this.VenderType = venType;
    }
    if (this.dialogAction == 'Addvendordoc') {
      this.addContactDetail.IsMandatory = false
    }
    if (this.dialogAction == 'supportReassign') {

      this.AssignEmployeeList()
    }
    if (this.dialogAction == 'Edit-Support-Level') {

      this.supportlevelget()
    }
    if (this.dialogAction == 'addSub-Offering' || this.dialogAction == 'EditSub-Offering') {
      // this.majorOfferingDrop()
      this.CoreService.assetCoreSerivce({}, 'GetAssetItemDropDownList?AssetCategoryId=0').subscribe(response => {
        this.AssetItemDropdown = response.body
      });
    }
    if (this.dialogAction == 'add-Support-Let-Team' || this.dialogAction == 'Edit-Support-Let-Team') {

      this.employeeDrop()
    }
    if (this.dialogAction == 'add-support-categories' || this.dialogAction == 'Edit-Support-Category') {
      this.TeamemployeeDrop()
    }
    if (this.dialogAction == 'Edit-Support-Category') {
      this.CategoryGet()
    }
    if (this.dialogAction == 'add-support-Subcategories' || this.dialogAction == 'Edit-Support-SubCategory') {
      this.categoryDrop()
    }
    if (this.dialogAction == 'Edit-Support-SubCategory') {
      this.SubCatGet()
    }
    if (this.dialogAction == 'add-block' || this.dialogAction == 'edit-block') {
      let CompanyBillingDropListemp = await this.CompanyBillingDropList();
      this.CompanyBillingDropDownList = CompanyBillingDropListemp.Result;
      if (this.dialogAction == 'edit-block') {
        let input = { AssetBlockId: this.modalData }
        this.actionassetGetById(input, 'AssetBlockById')
      }
    }
    if (this.dialogAction == 'add-support-categories' || this.dialogAction == 'Edit-Support-Category') {
      this.empLevelList();
    }
  }
  empLevelList() {
    this.spinner.show()
    this.apiService.SupportService({}, 'SupportLevelDropDownList').subscribe(res => {
      this.spinner.hide()
      this.empLevelDropList = res.body.Result
    })
  }
  async blockFilter(e) {
    let Floordrop = await this.FloorDropdown(e);
    this.FloorDropdownlist = Floordrop;
  }
  async floorFilter(e) {
    let Sectiondrop = await this.SectionDropdown(e);
    this.SectionDropdownlist = Sectiondrop;
  }
  async LocationFilter(e) {
    let assetDropdowndata = await this.assetDropdownblock(e);
    this.assetDropdownlist = assetDropdowndata;
  }
  vendorType(): Promise<any> {
    return this.dropdownService.OrginisationTypeDropDown({}).toPromise()
  }
  AssignEmployeeList() {
    this.spinner.show()

    this.campaignService.SupportService({}, 'SupportTeamDropDownList').subscribe(res => {
      this.spinner.hide()
      // this.dialogRef.close(res)
      // console.log(res)
      console.log(res);

      this.EmployeeList = res.body.Result
    })
  }
  SubCatGet() {
    this.spinner.show()

    this.campaignService.SupportService(this.modalData, 'SubcategoryGetById').subscribe(res => {
      this.spinner.hide()
      console.log(res)
      this.SupportSubCategory = res.body.Item2
    })
  }
  CategoryGet() {
    this.spinner.show()

    this.campaignService.SupportService(this.modalData, 'CategoryGetById').subscribe(res => {
      this.spinner.hide()

      // if(res.body.Status){

      // }
      this.SupportCategory = res.body.Item2

      let finalData = [];

      this.SupportCategory.SupportEmployeeLevel.forEach(ele => {

        let obj;
        // ...ele.SupportLevelId,
        obj = this.EmployeeList.filter(x => x.MemberId == ele.MemberId)[0];
        obj.SupportLevelId = ele?.SupportLevelId;
        finalData.push(obj);
        if (obj.SupportLevelId == 1) {
          this.levelshow = true
        }
        console.log(finalData);

        // finalData.push(...ele.SupportLevelId, this.EmployeeList.filter(x => x.MemberId == ele.MemberId)[0]);
      });
      this.SupportCategory.SupportEmployeeLevel = finalData;
      console.log(this.SupportCategory.SupportEmployeeLevel);


    })
  }
  TeamemployeeDrop() {
    this.spinner.show()
    this.apiService.SupportService({}, 'SupportTeamDropDownList').subscribe(res => {
      this.spinner.hide()
      this.EmployeeList = res.body.Result
      console.log(this.EmployeeList);

    })
  }

  Saveteammember() {
    this.spinner.show()

    this.campaignService.SupportService(this.Saveteamsupport, 'SupportTeamSaveFromEmployeeList').subscribe(res => {
      this.spinner.hide()
      this.dialogRef.close(res)
      console.log(res)
      this.SupportMember = res.body.Item2
    })
  }
  Saveteammember1() {
    this.spinner.show()
    console.log(this.modalData);

    let input = {
      // "SRId": this.modalData.srid,
      "SRassignedTo": this.srassignto,
      "SRAssignId": this.modalData.srid
    }
    console.log(input);

    this.campaignService.SupportMember(input, 'SupportRequestReassign').subscribe(res => {
      this.spinner.hide()
      console.log(res)
      this.SupportMember = res.body.Item2
      this.dialogRef.close(res)

    })
  }
  EmployeeData(e, user, i) {
    console.log(e);
    console.log(user);

    this.srassignto = user.MemberId
    if (e == true) {

      this.Saveteamsupport.push(user)
    }
    else {

      this.Saveteamsupport.splice(i, 1)
    }

    console.log(this.Saveteamsupport);

  }
  categoryDrop() {
    this.spinner.show()

    this.campaignService.SupportService({}, 'CategoryDropDownList').subscribe(res => {
      this.spinner.hide()
      console.log(res)
      this.categoryList = res.body.Result

    })
  }

  Savesubcat() {
    this.spinner.show()

    this.campaignService.SupportService(this.SupportSubCategory, 'SubcategorySave').subscribe(res => {

      this.spinner.hide()
      this.dialogRef.close(res)
      console.log(res)
        // this.categoryList = res.body.Result
        ;
    })
  }
  levelselect(e) {
    console.log(e);

    let trueid = this.SupportCategory.SupportEmployeeLevel.filter(x => x.SupportLevelId == 1)
    console.log(trueid);

    if (trueid.length > 0) {

      this.levelshow = true
    }
    else {
      this.levelshow = false

    }
    console.log(this.levelshow);

  }
  Savecat() {
    this.spinner.show()
    this.campaignService.SupportService(this.SupportCategory, 'CategorySave').subscribe(res => {
      this.spinner.hide()
      this.dialogRef.close(res);
    })
  }
  Levelsupport() {
    this.spinner.show()

    this.campaignService.SupportService(this.SupportLevel, 'SupportLevelSave').subscribe(res => {
      this.spinner.hide()
      console.log(res)
      this.dialogRef.close(res)
        // this.SupportLevel = res.body.Result
        ;
    })
  }
  employeeDrop() {
    this.spinner.show()
    this.campaignService.postEmployee(0, 'ExitEmployeeDropDownList?CostCenterId=' + 0).subscribe(res => {
      this.campaignService.SupportService({}, 'CurrentSupportTeamMembersIds').subscribe(res2 => {
        if (!res2?.body?.Result) {
          res2.body.Result = [];
        }
        res2.body.Result = res2.body.Result.split(',').map(Number) || [];
        this.spinner.hide()
        this.EmployeeList = res.body.EmployeeList.filter((a) => !res2?.body?.Result.includes(a?.EmployeeId))
        console.log(this.EmployeeList);
      })
    })
  }

  actionGetById(data, apiName) {
    this.spinner.show()
    this.apiService.commonService(data, apiName).subscribe(resp => {
      this.spinner.hide()
      console.log(resp);
      let master: any = resp.body
      this.addContactDetail = master.Item2
      console.log(this.addContactDetail.OrganisationType);

    })
  }
  actionassetGetById(data, apiName) {
    this.spinner.show()
    this.ApiCoreService.assetCoreSerivce(data, apiName).subscribe(resp => {
      this.spinner.hide()
      console.log(resp);
      let master: any = resp.body
      this.addContactDetail = master
      console.log(this.addContactDetail);

    })
  }



  Mandatorycheck(e) {
    console.log(e);
    if (e.checked) {

      this.addContactDetail.IsMandatory = true
    }
    else {
      this.addContactDetail.IsMandatory = false

    }
  }

  saveVandorMaster(apiUrl) {
    this.spinner.show()
    this.apiService.commonService(this.addContactDetail, apiUrl).subscribe(res => {
      this.dialogRef.close(res)
      this.spinner.hide()
    });
  }
  saveassetMaster(apiUrl) {
    this.spinner.show()
    this.ApiCoreService.assetCoreSerivce(this.addContactDetail, apiUrl).subscribe(res => {
      this.dialogRef.close(res)
      this.spinner.hide()
    });
  }
  majorOfferingDrop() {
    this.spinner.show()
    this.apiService.commonService({}, 'VendorMajorOfferingDropDownList').subscribe(response => {
      this.spinner.hide()
      this.majorOfferinglist = response.body
      console.log(this.majorOfferinglist);


    })

  }
  orgselect() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }


  }
  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  CompanyBillingDropList(): Promise<any> {
    return this.dropdownService.CompanyBillingList({}).toPromise()
  }
  assetDropdownblock(e): Promise<any> {
    return this.dropdownService.assetblock(e, {}).toPromise()
  }
  FloorDropdown(e): Promise<any> {
    return this.dropdownService.assetfloor(e, {}).toPromise()
  }
  SectionDropdown(e): Promise<any> {
    return this.dropdownService.assetsection(e, {}).toPromise()
  }

  supportlevelget() {
    this.spinner.show()

    this.apiService.SupportService(this.modalData, 'SupportLevelGetById').subscribe(response => {
      this.spinner.hide()
      console.log(response);
      this.SupportLevel = response.body.Item2


    })
  }
  compareFn(a: any, b: any) {
    if (b) {
      const d = a[Object.keys(a)[0]];
      const e = b[Object.keys(b)[0]];
      return d === e;
    }
  }

  downloadMyFile(data) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    if (data.FileData) {
      link.setAttribute('href', data.Preview);
    } else {
      this.CoreService.getDocument({ DocumentId: data?.DocumentId }).subscribe(res => {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'data:image/png;base64,' + res?.body?.documentData);
        // link.setAttribute('href', data.DocumentPath);
        link.setAttribute('download', data.DocumentFileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
    }
    link.setAttribute('download', data.DocumentFileName);
    document.body.appendChild(link)
    link.click();
    link.remove();
  }
  fileUpload(event, data) {
    this.service.filesizeValidation(event).subscribe(res => data.fileSizeError = res);
    if (data.fileSizeError == '') {
      const files = event.target.files;
      const file = files[0];
      if (files && file) {
        const fileName = file.name;
        const fileExe = fileName.split('.').pop();

        if (fileExe == 'pdf') {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            let imagePreview = e.target.result;
            let b;
            b = imagePreview.split('base64,');
            data.DocumentFileName = fileName;
            data.DocumentId = 0;
            data.Preview = imagePreview;
            data.FileData = b[1];
            data.FileExe = fileExe;
          }
          reader.readAsDataURL(file);
          data.pdfvalid = "";
        }

        else {
          data.pdfvalid = "Please Upload PDF File Format";
        }
      }
    }
  }
  fileUploadRemove(data) {
    data.DocumentFileName = '';
    data.FileData = '';
    data.Preview = '';
    data.DocumentPath = '';
    data.FileExe = '';
    data.DocumentId = 0;
  }
}
