<div class="content inOutLogPage">
    <div class="page-heading">
        <div fxFlex fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
                <h1>Services and Utility Approval</h1>
            </div>
        </div>
    </div>
    <div class="content_body" [class.show-search]="isShow">
        <div class="filter_area pb-20">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex="1 0 auto">
                    <div class="top_drop" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                        <div class="input_group filter_box mr-3">
                            <p class="left_label">Status</p>
                            <mat-form-field appearance="outline">
                                <mat-select name="" [(value)]="selected">
                                    <mat-option value="0">All</mat-option>
                                    <mat-option *ngFor="let list of status" [value]="list">{{list.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="input_group filter_box mr-3">
                            <p class="left_label">Requisition Type</p>
                            <mat-form-field appearance="outline">
                                <mat-select name="" [(value)]="selected">
                                    <mat-option value="0">All</mat-option>
                                    <mat-option *ngFor="let list of Requisition" [value]="list">{{list.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="mini_btn" fxFlex="0 1 auto">
                    <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <div class="search-outer position-relative">
                            <button mat-mini-fab class="search_btn" (click)="toggleSearch('show')" *ngIf="!isShow">
                                <mat-icon>search</mat-icon>
                            </button>
                            <button mat-mini-fab class="search_btn" (click)="toggleSearch('hide')" *ngIf="isShow">
                                <mat-icon>clear</mat-icon>
                            </button>
                            <div class="search_form">
                                <input type="text" matInput placeholder="Search by keyword">
                            </div>
                        </div>
                        <button mat-mini-fab color="accent" class="gray_btn">
                            <img src="assets/icons/table.svg">
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-white table-responsive list_view server_side_page">
            <table id="table_lead" datatable [dtOptions]="dtOptions"
                class="row-border hover list_table table table-bordered table-hover table-compact">
                <thead>
                    <tr>
                        <th *ngFor="let item of theadColumn" (click)="sortItem = item.position">
                            <div>
                                <span>{{item.Title}}</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td><strong>REQ-46</strong> <br>
                            <small>26 July, 2022</small>
                        </td>
                        <td>Internet Provider</td>
                        <td>
                            <mat-chip-list aria-label="Dog selection">
                                <mat-chip
                                    style="font-size: 11px; padding: 0 8px; min-height: 20px; margin-bottom: 8px; color: #fff;"
                                    class="bg-danger">
                                    Important
                                </mat-chip>
                            </mat-chip-list>
                            26 July, 2022
                        </td>
                        <td>Administrator VertexPlus</td>
                        <td>To Be Approved</td>
                        <td>
                            <span class="action_icons">
                                <a class="icon" matTooltip="Approve" (click)="approveModal('new')">
                                    <mat-icon>done</mat-icon>
                                </a>
                                <a class="icon" matTooltip="Decline" (click)="addDecline()">
                                    <mat-icon>close</mat-icon>
                                </a>
                                <a class="icon" matTooltip="Rework" (click)="addRemark()">
                                    <mat-icon>sync</mat-icon>
                                </a>
                                <a class="icon" matTooltip="View Detail"
                                    (click)="sidenav.toggle(); statusType('ToBeApproved')">
                                    <mat-icon>visibility</mat-icon>
                                </a>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td><strong>REQ-46</strong> <br>
                            <small>26 July, 2022</small>
                        </td>
                        <td>AC Repair</td>
                        <td>
                            <mat-chip-list aria-label="Dog selection">
                                <mat-chip
                                    style="font-size: 11px; padding: 0 8px; min-height: 20px; margin-bottom: 8px; color: #fff;"
                                    class="bg-danger">
                                    Important
                                </mat-chip>
                            </mat-chip-list>
                            26 July, 2022
                        </td>
                        <td>Administrator VertexPlus</td>
                        <td>Approved</td>
                        <td>
                            <span class="action_icons">
                                <a class="icon" matTooltip="View Detail"
                                    (click)="sidenav.toggle(); statusType('approved')">
                                    <mat-icon>visibility</mat-icon>
                                </a>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td><strong>REQ-46</strong> <br>
                            <small>26 July, 2022</small>
                        </td>
                        <td>Electricity</td>
                        <td>
                            <mat-chip-list aria-label="Dog selection">
                                <mat-chip
                                    style="font-size: 11px; padding: 0 8px; min-height: 20px; margin-bottom: 8px; color: #fff;"
                                    class="bg-danger">
                                    Important
                                </mat-chip>
                            </mat-chip-list>
                            26 July, 2022
                        </td>
                        <td>Administrator VertexPlus</td>
                        <td>Rework</td>
                        <td>
                            <span class="action_icons">
                                <a class="icon" matTooltip="View Detail"
                                    (click)="sidenav.toggle(); statusType('rework')">
                                    <mat-icon>visibility</mat-icon>
                                </a>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td><strong>REQ-46</strong> <br>
                            <small>26 July, 2022</small>
                        </td>
                        <td>Coffee Machine</td>
                        <td>
                            <mat-chip-list aria-label="Dog selection">
                                <mat-chip
                                    style="font-size: 11px; padding: 0 8px; min-height: 20px; margin-bottom: 8px; color: #fff;"
                                    class="bg-danger">
                                    Important
                                </mat-chip>
                            </mat-chip-list>
                            26 July, 2022
                        </td>
                        <td>Administrator VertexPlus</td>
                        <td>Declined</td>
                        <td>
                            <span class="action_icons">
                                <a class="icon" matTooltip="View Detail"
                                    (click)="sidenav.toggle(); statusType('declined')">
                                    <mat-icon>visibility</mat-icon>
                                </a>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td><strong>REQ-46</strong> <br>
                            <small>26 July, 2022</small>
                        </td>
                        <td>Repair & Maintenance</td>
                        <td>
                            <mat-chip-list aria-label="Dog selection">
                                <mat-chip
                                    style="font-size: 11px; padding: 0 8px; min-height: 20px; margin-bottom: 8px; color: #fff;"
                                    class="bg-danger">
                                    Important
                                </mat-chip>
                            </mat-chip-list>
                            26 July, 2022
                        </td>
                        <td>Administrator VertexPlus</td>
                        <td>To Be Approved</td>
                        <td>
                            <span class="action_icons">
                                <a class="icon" matTooltip="Approve" (click)="approveModal('repair')">
                                    <mat-icon>done</mat-icon>
                                </a>
                                <a class="icon" matTooltip="Decline" (click)="addDecline()">
                                    <mat-icon>close</mat-icon>
                                </a>
                                <a class="icon" matTooltip="Rework" (click)="addRemark()">
                                    <mat-icon>sync</mat-icon>
                                </a>
                                <a class="icon" matTooltip="View Detail"
                                    (click)="sidenav.toggle(); statusType('ToBeApproved')">
                                    <mat-icon>visibility</mat-icon>
                                </a>
                            </span>
                        </td>
                    </tr>
                    <tr class="d-none">
                        <td align-item="center" colspan="11">No matching records found</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<!-- Mat Drawer -->
<mat-drawer-container class="example-container epica-sidebar">
    <mat-drawer #sidenav class="sidebar taskdetail-sidebar" mode="over" position="end">
        <mat-icon class="sidebar-closeicon" (click)="sidenav.toggle()">highlight_off</mat-icon>

        <div class="taskdetail-dv">
            <h2>Detail</h2>
            <div class="task_detail_box">
                <div class="row">
                    <div class="col-md-12 dtl_text">
                        <label>Requisition Type</label>
                        <p>Internet Provider</p>
                    </div>
                    <div class="col-md-6 dtl_text">
                        <label>Requisition No.</label>
                        <p>3 july, 2022</p>
                    </div>
                    <div class="col-md-6 dtl_text">
                        <label>Priority</label>
                        <p>Scheduled</p>
                    </div>
                    <div class="col-md-6 dtl_text">
                        <label>Required On/Before</label>
                        <p>26 July, 2022</p>
                    </div>
                    <div class="col-md-6 dtl_text">
                        <label>Requested By</label>
                        <p>Administrator VertexPlus</p>
                    </div>
                    <div class="col-md-12 dtl_text">
                        <label>Specific Recommendation</label>
                        <ul style="margin: 0 0 0 -20px; list-style: auto;">
                            <li *ngFor="let list of [0,1]">Lorem ipsum dolor sit amet.</li>
                        </ul>
                    </div>
                    <div class="col-md-12 dtl_text">
                        <label>Problem</label>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </div>
                    <div class="col-md-12 dtl_text">
                        <label>Justification</label>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </div>
                </div>
                <div class="task_full_dtl dtl_text mb-3 mt-0" *ngIf="currentStatus == 'declined'">
                    <label>Declined Remark</label>
                    <p>not availabe</p>
                </div>
                <div class="task_full_dtl dtl_text mb-3 mt-0" *ngIf="currentStatus == 'rework'">
                    <label>Rework Remark</label>
                    <p>not availabe</p>
                </div>
                <div class="row">
                    <div class="col-md-6 dtl_text">
                        <label>Status</label>
                        <p *ngIf="currentStatus == 'ToBeApproved'">To Be Approved</p>
                        <p *ngIf="currentStatus == 'rework'">Rework</p>
                        <p *ngIf="currentStatus == 'approved'">Approved</p>
                        <p *ngIf="currentStatus == 'pending'">Pending</p>
                        <p *ngIf="currentStatus == 'declined'">Declined</p>
                    </div>
                </div>
            </div>
            <div class="mt-5" *ngIf="currentStatus == 'ToBeApproved'">
                <button class="add-lead-btn mr-3" mat-flat-button color="accent" matTooltip="Approve"
                    (click)="approveModal('new')">
                    Approve
                </button>
                <button mat-stroked-button class="mr-3" color="warn" matTooltip="Decline"
                    (click)="addDecline()">Decline</button>
                <button mat-stroked-button color="basic" matTooltip="Rework" (click)="addRemark()">Rework</button>
            </div>
        </div>

    </mat-drawer>
</mat-drawer-container>