import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatatableService } from '@planwork/datatable-settings';
import { DataTableDirective } from 'angular-datatables';
import { CommonModalComponent } from 'app/main/core/common/common-modal/common-modal.component';
import { ServiceService } from 'app/main/core/masterservice/service.service';
import { BallparkModalsComponent } from 'app/main/lead/lead-business-head/ballpark-modals/ballpark-modals.component';
import { planworkConfig } from 'app/planwork.config';
import { NgxSpinnerService } from 'ngx-spinner';
import { TableColumn } from './Table.Column';
import { MatSidenav } from '@angular/material/sidenav';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  Result: any[];
}
@Component({
  selector: 'app-asset-approval',
  templateUrl: './asset-approval.component.html',
  styleUrls: ['./asset-approval.component.scss']
})
export class AssetApprovalComponent implements OnInit {
  selected = 'all'
  selected2 = 'all'
  tabledata: any = {};
  tblClm: any[] = [];
  DataOutput:any;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  tableSetting: any = {};
  isShow = false;
  theadColumn = [];
  sortItem = 1;
  EmployeeOutput: any
  status = [
    { name:'Pending',StatusId:1 },
    { name:'Rework' ,StatusId:3 },
    { name:'Declined' ,StatusId:4},
    { name:'Approved',StatusId:5}
  ];
  Requisition = [
    { name: 'New',reqId:1 },
    { name: 'Repair & Maintenance',reqId:2 },
    { name: 'Customize',reqId:3 },
    { name: 'Deposit',reqId:4 }
  ]
  dialogRef: any;
  searchText: any;
  currentStatus
  approval: any ={};
  ReqGetdata: any;
  ReworkReq: any={};
  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(private datatableServise: DatatableService,
    public dialog: MatDialog,
    private router: Router,
    private masterService: ServiceService,
    private spinner: NgxSpinnerService,
    private http: HttpClient
  ) {
    this.theadColumn = TableColumn;
    this.tableSetting = this.datatableServise.tablePagination();
  }

  ngOnInit(): void {
    this.getList()
  }
  toggleSearch(text) {
    if (text == 'show') {
      this.isShow = true;
    } else {
      this.isShow = false;
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.search('');
        dtInstance.draw();
      });
    }
  }
  approveModal(type) {
    const dialogRef = this.dialog.open(CommonModalComponent, {
      width: '300px',
      disableClose: true,
      autoFocus: false,
      panelClass: 'statusClass',
      data: { action: 'delete', statusText: 'Are you sure You want to Approve !' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (type == 'new') {
          this.router.navigate(['/asset-management/approved-requisition']);
        } else {
          console.log('aaaa');

          this.router.navigate(['/repair-maintenance/repair-maintenance']);
        }
      }
    })
  }

  addDecline() {
    this.dialogRef = this.dialog.open(BallparkModalsComponent, {
      disableClose: false,
      data: { action: 'remarkEpica', title: 'Rework Remark' }
    });
  }
  statusType(type) {
    this.currentStatus = type;
  }
  getList(): void {
    // this.spinner.show();

    const that = this;
    this.dtOptions = {
      language: { searchPlaceholder: 'Search by keyword' },
      ...this.tableSetting.pagination,
      ajax: (dataTablesParameters: any, callback) => {
        that.http.post<DataTablesResponse>(
          planworkConfig.baseUrl + 'Asset/api/AssetRequisitions/AssetApprovalList',
          { ...this.approval,
            Search:
            {
              ...dataTablesParameters,
              value: dataTablesParameters.search.value,
              regex: dataTablesParameters.search.regex,
            },

          }, { headers: this.tableSetting.dataTables }
        ).subscribe(resp => {
          console.log(resp);

          that.spinner.hide();
          that.searchText = dataTablesParameters.search.value;
          that.tabledata = dataTablesParameters;
         let res:any = resp.Result;
         that.DataOutput = res.ItemList
console.log(this.DataOutput);

          callback({
            recordsTotal: res?.FilteredRecordCount,
            recordsFiltered: res?.FilteredRecordCount,
            data: []
          });

        });
      },
      columns: this.theadColumn,
      ...this.tableSetting.domSetting
    };
  }
  organizationfilter(event,text){

    console.log(event);
    if(text == 'status'){

      this.approval.Status = event.value.StatusId
    }
    else{
      this.approval.RequisitionTypeid = event.value.reqId

    }
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    })

  }
  remark(id, type){


    this.dialogRef = this.dialog.open(BallparkModalsComponent, {
      disableClose: false,
      data: { action: 'remarkDeclinerequsotion', title: 'Decline Remark' ,data:{id : id,status:4}, }
    });
    if(type == 2){
      this.sidenav.toggle()
    }


  }
  clientGetById(id) {
    this.masterService.reuquisitonService({ RequisitionId: id }, 'MyAssetRequisitionGetById').subscribe(response => {
      this.ReqGetdata = response.body.Result;
    })
  }
  reworksave(id, type){
    this.ReworkReq.RequisitionId = id
    this.ReworkReq.status = 5
    const dialogRef = this.dialog.open(CommonModalComponent, {
      width: '300px',
      disableClose: true,
      autoFocus: false,
      panelClass: 'statusClass',
      data: { action: 'delete', statusText: 'Are you sure You want to Approve !' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // if (type == 'new') {

        // } else {
        //   console.log('aaaa');

        // }
         this.masterService.reuquisitonService( this.ReworkReq , 'AssetRequisitionsStatusChange')
    .subscribe(res => {
      let dialogRef
      if (res?.body?.TransactionMessage?.Success == true) {
          dialogRef = this.dialog.open(CommonModalComponent, {
          width: "300px",
          disableClose: true,
          data: { action: "success", statusText: res?.body?.TransactionMessage?.Message }
        });
        dialogRef.afterClosed()
        .subscribe(result => {
          if(result){
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.draw();
            })
            if(type == 2){
              this.sidenav.toggle()
            }

                    }
        })
      }
      else {
        dialogRef = this.dialog.open(CommonModalComponent, {
          width: "300px",
          disableClose: true,
          data: { action: "Alert", statusText: res?.body?.TransactionMessage?.Message },
        });
      }
    });
      }
    })


  }
  addRemark(id, type){

     const dialogRef = this.dialog.open(BallparkModalsComponent, {
      disableClose: false,
      data: { action: 'remarkrequsotion', title: 'Rework Remark' ,data:{id : id,status:3}, }
    });
    if(type == 2){
      this.sidenav.toggle()
    }

  }
}





