import { Component, ViewChild, Inject, OnInit, Output } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { PlanworkConfigService } from '@planwork/services/config.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TasksService } from 'app/main/tasks/tasks.service';
import { ServiceService } from 'app/main/core/masterservice/service.service';
import { element } from 'protractor';
import { Router } from '@angular/router';
import { X } from '@angular/cdk/keycodes';
import { CommonModalComponent } from 'app/main/core/common/common-modal/common-modal.component';
import { throttleTime } from 'rxjs/operators';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class ModalComponent implements OnInit {
  dialogAction: any;
  dialogRefs: any;
  allocatedata: any;
  timeFormate: any;
  errormessage: any;
  OccupiedTo: string;
  Occupiedstart: string;
  Detachtime: string;
  assigntime: string;
  staffingOccp: any[] = [];
  occpEmpData: any;
  AssignmentData: any;
  OccupiedHoursflag = true;
  UnOccupiedHoursflag = false;
  partiallytotaltime: string;
  viewTaskData: any;
  AssignHoursFrom: any;
  AssignHoursTo: any;
  assignhour: any;
  assignmin: number;
  datelist: any;
  tasktime: string;
  selecthourflag = false;
  cleartimeselect = false;
  occpflag = false;
  InputDetachreq = [

  ]
  detachRequest: any = {
    occupencyList: [],
  }
  currentDate = new Date()
  occupencyLists: any = {}
  assignmentCalendarData: any;
  assignmentDetachData: any;
  isDetachAllManager = true;

  constructor(
    public dialog: MatDialog,
    private location: Location,

    private router: Router,
    private TaskService: ServiceService,
    private appService: PlanworkConfigService,
    private taskService: TasksService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<'prsn_dialog'>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {

    this.timeFormate = this.appService.timeFormate;
    this.dialogAction = data.action;
    this.datelist = data.datelist

    this.allocatedata = data
    if (this.dialogAction == 'staffingOcc') {
      this.staffingOccp = data.occData;
      this.occpEmpData = data.resData;
    }
    if (this.dialogAction == 'assignmentCalendar' || this.dialogAction == 'assignmentDetach') {
      this.assignmentCalendarData = data.taskdata;
      this.Assignment(data.taskdata);
    }
    if (this.dialogAction == 'assignmentDetach') {
      this.assignmentDetachData = data.taskdata;
      this.Assignment(data.taskdata);
      console.log('basss');

    }
    console.log(data);
  }

  ngOnInit(): void {


    this.viewTaskData = JSON.parse(localStorage.getItem('viewTaskData'));
    if (this.allocatedata.action == "Availability") {

      for (let doc of this.allocatedata.partially) {
        this.tasktime = this.viewTaskData.AssignHours + ':' + this.viewTaskData.AssignMinutes
        doc.AssignHoursFrom = ''
        this.cleartimeselect = false

        this.UnOccupiedHours(doc);
        this.SelectTime(doc);
      }
    }
    if (this.allocatedata.action == "occupancyModal") {

      this.allocatedata.UnAvaliableresource.forEach(element => {
        this.tasktime = this.viewTaskData.AssignHours + ':' + this.viewTaskData.AssignMinutes
        element.DetachHoursFrom = ''
        this.cleartimeselect = false
        this.OccupaiedTotal(element);
        this.DetachTimeTotal(element);
        const first = this.timeFormate.filter(x => x.Value == element.OccFrom)[0];
        const last = this.timeFormate.filter(x => x.Value == element.OccTo)[0];
        if(first == last){
          element.detachHoursList = [
            { ...first }
          ];
        }else{
          element.detachHoursList = [
            { ...first },
            { ...last }
          ];
        }
      });
      console.log(this.allocatedata.UnAvaliableresource);
    }
  }


  UnOccupiedHours(doc) {
    // unocc partally hour total time
    doc.UnOccupiedHoursFrom = moment(doc.UnOccupiedHoursFrom, 'HH:mm:ss').format('HH:mm:ss');
    doc.UnOccupiedHoursTo = moment(doc.UnOccupiedHoursTo, 'HH:mm:ss').format('HH:mm:ss');

    var splitted1 = doc.UnOccupiedHoursFrom.split(":");
    var splitted2 = doc.UnOccupiedHoursTo.split(":");

    var time1 = Math.floor((parseInt(splitted1[0]) * 60) + parseInt(splitted1[1]))
    var time2 = Math.floor((parseInt(splitted2[0]) * 60) + parseInt(splitted2[1]))
    if (time2 >= time1) {
      var time3 = Math.floor(time2 - time1)
      var time4 = Math.floor(time3 / 60)
      var time5 = Math.floor(time3 % 60)

    }
    doc.partiallytotaltime = time4 + ":" + time5;
    let starttimes = doc.UnOccupiedHoursFrom.split(':');
    let endtimes = doc.UnOccupiedHoursTo.split(':');
    var StartDateTime = new Date();
    var EndDateTime = new Date();
    StartDateTime.setHours(parseInt(starttimes[0]));
    StartDateTime.setMinutes(parseInt(starttimes[1]));
    StartDateTime.setSeconds(parseInt(starttimes[2]));
    EndDateTime.setHours(parseInt(endtimes[0]));
    EndDateTime.setMinutes(parseInt(endtimes[1]));
    EndDateTime.setSeconds(parseInt(endtimes[2]));
    doc.UnOccupiedHoursFrom = StartDateTime
    doc.UnOccupiedHoursTo = EndDateTime
  }
  SelectTime(doc) {
    // select time dropdown 

    const a = this.allocatedata?.partially.filter(x => x.UnOccupiedHoursFrom == doc.UnOccupiedHoursFrom && x.UnOccupiedHoursTo == doc.UnOccupiedHoursTo)
    a.forEach(element => {

      element.UnOccFrom = moment(doc?.UnOccupiedHoursFrom).format('HH:mm:ss')
      element.UnOccTo = moment(doc.UnOccupiedHoursTo, 'HH:mm:ss').subtract(this.viewTaskData.AssignMinutes + (this.viewTaskData.AssignHours * 60), 'minutes').format('HH:mm:ss');
    })
    doc.AssignHoursTo = moment(doc.AssignHoursFrom, 'HH:mm:ss').add(this.viewTaskData.AssignMinutes + (this.viewTaskData.AssignHours * 60), 'minutes').format('HH:mm:ss');
    doc.AssignHoursFrom = moment(doc.AssignHoursFrom, 'HH:mm:ss').format('HH:mm:ss');
    doc.AssignHoursTo = moment(doc.AssignHoursTo, 'HH:mm:ss').format('HH:mm:ss');
    var split1 = doc.AssignHoursFrom.split(":");
    var split2 = doc.AssignHoursTo.split(":");
    var time1 = Math.floor((parseInt(split1[0]) * 60) + parseInt(split1[1]))
    var time2 = Math.floor((parseInt(split2[0]) * 60) + parseInt(split2[1]))

    if (time2 >= time1) {
      this.UnOccupiedHoursflag = true
      const a = this.allocatedata?.partially.filter(x => x.OccupiedDate == doc.OccupiedDate)
      let c = moment(doc.OccupiedDate).format('L');
      let b = this.viewTaskData?.DateList.findIndex(x => moment(x.Date).format('L') == c)
      this.viewTaskData.DateList[b].TaskStartTime = doc.AssignHoursFrom
      this.viewTaskData.DateList[b].TaskEndTime = doc.AssignHoursTo
      a.forEach(element => {
        this.UnOccupiedHoursflag ? element.isdisabled = true : element.isdisabled = false
        element.AssignHoursFrom != '' ? element.isClear = true : element.isClear = false
      });

      var time3 = Math.floor(time2 - time1)
      var time4 = Math.floor(time3 / 60)
      var time5 = Math.floor(time3 % 60)
    }
    this.assignhour = time4
    this.assignmin = time5
    doc.assigntime = time4 + ":" + time5;

    this.AssignHoursFrom = doc.AssignHoursFrom
    this.AssignHoursTo = doc.AssignHoursTo
    // if (time4 == undefined) {
    //   this.UnOccupiedHoursflag = false
    // }
  }
  SelectClear(event, doc, i) {

    const a = this.allocatedata?.partially.filter(x => x.OccupiedDate == doc.OccupiedDate)
    a.forEach(element => {

      element.AssignHoursFrom = ''
      element.AssignHoursTo = ''
      element.isdisabled = false
      element.isClear = false
      console.log(this.allocatedata.partially.every(x => x.AssignHoursFrom != ''))
      if (this.allocatedata.partially.every(x => x.AssignHoursFrom != '' && x.AssignHoursFrom != 'Invalid date')) {

        this.UnOccupiedHoursflag = true
      }
      else {
        this.UnOccupiedHoursflag = false
      }
    })
    this.cleartimeselect = false

    this.SelectValidaiton(event, doc, i);
  }
  SelectValidaiton(event, doc, i) {
    // Select hour validation  
    if (doc.AssignHoursFrom == '') {

      this.viewTaskData.DateList[i].TaskStartTime = moment(doc.UnOccupiedHoursFrom, 'HH:mm:ss').format('HH:mm:ss');
      this.viewTaskData.DateList[i].TaskEndTime = moment(doc.UnOccupiedHoursFrom, 'HH:mm:ss').add(this.viewTaskData.AssignMinutes + (this.viewTaskData.AssignHours * 60), 'minutes').format('HH:mm:ss');

    }
    console.log(doc)
    let From = moment(doc?.UnOccupiedHoursFrom).format('HH:mm:ss')
    let To = moment(doc?.UnOccupiedHoursTo).format('HH:mm:ss')
    doc.AssignHoursTo = moment(doc.AssignHoursFrom, 'HH:mm:ss').add(this.viewTaskData.AssignMinutes + (this.viewTaskData.AssignHours * 60), 'minutes').format('HH:mm:ss');
    // (From > event.value || To <= event.value || To < doc.AssignHoursTo) ?
    //   (this.UnOccupiedHoursflag = false, this.errormessage = "Time should be selected from Unoccupied Hours only")
    //   : (this.UnOccupiedHoursflag = true, this.errormessage = "")

  }

  OccupaiedTotal(doc) {
    //  Occupaied Total Time   
    const d = this.allocatedata?.UnAvaliableresource.filter(x => x.OccupiedHoursFrom == doc.OccupiedHoursFrom && x.OccupiedHoursTo == doc.OccupiedHoursTo)
    d.forEach(element => {
      element.OccFrom = moment(doc.OccupiedHoursFrom, 'HH:mm:ss').format('HH:mm:ss');
      element.OccTo = moment(doc.OccupiedHoursTo, 'HH:mm:ss').subtract(this.viewTaskData.AssignMinutes + (this.viewTaskData.AssignHours * 60), 'minutes').format('HH:mm:ss');


    })

    doc.OccupiedHoursFrom = moment(doc.OccupiedHoursFrom, 'HH:mm:ss').format('HH:mm:ss');
    doc.OccupiedHoursTo = moment(doc.OccupiedHoursTo, 'HH:mm:ss').format('HH:mm:ss');
    var splitted1 = doc.OccupiedHoursFrom.split(":");
    var splitted2 = doc.OccupiedHoursTo.split(":");
    var time1 = Math.floor((parseInt(splitted1[0]) * 60) + parseInt(splitted1[1]))
    var time2 = Math.floor((parseInt(splitted2[0]) * 60) + parseInt(splitted2[1]))
    if (time2 >= time1) {
      const a = this.allocatedata?.UnAvaliableresource.filter(x => x.OccupiedDate == doc.OccupiedDate)
      a.forEach(element => {
        this.OccupiedHoursflag ? element.isdisabled = true : element.isdisabled = false
        element.DetachHoursFrom != '' ? element.isClear = true : element.isClear = false
      });
      var time3 = Math.floor(time2 - time1)
      var time4 = Math.floor(time3 / 60)
      var time5 = Math.floor(time3 % 60)
    }
    doc.Occupaiedtime = time4 + ":" + time5;
    var StartDateTime = new Date();
    var EndDateTime = new Date();
    StartDateTime.setHours(parseInt(splitted1[0]));
    StartDateTime.setMinutes(parseInt(splitted1[1]));
    StartDateTime.setSeconds(parseInt(splitted1[2]));
    EndDateTime.setHours(parseInt(splitted2[0]));
    EndDateTime.setMinutes(parseInt(splitted2[1]));
    EndDateTime.setSeconds(parseInt(splitted2[2]));
    doc.OccupiedHoursFrom = StartDateTime
    doc.OccupiedHoursTo = EndDateTime

  }
  DetachTimeTotal(doc) {
    //  Detach Total Hours
    doc.DetachHoursFrom = moment(doc.DetachHoursFrom, 'HH:mm:ss').format('HH:mm:ss');
    doc.DetachHoursFromTo = moment(doc.DetachHoursFrom, 'HH:mm:ss').add(this.viewTaskData.AssignMinutes + (this.viewTaskData.AssignHours * 60), 'minutes').format('HH:mm:ss');
    // doc.DetachHoursFromTo = moment(doc.DetachHoursFromTo, 'HH:mm:ss').format('HH:mm:ss');

    var split1 = doc?.DetachHoursFrom.split(":");
    var split2 = doc?.DetachHoursFromTo.split(":");
    var time1 = Math.floor((parseInt(split1[0]) * 60) + parseInt(split1[1]))
    var time2 = Math.floor((parseInt(split2[0]) * 60) + parseInt(split2[1]))
    if (time2 >= time1) {
      this.occpflag = true
      var time3 = Math.floor(time2 - time1)
      var time4 = Math.floor(time3 / 60)
      var time5 = Math.floor(time3 % 60)
    }
    doc.Detachtime = time4 + ":" + time5;
    doc.myDetachHour = time4;
    doc.myDetachMinutes = time5;

    time4 == undefined || doc.DetachHoursFrom == '' ? this.OccupiedHoursflag = false : this.OccupiedHoursflag = true

  }
  DetachClear(event, doc) {

    doc.DetachHoursFrom = ''
    doc.DetachHoursFromTo = ''

    this.cleartimeselect = false
    this.DetachValidation(event, doc);
  }
  DetachValidation(event, element) {
    // Detach Validation
    element.DetachHoursFrom != '' ? this.cleartimeselect = true : this.cleartimeselect = false
    element.DetachHoursFromTo = moment(element.DetachHoursFrom, 'HH:mm:ss').add(this.viewTaskData.AssignMinutes + (this.viewTaskData.AssignHours * 60), 'minutes').format('HH:mm:ss');
    //  (OccupiedHoursFrom > event.value || OccupiedHoursTo <= event.value ||  OccupiedHoursTo < element.DetachHoursFromTo) ?
    //     (this.OccupiedHoursflag = false, this.errormessage = "Time should be selected from Unoccupied Hours only")    
    //    : (this.OccupiedHoursflag = true, this.errormessage = "")        

    element.DetachHoursFrom == '' ? this.OccupiedHoursflag = false : this.OccupiedHoursflag = true
  }



  AssignModal() {
    // Assign Button
    // let a= this.allocatedata?.partially.findIndex(x =>x.AssignHoursFrom=='')
    // console.log(a);
    // this.allocatedata?.partially.forEach(hourdata => {

    // });
    this.viewTaskData.AssignTo = this.allocatedata?.data
    localStorage.setItem('viewTaskData', JSON.stringify(this.viewTaskData));
    this.dialogRef.close(this.viewTaskData)
  }
  Assignment(element) {
    console.log(element);
    // Assignment Popup
    this.spinner.show();
    let input;
    if (this.dialogAction == 'assignmentCalendar') {
      input = {
        "EmployeeId": element?.EmployeeId,
        "TaskDate": element.TaskDate
      }
    } else if (this.dialogAction == 'assignment') {
      this.dialogAction = 'assignment'
      console.log('mayoo');
      input = {
        "EmployeeId": this.allocatedata?.UnAvaliableEmployee?.EmployeeId,
        "TaskDate": element.OccupiedDate
      }
    } else if (this.dialogAction == 'assignmentDetach') {
      input = {
        "EmployeeId": element?.RequestedFor?.EmployeeId,
        "TaskDate": element.OccupiedDate
      }
    }
    this.taskService.postService(input, "EmployeeTaskByDate").subscribe(
      response => {
        this.spinner.hide();
        this.AssignmentData = response.body.Result
        for (let schedulelist of this.AssignmentData?.Result) {
          this.Schedule(schedulelist);
        }
        const a = this.AssignmentData?.Result.filter(x => !x.EmployeeTimeSheet?.SubmissionDate);
        console.log(a);
        if (a?.length == 0) {
          this.isDetachAllManager = false;
        } else {
          this.isDetachAllManager = true;
        }
      });
  }

  Schedule(schedulelist) {
    // Assignment Task Time Schedule
    schedulelist.TaskStartTime = moment(schedulelist.TaskStartTime, 'HH:mm:ss').format('HH:mm:ss');
    schedulelist.TaskEndTime = moment(schedulelist.TaskEndTime, 'HH:mm:ss').format('HH:mm:ss');
    var split1 = schedulelist?.TaskStartTime.split(":");
    var split2 = schedulelist?.TaskEndTime.split(":");
    var time1 = Math.floor((parseInt(split1[0]) * 60) + parseInt(split1[1]))
    var time2 = Math.floor((parseInt(split2[0]) * 60) + parseInt(split2[1]))
    if (time2 >= time1) {
      var time3 = Math.floor(time2 - time1)
      var time4 = Math.floor(time3 / 60)
      var time5 = Math.floor(time3 % 60)
    }
    schedulelist.Schedule = time4 + ":" + time5;
    let starttimes = schedulelist.TaskStartTime.split(':');
    let endtimes = schedulelist.TaskEndTime.split(':');
    var StartDateTime = new Date();
    var EndDateTime = new Date();
    StartDateTime.setHours(parseInt(starttimes[0]));
    StartDateTime.setMinutes(parseInt(starttimes[1]));
    StartDateTime.setSeconds(parseInt(starttimes[2]));
    EndDateTime.setHours(parseInt(endtimes[0]));
    EndDateTime.setMinutes(parseInt(endtimes[1]));
    EndDateTime.setSeconds(parseInt(endtimes[2]));
    schedulelist.TaskStartTime = StartDateTime
    schedulelist.TaskEndTime = EndDateTime

  }
  detachrequest() {
    console.log(this.allocatedata);
    this.spinner.show();
    for (let x of this.allocatedata.UnAvaliableresource) {
      this.detachRequest.assignBy = x.AssignBy;
      //x.OccupiedDate
      this.detachRequest.requestedOn = new Date();
      this.occupencyLists.taskId = x.TaskId;
      this.occupencyLists.occupiedDate = x.OccupiedDate;
      this.occupencyLists.occupiedHoursFrom = moment(x.OccupiedHoursFrom).format('HH:mm:ss');
      this.occupencyLists.occupiedHoursTo = moment(x.OccupiedHoursTo).format('HH:mm:ss');
      this.occupencyLists.detachHoursFrom = x.DetachHoursFrom;
      this.occupencyLists.detachHoursFromTo = x.DetachHoursFromTo;
      this.occupencyLists.AssignHours = x.AssignHours;
      this.occupencyLists.AssignMinutes = x.AssignMinutes;
      this.occupencyLists.DetachHours = x.myDetachHour;
      this.occupencyLists.DetachMinutes = x.myDetachMinutes;
      this.detachRequest.requestedFor = this.allocatedata.UnAvaliableEmployee;
      this.detachRequest.requestedFor.Designation = this.allocatedata?.UnAvaliableEmployee?.Designation?.DesignationName;
      this.detachRequest.requestedFor.Department = this.allocatedata?.UnAvaliableEmployee?.Department?.DepartmentName;
      this.detachRequest.taskDetachId = 0
      this.detachRequest.occupencyList.push(this.occupencyLists)

    }
    this.InputDetachreq.push(this.detachRequest)
    console.log(this.InputDetachreq);


    this.taskService.OtherActivityService(this.InputDetachreq, 'TaskDetach/SaveTaskDetachRequest')
      .subscribe(response => {

        if (response.body.Success) {
          this.spinner.hide();

          console.log(response?.body?.Result?.TransactionMessage?.Message);

          this.resultPopup('success', response?.body?.Result?.TransactionMessage?.Message)

        } else {
          this.spinner.hide();
          this.resultPopup('Alert', response?.body?.Result?.TransactionMessage?.Message)
        }
        this.dialogRef.close()

      }, error => {
        this.spinner.hide();
      });
  }

  detachrequestEmployee(data) {
    debugger
    this.spinner.show();
    let Input = [{
      occupencyList: [{
        "taskId": data.TaskId,
        "occupiedDate": this.assignmentCalendarData.TaskDate,
        "occupiedHoursFrom": this.assignmentCalendarData.FromTime, //AS
        "occupiedHoursTo": this.assignmentCalendarData.ToTime, //AE
        "detachHoursFrom": this.viewTaskData.TaskStartTime, //DS
        "detachHoursFromTo": this.viewTaskData.TaskEndTime, //DE
        "dateList": null
      }],
      assignBy: data.AssignBy,
      requestedOn: new Date(),
      taskDetachId: 0,
      requestedFor: {
        EmployeeId: this.assignmentCalendarData?.EmployeeId,
        EmployeeName: this.assignmentCalendarData?.EmployeeName,
        EmployeeCode: this.assignmentCalendarData?.EmployeeCode,
        Designation: this.assignmentCalendarData?.Designation,
        Department: this.assignmentCalendarData?.DepartmentName,
        EmployeeImageName: this.assignmentCalendarData?.EmployeeImageName,
        EmployeeImagePath: this.assignmentCalendarData?.EmployeeImagePath
      }
    }];
    if(this.assignmentCalendarData.FromTime <= this.viewTaskData.TaskStartTime && this.assignmentCalendarData.ToTime >= this.viewTaskData.TaskEndTime){
      Input[0].occupencyList[0].detachHoursFrom = this.viewTaskData.TaskStartTime;
      Input[0].occupencyList[0].detachHoursFromTo = this.viewTaskData.TaskEndTime;
    }else if(this.assignmentCalendarData.FromTime >= this.viewTaskData.TaskStartTime && this.assignmentCalendarData.ToTime <= this.viewTaskData.TaskEndTime){
      Input[0].occupencyList[0].detachHoursFrom = this.assignmentCalendarData.FromTime;
      Input[0].occupencyList[0].detachHoursFromTo = this.assignmentCalendarData.ToTime;
    }else if(this.assignmentCalendarData.FromTime <= this.viewTaskData.TaskStartTime && this.assignmentCalendarData.ToTime <= this.viewTaskData.TaskEndTime){
      Input[0].occupencyList[0].detachHoursFrom = this.viewTaskData.TaskStartTime;
      Input[0].occupencyList[0].detachHoursFromTo = this.assignmentCalendarData.ToTime;
    }else if(this.assignmentCalendarData.FromTime >= this.viewTaskData.TaskStartTime && this.assignmentCalendarData.ToTime >= this.viewTaskData.TaskEndTime){
      Input[0].occupencyList[0].detachHoursFrom = this.assignmentCalendarData.FromTime;
      Input[0].occupencyList[0].detachHoursFromTo = this.viewTaskData.TaskEndTime;
    }
    this.taskService.OtherActivityService(Input, 'TaskDetach/SaveTaskDetachRequest')
      .subscribe(response => {
        if (response.body.Success) {
          this.spinner.hide();
          this.resultPopup('success', response?.body?.Result?.TransactionMessage?.Message)
        } else {
          this.spinner.hide();
          this.resultPopup('Alert', response?.body?.Result?.TransactionMessage?.Message)
        }
        this.dialogRef.close()
      }, error => {
        this.spinner.hide();
      });
  }
  detachrequestStaffingEmployee() {
    console.log(this.AssignmentData);
    console.log(this.viewTaskData);
    let Input = {
      assignBy: [],      
      requestedFor: {
        EmployeeId: this.assignmentCalendarData?.EmployeeId,
        EmployeeName: this.assignmentCalendarData?.EmployeeName,
        EmployeeCode: this.assignmentCalendarData?.EmployeeCode,
        Designation: this.assignmentCalendarData?.Designation,
        Department: this.assignmentCalendarData?.DepartmentName,
        EmployeeImageName: this.assignmentCalendarData?.EmployeeImageName,
        EmployeeImagePath: this.assignmentCalendarData?.EmployeeImagePath
      },
      RequestedBy: null,
      "requestedOn": new Date(),
      "StatusDate": null,
      "StartDate": this.viewTaskData.TaskStartDate,
      "EndDate": this.viewTaskData.TaskEndDate,
    };
    this.AssignmentData?.Result.forEach(element => {
      Input.assignBy.push(element.AssignBy);
    });
    console.log(Input);
    this.spinner.show();
    this.taskService.OtherActivityService(Input, 'TaskDetach/SaveTaskDetachRequestToProjectManager')
      .subscribe(response => {
        if (response.body.Success) {
          this.spinner.hide();
          this.resultPopup('success', response?.body?.Result?.TransactionMessage?.Message)
        } else {
          this.spinner.hide();
          this.resultPopup('Alert', response?.body?.Result?.TransactionMessage?.Message)
        }
        this.dialogRef.close()
      }, error => {
        this.spinner.hide();
      });
  }

  detachrequestStaffing() {
    console.log(this.allocatedata);
    this.spinner.show();
    for (let x of this.allocatedata.occData) {
      let detachRequest: any = {
        assignBy: []

      }
      // x.AssignBy.Department = x.AssignBy.Department.DepartmentName;
      // x.AssignBy.Designation = x.AssignBy.Designation.DesignationName
      detachRequest.assignBy.push(x.AssignBy);
      //detachRequest.requestedOn = x.OccupiedDate;

      detachRequest.StartDate = this.allocatedata.resData.startDate;
      detachRequest.EndDate = this.allocatedata.resData.endDate;
      detachRequest.requestedOn = new Date();
      detachRequest.StatusDate = new Date();
      detachRequest.requestedFor = this.allocatedata.resData.employee;
      detachRequest.requestedFor.Designation = this.allocatedata.resData.employee.Designation.DesignationName;
      detachRequest.requestedFor.Department = this.allocatedata.resData.employee.Department.DepartmentName;


      this.detachRequest = detachRequest

    }
    this.taskService.OtherActivityService(this.detachRequest, 'TaskDetach/SaveTaskDetachRequestToProjectManager')
      .subscribe(response => {

        if (response.body.Success) {
          this.spinner.hide();
          console.log(response?.body?.Result?.TransactionMessage?.Message);

          this.resultPopup('success', response?.body?.Result?.TransactionMessage?.Message)


        } else {
          this.spinner.hide();
          this.resultPopup('Alert', response?.body?.Result?.TransactionMessage?.Message)
        }
        this.dialogRef.close()


      }, error => {
        this.spinner.hide();
      });
  }
  resultPopup(action, message) {
    this.dialogRefs = this.dialog.open(CommonModalComponent, {
      width: '300px',
      disableClose: true,
      autoFocus: false,
      panelClass: 'statusClass',
      data: { action: action, statusText: message }
    });
  }
  goback() {
    this.location.back()
  }

}
