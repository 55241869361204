import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableService } from '@planwork/datatable-settings';
import { DataTableDirective } from 'angular-datatables';
import { TableColumn } from './Table.Column';

@Component({
  selector: 'app-dispose-approval',
  templateUrl: './dispose-approval.component.html',
  styleUrls: ['./dispose-approval.component.scss']
})
export class DisposeApprovalComponent implements OnInit {

  selected = '0'
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  tableSetting: any = {};
  isShow = false;
  theadColumn = [];
  sortItem = 1;
  EmployeeOutput:any
  status=[
    {name:'To Be Approved'},
    {name:'Approved'},
    {name:'Rework'},
    {name:'Declined'}
  ];
  constructor(private datatableServise: DatatableService) {
    this.theadColumn = TableColumn;
    this.tableSetting = this.datatableServise.tablePagination();
   }

  ngOnInit(): void {
  }
  toggleSearch(text) {
    if (text == 'show') {
      this.isShow = true;
    } else {
      this.isShow = false;
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.search('');
        dtInstance.draw();
      });
    }
  }
}
