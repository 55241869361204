import { Component, OnInit } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Router } from '@angular/router';
import { DataStorage } from 'app/store/storage';

interface Datatype {
  name: string;
  url: string;
  children: [];
}

@Component({
  selector: 'app-coresidebar',
  templateUrl: './coresidebar.component.html',
  styleUrls: ['./coresidebar.component.scss']
})
export class CoresidebarComponent implements OnInit {
  isExpanded = false;
  SIDEBARDATA: any[] = [];
  treeControl = new NestedTreeControl<any>(node => node.children);
  configure: any;
  constructor(private router: Router,
    private store: DataStorage,) {
    this.store._Masterpermission.subscribe(res => {
      this.SIDEBARDATA = res?.children;
    })
    
  }

  clicked(event) { 
    
    this.isExpanded = !this.isExpanded;
    event.target.classList.add('clabluecolor');
  }
  hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
  }
}
