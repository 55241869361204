import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanworkSharedModule } from '@planwork/planwork.shared.module';
import { RouterModule } from '@angular/router';
import { RegisterCompanyComponent } from './register-company.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { CompanyListComponent } from './company-list/company-list.component';
import { FuseMatSelectSearchModule } from '@planwork/mat-select-search/mat-select-search.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DatatableService } from '@planwork/datatable-settings';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticatedHttpService } from '@planwork/services/authenticated-http.service';


const routes = [
  {
      path: '',
      component: CompanyListComponent,
  },
  {
    path: 'register-company',
    component: RegisterCompanyComponent,
  },  
  {
    path: 'configuration',
    component: ConfigurationComponent,
  }
];


@NgModule({
  declarations: [RegisterCompanyComponent, ConfigurationComponent, CompanyListComponent],
  imports: [
    PlanworkSharedModule,
    CommonModule,
    RouterModule.forChild(routes),
    PlanworkSharedModule,
    FuseMatSelectSearchModule,
    MatSnackBarModule,
  ],
  exports: [PlanworkSharedModule],
  providers: [DatatableService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticatedHttpService, multi: true  }
  ]
})
export class RegisterCompanyModule { }

