import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LeadService } from '../../lead.service';
import { Subscription, of } from 'rxjs';
import { HttpRequest, HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { map, tap, last, catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ServiceService } from 'app/main/core/masterservice/service.service';
import { CommonModalComponent } from 'app/main/core/common/common-modal/common-modal.component';
import { DropdownService } from '@planwork/services/dropdown.service';
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};
@Component({
  selector: 'app-ballpark-modals',
  templateUrl: './ballpark-modals.component.html',
  styleUrls: ['./ballpark-modals.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class BallparkModalsComponent implements OnInit {
  DeliverableInput: any = {
    DeliverableId: '',
    DeliverableUnits: '',
    DurationUnit: {},
    DeliveryCycle: {},
    RenewalAlertBeforeUnit: {},
    DeliveryReminderBeforeUnit: {},
    CommencementTimeUnit: {},
    SuspensionTimeUnit: {},
    ManagementFee: {
      ManagementFeeType: '%'
    }
  };
  totalIssuesQty: any;
  deliverableClone: any;
  deliverableType = [
    { name: 'One Time', id: 1 },
    { name: 'Repeat', id: 2 },
    { name: 'Random', id: 3 }
  ]
  CategoryInput: any = {};
  SubCategoryInput: any = {};
  ActivityInput: any = {
    IsClientApproval: false,
  };
  SubActivityInput: any = {
    IsClientApproval: false,
  };
  ResourceInput: any = {
    TotalQuantity: 0,
    TotalOrderQuantity: 0,
    TotalEfforts: 0,
    IsClientApproval: false,
    IsInvoice: false,
    ClientRequirementList: [],
  };
  DeliverableInfo: any;
  dialogAction: any;
  SubHeading: any;
  SubTitle: any;
  user: any;
  favoriteSeason: string;
  seasons: string[] = ['One Time', 'Repeat', 'Random'];
  DeliverabledropDownList: any = {};
  ActivitydropDownList: any;
  SubActivitydropDownList: any;
  leadView: any = {};
  leadViewCurrency: any;
  finalFile: any[] = [];
  addDocumentDetail: any = {
    ImageList: [{
      DocumentFileName: '',
      DocumentPath: ''
    }]
  };
  Imageshow = false;
  leadProjectModel: any = {
    IsModule: true
  };
  DeclineDetail: any = {
    PMRejectReason: {}
  };
  ReworkReq:any={}
  ModalData: any={};
  companybranch: any;
  ClientAcceptenceData: any = {};
  currencysymbol: any;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<'prsn_dialog'>,
    private masterService: LeadService,
    private coreService: ServiceService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    public _http: HttpClient,
    private spinner: NgxSpinnerService,
    public dropdown: DropdownService
  ) {
    console.log(data);
    this.leadView = this.data.taskData;
    this.leadViewCurrency = this.data.currency;
    this.dialogAction = data.action;
    this.SubHeading = data.SubAction;
    this.ModalData = data;
    this.user = JSON.parse(localStorage.getItem('userid'));
    if (this.dialogAction == 'deliverableDetail') {
      this.DeliverableInput = data.Data;
    }
    if (this.dialogAction == 'viewAcceptence') {
      this.ClientAcceptenceData = data.Data;
    }
    if (this.dialogAction == 'add-deliverable' || this.dialogAction == 'edit-deliverable') {
      this.DropdownDeliverable()
      if (this.dialogAction == 'edit-deliverable') {
        this.DeliverableInput = data.Data;
        this.deliverableClone = { ...this.DeliverableInput }
      }
    }
    if (this.dialogAction == 'add-sub-category' || this.dialogAction == 'edit-sub-category') {
      // this.DropdownSubActivity()
      if (this.dialogAction == 'edit-sub-category') {
        this.SubCategoryInput = data.Data;
      }
    }
    if (this.dialogAction == 'add-activity' || this.dialogAction == 'edit-activity') {
      this.DropdownDeliverable();
      this.DropdownActivity(data.BusinessProcessId, data.CategoryId)
      if (this.dialogAction == 'edit-activity') {
        this.ActivityInput = data.Data;
      }
    }
    if (this.dialogAction == 'add-sub-activity' || this.dialogAction == 'edit-sub-activity') {
      this.DropdownDeliverable();
      this.DropdownActivity(data.BusinessProcessId, data.CategoryId);
      this.DropdownSubActivity(data.BusinessProcessId, data.ActivityId);
      if (this.dialogAction == 'edit-sub-activity') {
        this.SubActivityInput = data.Data;
      }
    }
    if (this.dialogAction == 'add-resource' || this.dialogAction == 'edit-resource') {
      this.DropdownDeliverable()
      console.log(data.deliverableData)
      this.SubTitle = data.SubTitle;
      this.ResourceInput.Currency = data.Currency;
      this.DeliverableInfo = data.deliverableData;
      this.ResourceInput.DeliverableUnits = data.deliverableData.DeliverableUnits;
      this.ResourceInput.NumberOfCycles = data.deliverableData.NumberOfCycles;
      this.ResourceInput.DeliveryCycle = data.deliverableData.DeliveryCycle;
      if (this.dialogAction == 'edit-resource') {
        this.ResourceInput = data.Data;
      }
    }
    if (this.dialogAction == 'editModulePhase') {
      this.leadProjectModel = this.data.data;
    }
  }

  async ngOnInit() {
    console.log(this.ModalData);

    this.currencysymbol = JSON.parse(localStorage.getItem('userid'))
    if (this.dialogAction == 'consumableIssue') {
      let persondata = await this.dropdowncompanybranch();
      this.companybranch = persondata;
      this.companybranch.forEach(element => {
        element.AvailableQuantity = 0
      });
    }
  }
  dropdowncompanybranch():Promise<any>{
    return this.dropdown.branchListDropDownList({}).toPromise();
  }
  delTypeChange(event) {
    if (event.value == 'One Time') {
      this.DeliverableInput.DeliveryCycle = 'NA';
      this.DeliverableInput.NumberOfCycles = 'NA';
    } else {
      this.DeliverableInput.DeliveryCycle = this.deliverableClone.DeliveryCycle;
      this.DeliverableInput.NumberOfCycles = this.deliverableClone.NumberOfCycles;
    }
  }
  reworksave(){
    this.ReworkReq.RequisitionId = this.ModalData.data.id
    this.ReworkReq.status = this.ModalData.data.status
    this.coreService.reuquisitonService( this.ReworkReq , 'AssetRequisitionsStatusChange')
    .subscribe(res => {
      let dialogRef
      if (res?.body?.TransactionMessage?.Success == true) {
          dialogRef = this.dialog.open(CommonModalComponent, {
          width: "300px",
          disableClose: true,
          data: { action: "success", statusText: res?.body?.TransactionMessage?.Message }
        });
        dialogRef.afterClosed()
        .subscribe(result => {
          if(result){
            window.location.reload()
          }
        })
      }
      else {
        dialogRef = this.dialog.open(CommonModalComponent, {
          width: "300px",
          disableClose: true,
          data: { action: "Alert", statusText: res?.body?.TransactionMessage?.Message },
        });}
    });

  }
  DropdownDeliverable() {
    this.masterService.projectmanager({ UserRoleId: this.user.UserRole.UserRoleId }, 'BallParkEstimationDropDownList')
      .subscribe(response => {
        if (response.body.Success) {
          this.DeliverabledropDownList = response.body.Result.LeadList;
        }
      });
  }
  DropdownActivity(a, b) {
    this.masterService.projectmanager({ UserRoleId: this.user.UserRole.UserRoleId }, 'ActivityDropDownList?BusinessProcessId=' + a + '&CategoryId=' + b)
      .subscribe(response => {
        if (response.body.Success) {
          this.ActivitydropDownList = response.body.Result;
        }
      });
  }
  DropdownSubActivity(a, b) {
    this.masterService.projectmanager({ UserRoleId: this.user.UserRole.UserRoleId }, 'SubActivityDropDownList?BusinessProcessId=' + a + '&ActivityId=' + b)
      .subscribe(response => {
        if (response.body.Success) {
          this.SubActivitydropDownList = response.body.Result;
        }
      });
  }
  deviceclose() {
    this.dialogRef.close()
  }
  devicecloseFalse(a) {
    this.dialogRef.close(a)
  }
  compareFn(a: any, b: any) {
    if (b) {
      const d = a[Object.keys(a)[0]];
      const e = b[Object.keys(b)[0]];
      return d === e;
    }
  }
  IsClientApprovalCheck(a, b) {
    if (a.checked) {
      b = true;
    } else {
      b = false;
    }
  }
  SaveDeliverableInput() {
    this.dialogRef.close(this.DeliverableInput)
  }
  SaveCategoryInput() {
    this.dialogRef.close(this.CategoryInput)
  }
  SaveSubCategoryInput() {
    this.dialogRef.close(this.SubCategoryInput)
  }
  removeSkills(a, i) {
    console.log(a)
    console.log(i)
    a.splice(i, 1)
  }
  SaveActivityInput() {
    this.dialogRef.close(this.ActivityInput)
  }
  SaveSubActivityInput() {
    this.dialogRef.close(this.SubActivityInput)
  }
  getTotalorderQuantity(a, b) {
    this.ResourceInput.TotalOrderQuantity = a * b;
    return a * b;
  }
  getTotalEfforts(a, b, c) {
    this.ResourceInput.TotalEfforts = a * b * c;
    return a * b * c;
  }
  getTotalorderQuantityRepeat(a, b, c) {
    // this.ResourceInput.TotalEfforts = a*b*c;
    return a * b * c;
  }
  getTotalEffortsRepeat(a, b, c, d) {
    // this.ResourceInput.TotalEfforts = a*b*c;
    return a * b * c * d;
  }
  AddClientRequirement(data) {
    console.log(data)
    this.ResourceInput.ClientRequirementList.push(data)
    this.ResourceInput.ClientRequirement = null;
  }
  getTotalIssueQty(){
    console.log('first')
    this.totalIssuesQty = this.companybranch.reduce((accumulator, currentItem) => {
      return accumulator + currentItem.QuantityToBeIssuue;
    }, 0);
  }
  SaveConsumableInput(){
    this.getTotalIssueQty();
    if(this.totalIssuesQty < this.ModalData?.Data?.Quantity){
      this.dialogRef.close(this.companybranch);
    }else{
      return;
    }
    
  }
  SaveResource() {
    console.log(this.ResourceInput)
    this.dialogRef.close(this.ResourceInput)
  }
  acceptedClientSubmit() {
    this.dialogRef.close({ data: Object.assign(this.addDocumentDetail) });
  }
  onSubmitPhase() {
    this.dialogRef.close(this.leadProjectModel)
    this.spinner.show();
  }
  delstartDateCheck(DeliverableInput) {
    console.log(DeliverableInput);
    if (DeliverableInput.Duration && DeliverableInput.DurationUnit) {
      if (DeliverableInput.StartDate) {
        let startDate = DeliverableInput.StartDate;
        var year = new Date(startDate).getFullYear();
        var month = new Date(startDate).getMonth();
        var day = new Date(startDate).getDate();
      }

      switch (DeliverableInput.DurationUnit.DurationUnitId) {
        case 5: //Year
          if (DeliverableInput.StartDate) {
            DeliverableInput.EndDate = new Date(year + DeliverableInput.Duration, month, day - 1);
          }
          DeliverableInput.durationDivide = DeliverableInput.Duration * 12;
          break;
        case 4: //Month
          if (DeliverableInput.StartDate) {
            DeliverableInput.EndDate = new Date(year, month + DeliverableInput.Duration, day - 1);
          }
          DeliverableInput.durationDivide = DeliverableInput.Duration * 1;
          break;
        case 2: //Daily
          if (DeliverableInput.StartDate) {
            DeliverableInput.EndDate = new Date(year, month, day + DeliverableInput.Duration);
          }
          break;
      }
    }
    if (DeliverableInput.durationDivide && DeliverableInput.DeliveryCycle) {
      if (DeliverableInput.DeliveryCycle.PaymentTypeId != 2) {
        DeliverableInput.NumberOfCycles = parseInt(DeliverableInput.durationDivide) / parseInt(DeliverableInput.DeliveryCycle.TotalMonth);
      } else {
        DeliverableInput.NumberOfCycles = 1;
      }
    }
    console.log(DeliverableInput);
    if (DeliverableInput.DurationUnit && DeliverableInput.DurationUnit.DurationUnitId == 4) {
      for (let list of this.DeliverabledropDownList?.PaymentTypeList) {
        if (list.PaymentTypeId == 3) {
          list.disable = true;
        }
        if (list.PaymentTypeId == 5) {
          list.disable = true;
        }
        console.log(list);
      }
    }
  }
  downloadMyFile(data, b) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    if (data.FileData) {
      link.setAttribute('href', data.Preview);
    } else {
      this.coreService.getDocument({ DocumentId: data?.DocumentId }).subscribe(res => {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'data:image/png;base64,' + res?.body?.documentData);
        // link.setAttribute('href', data.DocumentPath);
        link.setAttribute('download', data.DocumentFileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
    }
    link.setAttribute('download', data.DocumentFileName);
    document.body.appendChild(link)
    link.click();
    link.remove();
  }
  onClick(event) {
    console.log(event)
    const fileUploaded = document.getElementById('fileUploaded') as HTMLInputElement;
    // fileUploaded.onchange = () => {
    for (let index = 0; index < fileUploaded.files.length; index++) {
      const file = fileUploaded.files[index];
      console.log(file)
      this.files.push({
        data: file, state: 'in',
        inProgress: false, progress: 0, canRetry: false, canCancel: true
      });
    }
    this.uploadFiles();
    // };
    //fileUploaded.click();
  }
  public uploadFiles() {
    const fileUploaded = document.getElementById('fileUploaded') as HTMLInputElement;
    fileUploaded.value = '';

    this.files.forEach(file => {
      this.uploadFile(file);
      console.log(file)
    });
  }
  //Upload ,add & remove files
  /** Link text */
  @Input() text = 'Upload';
  /** Name used in form which will be sent in HTTP request. */
  @Input() param = 'file';
  /** Target URL for file uploading. */
  @Input() target = 'https://file.io';
  /** File extension that accepted, same as 'accept' of <input type="file" />.
      By the default, it's set to 'image/*'. */
  @Input() accept = 'image/*';
  /** Allow you to add handler after its completion. Bubble up response text from remote. */
  @Output() complete = new EventEmitter<string>();
  folder: any[] = [];
  public files: Array<FileUploadModel> = [];

  public uploadFile(file: FileUploadModel) {
    console.log(file)
    const f = new FormData();
    f.append(this.param, file.data);

    const req = new HttpRequest('POST', this.target, f, {
      reportProgress: true
    });

    file.inProgress = true;
    file.sub = this._http.request(req).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      tap(message => { }),
      last(),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        file.canRetry = true;
        return of(`${file.data.name} upload failed.`);
      })
    ).subscribe(
      (event: any) => {
        if (typeof (event) === 'object') {
          const type = file.data.name.split('.').pop();
          this.folder.push(Object.assign(file.data, { fileType: type }));
          const finelLenght = this.folder.length;
          if (finelLenght > 0) {

            this.finalFile[0] = this.folder[finelLenght - 1];
            console.log(this.finalFile[0])
            this.addDocumentDetail.DocumentFile.DocumentFileName = this.finalFile[0].name;
            this.addDocumentDetail.fileType = type;
            console.log(this.finalFile[0])
            const reader = new FileReader();
            reader.onload = this._documentLogo.bind(this);
            reader.readAsBinaryString(this.finalFile[0]);
            console.log(reader)
          } else {
            this.finalFile[0] = this.folder;
          }
          this.removeFileFromArray(file);
          this.complete.emit(event.body);
        }
      }
    );
  }
  _documentLogo(readerEvt: any) {
    const binaryString = readerEvt.target.result;
    const base64textString = btoa(binaryString);
    this.addDocumentDetail.DocumentFile.DocumentPath = base64textString;
    if (base64textString) {
      this.Imageshow = false;
    }
  }

  public removeFileFromArray(file: FileUploadModel) {
    const index = this.files.indexOf(file);
    if (index > -1) {
      this.files.splice(index, 1);
    }
  }
  retryFile(file: FileUploadModel) {
    this.uploadFile(file);
    file.canRetry = false;
  }
  deleteFile(i) {
    this.finalFile.splice(i, 1);
  }
}
export class FileUploadModel {
  data: File;
  state: string;
  inProgress: boolean;
  progress: number;
  canRetry: boolean;
  canCancel: boolean;
  sub?: Subscription;
}
