
export const makeArrayList = async (data) => {
    return data.ItemList
    }

    export const makeArrayListchange = async (data, endpoint) => {
        console.log(data);
        console.log(endpoint);
        switch (endpoint) {
            case 'WeeklyOffList':
                return data.WeeklyOffList
                break;
            case 'CompliancesCategoryList':
                return data.ItemList
                break;
            case 'DocumentPrintTypeList':
                return data.DocumentPrintTypeList
                break;
            case 'DocumentPrintCategorieList':
                return data.DocumentPrintCategorieList

                break;
            case 'WorkingScheduleList':
                return data.WorkingScheduleList
                break;
            case 'HolidayList':
                return data
                break;
            case 'ServiceTaskList':
                return data.ServiceTaskList
                break;
            case 'SkillCategoryList':
                return data.SkillCategoryList
                break;
            case 'EmployeeCategoryList':
                return data.EmployeeCategoryList
                break;
            case 'LevelList':
                return data.LevelList;
                break;
            case 'CadreList':
                return data.CadreList
                break;
            case 'BackgroundInfolist':
                return data.BackgroundInfoList
                break;
            case 'KeySkillList':
                return data.KeySkillList
                break;
            case 'BusinessProcessList':
                return data.BusinessProcessList
                break;
            case 'IndustryTypeList':
                return data.IndustryTypeList
                break;
            case 'ReferenceModeList':
                return data.ReferenceModeList
                break;
            case 'CustomerSegmentList':
                return data.CustomerSegmentList
                break;
            case 'QualificationList':
                return data.QualificationList
                break;
            case 'KeyFeatureList':
                return data
                break;
            case 'ProjectKeyFeatureList':
                return data.ProjectKeyFeatureList
                break;
            case 'ServiceKeyFeatureList':
                return data.ServiceKeyFeatureList
                break;
            case 'OrganisationTypeList':
                return data.OrganisationTypeList
                break;
            case 'ProjectCategoryList':
                return data.ProjectCategoryList
                break;
            case 'ProjectActivityList':
                return data.ProjectActivityList
                break;
            case 'ProjectTypeList':
                return data.ProjectTypeList
                break;
            case 'ProjectSubActivityList':
                return data.ProjectSubActivityList
                break;
            case 'ServiceCategoryList':
                return data.ServiceCategoryList
                break;
            case 'ServiceSubActivityList':
                return data.ServiceSubActivityList
                break;
            case 'ServiceTypeList':
                return data.ServiceTypeList
                break;
            case 'NumberSystemList':
                return data.NumberSystemList
                break;
            case 'GenericTypeList':
                return data.GenericTypeList
                break;
            case 'GenericCategoryList':
                return data.GenericCategoryList
                break;
                break;
            case 'CostCenterSBHList':
                return data.CostCenterSBHList
                break;
            case 'CostCenterList':
                return data.CostCenterList
                break;
            case 'NumberSystemList':
                return data.NumberSystemList
                break;
            case 'AssetItemList':
                return data.AssetList
                break;
            case 'TaxList':
                return data.TaxList
                break;
            case 'DurationUnitList':
                return data.DurationUnitList
                break;
            case 'CategoryList':
                return data.CategoryList
                break;
            case 'StatusList':
                return data.StatusList
                break;
            case 'CurrencyList':
                return data.CurrencyList
                break;
            case 'OtherActivityList':
                return data.ActivityList
                break;
            case 'BankList':
                return data.BankList
            case 'ResignationReasonList':


                return data.ResignationReasonList
                break;
            case 'TerminationReasonList':
                return data.TerminationReasonList
                break;
            case 'YearList':
                return data.YearList
                break;
            case 'OccupationList':
                return data.OccupationList
                break;
            case 'BankAccountTypeList':
                return data.BankAccountTypeList
                break;
            case 'DocumentTypeList':
                return data.DocumentTypeList
                break;
            case 'DocumentCategoryList':
                return data.DocumentCategoryList
                break;
            case 'UnitList':
                return data.UnitList
                break;
            case 'CityAreaList':
                return data.CityAreaList
                break;
            case 'CityList':
                return data.CityList
                break;
            case 'StateList':
                return data.StateList
                break;
            case 'CountryList':
                return data.CountryList
                break;
            case 'ReasonList':
                return data.ReasonList
                break;
            case 'LabelList':
                return data.LabelList
                break;
            case 'BufferPercentageList':
                return data.BufferPercentageList
                break;
            case 'EstimationBufferList':
                return data.EstimationBufferList
                break;
            case 'FinancialYearList':
                return data.FinancialYearList
                break;
            case 'CostCenterTypeList':
                return data.CostCenterTypeList
                break;
            case 'CostCenterNameList':
                return data.CostCenterNameList
                break;
            case 'PaymentTypeList':
                return data.PaymentTypeList
                break;
            case 'ProfitCenterList':
                return data.ProfitCenterList
                break;
            case 'TaxTypeList':
                return data.TaxTypeList
                break;
            case 'DepartmentList':
                return data.DepartmentList
                break;
            case 'ShiftList':
                return data.ShiftList
                break;
            case 'UserRoleList':
                return data.UserRoleList
                break;
            case 'DesignationList':
                return data.DesignationList
                break;
            case 'PaymentModeList':
                return data.PaymentModeList
                break;
            case 'NationalityList':
                return data.NationalityList
                break;
            case 'EmployeeList':
                return data.EmployeeList
                break;
            case 'EmployeeListNew':
                return data.EmployeeList
                break;
            case 'ProductCategoryList':
                return data.ProductCategoryList
                break;
            case 'ProductTypeList':
                return data.ProductTypeList
                break;
            case 'ServiceActivityList':
                return data.ServiceActivityList
                break;
            case 'ProjectTaskList':
                return data.ProjectTaskList
                break;
            case 'ModeOfPOList':
                return data.List
                break;

            case 'PaymentTermList':
                return data.PaymentTermList
                break;
            case 'GeneralTermList':
                return data
                break;
            case 'InvoicePaymentTermList':
                return data
                break;
            case 'AssetList':
                return data.AssetList
                break;
            case 'HSNList':
                return data.HSNList
                break;
            case 'ExistEmployeeList':
                return data.EmployeeList
                break;
            case 'EntitlementCodeList':
                return data.ItemList
                break;






        }
    }
