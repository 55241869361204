<div class="dialog-content-wrapper admin_modal prsn_dialog">
  <button class="closeModal" (click)="deviceclose()"><img src="assets/icons/close_mpdal_icon.svg" alt=""
      *ngIf="action != 'ChooseEmployeeType'"></button>
  <div class="modal_section">
    <h2>{{modalTitle}}</h2>

    <div *ngIf="action == 'refundcredit'" class="refundcredit">
      <div>
        <form class="row" autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12 d-flex">

            <div class=" col-sm-6 paymentmode">
              <h5 class="label-title ">Mode of Payment</h5>
              <div class="d-matlab">
                <mat-form-field appearance="outline">
                  <mat-label>Select</mat-label>
                  <mat-select class="form-control" selected>
                    <mat-option value="1">Cash</mat-option>
                    <mat-option value="2">Cheque</mat-option>
                    <mat-option value="3">Demand Draft </mat-option>
                    <mat-option value="4">Bank Transfer</mat-option>
                    <mat-option value="5">UPI</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>


          </div>
          <div class=" col-sm-12 d-flex">

            <div class="col-sm-6 pl-0 mb-4">
              <h5 class="label-title">Amount</h5>
              <mat-form-field appearance="outline" class="mat-field-rightbx mb-3">
                <span matPrefix class="inr">INR</span>
                <input matInput numbersOnly required type="number" [(ngModel)]="RefundCredit.Amount" name="Amount">
              </mat-form-field>
            </div>
            <div class=" col-sm-6 p-0 mb-4">
              <h5 class="label-title">Balance</h5>
              <div class="requestedDiv">
                <div class="colorbox colorbox3" style="margin-top: 3px;">
                  <p>
                    <span>INR {{RefundCredit.Amount}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <mat-form-field appearance="outline" class="h_auto">
              <mat-label>Description</mat-label>
              <textarea matInput type="text" class="form-control" required></textarea>
            </mat-form-field>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid" (click)="saveTer()">
              SUBMIT FOR APPROVAL
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="bx-narjus" *ngIf="action == 'creditNote'">
      <h4 class="text-primary mb-2">Narration</h4>
      <p class="pb-2">3 Email@900/-</p>
      <h4 class="text-primary mb-2">Justification</h4>
      <p>For reconciliation in next year</p>
    </div>
    <div class="bx-narjus" *ngIf="action == 'refundDes'">
      <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ullam, illum! Aut minima, sunt esse explicabo
        earum reiciendis, dolore ad necessitatibus nostrum, quasi sit voluptatum iste vel. Vel et laboriosam
        voluptate?</p>
    </div>
    <!-- Bank Details -->
    <div *ngIf="action == 'AddBankDetail' || action == 'EditBankDetail' " class="files_add">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div fxLayout="row wrap">
          <div class="flex-item" fxFlex="0 1 100">
            <mat-form-field appearance="outline">
              <mat-label>Beneficiary Account Name</mat-label>
              <input matInput required [(ngModel)]="BankDetail.BeneficiaryAccountName" #BeneficiaryAccountName="ngModel"
                name="BeneficiaryAccountName">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Bank Name</mat-label>
              <mat-select required [compareWith]="compareFn" [(ngModel)]="BankDetail.Bank" name="Bank" #Bank="ngModel">
                <fuse-mat-select-search name="search" [(ngModel)]="SearchBank">
                </fuse-mat-select-search>
                <mat-option [value]="banks" *ngFor="let banks of modalData?.data?.bankList | filter: SearchBank">
                  {{banks?.BankName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Branch</mat-label>
              <input matInput required [(ngModel)]="BankDetail.Branch" #Branch="ngModel" name="Branch">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Bank Address</mat-label>
              <input matInput required [(ngModel)]="BankDetail.BankAddress" #BankAddress="ngModel" name="BankAddress">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Bank Account No.</mat-label>
              <input matInput required [(ngModel)]="BankDetail.BankAccountNo" #BankAccountNo="ngModel"
                name="BankAccountNo">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Type of Account</mat-label>
              <mat-select required [compareWith]="compareFn" [(ngModel)]="BankDetail.BankAccountType"
                #BankAccountTypeId="ngModel" name="BankAccountTypeId">
                <fuse-mat-select-search name="search" [(ngModel)]="searchType">
                </fuse-mat-select-search>
                <mat-option [value]="accountType"
                  *ngFor="let accountType of modalData?.data?.accountType | filter: searchType">
                  {{accountType.BankAccountTypeName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>IFSC Code</mat-label>
              <input matInput required [(ngModel)]="BankDetail.IFSCCode" #IFSCCode="ngModel" name="IFSCCode">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Swift Code</mat-label>
              <input matInput required [(ngModel)]="BankDetail.SwiftCode" #SwiftCode="ngModel" name="SwiftCode">
            </mat-form-field>
            <div class="flex-item qrcode-upload" fxLayout="column">
              <label>Upload QR Code</label>
              <div class="attach_file" style="margin-top:.25em">
                <input type="file" (change)="fileUpload($event, BankDetail.QRCode)" class="upload" #myFileInputQR>
                <mat-icon style="margin: 52px"
                  *ngIf="!BankDetail?.QRCode?.Preview && !BankDetail?.QRCode?.DocumentPath">
                  add</mat-icon>
                <mat-icon class="cancelicon" *ngIf="BankDetail?.QRCode?.Preview || BankDetail?.QRCode?.DocumentPath"
                  (click)="fileUploadRemove(BankDetail?.QRCode)">
                  cancel</mat-icon>
                <img *ngIf="BankDetail?.QRCode?.Preview || BankDetail?.QRCode?.DocumentPath"
                  [src]="BankDetail?.QRCode?.Preview ? BankDetail?.QRCode?.Preview : BankDetail?.QRCode?.DocumentPath"
                  alt="" class="mr-0" style="height: 100%">
              </div>
              <mat-error>{{fileSizeError}}</mat-error>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <button mat-flat-button class="blue_btn mt-0" [disabled]="myForm.form.invalid"
            (click)="dialogRef.close(BankDetail)">{{action == 'AddBankDetail' ? 'Submit' :
            'Update'}}</button>
        </div>
      </form>
    </div>

    <!-- Exit Employee -->
    <div *ngIf="action == 'ExitEmployee' || action == 'GetEmployeeList'">
      <div class="form_layout all_depart">
        <div fxLayout="row wrap">
          <div class="flex-item" fxFlex="0 1 100">
            <!-- <mat-form-field appearance="outline">
              <mat-label>Department</mat-label>
              <mat-select [(value)]="selected" (selectionChange)="Dep($event)">
                <mat-option value="0">All</mat-option>
                <mat-option [value]="dep" *ngFor="let dep of campainDepartment">
                  {{dep.DepartmentName }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
            <mat-form-field appearance="outline">
              <mat-label>Cost Center</mat-label>
              <mat-select [(value)]="selected" (selectionChange)="exEmployee($event)" [compareWith]="compareFn">
                <mat-option value="0">All</mat-option>
                <mat-option [value]="dep" *ngFor="let dep of costCenterList">
                  {{dep?.CostCenterName?.CostCenterMasterName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Search Employee</mat-label>
              <input matInput [(ngModel)]="searchText" autocomplete="off">
              <span matSuffix>
                <mat-icon class="search_icon">search</mat-icon>
              </span>
            </mat-form-field>
          </div>
          <div class="all_depart_innner">
            <div class="flex-item" fxFlex="0 1 100" *ngIf="campainEmployee?.length > 0">
              <div class="list_div">
                <div class="alpha_list">
                  <!-- <h4>A</h4> -->
                  <div class="name_list" *ngFor="let user of exEmployeeList | filter:searchText">
                    <div class="about_employ">
                      <div class="user_img">
                        <img [src]="user?.EmployeeImagePath" style="width: 47px; height: 47px; border-radius: 50%;" onerror="this.src='assets/images/user.png'">
                      </div>
                      <div class="user_name">
                        <h5>{{user.EmployeeName}}</h5>
                        <h6>{{user.Designation}}</h6>
                      </div>
                    </div>
                    <div class="user_check">
                      <!-- [checked]="userIds.indexOf(user.UserId) > -1" -->
                      <mat-radio-button [value]="user.EmployeeId" (change)="selectUser($event, user, user.EmployeeId)">
                      </mat-radio-button>
                      <!-- <ng-container *ngIf="type; else showChecked">
                                               </ng-container> -->
                      <!-- <ng-template #showChecked>
                                                 <mat-radio-button class="custom-frame" (change)="selectUser($event, user, user.EmployeeId)">
                                                 </mat-radio-button>
                                               </ng-template> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-item" fxFlex="0 1 100">
            <button mat-flat-button class="blue_btn" [disabled]="selectedUser?.length == 0"
              (click)="addUser(action)">Exit</button>
            <!-- [disabled]="selectedUser?.length == 0" -->
            <!-- (click)="addUser()" -->
          </div>
        </div>
      </div>
    </div>

    <!-- assign employee -->
    <div *ngIf="action == 'KnowledgeTransfer' || action == 'assignCampaign'">
      <div class="form_layout all_depart">
        <div fxLayout="row wrap">
          <div class="flex-item" fxFlex="0 1 100">
            <!-- <mat-form-field appearance="outline">
              <mat-label>Department</mat-label>
              <mat-select [(value)]="selected" (selectionChange)="Dep($event)">
                <mat-option value="0">All</mat-option>
                <mat-option [value]="dep" *ngFor="let dep of campainDepartment">
                  {{dep.DepartmentName }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->




            <mat-form-field appearance="outline">
              <mat-label>Cost Center</mat-label>
              <mat-select [(value)]="selected" (selectionChange)="Dep($event)" [compareWith]="compareFn">
                <mat-option value="0">All</mat-option>
                <mat-option [value]="dep" *ngFor="let dep of campainempDepartment?.CostCenterList">
                  {{dep?.CostCenterName?.CostCenterMasterName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Search Employee</mat-label>
              <input matInput [(ngModel)]="searchText" autocomplete="off">
              <span matSuffix>
                <mat-icon class="search_icon">search</mat-icon>
              </span>
            </mat-form-field>
          </div>
          <div class="all_depart_innner">
            <div class="flex-item" fxFlex="0 1 100" *ngIf="campainEmployee?.length > 0">
              <div class="list_div">
                <div class="alpha_list">
                  <!-- <h4>A</h4> -->
                  <div class="name_list" *ngFor="let user of exEmployeeList | filter:searchText">
                    <div class="about_employ">
                      <div class="user_img">
                        <img [src]="user.EmployeeImagePath" onerror="this.src='assets/images/user.png'" style="width: 47px; height: 47px;
                                          border-radius: 50%;">
                      </div>
                      <div class="user_name">
                        <h5>{{user.EmployeeName}}</h5>
                        <h6>{{user.Designation}}</h6>
                      </div>
                    </div>
                    <div class="user_check">
                      <mat-radio-button [value]="user.EmployeeId" (change)="selectUser($event, user, user.EmployeeId)">
                      </mat-radio-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-item" fxFlex="0 1 100">
            <button mat-flat-button class="blue_btn" (click)="addUser(action)">
              {{action == 'KnowledgeTransfer' ? 'Add' : 'Assign'}}</button>
            <!-- [disabled]="selectedUser?.length == 0" -->
            <!-- (click)="addUser()" -->
          </div>
        </div>
      </div>
    </div>

    <!-- Map Resource -->
    <div *ngIf="action == 'assignTask'">
      <div class="form_layout all_depart">
        <div fxLayout="row wrap">
          <div class="flex-item" fxFlex="0 1 100">
            <mat-form-field appearance="outline">
              <mat-label>Cost Center</mat-label>
              <mat-select [(value)]="selected" (selectionChange)="staffingList($event)" [compareWith]="compareFn">
                <mat-option value="0">All</mat-option>
                <mat-option [value]="dep" *ngFor="let dep of costCenterList">
                  {{dep?.CostCenterName?.CostCenterMasterName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Search Employee</mat-label>
              <input matInput [(ngModel)]="searchText" autocomplete="off">
              <span matSuffix>
                <mat-icon class="search_icon">search</mat-icon>
              </span>
            </mat-form-field>
          </div>
          <div class="all_depart_innner">
            <div class="flex-item" fxFlex="0 1 100" *ngIf="mapResEmployee?.length > 0">
              <div class="list_div">
                <div class="alpha_list">
                  <!-- <h4>A</h4> -->
                  <div class="name_list" *ngFor="let user of mapResEmployee | filter:searchText">
                    <div class="about_employ">
                      <div class="user_img">
                        <img [src]="user.EmployeeImagePath" style="width: 47px; height: 47px;
                                          border-radius: 50%;">
                      </div>
                      <div class="user_name">
                        <h5>{{user.EmployeeName}}</h5>
                        <h6>{{user.Designation?.DesignationName}}</h6>
                        <h6>{{user?.EmployeeCode}}</h6>
                      </div>
                    </div>
                    <div class="user_check">
                      <mat-radio-button [value]="user.EmployeeId" (change)="selectUser($event, user, user.EmployeeId)"
                        [checked]="user.EmployeeName == data.resoName">
                      </mat-radio-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-item" fxFlex="0 1 100">
            <button mat-flat-button class="blue_btn" [disabled]="isdisabled" (click)="addUser(action)">
              {{action == 'KnowledgeTransfer' ? 'Add' : 'Assign'}}</button>
            <!-- [disabled]="selectedUser?.length == 0" -->
            <!-- (click)="addUser()" -->
          </div>
        </div>
      </div>
    </div>
    <!-- Enter Remarks -->
    <div *ngIf="action == 'enterRemarks'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class="col-md-12 mt-4">
          <div class="alpha_list">
            <div class="name_list">
              <div class="about_employ">
                <div class="user_img">
                  <img [src]="modalData?.data?.ImagePath" alt="" style="width: 47px; height: 47px; border-radius: 50%;">
                </div>
                <div class="user_name">
                  <h5>{{modalData?.data?.EmployeeName}}</h5>
                  <h6>{{modalData?.data?.Designation}}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=" col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label>Enter Remarks</mat-label>
            <textarea matInput class="form-control" [(ngModel)]="saveRemarkInput.Remark" name="Remark" required
              style="height: 100px;"></textarea>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <button mat-flat-button class="blue_btn mt-0" [disabled]="myForm.form.invalid"
            (click)="deleteEmployee()">Submit</button>
        </div>
      </form>
    </div>
    <!-- End Remarks -->

    <!-- Enter payment -->
    <div *ngIf="action == 'CompliancePayment'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class="col-md-12 comdatepiciocn">
          <mat-form-field appearance="outline">
            <mat-label>Payment Date</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="savePaymentInput.paymentDate"
              name="paymentDate" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field appearance="outline">
            <span matPrefix class="price-prefix">INR</span>
            <mat-label>Amount</mat-label>
            <input type="number" matInput [(ngModel)]="savePaymentInput.Amount" name="Amount" required>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field appearance="outline">
            <span matPrefix class="price-prefix">INR</span>
            <mat-label>Interest</mat-label>
            <input type="number" matInput [(ngModel)]="savePaymentInput.Interest" name="Interest">
          </mat-form-field>
        </div>
        <div class="col-sm-12">
          <mat-form-field appearance="outline">
            <span matPrefix class="price-prefix">INR</span>
            <mat-label>Penalty</mat-label>
            <!-- <input type="number" matInput [(ngModel)]="savePaymentInput.Panelty" name="Penalty" [required]="InputData?.IsPanelty"> -->
            <input type="number" matInput [(ngModel)]="savePaymentInput.Panelty" name="Penalty">
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field appearance="outline" class="h_auto">
            <mat-label>Enter Remarks</mat-label>
            <textarea matInput class="form-control" [(ngModel)]="savePaymentInput.Remark" name="Remark" required></textarea>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <button mat-flat-button class="blue_btn mt-0" [disabled]="myForm.form.invalid"
          (click)="dialogRef.close(savePaymentInput)">Submit</button>
        </div>
      </form>
    </div>
     <!-- View payment -->
     <div *ngIf="action == 'CompliancePaymentView'" class="epica-sidebar">
    <form class="row taskdetail-dv" autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
         <div class="col-md-6 dtl_text" style="margin-bottom: 15px !important;">
          <label class="mb-0">Payment Date</label> <p class="mb-0">{{InputData.PaymentDueDate | date}}</p>
        </div>
        <div class="col-md-6 dtl_text" style="margin-bottom: 15px !important;">
          <label class="mb-0">Amount</label> <p class="mb-0">INR {{InputData.TotalAmount}} </p>
        </div>

        <div class="col-md-6 dtl_text" style="margin-bottom: 15px !important;">
          <label class="mb-0">Interest</label> <p class="mb-0">INR {{InputData.InterestAmount}}</p>
        </div>
        <div class="col-md-6 dtl_text" style="margin-bottom: 15px !important;">
          <label class="mb-0">Penalty</label> <p class="mb-0">INR {{InputData.PenaltyAmount}} </p>
        </div>

        <div class="col-md-12 dtl_text" style="margin-bottom: 15px !important;">
          <label class="mb-0">Remark</label> <p class="mb-0">{{InputData.Remark}}</p>
        </div>
      </form>
    </div>

     <!-- Enter File -->
     <div *ngIf="action == 'ComplianceFile'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class="col-md-12 comdatepiciocn">
          <mat-form-field appearance="outline">
            <mat-label>Paid on</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="savePaymentInput.paidDate"
              name="paidDate" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-12 comdatepiciocn">
          <mat-form-field appearance="outline">
            <mat-label>Filed on</mat-label>
            <input matInput [matDatepicker]="filedOn" [(ngModel)]="savePaymentInput.filedDate"
              name="filedDate" required>
            <mat-datepicker-toggle matSuffix [for]="filedOn"></mat-datepicker-toggle>
            <mat-datepicker #filedOn></mat-datepicker>
          </mat-form-field>
        </div>

        <div class=" col-sm-12">
          <mat-form-field appearance="outline" class="h_auto">
            <mat-label>Remarks</mat-label>
            <textarea matInput class="form-control" [(ngModel)]="savePaymentInput.Remark" name="Remark" required
             ></textarea>
          </mat-form-field>
        </div>

        <div class="col-md-12">
        <div class="attach_file" style="margin-top:.25em">
          <img src="/assets/icons/attach_icon.svg" alt=""> Upload Attachment
          <input type="file" (change)="fileUpload($event, savePaymentInput.Attachment)" class="upload"
            #myFileInputQR>
        </div>

        <div *ngIf="savePaymentInput.Attachment?.DocumentFileName" class="d-flex mt-3 justify-content-between">
          <span>{{savePaymentInput.Attachment?.DocumentFileName}}</span>
          <mat-icon class="cancelicon cursor-pointer"
            (click)="fileUploadRemove(savePaymentInput.Attachment)">
            cancel</mat-icon>
        </div>

        </div>

        <div class="col-md-12 mt-4">
          <button mat-flat-button class="blue_btn " [disabled]="myForm.form.invalid"
          (click)="dialogRef.close(savePaymentInput)">Submit</button>
        </div>

      </form>
    </div>
     <!-- View File -->
     <div *ngIf="action == 'ComplianceFileView'" class="epica-sidebar">
      <form class="row taskdetail-dv" autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
         <div class="col-md-6 dtl_text" style="margin-bottom: 15px !important;">
          <label class="mb-0">Paid On</label> <p class="mb-0">{{InputData.PaidOn | date}} </p>
        </div>
        <div class="col-md-6 dtl_text" style="margin-bottom: 15px !important;">
          <label class="mb-0">Filed On</label> <p class="mb-0">{{InputData.FiledOn | date}} </p>
        </div>

        <div class="col-md-12 dtl_text" style="margin-bottom: 15px !important;">
          <label class="mb-0">Remark</label> <p class="mb-0">{{InputData.Remark}} </p>
        </div>

          <div *ngIf="InputData.Document" class="col-md-12 dtl_text" style="margin-bottom: 15px !important;">
            <label class="mb-0">Attachment</label>
            <a
              (click)="downloadMyFileOther(InputData.Document)">{{InputData?.Document?.DocumentFileName}}</a>
          </div>


      </form>
    </div>

     <!-- Download slip -->
     <div *ngIf="action == 'SlipDownload'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class="col-md-12 comdatepiciocn">
          <mat-form-field appearance="outline">
            <mat-label>Select Date</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="savePaymentInput.paymentDate"
              name="paymentDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <button mat-flat-button class=" mt-0" color="primary" [disabled]="myForm.form.invalid">Download Pay Slip</button>
        </div>
      </form>
    </div>

    <!-- Text Remarks -->
    <div *ngIf="action == 'textRemarks'">
      <div class="row">
        <div class="col-md-12 mt-4">
          <p>{{modalData?.data}}</p>
        </div>
        <!-- <div class="col-md-12 mt-5">
          <button mat-flat-button class="blue_btn mt-0" mat-dialog-close>Close</button>
        </div> -->
      </div>
    </div>
    <!-- End Remarks -->

    <div *ngIf="action == 'AddStatus' || action == 'EditStatus'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Business Process Name</mat-label>
              <mat-select class="form-control" [(ngModel)]="saveStatusInput.BusinessProcess" name="BusinessProcessName"
                [compareWith]="compareFn">
                <fuse-mat-select-search name="search" [(ngModel)]="tree30">
                </fuse-mat-select-search>
                <mat-option *ngFor="let d_list of BusinessProcessDropList | filter : tree30" [value]="d_list" required>
                  {{d_list.BusinessProcessName}}</mat-option>
              </mat-select>
            </mat-form-field>
            <!-- <input matInput type="text" class="form-control"
                [(ngModel)]="saveStatusInput.BusinessProcess.BusinessProcessName" name="Business Process Name" required>
            </mat-form-field> -->
          </div>
        </div>
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Status Name</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="saveStatusInput.StatusName"
                name="Status Name" required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid" (click)="saveStatus()">
            <span *ngIf="action == 'AddStatus'">Save</span>
            <span *ngIf="action == 'EditStatus'">Update</span>
          </button>
        </div>
      </form>
    </div>

    <div *ngIf="action == 'ChooseEmployeeType' || action == 'ChooseEmployeeType2'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">

        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Employee Type </mat-label>
              <mat-select class="form-control" [(ngModel)]="SaveEmployeeType.EmployeeOfficialDetail.EmployeeCategory"
                name="EmployeeCategory" [compareWith]="compareFn" required>
                <mat-option *ngFor="let empCat of employeeCategorys" [value]="empCat">
                  {{empCat.EmployeeCategoryName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <ng-container *ngIf="action == 'ChooseEmployeeType'">
          <div class="col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>First Name</mat-label>
              <input matInput type="text" [(ngModel)]="SaveEmployeeType.EmployeeOfficialDetail.FirstName"
                name="FirstName" pattern="^(\s)*[A-Za-z]+((\s)?((\'|\-|\.)?([A-Za-z])+))*(\s)*$" required>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>Last Name</mat-label>
              <input matInput type="text" [(ngModel)]="SaveEmployeeType.EmployeeOfficialDetail.LastName" name="LastName"
                pattern="[A-Za-z]+" required>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field appearance="outline">
              <mat-label>Email ID</mat-label>
              <input matInput type="text" [(ngModel)]="SaveEmployeeType.EmployeeOfficialDetail.PersonalEmailId"
                name="PersonalEmailId" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required>
            </mat-form-field>
          </div>
        </ng-container>

        <div class=" col-sm-12">
          <button type="submit" mat-flat-button color="primary" (click)="employeetypeid()"
            [disabled]="myForm.form.invalid">
            <span *ngIf="action == 'ChooseEmployeeType'">Save & Genrate Link</span>

            <span *ngIf="action == 'ChooseEmployeeType2'">Update</span>

            <!-- <span *ngIf="action == 'EditStatus'">Update</span> -->
          </button>
          <button class="back_row" mat-button routerLink="/app/core/common/employee" style="float: right;"
            (click)="dialogRef.close(SaveClient.Client)">
            <mat-icon class="back_arrow">keyboard_arrow_left</mat-icon> Back to List
          </button>
        </div>
      </form>
    </div>
    <div *ngIf="action == 'GetClientAdd' || action == 'GetClientEdit'">
      <form class="row" autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Client Name</mat-label>
              <mat-select class="form-control" [(ngModel)]="SaveClient.Client" name="Client" required>
                <fuse-mat-select-search name="search" [(ngModel)]="ClientSearch">
                </fuse-mat-select-search>
                <mat-option *ngFor="let empCat of modalData.data | filter: ClientSearch" [value]="empCat.ClientId">
                  {{empCat.ClientName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button *ngIf="action == 'GetClientAdd'" type="submit" mat-flat-button color="primary"
            (click)="dialogRef.close(SaveClient.Client)" [disabled]="myForm.form.invalid">
            Save
          </button>
          <button *ngIf="action == 'GetClientEdit'" type="submit" mat-flat-button color="primary"
            (click)="dialogRef.close(SaveClient.Client)" [disabled]="myForm.form.invalid">
            Update
          </button>
        </div>
      </form>
    </div>

    <div *ngIf="action == 'AddBusiness' || action == 'EditBusiness'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">

        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Business Process Name</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="saveBusinessInput.BusinessProcessName"
                name="Business Process Name" required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-12">
          <div class="d-matlab">
            <mat-label>Show on Lead</mat-label>
            <div style="margin: 5px 10px 20px 0;">
              <mat-radio-group aria-label="Select an option" class="form-control" [(ngModel)]="saveBusinessInput.IsLead"
                name="IsLead" required>
                <mat-radio-button [value]="true" style="margin-right: 15px;">Yes</mat-radio-button>
                <mat-radio-button [value]="false">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveBusiness()">
            <span *ngIf="action == 'AddBusiness'">Save</span>
            <span *ngIf="action == 'EditBusiness'">Update</span>
          </button>
        </div>
      </form>
    </div>

    <div *ngIf="action == 'AddNationality' || action == 'EditNationality'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Nationality</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="saveNationalityInput.NationalityName"
                name="NationalityName" required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveNational()">
            <span *ngIf="action == 'AddNationality'">Save</span>
            <span *ngIf="action == 'EditNationality'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- Nationality End -->
    <!-- Designation Start -->
    <div *ngIf="action == 'AddDesignation' || action == 'EditDesignation'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class="col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label>Designation Category</mat-label>
            <mat-select class="form-control" [(ngModel)]="saveDesignationInput.DesignationCategory"
              name="DesignationCategory" [compareWith]="compareFn">
              <fuse-mat-select-search name="search" [(ngModel)]="tree31">
              </fuse-mat-select-search>
              <mat-option *ngFor="let d_list of designationList | filter : tree31" [value]="d_list">
                {{d_list.Type}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Designation</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="saveDesignationInput.DesignationName"
                name="DesignationName" required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster(saveDesignationInput, baseCompanyCore + 'DesignationSave')">
            <span *ngIf="action == 'AddDesignation'">Save</span>
            <span *ngIf="action == 'EditDesignation'">Update</span>
          </button>
        </div>
      </form>
    </div>

    <!-- Designation End -->
    <div *ngIf="action == 'AddSkillSet' || action == 'EditSkillSet'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class="col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label>Key Technology</mat-label>
            <mat-select class="form-control" [(ngModel)]="SkillSetInput.SkillCategory" name="SkillCategoryS"
              [compareWith]="compareFn" required>
              <fuse-mat-select-search name="search" [(ngModel)]="tree32">
              </fuse-mat-select-search>
              <mat-option *ngFor="let d_list of SkillCategorylist | filter : tree32" [value]="d_list">
                {{d_list.SkillCategoryName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Key Skill</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="SkillSetInput.KeySkillName"
                name="KeySkillName" required>
            </mat-form-field>
          </div>
        </div>



        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveSkillSet()">
            <span *ngIf="action == 'AddSkillSet'">Save</span>
            <span *ngIf="action == 'EditSkillSet'">Update</span>
          </button>
        </div>
      </form>
    </div>


    <!-- Level Start -->
    <div *ngIf="action == 'AddLevel' || action == 'EditLevel'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Level</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="saveLevelInput.LevelName" name="LevelName"
                required>
            </mat-form-field>
          </div>
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Weightage</mat-label>
              <input matInput type="number" class="form-control" [(ngModel)]="saveLevelInput.Weightage" name="Weightage"
                required numbersOnly>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid" (click)="saveLevel()">
            <span *ngIf="action == 'AddLevel'">Save</span>
            <span *ngIf="action == 'EditLevel'">Update</span>
          </button>
        </div>
      </form>
    </div>

    <!-- Level End -->
    <!-- Cadre Start -->
    <div *ngIf="action == 'AddCadre' || action == 'EditCadre'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Cadre</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="saveCadreInput.CadreName" name="CadreName"
                required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid" (click)="saveCadres()">
            <span *ngIf="action == 'AddCadre'">Save</span>
            <span *ngIf="action == 'EditCadre'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- Cadre End -->

    <!-- Start background info -->
    <div *ngIf="action == 'AddBgInfo' || action == 'EditBgInfo'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Background Info</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="saveBgInfoInput.BackgroundInfoName"
                name="BackgroundInfoName" required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid" (click)="saveBgInfo()">
            <span *ngIf="action == 'AddBgInfo'">Save</span>
            <span *ngIf="action == 'EditBgInfo'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- End background info -->

    <!-- Key-technology Start -->
    <div *ngIf="action == 'AddSkillCategory' || action == 'EditSkillCategory'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">

        <div class="col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label>Department</mat-label>
            <mat-select class="form-control" [(ngModel)]="skillCategoryInput.Department" required name="Department"
              [compareWith]="compareFn">
              <fuse-mat-select-search name="search" [(ngModel)]="tree33">
              </fuse-mat-select-search>
              <mat-option *ngFor="let d_list of listOfDepartment | filter: tree33" [value]="d_list">
                {{d_list.DepartmentName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Skill</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="skillCategoryInput.SkillCategoryName"
                name="SkillCategoryName" required>
            </mat-form-field>
          </div>
        </div>

        <div class=" col-sm-12">
          <ul class="list">
            <li class="d-flex align-items-center" *ngFor="let list of skillCategoryInput.LevelList; let index = index">
              <span class="w118"> {{list.LevelName}}</span>
              <div class="wi10">
                <mat-form-field appearance="outline">
                  <mat-label>Per hour cost</mat-label>
                  <span class="inr">INR</span>
                  <input matInput type="number" name="Duration_+{{index}}" [(ngModel)]="list.Amount">
                </mat-form-field>
              </div>
            </li>
          </ul>
        </div>

        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveSkillCategory()">
            <span *ngIf="action == 'AddSkillCategory'">Save</span>
            <span *ngIf="action == 'EditSkillCategory'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- Key-technology End -->
    <!-- Product-Type Start -->
    <div *ngIf="action == 'AddProductType' || action == 'EditProductType'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class="col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label>ProductCategory</mat-label>
            <mat-select class="form-control" [(ngModel)]="productTypeInput.ProductCategory" name="ProductCategory"
              [compareWith]="compareFn" required>
              <fuse-mat-select-search name="search" [(ngModel)]="tree34">
              </fuse-mat-select-search>
              <mat-option *ngFor="let p_list of productListCategory | filter : tree34" [value]="p_list">
                {{p_list.ProductCategoryName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Product Type</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="productTypeInput.ProductTypeName"
                name="ProductTypeName" required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveProductType()">
            <span *ngIf="action == 'AddProductType'">Save</span>
            <span *ngIf="action == 'EditProductType'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- Product-Type End -->
    <!-- Product-Category Start -->
    <div *ngIf="action == 'AddProductCategory' || action == 'EditProductCategory'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Product Category</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="productCategoryInput.ProductCategoryName"
                name="ProductCategoryName" required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveProductCategory()">
            <span *ngIf="action == 'AddProductCategory'">Save</span>
            <span *ngIf="action == 'EditProductCategory'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- Product-Category End -->
    <!-- Financial year Start -->
    <div *ngIf="action == 'AddFinancialYear' || action == 'EditFinancialYear'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Financial Year</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="financialYearInput.FinancialYearName"
                name="ProductCategoryName" required>
            </mat-form-field>
          </div>
        </div>

        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Start Date</mat-label>
              <input matInput [matDatepicker]="LicenceStartDate" (focus)="LicenceStartDate.open()"
                [(ngModel)]="financialYearInput.StartDate" name="StartDate" required>
              <mat-datepicker-toggle matSuffix [for]="LicenceStartDate"></mat-datepicker-toggle>
              <mat-datepicker #LicenceStartDate></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>End Date</mat-label>
              <input matInput [matDatepicker]="LicenceEndDate" (focus)="LicenceEndDate.open()"
                [(ngModel)]="financialYearInput.EndDate" name="EndDate" required>
              <mat-datepicker-toggle matSuffix [for]="LicenceEndDate"></mat-datepicker-toggle>
              <mat-datepicker #LicenceEndDate></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster(financialYearInput, this.baseGlobalConfig + 'FinancialYearSave')">
            <span *ngIf="action == 'AddFinancialYear'">Save</span>
            <span *ngIf="action == 'EditFinancialYear'">Update</span>
          </button>
        </div>
      </form>
    </div>

    <div *ngIf="action == 'AddCompliances' || action == 'EditCompliances'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-radio-group [(ngModel)]="saveComplinceInput.ISGoverment" name="ISGoverment">
              <mat-radio-button [value]="true">Government</mat-radio-button>
              <mat-radio-button [value]="false">Non Government</mat-radio-button>
            </mat-radio-group>
            <mat-form-field appearance="outline">
              <mat-label>Category</mat-label>
              <input matInput type="text" [(ngModel)]="saveComplinceInput.ComplianceCategoryName"
                #ComplianceCategoryName="ngModel" name="ComplianceCategoryName" class="form-control" required>
            </mat-form-field>
          </div>
        </div>

        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster(saveComplinceInput, this.basecomplincesConfig + 'CompliancesCategorySave')">
            <span *ngIf="action == 'AddCompliances'">Save</span>
            <span *ngIf="action == 'EditCompliances'">Update</span>
          </button>
        </div>
      </form>
    </div>

    <div *ngIf="action == 'AddCityName' || action == 'CityNameEdit'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Select Country</mat-label>
                <mat-select (selectionChange)="SelectStateList($event)" [(ngModel)]="saveCityInput.CountryId"
                  name="Country">
                  <fuse-mat-select-search name="search" [(ngModel)]="countrySearch">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let Country of CountryList | filter: countrySearch" [value]="Country?.CountryId">
                    {{Country.CountryName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Select State</mat-label>
                <mat-select [(ngModel)]="saveCityInput.StateId" name="State">
                  <fuse-mat-select-search name="search" [(ngModel)]="stateSearch">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let State of StateList | filter: stateSearch" [value]="State?.StateId">
                    {{State.StateName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>City</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveCityInput.CityName" name="CityName"
                  required>
              </mat-form-field>
            </div>
          </div>
          <!-- <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>City Code</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveCityInput.CityCode" name="CityCode"
                  required>
              </mat-form-field>
            </div>
          </div> -->
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(saveCityInput, this.baseGlobalConfig + 'CitySave')">
              <span *ngIf="action == 'AddCityName'">Save</span>
              <span *ngIf="action == 'CityNameEdit'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- <div *ngIf="action == 'AddUserRole' || action == 'UserRoleEdit'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>User Role Name</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveUserRoleInput.UserRoleName"
                  name="EstimationBufferName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              *ngIf="action == 'AddUserRole'" (click)="saveUserRoles()" class="mb-20">
              <span>Save</span>
            </button>
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              *ngIf="action == 'UserRoleEdit'" (click)="updateUserRole()" class="mb-20">
              <span>Update</span>
            </button>
          </div>
        </form>
      </div>
    </div> -->
    <div *ngIf="action == 'AddCityAreaName' || action == 'CityAreaNameEdit'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Select Country</mat-label>
                <mat-select (selectionChange)="StateListfn()" [(ngModel)]="CityAreaInput.Country" name="Country"
                  [compareWith]="compareFn">
                  <fuse-mat-select-search name="search" [(ngModel)]="CountrySearch">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let Country of CountryList | filter: CountrySearch" [value]="Country">
                    {{Country.CountryName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Select State</mat-label>
                <mat-select [(ngModel)]="CityAreaInput.State" (selectionChange)="CityListfn()" name="State"
                  [compareWith]="compareFn">
                  <fuse-mat-select-search name="search" [(ngModel)]="StateSearch">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let State of StateList | filter: StateSearch" [value]="State">
                    {{State.StateName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Select City</mat-label>
                <mat-select [(ngModel)]="CityAreaInput.City" name="City" [compareWith]="compareFn">
                  <fuse-mat-select-search name="search" [(ngModel)]="CitySearch">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let City of CityList | filter: CitySearch" [value]="City">
                    {{City.CityName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>City Area Name</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="CityAreaInput.CityAreaName"
                  name="CityAreaName" required>
              </mat-form-field>
            </div>
          </div>
          <!-- <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>City Area Code</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="CityAreaInput.CityAreaCode"
                  name="CityAreaCode" required>
              </mat-form-field>
            </div>
          </div> -->
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveCityArea()">
              <span *ngIf="action == 'AddCityAreaName'">Save</span>
              <span *ngIf="action == 'CityAreaNameEdit'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="action == 'addNumberSystem' || action == 'editNumberSystem'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Number System Name</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="NumberSystemINput.NumberSystemName"
                  name="EstimationBufferName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              *ngIf="action == 'addNumberSystem'" (click)="saveNumberSystem()">
              <span>Save</span>
            </button>
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              *ngIf="action == 'editNumberSystem'" (click)="updateNumberSystem()">
              <span>Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="action == 'addGenericTypeName' || action == 'editGenericTypeName'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class="" class="d-matlab" *ngIf="action == 'addGenericTypeName'">
            <mat-form-field appearance="outline">
              <mat-label>Select Generic Category</mat-label>
              <mat-select [(ngModel)]="GenericTypeSaveInput.GenericCategory" name="GenericType">
                <mat-option *ngFor="let GenericType of GenericTypeDropDownListNew" [value]="GenericType">
                  {{GenericType.GenericTypeName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Generic Type Name</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="GenericTypeSaveInput.GenericTypeName"
                  name="EstimationBufferName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              *ngIf="action == 'addGenericTypeName'" (click)="saveGenericTypeSave()">
              <span>Save</span>
            </button>
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              *ngIf="action == 'editGenericTypeName'" (click)="updateGenericTypeSave()">
              <span>Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- Financial year End -->
    <!-- Tax type Start -->
    <div *ngIf="action == 'AddTexType' || action == 'EditTexType'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Taxation</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="taxTypeInput.TaxTypeName" name="TaxTypeName"
                required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster(taxTypeInput, this.baseGlobalConfig + 'TaxTypeSave')">
            <span *ngIf="action == 'AddTexType'">Save</span>
            <span *ngIf="action == 'EditTexType'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- Tax type End -->

    <!-- hsn sac -->
    <div *ngIf="action == 'AddHsnSac' || action == 'EditHsnSac'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Code No.</mat-label>
              <input matInput type="number" class="form-control" [(ngModel)]="hsnInput.HSNCode" name="HSNCode" required
                numbersOnly>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Percentage</mat-label>
              <input matInput type="number" class="form-control" [(ngModel)]="hsnInput.HSNPercent" name="HSNPercent"
                required numbersOnly>
              <span class="hsnsac-persign">%</span>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster(hsnInput, this.baseCompanyCore + 'HSNSave')">
            <span *ngIf="action == 'AddHsnSac'">Save</span>
            <span *ngIf="action == 'EditHsnSac'">Update</span>
          </button>
        </div>
      </form>
    </div>

    <!-- Tax Start -->
    <div *ngIf="action == 'AddTax' || action == 'EditTax'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">

        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Tax Percent</mat-label>
              <input matInput type="number" numbersOnly class="form-control" [(ngModel)]="saveTaxInput.TaxPercent"
                name="TaxPercent" required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>TaxName</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="saveTaxInput.TaxName" name="TaxName"
                required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid" (click)="saveTax()">
            <span *ngIf="action == 'AddTax'">Save</span>
            <span *ngIf="action == 'EditTax'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- Tax End -->

    <!-- AddGeneralTermStart -->
    <div *ngIf="action == 'AddGeneralTerm' || action == 'EditGeneralTerm'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>General Term</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="generalTermInput.GeneralTermName"
                name="GeneralTermName" required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveGeneralTerm()">
            <span *ngIf="action == 'AddGeneralTerm'">Save</span>
            <span *ngIf="action == 'EditGeneralTerm'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- AddGeneralTerme End -->

    <!-- AddGeneralTermStart -->
    <div *ngIf="action == 'AddInvoicePaymentTerm' || action == 'EditInvoicePaymentTerm'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Invoice Payment Term</mat-label>
              <input matInput type="text" class="form-control"
                [(ngModel)]="invoicePaymentTermInput.InvoicePaymentTermName" name="InvoicePaymentTermName" required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveInvoicePaymentTerm()">
            <span *ngIf="action == 'AddInvoicePaymentTerm'">Save</span>
            <span *ngIf="action == 'EditInvoicePaymentTerm'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- AddGeneralTerme End -->



    <!-- PaymentTerms  -->
    <div *ngIf="action == 'AddPaymentTerms' || action == 'EditPaymentTerms'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Payment Term</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="PaymentTermsInput.PaymentTermName"
                name="PaymentTermName" required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster(PaymentTermsInput, this.baseGlobalConfig + 'PaymentTermSave')">
            <span *ngIf="action == 'AddPaymentTerms'">Save</span>
            <span *ngIf="action == 'EditPaymentTerms'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- End PaymentTerms  -->

    <!-- Cost Center Name Start -->
    <div *ngIf="action == 'AddCostCenterName' || action == 'EditCostCenterName'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Cost Center</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="costCenterInput.CostCenterMasterName"
                name="CostCenterName" required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster( costCenterInput , baseCompanyCore + 'CostCenterNameSave')">
            <span *ngIf="action == 'AddCostCenterName'">Save</span>
            <span *ngIf="action == 'EditCostCenterName'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- Cost Center Name End -->
    <!-- Cost Center Type Start -->
    <div *ngIf="action == 'AddCostCenterType' || action == 'EditCostCenterType'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Cost Center Type</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="costCenterType.CostCenterTypeName"
                name="CostCenterTypeName" required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster( costCenterType , baseCompanyCore + 'CostCenterTypeSave')">
            <span *ngIf="action == 'AddCostCenterType'">Save</span>
            <span *ngIf="action == 'EditCostCenterType'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <div *ngIf="action == 'AddIndustryType' || action == 'EditIndustryType'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Industry Types</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="SaveIndustryType.IndustryTypeName"
                  name="IndustryType" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(SaveIndustryType, this.baseGlobalConfig + 'IndustryTypeSave')">
              <span *ngIf="action == 'AddIndustryType'">Save</span>
              <span *ngIf="action == 'EditIndustryType'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="action == 'AddEntitlementsCodes' || action == 'EditEntitlementsCodes'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Entitlements Code</mat-label>
                <input matInput type="text" [(ngModel)]="SaveEmployeeEntitlementInput.EntitlementCodeName"
                  name="EntitlementCodeName" class="form-control" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(SaveEmployeeEntitlementInput, this.baseEmployeeCoreConfg + 'EntitlementCodeSave')">
              <span *ngIf="action == 'AddEntitlementsCodes'">Save</span>
              <span *ngIf="action == 'EditEntitlementsCodes'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddQualificationType' || action == 'EditQualificationType'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Qualification Type</mat-label>
                <input matInput type="text" class="form-control"
                  [(ngModel)]="SaveQualificationType.QualificationTypeName" name="IndustryType" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveQualification()">
              <span *ngIf="action == 'AddQualificationType'">Save</span>
              <span *ngIf="action == 'EditQualificationType'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>


    <div *ngIf="action == 'ServiceAddKeyFeature' || action == 'ServiceEditKeyFeature'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Key Feature</mat-label>
                <input matInput type="text" class="form-control"
                  [(ngModel)]="ServiceKeyFeatureSave.ServiceKeyFeatureName" name="KeyFeatureName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="serviceKeyFeatureSave()">
              <span *ngIf="action == 'ServiceAddKeyFeature'">Save</span>
              <span *ngIf="action == 'ServiceEditKeyFeature'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>


    <div *ngIf="action == 'ProjectAddKeyFeature' || action == 'ProjectEditKeyFeature'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Key Feature</mat-label>
                <input matInput type="text" class="form-control"
                  [(ngModel)]="ProjectKeyFeatureSave.ProjectKeyFeatureName" name="ProjectKeyFeatureName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="projectKeyFeatureSave()">
              <span *ngIf="action == 'ProjectAddKeyFeature'">Save</span>
              <span *ngIf="action == 'ProjectEditKeyFeature'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>


    <div *ngIf="action == 'AddPaymentType' || action == 'EditPaymentType'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Payment Type</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="inputPaymentType.PaymentTypeName"
                  name="PaymentTypeName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Total Month</mat-label>
                <input matInput type="number" class="form-control" [(ngModel)]="inputPaymentType.TotalMonth"
                  name="TotalMonth" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(inputPaymentType, this.baseGlobalConfig + 'PaymentTypeSave')">
              <span *ngIf="action == 'AddPaymentType'">Save</span>
              <span *ngIf="action == 'EditPaymentType'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>


    <div *ngIf="action == 'AddCustomerSegment' || action == 'EditCustomerSegment'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Customer Segment</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="SaveCustmerSegment.CustomerSegmentName"
                  name="CustomerSegment" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveSegment()">
              <span *ngIf="action == 'AddCustomerSegment'">Save</span>
              <span *ngIf="action == 'EditCustomerSegment'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddReferenceMode' || action == 'EditReferenceMode'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Lead Reference Mode</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="SaveReferenceMode.ReferenceModeName"
                  name="ReferenceMode" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveReference()">
              <span *ngIf="action == 'AddReferenceMode'">Save</span>
              <span *ngIf="action == 'EditReferenceMode'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'DeleteAction'">
      <div>
        <form class="row " autocomplete="off" #myForm="ngForm" novalidate autocomplete="off">
          <div class=" col-sm-6">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="dialogRef.close(true)">
              <span>Yes</span>
            </button>
          </div>
          <div class=" col-sm-6">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="dialogRef.close(false)">
              <span>No</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddOrganizationType' || action == 'EditOrganizationType'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Organization Type</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="SaveOrganition.OrganisationTypeName"
                  name="OrganizationType" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(SaveOrganition, this.baseGlobalConfig + 'OrganisationTypeSave')">
              <span *ngIf="action == 'AddOrganizationType'">Save</span>
              <span *ngIf="action == 'EditOrganizationType'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddProjectCategory' || action == 'EditProjectCategory'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Project Category</mat-label>
                <input matInput type="text" class="form-control"
                  [(ngModel)]="saveProjectCategoryList.ProjectCategoryName" name="ProjectCategory" required>
              </mat-form-field>
            </div>
          </div>

          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveProjectCategory()">
              <span *ngIf="action == 'AddProjectCategory'">Save</span>
              <span *ngIf="action == 'EditProjectCategory'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddQualification' || action == 'EditQualification'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Qualification</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveQualificationData.QualificationName"
                  name="Qualification" required>
              </mat-form-field>
            </div>
          </div>

          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(saveQualificationData, this.baseGlobalConfig + 'QualificationSave')">
              <span *ngIf="action == 'AddQualification'">Save</span>
              <span *ngIf="action == 'EditQualification'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddPaymentMode' || action == 'EditPaymentMode'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Payment Mode</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="savePaymentModeData.PaymentModeName"
                  name="Payment" required>
              </mat-form-field>
            </div>
          </div>

          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(savePaymentModeData, this.baseGlobalConfig + 'PaymentModeSave')">
              <span *ngIf="action == 'AddPaymentMode'">Save</span>
              <span *ngIf="action == 'EditPaymentMode'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddEmployeeCategory' || action == 'EditEmployeeCategory'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Employee Category</mat-label>
                <input matInput type="text" class="form-control"
                  [(ngModel)]="saveEmployeeCategoryInputs.EmployeeCategoryName" name="EmployeeCategoryName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveEmployeeCategory()">
              <span *ngIf="action == 'AddEmployeeCategory'">Save</span>
              <span *ngIf="action == 'EditEmployeeCategory'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddProjectType' || action == 'EditProjectType'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class="col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Project Category</mat-label>
              <mat-select class="form-control" [(ngModel)]="SaveProjectType.ProjectCategory" name="ProjectCategory"
                [compareWith]="compareFn" required>
                <fuse-mat-select-search name="search" [(ngModel)]="tree1">
                </fuse-mat-select-search>
                <mat-option *ngFor="let d_list of projectList | filter : tree1" [value]="d_list">
                  {{d_list.ProjectCategoryName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Project Type</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="SaveProjectType.ProjectTypeName"
                  name="ProjectTypeName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>HSN/SAC</mat-label>
                <mat-select class="form-control" [(ngModel)]="SaveProjectType.SACCode" name="SACCode"
                  [compareWith]="compareFn" required>
                  <mat-option *ngFor="let d_list of HsnDropList" [value]="d_list">
                    {{d_list.HSNCode}} - {{d_list.HSNPercent}}%</mat-option>
                </mat-select>
              </mat-form-field>

            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveProjectTypes()">
              <span *ngIf="action == 'AddProjectType'">Save</span>
              <span *ngIf="action == 'EditProjectType'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddServiceCategory' || action == 'EditServiceCategorys'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class="col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>SBU</mat-label>
              <mat-select class="form-control" [(ngModel)]="saveServiceCategoryInput.CostCenterCategory"
                (selectionChange)="getSBHName($event)" name="CostCenterCategory" [compareWith]="compareFn">
                <fuse-mat-select-search name="search" [(ngModel)]="tree35">
                </fuse-mat-select-search>
                <mat-option *ngFor="let d_list of SBHDropDownLists | filter : tree35" [value]="d_list">
                  {{d_list.CostCenterCategoryName}}</mat-option>

              </mat-select>
            </mat-form-field>
          </div>
          <div class=" col-sm-12">
            <div class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>SBU Head</mat-label>
                <input matInput type="text" class="form-control"
                  [(ngModel)]="saveServiceCategoryInput.CostCenterCategory.StrategicBusinessHead.EmployeeName"
                  name="EmployeeName" readonly required>
              </mat-form-field>
            </div>

          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Service Category</mat-label>
                <input matInput type="text" class="form-control"
                  [(ngModel)]="saveServiceCategoryInput.ServiceCategoryName" name="ServiceCategory" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveServiceCategory()">
              <span *ngIf="action == 'AddServiceCategory'">Save</span>
              <span *ngIf="action == 'EditServiceCategorys'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>


    <div *ngIf="action == 'AddAssestItem' || action == 'EditAssestItem'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Asset Item</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveAssestItemInput.AssetItemName"
                  name="AssetItemName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveAssestItem()">
              <span *ngIf="action == 'AddAssestItem'">Save</span>
              <span *ngIf="action == 'EditAssestItem'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>




    <div *ngIf="action == 'AddServiceType' || action == 'EditServiceType'">
      <div>
        <form class="row servicetype-add" autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class="col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Service Category</mat-label>
              <mat-select class="form-control" [(ngModel)]="saveServiceCategoryTypeInput.ServiceCategory"
                name="ServiceCategory" [compareWith]="compareFn" required>
                <fuse-mat-select-search name="search" [(ngModel)]="tree2">
                </fuse-mat-select-search>
                <mat-option *ngFor="let d_list of serviceList | filter : tree2" [value]="d_list">
                  {{d_list.ServiceCategoryName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Service Type</mat-label>
                <input matInput type="text" class="form-control"
                  [(ngModel)]="saveServiceCategoryTypeInput.ServiceTypeName" name="ServiceType" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>HSN/SAC</mat-label>
                <mat-select class="form-control" [(ngModel)]="saveServiceCategoryTypeInput.SACCode" name="SACCode"
                  [compareWith]="compareFn">
                  <fuse-mat-select-search name="search" [(ngModel)]="tree3">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let d_list of HsnDropList | filter : tree3" [value]="d_list">
                    {{d_list.HSNCode}} - {{d_list.HSNPercent}}%</mat-option>
                </mat-select>
              </mat-form-field>
              <!-- <mat-form-field appearance="outline">
                <mat-label>HSN/SAC</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveServiceCategoryTypeInput.SACCode"
                  name="SACCode" required>
              </mat-form-field> -->
            </div>
          </div>

          <div class=" col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Renewal Alert Before</mat-label>
              <input matInput type="number" [(ngModel)]="saveServiceCategoryTypeInput.RenewalAlertBefore"
                name="RenewalAlertBefore">
              <mat-select placeholder="Select" class="form-control w85-sufix" matSuffix [compareWith]="compareFn"
                [(ngModel)]="saveServiceCategoryTypeInput.RenewalAlertBeforeUnit" name="RenewalAlertBeforeUnit">
                <fuse-mat-select-search name="search" [(ngModel)]="tree4">
                </fuse-mat-select-search>
                <mat-option *ngFor="let list of durationListData | filter: tree4" [value]="list">
                  {{list.DurationUnitName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class=" col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Delivery Reminder Before</mat-label>
              <input matInput type="number" [(ngModel)]="saveServiceCategoryTypeInput.DeliveryReminderBefore"
                name="DeliveryReminderBefore">
              <mat-select placeholder="Select" class="form-control w85-sufix" matSuffix [compareWith]="compareFn"
                [(ngModel)]="saveServiceCategoryTypeInput.DeliveryReminderBeforeUnit" name="DeliveryReminderBeforeUnit">
                <fuse-mat-select-search name="search" [(ngModel)]="tree5">
                </fuse-mat-select-search>
                <mat-option *ngFor="let list of durationListData tree5" [value]="list">
                  {{list.DurationUnitName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class=" col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Commencement Time</mat-label>
              <input matInput type="number" [(ngModel)]="saveServiceCategoryTypeInput.CommencementTime"
                name="CommencementTime">
              <mat-select placeholder="Select" class="form-control w85-sufix" matSuffix [compareWith]="compareFn"
                [(ngModel)]="saveServiceCategoryTypeInput.CommencementTimeUnit" name="CommencementTimeUnit">
                <fuse-mat-select-search name="search" [(ngModel)]="tree6">
                </fuse-mat-select-search>
                <mat-option *ngFor="let list of durationListData | filter : tree6" [value]="list">
                  {{list.DurationUnitName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class=" col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Suspension Time</mat-label>
              <input matInput type="number" [(ngModel)]="saveServiceCategoryTypeInput.SuspensionTime"
                name="SuspensionTime">
              <mat-select placeholder="Select" class="form-control w85-sufix" matSuffix [compareWith]="compareFn"
                [(ngModel)]="saveServiceCategoryTypeInput.SuspensionTimeUnit" name="SuspensionTimeUnit">
                <fuse-mat-select-search name="search" [(ngModel)]="tree7">
                </fuse-mat-select-search>
                <mat-option *ngFor="let list of durationListData | filter: tree7" [value]="list">
                  {{list.DurationUnitName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-12">
            <label>Additional Charges</label>
            <div class="row">
              <div class="col-md-8">
                <mat-form-field appearance="outline">
                  <input matInput type="text" class="form-control" placeholder="Title"
                    [(ngModel)]="saveServiceCategoryTypeInput.AdditionalChargeLabel" name="AdditionalChargeLabel">
                </mat-form-field>
              </div>
              <div class="col-md-4 pl-0 service-addchper">
                <mat-form-field appearance="outline">
                  <input matInput type="number" class="form-control"
                    [(ngModel)]="saveServiceCategoryTypeInput.AdditionalChargeDigit" name="AdditionalChargeDigit">
                  <span matSuffix>
                    %
                  </span>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="col-md-12 comdatepiciocn">
            <mat-form-field appearance="outline">
              <mat-label>Valid Till</mat-label>
              <input matInput [matDatepicker]="pickerV1"
                [(ngModel)]="saveServiceCategoryTypeInput.AdditionalChargeValidOn" name="AdditionalChargeValidOn">
              <mat-datepicker-toggle matSuffix [for]="pickerV1"></mat-datepicker-toggle>
              <mat-datepicker #pickerV1></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-md-12 service-addchper">
            <label>Additional Discount</label>
            <div class="row">
              <div class="col-md-8">
                <mat-form-field appearance="outline">
                  <input matInput type="text" class="form-control" placeholder="Title"
                    [(ngModel)]="saveServiceCategoryTypeInput.DiscountLabel" name="DiscountLabel">
                </mat-form-field>
              </div>
              <div class="col-md-4 pl-0">
                <mat-form-field appearance="outline">
                  <input matInput type="number" class="form-control"
                    [(ngModel)]="saveServiceCategoryTypeInput.DiscountDigit" name="DiscountDigit">
                  <span matSuffix>
                    %
                  </span>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="col-md-12 comdatepiciocn">
            <mat-form-field appearance="outline">
              <mat-label>Valid Till</mat-label>
              <input matInput [matDatepicker]="pickerV2" [(ngModel)]="saveServiceCategoryTypeInput.DiscountValidOn"
                name="DiscountValidOn">
              <mat-datepicker-toggle matSuffix [for]="pickerV2"></mat-datepicker-toggle>
              <mat-datepicker #pickerV2></mat-datepicker>
            </mat-form-field>
          </div>

          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveServiceType()">
              <span *ngIf="action == 'AddServiceType'">Save</span>
              <span *ngIf="action == 'EditServiceType'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddServiceTask' || action == 'EditServiceTask'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class="col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Estimation Tab</mat-label>
              <mat-select class="form-control"
                (selectionChange)="ServiceTaskSelectCategory($event, 'activity'); SelectEstimationtab($event, saveServiceTaskInput)"
                [(ngModel)]="saveServiceTaskInput.Category" name="ServiceCategory" [compareWith]="compareFn">
                <fuse-mat-select-search name="search" [(ngModel)]="tree8">
                </fuse-mat-select-search>
                <mat-option *ngFor="let cat_list of ServiceTaskCategoryDropDownList | filter:tree8" [value]="cat_list">
                  {{cat_list.CategoryName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-sm-12" *ngIf="saveServiceTaskInput?.IsEstimationgroup">
            <mat-radio-group [(ngModel)]="saveServiceTaskInput.EstimationGroup" name="EstimationGroup"
              (change)=" getServiceActivityDrpListForTask($event)">
              <mat-radio-button value="General">General</mat-radio-button>
              <mat-radio-button value="ThirdParty">Third party</mat-radio-button>
              <mat-radio-button value="Both">Both</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Category</mat-label>
                <mat-select class="form-control" (selectionChange)="ServiceTaskSelectCategory($event, 'subactivity')"
                  [(ngModel)]="saveServiceTaskInput.ServiceActivity" name="ServiceActivity" [compareWith]="compareFn">
                  <fuse-mat-select-search name="search" [(ngModel)]="tree9">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let list of ServiceActivityList | filter: tree9" [value]="list">
                    {{list.ServiceActivityName}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Sub-Category</mat-label>
                <mat-select class="form-control" [(ngModel)]="saveServiceTaskInput.ServiceSubActivity"
                  name="ServiceSubActivity" [compareWith]="compareFn">
                  <fuse-mat-select-search name="search" [(ngModel)]="tree10">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let list of ServiceSubActivityList | filter : tree10" [value]="list">
                    {{list.ServiceSubActivityName}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Estimation Title</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveServiceTaskInput.ServiceTaskName"
                  name="ServiceTaskName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveServiceTask()">
              <span *ngIf="action == 'AddServiceTask'">Save</span>
              <span *ngIf="action == 'EditServiceTask'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>






    <div *ngIf="action == 'AddProjectTask' || action == 'EditProjectTask'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class="col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Estimation Tab</mat-label>
              <mat-select class="form-control" (selectionChange)="ProjectTaskSelectCategory($event, 'activity')"
                [(ngModel)]="saveProjectTaskInput.Category" name="ProjectCategory" [compareWith]="compareFn">
                <fuse-mat-select-search name="search" [(ngModel)]="tree11">
                </fuse-mat-select-search>
                <mat-option *ngFor="let cat_list of ProjectTaskCategoryDropDownList | filter : tree11"
                  [value]="cat_list">
                  {{cat_list.CategoryName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Category</mat-label>
                <mat-select class="form-control" (selectionChange)="ProjectTaskSelectCategory($event, 'subactivity')"
                  [(ngModel)]="saveProjectTaskInput.ProjectActivity" name="ProjectActivity" [compareWith]="compareFn">
                  <fuse-mat-select-search name="search" [(ngModel)]="tree12">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let list of ProjectActivityList | filter : tree12" [value]="list">
                    {{list.ProjectActivityName}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Sub-Category</mat-label>

                <mat-select class="form-control" [(ngModel)]="saveProjectTaskInput.ProjectSubActivity"
                  name="ProjectSubActivity" [compareWith]="compareFn">
                  <fuse-mat-select-search name="search" [(ngModel)]="tree13">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let list of ProjectSubActivityList | filter : tree13" [value]="list">
                    {{list.ProjectSubActivityName}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Title</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveProjectTaskInput.ProjectTaskName"
                  name="ProjectTaskName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveProjectTask()">
              <span *ngIf="action == 'AddProjectTask'">Save</span>
              <span *ngIf="action == 'EditProjectTask'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddGeneric' || action == 'EditGeneric'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class="col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Generic Category</mat-label>
              <mat-select class="form-control" [(ngModel)]="saveGenericCategoryTypeInput.GenericCategory"
                name="GenericCategory" [compareWith]="compareFn">
                <fuse-mat-select-search name="search" [(ngModel)]="tree14">
                </fuse-mat-select-search>
                <mat-option *ngFor="let d_list of genericCategorysList | filter : tree14" [value]="d_list">
                  {{d_list.GenericCategoryName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Generic Type Name</mat-label>
                <input matInput type="text" class="form-control"
                  [(ngModel)]="saveGenericCategoryTypeInput.GenericTypeName" name="GenericTypeName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Generic Type Code</mat-label>
                <input matInput type="text" class="form-control"
                  [(ngModel)]="saveGenericCategoryTypeInput.GenericTypeCode" name="GenericTypeCode" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveGenericType()">
              <span *ngIf="action == 'AddGeneric'">Save</span>
              <span *ngIf="action == 'EditGeneric'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddCurrency' || action == 'EditCurrency'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Currency</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveCurrencyInput.CurrencyName"
                  name="CurrencyName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Currency Symbol</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveCurrencyInput.CurrencySymbol"
                  name="CurrencySymbol" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(saveCurrencyInput, this.baseGlobalConfig + 'CurrencySave')">
              <span *ngIf="action == 'AddCurrency'">Save</span>
              <span *ngIf="action == 'EditCurrency'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>




    <div *ngIf="action == 'AddModeOfPO' || action == 'EditModeOfPO'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Mode of PO</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveModeOfPOInput.ModeOfPOName"
                  name="CurrencyName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(saveModeOfPOInput, this.baseCompanyCore + 'ModeOfPOSave')">
              <span *ngIf="action == 'AddModeOfPO'">Save</span>
              <span *ngIf="action == 'EditModeOfPO'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddBank' || action == 'EditBank'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Bank</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveBankInput.BankName" name="BankName"
                  required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(saveBankInput, this.baseGlobalConfig + 'BankSave')">
              <span *ngIf="action == 'AddBank'">Save</span>
              <span *ngIf="action == 'EditBank'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- Reg. Reason -->
    <div *ngIf="action == 'AddRegReason' || action == 'EditRegReason'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Resignation</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveRegInput.ResignationReasonName"
                  name="ResignationReasonName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid" (click)="saveReg()">
              <span *ngIf="action == 'AddRegReason'">Save</span>
              <span *ngIf="action == 'EditRegReason'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- other activity -->
    <div *ngIf="action == 'SaveOtherActivity' || action == 'EditOtherActivity'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Other Activity
                </mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="otheractInput.OtherActivityName"
                  name="OtherActivityName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid" (click)="otheract()">
              <span *ngIf="action == 'SaveOtherActivity'">Save</span>
              <span *ngIf="action == 'EditOtherActivity'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- Ter. Reason -->
    <div *ngIf="action == 'AddTerReason' || action == 'EditTerReason'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Termination</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveTerInput.TerminationReasonName"
                  name="TerminationReasonName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid" (click)="saveTer()">
              <span *ngIf="action == 'AddTerReason'">Save</span>
              <span *ngIf="action == 'EditTerReason'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- Year -->
    <div *ngIf="action == 'AddYear' || action == 'EditYear'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Year</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveYearInput.YearName" name="YearName"
                  required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(saveYearInput, this.baseGlobalConfig + 'YearSave')">
              <span *ngIf="action == 'AddYear'">Save</span>
              <span *ngIf="action == 'EditYear'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- Occupation -->
    <div *ngIf="action == 'AddOccupation' || action == 'EditOccupation'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Occupation</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveOccupationInput.OccupationName"
                  name="OccupationName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveOccupation()">
              <span *ngIf="action == 'AddOccupation'">Save</span>
              <span *ngIf="action == 'EditOccupation'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Employee Add  -->


    <!-- end Employee -->
    <div *ngIf="action == 'AddBankAcTyp' || action == 'EditBankAcTyp'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Bank Account Type</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveBankAcTypInput.BankAccountTypeName"
                  name="BaknAcTypeName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(saveBankAcTypInput, this.baseGlobalConfig + 'BankAccountTypeSave')">
              <span *ngIf="action == 'AddBankAcTyp'">Save</span>
              <span *ngIf="action == 'EditBankAcTyp'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="action == 'AddDocumentType' || action == 'EditDocumentType'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">




          <div class=" col-sm-12">
            <div class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Document Category</mat-label>
                <mat-select class="form-control" [(ngModel)]="saveDocumentTypInput.DocumentCategory"
                  name="DocumentCategoryId" [compareWith]="compareFn">
                  <fuse-mat-select-search name="search" [(ngModel)]="DocumentCategory1">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let DocCategory of DocumentCategoryDD | filter : DocumentCategory1"
                    [value]="DocCategory" required>
                    {{DocCategory?.DocumentCategoryName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Document Type</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveDocumentTypInput.DocumentTypeName"
                  name="DocumentTypeName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(saveDocumentTypInput, this.baseGlobalConfig + 'DocumentTypeSave')">
              <span *ngIf="action == 'AddDocumentType'">Save</span>
              <span *ngIf="action == 'EditDocumentType'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- document Category -->
    <div *ngIf="action == 'AddDocumentCategory'  || action == 'EditDocumentCategory'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Document Category</mat-label>
                <input type="text" class="form-control" matInput type="text" class="form-control"
                  [(ngModel)]="saveDocumentCategoryInput.DocumentCategoryName" name="DocumentCategoryName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(saveDocumentCategoryInput, this.baseGlobalConfig + 'DocumentCategorySave')">
              <span *ngIf="action == 'AddDocumentCategory'">Save</span>
              <span *ngIf="action == 'EditDocumentCategory'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="action == 'AddUnits' || action == 'EditUnits'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">

          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Category</mat-label>
                <mat-select class="form-control" [(ngModel)]="saveUnitsInput.Category" name="Category"
                  [compareWith]="compareFn">
                  <fuse-mat-select-search name="search" [(ngModel)]="tree15">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let Category of ProjectActivityDropDownList | filter : tree15" [value]="Category"
                    required>
                    {{Category.CategoryName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Units</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveUnitsInput.UnitName" name="UnitName"
                  required>
              </mat-form-field>
            </div>
          </div>

          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(saveUnitsInput, this.baseGlobalConfig + 'UnitSave')">
              <span *ngIf="action == 'AddUnits'">Save</span>
              <span *ngIf="action == 'EditUnits'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="action == 'AddEstimationBuffer' || action == 'EditEstimationBuffer'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Estimation Buffer</mat-label>
                <input matInput type="text" class="form-control"
                  [(ngModel)]="saveEstimationBufferInput.EstimationBufferName" name="EstimationBufferName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(saveEstimationBufferInput, this.baseGlobalConfig + 'EstimationBufferSave')">
              <span *ngIf="action == 'AddEstimationBuffer'">Save</span>
              <span *ngIf="action == 'EditEstimationBuffer'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddBufferPercentage' || action == 'EditBufferPercentage'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Buffer Percentage</mat-label>
                <input matInput type="text" class="form-control"
                  [(ngModel)]="saveBufferPercentageInput.BufferPercentageName" name="BufferPercentageNames" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(saveBufferPercentageInput, this.baseGlobalConfig + 'BufferPercentageSave')">
              <span *ngIf="action == 'AddBufferPercentage'">Save</span>
              <span *ngIf="action == 'EditBufferPercentage'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="action == 'AddReason' || action == 'EditReason'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Business Process</mat-label>
                <mat-select class="form-control" [(ngModel)]="saveReasonInput.BusinessProcess" name="BusinessProcess"
                  [compareWith]="compareFn">
                  <fuse-mat-select-search name="search" [(ngModel)]="tree16">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let BusinessProcess of ReasonBusinessProcess | filter: tree16"
                    [value]="BusinessProcess">
                    {{BusinessProcess.BusinessProcessName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Reason</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveReasonInput.ReasonName"
                  name="ReasonsName" required>
              </mat-form-field>
            </div>
          </div>

          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(saveReasonInput, this.baseGlobalConfig + 'ReasonSave')">
              <span *ngIf="action == 'AddReason'">Save</span>
              <span *ngIf="action == 'EditReason'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddProjectActivity' || action == 'EditProjectActivity'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Estimation Tab</mat-label>
                <mat-select class="form-control" [(ngModel)]="saveProjectActivityInput.Category" name="Category"
                  [compareWith]="compareFn" required>
                  <fuse-mat-select-search name="search" [(ngModel)]="tree17">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let Category of ProjectActivityDropDownList | filter : tree17" [value]="Category"
                    required>
                    {{Category.CategoryName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Category</mat-label>
                <input matInput type="text" class="form-control"
                  [(ngModel)]="saveProjectActivityInput.ProjectActivityName" name="ProjectActivityName" required>
              </mat-form-field>
            </div>
          </div>

          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveProjectActivity()">
              <span *ngIf="action == 'AddProjectActivity'">Save</span>
              <span *ngIf="action == 'EditProjectActivity'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddProjectSubActivity' || action == 'EditProjectSubActivity'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Estimation Tab</mat-label>
                <mat-select class="form-control" [(ngModel)]="saveProjectSubActivityInput.Category" name="Category"
                  [compareWith]="compareFn" (selectionChange)="CatProjectSubActivityDrpList($event)">
                  <fuse-mat-select-search name="search" [(ngModel)]="tree18">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let Category of ProjectCatActivityDropDownList | filter : tree18"
                    [value]="Category" required>
                    {{Category.CategoryName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Category</mat-label>
                <mat-select class="form-control" [(ngModel)]="saveProjectSubActivityInput.ProjectActivity"
                  name="ProjectActivity" [compareWith]="compareFn">
                  <fuse-mat-select-search name="search" [(ngModel)]="tree19">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let Activity of ProjectCatActivityHeadDropDownList | filter : tree19"
                    [value]="Activity" required>
                    {{Activity.ProjectActivityName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Sub-Category</mat-label>
                <input matInput type="text" class="form-control"
                  [(ngModel)]="saveProjectSubActivityInput.ProjectSubActivityName" name="ProjectSubActivityName"
                  required>
              </mat-form-field>
            </div>
          </div>

          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveProjectSubActivity()">
              <span *ngIf="action == 'AddProjectSubActivity'">Save</span>
              <span *ngIf="action == 'EditProjectSubActivity'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddServiceSubActivity' || action == 'EditServiceSubActivity'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Estimation Tab</mat-label>
                <mat-select class="form-control" [(ngModel)]="saveServiceSubActivityInput.Category" name="Category"
                  [compareWith]="compareFn"
                  (selectionChange)="getServiceActivityDrpList($event); SelectEstimationtab($event, saveServiceSubActivityInput)">
                  <fuse-mat-select-search name="search" [(ngModel)]="tree20">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let Category of ServiceActivityDropDownList | filter : tree20" [value]="Category"
                    required>
                    {{Category.CategoryName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-12" *ngIf="saveServiceSubActivityInput?.IsEstimationgroup">
            <mat-radio-group [(ngModel)]="saveServiceSubActivityInput.EstimationGroup" name="EstimationGroup"
              (change)="getServiceActivityDrpList($event)">
              <mat-radio-button value="General">General</mat-radio-button>
              <mat-radio-button value="ThirdParty">Third party</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Category</mat-label>
                <mat-select class="form-control" [(ngModel)]="saveServiceSubActivityInput.ServiceActivity"
                  name="ServiceActivity" [compareWith]="compareFn">
                  <fuse-mat-select-search name="search" [(ngModel)]="tree21">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let Activity of ServiceCatActivityDropDownList | filter : tree21"
                    [value]="Activity" required>
                    {{Activity.ServiceActivityName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Sub-Category</mat-label>
                <input matInput type="text" class="form-control"
                  [(ngModel)]="saveServiceSubActivityInput.ServiceSubActivityName" name="ServiceSubActivityName"
                  required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveServiceSubActivity()">
              <span *ngIf="action == 'AddServiceSubActivity'">Save</span>
              <span *ngIf="action == 'EditServiceSubActivity'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddServiceActivity' || action == 'EditServiceActivity'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Estimation Tab</mat-label>
                <mat-select class="form-control" [(ngModel)]="saveServiceActivityInput.Category" name="Category"
                  [compareWith]="compareFn" (selectionChange)="SelectEstimationtab($event, saveServiceActivityInput)">
                  <fuse-mat-select-search name="search" [(ngModel)]="tree22">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let Category of ServiceActivityDropDownList | filter : tree22" [value]="Category"
                    required>
                    {{Category.CategoryName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>



          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Category</mat-label>
                <input matInput type="text" class="form-control"
                  [(ngModel)]="saveServiceActivityInput.ServiceActivityName" name="ServiceActivityName" required>
              </mat-form-field>
            </div>
          </div>
          <div class="col-sm-12"
            *ngIf="saveServiceActivityInput?.Category?.CategoryName == 'Material'|| saveServiceActivityInput?.Category?.CategoryName == 'Expense' ">
            <mat-radio-group [(ngModel)]="saveServiceActivityInput.EstimationGroup" name="EstimationGroup">
              <mat-radio-button value="General">General</mat-radio-button>
              <mat-radio-button value="ThirdParty">Third party</mat-radio-button>
              <mat-radio-button value="Both">Both</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class=" col-sm-12" *ngIf="saveServiceActivityInput?.Category?.CategoryName == 'Efforts'">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Skills Category</mat-label>
                <mat-select class="form-control" [(ngModel)]="saveServiceActivityInput.SkillCategoryList"
                  name="SkillCategoryList" [compareWith]="compareFn" multiple required #skillSelect="ngModel">
                  <fuse-mat-select-search name="search" [(ngModel)]="KeySkillSearch">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let skill of SkillCategorylist | filter: KeySkillSearch" [value]="skill" required>
                    {{skill.SkillCategoryName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <mat-chip-list class="col-md-12 service-achead-chips"
            *ngIf="saveServiceActivityInput?.SkillCategoryList?.length > 0 && saveServiceActivityInput?.Category?.CategoryName == 'Efforts'">
            <mat-chip *ngFor="let skill of saveServiceActivityInput.SkillCategoryList; let i = index">
              {{skill.SkillCategoryName}}
              <mat-icon matChipRemove (click)="skillSetRemove(i)">cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>

          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveServiceActivity()">
              <span *ngIf="action == 'AddServiceActivity'">Save</span>
              <span *ngIf="action == 'EditServiceActivity'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddLabel' || action == 'EditLabel'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Label</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveLabelInput.LabelName"
                  name="LabelsName" required>
              </mat-form-field>
            </div>
          </div>

          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(saveLabelInput, this.baseGlobalConfig + 'LabelSave')">
              <span *ngIf="action == 'AddLabel'">Save</span>
              <span *ngIf="action == 'EditLabel'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="action == 'AddClient' || action == 'EditClient'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Client Name</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveClientInputs.ClientName"
                  name="ClientName" required>
              </mat-form-field>
            </div>
          </div>

          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveClient()">
              <span *ngIf="action == 'AddClient'">Save</span>
              <span *ngIf="action == 'EditClient'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="action == 'AddCountry' || action == 'EditCountry'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Country</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveCountryInput.CountryName"
                  name="CountryName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Country Code</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveCountryInput.CountryCode"
                  name="CountryCode" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Mobile Digit</mat-label>
                <input matInput type="number" class="form-control" [(ngModel)]="saveCountryInput.MobileDigit"
                  name="MobileDigit" numbersOnly pattern="\d*">
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(saveCountryInput, this.baseGlobalConfig + 'CountrySave')">
              <span *ngIf="action == 'AddCountry'">Save</span>
              <span *ngIf="action == 'EditCountry'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddState' || action == 'EditState'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Country</mat-label>
                <mat-select class="form-control" [(ngModel)]="saveStateInput.CountryId" name="CountryState">
                  <fuse-mat-select-search name="search" [(ngModel)]="tree23">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let Country of CountryList | filter : tree23" [value]="Country?.CountryId">
                    {{Country.CountryName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>State</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveStateInput.StateName" name="StateName"
                  required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>State Code</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveStateInput.StateCode" name="StateCode"
                  required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(saveStateInput, this.baseGlobalConfig + 'StateSave')">
              <span *ngIf="action == 'AddState'">Save</span>
              <span *ngIf="action == 'EditState'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddProfitCenter' || action == 'EditProfitCenter'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Profit Center</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveProfitCenterInput.ProfitCenterName"
                  name="ProfitCenterName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(saveProfitCenterInput, this.baseCompanyCore + 'ProfitCenterSave')">
              <span *ngIf="action == 'AddProfitCenter'">Save</span>
              <span *ngIf="action == 'EditProfitCenter'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="action == 'AddCategory' || action == 'EditCategory'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Category Name</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="SaveCategory.CategoryName"
                  name="CategoryName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveCategory()">
              <span *ngIf="action == 'AddCategory'">Save</span>
              <span *ngIf="action == 'EditCategory'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'DurationAddUnit' || action == 'DurationEditUnit'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Units Name</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="SaveUnit.DurationUnitName"
                  name="DurationUnitName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveDurationsUnites()">
              <span *ngIf="action == 'DurationAddUnit'">Save</span>
              <span *ngIf="action == 'DurationEditUnit'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="action == 'AddDepartment' || action == 'EditDepartment'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Department Category</mat-label>
              <mat-select class="form-control" [(ngModel)]="saveDepartmentInput.DepartmentCategory"
                name="DepartmentCategoryId" [compareWith]="compareFn" required>
                <fuse-mat-select-search name="search" [(ngModel)]="tree24">
                </fuse-mat-select-search>
                <mat-option *ngFor="let d_list of departmentList.enumData | filter : tree24" [value]="d_list">
                  {{d_list.Type}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Department</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="saveDepartmentInput.DepartmentName"
                name="DepartmentName" required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>HOD</mat-label>
              <mat-select class="form-control" [(ngModel)]="saveDepartmentInput.Employee" name="Employee" required
                [compareWith]="compareFn">
                <fuse-mat-select-search name="search" [(ngModel)]="tree25">
                </fuse-mat-select-search>
                <mat-option *ngFor="let d_list of departmentList.EmployeeList | filter : tree25" [value]="d_list">
                  {{d_list.EmployeeName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster(saveDepartmentInput, this.baseCompanyCore + 'DepartmentSave')">
            <span *ngIf="action == 'AddDepartment'">Save</span>
            <span *ngIf="action == 'EditDepartment'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <div *ngIf="action == 'AddCostCenter' || action == 'EditCostCenter'">

      <form autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">
        <div fxLayout="row wrap">
          <div class="flex-item" fxFlex="0 1 100">
            <mat-form-field appearance="outline">
              <mat-label>CostCenter Name</mat-label>
              <mat-select class="form-control" [(ngModel)]="SaveCostCenter.CostCenterName" name="CostCenterName">
                <mat-option *ngFor="let d_list of costCenterTypeNameLis" [value]="d_list">
                  {{d_list.CostCenterMasterName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="flex-item" fxFlex="0 1 100">
            <mat-form-field appearance="outline">
              <mat-label>CostCenter Type</mat-label>
              <mat-select class="form-control" [(ngModel)]="SaveCostCenter.CostCenterType" name="CostCenterType">
                <mat-option *ngFor="let d_list of costCenterNameList" [value]="d_list">
                  {{d_list.CostCenterTypeName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="flex-item" fxFlex="0 1 100">
            <mat-form-field appearance="outline">
              <mat-label>Textarea</mat-label>
              <textarea matInput type="text" class="form-control" [(ngModel)]="SaveCostCenter.Description"
                name="Description" required></textarea>
            </mat-form-field>
          </div>
          <div class="flex-item" fxFlex="0 1 100">
            <mat-form-field appearance="outline">
              <mat-label>Area/Location</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="SaveCostCenter.AreaLocation"
                name="AreaLocation" required>
            </mat-form-field>
          </div>
          <div class="flex-item" fxFlex="0 1 100">
            <mat-label>Currency : IN [Rs.]</mat-label>
          </div>
        </div>
        <div fxLayout="row wrap">
          <div class="flex-item" fxFlex="0 1 100">
            <mat-form-field appearance="outline">
              <mat-label>Name</mat-label>
              <mat-select class="form-control" [(ngModel)]="SaveCostCenter.Employee" name="Employee"
                (selectionChange)="getEmployeeRecord(SaveCostCenter.Employee.EmployeeId)">
                <mat-option *ngFor="let d_list of costcenterEmployee" [value]="d_list">
                  {{d_list.EmployeeName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="flex-item" fxFlex="0 1 100">
            <mat-form-field appearance="outline">
              <mat-label>Email Id</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="SaveCostCenter.EmployeeEmailId"
                name="EmailId" readonly>
            </mat-form-field>
          </div>
          <div class="flex-item" fxFlex="0 1 100">
            <mat-form-field appearance="outline">
              <mat-label>Contact No</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="SaveCostCenter.EmployeeContactNo"
                name="ContactNo" readonly>
            </mat-form-field>
          </div>
          <div class="flex-item" fxFlex="0 1 100">
            <mat-form-field appearance="outline">
              <mat-label>CostCenter Category</mat-label>
              <mat-select class="form-control" [(ngModel)]="SaveCostCenter.CostCenterCategory"
                name="CostCenterCategory">
                <mat-option *ngFor="let d_list of costCenteCategoryList" [value]="d_list">
                  {{d_list.CostCenterCategoryName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>


        <div class="col-sm-12">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveCostCenter()">
            <span *ngIf="action == 'AddCostCenter'">Save</span>
            <span *ngIf="action == 'EditCostCenter'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <div *ngIf="action == 'AddProfitCenterDetails' || action == 'EditProfitCenterDetails'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class="col-sm-6">
            <mat-form-field appearance="outline">
              <mat-label>ProfitCenter Name</mat-label>
              <mat-select class="form-control" [(ngModel)]="saveProfitCenterDetailsInputs.ProfitCenter"
                name="ProfitCenter">
                <mat-option *ngFor="let d_list of profitNameList" [value]="d_list">
                  {{d_list.ProfitCenterName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Textarea</mat-label>
                <textarea matInput type="text" class="form-control"
                  [(ngModel)]="saveProfitCenterDetailsInputs.Description" name="Description" required></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Area/Location</mat-label>
                <input matInput type="text" class="form-control"
                  [(ngModel)]="saveProfitCenterDetailsInputs.AreaLocation" name="AreaLocation" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12" style="width:100%;">
            <div class="" class="d-matlab">
              <mat-label>Currency : IN [Rs.]</mat-label>
            </div>
          </div>
          <hr>
          <div class="col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Name</mat-label>
              <mat-select class="form-control" [(ngModel)]="saveProfitCenterDetailsInputs.Employee" name="Employee"
                (selectionChange)="getEmployeeRecordProfit($event)">
                <mat-option *ngFor="let d_list of profitEmpList" [value]="d_list">
                  {{d_list.EmployeeName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Contact No</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="employeeRecordListProfit.EmployeeContactNo"
                name="EmployeeContactNo" readonly>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Email Id</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="employeeRecordListProfit.EmployeeEmailId"
                name="EmployeeEmailId" readonly>
            </mat-form-field>
          </div>
        </div> -->
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveProfitCenterDetail()">
              <span *ngIf="action == 'AddProfitCenterDetails'">Save</span>
              <span *ngIf="action == 'EditProfitCenterDetails'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="action == 'AddGenericCategory' || action == 'EditGenericCategory'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Generic Category Name</mat-label>
                <input matInput type="text" class="form-control"
                  [(ngModel)]="saveGenericCategoryInputs.GenericCategoryName" name="GenericCategory" required>
              </mat-form-field>
            </div>
          </div>

          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveGenericCategory()">
              <span *ngIf="action == 'AddGenericCategory'">Save</span>
              <span *ngIf="action == 'EditGenericCategory'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="action == 'AddUserRole' || action == 'UserRoleEdit'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>User Role</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveUserroleList.UserRoleName"
                  name="UserRoleName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>PLI (Performance Linked Incentives)</mat-label>
                <input matInput type="text" class="form-control">
              </mat-form-field>
            </div>
          </div>

          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveUserRole()">
              <span *ngIf="action == 'AddUserRole'">Save</span>
              <span *ngIf="action == 'UserRoleEdit'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="action == 'AddCostCenterCategory' || action == 'EditCostCenterCategory'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class="col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>CostCenter Employee Name</mat-label>
              <mat-select class="form-control" [(ngModel)]="saveCostCenterCategorySave.StrategicBusinessHead"
                name="StrategicBusinessHead" [compareWith]="compareFn">
                <fuse-mat-select-search name="search" [(ngModel)]="tree26">
                </fuse-mat-select-search>
                <mat-option *ngFor="let d_list of CostCenterCategorydropdownList | filter : tree26" [value]="d_list">
                  {{d_list.EmployeeName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>SBU</mat-label>
                <input matInput type="text" class="form-control"
                  [(ngModel)]="saveCostCenterCategorySave.CostCenterCategoryName" name="CostCenterCategory" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveCostcenterCategory()">
              <span *ngIf="action == 'AddCostCenterCategory'">Save</span>
              <span *ngIf="action == 'EditCostCenterCategory'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddWorkingSchedule' || action == 'EditWorkingSchedule'">
      <form class="row" autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm">
        <div class="col-sm-12">
          <mat-radio-group *ngIf="action == 'AddWorkingSchedule'" [(ngModel)]="saveWorkingSchedule.WorkingType"
            name="myTime">
            <mat-radio-button value="one time">One Time</mat-radio-button>
            <mat-radio-button *ngIf="!isWorkingSchedule" value="repeat">Repeat</mat-radio-button>
            <mat-radio-button *ngIf="!isWorkingSchedule" value="random">Random</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label>Type</mat-label>
            <mat-select class="form-control" [(ngModel)]="saveWorkingSchedule.ShiftId" name="ShiftId"
              [compareWith]="compareFn" #WorkingShift="ngModel" required>
              <fuse-mat-select-search name="search" [(ngModel)]="tree27">
              </fuse-mat-select-search>
              <mat-option *ngFor="let d_list of workingList | filter : tree27" [value]="d_list?.ShiftId">
                {{d_list.ShiftName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-12" *ngIf="saveWorkingSchedule.WorkingType == 'one time'">
          <mat-form-field appearance="outline">
            <mat-label>Date</mat-label>
            <input [min]="minDate" matInput [matDatepicker]="picker" (focus)="picker.open()" placeholder="Select Date"
              [(ngModel)]="saveWorkingSchedule.StartDate" name="StartDate" #StartDate="ngModel" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-sm-12" *ngIf="saveWorkingSchedule.WorkingType == 'repeat'">
          <mat-label>Duration</mat-label>
          <div class="row twocol">
            <div class="col-md-6 inbox">
              <mat-form-field class="datePicker" floatLabel="never" appearance="outline">
                <input [min]="minDate" matInput [matDatepicker]="picker" (focus)="picker.open()"
                  placeholder="Select Date" [(ngModel)]="saveWorkingSchedule.StartDate" name="StartDate"
                  #StartDate="ngModel" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6 inbox">
              <mat-form-field class="datePicker" floatLabel="never" appearance="outline">
                <input [min]="saveWorkingSchedule.StartDate" matInput [matDatepicker]="picker1" (focus)="picker1.open()"
                  placeholder="Select Date" [(ngModel)]="saveWorkingSchedule.EndDate" name="EndDate" #EndDate="ngModel"
                  required>
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-sm-12" *ngIf="saveWorkingSchedule.WorkingType == 'random'">
          <form #f1="ngForm" (ngSubmit)="f1.form.valid && AddDate()"
            *ngFor="let date of saveWorkingSchedule?.RendomEntry?.RendomDate; let last = last; let i = index">
            <mat-label>Date</mat-label>
            <div style="display:flex" class="WorkingRandom">
              <mat-form-field class="datePicker" style="width: 100%;" floatLabel="never" appearance="outline"
                [class.mat-form-field-invalid]="(myForm.submitted || f1.submitted) && RendomDate.invalid">
                <input [min]="minDate" matInput [matDatepicker]="picker" (focus)="picker.open()"
                  placeholder="Select Date" [(ngModel)]="saveWorkingSchedule.RendomEntry.RendomDate[i]"
                  name="RendomDate_{{i}}" #RendomDate="ngModel" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <button mat-icon-button color="primary" *ngIf="last && action != 'EditWorkingSchedule'" class="add_btn"
                mat-button class="add_btn">
                <mat-icon matTooltip="Add">add</mat-icon>
              </button>
              <button mat-icon-button color="primary" *ngIf="saveWorkingSchedule?.RendomEntry?.RendomDate.length > 1"
                class="remove_btn ml-0" mat-button (click)="RemoveDate(i)">
                <mat-icon matTooltip="Delete">remove</mat-icon>
              </button>
            </div>
          </form>
        </div>

        <div class="form-group" style="width:100%;">
          <div class="row m-0 twocol">
            <div class="col-sm-12"><label>Timings</label></div>
            <div class="col-md-6 inbox">
              <mat-form-field appearance="outline">
                <mat-select placeholder="Set Start Time" name="FromTime" [(ngModel)]="saveWorkingSchedule.FromTime"
                  #FromTime="ngModel" required>
                  <mat-option *ngFor="let time of timeFormate" [value]="time.Value">
                    {{time.Time}}</mat-option>
                </mat-select>
                <!-- <input type="time" matInput class="form-control form-time-control" atp-time-picker name="FromTime"
                [(ngModel)]="saveWorkingSchedule.FromTime" #FromTime="ngModel" placeholder="Set Start Time"
                required /> -->
              </mat-form-field>
            </div>
            <div class="col-md-6 inbox">
              <mat-form-field appearance="outline">
                <mat-select placeholder="Set End Time" name="ToTime" [(ngModel)]="saveWorkingSchedule.ToTime"
                  #ToTime="ngModel" required>
                  <mat-option *ngFor="let time of timeFormate" [value]="time.Value">
                    {{time.Time}}</mat-option>
                </mat-select>
                <!-- <input type="time" matInput class="form-control form-time-control" atp-time-picker name="ToTime"
                [(ngModel)]="saveWorkingSchedule.ToTime" #ToTime="ngModel" placeholder="Set End Time" required /> -->
              </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="saveWorkingSchedule.FromTime>saveWorkingSchedule.ToTime">
              <p style="color: red; font-size:12px">
                Choose corrrect Timing</p>
            </div>
          </div>
        </div>

        <div class="form-group  position-relative">
          <form #f3="ngForm" (ngSubmit)="f3.form.valid && AddBreak()"
            class="row mt-n2 ml-0 mr-0 align-items-center twocol"
            *ngFor="let Break of saveWorkingSchedule.BreakList; let i = index; let last = last">
            <div class="col-sm-9"> <label>Breaks</label></div>
            <div class="col-sm-3 WorkingRandom" style="display: flex; justify-content: end;">
              <button mat-icon-button color="primary" *ngIf="last && action != 'EditWorkingSchedule'" class="add_btn">
                <mat-icon matTooltip="Add">add</mat-icon>
              </button>
              <button mat-icon-button color="primary" class="remove_btn"
                *ngIf="saveWorkingSchedule.BreakList.length > 1" (click)="RemoveBreak(i)">
                <mat-icon matTooltip="Delete">remove</mat-icon>
              </button>
            </div>
            <div class="col-md-6 inbox">
              <mat-form-field appearance="outline"
                [class.mat-form-field-invalid]="(f3.submitted || myForm.submitted) && FromTime.invalid">
                <mat-select placeholder="Start Time" [(ngModel)]="Break.FromTime" name="FromTime_{{i}}"
                  #FromTime="ngModel" required>
                  <mat-option *ngFor="let time of timeFormate" [value]="time.Value">
                    {{time.Time}}</mat-option>
                </mat-select>
                <!-- <input type="time" matInput class="form-control form-time-control" atp-time-picker required
                         name="FromTime_{{i}}" [(ngModel)]="Break.FromTime" #FromTime="ngModel" placeholder="Start Time" /> -->
              </mat-form-field>
            </div>
            <div class="col-md-6 inbox">
              <mat-form-field appearance="outline"
                [class.mat-form-field-invalid]="(f3.submitted || myForm.submitted) && ToTime.invalid">
                <mat-select placeholder="End Time" [(ngModel)]="Break.ToTime" name="ToTime_{{i}}" #ToTime="ngModel"
                  required>
                  <mat-option *ngFor="let time of timeFormate" [value]="time.Value">
                    {{time.Time}}</mat-option>
                </mat-select>
                <!-- <input type="time" matInput class="form-control form-time-control" atp-time-picker name="ToTime_{{i}}"
                            [(ngModel)]="Break.ToTime" #ToTime="ngModel" placeholder="End Time" required /> -->
              </mat-form-field>
            </div>

            <div class="col-md-12" *ngIf="Break.ToTime && (Break.FromTime>Break.ToTime)">
              <p style="color: red; font-size:12px">
                Choose corrrect Time</p>
            </div>
            <div class="col-md-12"
              *ngIf="Break.ToTime && (saveWorkingSchedule.FromTime>Break.FromTime ||  saveWorkingSchedule.FromTime>Break.ToTime || Break.ToTime>saveWorkingSchedule.ToTime ||  Break.FromTime>saveWorkingSchedule.ToTime)">
              <p style="color: red; font-size:12px">
                Break Should be between Timimg period</p>
            </div>
          </form>
        </div>

        <div class="form-group mt-n2 form-groupItem"
          *ngIf="saveWorkingSchedule.WorkingType == 'repeat' && action != 'EditWorkingSchedule'">
          <div class="row m-0">
            <div class="col-sm-12">
              <label>Day</label>
            </div>
            <div class="col-sm-12 every-week">
              <mat-form-field appearance="outline">
                <mat-select multiple placeholder="Every" [(ngModel)]="saveWorkingSchedule.RepeatEntry.WeekFequency"
                  name="RepeatEntry.WeekFequency">
                  <mat-option value="1"
                    *ngIf="(saveWorkingSchedule.EndDate-saveWorkingSchedule.StartDate) / 86400000 > 0 ">
                    1<sup>st</sup>
                  </mat-option>
                  <mat-option value="2"
                    *ngIf="(saveWorkingSchedule.EndDate-saveWorkingSchedule.StartDate) / 86400000 > 6 ">
                    2<sup>nd</sup>
                  </mat-option>
                  <mat-option value="3"
                    *ngIf="(saveWorkingSchedule.EndDate-saveWorkingSchedule.StartDate) / 86400000 > 13">
                    3<sup>rd</sup>
                  </mat-option>
                  <mat-option value="4"
                    *ngIf="(saveWorkingSchedule.EndDate-saveWorkingSchedule.StartDate) / 86400000 > 20">
                    4<sup>th</sup>
                  </mat-option>
                  <mat-option value="5"
                    *ngIf="(saveWorkingSchedule.EndDate-saveWorkingSchedule.StartDate) / 86400000 > 27">
                    Last
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="week-day mb-20 d-flex align-items-center">
                <mat-checkbox mat-mini-fab *ngFor="let data of weekDays; let i = index" (change)="daydata($event,data)"
                  [value]="data.Days">{{data.day}}</mat-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary"
            (click)="saveMaster(saveWorkingSchedule, this.baseCompanyCore + 'WorkingScheduleSave')">
            <span *ngIf="action == 'AddWorkingSchedule'">Save</span>
            <span *ngIf="action == 'EditWorkingSchedule'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <div *ngIf="action == 'AddHolidays' || action == 'EditHolidays'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class="col-sm-12">
            <mat-radio-group [(ngModel)]="saveHolidayInput.WorkingType" name="WorkingType">
              <mat-radio-button value="one time">One Time</mat-radio-button>
              <!-- <mat-radio-button *ngIf="!isHoliday" value="Repeat">Repeat</mat-radio-button> -->
            </mat-radio-group>
          </div>

          <div class="col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Shift</mat-label>
              <mat-select class="form-control" [(ngModel)]="saveHolidayInput.WorkingShift" name="ShiftId"
                [compareWith]="compareFn" required>
                <fuse-mat-select-search name="search" [(ngModel)]="tree28">
                </fuse-mat-select-search>
                <mat-option *ngFor="let d_list of holidayShiftList | filter : tree28" [value]="d_list">
                  {{d_list.ShiftName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-sm-12" *ngIf="saveHolidayInput.WorkingType == 'one time'">
            <mat-label>Date</mat-label>
            <mat-form-field class="datePicker" floatLabel="never" appearance="outline">
              <!-- [min]="minDate" -->
              <input matInput [matDatepicker]="picker" (focus)="picker.open()" placeholder="Select Date"
                [(ngModel)]="saveHolidayInput.StartDate" name="StartDate" #StartDate="ngModel" required>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <!-- <div class="col-sm-12">
                        <div class="form-group" *ngIf="saveHolidayInput.WorkingType == 'Repeat'">
                            <label>Duration</label>
                            <div class="row twocol">
                                <div class="col-md-6 inbox">
                                    <mat-form-field class="datePicker" floatLabel="never" appearance="outline">
                                        <input matInput [matDatepicker]="picker" (focus)="picker.open()" placeholder="From" [(ngModel)]="saveHolidayInput.StartDate" name="StartDate" #StartDate="ngModel" required>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div> -->
          <!-- [min]="saveHolidayInput.StartDate" -->
          <!-- <div class="col-md-6 inbox">
                                    <mat-form-field class="datePicker" floatLabel="never" appearance="outline">
                                        <input matInput [matDatepicker]="picker1" (focus)="picker1.open()" placeholder="To" [(ngModel)]="saveHolidayInput.EndDate" name="EndDate" #EndDate="ngModel" required>
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div> -->

          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Holiday</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveHolidayInput.HolidayName"
                  name="Holiday" required>
              </mat-form-field>
            </div>
          </div>


          <div class=" col-sm-12" *ngIf="saveHolidayInput.WorkingType == 'Repeat'">
            <label>Day</label>
            <div class="d-flex flex-column every-week">
              <mat-form-field appearance="outline">
                <mat-select multiple placeholder="Every" [(ngModel)]="saveHolidayInput.RepeatEntry.WeekFequency"
                  name="RepeatEntry.WeekFequency">
                  <mat-option value="1" *ngIf="(saveHolidayInput.EndDate-saveHolidayInput.StartDate) / 86400000 > 0 ">
                    1<sup>st</sup>
                  </mat-option>
                  <mat-option value="2" *ngIf="(saveHolidayInput.EndDate-saveHolidayInput.StartDate) / 86400000 > 6 ">
                    2<sup>nd</sup>
                  </mat-option>
                  <mat-option value="3" *ngIf="(saveHolidayInput.EndDate-saveHolidayInput.StartDate) / 86400000 > 13">
                    3<sup>rd</sup>
                  </mat-option>
                  <mat-option value="4" *ngIf="(saveHolidayInput.EndDate-saveHolidayInput.StartDate) / 86400000 > 20">
                    4<sup>th</sup>
                  </mat-option>
                  <mat-option value="5" *ngIf="(saveHolidayInput.EndDate-saveHolidayInput.StartDate) / 86400000 > 27">
                    Last</mat-option>
                </mat-select>
              </mat-form-field>
              <div class="week-day mb-20 d-flex align-items-center">
                <mat-checkbox mat-mini-fab *ngFor="let data of weekDays; let i = index" (change)="daydata($event,data)"
                  [value]="data.Days" required>{{data.day}}
                </mat-checkbox>
              </div>
            </div>
          </div>

          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(saveHolidayInput, this.baseCompanyCore + 'HolidaySave')">
              <span *ngIf="action == 'AddHolidays'">Save</span>
              <span *ngIf="action == 'EditHolidays'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'AddWeeklyOff' || action == 'EditWeeklyOff'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class="col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Type</mat-label>
              <mat-select class="form-control" [(ngModel)]="saveWeeklyOffInput.WorkingShift" name="WorkingShift"
                [compareWith]="compareFn">
                <mat-option *ngFor="let d_list of shiftList" [value]="d_list">
                  {{d_list.ShiftName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- <div class="col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Start date</mat-label>
              <input matInput [matDatepicker]="StartDate" [(ngModel)]="saveWeeklyOffInput.StartDate" name="StartDate"
                required>
              <mat-datepicker-toggle matSuffix [for]="StartDate"></mat-datepicker-toggle>
              <mat-datepicker #StartDate></mat-datepicker>
            </mat-form-field>
          </div> -->

          <div class="col-sm-12 mt-n2">

            <label>Duration</label>
            <div class="row twocol">
              <div class="col-md-6 inbox">
                <mat-form-field class="datePicker" floatLabel="never" appearance="outline">
                  <input [min]="minDate" matInput [matDatepicker]="picker" (focus)="picker.open()"
                    placeholder="Select Date" [(ngModel)]="saveWeeklyOffInput.StartDate" name="StartDate"
                    #StartDate="ngModel" required>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-md-6 inbox" *ngIf="action == 'AddWeeklyOff'">
                <mat-form-field class="datePicker" floatLabel="never" appearance="outline">
                  <input [min]="scheduleoff.StartDate" matInput [matDatepicker]="picker1" (focus)="picker1.open()"
                    placeholder="Select Date" [(ngModel)]="saveWeeklyOffInput.EndDate" name="EndDate" #EndDate="ngModel"
                    required>
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>
            </div>

          </div>

          <div class="col-sm-12 mb-20 mt-n2" *ngIf="action == 'AddWeeklyOff'">
            <label>Day</label>
            <div class="d-flex every-week">
              <div class="week-day d-flex align-items-center">
                <mat-checkbox mat-mini-fab *ngFor="let data of weekDays; let i = index" (change)="daydata($event,data)"
                  [value]="data.Days">{{data.day}}</mat-checkbox>
              </div>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveMaster(saveWeeklyOffInput, this.baseCompanyCore + 'WeeklyOffSave')">
              <span *ngIf="action == 'AddWeeklyOff'">Save</span>
              <span *ngIf="action == 'EditWeeklyOff'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- Shift Start -->
    <div *ngIf="action == 'AddShift' || action == 'EditShift'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Shift</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="saveShiftInput.ShiftName" name="ShiftName"
                required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>FromTime</mat-label>
              <input matInput type="time" class="form-control" [(ngModel)]="saveShiftInput.FromTime" name="FromTime"
                required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>ToTime</mat-label>
              <input matInput type="time" class="form-control" [(ngModel)]="saveShiftInput.ToTime" name="ToTime"
                required>
            </mat-form-field>
          </div>
        </div>

        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster(saveShiftInput, this.baseCompanyCore + 'ShiftSave')">
            <span *ngIf="action == 'AddShift'">Save</span>
            <span *ngIf="action == 'EditShift'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- Shift End -->

    <!-- campaign source data modal popup -->
    <div *ngIf="action == 'AddSourceData' || action == 'EditSourceData'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Source Data</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="SourceDataSave.SourceDataName"
                  name="SourceData" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveSourceData()">
              <span *ngIf="action == 'AddSourceData'">Save</span>
              <span *ngIf="action == 'EditSourceData'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="action == 'AddCampaignType' || action == 'EditCampaignType'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Campaign Type</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="campaignTypeDataSave.CampaignTypeName"
                  name="CampaignType" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveCampaign()">
              <span *ngIf="action == 'AddCampaignType'">Save</span>
              <span *ngIf="action == 'EditCampaignType'">Update</span>
            </button>
          </div>
        </form>
      </div>
    </div>





    <div *ngIf="action == 'addDeliverable'">
      <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
          autocomplete="off">
          <div class="col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Estimation Tab</mat-label>
              <mat-select class="form-control" [compareWith]="compareFn"
                (selectionChange)="ServiceTaskSelectCategory($event, 'activity')"
                [(ngModel)]="saveServiceTaskInput.Category" [(value)]="saveServiceTaskInput.Category" disabled
                name="ServiceCategory">
                <fuse-mat-select-search name="search" [(ngModel)]="tree29">
                </fuse-mat-select-search>
                <mat-option *ngFor="let cat_list of ServiceTaskCategoryDropDownList | filter: tree29"
                  [value]="cat_list">
                  {{cat_list.CategoryName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>{{modalData.customData.type.CategoryName == 'Efforts' ? 'Activity' :
                  'Category'}}
                </mat-label>
                <mat-select class="form-control" [compareWith]="compareFn"
                  (selectionChange)="ServiceTaskSelectCategory($event, 'subactivity')"
                  [(ngModel)]="saveServiceTaskInput.ServiceActivity" [(value)]="saveServiceTaskInput.ServiceActivity"
                  name="ServiceActivity" [disabled]="modalData.customData.type.CategoryName == 'Efforts'">
                  <fuse-mat-select-search name="category" [(ngModel)]="searchType">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let list of ServiceActivityList | filter: searchType" [value]="list">
                    {{list.ServiceActivityName}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>{{modalData.customData.type.CategoryName == 'Efforts' ? 'Sub Activity' :
                  'Sub-Category'}}
                </mat-label>
                <mat-select class="form-control" [(ngModel)]="saveServiceTaskInput.ServiceSubActivity"
                  name="ServiceSubActivity" [compareWith]="compareFn"
                  [disabled]="modalData.customData.type.CategoryName == 'Efforts'">
                  <fuse-mat-select-search name="search" [(ngModel)]="searchSubcategory">
                  </fuse-mat-select-search>
                  <mat-option *ngFor="let list of ServiceSubActivityList | filter: searchSubcategory" [value]="list">
                    {{list.ServiceSubActivityName}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-12">
            <div class="" class="d-matlab">
              <mat-form-field appearance="outline">
                <mat-label>Title</mat-label>
                <input matInput type="text" class="form-control" [(ngModel)]="saveServiceTaskInput.ServiceTaskName"
                  name="ServiceTaskName" required>
              </mat-form-field>
            </div>
          </div>
          <div class=" col-sm-4">
            <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
              (click)="saveServiceTaskEvt()"> Save </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="action == 'timeModel'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class="col-md-12 mt-4">
          <div class="alpha_list">
            <div class="name_list row">
              <div class="about_employ col-sm-12">
                <div class="user_img">
                  <img [src]="modalData?.content?.Employee?.EmployeeImagePath" alt=""
                    style="width: 47px; height: 47px; border-radius: 50%;">
                </div>
                <div class="user_name">
                  <h5>{{modalData?.content?.EmployeeName}}</h5>
                  <h6>{{modalData?.content?.Department?.DepartmentName}}</h6>
                </div>
              </div>
              <p class=" col-sm-5">{{modalData?.content?.EndDateTime}}</p>
            </div>
            <!-- <div class="d-flex">
                            <div class="timemodel col-7">
                                <p>In time</p>
                            </div>
                            <div class="timemodel col-5">
                                <p>Out time</p>
                            </div>

                            <div *ngFor="let item of modalData?.content?.InOutTimeList">
                                <div class="timemodel col-7">
                                    <p>{{item}}</p>
                                </div>
                                <div class="timemodel col-7">
                                    <p>7:00 PM</p>
                                </div>
                            </div>

                        </div> -->
            <div class="d-flex">
              <div class="timemodel col-7">
                <p><strong>In time</strong></p>
                <div *ngFor="let item of inList">
                  <div *ngIf="item.GoingType == true">
                    <p>{{item?.StartDate | date:'h:mm:ss a'}}</p>
                  </div>
                </div>
              </div>
              <div class="timemodel col-5">
                <p><strong>Out time</strong></p>
                <div *ngFor="let item of OutList">
                  <div *ngIf="item.GoingType == false">
                    <p>{{item?.StartDate | date:'h:mm:ss a'}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="action == 'SubmitDeliverable' || action == 'ViewDeliverable'">
      <form class="row " autocomplete="off" (ngSubmit)="f.form.valid && onSubmitDel()" #f="ngForm" novalidate
        autocomplete="off">
        <div class=" col-sm-12" [class.viewdata-only]="action == 'ViewDeliverable'">
          <div class="d-matlab">
            <mat-label>Delivered as per Scope</mat-label>
            <div style="margin: 5px 10px 20px 0;">
              <mat-radio-group class="form-control" [(ngModel)]="modelDeliver.DeliveredAsPerScope"
                name="DeliveredAsPerScope" required>
                <mat-radio-button [value]="true" style="margin-right: 15px;">Perfect</mat-radio-button>
                <mat-radio-button [value]="false">With Deviation</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div class=" col-sm-12" *ngIf="modelDeliver.DeliveredAsPerScope == false "
          [class.viewdata-only]="action == 'ViewDeliverable'">
          <div class="d-matlab">
            <mat-form-field appearance="outline" class="h_auto">
              <mat-label>Reason</mat-label>
              <textarea matInput type="text" class="form-control" [(ngModel)]="modelDeliver.DeliveredAsPerScopeReason"
                name="DeliveredAsPerScopeReason" required></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-12" [class.viewdata-only]="action == 'ViewDeliverable'">
          <div class="d-matlab">
            <mat-label>Delivered as per Schedule</mat-label>
            <div style="margin: 5px 10px 20px 0;">
              <mat-radio-group class="form-control" [(ngModel)]="modelDeliver.DeliveredAsPerSchedule"
                name="DeliveredAsPerSchedule" required>
                <mat-radio-button [value]="true" style="margin-right: 15px;">Perfect</mat-radio-button>
                <mat-radio-button [value]="false">With Deviation</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div class=" col-sm-12" *ngIf="modelDeliver.DeliveredAsPerSchedule  == false"
          [class.viewdata-only]="action == 'ViewDeliverable'">
          <div class="d-matlab">
            <mat-form-field appearance="outline" class="h_auto">
              <mat-label>Reason</mat-label>
              <textarea matInput type="text" class="form-control"
                [(ngModel)]="modelDeliver.DeliveredAsPerScheduleReason" name="DeliveredAsPerScheduleReason"
                required></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-12" [class.viewdata-only]="action == 'ViewDeliverable'">
          <div class="d-matlab">
            <mat-label>Delivered as per Cost/Budget</mat-label>
            <div style="margin: 5px 10px 20px 0;">
              <mat-radio-group class="form-control" [(ngModel)]="modelDeliver.DeliveredAsPerCost"
                name="DeliveredAsPerCost" required>
                <mat-radio-button [value]="true" style="margin-right: 15px;">Perfect</mat-radio-button>
                <mat-radio-button [value]="false">With Deviation</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div class=" col-sm-12" *ngIf="modelDeliver.DeliveredAsPerCost == false"
          [class.viewdata-only]="action == 'ViewDeliverable'">
          <div class="d-matlab">
            <mat-form-field appearance="outline" class="h_auto">
              <mat-label>Reason</mat-label>
              <textarea matInput type="text" class="form-control" [(ngModel)]="modelDeliver.DeliveredAsPerCostReason"
                name="DeliveredAsPerCostReason" required></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="document_add">
          <div class="col-md-12" *ngIf="action == 'SubmitDeliverable'">
            <h5>Attach File</h5>
          </div>
          <div class="col-md-12" *ngIf="action == 'ViewDeliverable' && modelDeliver?.AttachFiles?.length > 0">
            <h5>Attach File</h5>
          </div>
          <div class="col-md-12 attachment-bospace">
            <ng-container *ngIf="action == 'SubmitDeliverable'">
              <div class="attach_file position-relative">
                <img src="/assets/icons/attach_icon.svg" alt=""> Browse or drop file here
                <input type="file" (change)="UploadMultiple($event, imgUploadJson)" class="upload" name="fileUpload">
              </div>
              <mat-error *ngIf="fileSizeError">{{fileSizeError}}</mat-error>
              <!-- upload process bar -->
              <ng-container *ngIf="imgUploadJson?.percentDone && imgUploadJson?.percentDone != 100">
                <mat-progress-bar [mode]="imgUploadJson?.percentDone ? 'buffer' : 'buffer'"
                  [value]="imgUploadJson?.percentDone" style="margin-top: 20px;">
                </mat-progress-bar>
                <small>Uploading... ({{imgUploadJson?.percentDone}}%)</small>
              </ng-container>
            </ng-container>

            <!-- Attachment -->
            <div class="labels_listing" *ngIf="modelDeliver?.AttachFiles?.length > 0">
              <p class="attachfile d-flex justify-content-between align-items-center"
                *ngFor="let item of modelDeliver.AttachFiles; let i=index">
                <span class="d-flex align-items-center" style="cursor: pointer;">
                  <img src="/assets/images/{{item?.FileExe}}-icon.png" alt="" class="mr-3">
                  <a (click)="downloadMyFile(item, item.DocumentFileName)">{{item?.DocumentFileName}}7456</a>
                </span>
                <span class="d-flex align-items-center" *ngIf="action == 'SubmitDeliverable'">
                  <!-- 498 kb -->
                  <mat-icon class="pointer" (click)="uploadRemoveMultiple(modelDeliver.AttachFiles, i)" style="position: relative;
                                    top: 2px;
                                    margin-left: 10px;">
                    close
                  </mat-icon>
                </span>
              </p>
            </div>
          </div>
        </div>
        <form (ngSubmit)="rf.form.valid" #rf="ngForm" class="wid-100"
          [class.viewdata-only]="action == 'ViewDeliverable'"
          *ngIf="action == 'SubmitDeliverable' || (action == 'ViewDeliverable' && modelDeliver?.Risks[0]?.Comment)">
          <div class="col-md-12 mt-2">
            <h2 style="color: #fc7a09;">Risks/Concerns, if any</h2>
          </div>
          <div class="col-sm-12" *ngFor="let risk of modelDeliver?.Risks; let i =index">
            <div class="d-matlab">

              <mat-form-field appearance="outline" class="h_auto">
                <textarea matInput type="text" placeholder="Add Comment here.." class="form-control"
                  [(ngModel)]="risk.Comment" name="Comment_{{i}}" required></textarea>
              </mat-form-field>
              <p class="text-right mb-0" *ngIf="modelDeliver?.Risks.length > 1" style="margin-top: -20px;">
                <button (click)="removeComment(modelDeliver?.Risks,i)"
                  class="text-danger align-content-center cursor-pointer h5">
                  <mat-icon class="h5">delete</mat-icon>Delete
                </button>
              </p>
            </div>
          </div>
          <div class="col-sm-12" *ngIf="action == 'SubmitDeliverable'">
            <button class="link-addmoreitem " (click)="addMoreComment()">
              <mat-icon>add_circle</mat-icon> Add More
            </button>
          </div>
        </form>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="f.form.invalid">
            <span *ngIf="action == 'SubmitDeliverable'">Save</span>
            <!-- <span *ngIf="action == 'EditStatus'">Update</span> -->
          </button>
        </div>

      </form>

    </div>

    <!-- Generated Link -->
    <div *ngIf="action == 'genrateLink'">
      <p>{{modalData?.url}}</p>
      <button mat-flat-button mat-primary color="primary" [cdkCopyToClipboard]="modalData.url" (click)="copyTextValue()"
        [ngStyle]="{'background-color' : copyText == 'Copied' ? '#2ad400' : '#2075e9'}">{{copyText}}</button>
    </div>
    <!-- popup attendence log -->
    <div *ngIf="action == 'popup'">
      <p style="margin-bottom: 20px;">Are you sure you want to Approve</p>
      <button mat-flat-button mat-danger color="warn">Approve</button>
    </div>
    <!-- popup leave component -->
    <div *ngIf="action == 'leaveView'" class="leaveView">
      <div class="d-flex justify-content-between">
        <div class="main_C  d-flex">
          <img src="{{leavDataShow?.Employee?.EmployeeImagePath}}" *ngIf="leavDataShow?.Employee?.EmployeeImagePath">
          <img src="/assets/images/user.png" *ngIf="!leavDataShow?.Employee?.EmployeeImagePath">
          <div class="emp_C ml-3 d-block">
            <h3>{{leavDataShow?.Employee?.EmployeeName}}</h3>
            <h4 class="m-0">{{leavDataShow?.Employee?.Designation}}</h4>
          </div>
        </div>
        <div>
          <div class="aply_c">
            <!-- <img src="/assets/images/user.png"> -->
            <h3>Applied On</h3>
          </div>
          <p style="margin: -4px 0 0 9px; font-size: 14px;">
            {{leavDataShow?.CreatedBy?.CreatedDate | date}} at {{leavDataShow?.CreatedBy?.CreatedDate |
            date:'h:mm a'}} </p>
        </div>
      </div>
      <mat-tab-group [(selectedIndex)]="selectedIndex">
        <mat-tab label="SUMMARY">
          <div class="mt-4 summary_C ">
            <div class="time_C ml-3 mb-3">
              <p class="ml-2 ">From: <span class="ml-2"><b>{{leavDataShow?.FromDate | date}}</b></span>
              </p>
              <p style="margin-left: 23px;">To: <span class="ml-2"><b>{{leavDataShow?.ToDate |
                    date}}</b></span></p>
              <div class="d-flex">
                <p style="margin-left: 8px;">Type:</p>
                <span style="margin-left: 8px;">
                  <p><b>{{leavDataShow?.LeaveType.LeaveTypeName}}</b></p>
                </span>
              </div>

              <div class="d-flex">
                <p style="margin-left: -8px;">Reason:</p>
                <span style="margin-left: 8px;">
                  <p><b>{{leavDataShow?.LeaveReason}}</b></p>
                </span>
              </div>
            </div>
            <div class="content">

              <section class="example-section">
                <mat-checkbox [checked]="true"></mat-checkbox>There are <a
                  (click)="nextTab($event)">{{leavDataShow?.LeaveTaskList?.length}} tasks </a>
                assigned in this duration
              </section>
              <div class="contact_C">
                <label class="labCon">Contact detail for leave period</label>
                <label>Contact No.</label>
                <p><b>{{leavDataShow?.CountryCode}}-{{leavDataShow?.LeaveContactNo}}</b></p>
                <label class="mt-3">Address</label>
                <p><b> {{leavDataShow?.Address}}, {{leavDataShow?.City?.CityName}}
                    {{leavDataShow?.State?.StateName}} {{leavDataShow?.Country?.CountryName}}</b>
                </p>
              </div>
            </div>
          </div>


        </mat-tab>
        <mat-tab label="TASK" *ngIf="leavDataShow?.LeaveTaskList?.length > 0">
          <div class="mb-20"></div>
          <div class="row mt-20 tasklist" *ngFor="let item of leavDataShow.LeaveTaskList">
            <div class="col-md-6">
              <label>Date</label>
              <p><b> {{item.FromDate |date}} To {{item.ToDate |date}}</b></p>
            </div>
            <div class="col-md-6">
              <label>Project/Service</label>
              <p><b>{{item?.Service?.ServiceName ? item?.Service?.ServiceName :'NA' }}</b></p>
            </div>
            <div class="col-md-6">
              <label>Task</label>
              <p><b>{{item?.TaskName ? item?.TaskName : 'NA'}} </b></p>
            </div>
            <div class="col-md-6">
              <label>Project Manager</label>
              <p><b>{{item?.AssignBy?.EmployeeName ? item?.AssignBy?.EmployeeName : 'NA'}} </b></p>
            </div>
          </div>

        </mat-tab>

      </mat-tab-group>
      <div class="bordertop justify-content-between d-flex" *ngIf="modalData.type != 'ApprovebyPM'">
        <div class="userinfo ml-3">
          <label>Status </label>
          <span [ngClass]="{'text-success': leavDataShow?.LeaveStatus?.StatusId == 101,
                        'text-danger': leavDataShow?.LeaveStatus?.StatusId == 102 || leavDataShow?.LeaveStatus?.StatusId == 105,
                        'text-warning': leavDataShow?.LeaveStatus?.StatusId == 100}">
            <b>{{leavDataShow?.LeaveStatus?.StatusName}}</b></span>
        </div>
        <div *ngIf="istrue && leaveapprove">
          <button *ngIf="leavDataShow?.LeaveStatus?.StatusId == 100" mat-raised-button color="accent"
            style="line-height: 35px;" type="submit" class="mat_btn mt-1 mr-3"
            (click)="SaveRequest('Approve')">Approve</button>
          <button *ngIf="leavDataShow?.LeaveStatus?.StatusId == 100" mat-raised-button color="warn" type="submit"
            class="mat_btn mt-1" (click)="SaveRequest('Reject')">Reject</button>
        </div>
      </div>
      <div class="bordertop justify-content-between d-flex" *ngIf="modalData.type == 'ApprovebyPM'">
        <div class="userinfo ml-3">
          <label>Status </label>
          <span [ngClass]="{'text-success': leaveitem?.LeaveStatus?.StatusId == 101,
                                 'text-danger': leaveitem?.LeaveStatus?.StatusId == 102 ||leaveitem?.LeaveStatus?.StatusId == 105 ,
                                 'text-warning': leaveitem?.LeaveStatus?.StatusId == 100}">
            <b> {{leaveitem?.LeaveStatus?.StatusName}}</b></span>

        </div>
        <div *ngIf="istrue">
          <button *ngIf="leaveitem?.LeaveStatus?.StatusId == 100" mat-raised-button color="accent"
            style="line-height: 35px;" type="submit" class="mat_btn mt-1 mr-3"
            (click)="SaveRequest('Approve')">Approve</button>
          <button *ngIf="leaveitem?.LeaveStatus?.StatusId == 100" mat-raised-button color="warn" type="submit"
            class="mat_btn mt-1" (click)="SaveRequest('Reject')">Reject</button>


        </div>
      </div>

    </div>
    <div class="Extend" *ngIf="action == 'addExtend'">
      <div class="service_detail">
        <label>Date Of Joining</label>
        <p>{{modalData.data.DateOfJoining |date}} </p>
      </div>
      <div class="probLabel">
        <div class="skilSet">
          <div class="row">
            <div class="col-sm-12">
              <h6>Probation Period</h6>
            </div>
            <div class="col-sm-12">
              <div class="form-row col-md-8 pl-0">
                <div class="d-flex">
                  <mat-form-field appearance="outline" class="mat-field-leftbx ">
                    <!-- <mat-label>Percentage
                                    </mat-label> -->

                    <mat-select [(ngModel)]="ExtendMonth" name="ExtendMonth" [compareWith]="compareFn"
                      (selectionChange)="getLastDate()">
                      <mat-option [value]="list" *ngFor="let list of monthDropDown">{{list.Month}}
                      </mat-option>
                    </mat-select>

                  </mat-form-field>
                  <span class="percent">Months</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="service_detail">
        <label>Probation Period End Date</label>
        <p>{{modalData.data.ProbationEndDate |date}} </p>
      </div>
      <div>
        <button mat-flat-button color="primary" type="submit" class="mat_btn mt-1 mr-3"
          (click)="Extend()">Extend</button>
      </div>
    </div>

    <!-- Sandwich Detail -->
    <div class="sandwichbx" *ngIf="action == 'sandwichDetail'">
      <div class="d-flex mb-3">
        <div class="emp-img">
          <img
            src="{{modalData?.data.Employee?.EmployeeImagePath ? modalData?.data.Employee?.EmployeeImagePath : '/assets/images/user.png'}}"
            alt="" style="height: 45px; width: 45px;">
        </div>
        <div class="emp-name">
          <p style="font-weight: 600;margin: 0px; font-size: 13px !important;">
            {{modalData?.data?.Employee?.EmployeeName}}</p>
          <p class="emp-code">
            {{modalData?.data?.Employee?.EmployeeCode}}</p>
        </div>
      </div>
      <article>
        <p class="d-flex justify-content-between align-items-center" *ngFor="let list of modalData?.data?.SandWichDate">
          {{list?.FromDate | date}} to {{list?.EndDate | date}} ({{list?.TotalSandWichDays}} Day) <a
            (click)="sandwichcancel(list)">Cancel</a>
        </p>
      </article>
      <!-- <article>
                <p class="d-flex justify-content-between align-items-center">
                    10/12/2022 to 20/12/2022 (1 Day) <a>Cancel</a>
                </p>
            </article> -->
    </div>
    <!-- CampaignGroup Start -->
    <div *ngIf="action == 'AddCampaignGroup' || action == 'EditCampaignGroup'">
      <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
        autocomplete="off">

        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Campaign Group</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="saveCampaignGroupInput.CampaignGroupName"
                name="CampaignGroupName" required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveCampaignGroups()">
            <span *ngIf="action == 'AddCampaignGroup'">Save</span>
            <span *ngIf="action == 'EditCampaignGroup'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- CampaignGroup End -->
    <!-- Mode of Conveyance -->
    <div *ngIf="action == 'AddModeofConveyance' || action == 'EditModeofConveyance'">
      <form class="row " (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate novalidate autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <div class="lead_type" style="margin-bottom: 20px;">
              <label class="formlabel">Conveyance Mode Type</label>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="saveConveyanceInput.ConveyanceTypeid" name="ConveyanceTypeid"
                (change)="conveyanceType($event)">
                <mat-radio-button class="example-radio-button" color="primary" [value]="btn.value"
                  [checked]="btn.checked" *ngFor="let btn of ConveyanceMode">
                  {{btn.mode}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <mat-form-field appearance="outline">
              <mat-label>Name</mat-label>
              <input matInput type="text" [(ngModel)]="saveConveyanceInput.ConveyanceName" name="ConveyanceName"
                class="form-control" required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster(saveConveyanceInput, baseExpenseConfg + 'ModeofConveyanceSave')">
            <span *ngIf="action == 'AddModeofConveyance'">Save</span>
            <span *ngIf="action == 'EditModeofConveyance'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- consumable -->
    <div *ngIf="action == 'AddConsumable' || action == 'EditConsumable' ">
      <form class="row " (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">

        <div class=" col-sm-12">
          <div class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Consumable</mat-label>
              <input matInput type="text" class="form-control" [(ngModel)]="saveConsumableInput.ConsumableClassName"
                name="ConsumableClassName" required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster(saveConsumableInput, baseConsumableConfg + 'ConsumableClassSave')">
            <span *ngIf="action == 'AddConsumable'">Save</span>
            <span *ngIf="action == 'EditConsumable'">Update</span>
          </button>

        </div>
      </form>
    </div>
    <!-- Addassetclass -->
    <div *ngIf="action == 'Addassetclass' || action == 'Editassetclass'">
      <form class="row " (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">

        <div class=" col-sm-12">
          <div class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Asset Class</mat-label>
              <input matInput type="text" class="form-control" required [(ngModel)]="saveAssetInput.AssetClassName"
                name="AssetClassname">
            </mat-form-field>

          </div>

        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster(saveAssetInput, this.baseAssetConfg + 'AssetClassSave')">
            <span *ngIf="action == 'Addassetclass'">Save</span>
            <span *ngIf="action == 'Editassetclass'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- AddTerms&Conditions -->
    <div *ngIf="action == 'AddTerms&Conditions' || action == 'EditTerms&Conditions'">
      <form class="row " (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">

        <div class=" col-sm-12">
          <div class="d-matlab">
            <mat-form-field appearance="outline" class="h_auto">
              <mat-label>Terms & Conditions</mat-label>
              <textarea matInput type="text" class="form-control" required [(ngModel)]="saveAssetInput.AssetClassName"
                name="AssetClassname"></textarea>
            </mat-form-field>

          </div>

        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster(saveAssetInput, this.baseAssetConfg + 'AssetClassSave')">
            <span *ngIf="action == 'AddTerms&Conditions'">Save</span>
            <span *ngIf="action == 'EditTerms&Conditions'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- Addassetcategory -->
    <div *ngIf="action == 'Addassetcategory' || action == 'Editassetcategory'">
      <form class="row " (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Asset Class</mat-label>
              <mat-select required [(ngModel)]="saveAssetInput.AssetClassId" name="AssetClassId">
                <mat-option *ngFor="let list of AssetClassDropDown" [value]="list.AssetClassId">
                  {{list.AssetClassName}}</mat-option>
              </mat-select>

              <!-- <mat-select [(ngModel)]="ExtendMonth" name="ExtendMonth" [compareWith]="compareFn" (selectionChange)="getLastDate()">
                                <mat-option [value]="list" *ngFor="let list of monthDropDown">{{list.Month}}
                                </mat-option>
                            </mat-select> -->
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Asset Category</mat-label>
              <input matInput type="text" class="form-control" required [(ngModel)]="saveAssetInput.AssetCategoryName"
                name="AssetCategoryName">
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster(saveAssetInput, this.baseAssetConfg + 'AssetCategorySave')">
            <span *ngIf="action == 'Addassetcategory'">Save</span>
            <span *ngIf="action == 'Editassetcategory'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- Add consumable category -->
    <div *ngIf="action == 'Addconsumablecategory' || action == 'Editconsumablecategory'">
      <form class="row " (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Consumable Category</mat-label>
              <input matInput type="text" class="form-control" required
                [(ngModel)]="saveConsumableCatInput.ConsumableCategoryName" name="ConsumableCategoryName">
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster(saveConsumableCatInput, this.baseConsumableConfg + 'ConsumableCategorySave')">
            <span *ngIf="action == 'Addconsumablecategory'">Save</span>
            <span *ngIf="action == 'Editconsumablecategory'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- Add consumable Items -->
    <div *ngIf="action == 'Addconsumableitem' || action == 'Editconsumableitem'">
      <form class="row " (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">
        <div class="col-sm-12">
          <div class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Consumable Category</mat-label>
              <mat-select required [(ngModel)]="saveConsumableItemInput.ConsumableCategoryId"
                name="ConsumableCategoryId">
                <mat-option *ngFor="let list of consumableCategoryList" [value]="list.ConsumableCategoryId">
                  {{list.ConsumableCategoryName}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Consumable Item</mat-label>
              <input matInput type="text" class="form-control" required
                [(ngModel)]="saveConsumableItemInput.ConsumableItemName" name="ConsumableItemName">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster(saveConsumableItemInput, this.baseConsumableConfg + 'ConsumableItemSave')">
            <span *ngIf="action == 'Addconsumableitem'">Save</span>
            <span *ngIf="action == 'Editconsumableitem'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- Addassetitem -->
    <div *ngIf="action == 'Addassetitem' || action == 'Editassetitem'">
      <form class="row " (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">

        <div class=" col-sm-12">
          <div class="d-matlab">
            <div class="mb-3">
              <mat-checkbox style="margin-bottom:15px;" [(ngModel)]="saveAssetItemInput.IsComponent" name="IsComponent">
                <p class="m-0"> Is Component ?</p>
              </mat-checkbox>
            </div>

            <div class="d-flex">
              <mat-form-field appearance="outline" style="width: 50%;">
                <mat-label>Asset Class
                </mat-label>
                <mat-select (selectionChange)="changeAssetClass($event.value)"
                  [(ngModel)]="saveAssetItemInput.AssetClassId" name="AssetClassId" required>
                  <mat-option *ngFor="let list of AssetClassDropDown" [value]="list.AssetClassId">
                    {{list.AssetClassName}}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" class="ml-3" style="width: 50%;">
                <mat-label>Asset Category </mat-label>
                <mat-select required [(ngModel)]="saveAssetItemInput.AssetCategoryId" name="AssetCategoryId">
                  <mat-option *ngFor="let list of AssetCategoryList" [value]="list.AssetCategoryId">
                    {{list.AssetCategoryName}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <mat-form-field appearance="outline">
              <mat-label>Item</mat-label>
              <input matInput type="text" class="form-control" required [(ngModel)]="saveAssetItemInput.AssetItemName"
                name="AssetItemName">
            </mat-form-field>

            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" name="modePayment"
              [(ngModel)]="saveAssetItemInput.IsAssignable">
              <mat-radio-button [value]="true">Assignable</mat-radio-button>
              <mat-radio-button [value]="false">Non-Assignable</mat-radio-button>
            </mat-radio-group>

            <p class="maintenance-label">Maintenance Frequency</p>

            <div *ngFor="let item of saveAssetItemInput.MaintenanceFrequency;let last = last; let i = index">
              <div class="d-flex">
                <mat-form-field appearance="outline">
                  <mat-label>Duration</mat-label>
                  <input matInput type="number" [(ngModel)]="item.MaintenanceFrequencyDuration"
                    name="MaintenanceFrequencyDuration_{{i}}" class="form-control" required>
                </mat-form-field>


                <mat-form-field appearance="outline" class="ml-3">
                  <mat-label>Unit</mat-label>
                  <mat-select class="form-control" [(ngModel)]="item.MaintenanceFrequencyunit"
                    name="MaintenanceFrequencyunit_{{i}}" required>
                    <fuse-mat-select-search name="search" [(ngModel)]="tree36">
                    </fuse-mat-select-search>
                    <mat-option *ngFor="let list of durationListData | filter: tree36" [value]="list.DurationUnitId">
                      {{list.DurationUnitName}}</mat-option>
                  </mat-select>
                </mat-form-field>

              </div>

              <mat-form-field class="example-chip-list h_auto" appearance="outline">
                <mat-label>Scope of work</mat-label>
                <textarea matInput type="text" class="form-control" [(ngModel)]="item.ScopeOfWork"
                  name="ScopeOfWork_{{i}}" required style="height:80px;"></textarea>

              </mat-form-field>
              <div class="col-md-12 link-addmoreitem">
                <button *ngIf="last" mat-flat-button mat-primary color="primary" class="more_barnchAdd"
                  (click)="mainfrequency()">
                  <mat-icon>add</mat-icon> Add More
                </button>
                <button class="deleteBtn" type="button" *ngIf="saveAssetItemInput.MaintenanceFrequency.length !=1"
                  (click)="removeDetail(saveAssetItemInput.MaintenanceFrequency, i)">
                  Remove
                </button>
              </div>
            </div>

            <div class="">
              <div class="mt-16 mb-16">
                <!-- <mat-checkbox (click)="auditfrequency($event)" [(ngModel)]="saveAssetItemInput.AuditRequired" name="AuditRequired"> -->
                <p class="maintenance-label m-0"> Audit Frequency</p>
                <!-- </mat-checkbox> -->
              </div>
              <mat-checkbox class="mb-2 d-block" [checked]="saveAssetItemInput?.IsCountingAuditFrequency"
                (change)="auditfrcheck($event, 'isCounting')">
                <p class="m-0">Counting</p>
              </mat-checkbox>
              <div class="d-block" *ngIf="saveAssetItemInput?.IsCountingAuditFrequency">

                <div class="d-flex">
                  <mat-form-field appearance="outline">
                    <mat-label>Duration</mat-label>
                    <input matInput type="number" class="form-control"
                      [(ngModel)]="saveAssetItemInput.CountingAuditFrequency.MaintenanceFrequencyDuration"
                      name="MaintenanceFrequencyDuration1" required>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="ml-3">
                    <mat-label>Unit</mat-label>
                    <mat-select class="form-control"
                      [(ngModel)]="saveAssetItemInput.CountingAuditFrequency.MaintenanceFrequencyunit"
                      name="MaintenanceFrequencyunit1" required>
                      <fuse-mat-select-search name="search" [(ngModel)]="tree37">
                      </fuse-mat-select-search>
                      <mat-option *ngFor="let list of durationListData | filter: tree37" [value]="list.DurationUnitId">
                        {{list.DurationUnitName}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <mat-form-field class="example-chip-list h_auto" appearance="outline">
                  <mat-label>Scope of work</mat-label>
                  <textarea matInput type="text" class="form-control"
                    [(ngModel)]="saveAssetItemInput.CountingAuditFrequency.ScopeOfWork" name="ScopeOfWork1" required
                    style="height:80px;"></textarea>

                </mat-form-field>

              </div>
              <mat-checkbox class="mb-2 d-block" [checked]="saveAssetItemInput?.IsHealthCheckAuditFrequency "
                (change)="auditfrcheck($event, 'isHealth')">
                <p class="m-0">Health Check</p>
              </mat-checkbox>
              <div class="d-block" *ngIf="saveAssetItemInput?.IsHealthCheckAuditFrequency">
                <div class="d-flex">
                  <mat-form-field appearance="outline">
                    <mat-label>Duration</mat-label>
                    <input matInput type="number" class="form-control"
                      [(ngModel)]="saveAssetItemInput.HealthCheckAuditFrequency.MaintenanceFrequencyDuration"
                      name="MaintenanceFrequencyDuration2" required>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="ml-3">
                    <mat-label>Unit</mat-label>
                    <mat-select class="form-control"
                      [(ngModel)]="saveAssetItemInput.HealthCheckAuditFrequency.MaintenanceFrequencyunit"
                      name="MaintenanceFrequencyunit2" required>
                      <fuse-mat-select-search name="search" [(ngModel)]="tree37">
                      </fuse-mat-select-search>
                      <mat-option *ngFor="let list of durationListData | filter: tree37" [value]="list.DurationUnitId">
                        {{list.DurationUnitName}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <mat-form-field class="example-chip-list h_auto" appearance="outline">
                  <mat-label>Scope of work</mat-label>
                  <textarea matInput type="text" class="form-control"
                    [(ngModel)]="saveAssetItemInput.HealthCheckAuditFrequency.ScopeOfWork" name="ScopeOfWork2" required
                    style="height:80px;"></textarea>

                </mat-form-field>
              </div>
              <mat-checkbox class="mb-2 d-block" [checked]="saveAssetItemInput?.IsConfigurationAuditFrequency"
                (change)="auditfrcheck($event, 'isConf')">
                <p class="m-0">Configuration</p>
              </mat-checkbox>
              <div class="d-block" *ngIf="saveAssetItemInput?.IsConfigurationAuditFrequency">
                <div class="d-flex">
                  <mat-form-field appearance="outline">
                    <mat-label>Duration</mat-label>
                    <input matInput type="number" class="form-control"
                      [(ngModel)]="saveAssetItemInput.ConfigurationAuditFrequency.MaintenanceFrequencyDuration"
                      name="MaintenanceFrequencyDuration3" required>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="ml-3">
                    <mat-label>Unit</mat-label>
                    <mat-select class="form-control"
                      [(ngModel)]="saveAssetItemInput.ConfigurationAuditFrequency.MaintenanceFrequencyunit"
                      name="MaintenanceFrequencyunit3" required>
                      <fuse-mat-select-search name="search" [(ngModel)]="tree37">
                      </fuse-mat-select-search>
                      <mat-option *ngFor="let list of durationListData | filter: tree37" [value]="list.DurationUnitId">
                        {{list.DurationUnitName}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <mat-form-field class="example-chip-list h_auto" appearance="outline">
                  <mat-label>Scope of work</mat-label>
                  <textarea matInput type="text" class="form-control"
                    [(ngModel)]="saveAssetItemInput.ConfigurationAuditFrequency.ScopeOfWork" name="ScopeOfWork" required
                    style="height:80px;"></textarea>

                </mat-form-field>
              </div>
              <ng-container *ngIf="saveAssetItemInput.AuditRequired == true">
                <!-- <div class="d-flex">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Duration</mat-label>
                                        <input matInput type="number" class="form-control" [(ngModel)]="saveAssetItemInput.MaintenanceFrequencyDuration" name="MaintenanceFrequencyDuration" required>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="ml-3">
                                        <mat-label>Unit</mat-label>
                                        <mat-select class="form-control" [(ngModel)]="saveAssetItemInput.MaintenanceFrequencyunit" name="MaintenanceFrequencyunit" required>
                                            <fuse-mat-select-search name="search" [(ngModel)]="tree37">
                                            </fuse-mat-select-search>
                                            <mat-option *ngFor="let list of durationListData | filter: tree37" [value]="list.DurationUnitId">
                                                {{list.DurationUnitName}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div> -->

                <!-- <mat-form-field class="example-chip-list h_auto" appearance="outline">
                                    <mat-label>Scope of work</mat-label>
                                    <textarea matInput type="text" class="form-control" [(ngModel)]="saveAssetItemInput.ScopeOfWork" name="ScopeOfWork" required style="height:80px;"></textarea>
                                </mat-form-field> -->
                <!-- <mat-chip-list #chipList>
                                        <mat-chip *ngFor="let scope of freqscopelist" (removed)="freqremove(scope)">
                                            {{scope.name}}
                                            <button matChipRemove>
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </mat-chip>
                                        <input placeholder="Scope" [matChipInputFor]="chipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            [matChipInputAddOnBlur]="addOnBlur"
                                            (matChipInputTokenEnd)="freqadd($event)">
                                    </mat-chip-list> -->
              </ng-container>
            </div>
          </div>

        </div>

        <div class="col-sm-4 mt-3">

          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster(saveAssetItemInput, this.baseAssetConfg + 'AssetItemSave')">
            <span *ngIf="action == 'Addassetitem'">Save</span>
            <span *ngIf="action == 'Editassetitem'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- AddAssetAttribute -->
    <div *ngIf="action == 'AddAssetAttribute' || action == 'EditAssetAttribute'">
      <form class="row " (ngSubmit)="ngF.form.valid" #ngF="ngForm" novalidate autocomplete="off">

        <div class=" col-sm-12">
          <div class="" class="d-matlab">

            <mat-form-field appearance="outline">
              <mat-label>Asset Attributes</mat-label>
              <input matInput type="text" [(ngModel)]="saveAssetAttributeInput.AssetAttributeName"
                name="AssetAttributeName" class="form-control" required>
            </mat-form-field>

          </div>
          <div class="">
            <button type="submit" *ngIf="action == 'AddAssetAttribute'" mat-flat-button color="primary"
              [disabled]="ngF.form.invalid"
              (click)="saveMaster(saveAssetAttributeInput, this.baseAssetConfg + 'AttributeSave')">
              Save
            </button>
            <button type="button" *ngIf="action == 'EditAssetAttribute'" mat-flat-button color="primary"
              [disabled]="ngF.form.invalid"
              (click)="saveMaster(saveAssetAttributeInput, this.baseAssetConfg + 'AttributeSave')">
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
    <!-- Entitlements (this code not use after implement add-entitlements page)-->
    <!-- <div *ngIf="action == 'AddEntitlements' || action == 'EditEntitlements'">
            <form class="row " (ngSubmit)="ngF.form.valid" #ngF="ngForm" novalidate autocomplete="off">
                <div class=" col-sm-12">
                    <div class="" class="d-matlab">

                        <mat-form-field appearance="outline">
                            <mat-label>Entitlements Codes</mat-label>
                            <mat-select class="form-control" [(ngModel)]="saveExpenseEntitlementInput.EntitlementCodeid" name="EntitlementCodeid" required>
                                <fuse-mat-select-search name="search" [(ngModel)]="EntitlementCodes">
                                </fuse-mat-select-search>
                                <mat-option *ngFor="let d_list of EntitlementCode | filter: EntitlementCodes" [value]="d_list.EntitlementCodeid">
                                    {{d_list?.EntitlementCodeName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <h3 for="" style="font-weight: 700;" class="mb-2">Monthly Limit</h3>
                        <div class="row">
                            <ng-container *ngFor="let list of saveExpenseEntitlementInput?.monthlylLmitEntitlementModels; let last =  last; let i = index">
                                <div style="padding-right: 7px !important;padding-left: 16px;width: 214px;">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Currency</mat-label>
                                        <mat-select class="form-control" [(ngModel)]="list.MonthlyCurrencyid" name="MonthlyCurrencyid" required>
                                            <fuse-mat-select-search name="search" [(ngModel)]="CurrencySearch1">
                                            </fuse-mat-select-search>
                                            <mat-option *ngFor="let Cur of CurrencyDropDown | filter: CurrencySearch1" [value]="Cur.CurrencyId">
                                                {{Cur?.CurrencyName}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class=" pr-2" style="width: 156px;">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Amount</mat-label>
                                        <input matInput type="number" [(ngModel)]="list.MonthlyAmount" name="MonthlyAmount" class="form-control" required>
                                    </mat-form-field>
                                </div>

                                <div style="padding-left: 16px;
                            width: 311px;">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Category</mat-label>
                                        <mat-select [compareWith]="compareFn" [(ngModel)]="list.MonthlyCategoryid" name="MonthlyCategoryid" required>
                                            <mat-option *ngFor="let list of ExpMonthlyCategoryDropDown" [value]="list.Monthlylimitcategoryid">
                                                {{list.Monthlylimitcategoryname}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>


                                <div class="col-md-2 right d-flex pl-0">
                                    <button *ngIf="last" class="link-addmore" (click)="addmonthlist()">
                                        <mat-icon>add_circle</mat-icon>
                                    </button>
                                    <button class="link-addmore" type="button" *ngIf="saveExpenseEntitlementInput.monthlylLmitEntitlementModels.length > 1" (click)="rmvmonthlist(i)">
                                        <mat-icon>remove_circle</mat-icon>
                                    </button>
                                </div>
                            </ng-container>
                        </div>

                        <h3 for="" style="font-weight: 700;" class="mb-2">Local Conveyance:(Per/KM)</h3>
                        <div class="row">
                            <div style="padding-right: 7px !important;padding-left: 16px;width: 214px;">

                                <mat-form-field appearance="outline">
                                    <mat-label>Currency</mat-label>
                                    <mat-select class="form-control" [(ngModel)]="saveExpenseEntitlementInput.LocalConveyanceCurrencyid" name="LocalConveyanceCurrencyid" required>
                                        <fuse-mat-select-search name="search" [(ngModel)]="CurrencySearch1">
                                        </fuse-mat-select-search>
                                        <mat-option *ngFor="let Cur of CurrencyDropDown | filter: CurrencySearch1" [value]="Cur.CurrencyId">
                                            {{Cur?.CurrencyName}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class=" pr-2" style="width: 156px;">
                                <mat-form-field appearance="outline">
                                    <mat-label>Amount</mat-label>
                                    <input matInput type="number" [(ngModel)]="saveExpenseEntitlementInput.LocalConveyanceAmount" name="LocalConveyanceAmount" class="form-control" required>
                                </mat-form-field>
                            </div>
                        </div>
                        <h3 style="font-weight: 700;" class="mb-2">Travel</h3>
                        <div class="row">
                            <div style="padding-right: 7px !important;
                            padding-left: 16px;
                            width: 214px;">
                                <mat-form-field appearance="outline">
                                    <mat-label>Currency</mat-label>
                                    <mat-select class="form-control" [(ngModel)]="saveExpenseEntitlementInput.TravelCurrencyid" name="TravelCurrencyid" required>
                                        <fuse-mat-select-search name="search" [(ngModel)]="CurrencySearch1">
                                        </fuse-mat-select-search>
                                        <mat-option *ngFor="let Cur of CurrencyDropDown | filter: CurrencySearch1" [value]="Cur.CurrencyId">
                                            {{Cur?.CurrencyName}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div style="padding-left: 0;width:  156px;">
                                <mat-form-field appearance="outline">
                                    <mat-label>Amount</mat-label>
                                    <input matInput type="number" [(ngModel)]="saveExpenseEntitlementInput.TravelAmount" name="TravelAmount" class="form-control" required>
                                </mat-form-field>
                            </div>
                        </div>


                        <h3 for="" style="font-weight: 700;" class="mb-2">Hotel Limit/ Per Night</h3>
                        <div class="row">

                            <div style="padding-right: 7px !important;padding-left: 16px;width: 214px;">
                                <mat-form-field appearance="outline">
                                    <mat-label>Currency</mat-label>
                                    <mat-select class="form-control" [(ngModel)]="saveExpenseEntitlementInput.HotelCurrencyid" name="HotelCurrencyid" required>
                                        <fuse-mat-select-search name="search" [(ngModel)]="CurrencySearch1">
                                        </fuse-mat-select-search>
                                        <mat-option *ngFor="let Cur of CurrencyDropDown | filter: CurrencySearch1" [value]="Cur.CurrencyId">
                                            {{Cur?.CurrencyName}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div style="padding-left: 0px;width: 156px;">
                                <mat-form-field appearance="outline">
                                    <mat-label>Amount</mat-label>
                                    <input matInput type="number" [(ngModel)]="saveExpenseEntitlementInput.HotelAmount" name="HotelAmount" class="form-control" required>
                                </mat-form-field>
                            </div>
                        </div>
                        <h3 for="" style="font-weight: 700;" class="mb-2">Own Arrangement</h3>
                        <div class="row">
                            <div style="padding-right: 7px !important;padding-left: 16px;width: 214px;">
                                <mat-form-field appearance="outline">
                                    <mat-label>Currency</mat-label>
                                    <mat-select class="form-control" [(ngModel)]="saveExpenseEntitlementInput.OwnCurrencyid" name="OwnCurrencyid" required>
                                        <fuse-mat-select-search name="search" [(ngModel)]="CurrencySearch1">
                                        </fuse-mat-select-search>
                                        <mat-option *ngFor="let Cur of CurrencyDropDown | filter: CurrencySearch1" [value]="Cur.CurrencyId">
                                            {{Cur?.CurrencyName}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div style="padding-left: 0px;width: 156px;">
                                <mat-form-field appearance="outline">
                                    <mat-label>Amount</mat-label>
                                    <input matInput type="number" [(ngModel)]="saveExpenseEntitlementInput.OwnAmount" name="OwnAmount" class="form-control" required>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" col-sm-4">
                    <button type="submit" mat-flat-button color="primary" [disabled]="ngF.form.invalid" (click)="saveMaster(saveExpenseEntitlementInput, baseExpenseConfg + 'ExpenseEntitlementsCodeSave')">
                        <span *ngIf="action == 'AddEntitlements'">Save</span>
                        <span *ngIf="action == 'EditEntitlements'">Update</span>
                    </button>
                </div>
            </form>
        </div> -->
    <!-- Recurring Expense -->
    <div *ngIf="action == 'addrecurringExpense'" class="epica-module recexpense">
      <form class="row " (ngSubmit)="f.form.valid" #f="ngForm" novalidate autocomplete="off">

        <div class="col-12">
          <mat-radio-group [(ngModel)]="saveRecurringData.IsDomestic" name="IsDomestic" #IsDomestic="ngModel" required>
            <mat-radio-button [value]="list.value" *ngFor="let list of ExpenseType">{{list.key}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>Recurring Type</mat-label>
            <mat-select class="form-control" [(ngModel)]="saveRecurringData.typeid" name="typeid" #typeid="ngModel"
              required>
              <mat-option *ngFor="let list of allDays" [value]="list.value">
                {{list.key}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-12">
          <div class="" class="d-matlab">
            <div class="row">
              <div [ngClass]="saveRecurringData?.typeid == 1 ? 'col-12' : 'col-6 pr-2'">
                <mat-form-field appearance="outline">
                  <input matInput [matDatepicker]="picker1" [(ngModel)]="saveRecurringData.fromdate" name="fromdate"
                    #fromdate="ngModel" required (focus)="picker1.open()"
                    placeholder="{{saveRecurringData?.typeid == 1 ? 'Select Date' : 'From Date'}}">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-6 pl-2" *ngIf="saveRecurringData?.typeid != 1">
                <mat-form-field appearance="outline">
                  <input matInput [matDatepicker]="picker2" placeholder="To Date" required (focus)="picker2.open()"
                    [(ngModel)]="saveRecurringData.todate" name="todate" #todate="ngModel"
                    [min]="saveRecurringData.fromdate">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
              </div>
            </div>

            <label for="" style="font-weight: 700;" class="mb-2 d-block">Bill Amount Claimed</label>
            <div class="row">
              <div class="col-md-12 d-flex">
                <mat-form-field appearance="outline" style="width:30%" *ngIf="!saveRecurringData.IsDomestic">
                  <mat-select placeholder="Select" [(ngModel)]="saveRecurringData.CurrencyId" name="CurrencyId"
                    #CurrencyId="ngModel" required>
                    <fuse-mat-select-search name="search" [(ngModel)]="currencyListSearch">
                    </fuse-mat-select-search>
                    <mat-option [value]="currency?.CurrencyId"
                      *ngFor="let currency of CurrencyDropDown | filter: currencyListSearch">
                      {{currency.CurrencySymbol}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline"
                  [ngStyle]="{'width': saveRecurringData.IsDomestic ? '100%': '70%'}">
                  <span matPrefix class="price-prefix" style="top: 0px;"
                    *ngIf="saveRecurringData.IsDomestic ">{{userinput?.CompanyCurrencySymbol}}</span>
                  <input type="number" placeholder="Amount" matInput [(ngModel)]="saveRecurringData.billamount"
                    name="billamount" #billamount="ngModel" required>
                </mat-form-field>
              </div>
            </div>

            <mat-form-field appearance="outline" class="h_auto">
              <mat-label>Remark</mat-label>
              <textarea matInput style="height: 60px;" [(ngModel)]="saveRecurringData.remark" name="remark"
                #remark="ngModel" required></textarea>
            </mat-form-field>

            <div class="attach_file" style="margin-top:.25em">
              <img src="/assets/icons/attach_icon.svg" alt=""> Upload Bill
              <input type="file" (change)="fileUpload($event, saveRecurringData.billdocument)" class="upload"
                #myFileInputQR>
            </div>
            <div class="d-flex justify-content-between">
              <span>{{saveRecurringData?.billdocument?.DocumentFileName}}</span>
              <mat-icon class="cancelicon"
                *ngIf="saveRecurringData?.billdocument?.Preview || saveRecurringData?.billdocument?.DocumentPath"
                (click)="fileUploadRemove(saveRecurringData?.billdocument)">
                cancel</mat-icon>
            </div>
          </div>
        </div>
        <div class="col-sm-4 mt-3 pt-3">
          <button type="submit" mat-flat-button
            [disabled]="!saveRecurringData?.billdocument?.DocumentFileName || f.form.invalid || saveRecurringData.billamount < 1"
            color="primary" (click)="saveRecurring()">
            Save
          </button>
        </div>
      </form>
    </div>
    <!-- Travel Expense -->
    <div class="travelex recexpense" *ngIf="action == 'travelExpense'">
      <form class="row " (ngSubmit)="f.form.valid" #f="ngForm" novalidate autocomplete="off">
        <div class="col-12">
          <mat-radio-group [(ngModel)]="saveExpenseData.IsDomestic" name="IsDomestic" #IsDomestic="ngModel" required>
            <mat-radio-button [value]="list.value" *ngFor="let list of ExpenseType">{{list.key}}
            </mat-radio-button>
          </mat-radio-group>
          <mat-form-field appearance="outline">
            <mat-label>Travel Type</mat-label>
            <mat-select class="form-control" [(ngModel)]="saveExpenseData.typeid" name="typeid" #typeid="ngModel"
              required>
              <mat-option *ngFor="let list of travelRadio" [value]="list.value">
                {{list.key}}</mat-option>
            </mat-select>
          </mat-form-field>


          <!-- <mat-radio-group [(ngModel)]="saveExpenseData.typeid" name="typeid" #typeid="ngModel" required>
            <mat-radio-button [value]="list.value" *ngFor="let list of travelRadio">{{list.key}}
            </mat-radio-button>
          </mat-radio-group> -->
          <mat-form-field appearance="outline" class="single-datepicker">
            <mat-label>Travel Date</mat-label>
            <input matInput [matDatepicker]="pickerTravel" (focus)="pickerTravel.open()"
              [(ngModel)]="saveExpenseData.traveldate" name="traveldate" #traveldate="ngModel" required>
            <mat-datepicker-toggle matSuffix [for]="pickerTravel"></mat-datepicker-toggle>
            <mat-datepicker #pickerTravel></mat-datepicker>
          </mat-form-field>
          <div fxLayout="row wrap" class="grid_row" fxLayoutWrap fxLayoutAlign="start start">
            <div class="flex-item" fxLayout="column" fxFlex="0 1 50">
              <mat-form-field appearance="outline">
                <mat-label>Visit From</mat-label>
                <input type="text" matInput [(ngModel)]="saveExpenseData.visitfrom" name="visitfrom"
                  #visitfrom="ngModel" required>
              </mat-form-field>
            </div>
            <div class="flex-item" fxLayout="column" fxFlex="0 1 50">
              <mat-form-field appearance="outline">
                <mat-label>Visit To</mat-label>
                <input type="text" matInput [(ngModel)]="saveExpenseData.visitto" name="visitto" #visitto="ngModel"
                  required>
              </mat-form-field>
            </div>
            <div class="flex-item" fxLayout="column" fxFlex="0 1 50">
              <mat-form-field appearance="outline">
                <mat-label>Mode</mat-label>
                <mat-select [(ngModel)]="saveExpenseData.modeid" name="modeid" #modeid="ngModel" required>
                  <mat-option [value]="item?.Conveyanceid" *ngFor="let item of this.ModeDropDown">
                    {{item?.ConveyanceName}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flex-item" fxLayout="column" fxFlex="0 1 50" *ngIf="saveExpenseData.IsDomestic">
              <mat-form-field appearance="outline">
                <mat-label>Amount</mat-label>
                <span matPrefix class="price-prefix" style="top: -1px;">{{userinput?.CompanyCurrencySymbol}}</span>
                <input type="number" matInput [(ngModel)]="saveExpenseData.billamount" name="billamount"
                  #billamount="ngModel" required>
              </mat-form-field>
            </div>

            <div class="col-12 d-flex" style="padding: 0 10px;" *ngIf="!saveExpenseData.IsDomestic">
              <mat-form-field appearance="outline" style="width:30%">
                <mat-select placeholder="Select" [(ngModel)]="saveExpenseData.CurrencyId" name="CurrencyId"
                  #CurrencyId="ngModel" required>
                  <fuse-mat-select-search name="search" [(ngModel)]="currencyListSearch">
                  </fuse-mat-select-search>
                  <mat-option [value]="currency?.CurrencyId"
                    *ngFor="let currency of CurrencyDropDown | filter: currencyListSearch">
                    {{currency.CurrencySymbol}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" style="width:70%">
                <input type="number" placeholder="Amount" matInput [(ngModel)]="saveExpenseData.billamount"
                  name="billamount" #billamount="ngModel" required>
              </mat-form-field>
            </div>

            <div class="flex-item" fxLayout="column" fxFlex="0 1 100">
              <mat-form-field appearance="outline">
                <mat-label>Purpose</mat-label>
                <input type="text" matInput [(ngModel)]="saveExpenseData.purpose" name="purpose" #purpose="ngModel"
                  required>
              </mat-form-field>
            </div>
            <div class="flex-item" fxLayout="column" fxFlex="0 1 100">
              <mat-form-field appearance="outline">
                <mat-label>Client / Person</mat-label>
                <input type="text" matInput [(ngModel)]="saveExpenseData.clientperson" name="clientperson"
                  #clientperson="ngModel" required>
              </mat-form-field>
            </div>
            <div class="flex-item" fxLayout="column" fxFlex="0 1 100">
              <mat-form-field appearance="outline">
                <mat-label>Distance</mat-label>
                <input type="text" numbersOnly matInput [(ngModel)]="saveExpenseData.distance" name="distance"
                  #distance="ngModel" required>
                <span matSuffix class="price-postfix">KM</span>
              </mat-form-field>
            </div>
            <div class="flex-item" fxLayout="column" fxFlex="0 1 100">
              <mat-form-field appearance="outline" class="h_auto">
                <mat-label>Remark</mat-label>
                <textarea matInput style="height: 60px;" [(ngModel)]="saveExpenseData.remark" name="remark"
                  #remark="ngModel" required></textarea>
              </mat-form-field>
            </div>
            <div class="flex-item" fxLayout="column" fxFlex="0 1 100">
              <div class="attach_file" style="margin-top:.25em">
                <img src="/assets/icons/attach_icon.svg" alt=""> Upload Bill
                <input type="file" (change)="fileUpload($event, saveExpenseData.billdocument)" class="upload"
                  #myFileInputQR>
              </div>
              <div class="d-flex justify-content-between">
                <span>{{saveExpenseData?.billdocument?.DocumentFileName}}</span>
                <mat-icon class="cancelicon"
                  *ngIf="saveExpenseData?.billdocument?.Preview || saveExpenseData?.billdocument?.DocumentPath"
                  (click)="myFileInputQR.value='';fileUploadRemove(saveExpenseData?.billdocument)">
                  cancel</mat-icon>
              </div>
            </div>
          </div>
          <button type="submit" mat-flat-button class="mt-3"
            [disabled]="!saveExpenseData?.billdocument?.DocumentFileName || f.form.invalid || saveExpenseData.billamount < 1"
            color="primary" (click)="saveRecurringExpense()">
            Save
          </button>
        </div>
      </form>
    </div>
    <!-- Accommodation Expense -->
    <div class="travelex recexpense accomexpense" *ngIf="action == 'accommodationExpense'">
      <form #mF="ngForm" (ngSubmit)="mF.form.valid" novalidate autocomplete="off">
        <mat-radio-group [(ngModel)]="saveAccommodationExpense.IsDomestic" name="IsDomestic" #IsDomestic="ngModel">
          <mat-radio-button [value]="list.value" *ngFor="let list of ExpenseType">{{list.key}}
          </mat-radio-button>
        </mat-radio-group>
        <mat-form-field appearance="outline">
          <mat-label>Accommodation Type</mat-label>
          <mat-select class="form-control" [(ngModel)]="saveAccommodationExpense.AccomodationId" name="AccomodationId"
            #AccomodationId="ngModel" required>
            <mat-option *ngFor="let list of accRadio" [value]="list.AccomodationId">
              {{list.AccomodationName}}</mat-option>
          </mat-select>
        </mat-form-field>

        <label style="font-weight: 700;" class="d-block">Booking Mode</label>
        <mat-radio-group [(ngModel)]="saveAccommodationExpense.BookingModeId" name="BookingModeId"
          #BookingModeId="ngModel">
          <mat-radio-button [value]="list.value" *ngFor="let list of BookingMode">{{list.key}}
          </mat-radio-button>
        </mat-radio-group>

        <div fxLayout="row wrap" class="grid_row" fxLayoutWrap fxLayoutAlign="start start">
          <div class="flex-item" fxLayout="column" fxFlex="0 1 100">
            <mat-form-field appearance="outline">
              <mat-label>Location</mat-label>
              <input required type="text" [(ngModel)]="saveAccommodationExpense.location" name="location"
                #loation="ngModel" matInput>
            </mat-form-field>
          </div>
          <div class="flex-item d-flex" fxFlex="0 1 50">
            <mat-form-field appearance="outline">
              <input required matInput [matDatepicker]="picker1" placeholder="From Date"
                [(ngModel)]="saveAccommodationExpense.fromdate" name="fromdate" (click)="picker1.open();"
                (dateChange)="saveAccommodationExpense.todate = saveAccommodationExpense.fromdate; dateChange($event, 'fromdate')">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="flex-item d-flex" fxFlex="0 1 50">
            <mat-form-field appearance="outline">
              <input required matInput [matDatepicker]="picker2" placeholder="To Date"
                [min]="saveAccommodationExpense.fromdate" [(ngModel)]="saveAccommodationExpense.todate" name="todate"
                (dateChange)="dateChange($event, 'todate')" (click)="picker2.open()">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="flex-item d-flex" fxFlex="0 1 100" *ngIf="saveAccommodationExpense?.noofdays"
            style="margin-top: -11px; margin-bottom: 13px; font-size: 13px;">
            No of Days: {{saveAccommodationExpense?.noofdays}}
          </div>
          <div class="flex-item d-flex" fxFlex="0 1 100">
            <label for="" style="font-weight: 700;" class="d-block">Amount</label>
          </div>
          <!-- <div class="flex-item d-flex" fxFlex="0 1 100">
            <mat-form-field appearance="outline">
              <span matPrefix class="price-prefix" style="top: 0px;">{{userinput?.CompanyCurrencySymbol}}</span>
              <input type="number" [(ngModel)]="saveAccommodationExpense.billamount" name="billamount" matInput
                required>
            </mat-form-field>
          </div> -->

          <div class="flex-item d-flex" fxFlex="0 1 100">
            <mat-form-field appearance="outline" style="width:30%" *ngIf="!saveAccommodationExpense.IsDomestic">
              <mat-select placeholder="Select" [(ngModel)]="saveAccommodationExpense.CurrencyId" name="CurrencyId"
                #CurrencyId="ngModel" required>
                <fuse-mat-select-search name="search" [(ngModel)]="currencyListSearch">
                </fuse-mat-select-search>
                <mat-option [value]="currency?.CurrencyId"
                  *ngFor="let currency of CurrencyDropDown | filter: currencyListSearch">
                  {{currency.CurrencySymbol}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline"
              [ngStyle]="{'width': saveAccommodationExpense.IsDomestic ? '100%': '70%'}">
              <span matPrefix class="price-prefix" style="top: 0px;"
                *ngIf="saveAccommodationExpense.IsDomestic">{{userinput?.CompanyCurrencySymbol}}</span>
              <input type="number" placeholder="Amount" matInput [(ngModel)]="saveAccommodationExpense.billamount"
                name="billamount" #billamount="ngModel" required>
            </mat-form-field>
          </div>


          <div class="flex-item d-flex" fxFlex="0 1 100">
            <mat-form-field appearance="outline" class="h_auto">
              <mat-label>Remark</mat-label>
              <textarea matInput style="height: 60px;" [(ngModel)]="saveAccommodationExpense.remark" name="remark"
                required></textarea>
            </mat-form-field>
          </div>
          <div class="flex-item" fxFlex="0 1 100">
            <div class="attach_file" style="margin-top:.25em">
              <img src="/assets/icons/attach_icon.svg" alt=""> Upload Bill
              <input type="file" (change)="fileUpload($event, saveAccommodationExpense.billdocument)" class="upload"
                #myFileInput required>
            </div>
            <div class="d-flex justify-content-between"
              *ngIf="saveAccommodationExpense?.billdocument?.DocumentFileName">
              <a
                (click)="downloadMyFileOther(saveAccommodationExpense?.billdocument)">{{saveAccommodationExpense?.billdocument?.DocumentFileName}}</a>
              <a *ngIf="saveAccommodationExpense?.billdocument?.Preview || saveAccommodationExpense?.billdocument?.DocumentPath"
                (click)="myFileInput.value='';fileUploadRemove(saveAccommodationExpense?.billdocument)">
                <mat-icon>close</mat-icon>
              </a>
            </div>
            <mat-error class="mb-3" *ngIf="mF.submitted && !saveAccommodationExpense?.billdocument?.DocumentFileName">
              Please Upload Bill</mat-error>
          </div>
          <div class="flex-item d-flex mt-3" fxFlex="0 1 100">
            <button type="submit" mat-flat-button color="primary"
              [disabled]="!saveAccommodationExpense?.billdocument?.DocumentFileName || !mF.form.valid || saveAccommodationExpense.billamount < 1"
              (click)="saveAccommodationEx()">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
    <!-- Food & Beverage -->
    <div class="travelex recexpense accomexpense" *ngIf="action == 'feedbevExpense'">
      <form #mF="ngForm" (ngSubmit)="mF.form.valid" novalidate autocomplete="off">
        <div fxLayout="row wrap" class="grid_row" fxLayoutWrap fxLayoutAlign="start start">



          <div class="flex-item" fxLayout="column" fxFlex="0 1 100">
            <mat-radio-group [(ngModel)]="saveFoodBeverageData.IsDomestic" name="IsDomestic" #IsDomestic="ngModel"
              required>
              <mat-radio-button [value]="list.value" *ngFor="let list of ExpenseType">{{list.key}}
              </mat-radio-button>
            </mat-radio-group>
            <mat-form-field appearance="outline">
              <mat-label>Location</mat-label>
              <input required type="text" [(ngModel)]="saveFoodBeverageData.location" name="location" #loation="ngModel"
                matInput>
            </mat-form-field>
          </div>
          <div class="flex-item" fxLayout="column" fxFlex="0 1 100">
            <mat-form-field appearance="outline">
              <input matInput [matDatepicker]="picker1" placeholder="Date" [(ngModel)]="saveFoodBeverageData.fromdate"
                name="fromdate" #fromdate="ngModel" (click)="picker1.open()" required>
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="flex-item d-flex" fxFlex="0 1 100">
            <label for="" style="font-weight: 700;" class="">Amount</label>
          </div>
          <!-- <div class="flex-item d-flex" fxFlex="0 1 100">
            <mat-form-field appearance="outline">
              <span matPrefix class="price-prefix" style="top: 0px;">{{userinput?.CompanyCurrencySymbol}}</span>
              <input type="number" [(ngModel)]="saveFoodBeverageData.billamount" name="billamount" #billamount="ngModel"
                matInput required>
            </mat-form-field>
          </div> -->

          <div class="flex-item d-flex" fxFlex="0 1 100">
            <mat-form-field appearance="outline" style="width:30%" *ngIf="!saveFoodBeverageData.IsDomestic">
              <mat-select placeholder="Select" [(ngModel)]="saveFoodBeverageData.CurrencyId" name="CurrencyId"
                #CurrencyId="ngModel" required>
                <fuse-mat-select-search name="search" [(ngModel)]="currencyListSearch">
                </fuse-mat-select-search>
                <mat-option [value]="currency?.CurrencyId"
                  *ngFor="let currency of CurrencyDropDown | filter: currencyListSearch">
                  {{currency.CurrencySymbol}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" [ngStyle]="{'width': saveFoodBeverageData.IsDomestic ? '100%': '70%'}">
              <span matPrefix class="price-prefix" style="top: 0px;"
                *ngIf="saveFoodBeverageData.IsDomestic">{{userinput?.CompanyCurrencySymbol}}</span>
              <input type="number" placeholder="Amount" matInput [(ngModel)]="saveFoodBeverageData.billamount"
                name="billamount" #billamount="ngModel" required>
            </mat-form-field>
          </div>

          <div class="flex-item d-flex" fxFlex="0 1 100">
            <mat-form-field appearance="outline" class="h_auto">
              <mat-label>Remark</mat-label>
              <textarea matInput style="height: 60px;" [(ngModel)]="saveFoodBeverageData.remark" name="remark"
                #remark="ngModel" required></textarea>
            </mat-form-field>
          </div>
          <div class="flex-item" fxFlex="0 1 100">
            <div class="attach_file" style="margin-top:.25em">
              <img src="/assets/icons/attach_icon.svg" alt=""> Upload Bill
              <input type="file" (change)="fileUpload($event, saveFoodBeverageData.billdocument)" class="upload"
                #myFileInput required>
            </div>
            <div class="d-flex justify-content-between" *ngIf="saveFoodBeverageData?.billdocument?.DocumentFileName">
              <a
                (click)="downloadMyFileOther(saveFoodBeverageData?.billdocument)">{{saveFoodBeverageData?.billdocument?.DocumentFileName}}</a>
              <a *ngIf="saveFoodBeverageData?.billdocument?.Preview || saveFoodBeverageData?.billdocument?.DocumentPath"
                (click)="myFileInput.value='';fileUploadRemove(saveFoodBeverageData?.billdocument)">
                <mat-icon>close</mat-icon>
              </a>
            </div>
            <mat-error class="mb-2" *ngIf="mF.submitted && !saveFoodBeverageData?.billdocument?.DocumentFileName">
              Please Upload Bill</mat-error>
          </div>
          <div class="flex-item mt-3" fxFlex="0 1 100">
            <button type="submit" mat-flat-button color="primary"
              [disabled]="!saveFoodBeverageData?.billdocument?.DocumentFileName || !mF.form.valid || saveFoodBeverageData.billamount < 1"
              (click)="saveFoodBeverage()">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
    <!-- General Expenses -->
    <div class="travelex recexpense accomexpense" *ngIf="action == 'generalExpense'">
      <form #mF="ngForm" (ngSubmit)="mF.form.valid" novalidate autocomplete="off">
        <div fxLayout="row wrap" class="grid_row" fxLayoutWrap fxLayoutAlign="start start">
          <div class="flex-item" fxLayout="column" fxFlex="0 1 100">
            <mat-radio-group [(ngModel)]="saveGeneralExData.IsDomestic" name="IsDomestic" #IsDomestic="ngModel"
              required>
              <mat-radio-button [value]="list.value" *ngFor="let list of ExpenseType">{{list.key}}
              </mat-radio-button>
            </mat-radio-group>

            <mat-form-field appearance="outline">
              <mat-label>Expense Type</mat-label>
              <mat-select class="form-control" [(ngModel)]="saveGeneralExData.ExpenseId" name="ExpenseId"
                #ExpenseId="ngModel" required>
                <mat-option *ngFor="let list of ExpenseLsits" [value]="list.ExpenseId">
                  {{list.ExpenseName}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Location</mat-label>
              <input required type="text" [(ngModel)]="saveGeneralExData.location" name="location" #loation="ngModel"
                matInput>
            </mat-form-field>
          </div>

          <div class="flex-item" fxLayout="column" fxFlex="0 1 100">
            <mat-form-field appearance="outline">
              <mat-label>Title</mat-label>
              <input type="text" [(ngModel)]="saveGeneralExData.title" name="title" #title="ngModel" matInput required>
            </mat-form-field>
          </div>
          <div class="flex-item" fxLayout="column" fxFlex="0 1 100">
            <mat-form-field appearance="outline">
              <input matInput [matDatepicker]="picker2" placeholder="Date" [(ngModel)]="saveGeneralExData.fromdate"
                name="fromdate" #fromdate="ngModel" (click)="picker2.open()" required>
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="flex-item d-flex" fxFlex="0 1 100">
            <label for="" style="font-weight: 700;" class="">Amount</label>
          </div>
          <!-- <div class="flex-item d-flex" fxFlex="0 1 100">
            <mat-form-field appearance="outline">
              <span matPrefix class="price-prefix" style="top: 0px;">{{userinput?.CompanyCurrencySymbol}}</span>
              <input type="number" [(ngModel)]="saveGeneralExData.billamount" name="billamount" #billamount="ngModel"
                matInput required>
            </mat-form-field>
          </div> -->

          <div class="flex-item d-flex" fxFlex="0 1 100">
            <mat-form-field appearance="outline" style="width:30%" *ngIf="!saveGeneralExData.IsDomestic">
              <mat-select placeholder="Select" [(ngModel)]="saveGeneralExData.CurrencyId" name="CurrencyId"
                #CurrencyId="ngModel" required>
                <fuse-mat-select-search name="search" [(ngModel)]="currencyListSearch">
                </fuse-mat-select-search>
                <mat-option [value]="currency?.CurrencyId"
                  *ngFor="let currency of CurrencyDropDown | filter: currencyListSearch">
                  {{currency.CurrencySymbol}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" [ngStyle]="{'width': saveGeneralExData.IsDomestic ? '100%': '70%'}">
              <span matPrefix class="price-prefix" style="top: 0px;"
                *ngIf="saveGeneralExData.IsDomestic">{{userinput?.CompanyCurrencySymbol}}</span>
              <input type="number" placeholder="Amount" matInput [(ngModel)]="saveGeneralExData.billamount"
                name="billamount" #billamount="ngModel" required>
            </mat-form-field>
          </div>




          <div class="flex-item d-flex" fxFlex="0 1 100">
            <mat-form-field appearance="outline" class="h_auto">
              <mat-label>Remark</mat-label>
              <textarea matInput style="height: 60px;" [(ngModel)]="saveGeneralExData.remark" name="remark"
                #remark="ngModel" required></textarea>
            </mat-form-field>
          </div>
          <div class="flex-item" fxFlex="0 1 100">
            <div class="attach_file" style="margin-top:.25em">
              <img src="/assets/icons/attach_icon.svg" alt=""> Upload Bill
              <input type="file" (change)="fileUpload($event, saveGeneralExData.billdocument)" class="upload"
                #myFileInput required>
            </div>
            <div class="d-flex justify-content-between" *ngIf="saveGeneralExData?.billdocument?.DocumentFileName">
              <a
                (click)="downloadMyFileOther(saveGeneralExData?.billdocument)">{{saveGeneralExData?.billdocument?.DocumentFileName}}</a>
              <a *ngIf="saveGeneralExData?.billdocument?.Preview || saveGeneralExData?.billdocument?.DocumentPath"
                (click)="myFileInput.value='';fileUploadRemove(saveGeneralExData?.billdocument)">
                <mat-icon>close</mat-icon>
              </a>
            </div>
            <mat-error class="mb-3" *ngIf="mF.submitted && !saveGeneralExData?.billdocument?.DocumentFileName">
              Please Upload Bill</mat-error>
          </div>
          <div class="flex-item mt-3" fxFlex="0 1 100">
            <button type="submit" mat-flat-button color="primary"
              [disabled]="!saveGeneralExData?.billdocument?.DocumentFileName || !mF.form.valid || saveGeneralExData.billamount < 1"
              (click)="saveGeneralData()">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
    <!-- AddUom -->
    <div *ngIf="action == 'AddUom' || action == 'EditUom'">
      <form #mF="ngForm" (ngSubmit)="mF.form.valid" class="row " novalidate autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Abbreviation</mat-label>
              <input matInput type="text" [(ngModel)]="saveAssetInput.UnitOfMeasurementAbbreviationName"
                name="UnitOfMeasurementAbbreviationName" class="form-control" required>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Name</mat-label>
              <input matInput type="text" [(ngModel)]="saveAssetInput.UnitOfMeasurementName"
                name="UnitOfMeasurementName" class="form-control" required>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" *ngIf="action == 'AddUom'" mat-flat-button color="primary" [disabled]="mF.form.invalid"
            (click)="saveMaster(saveAssetInput, this.baseGlobalConfig + 'UnitOfMeasurementSave')">
            Save
          </button>
          <button type="submit" mat-flat-button *ngIf="action == 'EditUom'" color="primary" [disabled]="mF.form.invalid"
            (click)="saveMaster(saveAssetInput, this.baseGlobalConfig + 'UnitOfMeasurementSave')">
            Update
          </button>
        </div>
      </form>
    </div>
    <!-- Receive Payment -->
    <div *ngIf="action == 'ReceivePayment'">
      <form class="row " (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">

        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <div class="lead_type">
              <label class="label-title d-block font-weight-bold">Balance</label>
              <p>{{userinput?.CompanyCurrencySymbol}} {{saveAdvanceData?.ReceivedAmount}}</p>
            </div>
            <mat-form-field appearance="outline">
              <mat-label> Amount Received</mat-label>
              <input type="number" matInput [(ngModel)]="saveAdvanceData.PaidAmount" name="PaidAmount"
                #PaidAmount="ngModel">
              <span class="price-prefix" style="top: 0px;" matPrefix>{{userinput?.CompanyCurrencySymbol}}</span>
            </mat-form-field>
            <mat-error *ngIf="saveAdvanceData?.ReceivedAmount < saveAdvanceData.PaidAmount">Amount should be less than
              Balance</mat-error>
            <mat-form-field appearance="outline" class="single-datepicker">
              <mat-label>Received on</mat-label>
              <input matInput [matDatepicker]="pickerAdvance" class="form-control" required
                (focus)="pickerAdvance.open()" [(ngModel)]="saveAdvanceData.ReceiveDateTime" name="ReceiveDateTime"
                #ReceiveDateTime="ngModel">

              <mat-datepicker-toggle matSuffix [for]="pickerAdvance"></mat-datepicker-toggle>
              <mat-datepicker #pickerAdvance></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" (click)="saveReceivedPayment()"
            [disabled]="myForm.form.invalid || !saveAdvanceData.PaidAmount || saveAdvanceData.PaidAmount < 1 || saveAdvanceData?.ReceivedAmount < saveAdvanceData.PaidAmount">
            Save
          </button>
        </div>
      </form>
    </div>
    <!-- AdvancePayment -->
    <div *ngIf="action == 'AdvancePayment'">
      <form class="row " (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">

        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <div class="lead_type">
              <div class="add-loccon-radio">
                <mat-radio-group [(ngModel)]="saveAdvanceData.IsEmployee" name="IsEmployee" #IsEmployee="ngModel"
                  required class="example-radio-group" (change)="radioChange($event)">
                  <mat-radio-button [value]="list.value" *ngFor="let list of AdvanceType">{{list.key}}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>

            <mat-form-field appearance="outline" *ngIf="saveAdvanceData.IsEmployee">
              <mat-label>Select Employee</mat-label>
              <mat-select [(ngModel)]="saveAdvanceData.EmployeeId" name="EmployeeId" #EmployeeId="ngModel" required>
                <fuse-mat-select-search name="search" [(ngModel)]="SubmittedBySearch">
                </fuse-mat-select-search>
                <mat-option *ngFor="let po of PORaisedByDropDown | filter: SubmittedBySearch" [value]="po?.EmployeeId">
                  {{po?.EmployeeName}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="!saveAdvanceData.IsEmployee">
              <mat-label>Select Vendor</mat-label>
              <mat-select [(ngModel)]="saveAdvanceData.EmployeeId" name="EmployeeId" #EmployeeId="ngModel" (selectionChange)="GetPoList(saveAdvanceData.EmployeeId)" required>
                <fuse-mat-select-search name="search" [(ngModel)]="SubmittedBySearch">
                </fuse-mat-select-search>
                <mat-option *ngFor="let po of PORaisedByDropDown | filter: SubmittedBySearch" [value]="po?.vendorid">
                  {{po?.vendorname}}</mat-option>
              </mat-select>
            </mat-form-field>

            <div class="row">
              <div class="col-md-12" *ngIf="!saveAdvanceData.IsEmployee">
                <mat-form-field appearance="outline" >
                  <mat-label>Select PO</mat-label>
                  <mat-select [(ngModel)]="saveAdvanceData.PurchaseOrderId" name="PurchaseOrderId" #PurchaseOrderId="ngModel" required>
                    <fuse-mat-select-search name="search" [(ngModel)]="SubmittedBySearch">
                    </fuse-mat-select-search>
                    <mat-option *ngFor="let po of polist | filter: SubmittedBySearch" [value]="po?.PurchaseOrderId">
                      {{po?.PONumber}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-md-12" *ngIf="!saveAdvanceData.IsEmployee">
                <label class="d-block pb-2">Performa Invoice No</label>
                <mat-form-field appearance="outline">
                  <input type="text" matInput required name="PerformaInvoiceNo" #PerformaInvoiceNo="ngModel"
                    [(ngModel)]="saveAdvanceData.PerformaInvoiceNo">
                </mat-form-field>
              </div>
              <div class="col-md-6 ">
                <label class="d-block pb-2">Mode of Payment</label>
                <mat-form-field appearance="outline">
                  <mat-select placeholder="Select" name="modepayment" #modepayment="ngModel"
                    [(ngModel)]="saveAdvanceData.PaymentModeId" required (selectionChange)="getpettycash($event);">
                    <mat-option *ngFor="let list of paymenttype" [value]="list.key">
                      {{list.name}}
                    </mat-option>

                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6" *ngIf="saveAdvanceData.PaymentModeId == '2'">
                <label class="d-block pb-2">Cheque Date</label>
                <mat-form-field appearance="outline" class="single-datepicker">
                  <input matInput [matDatepicker]="picker" required name="chkdate" #chkdate="ngModel"
                    [(ngModel)]="saveAdvanceData.ChequeDate" (focus)="picker.open()">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-md-6" *ngIf="saveAdvanceData.PaymentModeId != '5'">
                <label class="d-block pb-2">Ref. No</label>
                <mat-form-field appearance="outline">
                  <input type="text" matInput required name="refno" #refno="ngModel"
                    [(ngModel)]="saveAdvanceData.RefNo">
                </mat-form-field>
              </div>
              <!-- <div class="col-md-6"
                  *ngIf="saveAdvanceData.PaymentModeId == '5' || saveAdvanceData.PaymentModeId == '1'">
                  <label class="d-block pb-2">Voucher No.</label>
                  <mat-form-field appearance="outline">
                      <input type="text" matInput required name="Voucherno" #Voucherno="ngModel"
                          [(ngModel)]="saveAdvanceData.VoucherNo">
                  </mat-form-field>
              </div> -->
              <div class="col-md-6 d-flex align-items-center" *ngIf="saveAdvanceData.PaymentModeId == '5'">
                <div class="dtl_text mb-0">
                  <label>Petty Cash Available</label>
                  <p class="text-success">
                    <strong>{{saveAdvanceData?.CurrencySymbol}}
                      {{pettycashamount}}</strong>
                  </p>
                </div>
              </div>
            </div>


            <mat-form-field appearance="outline">
              <mat-label> Advance Amount</mat-label>
              <input type="number" matInput [(ngModel)]="saveAdvanceData.PaidAmount" name="PaidAmount"
                #PaidAmount="ngModel" required>
              <span class="price-prefix" style="top: 0px;" matPrefix>{{saveAdvanceData?.CurrencySymbol}}</span>
            </mat-form-field>
            <mat-error
              *ngIf="((saveAdvanceData.PaymentModeId == 5) &&  (saveAdvanceData.PaidAmount ? saveAdvanceData.PaidAmount : 0*1) > pettycashamount)">
              Amount should be less than Petty Cash
            </mat-error>

            <mat-form-field appearance="outline" class="single-datepicker">
              <mat-label>Paid on</mat-label>
              <input matInput [matDatepicker]="pickerAdvance" class="form-control" required
                (focus)="pickerAdvance.open()" [(ngModel)]="saveAdvanceData.PaidOnDate" name="PaidOnDate"
                #PaidOnDate="ngModel" required>
              <mat-datepicker-toggle matSuffix [for]="pickerAdvance"></mat-datepicker-toggle>
              <mat-datepicker #pickerAdvance></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline" class="h_auto">
              <mat-label>Remark</mat-label>
              <textarea matInput type="textarea" style="height: 60px;" [(ngModel)]="saveAdvanceData.Remark"
                name="Remark" #Remark="ngModel" required></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" (click)="saveAdvancePayment()"
            [disabled]="myForm.form.invalid || ((saveAdvanceData.PaymentModeId == 5) &&  (saveAdvanceData.PaidAmount ? saveAdvanceData.PaidAmount : 0*1) > pettycashamount) || saveAdvanceData.PaidAmount < 1">
            Submit
          </button>
        </div>
      </form>
    </div>

    <!-- Make Voucher -->
    <div *ngIf="action == 'remVoucher'">
      <form class="row " autocomplete="off" novalidate autocomplete="off">

        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <div class="lead_type">
              <label class="label-title d-block font-weight-bold">Mode Of Payment</label>
              <div class="add-loccon-radio">
                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                  [(ngModel)]="modePayment" name="modePayment">
                  <mat-radio-button class="example-radio-button" color="primary" value="1" checked>
                    Cash
                  </mat-radio-button>
                  <mat-radio-button class="example-radio-button" color="primary" value="2">
                    Other
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <mat-form-field appearance="outline" *ngIf="modePayment == '2'">
              <mat-label>Select</mat-label>
              <mat-select>
                <mat-option value="0">Bank Name/Source01 - A/C / Identification No01</mat-option>
                <mat-option value="1">Bank Name/Source - A/C / Identification No</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Advance</mat-label>
              <input type="text" matInput>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Sub Total</mat-label>
              <input type="text" matInput disabled value="7000">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Amount Payable</mat-label>
              <input type="text" matInput disabled value="7000">
            </mat-form-field>
            <mat-form-field appearance="outline" class="h_auto">
              <mat-label>Justification</mat-label>
              <textarea matInput style="height: 60px;"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary">
            Save
          </button>
        </div>
      </form>
    </div>
    <!-- Monthly Expense -->
    <div *ngIf="action == 'addGenPr'" class="epica-module">
      <form class="row " (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">

        <div class=" col-sm-12">
          <div class="" class="d-matlab">

            <mat-form-field appearance="outline">
              <mat-label>Item</mat-label>
              <mat-select [(ngModel)]="assetitem.ItemId" name="ItemId" required>
                <fuse-mat-select-search name="search" [(ngModel)]="assetBySearch">
                </fuse-mat-select-search>
                <mat-option *ngFor="let list of AssetItemDroplist | filter: assetBySearch"
                  [value]="this.pasModalData.action2 == 'addConsumable' ? list?.ConsumableItemId : list.AssetItemId">
                  {{this.pasModalData.action2 == 'addConsumable' ? list?.ConsumableItemName :
                  list?.AssetItemName}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="d-flex">
              <div class="pr-0" style="width:50%">
                <mat-form-field appearance="outline" class="mat-field-leftbx" style="width: 100%;">
                  <input type="number" matInput required [(ngModel)]="assetitem.Quantity" class="form-control"
                    name="Quantity" #Quantity="ngModel" placeholder="Quantity">

                </mat-form-field>
              </div>
              <div class="pl-0 " style="width: 50%;">
                <mat-form-field class="pr-0 mat-field-rightbx" style="width: 100%;" appearance="outline">
                  <mat-label>UOM</mat-label>
                  <mat-select [(ngModel)]="assetitem.UomId" name="UomId" required>
                    <mat-option *ngFor="let list of UOMList" [value]="list.UnitOfMeasurementId">
                      {{list?.UnitOfMeasurementName}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <mat-form-field appearance="outline">
                  <mat-label>Priority</mat-label>

                  <mat-select [(ngModel)]="assetitem.RequisitionPriorityId" name="RequisitionPriorityId" required
                    (selectionChange)="priority()">
                    <mat-option *ngFor="let list of priortylist" [value]="list.RequisitionPriorityId">
                      {{list?.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="outline" class="single-datepicker">
                  <mat-label>Required on/before</mat-label>
                  <input matInput [matDatepicker]="pickerGR" [disabled]="!assetitem.RequisitionPriorityId"
                    [min]="minDate" [(ngModel)]="assetitem.RequireDate" class="form-control" required name="RequireDate"
                    #RequireDate="ngModel" (focus)="pickerGR.open()">
                  <mat-datepicker-toggle matSuffix [for]="pickerGR"></mat-datepicker-toggle>
                  <mat-datepicker #pickerGR></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field appearance="outline" class="h_auto">
                  <mat-label>Description</mat-label>
                  <textarea [class.error]="myForm.submitted && Description.invalid" matInput class="form-control"
                    [(ngModel)]="assetitem.Description" name="Description" #Description="ngModel" required
                    style="height: 100px;"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" (click)="savepr()" [disabled]="myForm.form.invalid">
            Save
          </button>
        </div>
      </form>
    </div>
    <!-- Asset Delete -->
    <div *ngIf="action == 'deleteAssetInv'" class="epica-module">
      <div class="apprem">
        <form  (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">
            <div class="tr-heading">
              <mat-form-field appearance="outline">
                <mat-label>Select Reason</mat-label>
                <mat-select [(ngModel)]="disposeData.DisposeReasonId" name="DisposeReasonId" required>
                  <mat-option *ngFor="let list of desposeReason" [value]="list?.value">{{list?.key}}</mat-option>
                </mat-select>
              </mat-form-field>

                <div class="row">
                  <div class="dtl_text col-sm-6">
                    <label style="color: #2075e9; font-size: 14px; font-weight: 600;">Cost Of Acquisition</label>
                        <p>INR {{disposeData.AcquisitionCost}}</p>
                  </div>
                  <div class="dtl_text col-sm-6">
                    <label  style="color: #2075e9; font-size: 14px; font-weight: 600;">WDV</label>
                        <p>INR {{disposeData.DepreciationValue  }}</p>
                  </div>
                </div>
                <mat-form-field appearance="outline">
                  <mat-label>Disposed Value</mat-label>
                    <input matInput type="number" [(ngModel)]="disposeData.DisposedValue" name="DisposedValue" required>
                </mat-form-field>

                <mat-form-field appearance="outline" class="h_auto">
                  <mat-label>Remark</mat-label>
                  <textarea matInput type="textarea" style="height: 60px;" [(ngModel)]="disposeData.DisposeRemark" name="DisposeRemark" required></textarea>
                </mat-form-field>


                <div class="attach_file" style="margin-top:.25em">
                  <img src="/assets/icons/attach_icon.svg" alt=""> Upload Attachment
                  <input type="file" (change)="fileUpload($event, disposeData.DisposeAttachment)" class="upload"
                    #myFileInputQR>
                </div>
                <div class="d-flex justify-content-between mt-2">
                  <a
                    (click)="downloadMyFileOther(disposeData?.DisposeAttachment)">{{disposeData?.DisposeAttachment?.DocumentFileName}}</a>
                  <a *ngIf="disposeData?.DisposeAttachment?.Preview || disposeData?.DisposeAttachment?.DocumentPath"
                    (click)="fileUploadRemove(disposeData?.DisposeAttachment)">
                    <mat-icon>close</mat-icon>
                  </a>
                </div>

                <button type="submit" mat-flat-button color="primary" class="mt-5" (click)='savedisposeData()'
                [disabled]="myForm.form.invalid">
                  Submit
              </button>
            </div>
          </form>
        </div>
    </div>
    <!-- Transfer To Location -->
    <form *ngIf="action == 'transferAssetInv'" class="epica-module" (ngSubmit)="myForm.form.valid" #myForm="ngForm"
      novalidate autocomplete="off">
      <p>
        <span style="font-size: 14px">Current Location</span>
        <strong class="d-block">
          {{currentLocation}}
        </strong>
      </p>
      <mat-form-field appearance="outline">
        <mat-label>Transfer To</mat-label>
        <mat-select [(ngModel)]="transferToId" name="transferToId" required>
          <mat-option *ngFor="let list of companybrach" [value]="list?.BranchId">{{list?.BranchName}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button type="submit" mat-flat-button color="primary" (click)='saveTransferLocation()'
        [disabled]="myForm.form.invalid">
        Save
      </button>
    </form>
    <!-- Outsource -->
    <div
      *ngIf="action == 'modalInHouse' || action == 'modalManContract' || action == 'modalOutsource' || action == 'underWarranty'"
      class="epica-module">
      <form (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">
      <div class="apprem">
        <div class="tr-heading d-flex">
          <p class="mt-0" style="padding-right: 30px;">Asset item
            <span style="font-size: 14px; color: #707070 !important;">{{modalData?.data?.ItemName}}</span>
            <small style="font-size: 12px; font-weight: 400;">
              {{modalData?.data?.AssetCode}}
            </small>
          </p>
          <p class="mt-0" style="padding-right: 30px;"
            *ngIf="action == 'underWarranty' || action == 'modalManContract'">Vendor
            <span style="font-size: 14px; color: #707070 !important;">{{modalData?.data?.VendorName}}</span>
          </p>
        </div>
      </div>

      <mat-form-field appearance="outline" *ngIf="action == 'modalInHouse'">
        <mat-label>Employee</mat-label>
        <mat-select >
          <mat-option value="1">Maheh</mat-option>
          <mat-option value="2">Maheh 1</mat-option>
        </mat-select>
      </mat-form-field>

      <h2 class="m-0 title-warning">Scope Of Work</h2>
      <div class="row row-spacing"  *ngFor="let dec of modalData?.data?.ScopeOfWorkarr; let i = index; let f = first; let l = last">
        <div class="flex-item" fxFlex="0 1 80">
          <mat-form-field appearance="outline" class="h_auto"
          [class.mat-form-field-invalid]="!dec.scops && modalData?.data?.ScopeOfWorkarr[i].value">
            <input matInput type="text" class="form-control" [(ngModel)]="dec.scops" name="scops_{{i}}" required>
          </mat-form-field>
        </div>
        <div class="flex-item d-flex" fxFlex="0 1 20">
          <button class="link-addmore"  *ngIf="l" (click)="addDetailData(modalData?.data?.ScopeOfWorkarr ,i)">
            <mat-icon>add_circle</mat-icon>
          </button>
          <button class="link-addmore" type="button" *ngIf="modalData?.data?.ScopeOfWorkarr.length != 1"
          (click)="removeDetailData(modalData?.data?.ScopeOfWorkarr, i)">
            <mat-icon>remove_circle</mat-icon>
          </button>
        </div>
      </div>

      <ng-container *ngIf="action == 'modalOutsource'">
        <div class="row row-spacing" >
          <div class="flex-item" fxFlex="0 1 70">
            <label>Approx Rate</label>
            <div class="d-flex heightsame-outer">
                <mat-form-field appearance="outline" style="width: 100%;" class="mat-field-rightbx">
                  <span matPrefix class="price-prefix">INR</span>
                <input type="number" matInput [(ngModel)]="modalData.data.ApproxRate" name="EstimatedCost" required>
              </mat-form-field>
            </div>
          </div>
          <div class="flex-item" fxFlex="0 1 30">
            <label>Approx Tax</label>
            <div class="d-flex heightsame-outer">
                <mat-form-field appearance="outline" style="width: 100%;" class="mat-field-rightbx">
                <span matPrefix class="price-prefix">%</span>
                <input type="number" matInput [(ngModel)]="modalData.data.ApproxTax" name="ApproxTax" required>
              </mat-form-field>
            </div>
          </div>
        </div>
      </ng-container>

      <div *ngIf="action == 'modalInHouse' || action == 'modalOutsource' || action == 'underWarranty'">
        <mat-checkbox [(ngModel)]="modalData.data.IsDeposit" name="IsDeposit">Deposit Required</mat-checkbox>
      </div>
      <div class="mt-3 pt-3">
        <button type="submit" mat-flat-button color="primary" *ngIf="action == 'modalInHouse'">
          Assign
        </button>
        <button type="submit" mat-flat-button color="primary" *ngIf="action == 'modalManContract' || action == 'modalOutsource' || action == 'underWarranty'"
          (click)="dialogRef.close(modalData?.data)" mat-dialog-close [disabled]="myForm.form.invalid">
          Submit
        </button>
      </div>
      </form>
    </div>
    <!-- Scope of work -->
    <div *ngIf="action == 'scopeWorkModal'" class="epica-module">
      <mat-form-field appearance="outline" class="h_auto">
        <textarea matInput type="textarea" style="height: 80px;"></textarea>
      </mat-form-field>
      <button type="submit" mat-flat-button color="primary">
        Save
      </button>
    </div>
    <!-- Add Remark -->

    <div *ngIf="action == 'addRemark'">
      <form (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">
        <mat-form-field appearance="outline" class="h_auto">
          <mat-label>Remark</mat-label>
          <textarea matInput type="textarea" style="height: 60px;"></textarea>
        </mat-form-field>
        <button type="submit" mat-flat-button color="primary">
          Save
        </button>
      </form>
    </div>
    <div *ngIf="action == 'attributes' || action == 'addAttributes'||action=='editAttributes'">
      <form (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">
        <div *ngFor="let list of AssetattributeDropDownlist;let i = index">
          <mat-form-field appearance="outline" class="mb-3">
            <span class="attname-pre" matPrefix>{{list?.AssetAttributeName}}</span>
            <input matInput type="text" class="form-control" [(ngModel)]="list.AssetAttributeValue"
              name="AssetAttributeValue_{{i}}">
          </mat-form-field>
        </div>
        <button type="submit" mat-flat-button color="primary" (click)='assetattribute()'
          [disabled]="myForm.form.invalid" *ngIf="action == 'addAttributes'">
          Save
        </button>
        <button type="submit" mat-flat-button color="primary" (click)='assetattribute()'
          [disabled]="myForm.form.invalid" *ngIf="action == 'editAttributes'">
          Update
        </button>
      </form>
    </div>
    <div *ngIf="action == 'viewattributes' || action == 'viewattribute'">
      <form (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">
        <div *ngFor="let list of Assetattributeview;let i = index">
          <mat-form-field appearance="outline" class="mb-3">
            <span class="attname-pre" matPrefix>{{list?.AssetAttributeName}}</span>
            <input matInput type="text" class="form-control"
              [disabled]="this.modalData.data.purchaserequestapprovestatus == 4" [(ngModel)]="list.AssetAttributeValue"
              name="AssetAttributeValue_{{i}}">
          </mat-form-field>
        </div>
        <div class="text-center" *ngIf="Assetattributeview?.length == 0">
          No record found
        </div>
        <button type="submit" mat-flat-button color="primary" (click)="deviceclose()" *ngIf="Assetattributeview?.length > 0">Ok</button>
        <!-- <button type="submit" mat-flat-button color="primary" (click)='assetattribute()'
          [disabled]="myForm.form.invalid" *ngIf="action == 'addAttributes'">
          Save
        </button>
        <button type="submit" mat-flat-button color="primary" (click)='assetattribute()'
          [disabled]="myForm.form.invalid" *ngIf="action == 'editAttributes'">
          Update
        </button> -->
      </form>
    </div>
    <!-- Filter -->
    <div *ngIf="action == 'addFilter'">
      <div class="filtertabs">
        <mat-tab-group>
          <mat-tab label="Size">
            <ul>
              <li>
                <mat-checkbox>32 Inch</mat-checkbox>
              </li>
              <li>
                <mat-checkbox>52 Inch</mat-checkbox>
              </li>
            </ul>
          </mat-tab>
          <mat-tab label="Space">
            <ul>
              <li>
                <mat-checkbox>250 GB</mat-checkbox>
              </li>
              <li>
                <mat-checkbox>500 GB</mat-checkbox>
              </li>
            </ul>
          </mat-tab>
          <mat-tab label="Processor">
            <ul>
              <li>
                <mat-checkbox>i3</mat-checkbox>
              </li>
              <li>
                <mat-checkbox>i5</mat-checkbox>
              </li>
              <li>
                <mat-checkbox>i9</mat-checkbox>
              </li>
            </ul>
          </mat-tab>
        </mat-tab-group>
        <div class="btngrps">
          <button mat-flat-button color="primary" class="mr-3">
            Apply Filter
          </button>
          <button mat-stroked-button color="warn">
            Clear All
          </button>
        </div>
      </div>
    </div>
    <!-- Add Description -->
    <div *ngIf="action == 'addDescription'">
      <mat-form-field appearance="outline" class="h_auto">
        <mat-label>Description</mat-label>
        <textarea matInput type="textarea" style="height: 80px;" [(ngModel)]="modalData.data.Description"></textarea>
      </mat-form-field>
      <button type="submit" mat-flat-button color="primary" (click)="descriptionsave()">
        Save
      </button>
    </div>
    <!-- addNegotiable -->
    <form *ngIf="action == 'addNegotiable'" #negotiateForm="ngForm" (ngSubmit)="negotiateSubmit()">
      <mat-form-field appearance="outline" class="h_auto">
        <textarea matInput type="textarea" style="height: 80px;" [(ngModel)]="negotiateModal.NegotiationRemark"
          name="NegotiationRemark" required></textarea>
      </mat-form-field>
      <button type="submit" mat-flat-button color="primary" [disabled]="negotiateForm.form.invalid">
        Save
      </button>
    </form>
    <!-- Extend Time -->
    <div *ngIf="action == 'supportExtendTime'" class="support-extime">
      <div class="epica-sidebar">
        <div class="tag"> category </div>
        <div class="task_name" style="font-size: 18px;">
          {{levelOneCategoryName}}
        </div>
      </div>
      <section class="d-flex mt-3 pt-2">
        <article class="bg-secondary text-white py-1 px-3 fw-bold mr-3">
          <small class="d-block text-uppercase opacity-75">exp. resolution Time</small>
          {{levelOneCategoryResolutionTime}} Hrs
        </article>
        <article class="bg-danger text-white py-1 px-3 fw-bold">
          <small class="d-block text-uppercase">Time Taken</small> {{levelOneTimeTaken}} Hrs
        </article>
      </section>
      <form #f="ngForm">
        <div class="row mt-3 pt-3">
          <label class="col-12 fw-bold pb-3" style="font-size: 16px;">Revised Duration</label>
          <div class="col-6 pr-2">
            <mat-form-field appearance="outline" class="single-datepicker" (click)="pickerGR.open()">
              <mat-label>Date</mat-label>
              <input [class.error]="f.submitted && ExtendedDate.invalid" matInput readonly [matDatepicker]="pickerGR"
                (focus)="pickerGR.open()" [(ngModel)]="extendTimeData.ExtendedDate" name="ExtendedDate"
                #ExtendedDate="ngModel" required>
              <mat-datepicker-toggle matSuffix [for]="pickerGR"></mat-datepicker-toggle>
              <mat-datepicker #pickerGR></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-6 pl-2">
            <mat-form-field appearance="outline">
              <mat-select [class.error]="f.submitted && ExtendedTime.invalid" required placeholder="Time"
                [(ngModel)]="extendTimeData.ExtendedTime" name="ExtendedTime" #ExtendedTime="ngModel">
                <mat-option *ngFor="let time of timeFormate" [value]="time.Value">
                  {{time.Time}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12">
            <div class="d-matlab">
              <mat-form-field appearance="outline" class="h_auto">
                <textarea [class.error]="f.submitted && ReasonForDelay.invalid" placeholder="Reason for delay" matInput
                  class="form-control" [(ngModel)]="extendTimeData.ReasonForDelay" name="ReasonForDelay"
                  #ReasonForDelay="ngModel" required style="height: 100px;"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
        <button mat-flat-button color="primary" class="add-lead-btn btn-lg fw-bold mt-3" [disabled]="f.form.invalid"
          (click)="sendOneLevelData()">
          Submit
        </button>
      </form>
    </div>
    <!-- Extend Time Detail -->
    <div *ngIf="action == 'supportExtendTimeDet'" class="support-extime">

      <div class="epica-sidebar">
        <div class="tag">
          Subject
        </div>
        <div class="task_name" style="font-size: 18px;">
          {{modalData?.data?.Subject}}
        </div>
      </div>
      <section class="d-flex mt-3 pt-2 mb-3 pb-3">
        <article class="bg-secondary text-white py-1 px-3 fw-bold mr-3">
          <small class="d-block text-uppercase opacity-75">exp. resolution
            Time</small>{{modalData?.data?.CategoryResolutionTime}} Hours
        </article>
        <article class="bg-danger text-white py-1 px-3 fw-bold">
          <small class="d-block text-uppercase">Time Taken</small>
          {{modalData?.data?.remainingTime?.hour}}.{{modalData?.data?.remainingTime?.minute}} Hours
        </article>
      </section>
      <p>
        <strong class="text-primary d-block pb-2">Revised Duration Requeted</strong>
        {{supportExtend?.ExtendedDate | date}}, {{supportExtend?.ExtendedTime }}
      </p>
      <p>
        <strong class="text-primary d-block pb-2">Reason for Dealy</strong> {{supportExtend?.ReasonForDelay}}
      </p>
      <div class="d-flex mt-3 pt-3">
        <button mat-flat-button color="primary" class="add-lead-btn btn-lg fw-bold" (click)="saveextend(supportExtend)">
          Approve
        </button>
        <button mat-stroked-button class="btn-lg fw-bold ml-3"
          (click)="reAssign(supportExtend,modalData?.data)">Re-Assign</button>
      </div>

    </div>
    <!-- Transfer Specification -->
    <div *ngIf="action == 'transferSpecModal'" class="epica-module">
      <!-- <mat-form-field appearance="outline">
        <mat-label>Category</mat-label>
        <mat-select>
          <mat-option value="1">Cat 1</mat-option>
          <mat-option value="2">Cat 2</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Sub Category</mat-label>
        <mat-select>
          <mat-option value="1">Subcat 1</mat-option>
          <mat-option value="1">Subcat 2</mat-option>
        </mat-select>
      </mat-form-field> -->
      <mat-form-field appearance="outline">
        <mat-label>Item</mat-label>
        <mat-select>
          <mat-option value="1">Laptop</mat-option>
          <mat-option value="2">Desktop</mat-option>
          <mat-option value="3">Keyboard</mat-option>
          <mat-option value="4">Mouse</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Asset Code</mat-label>
        <mat-select>
          <mat-option value="1">#1001</mat-option>
          <mat-option value="2">#1002</mat-option>
          <mat-option value="3">#1003</mat-option>
          <mat-option value="4">#1004</mat-option>
        </mat-select>
      </mat-form-field>
      <p class="d-flex" style="margin-top: -8px;">
        <img src="/assets/images/user.png" style="width: 19px; height: 19px; margin-right: 8px; margin-top: 2px;">
        <span>
          <strong>Prashant Pareek</strong><br>
          <small>EMP03/19/02007</small>
        </span>
      </p>
      <button type="submit" mat-flat-button color="primary" class="btn-lg mt-3" (click)="deviceclose()">
        Submit
      </button>
    </div>

    <!-- Add Agreement -->
    <!-- <div *ngIf="action == 'addAgreement'" class="epica-module">

            <mat-form-field appearance="outline">
                <mat-label>Refer To</mat-label>
                <input matInput>
            </mat-form-field>

            <ckeditor></ckeditor>

            <button type="submit" mat-flat-button color="primary" class="btn-lg mt-3">
                Submit
            </button>
        </div> -->
    <!-- Add Petty Cash -->
    <div *ngIf="action == 'addPettyCash' || action == 'addAdvanceCash'" class="epica-module">
      <form (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">
        <mat-form-field appearance="outline">
          <span matPrefix class="price-prefix">{{userinput?.CompanyCurrencySymbol}}</span>
          <mat-label>Amount Required</mat-label>
          <input type="number" matInput [(ngModel)]="pettycash.RequestAmount" name="RequestAmount" required>
        </mat-form-field>
        <mat-label style="font-weight: 600; font-size: 15px;">Required Before</mat-label>
        <div style="display:flex" class="WorkingRandom">
          <mat-form-field class="datePicker" style="width: 100%;" floatLabel="never" appearance="outline"
            [class.mat-form-field-invalid]="(myForm.submitted) && RequiredBefore.invalid">
            <input matInput [matDatepicker]="picker" (focus)="picker.open()" placeholder="Select Date"
              [(ngModel)]="pettycash.RequiredBefore" name="RequiredBefore" #RequiredBefore="ngModel" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <mat-form-field appearance="outline" class="h_auto">
          <mat-label>Remark</mat-label>
          <textarea matInput required [(ngModel)]="pettycash.Remark" name="Remark"></textarea>
        </mat-form-field>
        <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid" class="btn-lg"
          (click)="saveMaster(pettycash, action == 'addAdvanceCash' ? this.AdvanceUrl + 'AdvanceCashRequestSave' : this.PettyCashUrl + 'PettyCashRequestSave')">
          Save
        </button>
      </form>
    </div>
    <!-- Receive Petty Cash -->
    <div *ngIf="action == 'receivePettyCash'" class="epica-module">
      <form (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">
        <div class="ledgerdiv-rem " style="margin-bottom: 15px !important;">
          <p>Approved Amount <b class="mt-2">{{modalData?.data.CurrencySymbol}} {{modalData?.data.ApprovedAmount}} </b>
          </p>
        </div>

        <!-- <mat-form-field appearance="outline">
                    <span matPrefix class="price-prefix">INR</span>
                    <mat-label>Received Amount</mat-label>
                    <input type="number" matInput [(ngModel)]="pettycash.ReceivedAmount" name="ReceivedAmount" required>
                </mat-form-field> -->

        <mat-form-field appearance="outline">
          <mat-label>Received From</mat-label>
          <mat-select [(ngModel)]="pettycash.ReceivedFromId" name="ReceivedFromId" #ReceivedFromId="ngModel" required>
            <fuse-mat-select-search name="search" [(ngModel)]="SubmittedBySearch">
            </fuse-mat-select-search>
            <mat-option *ngFor="let po of PORaisedByDropDown | filter: SubmittedBySearch" [value]="po?.EmployeeId">
              {{po?.EmployeeName}}</mat-option>
          </mat-select>
        </mat-form-field>

        <button type="submit" mat-flat-button color="primary"
          [disabled]="myForm.form.invalid || pettycash.ReceivedAmount > modalData?.data.ApprovedAmount " class="btn-lg"
          (click)="saveMaster(pettycash, this.PettyCashUrl + 'PettyCashRequestStatusUpdate')">
          Save
        </button>
        <mat-error *ngIf="pettycash.ReceivedAmount > modalData?.data.ApprovedAmount">Invalid Amount</mat-error>
      </form>
    </div>
    <!-- Add Employee Feedback -->
    <div *ngIf="action == 'empFeedback'" class="epica-module empfeedback-pge">
      <form (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">
        <mat-form-field appearance="outline" required>
          <mat-label>Employee</mat-label>
          <mat-select [(ngModel)]="employeeFeedBackSave.GivenToEmployeeId" name="GivenToEmployeeId" required>
            <fuse-mat-select-search name="empoyeeFeedBack" [(ngModel)]="empoyeeFeedBack">
            </fuse-mat-select-search>
            <mat-option class="matselect-deppic" [value]="employee.EmployeeId"
              *ngFor="let employee of employeFeedBackDrop| filter : empoyeeFeedBack">
              <div class="d-flex ">
                <div class="emp-img mr-2">
                  <img *ngIf="!employee.employeeImagePath" src="/assets/images/user.png" alt=""
                    style="height: 40px; width: 40px;">
                  <img *ngIf="employee?.employeeImagePath" src="{{employee.employeeimagefilename}}" alt=""
                    style="height: 40px; width: 40px;">
                </div>
                <div class="emp-name">
                  <p style="font-weight: 600; margin-bottom: 0px; ">{{employee.EmployeeName}}
                  </p>
                  <p style="margin-bottom: 0px; font-size: 13px;">{{employee.DepartmentName}} </p>
                </div>
              </div>
              <!-- {{employee.EmployeeName}} -->
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Title</mat-label>
          <input type="text" matInput [(ngModel)]="employeeFeedBackSave.FeedbackTitle" name="FeedbackTitle" required>
        </mat-form-field>
        <mat-form-field appearance="outline" class="h_auto">
          <mat-label>Description</mat-label>
          <textarea matInput required [(ngModel)]="employeeFeedBackSave.FeedbackDescription"
            name="FeedbackDescription"></textarea>
        </mat-form-field>
        <div class="ratingarea">
          <div class="ratingbx frbx">
            <p>Rating</p>
            <div class="ratingbx">
              <div class="d-flex">
                <div>
                  <ul class="list-inline d-flex align-items-center">
                    <li class="diamond" *ngFor="let number of [0,1,2,3,4,5,6,7,8,9]; let i = index"
                      class="assign_ul_gray color-{{i}}" (click)="selectedRating(i)"
                      [class.active]="i === selectedIndexRate[i] || i === selectedIndexOver[i]"
                      (mouseover)="selectedRatingOver(i)" (mouseout)="selectedIndexOver = []; reviewNumberOver = 0">

                    </li>
                  </ul>

                  <mat-error *ngIf="myForm.submitted && !reviewNumber" class="mt-3">
                    Please Fill Overall Rating
                  </mat-error>

                  <input required type="hidden" [(ngModel)]="employeeFeedBackSave.EmployeeRating" name="EmployeeRating">
                </div>

                <div class="rate_point">
                  <p *ngIf="reviewNumber">({{reviewNumber}}/10)</p>
                  <p *ngIf="!reviewNumber">({{0}}/10)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2 style="color: #fc7a09">Evidence</h2>
        <mat-form-field appearance="outline" class="single-datepicker">
          <mat-label>Date (if any)</mat-label>
          <input matInput [(ngModel)]="employeeFeedBackSave.FeedBackDate" name="FeedBackDate" [matDatepicker]="pickerEv"
            (focus)="pickerEv.open()">
          <mat-datepicker-toggle matSuffix [for]="pickerEv"></mat-datepicker-toggle>
          <mat-datepicker #pickerEv></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" class="h_auto">
          <mat-label>Detail</mat-label>
          <textarea matInput [(ngModel)]="employeeFeedBackSave.FeedbackDetails" name="FeedbackDetails"></textarea>
        </mat-form-field>
        <div class="attach_file" style="margin-top:.25em">
          <img src="/assets/icons/attach_icon.svg" alt=""> Upload Attachment
          <input type="file" (change)="fileUpload($event, employeeFeedBackSave.FeedbackAttachment)" class="upload"
            #myFileInputQR>
        </div>
        <div class="d-flex justify-content-between mt-2">
          <a
            (click)="downloadMyFileOther(employeeFeedBackSave?.FeedbackAttachment)">{{employeeFeedBackSave?.FeedbackAttachment?.DocumentFileName}}</a>
          <a *ngIf="employeeFeedBackSave?.FeedbackAttachment?.Preview || employeeFeedBackSave?.FeedbackAttachment?.DocumentPath"
            (click)="fileUploadRemove(employeeFeedBackSave?.FeedbackAttachment)">
            <mat-icon>close</mat-icon>
          </a>
        </div>
        <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid" class="btn-lg mt-3"
          (click)="saveMaster(employeeFeedBackSave, this.baseUrlEmployeeFeedback + 'EmployeeFeedbackSave')">
          Save
        </button>
      </form>
    </div>
    <!-- employee feedback -->
    <div *ngIf="action =='ViewFeedBack'">
      <form class="row " (ngSubmit)="myForm.form.valid && onSubmitEmpFeedback()" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class=" col-sm-12">
          <div class="d-matlab">
            <mat-form-field appearance="outline" class="h_auto">
              <mat-label>Remark</mat-label>
              <textarea matInput [(ngModel)]="feedbackRemark.Remark" name="Remark" required></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid">Submit</button>
        </div>
      </form>
    </div>
    <!-- employee feedback -->
    <!-- doc print cateogry -->
    <div *ngIf="action =='AddDocprintcategory' || action == 'Editdocprintcategory'">
      <form class="row " (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">
        <div class=" col-sm-12">
          <div class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Category</mat-label>
              <input matInput type="text" class="form-control" required
                [(ngModel)]="DocPrintCategorie.DocPrintCategorieName" name="AssetClassname">
            </mat-form-field>

          </div>

        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster(DocPrintCategorie, this.basedocprintConfg + 'DocumentPrintCategorieSave')">
            <span *ngIf="action == 'AddDocprintcategory'">Save</span>
            <span *ngIf="action == 'Editdocprintcategory'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- doc print type -->
    <div *ngIf="action == 'AddDocprintType' || action == 'EditdocprintType'" class="epica-module">
      <form class="row " (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">
        <div class=" col-sm-12">
          <div class="" class="d-matlab">
            <mat-form-field appearance="outline">
              <mat-label>Category</mat-label>
              <mat-select required [(ngModel)]="docPrintTypeSave.DocPrintCategorieId" name="DocPrintCategorieId">
                <mat-option *ngFor="let list of docPrintCategoryList" [value]="list.DocPrintCategorieId">
                  {{list.DocPrintCategorieName}}</mat-option>
              </mat-select>

              <!-- <mat-select [(ngModel)]="ExtendMonth" name="ExtendMonth" [compareWith]="compareFn" (selectionChange)="getLastDate()">
                                <mat-option [value]="list" *ngFor="let list of monthDropDown">{{list.Month}}
                                </mat-option>
                            </mat-select> -->
            </mat-form-field>
            <div>
              <mat-form-field appearance="outline">

                <mat-label>DocPrint Type</mat-label>
                <input matInput type="text" class="form-control" required
                  [(ngModel)]="docPrintTypeSave.DocPrintTypeName" name="DocPrintTypeName">

              </mat-form-field>

              <p class="maintenance-label">Code Format</p>
              <mat-form-field appearance="outline" style="width: 132px;">
                <input matInput placeholder="Ex: OFL, APL" type="text" [(ngModel)]="docPrintTypeSave.CodeFormat"
                  pattern="[A-Za-z]{3}" onkeyup="this.value = this.value.toUpperCase();" name="CodeFormat"
                  class="form-control" maxlength="3" required>
              </mat-form-field>


            </div>
            <p class="maintenance-label">Doc Template</p>
            <ckeditor [(ngModel)]="docPrintTypeSave.DocTemplate" name="DocTemplate"></ckeditor>
          </div>
        </div>
        <div class=" col-sm-4" style="margin-top:15px">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster(docPrintTypeSave, this.basedocprintTypeConfg + 'DocumentPrintTypeSave')">
            <span *ngIf="action == 'AddDocprintType'">Save</span>
            <span *ngIf="action == 'EditdocprintType'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- Doc cancel -->
    <div *ngIf="action == 'docCancel'" class="epica-module empfeedback-pge">
      <p>
        <mat-checkbox>All</mat-checkbox>
      </p>
      <p>
        <mat-checkbox>11</mat-checkbox>
      </p>
      <p>
        <mat-checkbox>12</mat-checkbox>
      </p>
      <p>
        <mat-checkbox>13</mat-checkbox>
      </p>
      <p>
        <mat-checkbox>14</mat-checkbox>
      </p>
      <p>
        <mat-checkbox>15</mat-checkbox>
      </p>
      <button type="submit" mat-flat-button color="primary" class="btn-lg mt-3">
        Cancel
      </button>
    </div>
    <!-- Emp. Remark -->
    <!-- <div *ngIf="action == 'empRemark'" class="epica-module empfeedback-pge">
            <mat-form-field appearance="outline" class="h_auto">
                <mat-label>Remark</mat-label>
                <textarea matInput type="text" class="form-control" required style="height: 100px;"></textarea>
            </mat-form-field>
            <button type="submit" mat-flat-button color="primary" class="btn-lg mt-3">
                Submit
            </button>
        </div> -->

    <div *ngIf="action == 'add_contact'">
      <h2>Add Employee</h2>
      <div class="form_layout all_depart">
        <div fxLayout="row wrap">
          <div class="flex-item" fxFlex="0 1 100">
            <mat-form-field appearance="outline">
              <mat-label>Department</mat-label>
              <mat-select [(value)]="selected" (selectionChange)="DepartmentDropList($event)">
                <mat-option value="0">All</mat-option>
                <mat-option [value]="dep" *ngFor="let dep of campainDepartment">
                  {{dep.DepartmentName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Search Employee</mat-label>
              <input matInput [(ngModel)]="searchText" autocomplete="off">
              <span matSuffix>
                <mat-icon class="search_icon">search</mat-icon>
              </span>
            </mat-form-field>
          </div>
          <div class="all_depart_innner">
            <div class="flex-item" fxFlex="0 1 100" *ngIf="campainEmployeeList?.length > 0">
              <div class="list_div">
                <div class="alpha_list">
                  <div class="name_list" *ngFor="let user of campainEmployeeList | filter:searchText">
                    <div class="about_employ">
                      <div class="user_img">
                        <img *ngIf="user.EmployeeImagePath" [src]="user.EmployeeImagePath"
                          style="width: 47px; height: 47px; border-radius: 50%;">
                        <img *ngIf="!user.EmployeeImagePath" src="/assets/images/user.png"
                          style="width: 47px; height: 47px; border-radius: 50%;">
                      </div>
                      <div class="user_name">
                        <h5>{{user.EmployeeName}}</h5>
                        <h6>{{user.Designation}}</h6>
                        <h6>{{user.EmployeeCode}}</h6>
                      </div>
                    </div>
                    <div class="user_check">
                      <mat-radio-button [value]="user.EmployeeId" (change)="selectUser($event, user, user.EmployeeId)">
                      </mat-radio-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-item" fxFlex="0 1 100">
            <button mat-flat-button class="blue_btn" [disabled]="selectedUser?.length == 0"
              (click)="addUser(action)">Done</button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="action == 'add_New_contact'" class="prsn_dialog">
      <div class="modal_section addnew-contmodal">
        <div class="form_layout">
          <form fxLayout="row wrap" #f="ngForm">
            <div class="flex-item" fxFlex="0 1 100">
              <div fxLayout="row wrap">
                <div class="flex-item pl-0" fxFlex="0 1 50">
                  <mat-form-field appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input matInput
                      [(ngModel)]="addNewInput.ContactPersonFirstName"
                      name="ContactPersonFirstName" #ContactPersonFirstName="ngModel" required>
                  </mat-form-field>
                </div>
                <div class="flex-item pr-0" fxFlex="0 1 50">
                  <mat-form-field appearance="outline">
                    <mat-label>Last Name</mat-label>
                    <input matInput
                      [(ngModel)]="addNewInput.ContactPersonLastName"
                      name="ContactPersonLastName" #ContactPersonLastName="ngModel" required>
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row wrap">
                <div class="flex-item pl-0" fxFlex="0 1 30">
                  <mat-form-field appearance="outline">
                    <mat-label>Code</mat-label>
                    <mat-select [(ngModel)]="addNewInput.ISDCode"
                      name="ISDCode" #ISDCode="ngModel" (selectionChange)="findCountryCode($event.value)" required>
                      <fuse-mat-select-search name="search" [(ngModel)]="DepartmentSearch">
                      </fuse-mat-select-search>
                      <mat-option *ngFor="let isd of CountryDropList | filter: DepartmentSearch"
                        [value]="isd.CountryCode">
                        {{isd.CountryCode}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="flex-item pr-0" fxFlex="0 1 70">
                  <mat-form-field appearance="outline">
                    <mat-label>Phone Number</mat-label>
                    <input matInput type="text"
                      [(ngModel)]="addNewInput.Mobileno" name="Mobileno"
                      #Mobileno="ngModel" [class.error]="f.submitted && Mobileno.invalid" [maxlength]="mobileDigit"
                      [minlength]="mobileDigit" numbersOnly pattern="\d*" required>
                  </mat-form-field>
                </div>
              </div>
              <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                  [(ngModel)]="addNewInput.Emailid" name="Emailid"
                  #Emailid="ngModel" required>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Designation</mat-label>
                <mat-select [(ngModel)]="addNewInput.Designationid"
                  name="Designationid" #Designationid="ngModel" required>
                  <mat-option *ngFor="let industry of Designations" [value]="industry.DesignationId">
                    {{industry?.DesignationName}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flex-item" fxFlex="0 1 100">
              <button mat-flat-button color="primary" type="submit" (click)="saveAddNew()"
                [disabled]="!f.valid">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- <div *ngIf="action == 'modal-Spam_Move'" class="prsn_dialog spam_dialog">
            <form #f="ngForm">
                <div class="modal_section">
                    <div class="form_layout">
                        <div fxLayout="row wrap">
                            <div class="flex-item" fxFlex="0 1 100">
                                <label for="">Reason to mark as spam</label>
                                 <mat-form-field appearance="outline" class="h_auto">
                                    <textarea matInput type="text" [(ngModel)]="mspam.SpamComment" name="SpamComment" #SpamComment="ngModel" required row="5" style="height: 47px;"></textarea>
                                   </mat-form-field>
                            </div>
                            <div class="flex-item" fxFlex="0 1 100">
                                <button mat-flat-button type="submit" (click)="saveSpamData()" color="primary" [disabled]="!f.valid">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div> -->
    <div *ngIf="action =='modal-Spam_Move'" class="prsn_dialog">
      <form class="row " (ngSubmit)="myForm.form.valid && saveSpamData()" #myForm="ngForm" novalidate
        autocomplete="off">
        <div class=" col-sm-12">
          <div class="d-matlab">
            <mat-form-field appearance="outline" class="h_auto">
              <mat-label>Reason to mark as spam</mat-label>
              <textarea matInput type="text" [(ngModel)]="mspam.SpamComment" name="SpamComment" #SpamComment="ngModel"
                required></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class=" col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid">Save</button>
        </div>
      </form>
    </div>
    <!-- Accommodation Type -->
    <div *ngIf="action == 'AddAccommodationType' || action == 'EditAccommodationType'">
      <form class="row " (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate novalidate autocomplete="off">
        <div class="col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput type="text" [(ngModel)]="saveConveyanceInput.AccomodationName" name="AccomodationName"
              class="form-control" required>
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster(saveConveyanceInput, baseExpenseConfg + 'ExpenseAccomodationSave')">
            <span *ngIf="action == 'AddAccommodationType'">Save</span>
            <span *ngIf="action == 'EditAccommodationType'">Update</span>
          </button>
        </div>
      </form>
    </div>
    <!-- Expense Type -->
    <div *ngIf="action == 'AddExpenseType' || action == 'EditExpenseType'">
      <form class="row " (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate novalidate autocomplete="off">
        <div class="col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput type="text" [(ngModel)]="saveExpenseType.ExpenseName" name="ExpenseName"
              class="form-control" required>
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
            (click)="saveMaster(saveExpenseType, baseExpenseConfg + 'ExpenseTypeSave')">
            <span *ngIf="action == 'AddExpenseType'">Save</span>
            <span *ngIf="action == 'EditExpenseType'">Update</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
