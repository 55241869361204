<div id="planwork-main-content">
  <ng-container *ngIf="router.url.includes('login')">
    <router-outlet></router-outlet>
  </ng-container>
  <ng-container *ngIf="router.url.includes('time-out')">
    <router-outlet></router-outlet>
  </ng-container>
  
  <ng-container
    *ngIf="(!router.url.includes('login') || planworkSettings?.layout?.toolbar === 'show') && (!router.url.includes('time-out'))">
    <app-toolbar></app-toolbar>
  </ng-container>

  <div class="page-layout" *ngIf="(!router.url.includes('login')) && (!router.url.includes('time-out'))">
    <!-- Normal View-->
    <ng-container *ngIf="normalView">
      <div class="sidebar_Show_outer">
        <div id="sidebar_left_main" class="sidebar_Show">
          <app-sidebar></app-sidebar>
        </div>
      </div>
      <div id="main_content_main_page" class="sidebar_Show" (click)="sidebarClose()">
        <router-outlet></router-outlet>
      </div>
    </ng-container>
    <!-- Normal View-->

    <!-- Setting View-->
    <ng-container *ngIf="!normalView">
      <div id="sidebar_left">
        <app-coresidebar></app-coresidebar>
      </div>
      <div id="main_content">
        <router-outlet></router-outlet>
      </div>
    </ng-container>
    <!-- Setting View-->
  </div>
</div>