import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MasterComponent } from './master-compnent';
import { PlanworkSharedModule } from '../../../../../@planwork/planwork.shared.module';
import { FormsModule } from '@angular/forms';
import { FuseMatSelectSearchModule } from '@planwork/mat-select-search/mat-select-search.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticatedHttpService } from '@planwork/services/authenticated-http.service';
import { AddAttributesComponent } from '../settings/add-attributes/add-attributes.component';
 

@NgModule({
  declarations: [MasterComponent, AddAttributesComponent],
  imports: [
    CommonModule, 
    FormsModule,
    PlanworkSharedModule,
    FuseMatSelectSearchModule
  ],
  exports: [
    MasterComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticatedHttpService, multi: true  }
  ],
})
export class MasterCompnentModule { }
