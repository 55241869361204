import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
declare let ClientIP: any;

@Injectable({
  providedIn: 'root'
})
export class ClientipService {
  IPAddress: any;
  constructor(
    public _http: HttpClient,
  ) {
    this._http.get<any>('https://jsonip.com')
      .subscribe(data => {

        const serverIp = data.ip;
        const privateIP = ClientIP;
        const IP = serverIp + ':' + privateIP;
        this.IPAddress = IP;
      });
  }
}
