import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-asset-categories',
  templateUrl: './asset-categories.component.html',
  styleUrls: ['./asset-categories.component.scss']
})
export class AssetCategoriesComponent implements OnInit {
  obj = {
    heading: "Asset Category",
    addModalTitle: "Asset Category",
    addModelAction: "Addassetcategory",
    baseUrlType: 'Asset',
    editModalTitle: "Asset category",
    editModelAction: "Editassetcategory",
    endPoint: "AssetCategoryList",
    endPoint2: "assetcategory",
    addColumns: 1,
    colHeadings: { col1: 'Asset category Name',col2: 'Total Month', col3: ''  },
    sortableColumns: [
      { data: '0', Title: '#' },
      { data: 'assetcategoryname', Title: 'Asset Category' },
      { data: 'assetclassname', Title: 'Asset Class' },
      { data: 'createddate', Title: 'Created on' },
      { data: '4', Title: 'Action' },
    ],
    deleteEndPoint: 'AssetCategoryDelete',
    getListObj: {
      AssetClassName: '_blank'
    }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
