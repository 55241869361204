<div *ngIf="dialogAction == 'shortCloseService'" class="prsn_dialog document_add">
    <button class="closeModal" mat-dialog-close><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <h2>Short Close Service</h2>
        <div class="form_layout">
            <div fxLayout="column wrap">
                <div class="flex-item" fxFlex="0 1 100">
                    <mat-form-field appearance="outline">
                        <mat-label>Reason</mat-label>
                        <mat-select>
                            <mat-option value="1">Reason 1</mat-option>
                            <mat-option value="2">Reason 2</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" style="height: 116px;">
                        <mat-label>Justification</mat-label>
                        <textarea matInput></textarea>
                    </mat-form-field>
                </div>
                <div class="flex-item" fxFlex="0 1 100">
                    <button mat-flat-button color="primary">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="dialogAction == 'taskAssign'" class="prsn_dialog taskassign-modal">
    <button class="closeModal" mat-dialog-close><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <div class="topheading d-flex justify-content-between">
            <h2>
                <small class="text-uppercase">Task</small><br> On Page Optimization
            </h2>
            <div class="d-flex total-assigned">
                <p>
                    <small>Total</small>
                    <span class="bg-primary">22 Hours</span>
                </p>
                <p>
                    <small>Assigned</small>
                    <span class="bg-warning">16 Hours</span>
                </p>
            </div>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th>Assigned to</th>
                    <th>Assigned</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let list of [0,1,2]">
                    <td><img src="/assets/images/user_img02.png" alt=""> Prashant Pareek</td>
                    <td>08 Hours</td>
                    <td>
                        <button mat-raised-button class="btn-iconborder">
                            <mat-icon>visibility</mat-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<!-- occupancyModal -->
<div *ngIf="dialogAction == 'Availability'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <div class="modal_section">
        <h2>Availability</h2>
        <div class="userinfo d-flex">
            <div class="lside d-flex align-items-center">
                <img src="{{allocatedata?.data?.EmployeeImagePath}}" alt="">
                <h3 class="mb-0">{{allocatedata?.data?.EmployeeName}}
                    <span>{{allocatedata?.data?.Department?.DepartmentName}}</span>
                </h3>
            </div>
            <div class="rside d-flex align-items-center">
                <p class="mb-0">{{allocatedata?.data?.Mobile?.ISDCode}} {{allocatedata?.data?.Mobile?.MobileNo}}
                    <br> {{allocatedata?.data?.EmailId}}
                </p>
            </div>
        </div>
        <div class="row fromto-arrow">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-8 pr-0">
                        <mat-form-field appearance="outline" class="fromto-cale">
                            <mat-label>From - To</mat-label>
                            <mat-date-range-input [rangePicker]="picker1" disabled>
                                <input matStartDate [(ngModel)]="allocatedata.startdate" name="startdate" placeholder="Start date">
                                <input matEndDate [(ngModel)]="allocatedata.enddate" name="enddate" placeholder="End date">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker1></mat-date-range-picker>
                        </mat-form-field>
                    </div>
                    <button mat-flat-button color="primary">
                        <mat-icon>arrow_forward_ios</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <table class="table m-0">
            <thead>
                <tr>
                    <th>Occupied Dates</th>
                    <th>Unoccupied Hours</th>
                    <th>Select Hours</th>
                </tr>
            </thead>
            <tbody *ngFor="let list of allocatedata?.partially; let i= index">
                <tr class="position-relative">

                    <td>
                        {{list.OccupiedDate | date:'dd/MM/yyyy'}}
                    </td>
                    <td>
                        <span class="d-flex align-items-center">{{list?.UnOccupiedHoursFrom | date :'shortTime'}} to
                            {{list?.UnOccupiedHoursTo | date :'shortTime'}}</span>
                        <small *ngIf="list.UnOccupiedHoursFrom && list.UnOccupiedHoursTo">({{list?.partiallytotaltime ?
                            list?.partiallytotaltime : 'NA'}} Hours)</small>
                    </td>
                    <td>
                        <div class="d-flex">
                            <!-- [disabled]="time.isoption == 1"  -->
                            <mat-select placeholder="Select" (selectionChange)="SelectValidaiton($event,list,i);SelectTime(list);" [disabled]="this.tasktime > list?.partiallytotaltime || list?.isdisabled" [(ngModel)]="list.AssignHoursFrom">
                                <mat-option *ngFor="let time of timeFormate" [disabled]="time.Value < list?.UnOccFrom || time.Value > list?.UnOccTo" [value]="time.Value">{{time?.Time}}
                                </mat-option>
                            </mat-select>
                            <span class="totext">To</span>
                            <mat-select placeholder="Select" disabled [(ngModel)]="list.AssignHoursTo" (selectionChange)="SelectValidaiton($event,list,i); SelectTime(list)">
                                <mat-option *ngFor="let time of timeFormate" [value]="time.Value">{{time?.Time}}
                                </mat-option>
                            </mat-select>
                            <p class="text-danger cursor-pointer text-right cross-btn" *ngIf="list.isdisabled || list.isClear" (click)="SelectClear($event,list,i);">
                                <mat-icon style="font-size: 16px;">close</mat-icon>
                            </p>
                        </div>
                        <small *ngIf="list.AssignHoursFrom && list.AssignHoursTo && list?.assigntime != 'undefined:undefined'">({{list?.assigntime}}
                            Hour)</small>

                    </td>
                </tr>
            </tbody>

        </table>
        <mat-error class="mb-3">{{errormessage}}</mat-error>
        <button mat-flat-button color="primary" class="m-0" (click)="AssignModal()" [disabled]="!UnOccupiedHoursflag">Assign</button>
        <!-- <button mat-flat-button color="primary">Submit Request</button> -->
    </div>
</div>
<!-- occupancy modal -->
<div *ngIf="dialogAction == 'occupancyModal'" class="prsn_dialog">
    <button class="closeModal" mat-dialog-close><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <h2>Occupancy</h2>
        <div class="userinfo d-flex">
            <div class="lside d-flex align-items-center">
                <img [src]="allocatedata?.UnAvaliableEmployee?.EmployeeImagePath" alt="">
                <h3 class="mb-0">{{allocatedata?.UnAvaliableEmployee?.EmployeeName}}
                    <span>{{allocatedata?.UnAvaliableEmployee?.Department?.DepartmentName}}</span>
                </h3>
            </div>
            <div class="rside d-flex align-items-center">
                <p class="mb-0">{{allocatedata?.UnAvaliableEmployee?.Mobile?.ISDCode}} {{allocatedata?.UnAvaliableEmployee?.Mobile?.MobileNo}}
                    <br> {{allocatedata?.UnAvaliableEmployee?.EmailId}}
                </p>
            </div>
        </div>
        <div class="row fromto-arrow">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-8 pr-0">
                        <mat-form-field appearance="outline" class="fromto-cale">
                            <mat-label>From - To</mat-label>
                            <mat-date-range-input [rangePicker]="picker1">
                                <input matStartDate [(ngModel)]="allocatedata.startdate" name="startdate" placeholder="Start date">
                                <input matEndDate [(ngModel)]="allocatedata.enddate" name="enddate" placeholder="End date">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker1></mat-date-range-picker>
                        </mat-form-field>
                    </div>
                    <button mat-flat-button color="primary">
                        <mat-icon>arrow_forward_ios</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <table class="table m-0">
            <thead>
                <tr>
                    <th>Occupied Dates</th>
                    <th>Occupied Hours</th>
                    <th>Detach Hours</th>
                </tr>
            </thead>
            <tbody>
                <tr class="position-relative" *ngFor="let list of allocatedata?.UnAvaliableresource">
                    <td>
                        {{list.OccupiedDate | date:'dd/MM/yyyy'}}
                    </td>
                    <td>
                        <span class="d-flex align-items-center">{{list.OccupiedHoursFrom | date :'shortTime'}} to
                            {{list.OccupiedHoursTo | date :'shortTime'}} <a class="cursor-pointer"
                                (click)="Assignment(list)"><img src="/assets/images/blue-rightarrow.svg"
                                    alt=""></a></span>
                        <small *ngIf="list.OccupiedHoursFrom && list.OccupiedHoursTo ">({{list?.Occupaiedtime}}
                            Hours)</small>
                    </td>
                    <td>
                        <div class="d-flex">



                            <mat-select placeholder="Select" (selectionChange)="DetachValidation($event, list);DetachTimeTotal(list)" [disabled]="tasktime > list.Occupaiedtime || (occpflag && UnOccupiedHoursflag)" [(ngModel)]="list.DetachHoursFrom">
                                <mat-option *ngFor="let time of list.detachHoursList" [disabled]="time.Value < list?.OccFrom || time.Value > list?.OccTo" [value]="time.Value">{{time?.Time}}
                                </mat-option>
                            </mat-select>


                            <span class="totext">To</span>
                            <mat-select placeholder="Select" disabled (selectionChange)="DetachValidation($event, list);DetachTimeTotal(list)" [(ngModel)]="list.DetachHoursFromTo">
                                <mat-option *ngFor="let time of timeFormate" [value]="time.Value">{{time?.Time}}
                                </mat-option>
                            </mat-select>
                            <p class="text-danger cursor-pointer text-right cross-btn" *ngIf="cleartimeselect" (click)="DetachClear($event,list);">
                                <mat-icon style="font-size: 16px;">close</mat-icon>
                            </p>
                        </div>
                        <small *ngIf="list.DetachHoursFrom && list.DetachHoursFromTo && list?.Detachtime !='undefined:undefined'">({{list?.Detachtime}}
                            Hours)</small>

                    </td>
                </tr>
            </tbody>

        </table>
        <mat-error class="mb-3">{{errormessage}}</mat-error>
        <!-- <button mat-flat-button color="primary">Submit Request</button> -->

        <button mat-flat-button color="primary" (click)="detachrequest()" [disabled]="!OccupiedHoursflag">Detach
            Request</button>
    </div>
</div>
<!-- setDependency Modal -->
<div *ngIf="dialogAction == 'setDependency'" class="prsn_dialog">
    <button class="closeModal" mat-dialog-close><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <h2>Set Dependency</h2>
        <div class="dependency-box d-flex">
            <div class="row row-spacing" style="width: 100%;">
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Dependency Type</mat-label>
                        <mat-select>
                            <mat-option value="1">Start to start</mat-option>
                            <mat-option value="2">Start to Finish</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Select Predecessor</mat-label>
                        <mat-select>
                            <mat-option value="1">Start to start</mat-option>
                            <mat-option value="2">Start to Finish</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Select Successor</mat-label>
                        <mat-select>
                            <mat-option value="1">Start to start</mat-option>
                            <mat-option value="2">Start to Finish</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="icon-btn">
                <button>
                    <mat-icon>add_circle</mat-icon>
                </button>
            </div>
        </div>
        <button mat-flat-button mat-primary color="primary">Save</button>
    </div>
</div>

<!-- Assignment -->
<div *ngIf="dialogAction == 'assignment' || dialogAction == 'assignmentCalendar' || dialogAction == 'assignmentDetach'" class="prsn_dialog assignment_section">
    <button class="closeModal" mat-dialog-close><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <h2>Assignments</h2>
        <div class="assignment_user">
            <div class="user_top_detail" *ngIf="dialogAction == 'assignment'">
                <div class="assign_user">
                    <img [src]="allocatedata?.UnAvaliableEmployee?.EmployeeImagePath" alt="">
                    <div class="user_dtl_assign">
                        <h4>{{allocatedata?.UnAvaliableEmployee?.EmployeeName}}</h4>
                        <span>{{allocatedata?.UnAvaliableEmployee?.Department?.DepartmentName}}</span>
                        <button type="button" mat-flat-button color="primary">Detach Request to All Managers</button>
                    </div>
                </div>
                <div class="user_mail">
                    <p class="mb-0">{{allocatedata?.UnAvaliableEmployee?.Mobile?.ISDCode}} {{allocatedata?.UnAvaliableEmployee?.Mobile?.MobileNo}}
                        <br> {{allocatedata?.UnAvaliableEmployee?.EmailId}}
                    </p>
                </div>
            </div>
            <div class="user_top_detail" *ngIf="dialogAction == 'assignmentCalendar'">
                <div class="assign_user">
                    <img [src]="assignmentCalendarData?.EmployeeImagePath" alt="">
                    <div class="user_dtl_assign">
                        <h4>{{assignmentCalendarData?.EmployeeName}}</h4>
                        <span>{{assignmentCalendarData?.DepartmentName}}</span>
                        <button type="button" mat-flat-button color="primary" *ngIf="isDetachAllManager" [disabled]="assignmentCalendarData.clickedDate < currentDate" (click)="detachrequestStaffingEmployee()">
                        Detach Request to All Managers</button>
                    </div>
                </div>
                <div class="user_mail">
                    <p class="mb-0">{{assignmentCalendarData?.ISDCode}} {{assignmentCalendarData?.MobileNo}}
                        <br> {{assignmentCalendarData?.EmailId}}
                    </p>
                </div>
            </div>
            <div class="user_top_detail" *ngIf="dialogAction == 'assignmentDetach'">
                <div class="assign_user">
                    <img [src]="assignmentDetachData?.RequestedFor?.EmployeeImagePath" alt="">
                    <div class="user_dtl_assign">
                        <h4>{{assignmentDetachData?.RequestedFor?.EmployeeName}}</h4>
                        <span>{{assignmentDetachData?.RequestedFor?.Department}}</span>
                    </div>
                </div>
                <div class="user_mail">
                    <p class="mb-0">{{assignmentCalendarData?.ISDCode}} {{assignmentCalendarData?.MobileNo}}
                        <br> {{assignmentCalendarData?.EmailId}}
                    </p>
                </div>
            </div>
            <div class="project_assign_dtl">
                <div class="top_assign">
                    <div class="left_assign">
                        <span *ngIf="dialogAction != 'assignmentDetach'">
                            <mat-icon>keyboard_arrow_left</mat-icon>
                        </span>
                        <div class="date">{{AssignmentData?.Result[0]?.TaskDate | date:'dd/MM/yyyy'}}</div>
                        <span *ngIf="dialogAction != 'assignmentDetach'">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </span>
                    </div>
                    <div class="right_assign">
                        <span class="red">{{AssignmentData?.OccupiedHours}}</span>
                        <span class="green">{{AssignmentData?.UnOccupiedHours}}</span>
                    </div>
                </div>
                <div class="assign_listing">
                    <div class="assign_box" *ngFor="let list of AssignmentData?.Result">
                        <div class="left_dtl_assign">
                            <div *ngIf="list?.EmployeeTimeSheet?.SubmissionDate" class="mb-2">
                                <span class="verify-timefill">Timesheet Submitted</span>
                            </div>
                            <div class="section">
                                <label for="">{{list?.Service?.ServiceName ? 'Service' : 'Project'}}</label>
                                <p>{{list?.Service?.ServiceName ? list?.Service?.ServiceName : list?.Project?.ProjectName}}</p>
                            </div>
                            <div class="section">
                                <label for="">Task</label>
                                <p>{{list?.TaskName ? list?.TaskName : 'NA'}}</p>
                            </div>
                            <div class="section">
                                <label for="">Schedule</label>
                                <p>{{list?.TaskStartTime | date :'shortTime'}} - {{list?.TaskEndTime | date :'shortTime'}} <span>({{list?.Schedule}})</span></p>
                            </div>
                        </div>
                        <div class="right_dtl_assign" *ngIf="dialogAction != 'assignmentDetach'">
                            <div class="project_manager">
                                <h3>Project Manager</h3>
                                <span><img src="{{list?.AssignBy?.EmployeeImagePath}}" alt=""></span>
                                <p><b>{{list?.AssignBy?.EmployeeName}}</b></p>
                                <p>{{list?.AssignBy?.Mobile?.ISDCode}} {{list?.AssignBy?.Mobile?.MobileNo}}</p>
                                <p>{{list?.AssignBy?.EmailId}}</p>
                                <button type="button" mat-flat-button color="primary" class="mt-3" *ngIf="!list?.EmployeeTimeSheet?.SubmissionDate" (click)="detachrequestEmployee(list)" [disabled]="assignmentCalendarData.clickedDate < currentDate">Request for
                                    Detach</button>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" mat-flat-button color="primary" class="mt-5" *ngIf="isDetachAllManager && dialogAction != 'assignmentDetach'" [disabled]="assignmentCalendarData.clickedDate < currentDate" (click)="detachrequestStaffingEmployee()">Detach
                    Request to All
                    Managers</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="dialogAction == 'staffingOcc'" class="prsn_dialog assignment_section">
    <button class="closeModal" mat-dialog-close><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <h2>Occupancy</h2>
        <p><strong>{{occpEmpData?.employeeName}}</strong> is occupied with following managers, please send detach request to add on your project/service.</p>
        <div class="assignment_user">
            <div class="project_assign_dtl">
                <div class="top_assign">
                    <div class="left_assign">
                        <div class="date">{{occpEmpData?.startDate | date}} to {{occpEmpData?.endDate | date}}</div>
                    </div>
                    <button type="button" mat-flat-button (click)="detachrequestStaffing()" color="primary">Detach
                        Request to All Managers</button>
                </div>
                <div class="assign_listing">
                    <div class="assign_box d-block" *ngFor="let list of staffingOccp">
                        <div class="left_dtl_assign">
                            <div class="section">
                                <label for="">{{list?.Service?.ServiceName ? 'Service' : 'Project'}}</label>
                                <p>{{list?.Service?.ServiceName ? list?.Service?.ServiceName : list?.Project?.ProjectName}}
                                </p>
                            </div>
                        </div>
                        <div class="left_dtl_assign pt-0">
                            <div class="user_top_detail">
                                <div class="assign_user">
                                    <img src="/assets/images/user.png" alt="" *ngIf="!list?.AssignBy?.EmployeeImagePath">
                                    <img [src]="list?.AssignBy?.EmployeeImagePath" alt="" *ngIf="list?.AssignBy?.EmployeeImagePath">
                                    <div class="user_dtl_assign">
                                        <h4>{{list?.AssignBy?.EmployeeName}}</h4>
                                        <span>{{list?.AssignBy?.Designation?.DesignationName}}</span>
                                    </div>
                                </div>
                                <div class="user_mail">
                                    <span>{{list?.AssignBy?.EmailId}}</span>
                                    <span>{{list?.AssignBy?.Mobile?.ISDCode}}
                                        {{list?.AssignBy?.Mobile?.MobileNo}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" mat-flat-button color="primary" (click)="detachrequestStaffing()" class="mt-5">Detach Request to All
                        Managers</button>
                </div>
            </div>
        </div>
    </div>
</div>