import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchfilter'
})
export class SearchfilterPipe implements PipeTransform {

  transform(datasearch: any[], searchValue: string): any[] {
    if (!datasearch || !searchValue) {
      return datasearch;
    }
    return datasearch.filter(res => res.Name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
  }

}
