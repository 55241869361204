import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { DatatableService } from '@planwork/datatable-settings';
import { ServiceService } from "app/main/core/masterservice/service.service";
import { ModalpopupComponent } from "../modalpopup/modalpopup.component";
import { MatPaginator } from "@angular/material/paginator";
import { CommonModalComponent } from "../common-modal/common-modal.component";
import { makeArrayList, makeArrayListchange } from '../deleteObjMaking'
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from "@angular/router";
import { MAT_DATE_FORMATS } from '@angular/material/core';
import * as moment from 'moment';
import { planworkConfig } from "app/planwork.config";
import { TeamleadModalComponent } from "app/main/campaign/team-lead/teamlead-modal/teamlead-modal.component";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { DropdownService } from '@planwork/services/dropdown.service';
import { log } from "console";
import { ApiService } from "@planwork/services/api.service";
import { T } from "@angular/cdk/keycodes";

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};
const axios = require('axios').default;

export interface Datatype {
  industryTypeName: string;
  industryTypeCode: any;
  created: string;
}
@Component({
  selector: 'master-compnent-app',
  templateUrl: './master-compnent.html',
  styleUrls: ['./master-compnent.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class MasterComponent implements OnInit, AfterViewInit, OnDestroy {
  employeeCategorys: any = [
    { EmployeeCategoryId: 1, EmployeeCategoryName: 'Employee', EmployeeCategoryCode: null },
    { EmployeeCategoryId: 2, EmployeeCategoryName: 'Intern', EmployeeCategoryCode: null },
    { EmployeeCategoryId: 3, EmployeeCategoryName: 'Trainee', EmployeeCategoryCode: null },
    { EmployeeCategoryId: 4, EmployeeCategoryName: 'External', EmployeeCategoryCode: null },
  ]
  dialogRefModal: any;
  showEdit: any;
  recordsTotal: any;
  dateSearch: any
  persons: any[] = [];
  masterSelected: boolean;
  Ischecked: any;
  disable = true;
  selectcheckbox: any[];
  workingSheDeleteIds: any[] = [];
  CountryDropDownlist: any[] = [];
  StateLists: any[] = [];
  CountrySearch = '';
  StateSearch = '';
  CountryName: any;
  Country: any;
  Categorytype: any = '';
  ServiceCategoryName: any = '';
  SBU = '';
  docPrint = '';
  serviceList: any[] = [];



  headingsNew: any;
  @Input() obj: any;
  tableSetting: any = {};
  ShiftIds: any = {};
  pageNumbers: number[];
  length: 10
  popupComp: any
  colHeadings: any
  //baseUrl = 'http://planwork.vertexplus.com/'
  baseUrl: any;
  checkPageData: any;
  SpinnerTimeout = 500;
  goTo: number;
  searchContent: any;
  displayedColumns: any[] = []
  Datatype: any[] = [];
  dialogRef: any;
  dataSource: MatTableDataSource<Datatype>;
  @Input() pageSizeOptions: number[] = [10, 25, 50, 75, 100];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  noRec = false;
  serachText: any;
  totalRecords: any;
  pageIndex = 0
  itemsPerPage: number;
  searchText: any;
  pageSize: number;
  masterList: Datatype[];
  service: any;
  tblClmLength: any;
  tblClm: any[] = [];
  errMsg: any;
  search: any;
  ObjData: any;
  inputData: any;
  restictioninput: any;
  tabledata: any;
  employeeDropDownList: any = {};
  employeeCategoryDropDownList: any = {};
  ServiceCatActivityDropDownList: any;
  ServiceCatSubActivityDropDownList: any;
  ServiceActivityDropDownList: any;
  employeeCategory: any = {};
  ShiftName: any = '';
  CountryId: any = '';
  StateId: any;
  CategoryName: any = '';
  AssetClassId: any;
  AssetCategoryId: any;
  AssetItemId: any;
  AssetClassDropdown: any[] = [];
  AssetCategoryDropdown: any[] = [];
  AssetItemDropdown: any[] = [];
  ServiceActivityName: any = '';
  ServiceSubActivityName: any = '';
  CostCenterCategory: any = {
  };
  ServiceCategory: any = {
  };
  ServiceActivity: any = {
  };
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  configure: any;
  employeeId: any;
  resp: any;
  public timing;
  selected = 'all';
  selected1 = 'all';
  selected2 = 'all';
  selected4 = 'all';
  selected5 = 'all';
  GetCompanyShiftList: any[] = [];
  CompanyCategoryDropDown: any[] = [];
  check: any;
  variationlen: any[] = [];
  checklast: any[] = [];
  docPrintCategoryList: any = []
  Result: any[] = [
    {

    }
  ];
  SbuList: any[] = [];
  //Delete
  Otheractivity: string;
  apiUrl: any;
  private baseloginUrl: any;
  private baseUrlConfing: any;
  private baseKeyUrl: any;
  private baseUrlClient: any;
  private baseUrlEmployee: any;
  private userRole: any;
  private baseDashboardUrl: any;
  private baseUrlService: any;
  private baseLeadForFC: any;
  private baseServiceURL: any;
  private baseBillingURL: any;
  private baseInvoiceURL: any;
  private baseEmployeeURL: any;
  private globalBaseUrl: any;
  private EmployeeBaseUrl: any;
  private EmpTaxInvoiceBaseUrl: any;
  private ClientBaseUrl: any;
  private companyBaseUrl: any;
  private BillingBaseUrl: any;
  private companyCoreUrl: any;
  private companyCoreUrlservice: any;
  private LeadBaseUrl: any;
  private CampaignBaseUrl: any;
  private baseUrlAllocate: any;
  private baseUrlStaffing: any;
  DocPrintCategory: "";
  isAttributesShow = false;
  attributeNameList: any;
  attributeIdList: any;
  attributeKeyList: any;
  attributeClassId: any;
  attributeCategorieId: any;
  attributeItemId: any;
  attributeClassName: any;
  attributeCategorieName: any;
  attributeItemName: any;
  constructor(
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private http: HttpClient,
    private masterService: ServiceService,
    public snackBar: MatSnackBar,
    private datatableServise: DatatableService,
    private router: Router,
    private dropdownService: DropdownService,
    private apiService: ApiService
  ) {
    this.tableSetting = this.datatableServise.tablePagination();
    this.baseUrl = planworkConfig.baseUrl;
    this.masterSelected = false;
  }
  async ngOnInit() {
    console.log(this.obj)
    this.showEdit = true;
    this.tblClm = this.obj.sortableColumns
    this.tblClmLength = this.tblClm.length;
    this.colHeadings = this.obj.colHeadings;
    this.ListMaking();
    console.log(this.obj.endPoint);
    if (this.obj.endPoint == 'ServiceTypeList') {

      this.servicecategory()
    }

    if (this.obj.endPoint == 'AssetCategoryList') {
      let assteClassDrop = await this.AssetClassDrop()
      this.AssetClassDropdown = assteClassDrop
      this.empFilter('asset');
    }
    if (this.obj.endPoint == 'AssetItemList') {
      let assteClassDrop = await this.AssetClassDrop()
      this.AssetClassDropdown = assteClassDrop;

      // this.empFilter('assetItem');
    }
    if (this.obj.endPoint == 'AttributeList') {
      let assteClassDrop = await this.AssetClassDrop()
      this.AssetClassDropdown = assteClassDrop;

      // let assteCategoryDrop = await this.AssetCategoryDropDown()
      // this.AssetCategoryDropdown = assteCategoryDrop
      // let assteItemDrop = await this.AssetItemDropDown()
      // this.AssetItemDropdown = assteItemDrop
      this.empFilter('assetAttri');
    }
    if (this.obj.endPoint == 'ServiceActivityList' || this.obj.endPoint == 'ServiceSubActivityList' || this.obj.endPoint == 'ServiceTaskList') {
      this.ActivityDrpList();
    }
    if (this.obj.endPoint == 'DocumentPrintTypeList') {
      this.docPrintCategory();
    }
    if (this.obj.endPoint == 'EmployeeListNew' || this.obj.endPoint == 'ExistEmployeeList') {
      // this.employeeDrpList();
      this.employeeCategoryDrpList();
    }

    if (this.obj.endPoint == 'EmployeeListNew') {
      const tokenData = JSON.parse(localStorage.getItem('userid'));
      if (tokenData) {
        const primaryRole = tokenData.UserRoleList.filter(x => x.IsPrimaryRole === true)[0];
        this.configure = primaryRole.UserRolePermissionList.filter(x => x.Mid === 'configure')[0];
        const children = this.configure.children.filter(x => x.id === 'resources')[0];
        this.employeeId = children.children.filter(x => x.id === 'employee')[0];
      }
    }
    if (this.obj.endPoint == 'WorkingScheduleList') {

      let GetCompanyDepartmentemp = await this.GetCompanyshift();
      this.GetCompanyShiftList = GetCompanyDepartmentemp.Result;
      this.empFilter('all');
    }
    if (this.obj.endPoint == 'ServiceCategoryList') {
      let GetSbuHead = await this.SbuHeadList();
      this.SbuList = GetSbuHead.Result;
      console.log(this.SbuList);
    }
    if (this.obj.endPoint == 'EmployeeListNew' || this.obj.endPoint == 'ExistEmployeeList') {
      this.empFilter('all');
    }

    if (this.obj.endPoint == 'CityList') {
      let countrydropdown = await this.Countrylist();
      this.CountryDropDownlist = countrydropdown;
      console.log(this.CountryDropDownlist);
    }
    if (this.obj.endPoint == 'StateList') {
      this.inputData = {
        CountryId: 0,

      }
      let countrydropdown = await this.Countrylist();
      this.CountryDropDownlist = countrydropdown;
      console.log(countrydropdown);
      console.log(this.CountryDropDownlist);
    }
    // this.servicecategory();

  }




  GetCompanyshift(): Promise<any> {
    return this.dropdownService.GetCompanyShiftList({}).toPromise()
  }
  SbuHeadList(): Promise<any> {
    return this.dropdownService.SBHList({}).toPromise()
  }

  Countrylist(): Promise<any> {
    return this.dropdownService.CountryDropDownlist({}).toPromise();
  }
  AssetClassDrop(): Promise<any> {
    return this.dropdownService.GetAssetClassDropDownList({}).toPromise()
  }
  AssetCategoryDropDown
  setItemDropDown(): Promise<any> {
    return this.dropdownService.GetAssetItemDropDownList({}).toPromise()
  }

  getStateDropDown = (id) => {
    console.log(id);
    this.spinner.show();
    this.masterService.postService(null, 'StateDropDownList?CountryId=' + id)
      .subscribe(response => {
        this.StateLists = response.body;
        console.log(this.StateLists);
        this.spinner.hide();
      })

    this.inputData = {
      CountryId: id,

    }
    this.masterService.postService(this.inputData, 'CityList')
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }


  async ListMaking() {

    console.log(this.obj.baseUrlType)
    switch (this.obj.baseUrlType) {
      case 'DocPrintCategories':
        this.baseUrl = this.baseUrl + 'DocPrint/api/DocPrintCategories/'
        break;
      case 'CompliancesCore':
        this.baseUrl = this.baseUrl + 'Compliances/api/Compliances/'
        break;
      case 'DocPrintType':
        this.baseUrl = this.baseUrl + 'DocPrint/api/DocPrintType/'
        break;
      case 'OtherActivity':
        this.baseUrl = this.baseUrl + 'Task/api/OtherActivity/'
        break;
      case 'GlobalCore':
        this.baseUrl = this.baseUrl + 'GlobalCore/api/Configuration/'
        break;
      case 'Asset':
        this.baseUrl = this.baseUrl + 'Asset/api/Asset/'
        break;
      case 'Expense':
        this.baseUrl = this.baseUrl + 'Expense/api/Expense/'
        break;
      case 'EmployeeCore':
        this.baseUrl = this.baseUrl + 'EmployeeCore/api/Employee/'
        break;
      case 'ClientCore':
        this.baseUrl = this.baseUrl + 'ClientCore/api/Client/'
        break;
      case 'CompanyCore':
        this.baseUrl = this.baseUrl + 'CompanyCore/api/CompanyDetail/'
        break;
      case 'Lead':
        this.baseUrl = this.baseUrl + 'Lead/api/Configuration/'
        break;
      case 'Billing':
        this.baseUrl = this.baseUrl + 'Billing/api/Configuration/'
        break;
      case 'Consumable':
        this.baseUrl = this.baseUrl + 'Consumable/api/Consumable/'
        break;
      case 'EmployeeCore':
        this.baseUrl = this.baseUrl + 'EmployeeCore/api/Employee/'
        break;
      case 'EmployeeCore':
        this.baseUrl = this.baseUrl + 'EmployeeCore/api/Employee/'
        break;
      case 'ServiceCore':
        this.baseUrl = this.baseUrl + 'Service/api/Service/'
        break;

    }
    // this.spinner.show();
    // if (this.obj.endPoint === 'WorkingScheduleList') {
    //   this.inputData = {
    //     ShiftName: ''
    //   }
    // }
    // if (this.obj.endPoint === 'AssetCategoryList') {
    //   this.inputData = {
    //     AssetClassId: ""
    //   }
    // }
    // if (this.obj.endPoint === 'AssetItemList') {
    //   this.inputData = {
    //     AssetCategoryName: "",
    //     AssetClassName: ""
    //   }
    // }
    // if (this.obj.endPoint === 'AttributeList') {
    //   this.inputData = {
    //     AssetCategoryName: "",
    //     AssetClassName: "",
    //     AssetItemName: "",
    //   }
    // }
    if (this.obj.endPoint === 'ServiceCategoryList') {
      this.restictioninput = {
        ServiceCategoryId: 0,
        ServiceCategoryName: "",
        IsPlanningRestriction: false,
        IsApproved: '1',

      }

    }

    else if (this.obj.endPoint === 'CityList') {
      this.inputData = {
        CountryId: 0,
        StateId: 0,
      }
    }
    else if (this.obj.endPoint === 'ServiceActivityList') {
      this.inputData = {
        CategoryName: ''
      }
    }
    else if (this.obj.endPoint === 'ServiceSubActivityList') {
      this.inputData = {
        CategoryName: '',
        ServiceActivityName: '',

      }
    }
    else if (this.obj.endPoint === 'ServiceTaskList') {
      this.inputData = {
        CategoryName: '',
        ServiceActivityName: '',
        ServiceSubActivityName: ''
      }
    }
    else {
      this.inputData = {
        employeeCategory: {}
      }
    }
    console.log(this.obj.baseUrlType)
    const that = this;
    this.dtOptions = {
      language: { searchPlaceholder: 'Search by keyword' },
      ...this.tableSetting.pagination,
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        clearTimeout(this.timing)
        console.log(localStorage.getItem('token'))
        let global =
        {
          ...dataTablesParameters
        }
        let otherurl = {
          Search:
          {
            ...dataTablesParameters,
            value: dataTablesParameters.search.value,
            regex: dataTablesParameters.search.regex,
          },
        }
        console.log(this.obj.baseUrlType)
        if (this.obj.baseUrlType == 'Asset' || this.obj.baseUrlType == 'Consumable' || this.obj.baseUrlType == 'Expense' || this.obj.baseUrlType == 'DocPrintCategories' || this.obj.baseUrlType == 'DocPrintType' || this.obj.baseUrlType == 'CompliancesCore') {
          inputurldata = otherurl
        }
        else {
          var inputurldata = global
        }
        this.timing = setTimeout(() => {
          that.http.post(this.baseUrl + this.obj.endPoint,

            {

              ...inputurldata,
              ...this.obj.getListObj,
              ...this.inputData
            },
            {
              headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
            }).subscribe(resp => {
              this.spinner.hide();
              this.resp = resp
              this.searchText = dataTablesParameters.search.value;
              this.tabledata = dataTablesParameters;
              let array;
              console.log(this.resp.Result);

              console.log(this.obj.endPoint);
              if (this.obj.endPoint === 'ShiftList')// This is only for ShiftList
              {

                const result = this.resp.Result.ShiftList;
                for (var i = 0; i < result.length; i++) {
                  if (result[i].hasOwnProperty("FromTime")) {
                    result[i]["ShiftFromTime"] = result[i]["FromTime"];
                    result[i]["FromTimeOriginal"] = result[i]["FromTime"];
                    delete result[i]["FromTime"];
                  }
                  if (result[i].hasOwnProperty("ToTime")) {
                    result[i]["ShiftToTime"] = result[i]["ToTime"];
                    result[i]["ToTimeOriginal"] = result[i]["ToTime"];
                    delete result[i]["ToTime"];
                  }
                  result[i]["ShiftFromTime"] = moment(result[i]["ShiftFromTime"], 'HH:mm:ss').format('hh:mm A');
                  result[i]["ShiftToTime"] = moment(result[i]["ShiftToTime"], 'HH:mm:ss').format('hh:mm A');
                }
                console.log(this.obj.baseUrlType);

                if (this.obj.baseUrlType == 'Asset' || this.obj.baseUrlType == 'Consumable' || this.obj.baseUrlType == 'Expense') {

                  array = makeArrayList(this.resp.Result);
                }
                else {

                  array = makeArrayListchange(this.resp.Result, this.obj.endPoint);
                }

              }
              else if (this.obj.endPoint === 'WorkingScheduleList')// This is only for ShiftList
              {
                const result = this.resp.Result.WorkingScheduleList;
                if (this.resp.Result.WorkingScheduleList) {
                  this.masterSelected = false
                }
                console.log(result);
                for (var i = 0; i < result.length; i++) {
                  if (result[i].hasOwnProperty("FromTime")) {
                    result[i]["WorkingScheduleFromTime"] = result[i]["FromTime"];
                    result[i]["FromTimeOriginal"] = result[i]["FromTime"];
                    delete result[i]["FromTime"];
                  }
                  if (result[i].hasOwnProperty("ToTime")) {
                    result[i]["WorkingScheduleToTime"] = result[i]["ToTime"];
                    result[i]["ToTimeOriginal"] = result[i]["ToTime"];
                    delete result[i]["ToTime"];
                  }
                  if (result[i]?.BreakList == null) {
                    result[i].BreakList = [{}]
                  }

                  console.log(result[i]?.BreakList[0] !== undefined);
                  console.log(result[i]?.BreakList[0] !== null);
                  console.log(result[i]?.BreakList[0]);

                  console.log(result[i]?.BreakList[0] !== undefined && result[i]?.BreakList[0] !== null)

                  if (result[i]?.BreakList[0] !== undefined && result[i]?.BreakList[0] !== null) {

                    if (result[i]?.BreakList[0].hasOwnProperty("FromTime")) {
                      result[i]["WorkingScheduleBreakFromTime"] = result[i].BreakList[0]["FromTime"];
                      result[i]["BreakListFromTimeOriginal"] = result[i].BreakList[0]["FromTime"];
                      delete result[i].BreakList[0]["FromTime"];
                    }
                    if (result[i].BreakList[0].hasOwnProperty("ToTime")) {
                      result[i]["WorkingScheduleBreakToTime"] = result[i].BreakList[0]["ToTime"];
                      result[i]["BreakListToTimeOriginal"] = result[i].BreakList[0]["ToTime"];
                      delete result[i].BreakList[0]["ToTime"];
                    }
                    if (result[i].hasOwnProperty("StartDate")) {
                      result[i]["WorkingScheduleStartDate"] = result[i]["StartDate"];
                      result[i]["WorkingScheduleStartDateOriginal"] = result[i]["StartDate"];
                      delete result[i]["StartDate"];
                    }
                  }

                  if (result[i]?.Type?.hasOwnProperty("TypeName")) {
                    result[i]["WorkingScheduleTypeIdOriginal"] = result[i].Type["TypeId"];
                    result[i]["WorkingScheduleTypeNameOriginal"] = result[i].Type["TypeName"];
                    delete result[i].Type;
                  }

                  result[i]["WorkingScheduleTimeStr"] = moment(result[i]["WorkingScheduleFromTime"], 'HH:mm:ss').format('hh:mm A') + ' To ' + moment(result[i]["WorkingScheduleToTime"], 'HH:mm:ss').format('hh:mm A');
                  result[i]["WorkingScheduleBreakTimeStr"] = moment(result[i]["WorkingScheduleBreakFromTime"], 'HH:mm:ss').format('hh:mm A') + ' To ' + moment(result[i]["WorkingScheduleBreakToTime"], 'HH:mm:ss').format('hh:mm A');
                }
                if (this.obj.baseUrlType == 'Asset' || this.obj.baseUrlType == 'Consumable' || this.obj.baseUrlType == 'Expense') {

                  array = makeArrayList(this.resp.Result);
                }
                else {
                  array = makeArrayListchange(this.resp.Result, this.obj.endPoint);

                }

              }
              else if (this.obj.endPoint === 'HolidayList')// This is only for HolidayList

              {

                const result = this.resp.Result.HolidayList;
                for (var i = 0; i < result.length; i++) {
                  if (result[i].hasOwnProperty("StartDate")) {
                    result[i]["HolidayStartDate"] = result[i]["StartDate"];
                    result[i]["StartDateOriginal"] = result[i]["StartDate"];
                    delete result[i]["StartDate"];
                  }
                  if (result[i].hasOwnProperty("FromTime")) {
                    delete result[i]["FromTime"];
                  }
                  if (result[i].hasOwnProperty("ToTime")) {
                    delete result[i]["ToTime"];
                  }

                }
                console.log(this.resp.Result);

                // if(this.obj.baseUrlType == "GlobalCore"){
                //   console.log(this.resp.Result);
                array = makeArrayListchange(result, this.obj.endPoint);
                // }
                // else{

                //   array = makeArrayList(this.resp.Result);
                // }
              }
              else if (this.obj.endPoint === 'WeeklyOffList')// This is only for WeeklyOffList
              {

                this.showEdit = false;
                const result = this.resp.Result;
                for (var i = 0; i < result.length; i++) {
                  if (result[i].hasOwnProperty("FromTime")) {
                    console.log(result);
                    delete result[i]["FromTime"];
                  }
                  if (result[i].hasOwnProperty("ToTime")) {
                    delete result[i]["ToTime"];
                  }

                }
                array = makeArrayListchange(result, this.obj.endPoint);

                // array = makeArrayList(this.resp.Result);

              }
              else {
                console.log(this.obj.baseUrlType);
                if (this.obj.baseUrlType == 'Asset' || this.obj.baseUrlType == 'Consumable' || this.obj.baseUrlType == 'Expense') {
                  array = makeArrayList(this.resp.Result);
                }
                else {

                  array = makeArrayListchange(this.resp.Result, this.obj.endPoint);
                }
              }
              console.log(array.__zone_symbol__value);
              console.log(array);
              if (array.__zone_symbol__value) {
                this.persons = array.__zone_symbol__value;
                console.log(this.persons);

                for (let list of this.persons) {
                  // if (this.obj.endPoint == 'AttributeList' && list.AssetAttributeNames.length > 0) {
                  //   console.log(list.AssetAttributeNames);
                  //   list.AllAssetAttribute = ''
                  //   list.AssetAttributeNames.forEach((element, index) => {
                  //     list.AllAssetAttribute += list.AssetAttributeNames[index].AssetAttributeName + (list?.AssetAttributeNames.length > 1 &&
                  //       list?.AssetAttributeNames[index] != list?.AssetAttributeNames[list?.AssetAttributeNames.length - 1]
                  //       ? ',' : '')
                  //   });
                  // }
                  list.Ischecked = false;
                  if (list.MonthlyMobileLimit) {
                    list.MonthlyMobileLimit = list.MonthlyMobileLimit.split(',')
                  }
                }

              }
              console.log(this.resp?.Result)

              this.recordsTotal = this.resp.Result.recordsTotal >= 0 ? this.resp.Result.recordsTotal : this.resp.Result.FilteredRecordCount >= 0 ?
                this.resp.Result.FilteredRecordCount : this.resp.recordsTotal >= 0 ? this.resp.recordsTotal : this.resp.FilteredRecordCount
              callback({
                recordsTotal: this.resp?.Result.recordsTotal >= 0 ? this.resp?.Result?.recordsTotal : this.resp?.Result?.FilteredRecordCount >= 0 ?
                  this.resp?.Result?.FilteredRecordCount : this.resp?.recordsTotal >= 0 ? this.resp?.recordsTotal : this.resp?.FilteredRecordCount,
                recordsFiltered: this.resp?.Result?.recordsFiltered >= 0 ? this.resp?.Result?.recordsFiltered : this.resp?.Result?.FilteredRecordCount >= 0 ?
                  this.resp?.Result?.FilteredRecordCount : this.resp?.recordsFiltered ? this.resp?.recordsFiltered : this.resp?.FilteredRecordCount,
                data: []
              });
            })
        }, 900);
      },
      columns: this.obj.sortableColumns,
      ...this.tableSetting.domSetting
    };
  }

  openDialog() {


    if (this.obj.addModelAction == 'AddEntitlements') {
      this.router.navigate(['/app/core/common/settings/add-entitlements']);
    }
    else if (this.obj.addModelAction === 'AddEmployee') {
      if (this.employeeId.type != 'item core') {
        this.router.navigate(['/app/core/common/add-employee-new']);
      } else {
        this.router.navigate(['/app/core/common/add-employee-core']);
      }
    }
    else {

      var classmake
      classmake = (this.obj.addModelAction == "AddDocprintType") ? 'agreement-modal' : ''
      console.log(classmake);

      console.log(this.obj)
      this.popupComp = ModalpopupComponent
      this.dialogRef = this.dialog.open(this.popupComp, {
        width: "600px",
        panelClass: [classmake, "modal-scroll"],
        autoFocus: false,
        disableClose: true,
        data: { modalTitle: this.obj.addModalTitle, action: this.obj.addModelAction, baseUrlType: this.obj.baseUrlType },
      });
      this.dialogRef.afterClosed().subscribe(async (response) => {
        response !== 'close' ? $('#table1').DataTable().ajax.reload() : ''
        console.log(response);
        if (this.obj.addModelAction == 'AddServiceType' || this.obj.editModelAction == 'EditServiceType') {
          this.servicecategory()
        }
      });
    }
  }
  viewEmployee(item, text): void {
    if (text == 'view') {
      this.router.navigate(['/app/core/common/exit-employee/' + item.ExitEmployeeId + '/view' + '/add']);
    } else if (text == 'employee') {
      this.router.navigate(['/app/core/common/add-employee-new/' + item.EmployeeId], { queryParams: { type: 'Current', path: 'ExitEmployee' } });
    }
    else {
      this.router.navigate(['/app/core/common/exit-employee/' + item.ExitEmployeeId + '/edit' + '/add']);
    }

  }
  editMigrate(item): void {
    this.router.navigate(['/app/core/common/add-employee-new/' + item.EmployeeId], { queryParams: { migrate: 'Migrate' } });
    // const internpara = {
    //   EmployeeId: item.EmployeeId,
    //   EmployeeCategory: {
    //     EmployeeCategoryId: 1,
    //     EmployeeCategoryName: "Employee",
    //     EmployeeCategoryCode: null
    //   }
    // }
    // this.spinner.show();
    // this.masterService.postEmployee(internpara, 'InternMigrateToEmployee').subscribe(response => {
    //   console.log(response);
    //   if (response.body.Result) {
    //     this.employeeId = response.body.Result.EmployeeId;
    //     console.log(response);

    // if (this.obj.editModelAction === 'EmployeeEdit') {
    //   if (this.employeeId.type != 'item core') {
    //     this.router.navigate(['/app/core/common/add-employee-new/' + this.employeeId], { queryParams: { Migrate: 'Migrate'} });
    //   } else {
    //     this.router.navigate(['/app/core/common/add-employee-core/' + this.employeeId],{ queryParams: { Migrate: 'Migrate'} });
    //   }
    // }
    //   }
    // })

  }
  editMaster(item, endPoint): void {
    let inputData;
    console.log(item);
    console.log(endPoint);

    switch (endPoint) {
      case 'ConsumableItemList':
        inputData = { consumableitemid: item.ConsumableItemId }
        break;
      case 'ConsumableCategoryList':
        inputData = { ConsumableCategoryId: item.ConsumableCategoryId }
        break;
      case 'DocumentPrintCategorieList':
        inputData = { DocPrintCategorieId: item.DocPrintCategorieId }
        break;
      case 'DocumentPrintTypeList':
        inputData = { DocPrintTypeId: item.DocPrintTypeId }
        break;
      case 'CompliancesCategoryList':
        inputData = { ComplianceCategoryId: item.ComplianceCategoryId }
        break;
      case 'UnitOfMeasurementList':
        inputData = { UnitOfMeasurementId: item.UnitOfMeasurementId }
        break;
      case 'AssetClassList':
        inputData = { AssetClassId: item.AssetClassId }
        break;
      case 'AttributeList':
        inputData = { AssetAttributeId: item.AssetAttributeId }
        break;
      case 'AssetItemList':
        inputData = { AssetItemId: item.AssetItemId }
        break;
      case 'AssetCategoryList':
        inputData = { AssetCategoryId: item.AssetCategoryId }
        break;
      case 'ConsumableClassList':
        inputData = { ConsumableClassId: item.ConsumableClassId }
        break;
      case 'EntitlementCodeList':
        inputData = item
        break;
      case 'ModeofConveyanceList':
        inputData = { Conveyanceid: item.Conveyanceid }
        break;
      case 'ExpenseEntitlementsCodeList':
        inputData = { ExpenseEntitlementCodeid: item.ExpenseEntitlementCodeid }
        break;
      case 'YearList':
        inputData = { YearId: item.YearId }
        break;
      case 'UnitList':
        inputData = { UnitId: item.UnitId }
        break;
      case 'TaxTypeList':
        inputData = { TaxTypeId: item.TaxTypeId }
        break;
      case 'StateList':
        inputData = { StateId: item.StateId }
        break;
      case 'ReasonList':
        inputData = { ReasonId: item.ReasonId }
        break;
      case 'PaymentTypeList':
        inputData = { PaymentTypeId: item.PaymentTypeId }
        break;
      case 'PaymentTermList':
        inputData = { PaymentTermId: item.PaymentTermId }
        break;
      case 'PaymentModeList':
        inputData = { PaymentModeId: item.PaymentModeId }
        break;
      case 'IndustryTypeList':
        inputData = { IndustryTypeId: item.IndustryTypeId }
        break;
      case 'FinancialYearList':
        inputData = { FinancialYearId: item.FinancialYearId }
        break;
      case 'EstimationBufferList':
        inputData = { EstimationBufferId: item.EstimationBufferId }
        break;
      case 'DocumentTypeList':
        inputData = { DocumentTypeId: item.DocumentTypeId }
        break;
      case 'DocumentCategoryList':
        inputData = { DocumentCategoryId: item.DocumentCategoryId }
        break;
      case 'CurrencyList':
        inputData = { CurrencyId: item.CurrencyId }
        break;
      case 'CountryList':
        inputData = { CountryId: item.CountryId }
        break;
      case 'QualificationList':
        inputData = { QualificationId: item.QualificationId }
        break;
      case 'CostCenterNameList':
        inputData = { CostCenterNameId: item.CostCenterNameId }
        break;
      case 'HSNList':
        inputData = { HSNId: item.HSNId }
        break;
      case 'BufferPercentageList':
        inputData = { BufferPercentageId: item.BufferPercentageId }
        break;
      case 'BankAccountTypeList':
        inputData = { BankAccountTypeId: item.BankAccountTypeId }
        break;
      case 'BankList':
        inputData = { BankId: item.BankId }
        break;
      case 'CostCenterTypeList':
        inputData = { CostCenterTypeId: item.CostCenterTypeId }
        break;
      case 'HolidayList':
        inputData = { WorkingScheduleId: item.WorkingScheduleId }
        break;
      case 'WorkingScheduleList':
        inputData = { WorkingScheduleId: item.WorkingScheduleId }
        break;
      case 'ProfitCenterList':
        inputData = { ProfitCenterId: item.ProfitCenterId }
        break;
      case 'DepartmentList':
        inputData = { DepartmentId: item.DepartmentId }
        break;
      case 'ShiftList':
        inputData = { ShiftId: item.ShiftId }
        break;
      case 'DesignationList':
        inputData = { DesignationId: item.DesignationId }
        break;
      case 'ExpenseAccomodationList':
        inputData = { AccomodationId: item.AccomodationId }
        break;
      case 'ExpenseTypeList':
        inputData = { ExpenseId: item.ExpenseId }
        break;
      case 'CityList':
        inputData = item
        break;
      case 'OrganisationTypeList':
        inputData = item
        break;
      case 'OrganisationTypeList':
        inputData = item
        break;
      case 'LabelList':
        inputData = item
        break;
      case 'LevelList':
        inputData = item
        break;
      case 'ResignationReasonList':
        inputData = item
        break;
      case 'ProjectKeyFeatureList':
        inputData = item
        break;
      case 'ServiceKeyFeatureList':
        inputData = item
        break;
      case 'SkillCategoryList':
        inputData = item
        break;
      case 'KeySkillList':
        inputData = item
        break;
      case 'ServiceCategoryList':
        inputData = item
        break;
      case 'TerminationReasonList':
        inputData = item
        break;
      case 'CustomerSegmentList':
        inputData = item
        break;
      case 'CostCenterSBHList':
        inputData = item
        break;
      case 'ReferenceModeList':
        inputData = item
        break;
      case 'EmployeeCategoryList':
        inputData = item
        break;
      case 'CadreList':
        inputData = item
        break;
      case 'OccupationList':
        inputData = item
        break;
      case 'InvoicePaymentTermList':
        inputData = item
        break;
      case 'GeneralTermList':
        inputData = item
        break;
      case 'ModeOfPOList':
        inputData = item
        break;
      case 'UserRoleList':
        inputData = item
        break;
      case 'ServiceTypeList':
        inputData = item
        break;
      case 'OtherActivityList':
        inputData = item
        break;
      case 'BackgroundInfolist':
        inputData = item
        break;
      case 'NationalityList':
        inputData = item
        break;

      case 'ServiceActivityList':
        inputData = item
        break;
      case 'ServiceTaskList':
        inputData = item
        break;
      case 'ServiceSubActivityList':
        inputData = item
        break;
      case 'CityAreaList':
        inputData = item
        break;
    }
    if (this.obj.editModelAction === 'EditEntitlements') {
      this.router.navigate(['/app/core/common/settings/add-entitlements/' + item.ExpenseEntitlementCodeid]);
    }
    else if (this.obj.editModelAction === 'EmployeeEdit') {
      if (this.employeeId.type != 'item core') {
        this.router.navigate(['/app/core/common/add-employee-new/' + item.EmployeeId]);
      } else {
        this.router.navigate(['/app/core/common/add-employee-core/' + item.EmployeeId]);
      }
    }
    else {
      var classmake
      classmake = (this.obj.editModelAction == "EditdocprintType") ? 'agreement-modal' : ''

      console.log(this.obj.editModelAction)
      this.popupComp = ModalpopupComponent
      this.dialogRef = this.dialog.open(this.popupComp, {
        width: "620px",
        panelClass: [classmake, "modal-scroll"],
        autoFocus: false,
        disableClose: true,
        data: {
          modalTitle: this.obj.editModalTitle,
          action: this.obj.editModelAction,
          Data: inputData,
          baseUrlType: this.obj.baseUrlType
        },
      });
      this.dialogRef.afterClosed().subscribe(async (response) => {
        response !== 'close' ? $('#table1').DataTable().ajax.reload() : ''
      });
    }
  }

  deleteMaster(data, Endpoint) {
    this.dialogRef = this.dialog.open(CommonModalComponent, {
      width: "300px",
      disableClose: true,
      data: { action: "delete", statusText: "Are you sure you want to delete record" },
    });
    this.dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        // let endPoint = this.obj.deleteEndPoint;
        // const obj = await makeDeleteObj(data, endPoint)
        this.baseloginUrl = 'EmployeeCore/api/';
        this.baseloginUrl = 'EmployeeCore/api/';
        this.baseDashboardUrl = 'GlobalCore/api/Dashboard/';
        this.baseUrlConfing = 'GlobalCore/api/Configuration/';
        this.baseUrlClient = 'ClientCore/api/Client/';
        this.baseUrlService = 'Service/api/Service/';
        //this.baseUrlEmployee = 'core/api/Employee/';
        this.baseUrlEmployee = 'EmployeeCore/api/Employee/';
        this.EmpTaxInvoiceBaseUrl = 'EmployeeCore/api/EmployeeTaxInvoice/';

        this.userRole = 'EmployeeCore/api/RolePermission/';
        this.baseKeyUrl = 'Lead/api/Configuration/';
        this.baseLeadForFC = 'Lead/api/LeadForFC/';
        this.baseServiceURL = 'Service/api/Service/';
        this.baseBillingURL = 'Billing/api/ServiceBilling/';
        this.baseInvoiceURL = 'Billing/api/Invoices/';

        this.baseEmployeeURL = 'EmployeeCore/api/Employee/';
        // shivram new service params --------
        this.globalBaseUrl = 'GlobalCore/api/Configuration/';
        this.Otheractivity = 'Task/api/OtherActivity/'
        this.EmployeeBaseUrl = 'EmployeeCore/api/Employee/';
        this.ClientBaseUrl = 'ClientCore/api/Client/';
        this.companyBaseUrl = 'CompanyCore/api/CompanyDetail/';
        this.companyCoreUrl = 'CompanyCore/api/CompanyDetail/';
        this.companyCoreUrlservice = 'CompanyCore/api/Service/';
        this.LeadBaseUrl = 'Lead/api/Configuration/';
        this.BillingBaseUrl = 'Billing/api/Configuration/';
        this.CampaignBaseUrl = 'Campaign/api/Configuration/';
        this.baseUrlAllocate = 'Task/api/Task/';
        this.baseUrlStaffing = 'Task/api/StaffingTask/';
        this.Otheractivity = 'Task/api/OtherActivity/'
        let finalUrl = ''
        console.log(this.obj.baseUrlType);

        switch (this.obj.baseUrlType) {
          case 'GlobalCore':
            finalUrl = this.globalBaseUrl
            break;
          case 'DocPrintCategories':
            finalUrl = 'DocPrint/api/DocPrintCategories/'
            break;
          case 'DocPrintType':
            finalUrl = 'DocPrint/api/DocPrintType/'
            break;
          case 'CompliancesCore':
            finalUrl = 'Compliances/api/Compliances/'
            break;
          case 'Asset':
            finalUrl = 'Asset/api/Asset/'
            break;
          case 'Expense':
            finalUrl = 'Expense/api/Expense/'
            break;
          case 'Consumable':
            finalUrl = 'Consumable/api/Consumable/'
            break;
          case 'EmployeeCore':
            finalUrl = 'EmployeeCore/api/Employee/'
            break;
          case 'OtherActivity':
            finalUrl = this.Otheractivity
            break;
          case 'EmployeeCore':
            finalUrl = this.EmployeeBaseUrl
            break;
          case 'ClientCore':
            finalUrl = this.ClientBaseUrl
            break;
          case 'CompanyCore':
            finalUrl = this.companyBaseUrl
            break;
          case 'Lead':
            finalUrl = this.LeadBaseUrl
            break;
          case 'Billing':
            finalUrl = this.BillingBaseUrl
            break;
          case 'Campaign':
            finalUrl = this.CampaignBaseUrl
            break;
          case 'ServiceCore':
            finalUrl = this.baseServiceURL
            break;
        }
        console.log(data);

        // Delete Method
        let inputData;
        console.log(Endpoint);

        switch (Endpoint) {
          case 'AssetItemDelete':
            inputData = { AssetItemId: data.AssetItemId }
            break;
          case 'UnitOfMeasurementDelete':
            inputData = { UnitOfMeasurementId: data.UnitOfMeasurementId }
            break;
          case 'AttributeDelete':
            inputData = { AssetAttributeId: data.AssetAttributeId }
            break;
          case 'AssetCategoryDelete':
            inputData = { AssetCategoryId: data.AssetCategoryId }
            break;
          case 'AssetClassDelete':
            inputData = { AssetClassId: data.AssetClassId }
            break;
          case 'HSNDelete':
            inputData = { HSNId: data.HSNId }
            break;
          case 'HolidayDelete':
            inputData = { WorkingScheduleId: data.WorkingScheduleId }
            break;
          case 'WeeklyOffDelete':
            inputData = { WorkingScheduleId: data.WorkingScheduleId }
            break;
          case 'WorkingScheduleDelete':
            inputData = { WorkingScheduleId: data.WorkingScheduleId }
            break;
          case 'DepartmentDelete':
            inputData = { DepartmentId: data.DepartmentId }
            break;
          case 'ShiftDelete':
            inputData = { ShiftId: data.ShiftId }
            break;
          case 'DesignationDelete':
            inputData = { DesignationId: data.DesignationId }
            break;
          case 'DeleteConsumable':
            inputData = { DesignationId: data.ConsumableClassId }
            break;
          case 'ModeofConveyanceDelete':
            inputData = { Conveyanceid: data.Conveyanceid }
            break;
          case 'EntitlementCodeDelete':
            inputData = { EntitlementCodeid: data.EntitlementCodeid }
            break;
          case 'ExpenseEntitlementsCodeDelete':
            inputData = { ExpenseEntitlementCodeid: data.ExpenseEntitlementCodeid }
            break;
          case 'ServiceTypeDelete':
            inputData = { ServiceTypeId: data.ServiceTypeId }
            break;
          case 'NationalityDelete':
            inputData = { NationalityId: data.NationalityId }
            break;
        }
        this.spinner.show();

        if (inputData) {
          this.apiService.postService(inputData, finalUrl + this.obj.deleteEndPoint).subscribe(res => {
            this.spinner.hide();
            console.log(res);
            if (res?.data?.Result?.TransactionMessage?.Status == 200 || res?.body?.Status == 200 || res?.body?.Result?.TransactionMessage?.Status == 200) {
              console.log(this.baseUrl);
              $('#table1').DataTable().ajax.reload()
              this.disable = true;
              this.dialogRefModal = this.dialog.open(
                CommonModalComponent,
                {
                  width: '300px',
                  disableClose: true,
                  data: {
                    action: "success", statusText: res?.data?.Result?.TransactionMessage?.Message ? res?.data?.Result?.TransactionMessage?.Message : res?.body?.Result?.TransactionMessage?.Message ? res?.body?.Result?.TransactionMessage?.Message : res?.body?.Message
                  }
                });
            } else {
              this.dialogRefModal = this.dialog.open(CommonModalComponent, {
                width: '300px',
                disableClose: true,
                data: { action: "delete", statusText: res?.data?.Result?.TransactionMessage?.Message ? res?.data?.Result?.TransactionMessage?.Message : res?.body?.Result?.TransactionMessage?.Message ? res?.body?.Result?.TransactionMessage?.Message : res?.body?.Message }
              });
            }
          });
        } else {
          this.apiService.postService(data, finalUrl + this.obj.deleteEndPoint).subscribe(res => {
            console.log(res);
            if (res?.data?.Result?.TransactionMessage?.Status == 200 || res?.body?.Status == 200 || res?.body?.Result?.TransactionMessage?.Status == 200) {
              console.log(this.baseUrl);
              $('#table1').DataTable().ajax.reload()

              this.dialogRefModal = this.dialog.open(
                CommonModalComponent,
                {
                  width: '300px',
                  disableClose: true,
                  data: {
                    action: "success", statusText: res?.data?.Result?.TransactionMessage?.Message ? res?.data?.Result?.TransactionMessage?.Message : res?.body?.Result?.TransactionMessage?.Message ? res?.body?.Result?.TransactionMessage?.Message : res?.body?.Message
                  }
                });
            } else {
              this.dialogRefModal = this.dialog.open(CommonModalComponent, {
                width: '300px',
                disableClose: true,
                data: { action: "delete", statusText: res?.data?.Result?.TransactionMessage?.Message ? res?.data?.Result?.TransactionMessage?.Message : res?.body?.Result?.TransactionMessage?.Message ? res?.body?.Result?.TransactionMessage?.Message : res?.body?.Message }
              });
            }
          });
        }
      }
    });
  }
  ngAfterViewInit() { }
  ngOnDestroy() {
  }
  prsnDialog(): void {
    this.dialogRef = this.dialog.open(ModalpopupComponent, {
      panelClass: 'modal-scroll',
      autoFocus: false,
      data: { modalTitle: 'Employee', action: 'ExitEmployee' }
    });
    this.dialogRef.afterClosed()
      .subscribe(result => {
        if (!result) {
          return;
        } else {
          this.dialogRef = this.dialog.open(ModalpopupComponent, {
            width: "600px",
            panelClass: "modal-scroll",
            autoFocus: false,
            disableClose: true,
            data: { modalTitle: 'Add Remarks', action: 'enterRemarks', data: result },
          });
          this.dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
              let response;
              response = await this.masterService.asyncPostService(result, this.obj.deleteEndPoint, this.obj.baseUrlType)
              if (response.data.Result.TransactionMessage.Status = 200) {
                $('#table1').DataTable().ajax.reload()
                this.dialogRefModal = this.dialog.open(
                  CommonModalComponent,
                  {
                    width: '300px',
                    disableClose: true,
                    autoFocus: false,
                    panelClass: 'statusClass',
                    data: {
                      action: 'success', statusText: response.data.Result.TransactionMessage.Message
                    }
                  });

              } else {

                this.dialogRefModal = this.dialog.open(CommonModalComponent, {
                  width: '300px',
                  disableClose: true,
                  autoFocus: false,
                  panelClass: 'statusClass',
                  data: { action: 'danger', statusText: response.data.Result.TransactionMessage.Message }
                });
              }
            }
          });
        }
      });
  }
  employeeDrpList() {
    this.masterService.postEmployee(null, 'EmployeeDropdownList').subscribe(response => {
      if (response.body.Result) {
        this.employeeDropDownList = response.body.Result;
      }
    });
  }
  employeeCategoryDrpList() {
    this.masterService.postEmployee(null, 'EmployeeCategoryDropDownList').subscribe(response => {
      if (response.body.Result) {
        this.employeeCategoryDropDownList = response.body.Result;
      }
    });
  }
  ActivityDrpList() {
    this.masterService.postService({}, 'CategoryDropDownList')
      .subscribe(response => {
        this.ServiceActivityDropDownList = response.body;
      })
  }
  docPrintCategory() {
    this.dropdownService.postService({}, 'DocPrint/api/DocPrintCategories/DocPrintCategorieDropDown')
      .subscribe(response => {
        this.docPrintCategoryList = response.body;
      })
  }

  CatServiceActivityDrpList(a) {
    let CategoryId
    if (a.value.CategoryId) {
      CategoryId = a.value.CategoryId
    }
    else {
      CategoryId = a.value
    }
    this.masterService.PostService({}, 'ServiceActivityDropDownList?CategoryId=' + CategoryId).subscribe(response => {
      this.ServiceCatActivityDropDownList = response.body;
    });
  }

  CatServiceSubActivityDrpList(a) {
    if (a.value == 'all') {
      this.inputData = {
        ServiceActivityName: "",
      }
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.draw();
      })
    } else {
      this.masterService.PostService({}, 'ServiceSubActivityDropDownList?CategoryId=' + a.value.Category.CategoryId + '&ServiceActivityId=' + a.value.ServiceActivityId)
        .subscribe(response => {
          this.ServiceCatSubActivityDropDownList = response.body;
        });
    }
  }
  async Assetfilter(event, objcase) {
    console.log(event, objcase);
    let id
    let catid
    if (event.AssetCategoryId) {
      catid = event.AssetCategoryId
    }
    else {
      catid = 0
    }
    if (event == 'all' && objcase == 'assetclass') {
      this.inputData = {
        AssetClassId: 0,
      }
      id = 0
    }

    else if (event == 'all' && objcase == 'assetcategory') {
      this.inputData.AssetCategoryId = 0
    }

    else {
      id = event.AssetClassId
    }
    if ((this.obj.endPoint == 'AssetItemList' && objcase == 'assetclass') || (this.obj.endPoint == 'AttributeList' && objcase == 'assetclass')) {

      this.masterService.assetCoreSerivce({}, 'GetAssetCategoryDropDownList?AssetClass=' + id).subscribe(response => {
        console.log(response);
        this.inputData.AssetClassId = event.AssetClassId
        this.AssetCategoryDropdown = response.body
      });
    }
    else if (this.obj.endPoint == 'AttributeList' && objcase == 'assetcategory') {
      this.inputData.AssetCategoryId = event.AssetCategoryId
      id = event.AssetCategoryId
      this.masterService.assetCoreSerivce({}, 'GetAssetItemDropDownList?AssetCategoryId=' + catid).subscribe(response => {
        console.log(response);
        console.log(event)
        this.AssetItemDropdown = response.body
      });
    }
    else if (this.obj.endPoint == 'AttributeList' && objcase == 'assetitem') {

      this.inputData.AssetItemId = event.AssetItemId
    }
    else {
      this.inputData.AssetCategoryId = event.AssetCategoryId
    }
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    })
  }
  async docfilter(event) {
    this.inputData.DocPrintCategorieId = event.DocPrintCategorieId
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    })
  }
  async empFilter(event) {
    console.log(event);

    if (event == 'asset') {
      this.inputData = {
        AssetClassId: ""
      }
    }
    if (event == 'assetItem') {
      this.inputData = {
        AssetClassId: "",
        AssetCategoryId: ""
      }


    }

    if (event == 'assetAttri') {
      this.inputData = {
        AssetClassId: "",
        AssetCategoryId: "",
        AssetItemId: ""
      }
    }
    if (event == 'all') {
      this.inputData = {
        ShiftName: "",
        CategoryName: "",
        ServiceActivityName: "",
        ServiceSubActivityName: "",
        CountryId: "",
        StateId: "",
      }

    } else {
      this.DocPrintCategory = event.DocPrintCategorieName
      this.CostCenterCategory.CostCenterCategoryId = event.CostCenterCategoryId
      this.ServiceActivity.ServiceActivityId = event.ServiceActivityId
      this.CostCenterCategory.CostCenterCategoryName = event.CostCenterCategoryName
      this.ServiceCategory.ServiceCategoryId = event.ServiceCategoryId
      this.ServiceCategory.ServiceCategoryName = event.ServiceCategoryName
      this.inputData = {
        EmployeeCategory: {
          EmployeeCategoryId: event.EmployeeCategoryId,
          EmployeeCategoryName: event.EmployeeCategoryName
        },
        ShiftName: event.ShiftName,
        CategoryName: event.CategoryName,
        ServiceActivityName: event.ServiceActivityName,
        ServiceSubActivityName: event.ServiceSubActivityName,
        CountryId: event.CountryId,
        StateId: event.StateId,
        AssetClassId: event.AssetClassId,
        AssetCategoryId: event.AssetCategoryId,
        AssetItemId: event.AssetItemId,
      }
      this.inputData.CostCenterCategory = this.CostCenterCategory;
      this.inputData.ServiceCategory = this.ServiceCategory;
      this.inputData.ServiceActivity = this.ServiceActivity;
      this.inputData.DocPrintCategorieName = this.DocPrintCategory
    }
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    })
  }
  async formerEmpPage(text) {
    if (text == 'forEmp') {
      this.obj.endPoint = 'ExistEmployeeList';
    } else {
      this.obj.endPoint = 'EmployeeListNew';
    }
    $('#table1').DataTable().ajax.reload()
  }
  feedbackInfo(remark) {
    this.dialogRef = this.dialog.open(ModalpopupComponent, {
      width: "600px",
      panelClass: "modal-scroll",
      autoFocus: false,
      disableClose: true,
      data: { modalTitle: 'Remarks', action: 'textRemarks', data: remark },
    });
  }



  checkUncheckAll(person) {
    console.log(person)
    for (var i = 0; i < person.length; i++) {
      person[i].Ischecked = this.masterSelected;
      if (this.masterSelected) {
        this.variationlen.push(person);
        this.workingSheDeleteIds.push(person[i].WorkingScheduleId)
        console.log(this.workingSheDeleteIds);
        this.ShiftIds.ShiftIds = this.workingSheDeleteIds;
        console.log(this.ShiftIds);


      }
      else {
        this.variationlen = [];
        this.workingSheDeleteIds = [];
      }
      if (person[i].Ischecked) {
        this.disable = false;
      }
      else {
        this.disable = true;
      }
    }
    console.log(this.workingSheDeleteIds);

  }

  isAllSelected(list, e, index) {
    this.masterSelected = this.persons.every(function (item: any) {
      return item.Ischecked == true;
    })
    //Delete Disable enable button
    const isCheckValid = this.persons.filter(x => x.Ischecked == true)[0];
    console.log(this.persons);
    console.log(list);
    if (isCheckValid) {
      // console.log(this.persons);
      this.disable = false;
    } else {
      this.disable = true;
    }
    //Get Total length
    if (e.checked) {
      this.variationlen.push(list);
      this.workingSheDeleteIds.push(list.WorkingScheduleId);
      this.ShiftIds.ShiftIds = this.workingSheDeleteIds;
      console.log(this.ShiftIds);
    } else {
      this.variationlen.splice(index, 1);
      this.workingSheDeleteIds.splice(this.workingSheDeleteIds.indexOf(list.WorkingScheduleId), 1);
      this.ShiftIds.ShiftIds = this.workingSheDeleteIds;
      console.log(this.ShiftIds)
    }
    console.log(this.workingSheDeleteIds);
  }

  deleteSelectall() {
    this.dialogRef = this.dialog.open(CommonModalComponent, {
      width: "300px",
      disableClose: true,
      data: { action: "delete", statusText: "Are you sure you want to delete record" },
    });
    this.dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        //API Call
        this.masterService.companyCoreService({ WorkingSheduleId: this.ShiftIds.ShiftIds }, 'WorkingScheduleDeleteMultiple').subscribe(response => {
          console.log(response);
          if (response.body.Success) {
            this.dialogRefModal = this.dialog.open(
              CommonModalComponent,
              {
                width: '300px',
                disableClose: true,
                autoFocus: false,
                panelClass: 'statusClass',
                data: {
                  action: 'success', statusText: response.body.Message
                }
              });
            $('#table1').DataTable().ajax.reload();
            this.disable = true;
            this.masterSelected = false
          } else this.dialogRefModal = this.dialog.open(CommonModalComponent,
            {
              width: '300px',
              disableClose: true,
              autoFocus: false,
              panelClass: 'statusClass',
              data: {
                action: 'delete', statusText: response.body.Message
              }
            });
        })

      }
    });
  }

  getCityList = (id, data) => {
    console.log(id);
    console.log(data);

    this.inputData = {
      CountryId: data.CountryId,
      StateId: id
    }
    this.masterService.postService(this.inputData, 'CityList')
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  getstateList = (id) => {
    console.log(id);

    this.inputData = {
      CountryId: id,

    }
    this.masterService.postService(this.inputData, 'StateList')
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }
  async servicecategory() {
    this.service = await this.masterService.asyncPostService({}, 'ServiceCategoryDropdown', 'ServiceCore');
    this.serviceList = this.service.data.Result;
  }
  async restriction(event, data) {
    this.restictioninput.ServiceCategoryId = data.ServiceCategoryId;
    this.restictioninput.ServiceCategoryName = data.ServiceCategoryName;
    this.restictioninput.IsPlanningRestriction = event.checked;
    if (event.checked) {
      await this.masterService.asyncPostService(this.restictioninput, 'UpdatePlanningRestriction', 'ServiceCore');
    } else {
      const dialogRef = this.dialog.open(CommonModalComponent, {
        width: '300px',
        disableClose: true,
        autoFocus: false,
        panelClass: 'statusClass',
        data: { action: 'delete', statusText: "Are you sure you want to skip service planing process" }
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        console.log(result);

        if (result) {
          await this.masterService.asyncPostService(this.restictioninput, 'UpdatePlanningRestriction', 'ServiceCore');
        } else {
          $('#table1').DataTable().ajax.reload();
        }



      })

    }


  }

  ChangeDate(data) {

    let date: any = new Date(data._d)
    this.spinner.show();
    this.inputData.StartDate = date
    $('#table1').DataTable().ajax.reload();
    // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   dtInstance.search(date);
    //   dtInstance.draw();
    // });
  }
  addAttributes(ele) {
    console.log(ele);
    this.attributeNameList = this.obj.endPoint == 'AssetClassList' ? ele?.AssetClassName : this.obj.endPoint == 'AssetCategoryList' ? ele?.AssetCategoryName : this.obj.endPoint == 'AssetItemList' ? ele?.AssetItemName : '';
    this.attributeIdList = this.obj.endPoint == 'AssetClassList' ? ele?.AssetClassId : this.obj.endPoint == 'AssetCategoryList' ? ele?.AssetCategoryId : this.obj.endPoint == 'AssetItemList' ? ele?.AssetItemId : 0;

    if (this.obj.endPoint == 'AssetClassList') {
      this.attributeKeyList = 'assetClass';
      this.attributeClassId = ele?.AssetClassId;
      this.attributeClassName = ele?.AssetClassName;
    } else if (this.obj.endPoint == 'AssetCategoryList') {
      this.attributeKeyList = 'assetCategory';
      this.attributeClassId = ele?.AssetClassId;
      this.attributeCategorieId = ele?.AssetCategoryId;
      this.attributeClassName = ele?.AssetClassName;
      this.attributeCategorieName = ele?.AssetCategoryName;
    } else if (this.obj.endPoint == 'AssetItemList') {
      this.attributeKeyList = 'assetItem';
      this.attributeClassId = ele?.AssetClassId;
      this.attributeCategorieId = ele?.AssetCategoryId;
      this.attributeItemId = ele?.AssetItemId;
      this.attributeClassName = ele?.AssetClassName;
      this.attributeCategorieName = ele?.AssetCategoryName;
      this.attributeItemName = ele?.AssetItemName;
    }
  }
  saveAtt(e) {
    this.isAttributesShow = false;
  }
}



