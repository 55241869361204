<div class="content travel-ex apprem reimbursment_top">
    <div class="page-heading padd_bo d-flex align-items-center">
        <div fxFlex fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
                <div class="lead_info">
                    <h1>Reimbursements Approval</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content_body leadinfo-outer serviceflow-outer epica-accordion">
        <mat-tab-group mat-align-tabs="start" (selectedTabChange)="tabWiseSave($event);">

            <mat-tab>
                <ng-template mat-tab-label>
                    <span [matBadge]="countData?.RecurringExpensePending" matBadgeOverlap="false" matBadgeColor="warn"
                        [matBadgeHidden]="countData?.RecurringExpensePending == 0">Recurring Expense</span>
                </ng-template>
                <div class="filter_area mt-5" style="position: relative; top: 9px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="1 0 auto">
                            <div class="top_drop" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                                <div class="input_group filter_box">
                                    <p class="left_label">Status</p>
                                    <mat-form-field appearance="outline">
                                        <mat-select placeholder="All Status" (selectionChange)="getFilterData($event)">
                                            <mat-option [value]="0">All Status</mat-option>
                                            <mat-option [value]="list" *ngFor="let list of status">{{list.statusName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                    <div class="filter-calendar input_group filter_box">
                                        <p class="left_label">From</p>
                                        <mat-form-field appearance="outline">
                                            <input matInput [matDatepicker]="picker1" readonly (click)="picker1.open()"
                                                name="StartDate" [(ngModel)]="businessHead.StartDate"
                                                [max]="businessHead.EndDate" (dateChange)="getFilterData($event);">
                                            <mat-datepicker-toggle matSuffix [for]="picker1">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker1>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="filter-calendar input_group filter_box">
                                        <p class="left_label">To</p>
                                        <mat-form-field appearance="outline">
                                            <input matInput [matDatepicker]="picker2" readonly (click)="picker2.open()"
                                                [min]="businessHead.StartDate" name="EndDate"
                                                [(ngModel)]="businessHead.EndDate" (dateChange)="getFilterData($event)">
                                            <mat-datepicker-toggle matSuffix [for]="picker2">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker2>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mini_btn" fxFlex="0 1 auto">
                            <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <button mat-mini-fab color="accent" class="gray_btn">
                                    <img src="assets/icons/table.svg">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="tabNbr == 0">
                    <table id="table_lead1" datatable [dtOptions]="dtOptions"
                        class="mt-5 table row-border hover list_table table table-bordered table-hover table-compact tb-trre">
                        <thead>
                            <tr>
                                <th *ngFor="let item of tblClm1" (click)="sortItem = item.position"
                                    [class.d-none]="item.Title == 'noShow'">
                                    <div>
                                        <span>{{item.Title}}</span>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody [attr.data_sort]="'sort_' + sortItem">
                            <tr class="showHideClass" #tr *ngFor=" let element of DataOutput1?.ItemList; let i = index">
                                <td class="text-center ind_0">{{i+1+tabledata.start}}</td>
                                <td>
                                    <p class="d-flex">
                                        <img src="{{element?.EmployeeImage}}"
                                            onerror="this.src='assets/images/user_b.png'"
                                            style="width: 30px; height: 30px; margin-right: 8px; margin-top: 2px; border-radius: 50%;">
                                        <span>
                                            <strong>{{element?.EmployeeName}}</strong><br>
                                            <small>{{element?.EmployeeCode}}</small>
                                        </span>
                                    </p>
                                </td>
                                <td>{{element?.CreatedDate | date}}</td>
                                <td>{{element?.IsDomestic ? 'Domestic' : 'International'}}
                                    <span *ngIf="!element?.TypeId">(NA)</span>
                                    <span *ngIf="element?.TypeId == 1">(Daily)</span>
                                    <span *ngIf="element?.TypeId == 2">(Weekly)</span>
                                    <span *ngIf="element?.TypeId == 3">(Monthly)</span>
                                </td>
                                <td>
                                    <span *ngIf="element?.FromDate">{{element?.FromDate | date}} <span
                                            *ngIf="element?.ToDate">to
                                            {{element?.ToDate | date}}</span> </span> <span
                                        *ngIf="!element?.FromDate">NA</span>
                                </td>
                                <td>{{element?.CurrencySymbol}} {{element?.BillAmount}}</td>
                                <td>
                                    <!-- <span *ngIf="element?.IsApproved">Approved</span><span *ngIf="!element?.IsApproved">Pending</span> -->
                                    <div *ngIf="element?.PaymentStatus == 2">

                                        <span *ngIf="element?.Status == 1 && element?.ApprovedByRP == 1">Pending </span>
                                        <span *ngIf="element?.Status == 1 && element?.ApprovedByRP == 2">Pending at <a style="padding-bottom: 12px;"
                                                class="text-primary underline"
                                                [matMenuTriggerFor]="usrDtl">{{element?.PendingATDesignation ?
                                                element?.PendingATDesignation : 'NA'}}</a>
                                        </span>
                                        <span class="text-success fw-bold mr-3"
                                            *ngIf="element?.Status == 2">Approved</span>
                                        <!-- (click)="sidenavDecline.toggle(); sideNavData = element?.Reason;" -->
                                        <span class="text-danger fw-bold" *ngIf="element?.Status == 3" 
                                        (click)="sidenavDecline.toggle(); sideNavData = element?.Reason;" style="text-decoration: underline;">Rejected</span>
                                        <mat-menu class="user_dtl" #usrDtl="matMenu">
                                            <div class="user_detail">
                                                <span class="mail">
                                                    <mat-icon>person</mat-icon>
                                                    {{element?.PendingATName ? element?.PendingATName : 'NA'}}
                                                </span>
                                                <span class="mail">
                                                    <mat-icon>local_post_office</mat-icon>
                                                    {{element?.PendingATOfficialEmail ? element?.PendingATOfficialEmail
                                                    :
                                                    'NA'}}
                                                </span>
                                                <span class="phone">
                                                    <mat-icon>local_phone</mat-icon>
                                                    {{element?.PendingATMobileNo ? element?.PendingATMobileNo : 'NA'}}
                                                </span>
                                            </div>
                                        </mat-menu>
                                    </div>

                                    <span *ngIf="element?.PaymentStatus != 2">
                                        <a class="icon fw-bold" matTooltip="View Detail" style="text-decoration: underline;"
                                            [ngClass]="element?.PaymentStatus == 1 ? 'text-success ' :  'text-partial '"
                                            (click)="sidenav3.toggle(); getSideNavData(element?.RecurringExpenseId, 1)">
                                            {{element?.PaymentStatus == 1 ? 'Paid' : 'Partly Paid'}}
                                        </a>
                                    </span>
                                </td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span class="action_icons mr-3">
                                            <a class="icon fw-bold" matTooltip="View Detail"
                                                (click)="getViewDetail(element, 'rec'); sidenav2.toggle();">
                                                <mat-icon>visibility</mat-icon>
                                            </a>
                                        </span>
                                        <!-- <span class="text-success fw-bold mr-3"
                                            *ngIf="element?.ApprovedByRP == 2 && element?.Status == 1">Approved</span>
                                        <span class="text-danger fw-bold"
                                            *ngIf="element?.ApprovedByRP == 3 && element?.Status == 1">Rejected</span> -->
                                    </div>
                                </td>
                                <!-- <td class="d-none"></td> -->
                            </tr>
                        </tbody>
                        <tbody *ngIf="DataOutput1?.ItemList?.length == 0">
                            <tr>
                                <td align="center" colspan="10">No matching records found</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <span [matBadge]="countData?.TravelExpensePending" matBadgeOverlap="false" matBadgeColor="warn"
                        [matBadgeHidden]="countData?.TravelExpensePending == 0">Travel Expense</span>
                </ng-template>
                <div class="filter_area mt-5" style="position: relative; top: 9px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="1 0 auto">
                            <div class="top_drop" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                                <div class="input_group filter_box">
                                    <p class="left_label">Status</p>
                                    <mat-form-field appearance="outline">
                                        <mat-select placeholder="All Status" (selectionChange)="getFilterData($event)">
                                            <mat-option [value]="0">All</mat-option>
                                            <mat-option [value]="list" *ngFor="let list of status">{{list.statusName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                    <div class="filter-calendar input_group filter_box">
                                        <p class="left_label">From</p>
                                        <mat-form-field appearance="outline">
                                            <input matInput [matDatepicker]="picker3" readonly (click)="picker3.open()"
                                                name="StartDate" [(ngModel)]="businessHead.StartDate"
                                                [max]="businessHead.EndDate" (dateChange)="getFilterData($event);">
                                            <mat-datepicker-toggle matSuffix [for]="picker3">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker3>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="filter-calendar input_group filter_box">
                                        <p class="left_label">To</p>
                                        <mat-form-field appearance="outline">
                                            <input matInput [matDatepicker]="picker4" readonly (click)="picker4.open()"
                                                [min]="businessHead.StartDate" name="EndDate"
                                                [(ngModel)]="businessHead.EndDate" (dateChange)="getFilterData($event)">
                                            <mat-datepicker-toggle matSuffix [for]="picker4">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker4>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mini_btn" fxFlex="0 1 auto">
                            <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <button mat-mini-fab color="accent" class="gray_btn">
                                    <img src="assets/icons/table.svg">
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
                <div *ngIf="tabNbr == 1">
                    <table id="table_lead1" datatable [dtOptions]="dtOptions"
                        class="mt-5 table row-border hover list_table table table-bordered table-hover table-compact tb-trex">
                        <thead>
                            <tr>
                                <th *ngFor="let item of tblClm2" (click)="sortItem = item.position">
                                    <div>
                                        <span>{{item.Title}}</span>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody [attr.data_sort]="'sort_' + sortItem">
                            <tr class="showHideClass" #tr *ngFor=" let element of DataOutput1?.ItemList; let i = index">
                                <td class="text-center ind_0">{{i+1+tabledata.start}}</td>
                                <td>
                                    <p class="d-flex">
                                        <img src="{{element?.EmployeeImage}}"
                                            onerror="this.src='assets/images/user_b.png'"
                                            style="width: 30px; height: 30px; margin-right: 8px; margin-top: 2px; border-radius: 50%;">
                                        <span>
                                            <strong>{{element?.EmployeeName}}</strong><br>
                                            <small>{{element?.EmployeeCode}}</small>
                                        </span>
                                    </p>
                                </td>
                                <td>{{element?.CreatedDate | date}}</td>
                                <!-- <td><span *ngIf="!element?.TypeId">NA</span>
                                    <span *ngIf="element?.TypeId == 1">Intercity</span>
                                    <span *ngIf="element?.TypeId == 2">Local</span>
                                </td> -->
                                <td>
                                    <span *ngIf="element?.TravelDate">{{element?.TravelDate | date}}</span>
                                    <span *ngIf="!element?.TravelDate">NA</span>
                                </td>
                                <td>
                                    <span *ngIf="element?.VisitFrom">{{element?.VisitFrom}} <span
                                            *ngIf="element?.VisitTo">-
                                            {{element?.VisitTo}}</span> </span> <span
                                        *ngIf="!element?.VisitFrom">NA</span>
                                </td>
                                <!-- <td>{{element?.ModeName}}</td> -->
                                <td>{{element?.CurrencySymbol}} {{element?.BillAmount}}</td>
                                <!-- <td>
                                    <a class="alink" (click)="downloadMyFile(element, element?.BillDocumentId)">
                                        <mat-icon role="img"
                                            class="mat-icon mr-1 notranslate mticon material-icons mat-icon-no-color"
                                            aria-hidden="true" data-mat-icon-type="font">file_download</mat-icon>
                                    </a>
                                </td> -->
                                <td>
                                    <!-- <span *ngIf="element?.IsApproved">Approved</span><span *ngIf="!element?.IsApproved">Pending</span> -->
                                    <div *ngIf="element?.PaymentStatus == 2">
                                        <span *ngIf="element?.Status == 1 && element?.ApprovedByRP == 1">Pending </span>
                                        <span *ngIf="element?.Status == 1 && element?.ApprovedByRP == 2">Pending at <a style="padding-bottom: 12px;"
                                                class="text-primary underline"
                                                [matMenuTriggerFor]="usrDtl">{{element?.PendingATDesignation ?
                                                element?.PendingATDesignation : 'NA'}}</a>
                                        </span>
                                        <span class="text-success fw-bold mr-3"
                                            *ngIf="element?.Status == 2">Approved</span>
                                        <a class="text-danger fw-bold" *ngIf="element?.Status == 3"  style="text-decoration: underline;"
                                            (click)="sidenavDecline.toggle(); sideNavData = element?.Reason;">Rejected</a>
                                        <mat-menu class="user_dtl" #usrDtl="matMenu">
                                            <div class="user_detail">
                                                <span class="mail">
                                                    <mat-icon>person</mat-icon>
                                                    {{element?.PendingATName ? element?.PendingATName : 'NA'}}
                                                </span>
                                                <span class="mail">
                                                    <mat-icon>local_post_office</mat-icon>
                                                    {{element?.PendingATOfficialEmail ? element?.PendingATOfficialEmail
                                                    :
                                                    'NA'}}
                                                </span>
                                                <span class="phone">
                                                    <mat-icon>local_phone</mat-icon>
                                                    {{element?.PendingATMobileNo ? element?.PendingATMobileNo : 'NA'}}
                                                </span>
                                            </div>
                                        </mat-menu>
                                    </div>
                                    <span *ngIf="element?.PaymentStatus != 2">
                                        <a class="icon fw-bold" matTooltip="View Detail" style="text-decoration: underline;"
                                            [ngClass]="element?.PaymentStatus == 1 ? 'text-success ' :  'text-partial '"
                                            (click)="sidenav3.toggle(); getSideNavData(element?.TravelExpenseId, 2)">
                                            {{element?.PaymentStatus == 1 ? 'Paid' : 'Partly Paid'}}
                                        </a>
                                    </span>
                                </td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span class="action_icons mr-3">
                                            <a class="icon fw-bold" matTooltip="View Detail"
                                                (click)="getsidebarData(element?.TravelExpenseId); sidenav1.toggle();">
                                                <mat-icon>visibility</mat-icon>
                                            </a>
                                        </span>
                                        <!-- <span class="text-success fw-bold mr-3"
                                            *ngIf="element?.ApprovedByRP == 2 && element?.Status == 1">Approved</span>
                                        <span class="text-danger fw-bold"
                                            *ngIf="element?.ApprovedByRP == 3 && element?.Status == 1">Rejected</span> -->
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                        <tbody *ngIf="DataOutput1?.ItemList?.length == 0">
                            <tr>
                                <td align="center" colspan="10">No matching records found</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <span [matBadge]="countData?.AccommodationExpensePending" matBadgeOverlap="false"
                        matBadgeColor="warn"
                        [matBadgeHidden]="countData?.AccommodationExpensePending == 0">Accommodation Expense</span>
                </ng-template>
                <div class="filter_area mt-5" style="position: relative; top: 9px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="1 0 auto">
                            <div class="top_drop" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                                <div class="input_group filter_box">
                                    <p class="left_label">Status</p>
                                    <mat-form-field appearance="outline">
                                        <mat-select placeholder="All Status" (selectionChange)="getFilterData($event)">
                                            <mat-option [value]="0">All</mat-option>
                                            <mat-option [value]="list" *ngFor="let list of status">{{list.statusName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                    <div class="filter-calendar input_group filter_box">
                                        <p class="left_label">From</p>
                                        <mat-form-field appearance="outline">
                                            <input matInput [matDatepicker]="picker5" readonly (click)="picker5.open()"
                                                name="StartDate" [(ngModel)]="businessHead.StartDate"
                                                [max]="businessHead.EndDate" (dateChange)="getFilterData($event);">
                                            <mat-datepicker-toggle matSuffix [for]="picker5">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker5>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="filter-calendar input_group filter_box">
                                        <p class="left_label">To</p>
                                        <mat-form-field appearance="outline">
                                            <input matInput [matDatepicker]="picker6" readonly (click)="picker6.open()"
                                                [min]="businessHead.StartDate" name="EndDate"
                                                [(ngModel)]="businessHead.EndDate" (dateChange)="getFilterData($event)">
                                            <mat-datepicker-toggle matSuffix [for]="picker6">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker6>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mini_btn" fxFlex="0 1 auto">
                            <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <button mat-mini-fab color="accent" class="gray_btn">
                                    <img src="assets/icons/table.svg">
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
                <div *ngIf="tabNbr == 2">
                    <table id="table_lead1" datatable [dtOptions]="dtOptions"
                        class="mt-5 table row-border hover list_table table table-bordered table-hover table-compact tb-trac">
                        <thead>
                            <tr>
                                <th *ngFor="let item of tblClm3" (click)="sortItem = item.position">
                                    <div>
                                        <span>{{item.Title}}</span>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody [attr.data_sort]="'sort_' + sortItem">
                            <tr class="showHideClass" #tr *ngFor=" let element of DataOutput1?.ItemList; let i = index">
                                <td class="text-center ind_0">{{i+1+tabledata.start}}</td>
                                <td>
                                    <p class="d-flex">
                                        <img src="{{element?.EmployeeImage}}"
                                            onerror="this.src='assets/images/user_b.png'"
                                            style="width: 30px; height: 30px; margin-right: 8px; margin-top: 2px; border-radius: 50%;">
                                        <span>
                                            <strong>{{element?.EmployeeName}}</strong><br>
                                            <small>{{element?.EmployeeCode}}</small>
                                        </span>
                                    </p>
                                </td>
                                <td>{{element?.CreatedDate | date}}</td>
                                <td>{{element?.TypeName }}</td>
                                <td>
                                    <span *ngIf="element?.FromDate">{{element?.FromDate | date}} to
                                        {{element?.ToDate | date}}</span> <span *ngIf="!element?.FromDate">NA</span>
                                </td>
                                <!-- <td>{{element?.Location}}</td> -->
                                <td>{{element?.CurrencySymbol}} {{element?.BillAmount}}</td>
                                <td>
                                    <!-- <span *ngIf="element?.IsApproved">Approved</span><span *ngIf="!element?.IsApproved">Pending</span> -->
                                    <div *ngIf="element?.PaymentStatus == 2">
                                        <span *ngIf="element?.Status == 1 && element?.ApprovedByRP == 1">Pending </span>
                                        <span *ngIf="element?.Status == 1 && element?.ApprovedByRP == 2">Pending at <a style="padding-bottom: 12px;"
                                                class="text-primary underline"
                                                [matMenuTriggerFor]="usrDtl">{{element?.PendingATDesignation ?
                                                element?.PendingATDesignation : 'NA'}}</a>
                                        </span>
                                        <span class="text-success fw-bold mr-3"
                                            *ngIf="element?.Status == 2">Approved</span>
                                        <span class="text-danger fw-bold" *ngIf="element?.Status == 3" (click)="sidenavDecline.toggle(); sideNavData = element?.Reason;"
                                         style="text-decoration: underline;">Rejected</span>
                                        <mat-menu class="user_dtl" #usrDtl="matMenu">
                                            <div class="user_detail">
                                                <span class="mail">
                                                    <mat-icon>person</mat-icon>
                                                    {{element?.PendingATName ? element?.PendingATName : 'NA'}}
                                                </span>
                                                <span class="mail">
                                                    <mat-icon>local_post_office</mat-icon>
                                                    {{element?.PendingATOfficialEmail ? element?.PendingATOfficialEmail
                                                    :
                                                    'NA'}}
                                                </span>
                                                <span class="phone">
                                                    <mat-icon>local_phone</mat-icon>
                                                    {{element?.PendingATMobileNo ? element?.PendingATMobileNo : 'NA'}}
                                                </span>
                                            </div>
                                        </mat-menu>
                                    </div>
                                    <span *ngIf="element?.PaymentStatus != 2">
                                        <a class="icon fw-bold" matTooltip="View Detail" style="text-decoration: underline;"
                                            [ngClass]="element?.PaymentStatus == 1 ? 'text-success ' :  'text-partial '"
                                            (click)="sidenav3.toggle(); getSideNavData(element?.AccommodationExpenseId, 3)">
                                            {{element?.PaymentStatus == 1 ? 'Paid' : 'Partly Paid'}}
                                        </a>
                                    </span>

                                </td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span class="action_icons mr-3">
                                            <a class="icon fw-bold" matTooltip="View Detail"
                                                (click)="getViewDetail(element, 'acc'); sidenav2.toggle();">
                                                <mat-icon>visibility</mat-icon>
                                            </a>
                                        </span>
                                        <!-- <span class="text-success fw-bold"
                                            *ngIf="element?.ApprovedByRP == 2 && element?.Status == 1">Approved</span>
                                        <span class="text-danger fw-bold"
                                            *ngIf="element?.ApprovedByRP == 3 && element?.Status == 1">Rejected</span> -->
                                    </div>
                                </td>
                            </tr>


                        </tbody>
                        <tbody *ngIf="DataOutput1?.ItemList?.length == 0">
                            <tr>
                                <td align="center" colspan="10">No matching records found</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <span [matBadge]="countData?.FoodExpensePending" matBadgeOverlap="false" matBadgeColor="warn"
                        [matBadgeHidden]="countData?.FoodExpensePending == 0">Food & Beverage Expense</span>
                </ng-template>
                <div class="filter_area mt-5" style="position: relative; top: 9px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="1 0 auto">
                            <div class="top_drop" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                                <div class="input_group filter_box">
                                    <p class="left_label">Status</p>
                                    <mat-form-field appearance="outline">
                                        <mat-select placeholder="All Status" (selectionChange)="getFilterData($event)">
                                            <mat-option [value]="0">All</mat-option>
                                            <mat-option [value]="list" *ngFor="let list of status">{{list.statusName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                    <div class="filter-calendar input_group filter_box">
                                        <p class="left_label">From</p>
                                        <mat-form-field appearance="outline">
                                            <input matInput [matDatepicker]="picker7" readonly (click)="picker7.open()"
                                                name="StartDate" [(ngModel)]="businessHead.StartDate"
                                                [max]="businessHead.EndDate" (dateChange)="getFilterData($event);">
                                            <mat-datepicker-toggle matSuffix [for]="picker7">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker7>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="filter-calendar input_group filter_box">
                                        <p class="left_label">To</p>
                                        <mat-form-field appearance="outline">
                                            <input matInput [matDatepicker]="picker8" readonly (click)="picker8.open()"
                                                [min]="businessHead.StartDate" name="EndDate"
                                                [(ngModel)]="businessHead.EndDate" (dateChange)="getFilterData($event)">
                                            <mat-datepicker-toggle matSuffix [for]="picker8">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker8>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mini_btn" fxFlex="0 1 auto">
                            <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <button mat-mini-fab color="accent" class="gray_btn">
                                    <img src="assets/icons/table.svg">
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
                <div *ngIf="tabNbr == 3">
                    <table id="table_lead1" datatable [dtOptions]="dtOptions"
                        class="mt-5 table row-border hover list_table table table-bordered table-hover table-compact tb-trfoo">
                        <thead>
                            <tr>
                                <th *ngFor="let item of tblClm4" (click)="sortItem = item.position"
                                    [class.d-none]="item.Title == 'noShow'">
                                    <div>
                                        <span>{{item.Title}}</span>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody [attr.data_sort]="'sort_' + sortItem">
                            <tr class="showHideClass" #tr *ngFor=" let element of DataOutput1?.ItemList; let i = index">
                                <td class="text-center ind_0">{{i+1+tabledata.start}}</td>
                                <td>
                                    <p class="d-flex">
                                        <img src="{{element?.EmployeeImage}}"
                                            onerror="this.src='assets/images/user_b.png'"
                                            style="width: 30px; height: 30px; margin-right: 8px; margin-top: 2px; border-radius: 50%;">
                                        <span>
                                            <strong>{{element?.EmployeeName}}</strong><br>
                                            <small>{{element?.EmployeeCode}}</small>
                                        </span>
                                    </p>
                                </td>
                                <td>{{element?.CreatedDate | date}}</td>
                                <td>
                                    <span>{{element?.FromDate | date}}</span>
                                </td>
                                <td>{{element?.CurrencySymbol}} {{element?.BillAmount}}</td>
                                <td>
                                    <a class="alink" (click)="downloadMyFileOther(element?.BillDocumentId)">
                                        <mat-icon _ngcontent-jfr-c275="" role="img"
                                            class="mat-icon mr-1 notranslate mticon material-icons mat-icon-no-color"
                                            aria-hidden="true" data-mat-icon-type="font">file_download</mat-icon>
                                    </a>
                                </td>
                                <td>
                                    <!-- <span *ngIf="element?.IsApproved">Approved</span><span *ngIf="!element?.IsApproved">Pending</span> -->
                                    <div *ngIf="element?.PaymentStatus == 2">
                                        <span *ngIf="element?.Status == 1 && element?.ApprovedByRP == 1">Pending </span>
                                        <span *ngIf="element?.Status == 1 && element?.ApprovedByRP == 2">Pending at <a style="padding-bottom: 12px;"
                                                class="text-primary underline"
                                                [matMenuTriggerFor]="usrDtl">{{element?.PendingATDesignation ?
                                                element?.PendingATDesignation : 'NA'}}</a>
                                        </span>
                                        <span class="text-success fw-bold mr-3"
                                            *ngIf="element?.Status == 2">Approved</span>
                                        <span class="text-danger fw-bold" *ngIf="element?.Status == 3" (click)="sidenavDecline.toggle(); sideNavData = element?.Reason;"
                                         style="text-decoration: underline;">Rejected</span>
                                        <mat-menu class="user_dtl" #usrDtl="matMenu">
                                            <div class="user_detail">
                                                <span class="mail">
                                                    <mat-icon>person</mat-icon>
                                                    {{element?.PendingATName ? element?.PendingATName : 'NA'}}
                                                </span>
                                                <span class="mail">
                                                    <mat-icon>local_post_office</mat-icon>
                                                    {{element?.PendingATOfficialEmail ? element?.PendingATOfficialEmail
                                                    :
                                                    'NA'}}
                                                </span>
                                                <span class="phone">
                                                    <mat-icon>local_phone</mat-icon>
                                                    {{element?.PendingATMobileNo ? element?.PendingATMobileNo : 'NA'}}
                                                </span>
                                            </div>
                                        </mat-menu>
                                    </div>
                                    <span *ngIf="element?.PaymentStatus != 2">
                                        <a class="icon fw-bold" matTooltip="View Detail" style="text-decoration: underline;"
                                            [ngClass]="element?.PaymentStatus == 1 ? 'text-success ' :  'text-partial '"
                                            (click)="sidenav3.toggle(); getSideNavData(element?.FoodExpenseId, 4)">
                                            {{element?.PaymentStatus == 1 ? 'Paid' : 'Partly Paid'}}
                                        </a>
                                    </span>

                                </td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span class="action_icons mr-3">
                                            <a class="icon fw-bold" matTooltip="View Detail"
                                                (click)="getViewDetail(element, 'food'); sidenav2.toggle();">
                                                <mat-icon>visibility</mat-icon>
                                            </a>
                                        </span>
                                        <!-- <span class="text-success fw-bold"
                                            *ngIf="element?.ApprovedByRP == 2 && element?.Status == 1">Approved</span>
                                        <span class="text-danger fw-bold"
                                            *ngIf="element?.ApprovedByRP == 3 && element?.Status == 1">Rejected</span> -->
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                        <tbody *ngIf="DataOutput1?.ItemList?.length == 0">
                            <tr>
                                <td align="center" colspan="10">No matching records found</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <span [matBadge]="countData?.GeneralExpensePending" matBadgeOverlap="false" matBadgeColor="warn"
                        [matBadgeHidden]="countData?.GeneralExpensePending == 0">General Expense</span>
                </ng-template>
                <div class="filter_area mt-5" style="position: relative; top: 9px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="1 0 auto">
                            <div class="top_drop" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                                <div class="input_group filter_box">
                                    <p class="left_label">Status</p>
                                    <mat-form-field appearance="outline">
                                        <mat-select placeholder="All Status" (selectionChange)="getFilterData($event)">
                                            <mat-option [value]="0">All</mat-option>
                                            <mat-option [value]="list" *ngFor="let list of status">{{list.statusName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                    <div class="filter-calendar input_group filter_box">
                                        <p class="left_label">From</p>
                                        <mat-form-field appearance="outline">
                                            <input matInput [matDatepicker]="picker9" readonly (click)="picker9.open()"
                                                name="StartDate" [(ngModel)]="businessHead.StartDate"
                                                [max]="businessHead.EndDate" (dateChange)="getFilterData($event);">
                                            <mat-datepicker-toggle matSuffix [for]="picker9">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker9>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="filter-calendar input_group filter_box">
                                        <p class="left_label">To</p>
                                        <mat-form-field appearance="outline">
                                            <input matInput [matDatepicker]="picker10" readonly
                                                (click)="picker10.open()" [min]="businessHead.StartDate" name="EndDate"
                                                [(ngModel)]="businessHead.EndDate" (dateChange)="getFilterData($event)">
                                            <mat-datepicker-toggle matSuffix [for]="picker10">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker10>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mini_btn" fxFlex="0 1 auto">
                            <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <button mat-mini-fab color="accent" class="gray_btn">
                                    <img src="assets/icons/table.svg">
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
                <div *ngIf="tabNbr == 4">
                    <table id="table_lead1" datatable [dtOptions]="dtOptions"
                        class="mt-5 table row-border hover list_table table table-bordered table-hover table-compact tb-trge">
                        <thead>
                            <tr>
                                <th *ngFor="let item of tblClm5" (click)="sortItem = item.position"
                                    [class.d-none]="item.Title == 'noShow'">
                                    <div>
                                        <span>{{item.Title}}</span>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody [attr.data_sort]="'sort_' + sortItem">
                            <tr class="showHideClass" #tr *ngFor=" let element of DataOutput1?.ItemList; let i = index">
                                <td class="text-center ind_0">{{i+1+tabledata.start}}</td>
                                <td>
                                    <p class="d-flex">
                                        <img src="{{element?.EmployeeImage}}"
                                            onerror="this.src='assets/images/user_b.png'"
                                            style="width: 30px; height: 30px; margin-right: 8px; margin-top: 2px; border-radius: 50%;">
                                        <span>
                                            <strong>{{element?.EmployeeName}}</strong><br>
                                            <small>{{element?.EmployeeCode}}</small>
                                        </span>
                                    </p>
                                </td>
                                <td>{{element?.CreatedDate | date}}</td>
                                <td>
                                    <span *ngIf="element?.FromDate">{{element?.FromDate | date}}</span>
                                </td>
                                <td>{{element?.Title}}</td>
                                <td>{{element?.CurrencySymbol}} {{element?.BillAmount}}</td>
                                <td>
                                    <div *ngIf="element?.PaymentStatus == 2">
                                        <span *ngIf="element?.Status == 1 && element?.ApprovedByRP == 1">Pending </span>
                                        <span *ngIf="element?.Status == 1 && element?.ApprovedByRP == 2">Pending at <a style="padding-bottom: 12px;"
                                                class="text-primary underline"
                                                [matMenuTriggerFor]="usrDtl">{{element?.PendingATDesignation ?
                                                element?.PendingATDesignation : 'NA'}}</a>
                                        </span>
                                        <span class="text-success fw-bold mr-3"
                                            *ngIf="element?.Status == 2">Approved</span>
                                        <span class="text-danger fw-bold" *ngIf="element?.Status == 3" (click)="sidenavDecline.toggle(); sideNavData = element?.Reason;"
                                         style="text-decoration: underline;">Rejected</span>
                                        <mat-menu class="user_dtl" #usrDtl="matMenu">
                                            <div class="user_detail">
                                                <span class="mail">
                                                    <mat-icon>person</mat-icon>
                                                    {{element?.PendingATName ? element?.PendingATName : 'NA'}}
                                                </span>
                                                <span class="mail">
                                                    <mat-icon>local_post_office</mat-icon>
                                                    {{element?.PendingATOfficialEmail ? element?.PendingATOfficialEmail
                                                    :
                                                    'NA'}}
                                                </span>
                                                <span class="phone">
                                                    <mat-icon>local_phone</mat-icon>
                                                    {{element?.PendingATMobileNo ? element?.PendingATMobileNo : 'NA'}}
                                                </span>
                                            </div>
                                        </mat-menu>
                                    </div>
                                    <span *ngIf="element?.PaymentStatus != 2">
                                        <a class="icon fw-bold" matTooltip="View Detail" style="text-decoration: underline;"
                                            [ngClass]="element?.PaymentStatus == 1 ? 'text-success ' :  'text-partial '"
                                            (click)="sidenav3.toggle(); getSideNavData(element?.GeneralExpenseId, 5)">
                                            {{element?.PaymentStatus == 1 ? 'Paid' : 'Partly Paid'}}
                                        </a>
                                    </span>



                                    <!-- <div *ngIf="element?.PaymentStatus == 2">
                                        <span *ngIf="element?.Status == 1">Pending at <a style="padding-bottom: 12px;"
                                                class="text-primary underline"
                                                [matMenuTriggerFor]="usrDtl">{{element?.PendingATDesignation ?
                                                element?.PendingATDesignation : 'NA'}}</a></span>
                                        <span class="text-success fw-bold mr-3"
                                            *ngIf="element?.Status == 2">Approved</span>
                                        <span class="text-danger fw-bold" *ngIf="element?.Status == 3">Rejected</span>
                                        <mat-menu class="user_dtl" #usrDtl="matMenu">
                                            <div class="user_detail">
                                                <span class="mail">
                                                    <mat-icon>person</mat-icon>
                                                    {{element?.PendingATName ? element?.PendingATName : 'NA'}}
                                                </span>
                                                <span class="mail">
                                                    <mat-icon>local_post_office</mat-icon>
                                                    {{element?.PendingATOfficialEmail ? element?.PendingATOfficialEmail
                                                    :
                                                    'NA'}}
                                                </span>
                                                <span class="phone">
                                                    <mat-icon>local_phone</mat-icon>
                                                    {{element?.PendingATMobileNo ? element?.PendingATMobileNo : 'NA'}}
                                                </span>
                                            </div>
                                        </mat-menu>
                                    </div>
                                    <span *ngIf="element?.PaymentStatus != 2">
                                        <a class="icon fw-bold" matTooltip="View Detail"
                                            [ngClass]="element?.PaymentStatus == 1 ? 'text-success ' :  'text-warning '"
                                            (click)="sidenav3.toggle(); getSideNavData(element?.GeneralExpenseId, 5)">
                                            {{element?.PaymentStatus == 1 ? 'Paid' : 'Partly Paid'}}
                                        </a>
                                    </span> -->

                                </td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span class="action_icons mr-3">
                                            <a class="icon fw-bold" matTooltip="View Detail"
                                                (click)="getViewDetail(element, 'gen'); sidenav2.toggle();">
                                                <mat-icon>visibility</mat-icon>
                                            </a>
                                        </span>
                                        <!-- <span class="text-success fw-bold"
                                            *ngIf="element?.ApprovedByRP == 2 && element?.Status == 1">Approved</span>
                                        <span class="text-danger fw-bold"
                                            *ngIf="element?.ApprovedByRP == 3 && element?.Status == 1">Rejected</span> -->
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                        <tbody *ngIf="DataOutput1?.ItemList?.length == 0">
                            <tr>
                                <td align="center" colspan="10">No matching records found</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </mat-tab>

        </mat-tab-group>
    </div>
</div>

<!-- Mat Drawer -->
<mat-drawer-container class="example-container epica-sidebar">
    <mat-drawer #sidenav class="sidebar taskdetail-sidebar" mode="over" position="end">
        <mat-icon class="sidebar-closeicon" (click)="sidenav.toggle()">highlight_off</mat-icon>

        <div class="taskdetail-dv">
            <h2>Remark</h2>
            <div class="task_detail_box">
                <div class="row">
                    <div class="col-md-12 dtl_text">
                        <p>{{sideNavData}}</p>
                    </div>
                </div>
            </div>
        </div>

    </mat-drawer>
</mat-drawer-container>

<!-- Mat Drawer 1 -->
<mat-drawer-container class="example-container epica-sidebar">
    <mat-drawer #sidenav1 class="sidebar taskdetail-sidebar" mode="over" position="end">
        <mat-icon class="sidebar-closeicon" (click)="sidenav1.toggle()">highlight_off</mat-icon>

        <div class="taskdetail-dv">
            <h2>Detail</h2>
            <div class="task_detail_box mb-3">
                <div class="row">

                    <div class="col-md-6 dtl_text">
                        <label>Requested by</label>
                        <p class="d-flex">
                            <img src="{{sideNavData?.EmployeeImage}}" onerror="this.src='assets/images/user_b.png'"
                                style="width: 29px; height: 29px; margin-right: 8px; margin-top: 2px; border-radius: 50%;">
                            <span>
                                <strong>{{sideNavData?.EmployeeName ? sideNavData?.EmployeeName :
                                    'NA'}}</strong><br>
                                <small>{{sideNavData?.EmployeeCode ? sideNavData?.EmployeeCode :
                                    'NA'}}</small>
                            </span>
                        </p>
                    </div>
                    <div class="col-md-6 dtl_text">
                        <label>Requested On</label>
                        <p>{{sideNavData?.CreatedDate | date}}</p>
                    </div>
                    <div class="col-md-6 dtl_text">
                        <label>Type</label>
                        <p> {{sideNavData?.IsDomestic ? 'Domestic' : 'International'}}
                            <span *ngIf="!sideNavData?.TypeId">(NA)</span>
                            <span *ngIf="sideNavData?.TypeId == 1">(Intercity)</span>
                            <span *ngIf="sideNavData?.TypeId == 2">(Local)</span>
                        </p>
                    </div>
                    <div class="col-md-6 dtl_text">
                        <label>Travel Date</label>
                        <p> <span *ngIf="sideNavData?.TravelDate">{{sideNavData?.TravelDate | date}}</span>
                            <span *ngIf="!sideNavData?.TravelDate">NA</span>
                        </p>
                    </div>
                    <div class="col-md-12 dtl_text">
                        <label>Visit From - To</label>
                        <p>{{sideNavData?.VisitFrom}} - {{sideNavData?.VisitTo}}</p>
                    </div>
                    <!-- <div class="col-md-6 dtl_text">
                        <label>Visit From</label>
                        <p>{{sideNavData?.VisitFrom}}</p>
                    </div>
                    <div class="col-md-6 dtl_text">
                        <label>Visit To</label>
                        <p>{{sideNavData?.VisitTo}}</p>
                    </div> -->
                    <div class="col-md-6 dtl_text">
                        <label>Travel Mode</label>
                        <p>{{sideNavData?.ModeName}}</p>
                    </div>
                    <div class="col-md-6 dtl_text">
                        <label>Amount</label>
                        <p>{{sideNavData?.CurrencySymbol}} {{sideNavData?.BillAmount}}</p>
                    </div>
                    <div class="col-md-12 dtl_text">
                        <label>Purpose</label>
                        <p>
                            {{sideNavData?.Purpose}}
                        </p>
                    </div>
                    <div class="col-md-6 dtl_text">
                        <label>Client / Person</label>
                        <p>
                            {{sideNavData?.ClientPerson}}
                        </p>
                    </div>
                    <div class="col-md-6 dtl_text">
                        <label>Distance</label>
                        <p>
                            {{sideNavData?.Distance}} KM
                        </p>
                    </div>
                    <div class="col-md-12 dtl_text">
                        <label>Remark</label>
                        <p>
                            {{sideNavData?.Remark}}
                        </p>
                    </div>
                    <div class="col-md-12 dtl_text">
                        <label>Bill</label>
                        <p>
                            <a class="alink"
                                (click)="downloadMyFile(sideNavData, sideNavData?.BillDocumentId > 0 ? sideNavData?.BillDocumentId : sideNavData?.DocumentId)">
                                <mat-icon class="mticon">file_download</mat-icon> Download
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <ng-container>
                <button class="add-lead-btn mr-3" mat-flat-button color="primary" matTooltip="Approve"
                    *ngIf="sideNavData?.ApprovedByRP == 1 && sideNavData?.Status == 1"
                    (click)="ApprovalAction(sideNavData?.TravelExpenseId, 2, 1)">
                    Approve
                </button>
                <button mat-stroked-button class="mr-3" color="warn" matTooltip="Reject"
                    *ngIf="sideNavData?.ApprovedByRP == 1 && sideNavData?.Status == 1"
                    (click)="RejectAction(sideNavData?.TravelExpenseId, 2, 0)">Reject</button>
            </ng-container>
        </div>

    </mat-drawer>
</mat-drawer-container>

<!-- Mat Drawer 2 -->
<mat-drawer-container class="example-container epica-sidebar">
    <mat-drawer #sidenav2 class="sidebar taskdetail-sidebar" mode="over" position="end">
        <mat-icon class="sidebar-closeicon" (click)="sidenav2.toggle()">highlight_off</mat-icon>

        <div class="taskdetail-dv">
            <h2>Detail</h2>
            <div class="task_detail_box mb-3">
                <div class="row">
                    <div class="col-md-6 dtl_text">
                        <label>Requested by</label>
                        <p class="d-flex">
                            <img src="{{sideData?.EmployeeImage}}" onerror="this.src='assets/images/user_b.png'"
                                style="width: 30px; height: 30px; margin-right: 8px; margin-top: 2px;border-radius: 50%;">
                            <span>
                                <strong>{{sideData?.EmployeeName}}</strong><br>
                                <small>{{sideData?.EmployeeCode}}</small>
                            </span>
                        </p>
                    </div>
                    <div class="col-md-6 dtl_text">
                        <label>Requested On</label>
                        <p>{{sideData?.CreatedDate | date}}</p>
                    </div>
                    <div class="col-md-6 dtl_text" *ngIf="viewName == 'rec' || viewName == 'acc'">
                        <label>Type</label>
                        <p *ngIf="viewName == 'rec'">
                            {{sideData?.IsDomestic ? 'Domestic' : 'International'}}
                            <span *ngIf="!sideData?.TypeId">(NA)</span>
                            <span *ngIf="sideData?.TypeId == 1">(Daily)</span>
                            <span *ngIf="sideData?.TypeId == 2">(Weekly)</span>
                            <span *ngIf="sideData?.TypeId == 3">(Monthly)</span>
                        </p>
                        <p *ngIf="viewName == 'acc'">
                            {{sideData?.IsDomestic ? 'Domestic' : 'International'}} ({{sideData?.TypeName }})
                        </p>
                    </div>
                    <div class="col-md-6 dtl_text" *ngIf="viewName == 'acc'">
                        <label>Booking Mode</label>
                        <p>{{sideData?.BookingModeId == 1 ? 'Online' : 'Offline'}}</p>
                    </div>
                    <div class="col-md-6 dtl_text" *ngIf="viewName == 'rec' || viewName == 'acc'">
                        <label *ngIf="sideData?.ToDate">Duration</label>
                        <label *ngIf="!sideData?.ToDate">Date</label>
                        <p>
                            <span *ngIf="sideData?.FromDate">{{sideData?.FromDate | date}} <span
                                    *ngIf="sideData?.ToDate">to
                                    {{sideData?.ToDate | date}}</span> </span> <span
                                *ngIf="!sideData?.FromDate">NA</span>
                        </p>
                    </div>
                    <div class="col-md-6 dtl_text" *ngIf="viewName == 'food' || viewName == 'gen'">
                        <label>Type</label>
                        <p>{{sideData?.IsDomestic ? 'Domestic' : 'International'}} <span
                            *ngIf="sideData?.typename">({{sideData?.typename}})</span></p>
                    </div>
                    <div class="col-md-6 dtl_text" *ngIf="viewName == 'food' || viewName == 'gen'">
                        <label>Date</label>
                        <p>
                            {{sideData?.FromDate | date}}
                        </p>
                    </div>
                    <div class="col-md-6 dtl_text" *ngIf="viewName == 'gen'">
                        <label>Title</label>
                        <p>
                            {{sideData?.Title}}
                        </p>
                    </div>
                    <div class="col-md-6 dtl_text">
                        <label>Amount</label>
                        <p>{{sideData?.CurrencySymbol}} {{sideData?.BillAmount}}</p>
                    </div>
                    <div class="col-md-6 dtl_text" *ngIf="viewName == 'acc' || viewName == 'food' || viewName == 'gen'">
                        <label>Location</label>
                        <p>{{sideData?.Location}}</p>
                    </div>
                    <div class="col-md-12 dtl_text">
                        <label>Remark</label>
                        <p>
                            {{sideData?.Remark}}
                        </p>
                    </div>
                    <div class="col-md-12 dtl_text">
                        <label>Bill</label>
                        <p>
                            <a class="alink"
                                (click)="downloadMyFile(sideData, sideData?.BillDocumentId > 0 ? sideData?.BillDocumentId : sideData?.DocumentId)">
                                <mat-icon class="mticon">file_download</mat-icon> Download
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="d-flex" *ngIf="viewName == 'rec'">
                <button class="add-lead-btn mr-3" mat-flat-button color="primary" matTooltip="Approve"
                    *ngIf="sideData?.ApprovedByRP == 1 && sideData?.Status == 1"
                    (click)="ApprovalAction(sideData?.RecurringExpenseId, 1, 1)">
                    Approve
                </button>
                <button mat-stroked-button class="mr-3" color="warn" matTooltip="Reject"
                    *ngIf="sideData?.ApprovedByRP == 1 && sideData?.Status == 1"
                    (click)="RejectAction(sideData?.RecurringExpenseId, 1, 0)">Reject</button>
            </div>

            <div class="d-flex" *ngIf="viewName == 'acc'">
                <button class="add-lead-btn mr-3" mat-flat-button color="primary" matTooltip="Approve"
                    *ngIf="sideData?.ApprovedByRP == 1 && sideData?.Status == 1"
                    (click)="ApprovalAction(sideData?.AccommodationExpenseId, 3, 1)">
                    Approve
                </button>
                <button mat-stroked-button class="mr-3" color="warn" matTooltip="Reject"
                    *ngIf="sideData?.ApprovedByRP == 1 && sideData?.Status == 1"
                    (click)="RejectAction(sideData?.AccommodationExpenseId, 3, 0)">Reject</button>
            </div>

            <div class="d-flex" *ngIf="viewName == 'food'">
                <button class="add-lead-btn mr-3" mat-flat-button color="primary" matTooltip="Approve"
                    *ngIf="sideData?.ApprovedByRP == 1 && sideData?.Status == 1"
                    (click)="ApprovalAction(sideData?.FoodExpenseId, 4, 1)">
                    Approve
                </button>
                <button mat-stroked-button class="mr-3" color="warn" matTooltip="Reject"
                    *ngIf="sideData?.ApprovedByRP == 1 && sideData?.Status == 1"
                    (click)="RejectAction(sideData?.FoodExpenseId, 4, 0)">Reject</button>
            </div>

            <div class="d-flex" *ngIf="viewName == 'gen'">
                <button class="add-lead-btn mr-3" mat-flat-button color="primary" matTooltip="Approve"
                    *ngIf="sideData?.ApprovedByRP == 1 && sideData?.Status == 1"
                    (click)="ApprovalAction(sideData?.GeneralExpenseId, 5, 1)">
                    Approve
                </button>
                <button mat-stroked-button class="mr-3" color="warn" matTooltip="Reject"
                    *ngIf="sideData?.ApprovedByRP == 1 && sideData?.Status == 1"
                    (click)="RejectAction(sideData?.GeneralExpenseId, 5, 0)">Reject</button>
            </div>
        </div>

    </mat-drawer>
</mat-drawer-container>

<!-- Mat Drawer 3 -->
<mat-drawer-container class="example-container epica-sidebar">
    <mat-drawer #sidenav3 class="sidebar taskdetail-sidebar" mode="over" position="end">
        <mat-icon class="sidebar-closeicon" (click)="sidenav3.toggle()">highlight_off</mat-icon>

        <div class="taskdetail-dv">
            <h2>Detail</h2>
            <div class="task_detail_box">

                <div class="row">
                    <div class="col-md-5 dtl_text">
                        <label>Requested by</label>
                        <p class="d-flex">
                            <img src="{{ReimbursementData?.EmployeeImage}}"
                                onerror="this.src='assets/images/user_b.png'"
                                style="width: 29px; height: 29px; margin-right: 8px; margin-top: 2px; border-radius: 50%;">
                            <span>
                                <strong>{{ReimbursementData?.EmployeeName ? ReimbursementData?.EmployeeName :
                                    'NA'}}</strong><br>
                                <small>{{ReimbursementData?.EmployeeCode ? ReimbursementData?.EmployeeCode :
                                    'NA'}}</small>
                            </span>
                        </p>
                    </div>
                    <div class="col-md-4 dtl_text" *ngIf="ReimbursementData?.BillRemainingAmount > 0">
                        <label>Partly Paid Amount</label>
                        <p><strong>{{ReimbursementData?.CurrencySymbol}}
                                {{ReimbursementData?.Amount - ReimbursementData?.BillRemainingAmount}}</strong></p>
                    </div>
                    <div class="col-md-3 dtl_text">
                        <label>Total Payable</label>
                        <p><strong>{{ReimbursementData?.CurrencySymbol}} {{ReimbursementData?.Amount}}</strong></p>
                    </div>
                </div>

                <mat-accordion>
                    <mat-expansion-panel *ngFor="let list of ReimbursementData?.ReimbursementTransactionModel">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="flex-column">
                                <!-- <div>
                                    <div class="tag">JUN 24, 2020</div>
                                </div> -->
                                <h4 class="task_name">
                                    Date
                                </h4>
                                <span class="alink">{{list?.CreatedDate | date}}</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <!-- <h3 class="text-primary mb-1">Feedback</h3> -->
                        <div class="top_detail">
                            <div class="detail row">
                                <div class="dtl_text col-sm-4">
                                    <label class="text-secondary">Mode of Amount</label>
                                    <p>{{list?.PaymentModeName}}</p>
                                </div>

                                <div class="dtl_text col-sm-4" *ngIf="list?.ChequeDate">
                                    <label class="text-secondary">Cheque Date</label>
                                    <p>{{list?.ChequeDate | date}}</p>
                                </div>
                                <div class="dtl_text col-sm-4" *ngIf="list?.VoucherNo">
                                    <label class="text-secondary">Voucher No</label>
                                    <p>{{list?.VoucherNo}}</p>
                                </div>
                                <div class="dtl_text col-sm-4" *ngIf="list?.RefNo">
                                    <label class="text-secondary">Ref. No</label>
                                    <p>{{list?.RefNo}}</p>
                                </div>
                                <div class="dtl_text col-sm-4" *ngIf="list?.BillRemainingAmount > 0">
                                    <label class="text-secondary">Due Amount</label>
                                    <p>{{list?.CurrencySymbol}} {{list?.BillRemainingAmount}}</p>
                                </div>
                            </div>
                            <div class="detail row">
                                <div class="dtl_text col-sm-4">
                                    <label class="text-secondary">Advance</label>
                                    <p>{{list?.CurrencySymbol}} {{list?.AdvanceAmount}}</p>
                                </div>
                                <div class="dtl_text col-sm-4">
                                    <label class="text-secondary">TDS Amount</label>
                                    <p>{{list?.CurrencySymbol}} {{list?.TdsAmount}}</p>
                                </div>
                                <div class="dtl_text col-sm-4">
                                    <label class="text-secondary">Amount Paid</label>
                                    <p>{{list?.CurrencySymbol}} {{list?.PayableAmount}}</p>
                                </div>
                            </div>
                            <div class="detail">
                                <div class="dtl_text">
                                    <label class="text-secondary">Notes</label>
                                    <p>{{list?.Notes}}</p>
                                </div>
                            </div>
                            <div class="detail" *ngIf="list?.DocumentId">
                                <div class="dtl_text">
                                    <label class="text-secondary">Attachment</label>
                                    <p>
                                        <a class="alink"
                                            (click)="downloadMyFile(list, sideData?.BillDocumentId > 0 ? sideData?.BillDocumentId : sideData?.DocumentId)">
                                            <mat-icon class="mticon">file_download</mat-icon> Download
                                        </a>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>

    </mat-drawer>
</mat-drawer-container>

<!-- Mat Drawer Reason-->
<mat-drawer-container class="example-container epica-sidebar">
    <mat-drawer #sidenavDecline class="sidebar taskdetail-sidebar" mode="over" position="end">
        <mat-icon class="sidebar-closeicon" (click)="sidenavDecline.toggle()">highlight_off</mat-icon>
        <div class="taskdetail-dv">
            <h2>Reason</h2>
            <div class="task_detail_box">
                <div class="row">
                    <div class="col-md-12 dtl_text">
                        <p>{{sideNavData ? sideNavData : 'NA'}}</p>
                    </div>
                </div>
            </div>
        </div>
    </mat-drawer>
</mat-drawer-container>