<div *ngIf="dialogAction == 'add-contact' || dialogAction == 'edit-contact'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2>Add Contact</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <div fxLayout="row wrap" class="grid_row" fxLayoutWrap fxLayoutAlign="start start" fxLayoutGap="0px">

                            <div class="flex-item" fxLayout="column" fxFlex="0 1 50">
                                <mat-form-field appearance="outline">
                                    <mat-label>First Name</mat-label>
                                    <input matInput placeholder="First Name" [class.error]="f.submitted && FirstName.invalid" [(ngModel)]="addContactDetail.FirstName" name="FirstName" #FirstName="ngModel" pattern="^(\s)*[A-Za-z]+((\s)?((\'|\-|\.)?([A-Za-z])+))*(\s)*$" required>
                                </mat-form-field>
                            </div>
                            <div class="flex-item" fxLayout="column" fxFlex="0 1 50">
                                <mat-form-field appearance="outline">
                                    <mat-label>Last Name</mat-label>
                                    <input matInput placeholder="First Name" [class.error]="f.submitted && LastName.invalid" [(ngModel)]="addContactDetail.LastName" name="LastName" #LastName="ngModel" pattern="[a-zA-Z]+" required>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Designation</mat-label>
                            <mat-select placeholder="Designation" [compareWith]="compareFn" [(ngModel)]="addContactDetail.Designation" name="Designation" #Designation="ngModel" class="form-control">
                                <mat-option *ngFor="let Uni of DemoList " [value]="Uni.Name">
                                    {{Uni?.Name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- <mat-form-field appearance="outline">
                            <mat-label>Contact No.</mat-label>
                            <input type="text" matInput placeholder="Contact No."
                                [class.error]="f.submitted && ContactNo.invalid"
                                [(ngModel)]="addContactDetail.ContactNo" name="ContactNo" #ContactNo="ngModel">
                        </mat-form-field> -->
                        <div fxLayout="row wrap" class="grid_row" fxLayoutWrap fxLayoutAlign="start start">
                            <div class="flex-item" fxLayout="column" fxFlex="0 1 25">
                                <mat-form-field appearance="outline">
                                    <mat-label>+91</mat-label>
                                    <mat-select class="form-control">
                                        <mat-option>+91</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="flex-item" fxLayout="column" fxFlex="0 1 75">
                                <mat-form-field appearance="outline">
                                    <mat-label>Contact No.</mat-label>
                                    <input type="number" matInput placeholder="Contact No." [class.error]="f.submitted && ContactNo.invalid" [(ngModel)]="addContactDetail.ContactNo" name="ContactNo" #ContactNo="ngModel">
                                </mat-form-field>
                            </div>
                        </div>
                        <mat-form-field appearance="outline">
                            <mat-label>Email</mat-label>
                            <input type="email" matInput placeholder="Email" [class.error]="f.submitted && Email.invalid" [(ngModel)]="addContactDetail.Email" name="Email" #Email="ngModel" pattern='^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'>
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'add-contact'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorFacilitySave')" color="primary">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'edit-contact'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorFacilitySave')" color="primary">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="dialogAction == 'add-faculty' || dialogAction == 'edit-faculty'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2>Add Facility</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">

                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Facility</mat-label>
                            <mat-select placeholder="Facility" [compareWith]="compareFn" [(ngModel)]="addContactDetail.Facility" name="Facility" #Facility="ngModel" class="form-control">
                                <mat-option *ngFor="let Uni of DemoList " [value]="Uni.Name">
                                    {{Uni?.Name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Country</mat-label>
                            <mat-select placeholder="Country" [compareWith]="compareFn" [(ngModel)]="addContactDetail.Country" name="Country" #Country="ngModel" class="form-control">
                                <mat-option *ngFor="let Uni of DemoList " [value]="Uni.Name">
                                    {{Uni?.Name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>State</mat-label>
                            <mat-select placeholder="State" [compareWith]="compareFn" [(ngModel)]="addContactDetail.State" name="State" #State="ngModel" class="form-control">
                                <mat-option *ngFor="let Uni of DemoList " [value]="Uni.Name">
                                    {{Uni?.Name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>City</mat-label>
                            <mat-select placeholder="City" [compareWith]="compareFn" [(ngModel)]="addContactDetail.City" name="City" #City="ngModel" class="form-control">
                                <mat-option *ngFor="let Uni of DemoList " [value]="Uni.Name">
                                    {{Uni?.Name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- <mat-form-field appearance="outline">
                            <mat-label>Location</mat-label>
                            <input type="text" matInput placeholder="Location"
                                [class.error]="f.submitted && Location.invalid" [(ngModel)]="addContactDetail.Location"
                                name="Location" #Location="ngModel">
                        </mat-form-field> -->
                        <mat-form-field appearance="outline">
                            <mat-label>Address</mat-label>
                            <input type="text" matInput placeholder="Address" [class.error]="f.submitted && Address.invalid" [(ngModel)]="addContactDetail.Address" name="Address" #Address="ngModel">
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'add-faculty'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorFacilitySave')" color="primary">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'edit-faculty'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorFacilitySave')" color="primary">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="dialogAction == 'add-offer' || dialogAction == 'edit-offer'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2>Add Offering</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">

                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Major Offering</mat-label>
                            <mat-select placeholder="Major Offering" [compareWith]="compareFn" [(ngModel)]="addContactDetail.Major" name="Major" #Major="ngModel" class="form-control">
                                <mat-option *ngFor="let Uni of DemoList " [value]="Uni.Name">
                                    {{Uni?.Name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Sub Offering</mat-label>
                            <mat-select placeholder="Sub Offering" [compareWith]="compareFn" [(ngModel)]="addContactDetail.Sub" name="Sub" #Sub="ngModel" class="form-control">
                                <mat-option *ngFor="let Uni of DemoList " [value]="Uni.Name">
                                    {{Uni?.Name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Quantity</mat-label>
                            <input type="text" matInput placeholder="Quantity" [class.error]="f.submitted && Quantity.invalid" [(ngModel)]="addContactDetail.Quantity" name="Quantity" #Quantity="ngModel">
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'add-offer'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorFacilitySave')" color="primary">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'edit-offer'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorFacilitySave')" color="primary">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="dialogAction == 'add-Quality' || dialogAction == 'edit-Quality'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2>Add Certificate</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">

                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Name of Certificate</mat-label>
                            <input type="text" matInput placeholder="Name of Certificate" [class.error]="f.submitted && CertificateName.invalid" [(ngModel)]="addContactDetail.CertificateName" name="CertificateName" #CertificateName="ngModel">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Certifying Agency</mat-label>
                            <input type="text" matInput placeholder="Certifying Agency" [class.error]="f.submitted && Agency.invalid" [(ngModel)]="addContactDetail.Agency" name="Agency" #Agency="ngModel">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Certificate No.</mat-label>
                            <input type="text" matInput placeholder="Certificate No." [class.error]="f.submitted && Agency.invalid" [(ngModel)]="addContactDetail.Agency" name="Agency" #Agency="ngModel">
                        </mat-form-field>
                        <mat-form-field appearance="outline" (click)="picker.open()">
                            <mat-label>Valid Till</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="addContactDetail.ValidDate" name="ValidDate" #ValidDate="ngModel" readonly>
                            <mat-datepicker-toggle matSuffix [for]="picker">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker>
                            </mat-datepicker>
                        </mat-form-field>
                        <div class="form-section">
                            <div class="attach_file attach_file_idproof">
                                <img src="/assets/icons/attach_icon.svg" alt=""> Upload Certificate
                                <input type="file" class="upload" #my2FileInput accept="application/pdf, application/vnd.ms-excel" (change)="fileUpload($event, addContactDetail.copy)">
                            </div>

                            <mat-error>
                                {{addContactDetail.copy.fileSizeError}}
                            </mat-error>
                            <div class="uploadfile-nameicon d-flex justify-content-between" *ngIf="addContactDetail?.copy?.DocumentFileName">
                                <span>
                                    <a (click)="downloadMyFile(addContactDetail.copy)">{{addContactDetail.copy.DocumentFileName}}
                                    </a></span>
                                <a routerLink="." (click)="my2FileInput.value='';fileUploadRemove(addContactDetail.copy)">
                                    <mat-icon>close</mat-icon>
                                </a>
                            </div>
                            <mat-error>
                                {{addContactDetail?.copy?.pdfvalid}}
                            </mat-error>
                            <mat-error *ngIf="f.submitted && !addContactDetail?.copy?.DocumentFileName" class="pt-2">
                                Please upload Aadhar Front Copy</mat-error>
                        </div>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'add-Quality'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorFacilitySave')" color="primary">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'edit-Quality'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorFacilitySave')" color="primary">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="dialogAction == 'add-Rating' || dialogAction == 'edit-Rating'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2>Add Rating</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">

                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Rating Agency</mat-label>
                            <input type="text" matInput placeholder="Rating Agency" [class.error]="f.submitted && Agency.invalid" [(ngModel)]="addContactDetail.Agency" name="Agency" #Agency="ngModel">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Rating</mat-label>
                            <input type="text" matInput placeholder="Rating" [class.error]="f.submitted && Rating.invalid" [(ngModel)]="addContactDetail.Rating" name="Rating" #Rating="ngModel">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>License / Authentication (If Any)</mat-label>
                            <input type="text" matInput placeholder="License / Authentication (If Any)" [class.error]="f.submitted && License.invalid" [(ngModel)]="addContactDetail.License" name="License" #License="ngModel">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Year</mat-label>
                            <mat-select placeholder="Year" [compareWith]="compareFn" [(ngModel)]="addContactDetail.Year" name="Year" #Year="ngModel" class="form-control">
                                <mat-option *ngFor="let Uni of DemoList " [value]="Uni.Name">
                                    {{Uni?.Name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="form-section">
                            <div class="attach_file attach_file_idproof">
                                <img src="/assets/icons/attach_icon.svg" alt=""> Upload Credit Rating
                                <input type="file" class="upload" #my2FileInput accept="application/pdf, application/vnd.ms-excel" (change)="fileUpload($event, addContactDetail.copy)">
                            </div>

                            <mat-error>
                                {{addContactDetail.copy.fileSizeError}}
                            </mat-error>
                            <div class="uploadfile-nameicon d-flex justify-content-between" *ngIf="addContactDetail?.copy?.DocumentFileName">
                                <span>
                                    <a (click)="downloadMyFile(addContactDetail.copy)">{{addContactDetail.copy.DocumentFileName}}
                                    </a></span>
                                <a routerLink="." (click)="my2FileInput.value='';fileUploadRemove(addContactDetail.copy)">
                                    <mat-icon>close</mat-icon>
                                </a>
                            </div>
                            <mat-error>
                                {{addContactDetail?.copy?.pdfvalid}}
                            </mat-error>
                            <mat-error *ngIf="f.submitted && !addContactDetail?.copy?.DocumentFileName" class="pt-2">
                                Please upload Aadhar Front Copy</mat-error>
                        </div>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'add-Rating'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorFacilitySave')" color="primary">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'edit-Rating'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorFacilitySave')" color="primary">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="dialogAction == 'add-Other' || dialogAction == 'edit-Other'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2>Add Certificate</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">

                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Name of Certificate</mat-label>
                            <input type="text" matInput placeholder="Name of Certificate" [class.error]="f.submitted && CertificateName.invalid" [(ngModel)]="addContactDetail.CertificateName" name="CertificateName" #CertificateName="ngModel">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Certifying Agency</mat-label>
                            <input type="text" matInput placeholder="Certifying Agency" [class.error]="f.submitted && Agency.invalid" [(ngModel)]="addContactDetail.Agency" name="Agency" #Agency="ngModel">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Certificate No.</mat-label>
                            <input type="text" matInput placeholder="Certificate No." [class.error]="f.submitted && Agency.invalid" [(ngModel)]="addContactDetail.Agency" name="Agency" #Agency="ngModel">
                        </mat-form-field>
                        <mat-form-field appearance="outline" (click)="picker.open()">
                            <mat-label>Valid Till</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="addContactDetail.ValidDate" name="ValidDate" #ValidDate="ngModel" readonly>
                            <mat-datepicker-toggle matSuffix [for]="picker">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker>
                            </mat-datepicker>
                        </mat-form-field>
                        <div class="form-section">
                            <div class="attach_file attach_file_idproof">
                                <img src="/assets/icons/attach_icon.svg" alt=""> Upload Certificate
                                <input type="file" class="upload" #my2FileInput accept="application/pdf, application/vnd.ms-excel" (change)="fileUpload($event, addContactDetail.copy)">
                            </div>

                            <mat-error>
                                {{addContactDetail.copy.fileSizeError}}
                            </mat-error>
                            <div class="uploadfile-nameicon d-flex justify-content-between" *ngIf="addContactDetail?.copy?.DocumentFileName">
                                <span>
                                    <a (click)="downloadMyFile(addContactDetail.copy)">{{addContactDetail.copy.DocumentFileName}}
                                    </a></span>
                                <a routerLink="." (click)="my2FileInput.value='';fileUploadRemove(addContactDetail.copy)">
                                    <mat-icon>close</mat-icon>
                                </a>
                            </div>
                            <mat-error>
                                {{addContactDetail?.copy?.pdfvalid}}
                            </mat-error>
                            <mat-error *ngIf="f.submitted && !addContactDetail?.copy?.DocumentFileName" class="pt-2">
                                Please upload Aadhar Front Copy</mat-error>
                        </div>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'add-Other'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorFacilitySave')" color="primary">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'edit-Other'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorFacilitySave')" color="primary">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="dialogAction == 'add-OtherDocuments' || dialogAction == 'edit-OtherDocuments'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2>Add Document</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">

                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Name of Document</mat-label>
                            <input type="text" matInput placeholder="Name of Document" [class.error]="f.submitted && CertificateName.invalid" [(ngModel)]="addContactDetail.CertificateName" name="CertificateName" #CertificateName="ngModel">
                        </mat-form-field>
                        <div class="form-section">
                            <div class="attach_file attach_file_idproof">
                                <img src="/assets/icons/attach_icon.svg" alt=""> Upload Document
                                <input type="file" class="upload" #my2FileInput accept="application/pdf, application/vnd.ms-excel" (change)="fileUpload($event, addContactDetail.copy)">
                            </div>
                            <mat-error>
                                {{addContactDetail.copy.fileSizeError}}
                            </mat-error>
                            <div class="uploadfile-nameicon d-flex justify-content-between" *ngIf="addContactDetail?.copy?.DocumentFileName">
                                <span>
                                    <a (click)="downloadMyFile(addContactDetail.copy)" >{{addContactDetail.copy.DocumentFileName}}
                                    </a></span>
                                <a routerLink="." (click)="my2FileInput.value='';fileUploadRemove(addContactDetail.copy)">
                                    <mat-icon>close</mat-icon>
                                </a>
                            </div>
                            <mat-error>
                                {{addContactDetail?.copy?.pdfvalid}}
                            </mat-error>
                            <mat-error *ngIf="f.submitted && !addContactDetail?.copy?.DocumentFileName" class="pt-2">
                                Please upload Aadhar Front Copy</mat-error>
                        </div>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'add-OtherDocuments'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorFacilitySave')" color="primary">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'edit-OtherDocuments'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorFacilitySave')" color="primary">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="dialogAction == 'add-vendor-compliance' || dialogAction == 'edit-vendor-compliance'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2>Add Vendor Compliance</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item" fxFlex="0 1 100">

                        <mat-form-field appearance="outline">
                            <mat-label>Vendor Compliance</mat-label>
                            <input type="text" matInput placeholder="Name of Document" [class.error]="f.submitted && CertificateName.invalid" [(ngModel)]="addContactDetail.ComplianceName" name="CertificateName" #CertificateName="ngModel" required>
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'add-vendor-compliance'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorComplianceSave')" color="primary">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'edit-vendor-compliance'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorComplianceSave')" color="primary">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div *ngIf="dialogAction == 'add-vendor-supplier' || dialogAction == 'edit-vendor-supplier' " class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2 *ngIf="dialogAction == 'add-vendor-supplier'">Add Vendor Supplier</h2>
            <h2 *ngIf="dialogAction == 'edit-vendor-supplier'">Edit Vendor Supplier</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item" fxFlex="0 1 100">

                        <mat-form-field appearance="outline">
                            <mat-label>Vendor Supplier</mat-label>
                            <input type="text" matInput placeholder="Name of Document" [class.error]="f.submitted && CertificateName.invalid" [(ngModel)]="addContactDetail.SupplierCategoryName" name="CertificateName" #CertificateName="ngModel" required>
                        </mat-form-field>
                    </div>



                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorSupplierCategorySave')" color="primary">
                            <span *ngIf="dialogAction == 'add-vendor-supplier'">Save</span>
                            <span *ngIf="dialogAction == 'edit-vendor-supplier'">Update</span></button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- addOffering -->
<div *ngIf="dialogAction == 'add-Offering' || dialogAction == 'Edit-Offering'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2 *ngIf="dialogAction == 'add-Offering'"> Add Offering</h2>
            <h2 *ngIf="dialogAction == 'Edit-Offering'"> Edit Offering</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Offering</mat-label>
                            <input type="text" matInput placeholder="Name of Offering" [class.error]="f.submitted && MajorOfferingName.invalid" [(ngModel)]="addContactDetail.MajorOfferingName" name="MajorOfferingName" required #MajorOfferingName="ngModel">
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'add-Offering'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorMajorOfferingSave')" color="primary">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'Edit-Offering'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorMajorOfferingSave')" color="primary">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<!--add Offering  -->
<!-- add Sub-Offering -->
<div *ngIf="dialogAction == 'addSub-Offering' || dialogAction == 'EditSub-Offering'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2 *ngIf="dialogAction == 'addSub-Offering'">Add Sub Offering</h2>
            <h2 *ngIf="dialogAction == 'EditSub-Offering'">Edit Sub Offering</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Offering</mat-label>
                            <mat-select placeholder="Select Offering" [(ngModel)]="addContactDetail.MajorOfferingId" [compareWith]="compareFn" name="MajorOfferingName" required>
                                <!-- <mat-option value="">Select Major Offering Name</mat-option> -->
                                <mat-option *ngFor="let list of AssetItemDropdown " [value]="list.AssetItemId">
                                    {{list?.AssetItemName}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Sub Offering</mat-label>
                            <input type="text" matInput placeholder="Sub Offering Name" [class.error]="f.submitted && SubOfferingName.invalid" [(ngModel)]="addContactDetail.SubOfferingName" name="SubOfferingName" #SubOfferingName="ngModel" required>
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'addSub-Offering'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorOfferingSubSave')" color="primary">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'EditSub-Offering'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorOfferingSubSave')" color="primary">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- add Sub-Offering -->
<!-- add-Facility -->
<div *ngIf="dialogAction == 'add-Facility' || dialogAction == 'EditFacility'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2 *ngIf="dialogAction == 'add-Facility'">Add Facility</h2>
            <h2 *ngIf="dialogAction == 'EditFacility'">Edit Facility</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item" fxFlex="0 1 100">

                        <mat-form-field appearance="outline">
                            <mat-label>Facility</mat-label>
                            <input type="text" matInput placeholder="Name of Facility" [class.error]="f.submitted && FacilityName.invalid" [(ngModel)]="addContactDetail.FacilityName" name="FacilityName" #FacilityName="ngModel" required>
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'add-Facility'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorFacilitySave')" color="primary">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'EditFacility'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorFacilitySave')" color="primary">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- add-Facility -->
<!-- add-Category -->
<div *ngIf="dialogAction == 'addServiceProvider' || dialogAction == 'EditServiceProvider'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2 *ngIf="dialogAction == 'addServiceProvider'">Add Category</h2>
            <h2 *ngIf="dialogAction == 'EditServiceProvider'">Edit Category</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item" fxFlex="0 1 100">

                        <mat-form-field appearance="outline">
                            <mat-label>Category</mat-label>
                            <!-- <input type="text" matInput required> -->
                            <input type="text" matInput [class.error]="f.submitted && servicecategoryname.invalid" [(ngModel)]="addContactDetail.ServiceCategoryName" name="servicecategoryname" #servicecategoryname="ngModel" required>
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'addServiceProvider'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorServiceCategorySave')" color="primary">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'EditServiceProvider'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorServiceCategorySave')" color="primary">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div *ngIf="dialogAction == 'Addvendordoc' || dialogAction == 'Editvendordoc'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2 *ngIf="dialogAction == 'Addvendordoc'">Add Vendor Document Type</h2>
            <h2 *ngIf="dialogAction == 'Editvendordoc'">Edit Vendor Document Type</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item oganization_add" fxLayout="column" fxFlex="0 1 50">
                        <mat-form-field appearance="outline">
                            <mat-label>Organization Type</mat-label>
                            <mat-select  #select [compareWith]="compareFn" [(ngModel)]="addContactDetail.OrganisationType" name="vendororganizationtypeid" multiple required  [class.error]="f.submitted && vendororganizationtypeid.invalid"
                                placeholder="Vendor Organization Type" #vendororganizationtypeid="ngModel" class="form-control position-relative">
                                <fuse-mat-select-search name="search" [(ngModel)]="vendordocfilter">
                                </fuse-mat-select-search>
                                <div class="select-all" [class.select-all2]="allSelected">
                                <mat-checkbox [(ngModel)]="allSelected"
                                [ngModelOptions]="{standalone: true}"
                                (change)="orgselect()">All</mat-checkbox>
                                </div>
                                <mat-option class="vendordocumenttype" (click)="optionClick()" *ngFor="let customer of VenderType | filter: vendordocfilter" [value]="customer">
                                    {{customer.OrganisationTypeName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="flex-item " fxFlex="0 1 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Document Type</mat-label>
                            <input type="text" matInput [class.error]="f.submitted && DocumentTypeName.invalid" [(ngModel)]="addContactDetail.DocumentTypeName" name="DocumentTypeName" #DocumentTypeName="ngModel" required>
                        </mat-form-field>
                    </div>
                    <div class="flex-item mb-12" fxFlex="0 1 100">
                    <mat-checkbox [checked]="addContactDetail?.IsMandatory " (change)="Mandatorycheck($event) ">Is mandatory?</mat-checkbox>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'Addvendordoc'" [disabled]="f.form.invalid" (click)="saveVandorMaster('DocumentTypeSave')" color="primary">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'Editvendordoc'" [disabled]="f.form.invalid" (click)="saveVandorMaster('DocumentTypeSave')" color="primary">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- add-Category -->
<!-- add block -->
<div *ngIf="dialogAction == 'add-block' ||dialogAction == 'edit-block' " class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2 *ngIf="dialogAction == 'add-block'">Add Block</h2>
            <h2 *ngIf="dialogAction == 'edit-block'">Edit Block</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Location</mat-label>
                            <mat-select  name="LocationId" #LocationId="ngModel" required [(ngModel)]="addContactDetail.LocationId">

                            <mat-option [value]="cmp.BillingId" *ngFor="let cmp of CompanyBillingDropDownList">
                                {{cmp.LocationName   }}
                            </mat-option>
                        </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Block</mat-label>
                            <input type="text" [(ngModel)]="addContactDetail.AssetBlockName" name="AssetBlockName" #AssetBlockName="ngModel" matInput placeholder="Block" required>
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'add-block'" [disabled]="f.form.invalid" color="primary" (click)="saveassetMaster('AssetBlockSave')">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'edit-block'" [disabled]="f.form.invalid" (click)="saveassetMaster('AssetBlockSave')" color="primary">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- add block -->
<!-- add Floor -->
<div *ngIf="dialogAction == 'add-Floor' || dialogAction == 'edit-floor'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2 *ngIf="dialogAction == 'add-Floor'">Add Floor</h2>
            <h2 *ngIf="dialogAction == 'edit-floor'">Edit Floor</h2>
            <!-- <h2 *ngIf="dialogAction == ''">Edit Floor</h2> -->
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                      <mat-form-field appearance="outline">
                        <mat-label>Location</mat-label>
                        <mat-select  name="LocationId" required #LocationId="ngModel" (selectionChange)="LocationFilter($event.value)"  [(ngModel)]="addContactDetail.LocationId">

                        <mat-option [value]="cmp.BillingId" *ngFor="let cmp of CompanyBillingDropDownList">
                            {{cmp.LocationName   }}
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Block</mat-label>
                            <mat-select  name="AssetBlockId" required #AssetBlockId="ngModel" required  [(ngModel)]="addContactDetail.AssetBlockId">

                              <mat-option [value]="block.AssetBlockId" *ngFor="let block of assetDropdownlist">
                                  {{block.AssetBlockName   }}
                              </mat-option>
                              </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                          <mat-label>Floor</mat-label>
                          <input type="text" [(ngModel)]="addContactDetail.AssetFloorName" name="AssetFloorName" #AssetFloorName="ngModel" matInput placeholder="Floor" required>
                      </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                      <button mat-flat-button *ngIf="dialogAction == 'add-Floor'" [disabled]="f.form.invalid" color="primary" (click)="saveassetMaster('AssetFloorSave')">Save</button>
                      <button mat-flat-button *ngIf="dialogAction == 'edit-floor'" [disabled]="f.form.invalid" (click)="saveassetMaster('AssetFloorSave')" color="primary">Update</button>
                  </div>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- add Floor -->
<!-- add section -->
<div *ngIf="dialogAction == 'add-Section' || dialogAction == 'edit-Section'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2 *ngIf="dialogAction == 'add-Section'">Add Section</h2>
            <h2 *ngIf="dialogAction == 'edit-Section'">Edit Section</h2>
            <!-- <h2 *ngIf="dialogAction == ''">Edit Floor</h2> -->
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                      <mat-form-field appearance="outline">
                        <mat-label>Location</mat-label>
                        <mat-select  name="LocationId"required #LocationId="ngModel" (selectionChange)="LocationFilter($event.value)" [(ngModel)]="addContactDetail.LocationId">

                        <mat-option [value]="cmp.BillingId" *ngFor="let cmp of CompanyBillingDropDownList">
                            {{cmp.LocationName   }}
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                      <mat-label>Block</mat-label>
                      <mat-select  name="AssetBlockId"required #AssetBlockId="ngModel"  (selectionChange)="blockFilter($event.value)"  [(ngModel)]="addContactDetail.AssetBlockId">

                        <mat-option [value]="block.AssetBlockId" *ngFor="let block of assetDropdownlist">
                            {{block.AssetBlockName   }}
                        </mat-option>
                        </mat-select>
                  </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Floor</mat-label>
                            <mat-select  name="AssetFloorId" required #AssetFloorId="ngModel"  [(ngModel)]="addContactDetail.AssetFloorId">

                              <mat-option [value]="floor.AssetFloorId" *ngFor="let floor of FloorDropdownlist">
                                  {{floor.AssetFloorName   }}
                              </mat-option>
                              </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Section</mat-label>
                            <input type="text" [(ngModel)]="addContactDetail.AssetSectionName" name="AssetSectionName" #AssetSectionName="ngModel" matInput placeholder="Section" required>

                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                      <button mat-flat-button *ngIf="dialogAction == 'add-Section'" [disabled]="f.form.invalid" color="primary" (click)="saveassetMaster('AssetSectionSave')">Save</button>
                      <button mat-flat-button *ngIf="dialogAction == 'edit-Section'" [disabled]="f.form.invalid" (click)="saveassetMaster('AssetSectionSave')" color="primary">Update</button>
                  </div>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- add section -->
<!-- add storage -->
<div *ngIf="dialogAction == 'add-Storage' || dialogAction == 'edit-Storage'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2 *ngIf="dialogAction == 'add-Storage'">Add Storage</h2>
            <h2 *ngIf="dialogAction == 'edit-Storage'">Edit Storage</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                      <mat-form-field appearance="outline">
                        <mat-label>Location</mat-label>
                        <mat-select  name="LocationId"required #LocationId="ngModel" (selectionChange)="LocationFilter($event.value)"
                        [compareWith]="compareFn"  [(ngModel)]="addContactDetail.LocationId">
                        <mat-option [value]="cmp.BillingId" *ngFor="let cmp of CompanyBillingDropDownList">
                            {{cmp.LocationName   }}
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                      <mat-label>Block</mat-label>
                      <mat-select  name="AssetBlockId"required #AssetBlockId="ngModel"  (selectionChange)="blockFilter($event.value)"
                      [compareWith]="compareFn" [(ngModel)]="addContactDetail.AssetBlockId">
                        <mat-option [value]="block.AssetBlockId" *ngFor="let block of assetDropdownlist">
                            {{block.AssetBlockName   }}
                        </mat-option>
                        </mat-select>
                  </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Floor</mat-label>
                            <mat-select  name="AssetFloorId" required #AssetFloorId="ngModel"  [(ngModel)]="addContactDetail.AssetFloorId"
                            (selectionChange)="floorFilter($event.value)"  [compareWith]="compareFn" >
                              <mat-option [value]="floor.AssetFloorId" *ngFor="let floor of FloorDropdownlist">
                                  {{floor.AssetFloorName   }}
                              </mat-option>
                              </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Section</mat-label>
                            <mat-select  name="AssetSectionId" required #AssetSectionId="ngModel"  [(ngModel)]="addContactDetail.AssetSectionId"
                            [compareWith]="compareFn" >
                              <mat-option [value]="Section.AssetSectionId" *ngFor="let Section of SectionDropdownlist">
                                  {{Section.AssetSectionName   }}
                              </mat-option>
                              </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Storage</mat-label>
                            <input type="text" [(ngModel)]="addContactDetail.AssetStorageName" name="AssetStorageName" #AssetStorageName="ngModel" matInput placeholder="Storage" required>
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                      <button mat-flat-button *ngIf="dialogAction == 'add-Storage'" [disabled]="f.form.invalid" color="primary" (click)="saveassetMaster('StorageSave')">Save</button>
                      <button mat-flat-button *ngIf="dialogAction == 'edit-Storage'" [disabled]="f.form.invalid" (click)="saveassetMaster('StorageSave')" color="primary">Update</button>
                  </div>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- add support team latest-->
<div *ngIf="dialogAction == 'add-Support-Team' || dialogAction == 'supportReassign'" class="prsn_dialog suppTeam">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2 *ngIf="dialogAction == 'add-Support-Team'">Add Member</h2>
            <h2 *ngIf="dialogAction == 'supportReassign'">Re-Assign</h2>
            <!-- <h2 *ngIf="dialogAction == ''">Edit Floor</h2> -->
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Search Employee</mat-label>
                            <input matInput [(ngModel)]="searchText" name="searchText" autocomplete="off">
                            <span matSuffix>
                                <mat-icon class="search_icon">search</mat-icon>
                            </span>
                        </mat-form-field>
                    </div>
                    <div class="all_depart_innner mb-3" style="overflow-y: scroll; max-height: 352px;">
                        <div class="flex-item" fxFlex="0 1 100">
                            <div class="list_div" >
                                <div class="alpha_list">
                                    <div class="name_list" *ngFor="let user of EmployeeList | filter:searchText ; let i = index" style="margin-bottom:15px !important;">
                                        <div class="about_employ">
                                            <div class="user_img">
                                                <img src="{{user?.EmployeeImagePath}}" style="width: 47px; height: 47px; border-radius: 50%;">
                                            </div>
                                            <div class="user_name">
                                                <h5>{{user?.MemberName}}</h5>
                                                <h6>{{user?.MemberDesignation}}</h6>
                                            </div>
                                        </div>
                                        <div class="user_check" style="width:22px !important;">


                                            <mat-radio-group [(ngModel)]="user.SRassignedTo" name="Saveteamsupport" (change)="EmployeeData($event, user,i)">
                                                <mat-radio-button></mat-radio-button>
                                            </mat-radio-group>
                                            <!-- <mat-checkbox class="sameascuradd-check nopremp-checkbox" name="Saveteamsupport_{{i}}" >
                                            </mat-checkbox> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'supportReassign'" (click)="Saveteammember1()" [disabled]="f.form.invalid || !srassignto" color="primary">Save</button>
                        <!-- <button mat-flat-button *ngIf="dialogAction == 'EditFacility'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorFacilitySave')" color="primary">Update</button> -->
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- end support team latest -->
<!-- add support categories -->
<div *ngIf="dialogAction == 'add-support-categories' || dialogAction == 'Edit-Support-Category'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2 *ngIf="dialogAction == 'add-support-categories'">Add Category</h2>
            <h2 *ngIf="dialogAction == 'Edit-Support-Category'">Edit Category</h2>
            <!-- <h2 *ngIf="dialogAction == ''">Edit Floor</h2> -->
            <div class="form_layout suppcat">
                <div fxLayout="column wrap">
                    <div class="flex-item " fxFlex="0 1 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Category</mat-label>
                            <input type="text" matInput [(ngModel)]="SupportCategory.CategoryName" name="CategoryName" required>
                        </mat-form-field>
                        <div class="col-md-8 pl-0">
                            <div class="resulationTime">
                                <label class="timeSet">Set Resolution Time</label>
                            </div>
                            <div class="row">
                                <div class="col-md-7 pr-3">
                                    <div class="row">
                                        <div class="col-md-6 pr-0 heightsame-outer">
                                            <mat-form-field appearance="outline" class="mat-field-leftbx">
                                                <input type="number" matInput [(ngModel)]="SupportCategory.ResolutionTimeHour" name="SubcategoryName" required>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6 pl-0 desabled_cls">
                                            <mat-form-field appearance="outline" class="mat-field-rightbx">
                                                <input matInput type="number" placeholder="Hours" required disabled>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5 p-0">
                                    <mat-form-field appearance="outline">
                                        <mat-select placeholder="Minute" class="form-control" [(value)]="selected" [(ngModel)]="SupportCategory.ResolutionTimeMinute" required name="ResponseTimeMinute">
                                            <mat-option *ngFor="let mint of minuteList" [value]="mint.value">
                                                {{mint.time}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <mat-form-field appearance="outline" class="empmulti ">
                            <mat-label>Select Employees</mat-label>
                            <mat-select placeholder="Select Employees" class="form-control sameascuradd-check nopremp-checkbox" multiple required [(ngModel)]="SupportCategory.SupportEmployeeLevel" name="SupportEmployeeLevel">
                                <fuse-mat-select-search name="search" [(ngModel)]="SupportsCat1">
                                </fuse-mat-select-search>
                                <mat-option style="min-height: 57px; padding-top: 5px; padding-bottom: 5px; background-color: #fff !important;" class="d-block alpha_list" *ngFor="let list of EmployeeList |filter:  SupportsCat1" [value]="list">
                                    <div class="name_list">
                                        <div class="about_employ">
                                            <div class="user_img">
                                                <img style="width: 47px; height: 47px; border-radius: 50%;" [src]="list?.EmployeeImagePath">
                                            </div>
                                            <div class="user_name">
                                                <h5>{{list?.MemberName}}</h5>
                                                <!-- <h6>{{list?.MemberDesignation}}</h6> -->
                                            </div>
                                        </div>
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="all_depart_innner mb-3 " *ngIf="SupportCategory?.SupportEmployeeLevel?.length > 0">
                        <div class="flex-item" fxFlex="0 1 100">
                            <div class="list_div" >
                                <div class="alpha_list">
                                    <div class="d-flex justify-content-between mb-3" style="font-weight: 700; font-size: 16px; color: #4C5869;">
                                        <span>Selected Employee</span>
                                        <span>Set Level</span>
                                    </div>
                                    <div class="name_list" *ngFor="let user of SupportCategory.SupportEmployeeLevel; let i = index">
                                        <div class="about_employ">
                                            <div class="user_img">
                                                <img [src]="user?.EmployeeImagePath" style="width: 47px; height: 47px; border-radius: 50%;">
                                            </div>
                                            <div class="user_name">
                                                <h5>{{user?.MemberName}}</h5>
                                                <h6>{{user?.MemberDesignation}}</h6>
                                            </div>
                                        </div>

                                        <div class="user_check">
                                            <mat-form-field appearance="outline">
                                                <!-- (selectionChange)="selectlevel($event.value)"  -->
                                                <mat-label>Level</mat-label>
                                                <mat-select placeholder="Level" required name="SupportLevelId_{{i}}" [(ngModel)]="user.SupportLevelId" (selectionChange)="levelselect($event)" class="form-control">
                                                    <mat-option *ngFor="let lvl of empLevelDropList" [value]="lvl.SupportLevelId">{{lvl.SupportLevelName}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <mat-error *ngIf="!levelshow">Please select at least one L1 employee</mat-error>
                        </div>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'add-support-categories'" [disabled]="(f.form.invalid) || !levelshow " (click)="Savecat()" color="primary">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'Edit-Support-Category'" [disabled]="(f.form.invalid) || !levelshow" (click)="Savecat()" color="primary">Update</button>
                        <!-- <button mat-flat-button *ngIf="dialogAction == 'EditFacility'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorFacilitySave')" color="primary">Update</button> -->
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- end support categories -->
<!-- add support Sub Category -->
<div *ngIf="dialogAction == 'add-support-Subcategories' ||dialogAction ==  'Edit-Support-SubCategory'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2 *ngIf="dialogAction == 'add-support-Subcategories'">Add Sub Category</h2>
            <h2 *ngIf="dialogAction == 'Edit-Support-SubCategory'">Edit Sub Category</h2>
            <!-- <h2 *ngIf="dialogAction == ''">Edit Floor</h2> -->
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item" fxFlex="0 1 100">

                        <mat-form-field appearance="outline">
                            <mat-label>Category</mat-label>
                            <mat-select class="form-control" [(ngModel)]="SupportSubCategory.CategoryId" name="CategoryId" required>
                                <fuse-mat-select-search name="search" [(ngModel)]="SupportsCat">
                                </fuse-mat-select-search>
                                <mat-option *ngFor="let list of categoryList| filter: SupportsCat" [value]="list.CategoryId">
                                    {{list?.CategoryName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Sub-Category</mat-label>

                            <input type="text" matInput [(ngModel)]="SupportSubCategory.SubcategoryName" name="SubcategoryName" placeholder="Sub-Category" required>
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'add-support-Subcategories'" [disabled]="f.form.invalid" (click)="Savesubcat()" color="primary">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'Edit-Support-SubCategory'" [disabled]="f.form.invalid" (click)="Savesubcat()" color="primary">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- end support Sub Category -->
<!-- add support team-->
<div *ngIf="dialogAction == 'add-Support-Let-Team' || dialogAction == 'Edit-Support-Let-Team'" class="prsn_dialog suppTeam">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2 *ngIf="dialogAction == 'add-Support-Let-Team'">Add Member</h2>
            <h2 *ngIf="dialogAction == 'Edit-Support-Let-Team'">Edit Member</h2>
            <!-- {{EmployeeList.length}}
            <br>

            {{EmployeeList | json}} -->

            <!-- <h2 *ngIf="dialogAction == ''">Edit Floor</h2> -->
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Search Employee</mat-label>
                            <input matInput [(ngModel)]="searchText" name="searchText" autocomplete="off">
                            <span matSuffix>
                                <mat-icon class="search_icon">search</mat-icon>
                            </span>
                        </mat-form-field>
                    </div>
                    <div class="all_depart_innner mb-3" style="overflow-y: scroll;height: 400px;">
                        <div class="flex-item" fxFlex="0 1 100">
                            <div class="list_div" style="width:411px">
                                <div class="alpha_list">
                                    <div class="name_list" *ngFor="let user of EmployeeList | filter:searchText ; let i = index" style="margin-bottom:15px !important;">
                                        <div class="about_employ">
                                            <div class="user_img">
                                                <img [src]="user?.EmployeeImagePath" style="width: 47px; height: 47px; border-radius: 50%;">
                                            </div>
                                            <div class="user_name">
                                                <h5>{{user?.EmployeeName}}</h5>
                                                <h6>{{user?.Designation}}</h6>
                                            </div>
                                        </div>
                                        <div class="user_check" style="width:22px !important;">
                                            <mat-checkbox class="sameascuradd-check nopremp-checkbox" name="Saveteamsupport_{{i}}" (change)="EmployeeData($event.checked, user,i)">
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'add-Support-Let-Team'" (click)="Saveteammember()" [disabled]="f.form.invalid" color="primary">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'Edit-Support-Let-Team'" (click)="Saveteammember()" [disabled]="f.form.invalid" color="primary">Update</button>
                        <!-- <button mat-flat-button *ngIf="dialogAction == 'EditFacility'" [disabled]="f.form.invalid" (click)="saveVandorMaster('VendorFacilitySave')" color="primary">Update</button> -->
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- end support team latest -->
<!-- add support Level -->
<div *ngIf="dialogAction == 'add-Support-Level'||dialogAction == 'Edit-Support-Level'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #fd="ngForm">
        <div class="modal_section">
            <h2 *ngIf="dialogAction == 'add-Support-Level'">Add Level</h2>
            <h2 *ngIf=" dialogAction == 'Edit-Support-Level'">Edit Level</h2>
            <!-- <h2 *ngIf="dialogAction == ''">Edit Floor</h2> -->
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Level</mat-label>
                            <input type="text" matInput required [(ngModel)]="SupportLevel.SupportLevelName" name="SupportLevelName" #SupportLevelName="ngModel">

                        </mat-form-field>
                        <div class="col-md-8 pl-0">
                            <div class="resulationTime">
                                <label class="timeSet">Set Response Time</label>
                            </div>
                            <div class="row">
                                <div class="col-md-7 pr-3">
                                    <div class="row">
                                        <div class="col-md-6 pr-0 heightsame-outer">
                                            <mat-form-field appearance="outline" class="mat-field-leftbx">
                                                <input type="number" matInput [class.error]="fd.submitted && ResponseTimeHour.invalid" name="ResponseTimeHour" [(ngModel)]="SupportLevel.ResponseTimeHour" required #ResponseTimeHour="ngModel">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6 pl-0 desabled_cls">
                                            <mat-form-field appearance="outline" class="mat-field-rightbx">
                                                <input matInput type="number" placeholder="Hours" required disabled>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5 p-0">
                                    <mat-form-field appearance="outline">
                                        <mat-select placeholder="Minute" class="form-control" name="ResponseTimeMinute" [(ngModel)]="SupportLevel.ResponseTimeMinute" required>
                                            <mat-option *ngFor="let mint of minuteList" [value]="mint.value">
                                                {{mint.time}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'add-Support-Level'" (click)="Levelsupport()" [disabled]="fd.form.invalid " color="primary">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'Edit-Support-Level'" (click)="Levelsupport()" [disabled]="fd.form.invalid" color="primary">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- end support Level -->
