import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consumable-categories',
  templateUrl: './consumable-categories.component.html',
  styleUrls: ['./consumable-categories.component.scss']
})
export class ConsumableCategoriesComponent implements OnInit {

  obj = {
    heading: "Consumable Category",
    addModalTitle: "Consumable Category",
    addModelAction: "Addconsumablecategory",
    baseUrlType: 'Consumable',
    editModalTitle: "Consumable category",
    editModelAction: "Editconsumablecategory",
    endPoint: "ConsumableCategoryList",
    addColumns: 1,
    colHeadings: { col1: 'Consumable Category Name' },
    sortableColumns: [
      { data: '0', Title: '#' },
      { data: 'assetcategoryname', Title: 'Consumable Category' },
      { data: 'createddate', Title: 'Created on' },
      { data: '4', Title: 'Action' },
    ],
    deleteEndPoint: 'consumableCategoryDelete',
    getListObj: {
      AssetClassName: '_blank'
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
