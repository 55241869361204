import { Component, OnInit } from '@angular/core';
export interface Datatype {
  Asset: string;
  Category : string;
  SubCategory : string;
  AssetAttributes : string;
  mobile: string;
}
@Component({
  selector: 'app-asset-attributes',
  templateUrl: './asset-attributes.component.html',
  styleUrls: ['./asset-attributes.component.scss']
})
export class AssetAttributesComponent {
  obj = {
    heading: "Asset Attributes",
    ModalpopupComponent: true,
    baseUrlType: 'Asset',
    addModalTitle: "Asset Attribute",
    addModelAction: "AddAssetAttribute",
    editModalTitle: "Edit Asset Attribute",
    editModelAction: "EditAssetAttribute",
    endPoint: "AttributeList",
    endPoint2: "assetattribute",
    colHeadings: { col1: 'Asset Attribute'},

    sortableColumns: [
      {data: '0', Title: '#' },
      { data: 'Asset.AssetAttributes', Title: 'Asset Attribute' },
      { data: 'createddate', Title: '	Created on' },
      {data: '5', Title: 'Action' },
    ],
    deleteEndPoint: 'AttributeDelete',
    getListObj: {
      AssetAttributeName: '_blank',
    }
  }
}
