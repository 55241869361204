<div class="content inOutLogPage">
    <div class="page-heading">
        <div fxFlex fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
                <h1>Consumable Requisitions</h1>
            </div>
            <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            </div>
        </div>
    </div>

    <div class="content">
    <div class="content_body" [class.show-search]="isShow">
        <div class="filter_area pb-20">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex="1 0 auto">
                    <div class="top_drop" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                        <div class="input_group filter_box mr-3">
                            <p class="left_label">Status</p>
                            <mat-form-field appearance="outline" class="mb-0 mt-15">
                                <mat-select placeholder="All Type" [(ngModel)]="approval.status" name="status"
                                    (selectionChange)="changeFilter()">
                                    <mat-option [value]="0"> All </mat-option>
                                    <mat-option *ngFor="let item of status" [value]="item?.StatusId">
                                        {{item.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="mini_btn" fxFlex="0 1 auto">
                    <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <div class="search-outer position-relative">
                            <button mat-mini-fab class="search_btn" (click)="toggleSearch('show')" *ngIf="!isShow">
                                <mat-icon>search</mat-icon>
                            </button>
                            <button mat-mini-fab class="search_btn" (click)="toggleSearch('hide')" *ngIf="isShow">
                                <mat-icon>clear</mat-icon>
                            </button>
                            <div class="search_form">
                                <input type="text" matInput placeholder="Search by My Assets">
                            </div>
                        </div>
                        <button mat-mini-fab color="accent" class="gray_btn">
                            <img src="assets/icons/table.svg">
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-white table-responsive list_view server_side_page">
            <!-- <table class="table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Item</th>
                        <th>Type Usages</th>
                        <th>Quantity</th>
                        <th>Required On/Before</th>
                        <th>Requisition By</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let list of [1,2,3,4,5]; let i = index">
                        <td>{{i + 1}}</td>
                        <td>
                            <strong *ngIf="i == 0">Broom</strong>
                            <strong *ngIf="i == 1">Mop</strong>
                            <strong *ngIf="i == 2">Phenyl</strong>
                            <strong *ngIf="i == 3">Toilet Soap</strong>
                            <strong *ngIf="i == 4">Dish Soap</strong>
                        </td>
                        <td>Bulk</td>
                        <td>4 pcs</td>
                        <td>
                            <mat-chip-list aria-label="Dog selection">
                                <mat-chip
                                    style="font-size: 11px; padding: 0 8px; min-height: 20px; margin-bottom: 8px; color: #fff;"
                                    class="bg-danger">
                                    Important
                                </mat-chip>
                            </mat-chip-list>
                            26 July, 2022
                        </td>
                        <td>
                            <p class="d-flex">
                                <img src="/assets/images/user.png"
                                    style="width: 19px; height: 19px; margin-right: 8px; margin-top: 2px;">
                                <span>
                                    <strong>Prashant Pareek</strong><br>
                                    <small>EMP03/19/02007</small>
                                </span>
                            </p>
                        </td>
                        <td>
                            <mat-icon (click)="sidenav.toggle()" class="mticon" matTooltip="Description">
                                description
                            </mat-icon>
                        </td>
                        <td>Approved</td>
                        <td>
                            <div class="action_icons">
                                <div class="icon" matTooltip="Approve" (click)="approveModal('new')">
                                    <mat-icon>done</mat-icon>
                                </div>
                                <div class="icon" matTooltip="Decline" (click)="addDecline()">
                                    <mat-icon>clear</mat-icon>
                                </div>
                                <div class="icon" matTooltip="Rework" (click)="addRemark()">
                                    <mat-icon>sync</mat-icon>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table> -->

            <table id="table_lead" datatable [dtOptions]="dtOptions"
            class="row-border hover list_table table table-bordered table-hover table-compact">
            <thead>
                <tr>
                    <th *ngFor="let item of theadColumn" (click)="sortItem = item.position">
                        <div>
                            <span>{{item.Title}}</span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody [attr.data_sort]="'sort_' + sortItem">
                <tr *ngFor=" let element of DataOutput; let i = index">
                    <td>{{i+1+tabledata.start}}</td>
                    <td><strong>{{element?.ConsumableItemName}}</strong></td>
                    <td> 
                        <span *ngIf="element?.IsIndividual">Individual</span>
                        <span *ngIf="!element?.IsIndividual">Bulk</span>
                    </td>
                    <td> {{element?.Quantity}} {{element?.UOMName}}</td>
                    <td>
                        <mat-chip-list aria-label="Dog selection">
                            <mat-chip
                                style="font-size: 11px; padding: 0 8px; min-height: 20px; margin-bottom: 8px; color: #fff;"
                                class="bg-danger">
                                {{element?.ConsumablePriorityId == 1? 'Scheduled' :
                                element?.ConsumablePriorityId == 2 ? 'Important':
                                element?.ConsumablePriorityId == 3? 'Urgent' : element?.ConsumablePriorityId
                                == 4 ? 'Critical':'Immediate'
                                }}
                            </mat-chip>
                        </mat-chip-list> {{element?.RequireDate | date}}
                    </td>
                    <td>
                        <p class="d-flex">
                            <img src="/assets/images/user.png"
                                style="width: 19px; height: 19px; margin-right: 8px; margin-top: 2px;">
                            <span>
                                <strong>{{element?.RequestedBy}}</strong><br>
                                <small>{{element?.EmployeeCode}}</small>
                            </span>
                        </p>
                    </td> 
                    <td>
                        <mat-icon (click)="sidenav.toggle(); sidenavData = element" class="mticon" matTooltip="Description">
                            description
                        </mat-icon>
                    </td>
                    <td *ngIf="element?.status == 1; else statusAnother">
                        <div class="action_icons">
                            <div class="icon" matTooltip="Approve" (click)="changeStatus(element, 4)" >
                                <mat-icon>done</mat-icon>
                            </div>
                            <div class="icon" matTooltip="Decline" (click)="changeStatus(element, 3)">
                                <mat-icon>clear</mat-icon>
                            </div>
                            <!-- <div class="icon" matTooltip="Rework" (click)="changeStatus(element, 2)">
                                <mat-icon>sync</mat-icon>
                            </div> -->
                        </div>
                    </td>
                    <ng-template #statusAnother>
                        <td *ngIf="element?.status == 3; else notDecline">
                            <a href="javascript:void(0);" (click)="sidenavreason.toggle(); sidenavData = element?.Declinejustification">Declined</a>
                         </td>
                         <ng-template #notDecline>
                             <td>
                                {{element?.status == 1 ? 'To Be Approved' : element?.status == 4 ? 'Approved' : 'NA'}}
                             </td>
                         </ng-template>
                    </ng-template>
                </tr>
            </tbody>
            <tbody *ngIf="DataOutput?.length == 0">
                <tr>
                    <td colspan="9" class="text-center">No Record Found</td>
                </tr>
            </tbody>
        </table>
        </div>
    </div>
    </div>
</div>

  <!-- Mat Drawer -->
  <mat-drawer-container class="example-container epica-sidebar">
    <mat-drawer #sidenav class="sidebar taskdetail-sidebar" mode="over" position="end">
        <mat-icon class="sidebar-closeicon" (click)="sidenav.toggle()">highlight_off</mat-icon>
        <div class="taskdetail-dv">
            <h2>Detail</h2>
            <div class="task_detail_box">
                <div class="row">
                    <div class="col-md-12 dtl_text mb-3 pb-3">
                        <label>Description</label>
                        <p>{{sidenavData?.Description}}</p>
                    </div>
                    <div class="col-md-12 dtl_text">
                        <label>Justification</label>
                        <p>{{sidenavData?.Justification}}</p>
                    </div>
                </div>
            </div>
        </div>
    </mat-drawer>
</mat-drawer-container>
<!-- Mat Drawer -->
<mat-drawer-container class="example-container epica-sidebar">
    <mat-drawer #sidenavreason class="sidebar taskdetail-sidebar" mode="over" position="end">
        <mat-icon class="sidebar-closeicon" (click)="sidenavreason.toggle()">highlight_off</mat-icon>
        <div class="taskdetail-dv">
            <h2>Decline Reason</h2>
            <div class="task_detail_box">
                <div class="row">
                    <div class="col-md-12 dtl_text mb-3 pb-3">
                        <p>{{sidenavData}}</p>
                    </div>
                </div>
            </div>
        </div>
    </mat-drawer>
</mat-drawer-container>