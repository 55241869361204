import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlanworkConfigService } from '@planwork/services/config.service';
import { DataStorage } from 'app/store/storage';
import formatRelativeWithOptions from 'date-fns/esm/fp/formatRelativeWithOptions/index.js';
import { ServiceService } from '../core/masterservice/service.service';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  user: any;
  role_id: any;
  allNavigation: any;
  menu: any;
  SIDEBARDATA: any[] = [];
  constructor(
    private loginService: ServiceService,
    private router: Router,
    private planworkConfig: PlanworkConfigService,
  ) {
    this.user = JSON.parse(localStorage.getItem('userid'));
    this.role_id = JSON.parse(localStorage.getItem('roleId'));
    this.planworkConfig.sidebarHide.subscribe(res => {
      if (res == 1) {
        for (let list of this.menu) {
          list.isShow = false;
        }
      }
    });
  }

  ngOnInit(): void {
    if (this.user != null) {
      this.allNavigation = this.user.UserRoleList;
      this.allNavigation.forEach(element => {
        if (element.UserRoleId === this.role_id) {
          this.menu = element.UserRolePermissionList;
          for (let list of this.menu) {
            list.activeClass = false;
            list.isShow = false;
          }
        }
      });
      this.menu.forEach(menuEle => {
        if (menuEle.children.length > 0) {
          if(menuEle.children.findIndex(x=>x.id == "BranchPermission") > 0){
            menuEle.children?.splice(menuEle.children.findIndex(x=>x.id == "BranchPermission"), 1)
          }
          menuEle.children.forEach(element => {
            if (element.RoleId != null) {
              let checkRoll = element.RoleId.findIndex(res => res == this.user.UserRole.UserRoleId);
              if (checkRoll != -1) {
                element.type = true;
              } else {
                element.type = false;
              }
              element.children.forEach(secmap => {
                let checkRoll = secmap.RoleId.findIndex(res => res == this.user.UserRole.UserRoleId);
                if (checkRoll != -1) {
                  secmap.type = true;
                } else {
                  secmap.type = false;
                }
                secmap.Buttons.forEach(secmapbutton => {
                  let checkRoll = secmapbutton.RoleId.findIndex(res => res == this.user.UserRole.UserRoleId);
                  if (checkRoll != -1) {
                    secmapbutton.type = true;
                  } else {
                    secmapbutton.type = false;
                  }
                })
              })
            }
          })
        }
      });
      this.menu.forEach(menuEle => {
        if (menuEle.Pages.length > 0) {
          menuEle.Pages.forEach(element => {
            if (element.RoleId != null) {
              let checkRoll = element.RoleId.findIndex(res => res == this.user.UserRole.UserRoleId);
              if (checkRoll != -1) {
                element.type = true;
              } else {
                element.type = false;
              }
            }
          })
        }
      });
    }
    //Mobile code 
    $('.menum-btn').click(function () {
      $('#sidebar_left_main').addClass('active');
      $('.overlaym-all').addClass('active');
    })
    $('.overlaym-all').click(function () {
      $(this).removeClass('active');
      $('#sidebar_left_main').removeClass('active');
    })
  }
  itemTitle(item) {
    for (let list of this.menu) {
      list.activeClass = false;
      list.isShow = false;
    }
    item.activeClass = true;
    item.isShow = true;
  }
  chooseRoute(a, b) {
    localStorage.setItem('Mid', b.Mid);
    this.router.navigate([a.url]);
  }
  closeBar(item) {
    setTimeout(() => {
      item.isShow = false;
    }, 1);
  }
}
