import { Component } from '@angular/core';

@Component({
  selector: 'app-accommodation-type',
  templateUrl: './accommodation-type.component.html',
  styleUrls: ['./accommodation-type.component.scss']
})
export class AccommodationTypeComponent {

  obj = {
    heading: "Accommodation Type",
    addModalTitle: "Accommodation Type",
    addModelAction: "AddAccommodationType",
    baseUrlType: 'Expense',
    editModalTitle: "Accommodation Type",
    editModelAction: "EditAccommodationType",
    endPoint: "ExpenseAccomodationList",
    addColumns: 1,
    colHeadings: { col1: 'Accommodation Type Name' },
    sortableColumns: [
      { data: '0', Title: '#', width: 25 },
      { data: 'Accommodation  Type', Title: 'Accommodation Type' },
      // { data: '	2', Title: 'Monthly Mobile Limit'},
      // { data: '	3', Title: 'Local Conveyance (Per/KM)'},
      // { data: '	4', Title: 'DA/Per Day'},
      // { data: '	5', Title: 'Hotel Limit/ Per Night'},
      // { data: '	6', Title: 'Own Arrangement'},
      { data: '2', Title: 'Created on' },
      { data: '3', Title: 'Action', width: 60 },
    ],
    deleteEndPoint: 'ExpenseAccomodationDelete',
    getListObj: {
      Entitlementcodename: '_blank'
    }
  }

}
