import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { DatatableService } from "@planwork/datatable-settings";
import { DataTableDirective } from "angular-datatables";
import { CommonModalComponent } from "app/main/core/common/common-modal/common-modal.component";
import { ServiceService } from "app/main/core/masterservice/service.service";
import { BallparkModalsComponent } from "app/main/lead/lead-business-head/ballpark-modals/ballpark-modals.component";
import { planworkConfig } from "app/planwork.config";
import { NgxSpinnerService } from "ngx-spinner";
import {
  TableColumn1,
  TableColumn2,
  TableColumn3,
  TableColumn4,
  TableColumn5,
} from "./Table.Column";
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  Result: any[];
}
@Component({
  selector: "app-expense",
  templateUrl: "./expense.component.html",
  styleUrls: ["./expense.component.scss"],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class ExpenseComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('sidenav1') sidenav1: MatSidenav;
  @ViewChild('sidenav2') sidenav2: MatSidenav;
  @ViewChild('sidenav3') sidenav3: MatSidenav;
  popupComp: any;
  selected = 0;
  status:any=[
    {status_id:1, statusName:'Pending', type: 1},
    {status_id:2, statusName:'Approved', type: 1},
    {status_id:3, statusName:'Rejected', type: 1},
    {status_id:1, statusName:'Paid', type: 2},
    {status_id:3, statusName:'Partly Paid', type: 2}
  ]
  businessHead: any = { Status: 0, EmployeeReportingPersonId: 1, paymentstatus:0 };
  dialogRef: any;
  tblClm1: any[] = [];
  tblClm2: any[] = [];
  tblClm3: any[] = [];
  tblClm4: any[] = [];
  tblClm5: any[] = [];
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  tableSetting: any = {};
  sortItem = 1;
  search = new Object();
  sideNavData: any;
  searchText: any;
  DataOutput1: any;
  tabledata: any = {};
  public selectedIndexTab = 0;
  APIName: any;
  TableData: any;
  tabNbr = 0;
  public timing;
  sideData: any;
  viewName: any;
  countData: any= {};
  ReimbursementData: any;
  constructor(
    private http: HttpClient,
    private datatableServise: DatatableService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private coreService: ServiceService
  ) {
    this.tableSetting = this.datatableServise.tablePagination();
    this.tblClm1 = TableColumn1;
    this.tblClm2 = TableColumn2;
    this.tblClm3 = TableColumn3;
    this.tblClm4 = TableColumn4;
    this.tblClm5 = TableColumn5;
  }

  ngOnInit(): void {
    
    this.businessHead.StartDate = new Date()
    this.businessHead.EndDate = new Date()
    this.businessHead.StartDate = new Date(this.businessHead.StartDate.getFullYear(), this.businessHead.StartDate.getMonth(),2);
    this.getcountdata()
    this.getList1(0);
  }

  getcountdata(){
    let input = {
      StartDate: this.businessHead.StartDate,
      EndDate: this.businessHead.EndDate
    }
    this.coreService.ExpenseService(input, 'ExpenseCountByStatus')
    .subscribe(response => {
      console.log(response)
      this.countData = response.body.Result;
    })
  }
  getList1(index) {
    let apiName;

    switch (index) {
      case 0:
        apiName = 'Expense/RecurringExpenseList';
        break;
      case 1:
        apiName = 'Expense/TravelExpenseList';
        break;
      case 2:
        apiName = 'Expense/AccommodationExpenseList';
        break;
      case 3:
        apiName = 'Expense/FoodExpenseList';
        break;
      case 4:
        apiName = 'Expense/GeneralExpenseList';
        break;
    }

    this.spinner.show();
    const that = this;
    this.dtOptions = {
      language: { searchPlaceholder: 'Search by keyword' },
      ...this.tableSetting.pagination,
      ajax: (dataTablesParameters: any, callback) => {
        this.timing = setTimeout(() => {
          that.http.post<DataTablesResponse>(
            planworkConfig.baseUrl + planworkConfig.ExpenseBaseUrl + apiName,
            {
              Search: {
                ...dataTablesParameters,
                value: dataTablesParameters.search.value,
                regex: dataTablesParameters.search.regex,
              },
              ...this.businessHead,
            },
            { headers: this.tableSetting.dataTables }
          ).subscribe(resp => {
            this.spinner.hide();
            this.searchText = dataTablesParameters.search.value;
            this.tabledata = dataTablesParameters;
            that.DataOutput1 = resp.Result;
            // that.viewData = resp.ledgerData;
            callback({
              recordsTotal: this.DataOutput1.recordsTotal,
              recordsFiltered: this.DataOutput1.FilteredRecordCount,
              data: []
            });
          }, error => {
            this.spinner.hide();
          });
        }, 900);
      },
      columns: this.tblClm1,
      ...this.tableSetting.domSetting
    };
  }


  getFilterData(event) {
    this.businessHead.Status = 0;
    this.businessHead.paymentstatus = 0;
    if(event.value != 0){
      if (event.value.type == 1) {
        this.businessHead.Status = parseInt(event.value.status_id);
      }else{
        this.businessHead.paymentstatus = parseInt(event.value.status_id);
      }
      
    }
    console.log(this.businessHead.paymentstatus);
    if(!this.businessHead.paymentstatus){
      this.businessHead.paymentstatus =0
    }
    
    this.spinner.show();
    this.getcountdata()
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }
  getSideNavData(id, catid){
    let input = {
      expenseid: id,
      categoryid: catid
    }
    this.coreService.RembersmentService( input , 'ReimbursementTransactionListbyReimbursementId')
    .subscribe(response => {
      console.log(response)
      this.ReimbursementData = response.body.Result;
    })
  }
  tabWiseSave(e) {
    this.businessHead =  { Status: 0, EmployeeReportingPersonId: 1, paymentstatus:0 }
    
    this.businessHead.StartDate = new Date()
    this.businessHead.EndDate = new Date()
 this.businessHead.StartDate = new Date(this.businessHead.StartDate.getFullYear(), this.businessHead.StartDate.getMonth(),2);
    this.getList1(e.index);
    this.tabNbr = e.index;
  }

  getsidebarData(id) {
    this.coreService.ExpenseService({ TravelExpenseId: id }, "TravelExpenseGetById")
      .subscribe((response) => {
        console.log(response);
        this.sideNavData = response.body.Result;
      });
  }

  getViewDetail(data, name) {
    this.sideData = data;
    this.viewName = name;
    console.log(this.sideData);
  }

  ApprovalAction(typeid, id, bool) {
    console.log(typeid);
    console.log(id);
    console.log(bool);
    
    const dialogRef = this.dialog.open(CommonModalComponent, {
      width: "300px",
      disableClose: true,
      autoFocus: false,
      panelClass: "statusClass", 
      data: {
        action: "delete",
        statusText: `Are you sure you want to ${bool ? "approve" : "Reject"}?`,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.coreService.ExpenseService({}, `ApproveRejectExpenseByRP?ExpenseTypeId=${id}&ExpenseId=${typeid}&IsApprove=${bool}`)
          .subscribe((response) => {
            if (response.body.Result.Success) {
              this.getcountdata()
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.draw();
              });
              if(id == 2){
              this.sidenav1.toggle()
              }else{
              this.sidenav2.toggle()
              }
              const dialogRef = this.dialog.open(CommonModalComponent, {
                width: '300px',
                disableClose: true,
                autoFocus: false,
                panelClass: 'statusClass',
                data: { action: 'success', statusText: response.body.Result.Message }
              });
            } else {
              this.getcountdata()
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.draw();
              });
              const dialogRef = this.dialog.open(CommonModalComponent, {
                width: '300px',
                disableClose: true,
                autoFocus: false,
                panelClass: 'statusClass',
                data: { action: 'Alert', statusText: response.body.Result.Message }
              });
            }
          });
      }
    });
  }
   RejectAction(typeid, id, bool) {
    const dialogRef = this.dialog.open(BallparkModalsComponent, {
      width: "300px",
      disableClose: true,
      autoFocus: false,
      panelClass: "statusClass", 
      data: { action: 'rejectExpense', title: 'Reject Remark' },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.coreService.ExpenseService({}, `ApproveRejectExpenseByRP?ExpenseTypeId=${id}&ExpenseId=${typeid}&IsApprove=${bool}&Reason=${result?.Reason}`)
          .subscribe((response) => {
            if (response.body.Result.Success) {
              this.getcountdata()
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.draw();
              });
              if(id == 2){
              this.sidenav1.toggle()
              }else{
              this.sidenav2.toggle()
              }
              const dialogRef = this.dialog.open(CommonModalComponent, {
                width: '300px',
                disableClose: true,
                autoFocus: false,
                panelClass: 'statusClass',
                data: { action: 'success', statusText: response.body.Result.Message }
              });
            } else {
              this.getcountdata()
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.draw();
              });
              const dialogRef = this.dialog.open(CommonModalComponent, {
                width: '300px',
                disableClose: true,
                autoFocus: false,
                panelClass: 'statusClass',
                data: { action: 'Alert', statusText: response.body.Result.Message }
              });
            }
          });
      }
    });
  }

  viewDetail() {
    this.dialogRef = this.dialog.open(BallparkModalsComponent, {
      disableClose: true,
      panelClass: "delidetail-modal",
      data: { action: "locaConDetail" },
    });
  }

  downloadMyFile(data, b) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    if (data?.FileData) {
      link.setAttribute('href', data?.Preview ? data?.Preview : data?.imagePreview);
    } else {
      this.coreService.getDocument({ DocumentId: b }).subscribe(res => {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'data:application/pdf;base64,' + res?.body?.documentData);
        link.setAttribute('download', res?.body?.documentName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
    }
    link.setAttribute('download', b);
    document.body.appendChild(link)
    link.click();
    link.remove();
  }
  downloadMyFileOther(data) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    if (data?.FileData) {
      link.setAttribute('href', data?.Preview ? data?.Preview : data?.imagePreview);
    } else {
      this.coreService.getDocument({ DocumentId: data }).subscribe(res => {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'data:application/pdf;base64,' + res?.body?.documentData);
        link.setAttribute('download', res?.body?.documentName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
    }
    link.setAttribute('download', data.DocumentFileName);
    document.body.appendChild(link)
    link.click();
    link.remove();
  }
}
