import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatatableService } from '@planwork/datatable-settings';
import { DataTableDirective } from 'angular-datatables';
import { CommonModalComponent } from 'app/main/core/common/common-modal/common-modal.component';
import { ServiceService } from 'app/main/core/masterservice/service.service';
import { BallparkModalsComponent } from 'app/main/lead/lead-business-head/ballpark-modals/ballpark-modals.component';
import { planworkConfig } from 'app/planwork.config';
import { NgxSpinnerService } from 'ngx-spinner';
import { TableColumn } from './Table.Column';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  Result: any[];
}
@Component({
  selector: 'app-consumable-requisitions',
  templateUrl: './consumable-requisitions.component.html',
  styleUrls: ['./consumable-requisitions.component.scss']
})
export class ConsumableRequisitionsComponent implements OnInit {
  showSearch: boolean;
  serachText: any;
  dialogRef: any;
  Requisition = [
    { name: 'New' },
    { name: 'Repair & Maintenance' },
    { name: 'Customize' },
    { name: 'Deposit' }
  ]
  tabledata: any = {};
  tblClm: any[] = [];
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  tableSetting: any = {};
  isShow = false;
  theadColumn = [];
  sortItem = 1;
  EmployeeOutput: any
  sidenavData: any={}
  status = [
    { name:'Pending',StatusId:1 },
    { name:'Declined' ,StatusId:3},
    { name:'Approved',StatusId:4}
  ];
  selected:'all';
  currentStatus: any;
  approval: any={
    status: 0
  };
  searchText: any[] = [];
  DataOutput: any;
  ReqGetdata: any;
  selectedItem: any;
  constructor(
    private datatableServise: DatatableService,
    private dialog: MatDialog,
    private masterService: ServiceService,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private router : Router
  ) {
    this.theadColumn = TableColumn;
    this.tableSetting = this.datatableServise.tablePagination();
  }

  ngOnInit(): void {
    this.getList();
  }
 
  changeFilter(){
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });    
  }
  toggleSearch(text) {
    if (text == 'show') {
      this.isShow = true;
    } else {
      this.isShow = false;
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.search('');
        dtInstance.draw();
      });
    }
  }
  // remark(id,stid){
  //   this.dialogRef = this.dialog.open(BallparkModalsComponent, {
  //     disableClose: false,
  //     data: { action: 'remarkrequsotion', title: 'Rework Remark' ,data:{id : id,status:2}, }
  //   });
  // }
  // clientGetById(id) {
  //   this.masterService.reuquisitonService({ RequisitionId: id }, 'MyAssetRequisitionGetById').subscribe(response => {
  //     this.ReqGetdata = response.body.Result;
  //   })
  // }
  getList(): void {
    this.spinner.show();
    const that = this;
    this.dtOptions = {
      language: { searchPlaceholder: 'Search by keyword' },
      ...this.tableSetting.pagination,
      ajax: (dataTablesParameters: any, callback) => {
        that.http.post<DataTablesResponse>(
          planworkConfig.baseUrl + 'Consumable/api/ConsumableRequisitions/ConsumableApprovalList',
          { ...this.approval,
            Search:
            {
              ...dataTablesParameters,
              value: dataTablesParameters.search.value,
              regex: dataTablesParameters.search.regex,
            },

          }, { headers: this.tableSetting.dataTables }
        ).subscribe(resp => {
          that.spinner.hide();
          that.searchText = dataTablesParameters.search.value;
          that.tabledata = dataTablesParameters;
         let res:any = resp.Result;
         that.DataOutput = res.ItemList
          callback({
            recordsTotal: res?.FilteredRecordCount,
            recordsFiltered: res?.FilteredRecordCount,
            data: []
          });
        });
      },
      columns: this.theadColumn,
      ...this.tableSetting.domSetting
    };
  }
  changeStatus(data, type) {
    let dialogRef;
    if(type == 3){
      dialogRef = this.dialog.open(BallparkModalsComponent, {
        width: "300px",
        disableClose: true,
        autoFocus: false,
        panelClass: "statusClass", 
        data: { action: 'rejectExpense', title: 'Decline Remark' },
      });
    }else{
    dialogRef = this.dialog.open(CommonModalComponent, {
      width: '300px',
      disableClose: true,
      autoFocus: false,
      panelClass: 'statusClass',
      data: { action: 'delete', statusText: `Are you sure You want to Approve!` }
    });
  }
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        let input
        if(type == 3){
          input = { ConsumableId:data?.ConsumableId, status: type, Declinejustification: result?.Reason }          
        }else{
          input = { ConsumableId:data?.ConsumableId, status: type }
        }
        this.masterService.ConsumableService(input, 'ConsumableRequisitionsStatusChange').subscribe(response => {
          if (response?.body?.TransactionMessage?.Success) {
            $('#table_lead').DataTable().ajax.reload();
            const dialogRef = this.dialog.open(CommonModalComponent, {
              width: '300px',
              disableClose: true,
              autoFocus: false,
              panelClass: 'statusClass',
              data: { action: 'success', statusText: response?.body?.TransactionMessage?.Message}
            });
          } else {
            const dialogRef = this.dialog.open(CommonModalComponent, {
              width: '300px',
              disableClose: true,
              autoFocus: false,
              panelClass: 'statusClass',
              data: { action: 'Alert', statusText: response?.body?.TransactionMessage?.Message}
            });
          }
        })
      }
    });
  }
}
