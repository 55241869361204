<div class="innerPart">
    <div class="row">
        <div class="col-sm-7">
            <div class="servBox">
                <div class="welcomeDiv">
                    <h1>Hi John Smith</h1>
                    <h2>Welcome to the PlanWork</h2>
                    <h3>What would you like to use/purchase?</h3>
                    <p>Please select the modules which you would like to use/purchase.</p>
                </div>
                <ul class="pikServItem">
                    <li>
                        <mat-checkbox>
                            <img src="assets/icons/planIcon01.svg" alt="Services">
                        </mat-checkbox>
                        <span class="servName">Campaign</span>
                    </li>
                    <li>
                        <mat-checkbox>
                            <img src="assets/icons/planIcon02.svg" alt="Services">
                        </mat-checkbox>
                        <span class="servName">Lead</span>
                    </li>
                    <li>
                        <mat-checkbox>
                            <img src="assets/icons/planIcon03.svg" alt="Services">
                        </mat-checkbox>
                        <span class="servName">Project</span>
                    </li>
                    <li>
                        <mat-checkbox>
                            <img src="assets/icons/planIcon04.svg" alt="Services">
                        </mat-checkbox>
                        <span class="servName">Service</span>
                    </li>
                    <li>
                        <mat-checkbox>
                            <img src="assets/icons/planIcon05.svg" alt="Services">
                        </mat-checkbox>
                        <span class="servName">Task</span>
                    </li>
                    <li>
                        <mat-checkbox>
                            <img src="assets/icons/planIcon06.svg" alt="Services">
                        </mat-checkbox>
                        <span class="servName">Billing</span>
                    </li>
                    <li>
                        <mat-checkbox>
                            <img src="assets/icons/planIcon07.svg" alt="Services">
                        </mat-checkbox>
                        <span class="servName">QA</span>
                    </li>
                    <li>
                        <mat-checkbox>
                            <img src="assets/icons/planIcon08.svg" alt="Services">
                        </mat-checkbox>
                        <span class="servName">Vendor</span>
                    </li>
                    <li>
                        <mat-checkbox>
                            <img src="assets/icons/planIcon05.svg" alt="Services">
                        </mat-checkbox>
                        <span class="servName">Asset</span>
                    </li>
                    <li>
                        <mat-checkbox>
                            <img src="assets/icons/planIcon05.svg" alt="Services">
                        </mat-checkbox>
                        <span class="servName">Consumable</span>
                    </li>
                    <li>
                        <mat-checkbox>
                            <img src="assets/icons/planIcon05.svg" alt="Services">
                        </mat-checkbox>
                        <span class="servName">Reimbursements</span>
                    </li>
                    <li>
                        <mat-checkbox>
                            <img src="assets/icons/planIcon05.svg" alt="Services">
                        </mat-checkbox>
                        <span class="servName">Purchase & Procurement</span>
                    </li>
                    <li>
                        <mat-checkbox>
                            <img src="assets/icons/planIcon05.svg" alt="Services">
                        </mat-checkbox>
                        <span class="servName">Payments</span>
                    </li>
                    <li>
                        <mat-checkbox>
                            <img src="assets/icons/planIcon05.svg" alt="Services">
                        </mat-checkbox>
                        <span class="servName">Utility Bills</span>
                    </li>
                    <li>
                        <mat-checkbox>
                            <img src="assets/icons/planIcon05.svg" alt="Services">
                        </mat-checkbox>
                        <span class="servName">Gate Pass</span>
                    </li>
                </ul>
                <div class="submitBtn">
                  <button mat-flat-button mat-primary color="primary">Submit</button>
                </div>
            </div>
        </div>
        <div class="col-sm-5 text-center">
            <img src="assets/images/rocket.svg" alt="rocket">
        </div>
    </div>
</div>