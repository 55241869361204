import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalpopupComponent } from 'app/main/core/common/modalpopup/modalpopup.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit() { }

  downloadslip() {
    let dialogRef = this.dialog.open(ModalpopupComponent, {
      width: "500px",
      autoFocus: false,
      disableClose: true,
      data: { modalTitle: 'Pay Slip', action: 'SlipDownload' },
    });
    dialogRef.afterClosed().subscribe(async (response) => {

    });
  }
}
