import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { CampaignService } from 'app/main/campaign/campaign.service';
import { Observable, observable, Observer, Subject } from 'rxjs';
import { ServiceService } from '../../masterservice/service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { CommonModalComponent } from '../common-modal/common-modal.component';
import { PlanworkConfigService } from '@planwork/services/config.service';
import { NgModel } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DropdownService } from '@planwork/services/dropdown.service';
import { FileUploadModel } from 'app/main/lead/campaign-enquiries/add-prospect/add-prospect.component';
import { ModalComponent } from 'app/main/services/service-planning/modal/modal.component';
import { MyActivityService } from 'app/main/my-activity/my-activity.service';
import { Location } from '@angular/common';
import { promise } from 'protractor';
import { ClientipService } from '@planwork/services/clientip.service';
import { ApiService } from '@planwork/services/api.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { AddContactModalComponent } from '../add-contact-modal/add-contact-modal.component';
import * as moment from 'moment';
export interface scopelist {
  name: string;
}
export interface freqscopelist {
  name: string;
}
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};
@Component({
  selector: 'app-modalpopup',
  templateUrl: './modalpopup.component.html',
  styleUrls: ['./modalpopup.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ],
})
export class ModalpopupComponent implements OnInit, OnDestroy {
  ExpenseType = [{ key: 'Domestic', value: true }, { key: 'International', value: false }];
  allDays = [{ key: 'Daily', value: 1 }, { key: 'Weekly', value: 2 }, { key: 'Monthly', value: 3 }];
  travelRadio = [{ key: 'Intercity', value: 1 }, { key: 'Local', value: 2 }];
  BookingMode = [{ key: 'Online', value: 1 }, { key: 'Offline', value: 2 }];
  AdvanceType = [{ key: 'Employee', value: true }, { key: 'Vendor', value: false }];
  desposeReason = [{ key: 'Sold', value: 1 }, { key: 'Stolen', value: 2 }, { key: 'Lost', value: 3 }, { key: 'Scrap', value: 4 }];
  allDay = 'Daily';
  allTravel = 'Intercity';
  allAcc = 'Hotel';
  currencyCost = '1';
  ClientSearch = ''
  currencyListSearch = ''
  CurrencyDropDown: any;
  AssetClassDropDown: any
  assetitem: any = {}
  negotiateModal: any = {};
  AssetCategoryList: any
  AssetItemDroplist: any
  saveAdvanceData: any = {
    IsEmployee: true
  };
  pettycash: any = {Attachment:{}}
  savePaymentInput: any = {};
  CurrencySearch1 = '';
  attributelistarr: any = [{

  }];
  @ViewChild('myFileInput') myFileInput;
  dialogRefss: any
  dialogRefP: any
  SearchBank = ''
  searchCurrency = ''
  CustomerSegmentSearch = ''
  attribute = ''
  tree1 = ''
  tree2 = ''
  tree3 = ''
  tree4 = ''
  tree5 = ''
  tree6 = ''
  tree7 = ''
  tree8 = ''
  tree9 = ''
  tree10 = ''
  tree11 = ''
  tree12 = ''
  tree13 = ''
  tree14 = ''
  tree15 = ''
  tree16 = ''
  tree17 = ''
  tree18 = ''
  tree19 = ''
  tree20 = ''
  tree21 = ''
  tree22 = ''
  tree23 = ''
  tree24 = ''
  tree25 = ''
  tree26 = ''
  tree27 = ''
  tree28 = ''
  tree29 = ''
  tree30 = ''
  tree31 = ''
  tree32 = ''
  tree33 = ''
  tree34 = ''
  tree35 = ''
  tree36 = ''
  tree37 = ''
  DocumentCategory1 = ''
  seExitEmp: any;
  KeySkillSearch = '';
  campainempDepartment: any = {};
  costCenterList: any = {};
  RefundCredit = {
    Amount: 0
  }
  selectedUser: any[] = [];
  EmployeeListdata: any;
  // dialogRef: any
  BankDetail: any = { QRCode: {} };
  confirmDialogRef: MatDialogRef<CommonModalComponent>;
  // lavelList: any[] = [];
  userIds: any[] = [];
  employeeCategorys: any = [
    { EmployeeCategoryId: 1, EmployeeCategoryName: 'Employee', EmployeeCategoryCode: 'EMP' },
    { EmployeeCategoryId: 2, EmployeeCategoryName: 'Intern', EmployeeCategoryCode: 'INT' },
    { EmployeeCategoryId: 3, EmployeeCategoryName: 'Trainee', EmployeeCategoryCode: 'TRN' },
    { EmployeeCategoryId: 4, EmployeeCategoryName: 'External', EmployeeCategoryCode: 'EXT' },
  ]
  EntitlementCodes = '';
  searchText;
  campainDepartment: any[] = [];
  campainEmployee: any;
  exEmployeeList: any[] = [];
  CountrySearch = '';
  Message = '';
  StateSearch = '';
  searchType = '';
  searchSubcategory = '';
  CitySearch = '';
  modulename = 'Plan Work';
  isHoliday: any;
  modalTitle: any;
  GenericTypeDropDownListNew: any;
  action: any;
  type: any = false;
  id: any;
  departmentList: any = {};
  minDate = new Date();
  isWorkingSchedule: any;
  //SkillCategory: any;
  CountryList: any;
  StateList: any;
  weekDays = [{ day: 'M', Days: 'Monday' }, { day: 'T', Days: 'Tuesday' }, { day: 'W', Days: 'Wednesday' }, { day: 'T', Days: 'Thursday' },
  { day: 'F', Days: 'Friday' }, { day: 'S', Days: 'Saturday' }, { day: 'S', Days: 'Sunday' }];
  holiday: any = {
    RepeatEntry: {
      WeekFequency: [],
      Days: []
    },
    WorkingType: 'one time'
  };
  scheduleoff: any = {
    WorkingType: 'one time',
    // ScheduleOffType:{
    //   ScheduleOffTypeId:''
    // },
    RepeatEntry: {
      WeekFequency: [],
      Days: []
    }
  };
  saveDepartmentInput: any = {
    // Employee:{},
    // DepartmentCategory:{}
  };
  saveShiftInput: any = {};

  saveProfitCenterDetailsInput: any = {
    ProfitCenter: {
      ProfitCenterId: 8,
      ProfitCenterName: '',
      ProfitCenterCode: '547'
    },
    Currency: {
      CurrencyId: 1,
      CurrencyName: 'IN [Rs.]',
      CurrencySymbol: null
    },
    Employee: {
      EmployeeId: 1247,
      EmployeeName: '',
      EmployeeCode: null,
      Designation: ''
    },
    AreaLocation: '',
    Description: '',
    IsApproved: '1'
  };
  SaveEmployeeType: any = {
    EmployeeOfficialDetail: {

    },
    UpdatedBy: {}
  };
  saveStatusInput: any = {

    StatusName: "",
    StatusCode: "DEMO25",
    Description: "Demo DESCRIPTION",
    BusinessProcess: {
      BusinessProcessId: 7,
      BusinessProcessName: ""
    },
    IsApproved: "1"

  }

  saveRemarkInput: any = {};

  saveBusinessInput: any = {
    BusinessProcessName: "",
    BusinessProcessCode: "11",
    IsLead: true,
    IsApproved: "1"
  }

  saveTaxInput: any = {
    IsInterState: true,
    Description: "DEMO Description",
    TaxCode: "254",
    TaxPercent: '',
    TaxName: "",
    IsApproved: "1"
  }
  saveAssestItemInput: any = {
    AssetItemCode: "",
    AssetItemName: "",
    IsApproved: "1"

  }

  //Campaign Group start

  saveCampaignGroupInput: any = {
    CampaignGroupName: ''
  }
  skillCategoryInput: any = {
    SkillCategoryName: '',
    SkillCategoryCode: '',
    IsApproved: "true",
    Department: {
      DepartmentId: '',
      DepartmentName: '',
    },
    LevelList: [],
    AmountType: 'INR',
  };
  saveCityInput: any = {};
  saveComplinceInput: any = {};
  saveUserRoleInput: any = {
    CompanyId: '',
    UserRoleName: '',
    UserRoleCode: '',
    IsApproved: "true",
  };
  CityAreaInput = {
    CityAreaCode: '',
    CityAreaName: '',
    Country: {
      CountryId: '',
      CountryName: '',
      CountryCode: '',
    },
    State: {
      StateId: '',
      StateName: '',
      StateCode: '',
    },
    City: {
      CityId: '',
      CityName: '',
    },
    IsApproved: true,
  };
  attributedata: any = {}
  NumberSystemINput = {
    NumberSystemName: '',
    NumberSystemCode: '',
    IsApproved: 1
  };
  NumberSystemINputupdate = {
    NumberSystemName: '',
    NumberSystemCode: '',
    NumberSystemId: '',
    IsApproved: 1
  };
  GenericTypeSaveInput = {
    GenericCategory: {
      GenericCategoryId: '',
      GenericCategoryName: ''
    },
    GenericTypeName: '',
    GenericTypeCode: '',
    IsApproved: ''
  };
  selectCountry: any;
  CityList: any;
  ProjectActivityDropDownList: any;
  ServiceActivityDropDownList: any;
  ReasonBusinessProcess: any = [];
  SBHDropDownLists: any = []
  selected: any = '0';
  modalData: any;
  baseUrlType: any;
  unsubscribe$ = new Subject();
  saveMessage: any;
  SaveIndustryType: any = {};
  SaveQualificationType: any = {
    QualificationTypeName: '',
    QualificationTypeCode: 250,
    IsApproved: 1
  };
  saveQualificationData: any = {}
  savePaymentModeData: any = {}
  serviceTasksave: any = {

  }
  saveProjectActivityInput: any = {
    ProjectActivityName: '',
    ProjectActivityCode: '',
    // Category: {
    //   CategoryId: '',
    //   CategoryName: '',
    //   CategoryCode: ''
    // },
    IsApproved: 1
  }
  saveServiceActivityInput: any = {
    ServiceActivityName: '',
    ServiceActivityCode: '',
    // Category: {
    //   CategoryId: '',
    //   CategoryName: '',
    //   CategoryCode: ''
    // },
    IsApproved: 'true',
    Category: {},
  }
  SaveCustmerSegment: any = {
    CustomerSegmentName: '',
    CustomerSegmentCode: '224',
    IsApproved: "true"
  };
  SaveReferenceMode: any = {
    ReferenceModeName: '',
    ReferenceModeCode: '124',
    IsApproved: "true"
  };
  SaveOrganition: any = {
    OrganisationTypeName: '',
    IsApproved: "true"
  };
  saveProjectCategoryList: any = {
    ProjectCategoryName: '',
    ProjectCategoryCode: 32,
    IsApproved: 1
  };
  saveWorkingSchedule: any =
    {
      WorkingType: 'one time',
      BreakList: [{
        FromTime: '',
        ToTime: ''
      }],
      RepeatEntry: {
        WeekFequency: [],
        Days: []
      },
      RendomEntry: {
        RendomDate: ['']
      },
    };
  workingList: any[] = [];
  fileSizeError: any;
  SaveProjectType: any = {
    // ProjectCategory: {},
    ProjectTypeName: '',
    ProjectTypeCode: '',
    SACCode: '',
    IsApproved: 1
  };
  saveHolidayInput: any = {
    RepeatEntry: {
      WeekFequency: [],
      Days: []
    },
    WorkingType: 'one time',
    WorkingShift: {}
  };
  SaveKeyFeature: any = {
    KeyFeatureName: '',
    KeyFeatureCode: '',
    IsApproved: 1
  }
  ServiceKeyFeatureSave: any = {
    IsApproved: 'true'
  }
  ProjectKeyFeatureSave: any = {
    IsApproved: 1
  }
  inputPaymentType: any = {}
  pettycashamount: any;
  paymenttype: any = [
    // { key: 1, name: "Cash" },
    { key: 2, name: "Cheque" },
    { key: 3, name: "Bank Transfer" },
    { key: 4, name: "UPI" },
    { key: 5, name: "Petty Cash" }
  ]
  saveServiceSubActivityInput: any = {
    ServiceSubActivityName: '',
    ServiceSubActivityCode: '',
    ServiceActivity: {},
    Category: {},
    IsApproved: 'true'
  }
  saveProjectSubActivityInput: any = {
    ProjectSubActivityName: '',
    ProjectSubActivityCode: '',
    ProjectActivity: {},
    Category: {},
    IsApproved: 1
  }
  ProjectCatActivityDropDownList: any;
  ServiceCatActivityDropDownList: any;
  saveWeeklyOffInput: any = {
    // WorkingType: '',
    Type: {},
    RepeatEntry: {
      WeekFequency: [],
      Days: [
        '',
      ]
    },
    WorkingShift: {},
    StartDate: '',
    EndDate: ''
  };
  saveServiceCategoryInput: any = {
    ServiceCategoryName: '',
    IsApproved: "true",
    CostCenterCategory: {
      StrategicBusinessHead: {}
    }
  };
  saveServiceCategoryTypeInput: any = {
    ServiceCategory: {
    },
    ServiceTypeName: '',
    ServiceTypeCode: '45454',
    SACCode: '',
    IsAudit: false,
    IsApproved: 'true',
    AdditionalChargeDigit: 0,
    DiscountDigit: 0,
  };
  saveServiceTaskInput: any = {
    IsApproved: 'true',
    ServiceTaskName: "",
    ServiceTaskCode: "",
    ServiceSubActivity: {},
    Category: {},
    ServiceActivity: {}
  };
  saveProjectTaskInput: any = {
    IsApproved: 'true',
    ProjectTaskName: "",
    ProjectTaskCode: "",
    ProjectSubActivity: {},
    Category: {},
    ProjectActivity: {}
  };
  CostCenterCategorydropdownList: any = [];
  saveGenericCategoryTypeInput: any = {
    GenericCategory: {
      GenericCategoryId: 47,
      GenericCategoryName: ''
    },
    GenericTypeName: '',
    GenericTypeCode: '',
    IsApproved: 'true'
  };
  saveUserroleList: any = {
    UserRoleName: '',
    // UserRoleCode: '',
    IsApproved: "true"
  };
  saveCurrencyInput: any = {};
  saveRecurringData: any = { billdocument: {}, IsDomestic: true };
  saveExpenseData: any = { billdocument: {}, IsDomestic: true };
  saveFoodBeverageData: any = { billdocument: {}, IsDomestic: true };
  saveGeneralExData: any = { billdocument: {}, IsDomestic: true };
  saveAccommodationExpense: any = { billdocument: {}, IsDomestic: true };
  saveModeOfPOInput: any = {};
  saveBankInput: any = {};
  saveYearInput: any = {};
  saveRegInput: any = {
    ResignationReasonName: '',
    IsApproved: "true",
  };
  // this.otheractInput.otherActivity
  otheractInput: any = {
    OtherActivityName: '',

  };
  saveTerInput: any = {
    TerminationReasonName: '',
    IsApproved: "true"
  };
  saveOccupationInput: any = {
    OccupationName: '',
    OccupationCode: 'B02',
    IsApproved: "true"
  };
  saveBankAcTypInput: any = {};

  saveDocumentCategoryInput: any = {}
  saveDocumentTypInput: any = {};
  saveUnitsInput: any = {

    IsApproved: "true"
  };
  saveEstimationBufferInput: any = {};
  saveBufferPercentageInput: any = {};
  saveLabelInput: any = {
    BusinessProcess: {
      BusinessProcessId: 2,
      BusinessProcessName: ''
    },
    LabelName: '',
    LabelCode: '',
    Color: '',
  };
  saveReasonInput: any = {};
  saveCountryInput: any = {};
  saveAssetInput: any = {};
  saveConsumableCatInput: any = {};
  DocPrintCategorie: any = {};
  saveAssetAttributeInput: any = {
    IsAssignable: false,
  };
  docPrintTypeSave: any = {}
  employeeFeedBackSave: any = { FeedbackAttachment: {} };
  SaveEmployeeEntitlementInput: any = {}
  saveConsumableInput: any = {};
  saveConveyanceInput: any = {};
  saveExpenseType: any = {};
  saveAccommodationTypeInput: any = {};
  ConveyanceMode: any = [
    { mode: 'Private', value: 1 },
    { mode: 'Public', value: 2 },
    { mode: 'Both', value: 3 },
  ]
  saveAssetItemInput: any = {
    MaintenanceFrequency: [{}],
    ConfigurationAuditFrequency: { frequencytype: 0 },
    HealthCheckAuditFrequency: { frequencytype: 0 },
    CountingAuditFrequency: { frequencytype: 0 }
  };
  saveExpenseEntitlementInput: any = {
    monthlylLmitEntitlementModels: [{}],
  };
  deleteMonthlyEntitlementList: any = []
  saveStateInput: any = {
    IsApproved: 'true',
    Country: {
      CountryId: 91,
      CountryName: 'India',
      CountryCode: '+91',
      MobileDigit: 10,
      iso2: "IN",
      iso3: "IND"
    }
  };
  saveProfitCenterInput: any = {};
  SaveCategory: any = {
    CategoryName: '',
    IsApproved: 1,
    CategoryCode: '25'
  };
  SaveUnit: any = {
    Value: '',
    DurationUnitName: '',
    FrequencyName: '',
    IsApproved: 1
  };
  SaveCostCenter: any = {
    AreaLocation: '',
    Description: '',
    CostCenterTypeId: 5,
    CostCenterName: {
      CostCenterNameId: 2,
      CostCenterMasterName: ''
    },
    Currency: {
      CurrencyId: 1,
      CurrencyName: 'IN [Rs.]',
      CurrencySymbol: null
    },
    CostCenterType: {
      CostCenterTypeId: 3,
      CostCenterTypeName: ''
    },
    IsApproved: 1
  };

  saveProfitCenterDetailsInputs: any = {
    ProfitCenter: {
      ProfitCenterId: 8,
      ProfitCenterName: '',
      ProfitCenterCode: '547'
    },
    IsApproved: 1,
    Currency: {
      CurrencyId: 1,
      CurrencyName: 'IN [Rs.]',
      CurrencySymbol: null
    },
    Employee: {
      EmployeeId: 1247,
      EmployeeName: '',
      EmployeeCode: null,
      Designation: ''
    },
    AreaLocation: '',
    Description: ''
  };

  saveGenericCategoryInputs: any = {
    GenericCategoryName: '',
    GenericCategoryCode: '523',
    IsApproved: 1
  };
  saveCostCenterCategorySave: any = {
    CostCenterCategoryName: '',
    StrategicBusinessHead: {},
    IsApproved: 1
  };

  saveClientInputs: any = {
    Status: '1',
    ClientName: '',
    ClientCodeOld: '',
    ClientCode: '',
    ClientCountry: {
      CountryId: 2,
      CountryName: 'Albania',
      CountryCode: '+355',
      iso2: '',
      iso3: ''
    },
    RegisteredAddress: {
      CorresAddress: {
        ContactDetailTitle: '',
        ContactDetailName: '',
        Address: 'czcxzc',
        Country: {
          CountryId: 6,
          CountryName: 'Angola',
          CountryCode: '+244',
          iso2: '',
          iso3: ''
        },
        State: {
          StateId: 108,
          StateName: 'Huambo',
          StateCode: '',
          Country: {
            CountryId: 6,
            CountryName: 'Angola',
            CountryCode: '+244',
            iso2: '',
            iso3: ''
          },
          iso2: '',
          iso3: '',
          GSTStateCode: ''
        },
        City: {
          CityId: 148,
          CityName: 'Huambo',
          CityCode: '',
          Country: {
            CountryId: 6,
            CountryName: 'Angola',
            CountryCode: '+244',
            iso2: '',
            iso3: ''
          },
          State: {
            StateId: 108,
            StateName: 'Huambo',
            StateCode: '',
            Country: {
              CountryId: 6,
              CountryName: 'Angola',
              CountryCode: '+244',
              iso2: '',
              iso3: ''
            },
            iso2: '',
            iso3: '',
            GSTStateCode: ''
          },
          iso2: '',
          iso3: '',
          Latitude: '',
          Longitude: ''
        },
        ZipCode: '34324',
        Department: {
          DepartmentId: 1,
          DepartmentName: '',
          DepartmentCode: '',
          DepartmentCategory: {
            TypeId: 1,
            Type: ''
          },
          Employee: {
            EmployeeId: 1,
            EmployeeName: '',
            EmployeeCode: '',
            Designation: '',
            Department: ''
          }
        },
        PrimaryEmailId: 'xcxc@mgail.com',
        SecondaryEmailId: 'cxc@mgail.com',
        PhoneNumber: {
          STDCode: '12',
          PhoneNo: '12121'
        },
        MobileNumber: {
          ISDCode: '1212',
          MobileNo: '2121212'
        },
        Website: 'sas',
        AccountManager: {
          EmployeeId: 1031,
          EmployeeName: 'Abhishek  Tyagi',
          EmployeeCode: 'EMP03/18/01775',
          Designation: 'Support Engineer'
        }
      },
      ContactDetailList: [
        {
          ContactPerson: {
            Title: {
              TitleId: 2,
              TitleName: 'Mrs'
            },
            ContactPersonName: 'xzX'
          },
          Department: {
            DepartmentId: 1,
            DepartmentName: '',
            DepartmentCode: '',
            DepartmentCategory: {
              TypeId: 1,
              Type: ''
            },
            Employee: {
              EmployeeId: 1,
              EmployeeName: '',
              EmployeeCode: '',
              Designation: '',
              Department: ''
            }
          },
          Designation: {
            DesignationId: 231,
            DesignationName: 'HR',
            DesignationCode: '',
            DesignationCategory: {
              TypeId: '',
              Type: ''
            }
          },
          EmailId: 'zx@mgail.com',
          MobileNumber: {
            ISDCode: 12,
            MobileNo: 2121212
          },
          Status: '1',
          ContactPersonId: 1
        }
      ]
    },
    RegistrationNo: '34324234',
    RegistrationFileName: '',
    RegistrationPath: '',
    DateOfIncorporation: '2020-09-22T18:30:00.000Z',
    AccountManager: {
      EmployeeId: 1031,
      EmployeeName: 'Abhishek  Tyagi',
      EmployeeCode: 'EMP03/18/01775',
      Designation: 'Support Engineer'
    },
    IndustryType: {
      IndustryTypeId: 9,
      IndustryTypeName: 'Demo Industary',
      IndustryTypeCode: ''
    },
    OrganisationType: {
      OrganisationTypeId: 25,
      OrganisationTypeName: 'Government',
      OrganisationTypeCode: ''
    },
    CustomerSegment: {
      CustomerSegmentId: 3,
      CustomerSegmentName: 'VTPL Private Customer',
      CustomerSegmentCode: ''
    },
    ReferenceMode: {
      ReferenceModeId: 11,
      ReferenceModeName: 'Reference VTPL2 Mode',
      ReferenceModeCode: ''
    },
    ClientSummary: '',
    ReferredBy: {
      Title: {
        TitleId: 1,
        TitleName: 'Mr'
      },
      ReferredByName: 'cxzcxzc'
    },
    DocumentList: [
      {
        DocumentFileName: '',
        DocumentPath: '',
        DocumentDate: '2020-09-23T11:43:33.607Z',
        DocumentType: {
          DocumentTypeId: 6,
          DocumentTypeName: 'Audit Report',
          DocumentTypeCode: '',
          BusinessProcess: {
            BusinessProcessId: 1,
            BusinessProcessName: '',
            BusinessProcessCode: '',
            IsLead: false
          }
        },
        Content: '',
        DocumentReferenceNo: 'cxcxzc',
        DocumentId: 1,
        Status: '1',
        RelationTo: {
          RelationId: 2,
          RelationName: 'DOCUMENTS'
        }
      }
    ],
    BillingDetailList: [
      {
        Status: '1',
        IsSameCorresAddress: true,
        IsTaxApplicable: true,
        CorresAddress: {
          ContactDetailTitle: '',
          ContactDetailName: '',
          Address: 'czcxzc',
          Country: {
            CountryId: 6,
            CountryName: 'Angola',
            CountryCode: '+244',
            iso2: '',
            iso3: ''
          },
          State: {
            StateId: 108,
            StateName: 'Huambo',
            StateCode: '',
            Country: {
              CountryId: 6,
              CountryName: 'Angola',
              CountryCode: '+244',
              iso2: '',
              iso3: ''
            },
            iso2: '',
            iso3: '',
            GSTStateCode: ''
          },
          City: {
            CityId: 148,
            CityName: 'Huambo',
            CityCode: '',
            Country: {
              CountryId: 6,
              CountryName: 'Angola',
              CountryCode: '+244',
              iso2: '',
              iso3: ''
            },
            State: {
              StateId: 108,
              StateName: 'Huambo',
              StateCode: '',
              Country: {
                CountryId: 6,
                CountryName: 'Angola',
                CountryCode: '+244',
                iso2: '',
                iso3: ''
              },
              iso2: '',
              iso3: '',
              GSTStateCode: ''
            },
            iso2: '',
            iso3: '',
            Latitude: '',
            Longitude: ''
          },
          ZipCode: '34324',
          Department: {
            DepartmentId: 1,
            DepartmentName: '',
            DepartmentCode: '',
            DepartmentCategory: {
              TypeId: 1,
              Type: ''
            },
            Employee: {
              EmployeeId: 1,
              EmployeeName: '',
              EmployeeCode: '',
              Designation: '',
              Department: ''
            }
          },
          PrimaryEmailId: 'xcxc@mgail.com',
          SecondaryEmailId: 'cxc@mgail.com',
          PhoneNumber: {
            STDCode: '12',
            PhoneNo: '12121'
          },
          MobileNumber: {
            ISDCode: '1212',
            MobileNo: '2121212'
          },
          Website: 'sas',
          AccountManager: {
            EmployeeId: 1031,
            EmployeeName: 'Abhishek  Tyagi',
            EmployeeCode: 'EMP03/18/01775',
            Designation: 'Support Engineer'
          }
        },
        BillingAddress: {
          ContactDetailTitle: '',
          ContactDetailName: '',
          Address: 'czcxzc',
          Country: {
            CountryId: 6,
            CountryName: 'Angola',
            CountryCode: '+244',
            iso2: '',
            iso3: ''
          },
          State: {
            StateId: 108,
            StateName: 'Huambo',
            StateCode: '',
            Country: {
              CountryId: 6,
              CountryName: 'Angola',
              CountryCode: '+244',
              iso2: '',
              iso3: ''
            },
            iso2: '',
            iso3: '',
            GSTStateCode: ''
          },
          City: {
            CityId: 148,
            CityName: 'Huambo',
            CityCode: '',
            Country: {
              CountryId: 6,
              CountryName: 'Angola',
              CountryCode: '+244',
              iso2: '',
              iso3: ''
            },
            State: {
              StateId: 108,
              StateName: 'Huambo',
              StateCode: '',
              Country: {
                CountryId: 6,
                CountryName: 'Angola',
                CountryCode: '+244',
                iso2: '',
                iso3: ''
              },
              iso2: '',
              iso3: '',
              GSTStateCode: ''
            },
            iso2: '',
            iso3: '',
            Latitude: '',
            Longitude: ''
          },
          ZipCode: '34324',
          Department: {
            DepartmentId: 1,
            DepartmentName: '',
            DepartmentCode: '',
            DepartmentCategory: {
              TypeId: 1,
              Type: ''
            },
            Employee: {
              EmployeeId: 1,
              EmployeeName: '',
              EmployeeCode: '',
              Designation: '',
              Department: ''
            }
          },
          PrimaryEmailId: 'xcxc@mgail.com',
          SecondaryEmailId: 'cxc@mgail.com',
          PhoneNumber: {
            STDCode: '12',
            PhoneNo: '12121'
          },
          MobileNumber: {
            ISDCode: '1212',
            MobileNo: '2121212'
          },
          Website: 'sas',
          AccountManager: {
            EmployeeId: 1031,
            EmployeeName: 'Abhishek  Tyagi',
            EmployeeCode: 'EMP03/18/01775',
            Designation: 'Support Engineer'
          }
        },
        ContactDetailList: [
          {
            ContactPerson: {
              Title: {
                TitleId: 2,
                TitleName: 'Mrs'
              },
              ContactPersonName: 'xzX'
            },
            Department: {
              DepartmentId: 1,
              DepartmentName: '',
              DepartmentCode: '',
              DepartmentCategory: {
                TypeId: 1,
                Type: ''
              },
              Employee: {
                EmployeeId: 1,
                EmployeeName: '',
                EmployeeCode: '',
                Designation: '',
                Department: ''
              }
            },
            Designation: {
              DesignationId: 231,
              DesignationName: 'HR',
              DesignationCode: '',
              DesignationCategory: {
                TypeId: '',
                Type: ''
              }
            },
            EmailId: 'zx@mgail.com',
            MobileNumber: {
              ISDCode: 12,
              MobileNo: 2121212
            },
            Status: '1',
            ContactPersonId: 1
          }
        ],
        TaxationDetailList: [
          {
            TaxType: {
              TaxTypeId: 1,
              TaxTypeName: 'GST',
              TaxCode: '',
              IsInterState: true,
              Description: 'Nice',
              TaxPercent: 20.2
            },
            RegistrationNo: 'cxzcx',
            CodeName: 'cx',
            StateCode: '08',
            TaxationFileName: '',
            TaxationPath: '',
            Status: '1',
            TaxationId: 1
          }
        ],
        BillingId: 1
      }
    ],
    ClientPotential: {
      Currency: {
        CurrencyId: 1,
        CurrencyName: '',
        CurrencyCode: '',
        CurrencySymbol: ''
      },
      ClientPotentialAmount: 1
    },
    TradeName: 'cxzcxzcxz',
    IsInvoiceAfterPayment: false
  };
  saveEmployeeCategoryInputs: any = {
    EmployeeCategoryName: '',
    EmployeeCategoryCode: '454',
    IsApproved: 'true',
    Status: '1'
  };
  // campaign source data input parameter
  SourceDataSave: any = {
    SourceDataName: '',
  };
  sourceDataNameList: any[] = [];
  campaignTypeDataSave: any = {
    CampaignTypeName: ''
  };
  saveNationalityInput: any = {
    NationalityName: '',
    NationalityCode: 123,
  };
  saveDesignationInput: any = {};
  SkillCategorylist: any[] = [];
  SkillSetInput: any = {
    KeySkillName: '',
    KeySkillCode: '',
    /* SkillCategory: {
      SkillCategoryId: 1,
      SkillCategoryName: ''
    }, */
    IsApproved: 'true'
  }
  saveLevelInput: any = {
    LevelCode: '',
    Weightage: ''
  };
  saveCadreInput: any = {
    CadreName: '',
    CadreCode: '2',
    IsApproved: 'true'
  };
  saveBgInfoInput: any = {
    IsApproved: "true"
  };
  designationList: any[] = [];
  productTypeInput: any = {
    IsApproved: 1,
    ProductTypeName: '',
    ProductTypeCode: ''
  };
  productListCategory: any[] = [];
  productCategoryInput: any = {
    IsApproved: 1,
    ProductCategoryName: '',
    ProductCategoryCode: 4545,
  }
  financialYearInput: any = {}
  taxTypeInput: any = {}
  hsnInput: any = {}
  generalTermInput: any = {
    GeneralTermName: '',
    IsApproved: '1'
  }

  invoicePaymentTermInput: any = {
    InvoicePaymentTermName: '',
    IsApproved: '1'
  }


  PaymentTermsInput: any = {}

  costCenterInput: any = {};

  costCenterType: any = {};
  listOfDepartment: any;
  // end campaign data
  //saveUserRoleInput: any = {};
  costCenterNameList: any[] = [];
  costCenteCategoryList: any[] = [];
  costcenterEmployee: any[] = [];
  costCenterTypeNameLis: any[] = [];
  genericList: any[] = [];
  serviceList: any[] = [];
  HsnDropList: any[] = [];
  projectList: any[] = [];
  campaignList: any[] = [];
  shiftList: any[] = [];
  holidayShiftList: any[] = [];
  employeeRecordList;
  employeeRecordListProfit;
  genericCategorysList: any[] = [];
  profitEmpList: any[] = [];
  profitNameList: any[] = [];
  @ViewChild('myForm', { static: false }) form: any;
  @ViewChild('skillSelect', { static: false }) skillSelectField: any;
  ServiceDropDownList: any;
  ServiceTaskCategoryDropDownList: any;
  ProjectTaskCategoryDropDownList: any;
  obj: any;
  ServiceActivityList: any;
  saveCatId: any;
  ServiceSubActivityList: any;
  ProjectSubActivityList: any;
  response: any;
  ProjectCatActivityHeadDropDownList: any;
  ProjectActivityList: any;
  dialogRefModal: any;
  AdditionalDiscount = 0;
  CharegesDiscount = 0;
  durationListData: any[] = [];
  countrySearch = '';
  stateSearch = '';
  timeFormate: any[] = [];
  BusinessProcessDropList: any[] = [];
  selectedUserInfo: any;
  mapResEmployee: any[] = [];
  modelDeliver: any = {
    AttachFiles: [],
    Risks: [{}]
  };
  imgUploadJson: any = {};
  delRisk: any;
  @ViewChild('rf', { static: false }) riskForm: any;
  isdisabled = true;
  copyText = 'Copy';
  DocumentCategoryDD: any;
  leavDataShow: any;
  leaveId: any;
  SaveClient: any = {};
  selectedIndex = 0;
  istrue = false
  monthDropDown: any[]
  ExtendMonth: any
  dialogre: any
  EndDate: any;
  baseGlobalConfig = 'GlobalCore/api/Configuration/';
  basecomplincesConfig = 'Compliances/api/Compliances/';
  baseCompanyCore = 'CompanyCore/api/CompanyDetail/';
  baseAssetConfg = 'Asset/api/Asset/';
  basedocprintConfg = 'DocPrint/api/DocPrintCategories/';
  basedocprintTypeConfg = 'DocPrint/api/DocPrintType/';
  baseExpenseConfg = 'Expense/api/Expense/';
  baseassetpurchaseConfg = 'Asset/api/AssetPurchaseRequest/';
  baseConsumableConfg = 'Consumable/api/Consumable/';
  baseEmployeeCoreConfg = 'EmployeeCore/api/Employee/';
  baseUrlEmployeeFeedback = 'EmployeeCore/api/EmployeeFeedback/';
  PettyCashUrl = 'Payment/api/PettyCash/';
  AdvanceUrl = 'Payment/api/AdvancePayment/';
  dialogRefs: MatDialogRef<CommonModalComponent, any>;
  auditadd: any = [{
    scopelist: []
  }];
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  modePayment = '1';
  Banklist: any;
  EntitlementCode: any;
  scopelist = [];
  freqscopelist = [];
  assetattributenameNew: any[];
  ExpMonthlyCategoryDropDown: any;
  // scopeDatalist:scopelist[]=[]
  isCounting = false;
  isHealth = false;
  isConf = false;
  extendTimeData: any = {
    srid: 1
    //SupportLevelId : ""
  };
  PORaisedByDropDown: any;
  levelOneSupportData: any = {};
  levelOneCategoryName: any;
  levelOneCategoryResolutionTime: any;
  levelOneTimeTaken: any;
  supportExtend: any;
  SupportRequest: any;
  ExtendedTime: any;
  SubmittedBySearch: any = ''
  assetBySearch: any = ''
  @ViewChild('myForm', { static: false }) one: any;
  @ViewChild('f1', { static: false }) two: any;
  @ViewChild('f3', { static: false }) three: any;
  isMasterSubmit = true
  selectedIndexRate: any = [];
  selectedIndexOver: any = [];
  selectedIndexOverQty: any = [];
  selectedIndexQty: any = [];
  reviewNumber: any;
  reviewNumberOverQty: any;
  reviewNumberOver: any;
  reviewNumberQty: any;
  input: any
  ModeDropDown: any;
  feedratModel: any = {
    SatisfactionBy: [],
    //ServiceRecommendation: []
  };
  docPrintCategoryList: any = []
  employeFeedBackDrop: any = []
  empoyeeFeedBack = ''
  feedbackRemark: any = {};
  pasModalData: any;
  userinput: any;
  inList: any = []
  OutList: any = []
  campainEmployeeList: any;
  addNewInput: any = {};
  rawId: any;
  rawData: any;
  Designations: any;
  CountryDropList: any;
  countryId: any;
  mobileDigit: any;
  DepartmentSearch: any
  mspam: any = {
    SpamComment: ''
  };
  priortylist: any = [
    { RequisitionPriorityId: 1, name: 'Scheduled' },
    { RequisitionPriorityId: 2, name: 'Important' },
    { RequisitionPriorityId: 3, name: 'Urgent' },
    { RequisitionPriorityId: 4, name: 'Critical' },
    { RequisitionPriorityId: 5, name: 'Immediate' }
  ]
  Assetattributelist: any = [];
  prdata: any = [];
  AssetattributeDropDownlist: any = [];
  Assetattributeview: any = [];
  accRadio: any = [];
  ExpenseLsits: any = [];
  consumableCategoryList: any[] = [];
  saveConsumableItemInput: any = {};
  UOMList: any = [];
  leaveitem: any = {};
  leaveapprove = true;
  companybrach: any = [];
  currentLocation: string;
  transferToId: any;
  disposeData: any = { DisposeAttachment: {} };
  dialogData: any = {};
  InputData: any = {};
  polist: void;

  constructor(
    public dialogRef: MatDialogRef<ModalpopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private masterService: ServiceService,
    private CampaignService: CampaignService,
    private myActivityService: MyActivityService,
    private location: Location,
    private campaignService: CampaignService,
    private router: Router,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar, private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private dropdownService: DropdownService,
    private appService: PlanworkConfigService,
    private leaveservice: MyActivityService,
    private apiService: ApiService,
    private coreService: ServiceService,
  ) {
    this.dialogData = data;
    console.log('this.dialogData: ', this.dialogData);
    this.InputData = data.data;
    console.log('this.InputData: ', this.InputData);
    this.timeFormate = this.appService.timeFormate;
    this.modalTitle = data.modalTitle;
    this.action = data.action;
    this.leaveId = data.leaveId;
    this.type = data.type;
    this.modalData = data;
    this.rawId = data.RawDataId;
    this.rawData = data.pi;
    this.baseUrlType = data.baseUrlType;
    console.log(data);
    this.levelOneSupportData = data?.listData;
    this.pasModalData = data;
    this.levelOneCategoryName = this.levelOneSupportData?.CategoryName;
    this.levelOneCategoryResolutionTime = this.levelOneSupportData?.CategoryResolutionTime;
    this.levelOneTimeTaken = this.levelOneSupportData?.TimeTaken;
    this.input = JSON.parse(localStorage.getItem('Company'));
    this.userinput = JSON.parse(localStorage.getItem('userid'));

    this.id = {
      Id: data.Id
    };
    if (this.data.action == 'add_contact') {
      this.DepartmentDropList(0)
      this.getDropdownList()
    }
    if (this.data.action == 'deleteAssetInv') {
      this.disposeData = data?.data;
      this.disposeData.DisposeAttachment = {};
    }
    if (this.data.action == 'timeModel') {
      console.log(this.modalData);
      let paylod = {
        "Employee": {
          "EmployeeId": this.modalData.content.EmployeeId,
          "EmployeeName": this.modalData.content.EmployeeName,
        },
        "StartDate": this.modalData.AttendenceDate.StartDate
      }
      this.spinner.show()
      this.dropdownService.postService(paylod, 'EmployeeCore/api/InOut/EmployeeInOutList').subscribe(res => {
        this.inList = res.body.Result.InList
        console.log(this.inList);

        this.OutList = res.body.Result.OutList
        console.log(this.OutList);
        this.spinner.hide()
      })
    }
    if (this.data.action == 'Addconsumableitem' || this.data.action == 'Editconsumableitem') {
      this.spinner.show()
      this.dropdownService.postService({}, 'Consumable/api/Consumable/GetConsumableCategoryDropDownList').subscribe(res => {
        this.consumableCategoryList = res.body
        this.spinner.hide()
      })
    }
    if (this.data.action == 'AddDocprintType' || this.data.action == 'EditdocprintType') {
      this.spinner.show()
      this.dropdownService.postService({}, 'DocPrint/api/DocPrintCategories/DocPrintCategorieDropDown').subscribe(res => {
        this.docPrintCategoryList = res.body
        this.spinner.hide()
      })
    }
    if (this.data.action == 'empFeedback') {
      this.spinner.show()
      this.dropdownService.postService({}, 'EmployeeCore/api/EmployeeFeedback/EmployeeDropDownForFeedback').subscribe(res => {
        console.log(res);
        this.employeFeedBackDrop = res.body
        this.spinner.hide()
      })
    }
    if (this.data.action == 'supportExtendTimeDet') {

      this.CampaignService.SupportMember({ srextendtimeid: this.modalData.data.srextendtimeid }, 'ExtendTimeRequestGetById').subscribe(res => {
        console.log(res);

        this.supportExtend = res.body.Item2

        let CreatedDate = new Date(this.modalData.data.CreatedDate.split('.')[0]);
        let CurrentTime = new Date(this.modalData.data.CurrentTime.split('.')[0]);

        let equaleTotalTime = CurrentTime.getTime() - CreatedDate.getTime();
        let days = Math.floor(equaleTotalTime / (60 * 60 * 24 * 1000));
        let hours = Math.floor(equaleTotalTime / (60 * 60 * 1000)) - (days * 24);
        let minutes = Math.floor(equaleTotalTime / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
        return this.modalData.data.remainingTime = {
          hour: hours, minute: minutes
        },

          this.modalData.data.remainingTime.minute = minutes <= 9 ? '0' + minutes : minutes,
          this.modalData.data.remainingTime.hour = hours <= 9 ? '0' + hours : hours;

      })

    }

    if (data.type != 'View') {

      this.istrue = true
    }
    console.log(data.type);

    if (data.action == 'ViewDeliverable') {
      this.modelDeliver = data.view;
      if (this.modelDeliver.AttachFiles && this.modelDeliver.AttachFiles.length > 0) {
        for (let attach of this.modelDeliver.AttachFiles) {
          const fileExe = attach.DocumentFileName.split('.').pop();
          attach.FileExe = fileExe;
        }
      }
    }
    if (data.action == 'EditBankDetail') {
      console.log(data);
      this.BankDetail = data.data.bankData
    }
    if (data.action == 'GetClientEdit') {
      this.SaveClient.Client = data.ClientInfo
    }
    if (data.action == 'ChooseEmployeeType' || data.action == 'ChooseEmployeeType2' && data.EmployeeCategory) {
      this.SaveEmployeeType.EmployeeOfficialDetail.EmployeeCategory = data.EmployeeCategory;
    }
    if (this.action == "ChooseEmployeeType" || this.action == "ChooseEmployeeType2") {
      this.SaveEmployeeType.EmployeeOfficialDetail.EmployeeId = this.data.id;
    }

    if(data.action  == 'ComplianceFile'){
      this.savePaymentInput.Attachment = {};
      this.savePaymentInput.paidDate = this.InputData.paidDate
    }
    // if(data.action  == 'ComplianceFileView'){
    //   this.savePaymentInput.Attachment = {};
    // }
    switch (this.action) {
      // case 'EditEntitlements':
      //   this.EditModal('ExpenseEntitlementsCodeGetById', 'EditEntitlements', this.modalData.Data);
      //   break;
      case 'leaveView':
        this.leavPopupDataShow();
        break;
      case 'Editconsumableitem':
        this.EditModal('ConsumableItemGetById', 'EditConsumableItem', this.modalData.Data);
        break;
      case 'Editconsumablecategory':
        this.EditModal('ConsumableCategoryGetById', 'EditConsumableCategory', this.modalData.Data);
        break;
      case 'EditExpenseType':
        this.EditModal('ExpenseTypeGetById', 'EditExpenseType', this.modalData.Data);
        break;
      case 'EditAccommodationType':
        this.EditModal('ExpenseAccomodationGetById', 'EditAccommodationType', this.modalData.Data);
        break;
      case 'EditModeofConveyance':
        this.EditModal('ModeofConveyanceGetById', 'EditModeofConveyance', this.modalData.Data);
        break;
      case 'EditUom':
        this.EditModal('UnitOfMeasurementGetById', 'EditUom', this.modalData.Data);
        break;
      case 'Editassetclass':
        this.EditModal('AssetClassGetById', 'Editassetclass', this.modalData.Data);
        break;
      case 'Editassetitem':
        this.EditModal('AssetItemGetById', 'Editassetitem', this.modalData.Data);
        break;
      case 'EditAssetAttribute':
        this.EditModal('AttributeGetById', 'EditAssetAttribute', this.modalData.Data);
        break;
      case 'Editassetcategory':
        this.EditModal('AssetCategoryGetById', 'Editassetcategory', this.modalData.Data);
        break;
      case 'EditEntitlementsCodes':
        this.EditModal('EntitlementCodeGetById', 'EditEntitlementsCodes', this.modalData.Data);
        break;
      case 'EditPaymentTerms':
        this.EditModal('PaymentTermGetById', 'EditPaymentTerms', this.modalData.Data);
        break;
      case 'AddUnits':
        this.AddUnits()
        break;
      case 'AddDocumentType':
        this.DocumentCategory()
        break;
      // case 'AddUnits':
      //   this.EditUnits()
      //   break;

      case 'EditUnit':
        this.EditUnit()
        break;
      case 'EditNationality':
        this.EditNationality()
        break;
      case 'EditDesignation':
        this.EditModal('DesignationGetById', 'EditDesignation', this.modalData.Data);
        break;
      case 'EditLevel':
        this.EditLevel()
        break;
      case 'EditOrganizationType':
        this.OrganisationTypeGetById()
        break;
      case 'EditReferenceMode':
        this.ReferenceModeGetById()
        break;
      case 'EditCustomerSegment':
        this.EditCustomerSegment()
        break;
      case 'EditPaymentType':
        this.EditModal('PaymentTypeGetById', 'EditPaymentType', this.modalData.Data);
        break;
      case 'EditIndustryType':
        this.EditModal('IndustryTypeGetById', 'EditIndustryType', this.modalData.Data);
        break;
      case 'EditGenericCategory':
        this.EditGenericCategory()
        break;
      case 'EditCostCenter':
        this.EditCostCenter()
        break;
      case 'EditCostCenterType':
        this.EditModal('CostCenterTypeGetById', 'CostCenterGetedit', this.modalData.Data);
        break;
      case 'EditCostCenterName':
        this.EditModal('CostCenterNameGetbyId', 'CostCenternameGetedit', this.modalData.Data);
        break;
      case 'EditTexType':
        this.EditModal('TaxTypeGetById', 'EditTexType', this.modalData.Data);
        break;
      case 'EditHsnSac':
        this.EditModal('HSNGetById', 'EditHsnSac', this.modalData.Data);
        break;
      case 'EditGeneralTerm':
        this.EditGeneralTerm()
        break;
      case 'EditInvoicePaymentTerm':
        this.EditInvoicePaymentTerm()
        break;
      case 'EditFinancialYear':
        this.EditModal('FinancialYearGetById', 'EditFinancialYear', this.modalData.Data);
        break;
      case 'EditProductCategory':
        this.EditProductCategory()
        break;
      case 'EditProductType':
        this.EditProductType()
        break;
      case 'EditSkillSet':
        this.EditSkillSet()
        break;
      case 'EditSkillCategory':
        this.EditSkillCategory()
        break;
      case 'EditCadre':
        this.EditCadre()
        break;
      case 'EditBgInfo':
        this.EditBgInfo()
        break;
      case 'EditProjectCategory':
        this.EditProjectCategory()
        break;
      case 'EditProjectType':
        this.EditProjectType()
        break;
      case 'EditDepartment':
        this.EditModal('DepartmentGetById', 'EditDepartmentGet', this.modalData.Data);
        break;
      case 'EditConsumable':
        this.EditModal('ConsumableClassGetById', 'EditConsumable', this.modalData.Data);
        break;
      case 'EditShift':
        this.EditModal('ShiftGetById', 'EditShift', this.modalData.Data);
        break;
      case 'EditProfitCenterDetails':
        this.EditProfitCenterDetails()
        break;
      case 'EditStatus':
        this.EditStatus()
        break;
      case 'EditBusiness':
        this.EditBusiness()
        break;
      case 'EditTax':
        this.EditTax()
        break;
      case 'DurationEditUnit':
        this.EditDurationUnit()
        break;
      case 'EditAssestItem':
        this.EditAssestItem()
        break;
      case 'EditCampaignGroup':
        this.EditCampaignGroup()
        break;
      case 'EditServiceType':
        this.EditServiceType()
        break;
      case 'EditServiceCategorys':
        this.EditServiceCategorys()
        break;
      case 'EditKeyFeature':
        this.EditKeyFeature()
        break;
      case 'ServiceEditKeyFeature':
        this.EditserviceKeyFeature()
        break;
      case 'ProjectEditKeyFeature':
        this.EditprojectKeyFeature()
        break;
      case 'EditGeneric':
        this.EditGeneric()
        break;
      case 'EditCurrency':
        this.EditModal('CurrencyGetById', 'editCurrency', this.modalData.Data);
        break;
      case 'Editdocprintcategory':
        this.EditModal('DocumentPrintCategorieGetById', 'editdocprintcategory', this.modalData.Data);
        break;
      case 'EditdocprintType':
        this.EditModal('DocumentPrintTypeGetById', 'editdocprintType', this.modalData.Data);
        break;
      case 'EditCompliances':
        this.EditModal('CompliancesCategoryGetById', 'editCompliances', this.modalData.Data);
        break;
      case 'EditModeOfPO':
        this.EditModeOfPO()
        break;
      case 'EditBank':
        this.EditModal('BankGetById', 'EditBank', this.modalData.Data)
        break;
      case 'EditYear':
        this.EditModal('YearGetById', 'EditYear', this.modalData.Data);
        break;
      case 'EditRegReason':
        this.EditRegReason()
        break;
      case 'EditOtherActivity':
        this.EditOtherAct()
        break;
      case 'EditTerReason':
        this.EditTerReason()
        break;
      case 'EditOccupation':
        this.EditOccupation()
        break;
      case 'EditBankAcTyp':
        this.EditModal('BankAccountTypeGetbyId', 'EditBankAcTyp', this.modalData.Data);
        break;
      case 'EditDocumentType':
        this.DocumentTypeGetById()
        break;
      case 'EditDocumentCategory':
        this.EditModal('DocumentCategoryGetById', 'DocumentCategoryGetById', this.modalData.Data);
        break;
      case 'EditUnits':
        this.AddUnits()
        this.EditModal('UnitGetByID', 'EditUnits', this.modalData.Data);
        break;
      case 'EditEstimationBuffer':
        this.EditModal('EstimationBufferGetById', 'EditEstimationBuffer', this.modalData.Data);
        break;
      case 'EditLabel':
        this.editLabel()
        break;
      case 'EditBufferPercentage':
        this.EditModal('BufferPercentageGetbyId', 'EditBufferPercentage', this.modalData.Data);
        break;
      case 'EditClient':
        this.EditClient()
        break;
      case 'EditReason':
        this.EditModal('ReasonGetById', 'EditReason', this.modalData.Data);
        break;
      case 'EditProjectActivity':
        this.EditProjectActivity()
        break;
      case 'EditServiceActivity':
        this.EditServiceActivity()
        break;
      case 'EditCountry':
        this.EditModal('CountryGetById', 'EditCountry', this.modalData.Data);
        break;
      case 'AddState':
        this.getCountryfn()
        break;
      case 'EditState':
        this.EditModal('StateGetById', 'EditState', this.modalData.Data);
        this.getCountryfn()
        break;
      case 'EditProfitCenter':
        this.EditModal('ProfitCenterGetById', 'EditProfitCenter', this.modalData.Data);
        break;
      case 'EditCostCenterCategory':
        this.EditCostCenterCategory()
        break;
      case 'EditCategory':
        this.EditCategory()
        break;
      case 'UserRoleEdit':
        this.UserRoleEdit()
        break;
      case 'EditEmployeeCategory':
        this.EditEmployeeCategory()
        break;
      case 'EditWorkingSchedule':
        this.EditModal('WorkingScheduleGetById', 'EditWorkingSchedule', this.modalData.Data);
        break;
      case 'EditHolidays':
        this.EditModal('HolidayGetById', 'EditHolidays', this.modalData.Data);
        break;
      case 'EditWeeklyOff':
        this.EditWeeklyOff()
        break;
      case 'EditSourceData':
        this.EditSourceData()
        break;
      case 'EditCampaignType':
        this.EditCampaignType()
        break;
      case 'AddCityAreaName':
        this.getCountryfn()
        break;
      case 'AddCityName':
        this.getCountryfn()
        break;
      case 'CityNameEdit':
        this.getEditCityData()
        break;
      case 'CityAreaNameEdit':
        this.getEditCountryData()
        break;
      case 'editNumberSystem':
        this.editNumberSystem()
        break;
      case 'editGenericTypeName':
        this.editGenericTypeName()
        break;
      case 'editGenericTypeName':
        this.getGenericTypeDropDownList();
        break;
      case 'EditPaymentMode':
        this.EditModal('PaymentModeGetById', 'getEditPaymentMode', this.modalData.Data);
        break;

    }

  }
  fileUpload(event, data) {
  console.log('data: ', data);
  console.log('event: ', event);
    this.appService.filesizeValidation(event).subscribe(res => data.fileSizeError = res);
    if (data?.fileSizeError == '') {
      console.log(data);
      const files = event.target.files;
      const file = files[0];
      if (files && file) {
        const fileName = file.name;
        const fileExe = fileName.split('.').pop();
        let reader = new FileReader();
        reader.onload = (e: any) => {
          let imagePreview = e.target.result;
          let b;
          b = imagePreview.split('base64,');
          data.DocumentId = 0
          data.DocumentFileName = fileName;
          data.Preview = imagePreview;
          data.DocumentPath = '';
          data.FileData = b[1]
          data.FileExe = fileExe;
        }
        reader.readAsDataURL(file);
      }
    }
  }
  fileUploadRemove(data) {
    data.DocumentFileName = '';
    data.DocumentPath = '';
    data.DocumentId = 0
    data.Preview = ''
    data.FileData = '';
    this.myFileInput.nativeElement.value = '';
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  BankList(): Promise<any> {
    return this.dropdownService.BankListfunc({}).toPromise()
  }
  modetype() {
    return this.dropdownService.ModeListfunc({}).toPromise()
  }
  sendOneLevelData() {
    this.dialogRef.close(this.extendTimeData);
  }
  async employeetypeid() {

    this.spinner.show();
    this.response = await this.masterService.asyncPostService(this.SaveEmployeeType.EmployeeOfficialDetail, 'SaveEmployeeType', this.baseUrlType)
    console.log(this.response);

    this.SaveEmployeeType.EmployeeOfficialDetail.EmployeeCategory = this.response.data.Item2;
    this.spinner.hide();

    this.dialogRef.close(this.response.data.Item2)

    this.dialogRef = this.dialog.open(ModalpopupComponent, {
      panelClass: ['modal-scroll', 'genlinkmodal'],
      autoFocus: false,
      disableClose: true,
      data: { modalTitle: 'Copy Link', action: 'genrateLink', url: this.response.data.Item2.WelcomeLink }
    });

    // this.spinner.show();
    // this.masterService.postEmployee({ EmployeeId: this.response.data.Result.EmployeeId }, 'GenrateLink').subscribe(response => {
    //   this.spinner.hide();
    //   if (response.body.Success) {
    //     this.dialogRef = this.dialog.open(ModalpopupComponent, {
    //       panelClass: ['modal-scroll', 'genlinkmodal'],
    //       autoFocus: false,
    //       disableClose: true,
    //       data: { modalTitle: 'Copy Link', action: 'genrateLink', url: response.body.Result.Link }
    //     });
    //   }
    //   else {
    //     this.Message = response.body.Result.TransactionMessage.Message;
    //   }
    // }),

    //   error => {
    //     this.spinner.hide();
    //   }
  }
  leavPopupDataShow() {
    let LeaveViewBY
    console.log(this.modalData.type);

    // if (this.modalData.type == 'View') {
    // LeaveViewBY = 'GetLeaveown?LeaveId=';
    // } else {
    LeaveViewBY = 'GetLeave?LeaveId=';
    // }

    this.spinner.show()
    this.myActivityService.postService(null, LeaveViewBY + this.leaveId).subscribe(response => {
      this.response = response
      console.log(this.response);

      this.spinner.hide()

      let getitem = JSON.parse(localStorage.getItem('userid'))
      console.log(getitem.EmployeeId);

      this.leavDataShow = this.response.body.Result;
      if (this.leavDataShow?.LeaveTaskList.length > 0) {
        this.leaveapprove = false
        this.leaveitem = this.leavDataShow?.LeaveTaskList.filter(x => x.AssignBy.EmployeeId == getitem.EmployeeId)[0]
        this.leaveapprove = this.leavDataShow?.LeaveTaskList.every(x => x.LeaveStatus?.StatusId != 100)
      }
    })
  }
  async saveRecurring() {
    this.spinner.show()
    if (this.saveRecurringData.IsDomestic) {
      this.saveRecurringData.CurrencyId = parseInt(this.userinput?.CompanyCurrencyId);
    }
    this.response = await this.masterService.asyncPostService(this.saveRecurringData, 'RecurringExpenseSave', this.baseUrlType)
    this.spinner.hide()
    if (this.response.data.Result.Status == 200) {
      this.resultPopup('success', this.response.data.Result.Message);
      this.dialogRef.close(true)
    } else {
      this.resultPopup('Alert', this.response.data.Result.Message)
    }
  }
  async saveRecurringExpense() {
    this.spinner.show()
    if (this.saveExpenseData.IsDomestic) {
      this.saveExpenseData.CurrencyId = parseInt(this.userinput?.CompanyCurrencyId);
    }
    this.response = await this.masterService.asyncPostService(this.saveExpenseData, 'TravelExpenseSave', this.baseUrlType)
    console.log(this.response);

    this.spinner.hide()
    if (this.response.data.Result.Status = 200) {
      this.resultPopup('success', this.response.data.Result.Message);
      this.dialogRef.close(true)
    } else {
      this.resultPopup('Alert', this.response.data.Result.Message)
    }

    console.log(this.response)
    // this.masterService.SupportMember(this.saveRecurringData , 'RecurringExpenseSave').subscribe(res => {
    this.spinner.hide()
    // res.case = "approve"
    this.dialogRef.close(this.response)
    // })
  }
  async saveFoodBeverage() {
    console.log(this.saveFoodBeverageData?.billdocument);

    if (this.saveFoodBeverageData.IsDomestic) {
      this.saveFoodBeverageData.CurrencyId = parseInt(this.userinput?.CompanyCurrencyId);
    }
    if (this.saveFoodBeverageData?.billdocument?.DocumentFileName) {
      this.spinner.show()
      this.response = await this.masterService.asyncPostService(this.saveFoodBeverageData, 'FoodExpenseSave', this.baseUrlType)
      console.log(this.response)
      this.spinner.hide()
      this.dialogRef.close(this.response)
    }
  }
  async saveGeneralData() {

    if (this.saveGeneralExData.IsDomestic) {
      this.saveGeneralExData.CurrencyId = parseInt(this.userinput?.CompanyCurrencyId);
    }
    if (this.saveGeneralExData?.billdocument?.DocumentFileName) {
      this.spinner.show()
      console.log(this.saveGeneralExData);
      this.response = await this.masterService.asyncPostService(this.saveGeneralExData, 'GeneralExpenseSave', this.baseUrlType)
      this.spinner.hide()
      this.dialogRef.close(this.response)
    }
  }

  dateChange(event, dateType) {
    console.log(dateType);
    if (dateType == 'fromdate') {
      this.saveAccommodationExpense.todate = ''
    }
    console.log(this.saveAccommodationExpense.fromdate);
    console.log(this.saveAccommodationExpense.todate);
    var date1 = new Date(this.saveAccommodationExpense.fromdate);
    var date2 = new Date(this.saveAccommodationExpense.todate);
    var Difference_In_Time = date2.getTime() - date1.getTime();
    this.saveAccommodationExpense.noofdays = Difference_In_Time / (1000 * 3600 * 24);
    console.log(this.saveAccommodationExpense.noofdays);

  }


  async saveAccommodationEx() {
    if (this.saveAccommodationExpense.IsDomestic) {
      this.saveAccommodationExpense.CurrencyId = parseInt(this.userinput?.CompanyCurrencyId);
    }
    if (!this.saveAccommodationExpense.typeid) {
      this.saveAccommodationExpense.typeid = 1
    }
    console.log(this.saveAccommodationExpense);
    if (this.saveAccommodationExpense?.billdocument?.DocumentFileName) {
      this.spinner.show()
      this.response = await this.masterService.asyncPostService(this.saveAccommodationExpense, 'AccommodationExpenseSave', this.baseUrlType)
      this.spinner.hide()
      this.dialogRef.close(this.response)
    }
  }

  async editGenericTypeName() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'GenericTypeGetById', this.baseUrlType)
    this.GenericTypeSaveInput = this.response.data.Result;
  }
  async editNumberSystem() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'NumberSystemGetById', this.baseUrlType)
    this.NumberSystemINput = this.response.data.Result;
  }
  async EditCampaignType() {
    this.response = await this.masterService.asyncPostService({ CampaignTypeId: this.modalData.Data }, 'CampaignTypeById', this.baseUrlType)
    this.campaignTypeDataSave = this.response.data.Result;
  }
  async EditSourceData() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'SourceDataGetById', this.baseUrlType)
    this.SourceDataSave = this.response.data.Result;
  }
  async EditWeeklyOff() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'WeeklyOffGetById', this.baseUrlType)
    this.saveWeeklyOffInput = this.response.data.Result;
  }


  async EditEmployeeCategory() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'EmployeeCategoryGetById', this.baseUrlType)
    this.saveEmployeeCategoryInputs = this.response.data.Result;
  }
  async UserRoleEdit() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'UserRoleGetById', this.baseUrlType)
    this.saveUserroleList = this.response.data.Result;
  }
  async EditCostCenterCategory() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'CostCenterSBHGetById', this.baseUrlType)
    this.saveCostCenterCategorySave = this.response.data.Result;
  }
  async EditServiceActivity() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'ServiceActivityGetById', this.baseUrlType)
    this.saveServiceActivityInput = this.response.data.Result;

    if (this.saveServiceActivityInput.EstimationGroup != null) {
      this.saveServiceActivityInput.IsEstimationgroup = true;
    }
  }
  async EditProjectActivity() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'ProjectActivityGetById', this.baseUrlType)
    this.saveProjectActivityInput = this.response.data.Result;
  }
  async EditClient() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'ClientGetById', this.baseUrlType)
    this.saveClientInputs = this.response.data.Result;
  }




  async AddUnits() {
    this.response = await this.masterService.asyncPostService({}, 'CategoryDropDownList', 'GlobalCore')
    this.ProjectActivityDropDownList = this.response.data;
  }
  async DocumentCategory() {
    this.response = await this.masterService.asyncPostService({}, 'DocumentCategoryDropDownList', 'GlobalCore')
    this.DocumentCategoryDD = this.response.data;

  }
  async EditRegReason() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'ResignationReasonGetById', this.baseUrlType)
    this.saveRegInput = this.response.data.Result;
  }
  async EditOtherAct() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'OtherActivityGetById', this.baseUrlType)
    this.otheractInput = this.response.data.Result;

  }
  async EditTerReason() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'TerminationReasonGetById', this.baseUrlType)
    this.saveTerInput = this.response.data.Result;
  }
  async EditOccupation() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'OccupationGetById', this.baseUrlType)
    this.saveOccupationInput = this.response.data.Result;
  }
  //Edit Modal
  async DocumentTypeGetById() {
    await this.DocumentCategory();
    this.EditModal('DocumentTypeGetById', 'DocumentTypeGetById', this.modalData.Data);
  }

  savepr() {
    this.spinner.show()
    this.assetitem.purchaserequestapprovestatus = this.pasModalData.action2 == 'addConsumable' ? 0 : 1;
    this.prdata.push(this.assetitem)
    if (this.prdata) {

      if (this.pasModalData.action2 == 'addConsumable') {
        this.prdata[0].Uom = this.prdata[0].UomId;
        this.coreService.ConsumableService1(this.prdata, 'ConsumablePurchaseRequestSave').subscribe(response => {
          this.dialogRef.close(response)
          this.spinner.hide()
        })
      } else {
        this.coreService.assetPurchaseRequest(this.prdata, 'AssetPurchaseRequestSave').subscribe(response => {
          this.dialogRef.close(response)
          // routerLink="/asset-management/generate-pr"
          this.spinner.hide()
        })
      }
    }
  }
  async EditModal(apiName, name, inputData) {

    console.log(name);
    console.log(inputData);
    console.log(this.baseUrlType);


    console.log(apiName);
    this.response = await this.masterService.asyncPostService(inputData, apiName, this.baseUrlType)
    let saveInput
    if (this.action === 'EditHolidays') {

      this.response.data.Result.WorkingType = "one time"
    }
    console.log(this.response)
    console.log(this.response?.data?.Item2)
    if (this.response?.data?.Item2) {
      saveInput = this.response?.data?.Item2
    }
    else {
      saveInput = this.response?.data?.Result;

    }

    console.log(saveInput);


    switch (name) {
      case 'EditUom':
        this.saveAssetInput = saveInput;
        break;
      case 'Editassetclass':
        this.saveAssetInput = saveInput;
        break;
      case 'EditAssetAttribute':
        this.saveAssetAttributeInput = saveInput;
        break;
      case 'Editassetitem':
        this.saveAssetItemInput = saveInput;
        this.changeCategory(this.saveAssetInput)
        this.changeAssetClass(this.saveAssetItemInput.AssetClassId)
        break;
      case 'EditConsumableItem':
        this.saveConsumableItemInput = saveInput;
        break;
      case 'EditConsumableCategory':
        this.saveConsumableCatInput = saveInput;
        break;
      case 'Editassetcategory':
        this.saveAssetInput = saveInput;
        break;
      case 'EditEntitlementsCodes':
        this.SaveEmployeeEntitlementInput = saveInput;
        break;
      case 'EditState':
        this.saveStateInput = saveInput;
        break;
      case 'EditReason':
        this.saveReasonInput = saveInput;
        break;
      case 'EditPaymentType':
        this.inputPaymentType = saveInput;
        break;
      case 'EditPaymentTerms':
        this.PaymentTermsInput = saveInput;
        break;
      case 'EditFinancialYear':
        this.financialYearInput = saveInput;
        break;
      case 'EditQualification':
        this.saveQualificationData = saveInput;
        break;
      case 'EditHsnSac':
        this.hsnInput = saveInput;
        this.hsnInput.HSNId = this.hsnInput.HSNId;
        break;
      case 'EditHolidays':
        this.saveHolidayInput = saveInput
        this.saveWorkingSchedule.WorkingType = "one time"
        this.isHoliday = this.saveHolidayInput;
        console.log(this.isHoliday);
        break;
      case 'EditWorkingSchedule':
        this.saveWorkingSchedule = saveInput
        break;
      case 'EditDesignation':
        this.saveDesignationInput = saveInput
        break;
      case 'EditShift':
        this.saveShiftInput = saveInput
        break;
      case 'EditProfitCenter':
        this.saveProfitCenterInput = saveInput
        break;
      case 'CostCenternameGetedit':
        this.costCenterInput = saveInput
        break;
      case 'CostCenterGetedit':
        this.costCenterType = saveInput
        break;
      case 'EditEstimationBuffer':
        this.saveEstimationBufferInput = saveInput
        break;
      case 'DesignationGetedit':
        this.saveDesignationInput = saveInput
        break;
      case 'EditDepartmentGet':
        this.saveDepartmentInput = saveInput
        break;
      case 'DocumentTypeGetById':
        this.saveDocumentTypInput = saveInput
        break;
      case 'EditYear':
        this.saveYearInput = saveInput
        break;
      case 'EditUnits':
        this.saveUnitsInput = saveInput
        break;
      case 'EditTexType':
        this.taxTypeInput = saveInput
        break;
      case 'getEditPaymentMode':
        this.savePaymentModeData = saveInput
        break;
      case 'EditIndustryType':
        this.SaveIndustryType = saveInput
        break;
      case 'DocumentCategoryGetById':
        this.saveDocumentCategoryInput = saveInput
        break;
      case 'EditCountry':
        this.saveCountryInput = saveInput
        break;
      case 'editCurrency':
        this.saveCurrencyInput = saveInput
        break;
      case 'editdocprintcategory':
        this.DocPrintCategorie = saveInput
        break;
      case 'editdocprintType':
        this.docPrintTypeSave = saveInput;
        break;
      case 'editCompliances':
        console.log(saveInput);

        this.saveComplinceInput = saveInput;
        break;
      case 'EditBank':
        this.saveBankInput = saveInput
        break;
      case 'EditBankAcTyp':
        this.saveBankAcTypInput = saveInput
        break;
      case 'EditBufferPercentage':
        this.saveBufferPercentageInput = saveInput
        break;
      case 'EditConsumable':
        this.saveConsumableInput = saveInput
        break;
      case 'EditModeofConveyance':
        this.saveConveyanceInput = saveInput
        break;
      case 'EditAccommodationType':
        this.saveConveyanceInput = saveInput
        break;
      case 'EditExpenseType':
        this.saveExpenseType = saveInput
        break;
      // case 'EditEntitlements':
      //   this.saveExpenseEntitlementInput = saveInput
      //   break;
    }
  }

  async EditModeOfPO() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'ModeOfPOGetById', this.baseUrlType)
    this.saveModeOfPOInput = this.response.data;
  }


  async EditGeneric() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'GenericTypeGetById', this.baseUrlType)
    this.saveGenericCategoryTypeInput = this.response.data.Result;
  }
  async EditCampaignGroup() {
    this.response = await this.masterService.asyncPostService({ CampaignGroupId: this.modalData.Data }, 'CampaignGroupById', this.baseUrlType)
    this.saveCampaignGroupInput = this.response.data.Result;
  }
  async EditAssestItem() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'AssetGetById', this.baseUrlType)
    this.saveAssestItemInput = this.response.data.Result;
  }

  async EditTax() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'TaxGetById', this.baseUrlType)
    this.saveTaxInput = this.response.data.Result;
  }
  async EditDurationUnit() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'DurationUnitGetById', this.baseUrlType)
    this.SaveUnit = this.response.data.Result;
  }
  async EditProfitCenterDetails() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'ShiftGetById', this.baseUrlType)
    this.saveProfitCenterDetailsInput = this.response.data.Result;
  }
  async EditShift() {
    this.modalData.Data.FromTime = this.modalData.Data.FromTimeOriginal;
    this.modalData.Data.ToTime = this.modalData.Data.ToTimeOriginal;
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'ShiftGetById', this.baseUrlType)
    this.saveShiftInput = this.response.data.Result;
  }
  async EditProjectType() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'ProjectTypeGetById', this.baseUrlType)
    this.SaveProjectType = this.response.data.Result;
  }
  async EditProjectCategory() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'ProjectCategoryGetById', this.baseUrlType)
    this.saveProjectCategoryList = this.response.data.Result;
  }
  async EditCadre() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'CadreGetbyId', this.baseUrlType)
    this.saveCadreInput = this.response.data.Result;
  }
  async EditBgInfo() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'BackgroundInfoGetbyId', this.baseUrlType)
    this.saveBgInfoInput = this.response.data.Result;
  }
  async EditSkillCategory() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'SkillCategoryGetById', this.baseUrlType)
    this.skillCategoryInput = this.response.data.Result;
  }
  async EditSkillSet() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'KeySkillGetById', this.baseUrlType)
    this.SkillSetInput = this.response.data.Result;
  }
  async EditProductType() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'ProductTypeGetById', this.baseUrlType)
    this.productTypeInput = this.response.data.Result;
  }
  async EditProductCategory() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'ProductCategoryGetById', this.baseUrlType)
    this.productCategoryInput = this.response.data.Result;
  }
  async EditGeneralTerm() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'GeneralTermGetById', this.baseUrlType)
    this.generalTermInput = this.response.data.Result;
  }
  async EditInvoicePaymentTerm() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'InvoicePaymentTermGetById', this.baseUrlType)
    this.invoicePaymentTermInput = this.response.data.Result;
  }


  async EditCostCenter() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'CostCenterGetById', this.baseUrlType)
    this.SaveCostCenter = this.response.data.Result;
  }
  async EditGenericCategory() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'GenericCategoryGetById', this.baseUrlType)
    this.saveGenericCategoryInputs = this.response.data.Result;
  }
  async EditCustomerSegment() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'CustomerSegmentGetById', this.baseUrlType)
    this.SaveCustmerSegment = this.response.data.Result;
  }
  async EditLevel() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'LevelGetById', this.baseUrlType)
    this.saveLevelInput = this.response.data.Result;
  }

  async EditNationality() {
    this.response = await this.masterService.asyncPostService({ NationalityId: this.modalData.Data.NationalityId }, 'NationalityGetById', this.baseUrlType)
    this.saveNationalityInput = this.response.data.Item2;
  }
  async EditStatus() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'StatusGetBYId', this.baseUrlType)
    this.saveStatusInput = this.response.data.Result;
  }
  async EditBusiness() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'BusinessProcessGetById', this.baseUrlType)
    this.saveBusinessInput = this.response.data.Result;
  }
  async EditCategory() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'CategoryGetById', this.baseUrlType)
    this.SaveCategory = this.response.data.Result;
  }

  async editLabel() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'LabelGetById', this.baseUrlType)
    this.saveLabelInput = this.response.data.Result;
  }

  async ServiceTaskSelectCategoryType(event, type) {
    let response;
    if (event.CategoryId) {
      this.saveCatId = event.CategoryId
    }
    switch (type) {
      case 'activity':
        response = await this.masterService.companyCorepostService({}, 'ServiceActivityDropDownList?CategoryId=' + event.CategoryId)
        if (response.data) {
          this.ServiceActivityList = response.data;
          if (this.modalData.customData.type.CategoryName == "Efforts") {
            this.saveServiceTaskInput.ServiceActivity = this.modalData.customData.tasks.category;
          }
        }
        break;
      case 'subactivity':
        response = await this.masterService.companyCorepostService({}, 'ServiceSubActivityDropDownList?CategoryId=' + this.saveCatId + '&ServiceActivityId=' + event.ServiceActivityId)
        if (response.data) {
          this.ServiceSubActivityList = response.data;
          if (this.modalData.customData.type.CategoryName == "Efforts") {
            this.saveServiceTaskInput.ServiceSubActivity = this.modalData.customData.tasks.subCategory;
          }
        }
    }

  }

  async ServiceTaskSelectCategory(event, type) {

    let response;
    if (event.value.CategoryId) {
      this.saveCatId = event.value.CategoryId
    }
    switch (type) {
      case 'activity':
        response = await this.masterService.asyncPostService({}, 'ServiceActivityDropDownList?CategoryId=' + event.value.CategoryId, 'ServiceCore')
        console.log(response);

        if (response.data) {
          this.ServiceActivityList = response.data;
        }
        break;
      case 'subactivity':
        response = await this.masterService.asyncPostService({}, 'ServiceSubActivityDropDownList?CategoryId=' + this.saveCatId + '&ServiceActivityId=' + event.value.ServiceActivityId, 'ServiceCore')
        console.log(response);
        if (response.data) {
          this.ServiceSubActivityList = response.data;
        }
    }

  }
  async ProjectTaskSelectCategory(event, type) {
    let response;
    if (event.value.CategoryId) {
      this.saveCatId = event.value.CategoryId
    }
    switch (type) {
      case 'activity':
        response = await this.masterService.companyCorepostService({}, 'ProjectActivityDropDownList?CategoryId=' + event.value.CategoryId)

        if (response.data) {
          this.ProjectActivityList = response.data;
        }
        break;
      case 'subactivity':

        response = await this.masterService.companyCorepostService({}, 'ProjectSubActivityDropDownList?CategoryId=' + this.saveCatId + '&ProjectActivityId=' + event.value.ProjectActivityId)
        if (response.data) {
          this.ProjectSubActivityList = response.data;
        }
    }
  }
  async EditServiceType() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'ServiceTypeGetById', this.baseUrlType)
    if (this.response.data.Result) {
      this.saveServiceCategoryTypeInput = this.response.data.Result;
    }
  }
  async EditServiceCategorys() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'ServiceCategoryGetById', this.baseUrlType)
    if (this.response.data.Result) {
      this.saveServiceCategoryInput = this.response.data.Result;
    }
  }

  async OrganisationTypeGetById() {
    console.log(this.modalData.Data);

    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'OrganisationTypeGetById', this.baseUrlType)


    if (this.response.data.Success) {
      this.SaveOrganition = this.response.data.Result;
    }
  }
  async ReferenceModeGetById() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'ReferenceModeGetById', this.baseUrlType)
    if (this.response.data.Result) {
      this.SaveReferenceMode = this.response.data.Result;
    }
  }
  async EditKeyFeature() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'KeyFeatureGetById', this.baseUrlType)
    if (this.response.data.Result) {
      this.SaveKeyFeature = this.response.data.Result;
    }
  }
  async EditserviceKeyFeature() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'ServiceKeyFeatureGetById', this.baseUrlType)
    if (this.response.data.Result) {
      this.ServiceKeyFeatureSave = this.response.data.Result;
    }
  }
  async EditprojectKeyFeature() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'ProjectKeyFeatureGetById', this.baseUrlType)
    if (this.response.data.Result) {
      this.ProjectKeyFeatureSave = this.response.data.Result;
    }
  }
  EntitlementCodeDropDown(): Promise<any> {
    return this.dropdownService.EntitlementCodeDropDownList({}).toPromise()
  }
  ExpenseEntitlementCodeDropDown(): Promise<any> {
    return this.dropdownService.ExpenseEntitlementsCodeList({}).toPromise()
  }
  PORaisedByList(): Promise<any> {
    return this.dropdownService.EmployeeDropDown({}).toPromise();
  }
  VendorList(): Promise<any> {
    return this.dropdownService.VendorList({}).toPromise();
  }

  async ngOnInit() {
    console.log(this.modalData.data);

    if (this.action == 'addGenPr') {
      if (this.pasModalData.action2 == 'addConsumable') {
        this.masterService
          .ConsumableService2({}, "GetConsumableItemDropDownList")
          .subscribe((response) => {
            this.AssetItemDroplist = response.body;
          });
      } else {
        this.response = await this.masterService.asyncPostService({}, 'GetAssetItemDropDownList?AssetCategoryId=' + 0, this.baseUrlType)
        this.AssetItemDroplist = this.response.data
      }
      this.getUomList()
    }
    if (this.data.action == 'add_New_contact') {
      let CountryDropdownemp = await this.Countrylist();
      this.CountryDropList = CountryDropdownemp;
      let Designation = await this.DesignationDropDownList();
      this.Designations = Designation.Result;
    }
    if (this.action == 'AddModeofConveyance') {
      this.saveConveyanceInput.ConveyanceTypeid = 1
    }
    if (this.action === 'AddCityName' && this.modalData.Data) {
      this.saveCityInput.CountryId = this.modalData.Data.Country.CountryId
      this.saveCityInput.StateId = this.modalData.Data.State.StateId

      this.getEditCityData();
    }

    if (this.action === 'receivePettyCash' || this.action == 'AdvancePayment') {
      let PORaisedBylist = await this.PORaisedByList();
      this.PORaisedByDropDown = PORaisedBylist;
      this.saveAdvanceData.CurrencyId = parseInt(this.userinput?.CompanyCurrencyId);
      this.saveAdvanceData.CurrencySymbol = this.userinput?.CompanyCurrencySymbol;

    }
    if (this.action === 'ReceivePayment') {
      this.saveAdvanceData.CurrencySymbol = this.modalData.data?.CurrencySymbol;
      this.saveAdvanceData.ReceivedAmount = this.modalData.data?.ReceivedAmount;
    }

    if (this.action === 'Addassetcategory' || this.action === 'Editassetcategory') {
      let AssetDroplist = await this.AssetClassDrop();
      this.AssetClassDropDown = AssetDroplist;
      console.log(this.AssetClassDropDown);
    }
    if (this.action == 'attributes' || this.action == 'addAttributes') {

      console.log(this.modalData.data);
      let AssetDroplist = await this.AssetattributeDrop();
      this.AssetattributeDropDownlist = AssetDroplist;
      console.log(this.AssetattributeDropDownlist);
    }
    if (this.action == 'viewattributes') {

      console.log(this.modalData);
      this.Assetattributeview = [...this.modalData.data.Attributes];
      console.log(this.Assetattributeview);
    }
    if (this.action == 'viewattribute') {

      console.log(this.modalData);
      this.Assetattributeview = [...this.modalData.data];
      console.log(this.Assetattributeview);
    }
    if (this.action == 'editAttributes') {
      console.log(this.modalData);

      this.AssetattributeDropDownlist = [...this.modalData.data.Attributes]
      console.log(this.AssetattributeDropDownlist);

    }
    if (this.action === 'Addassetitem' || this.action === 'Editassetitem') {
      console.log(this.saveAssetItemInput);

      let AssetDroplist = await this.AssetClassDrop();
      this.AssetClassDropDown = AssetDroplist;
    }
    if (this.action === 'AddDepartment' || this.action === 'EditDepartment') {
      this.deDepartmentCatDropdown();
    }
    // if (this.action === 'AddEntitlements' || this.action === 'EditEntitlements') {
    //   let ExpMonthlyCategoryList = await this.ExpMonthlyCategoryDropDownList();
    //   this.ExpMonthlyCategoryDropDown = ExpMonthlyCategoryList.Result;


    //   let EntitlementCodeDropDown: any = await this.EntitlementCodeDropDown();
    //   this.EntitlementCode = EntitlementCodeDropDown.Result;

    //   let Currencylist = await this.CurrencyList();
    //   this.CurrencyDropDown = Currencylist;


    // }

    if (this.action == 'travelExpense') {

      let ModeDropDown = await this.modetype()
      this.ModeDropDown = ModeDropDown.Result
      console.log(this.ModeDropDown);

    }
    if (this.action == 'monthlyExpense' || this.action == 'addrecurringExpense' || this.action == 'travelExpense' || this.action == 'accommodationExpense' || this.action == 'feedbevExpense' || this.action == 'generalExpense') {

      let Currencylist = await this.CurrencyList();
      this.CurrencyDropDown = Currencylist;
      if (this.action == 'accommodationExpense') {
        let Accomdationlist = await this.AccomdationDrop();
        this.accRadio = Accomdationlist.Result;
      }
      if (this.action == 'generalExpense') {
        let Expenselist = await this.ExpenseDrop();
        this.ExpenseLsits = Expenselist.Result;
      }
    }
    if (this.action === 'AddDesignation' || this.action === 'EditDesignation') {
      this.designationCatList();
    }
    if (this.action === 'AddSkillSet' || this.action === 'EditSkillSet') {
      this.SkillCategory();
    }
    if (this.action === 'AddProductType' || this.action === 'EditProductType') {
      this.productCategoryList();
    }
    if (this.action === 'AddSkillCategory' || this.action === 'EditSkillCategory') {
      this.departmentListFun();
    }
    if (this.action === 'AddProfitCenterDetails' || this.action === 'EditProfitCenterDetails') {
      this.profitCenterDetailsDrpList();
    }
    if (this.action === 'EditReason' || this.action === 'AddReason') {
      this.ReasonBusinessProcessList();
    }
    if (this.action === 'AddServiceType' || this.action === 'EditServiceType') {
      this.serviceTypeList();
      this.durationUnitList();
      this.hsnList();
    }
    if (this.action === 'Addassetitem' || this.action === 'Editassetitem') {

      this.durationUnitList();
      if (this.saveAssetItemInput?.CountingAuditFrequency == null) {
        this.saveAssetItemInput.CountingAuditFrequency = {}
      }
      if (this.saveAssetItemInput?.HealthCheckAuditFrequency == null) {
        this.saveAssetItemInput.HealthCheckAuditFrequency = {}
      }
    }

    if (this.action === 'AddStatus' || this.action === 'EditStatus') {
      this.BusinessProcessList();
    }
    if (this.action === 'EditServiceType') {
      this.serviceTypeList();
      this.durationUnitList();
      this.hsnList();
      console.log(this.modalData.Data);
      // this.saveServiceCategoryTypeInput = this.modalData.Data;
    }
    if (this.action === 'AddGeneric' || this.action === 'EditGeneric') {
      this.genericCategoryList();
    }
    if (this.action === 'AddProjectType' || this.action === 'EditProjectType') {
      this.projectTypeList();
      this.hsnList();
    }
    if (this.action === 'AddCostCenterCategory' || this.action === 'EditCostCenterCategory') {
      let SBHDropDownLists = await this.CostCenterCategorydropdown();
      this.CostCenterCategorydropdownList = SBHDropDownLists;
    }
    if (this.action === 'AddCampaignType' || this.action === 'EditCampaignType') {
      this.campaignDroupdownList();
    }
    if (this.action === 'AddGenericCategory' || this.action === 'EditGenericCategory') {
      this.genericCategoryList();
    }
    if (this.action === 'AddWorkingSchedule' || this.action === 'EditWorkingSchedule') {
      // this.isWorkingSchedule = false;
      this.workingScheduleListList();
    }
    if (this.action === 'AddWeeklyOff' || this.action === 'EditWeeklyOff') {
      this.weeklyOffList();
    }
    if (this.action === 'AddHolidays' || this.action === 'EditHolidays') {
      this.holidayList();
    }
    if (this.action === 'AddCostCenter' || this.action === 'EditCostCenter') {
      this.costCenterCategoryList();
    }
    if (this.action === 'AddProfitCenterDetails' || this.action === 'EditProfitCenterDetails') {
      this.profitCenterDetailsDrpList();
    }
    if (this.action === 'AddProjectActivity' || this.action === 'EditProjectActivity') {
      this.ActivityDrpList('AddProjectActivity');
    }
    if (this.action === 'AddServiceActivity' || this.action === 'EditServiceActivity' || this.action === 'AddServiceSubActivity' || this.action === 'EditServiceSubActivity') {
      this.ActivityDrpList('AddServiceActivity');
      this.SkillCategory();
    }
    if (this.action === 'EditServiceSubActivity') {
      this.saveServiceSubActivityInput = this.data.Data;
      if (this.saveServiceSubActivityInput.EstimationGroup != null) {
        this.saveServiceSubActivityInput.IsEstimationgroup = true;
      }
    }
    if (this.action === 'AddProjectSubActivity' || this.action === 'EditProjectSubActivity') {
      this.ProjectDrpList(this.action);

      if (this.action === 'EditProjectSubActivity') {
        this.EditProjectSubActivity();
      }

    }
    if (this.action === 'AddServiceTask' || this.action === 'EditServiceTask' || this.action === 'addDeliverable') {
      this.ServiceDrpList(this.action);
    }
    if (this.action === 'EditServiceTask') {
      this.saveServiceTaskInput = this.data.Data;
      if (this.saveServiceTaskInput.EstimationGroup != null) {
        this.saveServiceTaskInput.IsEstimationgroup = true;
      }
    }
    if (this.action === 'AddProjectTask' || this.action === 'EditProjectTask') {
      this.ProjectDrpList(this.action);
    }
    if (this.action === 'EditQualification') {
      this.EditModal('QualificationGetById', 'EditQualification', this.modalData.Data);
    }
    if (this.action === 'EditPaymentMode') {
      this.getPaymentMode(this.action);
    }

    if (this.action == 'ExitEmployee' || this.action == 'KnowledgeTransfer' || this.action == 'assignCampaign') {
      this.exEmployee(0);
      this.getDropdownList();
    }
    if (this.action == 'assignTask') {
      this.staffingList(0);
    }
    if (this.action == 'assignTask') {
      this.EmployeeList(0);
      this.getDropdownList();
    }
    if (this.action == 'ExitEmployee' || this.action == 'GetEmployeeList' || this.action == 'KnowledgeTransfer' || this.action == 'assignCampaign') {
      this.Dep(0);
    }

    if (this.action == 'AddServiceCategory' || this.action == 'EditServiceCategorys') {
      let SBHDropDownLists = await this.SBHDropDown();
      this.SBHDropDownLists = SBHDropDownLists.Result;
      console.log(this.SBHDropDownLists)

    }
    if (this.action == "addExtend") {
      this.spinner.show()
      let monthDropDown = await this.MonthDropDownList()
      this.monthDropDown = monthDropDown.Result;
      this.ExtendMonth = monthDropDown.Result.filter(x => x.Month == this.modalData.data.ProbationPeriod)[0]
      console.log(this.ExtendMonth);

      this.getLastDate()
      this.spinner.hide()

    }
    // let Currencylist = await this.CurrencyList();
    // this.CurrencyDropDown = Currencylist;
    if (this.action == 'transferAssetInv') {
      let persondata = await this.dropdowncompanybranch();
      this.companybrach = persondata;
      this.currentLocation = this.companybrach.filter(x => x.BranchId === this.pasModalData?.locationId)[0].BranchName;
      console.log(this.currentLocation);
    }
  }
  DesignationDropDownList(): Promise<any> {
    return this.dropdownService.DesignationList({}).toPromise()
  }
  dropdowncompanybranch(): Promise<any> {
    return this.dropdownService.branchListDropDownList({}).toPromise();
  }
  CurrencyList(): Promise<any> {
    return this.dropdownService.CurrencyDropDownList({}).toPromise();
  }
  AccomdationDrop(): Promise<any> {
    return this.dropdownService.AccomdationDropDown({}).toPromise();
  }
  ExpenseDrop(): Promise<any> {
    return this.dropdownService.ExpenseDropDown({}).toPromise();
  }
  MonthDropDownList(): Promise<any> {
    return this.dropdownService.MonthDropDownList({}).toPromise()
  }
  SBHDropDown(): Promise<any> {
    return this.dropdownService.SBHDropDownList({}).toPromise()
  }

  AssetClassDrop(): Promise<any> {
    return this.dropdownService.GetAssetClassDropDownList({}).toPromise()
  }
  AssetattributeDrop(): Promise<any> {
    console.log(this.modalData.data);

    return this.dropdownService.AssetattributeDropDown(this.modalData?.data, {}).toPromise()
  }

  AssetCategoryDropDown(): Promise<any> {
    return this.dropdownService.GetAssetCategoryDropDownList({}).toPromise()
  }
  AssetItemDropDown(): Promise<any> {
    return this.dropdownService.GetAssetItemDropDownList({}).toPromise()
  }
  ExpMonthlyCategoryDropDownList(): Promise<any> {
    return this.dropdownService.MonthlyCategoryDropDownList({}).toPromise()
  }

  async EditProjectSubActivity() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'ProjectSubActivityGetById', this.baseUrlType)
    this.saveProjectSubActivityInput = this.response.data.Result;

    this.response = await this.masterService.companyCorepostService({}, 'ProjectActivityDropDownList?CategoryId=' + this.response.data.Result.Category.CategoryId)
    if (this.response.data && this.response.data.length) {
      this.ProjectCatActivityHeadDropDownList = this.response.data;
    }

  }

  getUomList() {
    this.masterService.postService({}, "GetUnitOfMeasurementDropDownList")
      .subscribe((response) => {
        this.UOMList = response.body;
      });
  }
  async EditUnit() {
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'DurationUnitGetById', this.baseUrlType)
    this.SaveUnit = this.response.data.Result;
  }
  async deDepartmentCatDropdown() {
    this.response = await this.masterService.asyncPostService({}, 'DepartmentCategoryDropDownList', this.baseUrlType)
    this.departmentList = this.response.data.Result;
  }
  async saveProfitCenterDetails() {
    this.response = await this.masterService.asyncPostService(this.saveProfitCenterDetailsInput, 'ProfitCenterDetailSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }

  async saveStatus() {
    this.response = await this.masterService.asyncPostService(this.saveStatusInput, 'StatusSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveBusiness() {
    this.response = await this.masterService.asyncPostService(this.saveBusinessInput, 'BusinessProcessSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }

  async saveTax() {
    this.response = await this.masterService.asyncPostService(this.saveTaxInput, 'TaxSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveAssestItem() {
    this.response = await this.masterService.asyncPostService(this.saveAssestItemInput, 'AssetSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }

  //save Campaign group start here
  async saveCampaignGroup() {
    this.response = await this.masterService.asyncPostService(this.saveCampaignGroupInput, 'CampaignGroupSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async getEditCountryData() {
    await this.getCountryfn();
    console.log(this.modalData);

    this.response = await this.masterService.newpostService('', 'CityDropDownList?StateId=' + this.modalData.Data.State.StateId)
    this.CityList = await this.response.data;
    this.response = await this.masterService.newpostService('', 'StateDropDownList?CountryId=' + this.modalData.Data.Country.CountryId)
    this.StateList = await this.response.data;
    this.response = await this.masterService.asyncPostService(this.modalData.Data, 'CityAreaGetById', this.baseUrlType)
    console.log(this.response);
    this.CityAreaInput = await this.response.data.Result;
  }
  async getEditCityData() {
    console.log(this.modalData.Data);
    await this.getCountryfn();
    console.log(this.CountryList);

    this.response = await this.masterService.newpostService('', 'StateDropDownList?CountryId=' + this.modalData.Data.Country.CountryId)
    console.log(this.response);

    this.StateList = this.response.data;

    if (this.modalData.Data.CityId) {
      console.log(this.modalData.Data.CityId);
      this.response = await this.masterService.asyncPostService(this.modalData.Data, 'CityGetById', this.baseUrlType)
      this.saveCityInput = this.response.data.Result;
    }
  }
  getGenericTypeDropDownList() {
    this.masterService
      .postService('', 'GenericTypeDropDownList')
      .subscribe((response) => {
        this.GenericTypeDropDownListNew = response.body.Result.GenericTypeList;
      });

  }
  getSkillCategory() {
    this.masterService
      .postService('', 'KeySkillDropDownList')
      .subscribe((response) => {
        this.SkillCategory = response.body.Result.SkillCategoryDropDownList;
      });
  }
  StateListfn() {
    this.spinner.show();
    const CountryId = this.CityAreaInput.Country.CountryId || this.selectCountry.CountryId;
    this.masterService
      .postService('', 'StateDropDownList?CountryId=' + CountryId)
      .subscribe((response) => {
        this.spinner.hide();
        this.StateList = response.body;
      });
  }
  SelectStateList(a) {
    console.log(a);

    this.spinner.show();
    this.masterService
      .postService('', 'StateDropDownList?CountryId=' + a.value)
      .subscribe((response) => {
        this.spinner.hide();
        this.StateList = response.body;
      });
  }
  CityListfn() {
    this.spinner.show();
    const StateId = this.CityAreaInput.State.StateId;
    this.masterService
      .postService('', 'CityDropDownList?StateId=' + StateId)
      .subscribe((response) => {
        this.spinner.hide();
        this.CityList = response.body;
      });
  }
  async saveUserRoles() {
    this.response = await this.masterService.asyncPostService(this.saveUserRoleInput, 'UserRoleSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async updateUserRole() {
    this.response = await this.masterService.asyncPostService(this.saveUserRoleInput, 'UserRoleSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }

  async saveCityArea() {
    this.response = await this.masterService.asyncPostService(this.CityAreaInput, 'CityAreaSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }

  async saveNumberSystem() {
    this.response = await this.masterService.asyncPostService(this.NumberSystemINput, 'NumberSystemSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async updateNumberSystem() {
    this.response = await this.masterService.asyncPostService(this.NumberSystemINput, 'NumberSystemSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }


  async saveGenericTypeSave() {
    this.response = await this.masterService.asyncPostService(this.GenericTypeSaveInput, 'GenericTypeSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }

  async updateGenericTypeSave() {
    this.response = await this.masterService.asyncPostService(this.GenericTypeSaveInput, 'GenericTypeSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  /* SkillCategory() {
    this.masterService.employeeCoreService({}, 'KeySkillDropDownList')
      .subscribe(response => {
        this.SkillCategorylist = response.body.Result;
      });
  } */
  SkillCategory() {
    this.masterService.employeeCoreService({}, 'SkillCategoryDropDownList')
      .subscribe(response => {
        this.SkillCategorylist = response.body.Result;
      });
  }

  /* Save National start */
  async saveNational() {
    this.response = await this.masterService.asyncPostService(this.saveNationalityInput, 'NationalitySave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveLevel() {
    this.saveLevelInput.Weightage = this.saveLevelInput.Weightage.toString()
    this.response = await this.masterService.asyncPostService(this.saveLevelInput, 'LevelSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  /* Save Level End */

  /* Save Cadre start */
  async saveCadres() {
    this.response = await this.masterService.asyncPostService(this.saveCadreInput, 'CadreSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveBgInfo() {
    this.response = await this.masterService.asyncPostService(this.saveBgInfoInput, 'BackgroundInfoSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  /* Save Cadre End */

  /* Save Skill Category start */
  async saveSkillCategory() {
    this.response = await this.masterService.asyncPostService(this.skillCategoryInput, 'SkillCategorySave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  /* Save Skill Category End */
  /* Save Product type start */
  async saveProductType() {
    this.response = await this.masterService.asyncPostService(this.productTypeInput, 'ProductTypeSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  /* Save Product type End */
  /* ***************All DropDown list Start**************** */
  /*  Designation Category Dropdown list */
  designationCatList() {
    this.masterService.companyCoreService({}, 'DesignationCategoryDropDownList')
      .subscribe(response => {
        this.designationList = response.body.Result;
      });
  }
  /* Designation Category Dropdown list */
  /*  Product Category Dropdown list Start*/
  productCategoryList() {
    this.masterService.companyCoreService({}, 'ProductCategoryDropDownlist')
      .subscribe(response => {
        this.productListCategory = response.body.Result.ProductCategoryList;
      });
  }
  /*  Product Category Dropdown list End*/
  /*  Department Dropdown list */
  departmentListFun() {
    this.masterService.companyCoreService({}, 'SkillCategoryDepartmentDropDownList')
      .subscribe(response => {
        this.listOfDepartment = response.body.Result;
      });
  }
  /*  Department Dropdown list */
  /* ***************All DropDown list End**************** */
  /* Save Product Category start */
  async saveProductCategory() {
    this.response = await this.masterService.asyncPostService(this.productCategoryInput, 'ProductCategorySave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  /* Save Product Category End */
  async saveSkillSet() {
    this.response = await this.masterService.asyncPostService(this.SkillSetInput, 'KeySkillSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveGeneralTerm() {
    this.response = await this.masterService.asyncPostService(this.generalTermInput, 'GeneralTermSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }

  async saveInvoicePaymentTerm() {
    this.response = await this.masterService.asyncPostService(this.invoicePaymentTermInput, 'InvoicePaymentTermSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async AddServiceTask() {
    this.response = await this.masterService.asyncPostService({}, 'CategoryDropDownList', 'GlobalCore')
    this.ServiceTaskCategoryDropDownList = this.response.data;
    return this.response.data.Result;
  }
  async AddProjectTask() {
    this.response = await this.masterService.asyncPostService({}, 'CategoryDropDownList', 'GlobalCore')
    this.ProjectTaskCategoryDropDownList = this.response.data.Result;
    this.ProjectCatActivityDropDownList = this.response.data.Result;
  }
  async AddProjectsubActivity() {
    this.response = await this.masterService.asyncPostService({}, 'CategoryDropDownList', this.baseUrlType)
    this.ProjectCatActivityDropDownList = this.response.data.Result;
  }

  CatProjectSubActivityDrpList = async (event) => {
    const { value } = event
    this.response = await this.masterService.companyCorepostService({}, 'ProjectActivityDropDownList?CategoryId=' + value.CategoryId)
    if (this.response.data && this.response.data.length) {
      this.ProjectCatActivityHeadDropDownList = this.response.data;
    }
  }

  processPayment(){

  }

  ServiceDrpList = async (event) => {
    if (event == 'AddServiceTask' || event == 'addDeliverable') {
      let data = await this.AddServiceTask();
      if (event == 'addDeliverable') {
        this.saveServiceTaskInput.Category = this.modalData.customData.type;
        await this.ServiceTaskSelectCategoryType(this.modalData.customData.type, 'activity')
        if (this.modalData.customData.type.CategoryName == "Efforts") {
          await this.ServiceTaskSelectCategoryType(this.modalData.customData.tasks.category, 'subactivity');
        }
        this.saveServiceTaskInput.IsApproved = '0'
      }
    } else {
      let event;
      const { Data } = this.modalData
      await this.AddServiceTask()
      this.saveServiceTaskInput.Category = Data.Category
      event = { value: { CategoryId: Data.Category.CategoryId } }
      await this.ServiceTaskSelectCategory(event, 'activity')
      this.saveServiceTaskInput.ServiceActivity = Data.ServiceActivity
      this.saveCatId = Data.Category.CategoryId
      event = { value: { ServiceActivityId: Data.ServiceActivity.ServiceActivityId } }
      await this.ServiceTaskSelectCategory(event, 'subactivity')
      this.saveServiceTaskInput.ServiceSubActivity = Data.ServiceSubActivity
      this.saveServiceTaskInput.ServiceTaskName = Data.ServiceTaskName
    }

  }
  ProjectDrpList = async (event) => {
    if (event == 'AddProjectTask' || event == 'AddProjectSubActivity' || event == 'EditProjectSubActivity') {
      await this.AddProjectTask();

    }
    else {
      let event;
      const { Data } = this.modalData
      await this.AddProjectTask()
      this.saveProjectTaskInput.ProjectTaskId = Data.ProjectTaskId;
      this.saveProjectTaskInput.Category = Data.Category;
      event = { value: { CategoryId: Data.Category.CategoryId } }
      await this.ProjectTaskSelectCategory(event, 'activity')
      this.saveProjectTaskInput.ProjectActivity = Data.ProjectActivity
      this.saveCatId = Data.Category.CategoryId
      event = { value: { ProjectActivityId: Data.ProjectActivity.ProjectActivityId } }
      await this.ProjectTaskSelectCategory(event, 'subactivity')
      this.saveProjectTaskInput.ProjectSubActivity = Data.ProjectSubActivity
      this.saveProjectTaskInput.ProjectTaskName = Data.ProjectTaskName
    }

  }

  costCenterCategoryList() {
    this.masterService.postService({}, 'CostCenterDropDownList')
      .subscribe(response => {
        this.costCenterNameList = response.body.Result.CostCenterTypeNameList;
        this.costCenteCategoryList = response.body.Result.CostCenterCategoryList;
        this.costcenterEmployee = response.body.Result.EmployeeList;
        this.costCenterTypeNameLis = response.body.Result.CostCenterNameList;
      });
  }
  compareFn(a: any, b: any) {
    if (b) {
      const d = a[Object.keys(a)[0]];
      const e = b[Object.keys(b)[0]];
      return d === e;
    }
  }
  profitCenterDetailsDrpList() {
    this.masterService.postService({}, 'ProfitCenterDropDownlist')
      .subscribe(response => {
        this.profitNameList = response.body.Result.ProfitCenterNameList;
        this.profitEmpList = response.body.Result.EmployeeList;
      });
  }
  getEmployeeRecord = (id: number) => {
    this.masterService.postService(null, 'HeadOfCostCenterGetById?EmployeeId=' + id)
      .subscribe(response => {
        this.employeeRecordList = response.body.Result.HeadOfCostCenter;
      });
  }
  CostCenterCategorydropdown(): Promise<any> {
    return this.dropdownService.EmployeeDropDown({}).toPromise();
  }
  getServiceActivityDrpList = async (event: { value: any; }) => {
    let EstimationGroup = this.saveServiceSubActivityInput.EstimationGroup
    if (EstimationGroup == null) {
      EstimationGroup = "";
    }
    // if(this.saveServiceSubActivityInput?.Category?.CategoryId == null){
    //   this.saveServiceSubActivityInput.Category.CategoryId = ""
    // }
    const CategoryId = this.saveServiceSubActivityInput.Category.CategoryId
    this.response = await this.masterService.asyncPostService({}, 'ServiceActivityDropDownListByEstimationGroup?CategoryId=' + CategoryId + '&EstimationGroup=' + EstimationGroup, 'ServiceCore')
    if (this.response.data) {
      this.ServiceCatActivityDropDownList = this.response.data;
    }
  }
  getServiceActivityDrpListForTask = async (event: { value: any; }) => {
    let EstimationGroup = this.saveServiceTaskInput.EstimationGroup
    if (EstimationGroup == null) {
      EstimationGroup = "";
    }
    const CategoryId = this.saveServiceTaskInput?.Category?.CategoryId
    this.response = await this.masterService.asyncPostService({}, 'ServiceActivityDropDownListByEstimationGroup?CategoryId=' + CategoryId + '&EstimationGroup=' + EstimationGroup, 'ServiceCore')
    if (this.response.data) {
      this.ServiceActivityList = this.response.data;

    }
  }
  conveyanceType(event) {
  }



  ActivityDrpList = async (event) => {
    this.response = await this.masterService.asyncPostService({}, 'CategoryDropDownList', 'GlobalCore')
    switch (event) {
      case 'AddProjectActivity':
        this.ProjectActivityDropDownList = this.response.data;
        break;
      case 'AddServiceActivity':
        this.ServiceActivityDropDownList = this.response.data;
        if (this.modalData) {
          console.log(this.modalData);

          const { Data } = this.modalData
          if (Data != null) {
            this.saveServiceSubActivityInput.Category = Data.Category
            let event = { value: { CategoryId: Data?.Category?.CategoryId } }
            await this.getServiceActivityDrpList(event)
            console.log(event);

            for (let i = 0; i < this.ServiceCatActivityDropDownList.length; i++) {
              let obj = this.ServiceCatActivityDropDownList[i]
              if (obj.ServiceActivityName == Data.ServiceActivity?.ServiceActivityName) {
                this.saveServiceSubActivityInput.ServiceActivity = this.ServiceCatActivityDropDownList[i]
              }
            }
            //this.saveServiceSubActivityInput.ServiceActivity = this.ServiceCatActivityDropDownList[0]
            this.saveServiceSubActivityInput.ServiceSubActivityName = Data?.ServiceSubActivityName
          }
        }
    }
  }

  CatProjectActivityDrpList = async (a) => {
    this.response = await this.masterService.newpostService({}, 'ProjectActivityDropDownList?CategoryId=' + a)
    if (this.response.data.Result && this.response.data.Result.ProjectActivityList.length) {
      this.ProjectCatActivityDropDownList = this.response.data.Result.ProjectActivityList;
    }
  }

  CatServiceActivityDrpList = (a) => {
    this.masterService.postService({}, 'ProjectActivityDropDownList?CategoryId=' + a)
      .subscribe(response => {
        this.ServiceCatActivityDropDownList = response.body.Result.ServiceActivityList;
      });
  }

  getEmployeeRecordProfit(data) {
    const EmpId = data.EmployeeId;
    this.masterService.postService({ IsApproved: 1, EmployeeId: EmpId }, 'HeadOfProfitCenterGetById?EmployeeId=2')
      .subscribe(response => {
        this.employeeRecordListProfit = response.body.Result.HeadOfProfitCenter;
      });
  }
  holidayList() {
    this.masterService.companyCoreService({}, 'GetCompanyShiftList')
      .subscribe(response => {
        this.holidayShiftList = response.body;
      });
  }
  ReasonBusinessProcessList() {
    this.masterService.postService({}, 'ReasonBusinessProcessDropDownList')
      .subscribe(response => {
        this.ReasonBusinessProcess = response.body.Result.BusinessProcessList;
      });
  }
  BusinessProcessList() {
    this.masterService.postService({}, 'BusinessProcessDropDownList')
      .subscribe(response => {
        this.BusinessProcessDropList = response.body.Result;
      });
  }
  weeklyOffList() {
    this.masterService.companyCoreService({}, 'GetCompanyShiftList')
      .subscribe(response => {
        this.shiftList = response.body;
      });
  }

  getpettycash(event) {
    this.saveAdvanceData.ChequeDate = null;
    this.saveAdvanceData.RefNo = null;
    this.saveAdvanceData.VoucherNo = null;
    console.log(event.value);
    if (event.value == 5) {
      this.coreService.pettycash(this.saveAdvanceData, 'GetPettyCashAvailableBalance').subscribe((response) => {
        this.pettycashamount = response.body.Result;
      })
    }
  }
  async serviceTypeList() {
    this.response = await this.masterService.asyncPostService({}, 'ServiceCategoryDropDownlist', this.baseUrlType)
    if (this.response.data.Result) {
      this.serviceList = this.response.data.Result;
      if (this.modalData && this.modalData.Data !== null) {
        const data = this.modalData.Data
        this.saveServiceCategoryTypeInput.ServiceCategory = data?.ServiceCategory
        this.saveServiceCategoryTypeInput.ServiceTypeName = data?.ServiceTypeName
        this.saveServiceCategoryTypeInput.SACCode = data?.SACCode
      }
    }
  }
  async hsnList() {
    this.response = await this.masterService.asyncPostService({}, 'HSNDropDownList', 'CompanyCore')
    if (this.response.data.Result) {
      this.HsnDropList = this.response.data.Result;
    }
  }
  async durationUnitList() {
    this.response = await this.masterService.asyncPostService({}, 'DurationUnitDropDownList', 'GlobalCore')

    if (this.response.data) {
      this.durationListData = this.response.data;
      console.log(this.response);
      console.log(this.durationListData);
    }
  }
  projectTypeList() {
    this.masterService.companyCoreService({}, 'ProjectCategoryDropDownList')
      .subscribe(response => {
        this.projectList = response.body.Result;
      });
  }
  genericCategoryList() {
    this.masterService.companyCoreService({}, 'GenericCategoryDropDownList')
      .subscribe(response => {
        this.genericCategorysList = response.body.Result.GenericCategoryList;
      });
  }

  workingScheduleListList() {
    this.masterService.companyCoreService({}, 'GetCompanyShiftList')
      .subscribe(response => {
        this.workingList = response.body;

      });
  }
  async getCountryfn() {
    this.response = await this.masterService.asyncPostService('', 'CountryDropDownList', this.baseUrlType)

    this.CountryList = this.response.data
  }
  // campaign module start
  campaignDroupdownList() {
    this.campaignService.postServiceSource({}, 'CampaignTypeDropDownList')
      .subscribe(response => {
      });
  }
  async saveQualification() {
    this.response = await this.masterService.asyncPostService(this.SaveQualificationType, 'QualificationTypeSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }

  async saveSegment() {
    this.response = await this.masterService.asyncPostService(this.SaveCustmerSegment, 'CustomerSegmentSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }

  async saveReference() {
    this.response = await this.masterService.asyncPostService(this.SaveReferenceMode, 'ReferenceModeSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }

  getPaymentMode = async (event: { value: any; }) => {
    const { CategoryId } = event.value
    this.response = await this.masterService.newpostService({}, 'GetPaymentModeById?CategoryId=' + CategoryId)
    if (this.response.data.Result) {
      this.savePaymentModeData = this.response.data.Result.PaymentModeList;
    }
  }
  saveAdvancePayment() {
    this.dialogRef.close(this.saveAdvanceData);
  }
  saveReceivedPayment() {
    this.dialogRef.close(this.saveAdvanceData);
  }
  async radioChange(event) {
    console.log(event);
    if (event?.value) {
      let PORaisedBylist = await this.PORaisedByList();
      this.PORaisedByDropDown = PORaisedBylist;
    } else {
      let PORaisedBylist = await this.VendorList();
      this.PORaisedByDropDown = PORaisedBylist;
    }
  }
  async saveProjectCategory() {
    this.response = await this.masterService.asyncPostService(this.saveProjectCategoryList, 'ProjectCategorySave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }

  async saveUserRole() {
    this.response = await this.masterService.asyncPostService(this.saveUserroleList, 'UserRoleSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }

  async saveProjectTypes() {
    this.response = await this.masterService.asyncPostService(this.SaveProjectType, 'ProjectTypeSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }

  async saveServiceCategory() {
    this.response = await this.masterService.asyncPostService(this.saveServiceCategoryInput, 'ServiceCategorySave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveServiceTask() {
    this.response = await this.masterService.asyncPostService(this.saveServiceTaskInput, 'ServiceTaskSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveServiceTaskEvt() {

    if (this.data.list.GeneralList && this.data.list.GeneralList.length > 0) {
      this.saveServiceTaskInput.EstimationGroup = "General";
    } else if (this.data.list.ThirdPartyList && this.data.list.ThirdPartyList.length > 0) {
      this.saveServiceTaskInput.EstimationGroup = "ThirdParty";
    }
    this.response = await this.masterService.asyncPostService(this.saveServiceTaskInput, 'ServiceTaskSave', this.baseUrlType);
    if (this.response.data.Success) {
      this.dialogRef.close(this.response);
    } else {
      await this.successOrFailureMsg(this.response);
    }
  }
  async saveProjectTask() {
    this.response = await this.masterService.asyncPostService(this.saveProjectTaskInput, 'ProjectTaskSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveServiceType() {
    this.response = await this.masterService.asyncPostService(this.saveServiceCategoryTypeInput, 'ServiceTypeSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }

  async saveGenericType() {
    this.response = await this.masterService.asyncPostService(this.saveGenericCategoryTypeInput, 'GenericTypeSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveReg() {
    this.response = await this.masterService.asyncPostService(this.saveRegInput, 'ResignationReasonSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async otheract() {
    this.response = await this.masterService.asyncPostService(this.otheractInput, 'SaveOtherActivity', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveTer() {
    this.response = await this.masterService.asyncPostService(this.saveTerInput, 'TerminationReasonSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveOccupation() {
    this.response = await this.masterService.asyncPostService(this.saveOccupationInput, 'OccupationSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveProfitCenterDetail() {
    this.response = await this.masterService.asyncPostService(this.saveProfitCenterDetailsInputs, 'ProfitCenterDetailSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  assetattribute() {
    this.dialogRef.close(this.AssetattributeDropDownlist)
  }

  negotiateSubmit() {
    this.dialogRef.close(this.negotiateModal)
  }
  async saveMaster(payload, apiName) {
    if (this.action == 'addAdvanceCash') {
      payload.AdvanceRequestAmount = payload.RequestAmount
    }
    if (this.action == 'receivePettyCash') {
      this.pettycash.ReceivedAmount = this.modalData?.data?.ApprovedAmount
      this.pettycash.PettyCashId = this.modalData?.data?.PettyCashId
    }
    if (this.deleteMonthlyEntitlementList.length > 0) {
      this.DeleteMonthlyEntitlement(this.deleteMonthlyEntitlementList, this.baseExpenseConfg + 'DeleteMonthlyExpenseEntitlementsCode')
    }

    // console.log( saveWorkingSchedule.FromTime > saveWorkingSchedule.ToTime );
    console.log(payload);
    if (this.action == 'AddWorkingSchedule') {
      if (payload.WorkingType == "one time" || payload.WorkingType == "repeat") {
        var variable = false
        if (this.one.valid && this.three.valid) {
          variable = true
        }
      }
      if (payload.WorkingType == "random") {
        var two = false
        if (this.two.valid) {
          two = true
        }
      }
      if (variable || two) {
        this.isMasterSubmit = true
      }
      else {
        this.isMasterSubmit = false
      }
    }

    if (this.isMasterSubmit) {
      this.spinner.show()
      this.apiService.postService(payload, apiName).subscribe(res => {
        this.spinner.hide()

        this.successOrFailureMsg(res)

      });
    }
  }

  async saveProfitCenter() {
    this.response = await this.masterService.asyncPostService(this.saveProfitCenterInput, 'ProfitCenterSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveCategory() {
    this.response = await this.masterService.asyncPostService(this.SaveCategory, 'CategorySave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveKeyFeature() {
    this.response = await this.masterService.asyncPostService(this.SaveKeyFeature, 'KeyFeatureSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async serviceKeyFeatureSave() {
    this.response = await this.masterService.asyncPostService(this.ServiceKeyFeatureSave, 'ServiceKeyFeatureSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }

  async projectKeyFeatureSave() {
    this.response = await this.masterService.asyncPostService(this.ProjectKeyFeatureSave, 'ProjectKeyFeatureSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveProjectActivity() {
    this.response = await this.masterService.asyncPostService(this.saveProjectActivityInput, 'ProjectActivitySave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveProjectSubActivity() {
    this.response = await this.masterService.asyncPostService(this.saveProjectSubActivityInput, 'ProjectSubActivitySave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveServiceSubActivity() {

    this.response = await this.masterService.asyncPostService(this.saveServiceSubActivityInput, 'ServiceSubActivitySave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }

  async saveServiceActivity() {
    this.response = await this.masterService.asyncPostService(this.saveServiceActivityInput, 'ServiceActivitySave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }

  async saveDurationsUnites() {
    this.response = await this.masterService.asyncPostService(this.SaveUnit, 'DurationUnitSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveCostCenter() {
    this.response = await this.masterService.asyncPostService(this.SaveCostCenter, 'CostCenterSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveGenericCategory() {
    this.response = await this.masterService.asyncPostService(this.saveGenericCategoryInputs, 'GenericCategorySave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveClient() {
    this.response = await this.masterService.asyncPostService(this.saveClientInputs, 'ClientSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveEmployeeCategory() {
    this.response = await this.masterService.asyncPostService(this.saveEmployeeCategoryInputs, 'EmployeeCategorySave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveCostcenterCategory() {
    this.response = await this.masterService.asyncPostService(this.saveCostCenterCategorySave, 'CostCenterSBHSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }
  async saveSourceData() {
    this.response = await this.masterService.asyncPostService(this.SourceDataSave, 'SourceDataSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }


  async saveCampaign() {
    this.response = await this.masterService.asyncPostService(this.campaignTypeDataSave, 'CampaignTypeSave', this.baseUrlType)
    await this.successOrFailureMsg(this.response)
  }

  async successOrFailureMsg(response) {
    console.log(response);

    if (response?.data?.Result?.Status == 200 || response?.body?.TransactionMessage?.Status == 200 || response?.body?.Result?.TransactionMessage?.Status == 200 || response?.data?.Result?.TransactionMessage?.Status == 200 || response?.body?.Status == 200 || response?.body?.Result?.Status == 200 || response?.data?.Status == 200) {
      if (this.action == 'AddCityName') {

        this.dialogRef.close(this.saveCityInput);
      }
      else {

        this.dialogRef.close(response);
      }

      const dialogRef = this.dialog.open(CommonModalComponent, {
        width: "300px",
        disableClose: true,
        data: { action: "success", statusText: response?.body?.TransactionMessage?.Message ? response?.body?.TransactionMessage?.Message : response?.data?.Result?.Message ? response?.data?.Result?.Message : response?.data?.Result?.TransactionMessage?.Message ? response?.data?.Result?.TransactionMessage?.Message : response?.body?.Result?.TransactionMessage?.Message ? response?.body?.Result?.TransactionMessage?.Message : response?.body?.Message ? response?.body?.Message : response?.body?.Result?.Message ? response?.body?.Result?.Message : response?.data?.Message }
      });
    } else {
      const dialogRef = this.dialog.open(CommonModalComponent, {
        width: "300px",
        disableClose: true,
        data: { action: "delete", statusText: response?.body?.TransactionMessage?.Message ? response?.body?.TransactionMessage?.Message : response?.data?.Result?.Message ? response?.data?.Result?.Message : response?.data?.Result?.TransactionMessage?.Message ? response?.data?.Result?.TransactionMessage?.Message : response?.body?.Result?.TransactionMessage?.Message ? response?.body?.Result?.TransactionMessage?.Message : response?.body?.Message ? response?.body?.Message : response?.body?.Result?.Message ? response?.body?.Result?.Message : response?.data?.Message }
      });
    }

  }
  saveCampaignGroups() {
    this.spinner.show();
    this.campaignService.postServiceSource(this.saveCampaignGroupInput, 'CampaignGroupSave')
      .pipe(takeUntil(this.unsubscribe$)).subscribe(response => {
        this.spinner.hide();
        console.log(response);
        // tslint:disable-next-line:no-conditional-assignment
        if (response.body.Result.TransactionMessage.Status = 200) {
          this.dialogRef.close();
          this.dialogRefs = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            data: { action: 'success', statusText: response.body.Result.TransactionMessage.Message }
          });
        } else {
          this.snackBar.open(response.body.Result.TransactionMessage.Message, 'x', {
            panelClass: ['alert-danger'],
            duration: 4000
          });
        }
      });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  deviceclose() {
    this.dialogRef.close();
    if (this.action == 'genrateLink') {
      this.router.navigate(['/app/core/common/employee']);
    }
    if (this.action == 'GetClientAdd') {
      this.location.back();
    }
  }
  descriptionsave() {
    this.dialogRef.close(this.modalData?.data?.Description);
  }
  AddDate() {
    console.log(this.saveWorkingSchedule.RendomEntry.RendomDate);

    this.saveWorkingSchedule.RendomEntry.RendomDate.push('');
    console.log(this.saveWorkingSchedule.RendomEntry.RendomDate);
  }
  RemoveDate(i: number) {
    this.saveWorkingSchedule.RendomEntry.RendomDate.splice(i, 1)
    console.log(this.saveWorkingSchedule.RendomEntry.RendomDate);
  }

  AddBreak() {
    console.log(this.saveWorkingSchedule.BreakList);
    this.saveWorkingSchedule.BreakList.push({});
    console.log(this.saveWorkingSchedule.BreakList);

  }
  RemoveBreak(i: number) {
    this.saveWorkingSchedule.BreakList.splice(i, 1)
    console.log(this.saveWorkingSchedule.BreakList);

  }
  daydata(event, data) {
    if (event.checked) {
      this.holiday.RepeatEntry.Days.push(data.Days);
      this.saveHolidayInput.RepeatEntry.Days.push(data.Days);
      this.scheduleoff.RepeatEntry.Days.push(data.Days);
      this.saveWeeklyOffInput.RepeatEntry.Days.push(data.Days);
      this.saveWorkingSchedule.RepeatEntry.Days.push(data.Days);
    }
    //this.getYearList();
  }

  getDropdownList() {
    this.campaignService.globalpostService(null, 'CampaignDropDownList')
      .subscribe(response => {
        console.log(response);
        if (response.body.Success) {
          this.campainDepartment = response.body.DepartmentList;
          console.log(this.campainDepartment);

          this.campainempDepartment = response.body;
          this.costCenterList = response.body.CostCenterList;

        }
      });
  }
  Dep(id) {
    let depId;
    if (id.value && id.value.CostCenterId) {
      depId = id.value.CostCenterId;

    }
    else {
      depId = 0;
    }
    this.spinner.show();
    this.campaignService.postServiceEmpCam(null, 'CostCenterWiseEmployeeDropDownList?CostCenterId=' + depId)
      .subscribe(response => {
        console.log(response);
        this.spinner.hide();
        if (response.body.Success) {
          this.campainEmployee = response.body.EmployeeList;
          console.log(this.campainEmployee);
        }
      })
  }
  exEmployee(id) {
    let depId;
    if (id.value && id.value.CostCenterId) {
      depId = id.value.CostCenterId;
    } else {
      depId = 0;
    }
    this.spinner.show();
    let data = [];
    if (this.data.popupEmpIds) {
      data = this.data.popupEmpIds;
    }
    this.campaignService.postEmployee(data, 'ExitEmployeeDropDownList?CostCenterId=' + depId)
      .subscribe(response => {
        this.spinner.hide();
        if (response.body.Success) {
          this.exEmployeeList = response.body.EmployeeList;
          console.log(this.exEmployeeList);
        }
      })
  }
  EmployeeList(id) {
    let depId;
    if (id.value && id.value.CostCenterId) {
      depId = id.value.CostCenterId;
    } else {
      depId = 0;
    }
    this.spinner.show();
    let data = [];
    if (this.data.popupEmpIds) {
      data = this.data.popupEmpIds;
    }
    this.campaignService.postEmployee(data, 'EmployeeListForResouceAllocation?CostCenterId=' + depId)
      .subscribe(response => {
        this.spinner.hide();
        if (response.body.Success) {
          this.exEmployeeList = response.body.Result;
        }
      })
  }
  staffingList(id) {
    let CostCenterId;
    if (id == 0) {
      CostCenterId = 0;
    } else {
      CostCenterId = id.value.CostCenterId;
    }
    this.data.DateList.forEach(date => {
      date = new Date(date);
    });
    console.log(this.data);

    const data = {
      CostCenterId: CostCenterId,
      DateList: this.data.DateList
    };

    this.spinner.show();
    this.masterService.staffingPostService(data, 'StaffingResourceList').subscribe(response => {
      this.spinner.hide();
      if (response.body.Success) {
        this.mapResEmployee = response.body.Result;
      }
    }, error => {
      this.spinner.hide();
    })
  }
  SelectEstimationtab(event, data) {
    if (event.value.CategoryId == 2 || event.value.CategoryId == 3) {
      data.IsEstimationgroup = true;
      data.EstimationGroup = ''
    } else {
      data.IsEstimationgroup = false;
      data.EstimationGroup = null;
    }
  }
  //selectedUserInfo = []

  selectUser(value: any, user: any, id: any) {
    this.seExitEmp = user;
    this.isdisabled = false
    /* if (this.type) { */
    this.selectedUser = id
    this.selectedUserInfo = user;
    return
    /* } */
    /* if (value.checked) {
      this.selectedUser.push(user.EmployeeId);
      this.selectedUserInfo.push(user);
    } else {
      const removeIndex = this.selectedUser.findIndex(item => item === id);
      if (removeIndex !== -1) {
        this.selectedUser.splice(removeIndex, 1);
      }
    } */
  }

  addUser(action) {
    if (this.data.action == 'add_contact') {
      this.dialogRef.close(this.selectedUser);
      console.log(this.dialogRef)
    }
    if (this.data.action == 'ExitEmployee') {
      this.dialogRef.close();
      this.router.navigate(['/app/core/common/exit-employee/add']);
      console.log(this.selectedUserInfo);
      const data = JSON.stringify(this.selectedUserInfo)
      console.log(data);
      sessionStorage.setItem('exitEmpData', data);
    }
    if (this.data.action == 'KnowledgeTransfer' || this.data.action == 'assignCampaign') {
      this.dialogRef.close(this.selectedUserInfo);
    }
    if (this.data.action == 'assignTask') {
      const data = {
        EmployeeId: this.selectedUser,
        DateList: this.data.DateList
      };
      const dates = [];
      for (let date of this.data.DateList) {
        dates.push(new Date(date));
      }
      const minDate = new Date(Math.min(...dates));
      const maxDate = new Date(Math.max(...dates));
      const allData = {
        employeeName: this.selectedUserInfo?.EmployeeName,
        employee: this.selectedUserInfo,
        startDate: minDate,
        endDate: maxDate
      }
      this.spinner.show();
      this.masterService.staffingPostService(data, 'CheckOccupancyForStaffing').subscribe(response => {
        this.spinner.hide();
        if (response.body.Success) {
          if (response.body.Result && response.body.Result.length > 0) {
            this.dialogRef.close();
            const dialogRef = this.dialog.open(ModalComponent, {
              disableClose: false,
              panelClass: ['modal-scroll', 'assignment-modal'],
              data: { action: 'staffingOcc', occData: response.body.Result, resData: allData }
            })
          } else {
            this.dialogRef.close(this.selectedUserInfo);
          }
        }
      }, error => {
        this.spinner.hide();
      })
    }

    /* this.dialogRef = this.dialog.open(ModalpopupComponent, {
      width: "600px",
      panelClass: "modal-scroll",
      autoFocus: false,
      disableClose: true,
      data: { modalTitle: 'Add Remarks', action: 'enterRemarks', data: this.seExitEmp },
    }); */
    /* if (action == 'GetEmployeeList') {
      this.dialogRef.close(this.selectedUserInfo);
      return;
    }
    this.dialogRef.close(this.selectedUser); */
  }
  deleteEmployee() {
    this.saveRemarkInput.EmployeeId = this.modalData.data.EmployeeId
    this.dialogRef.close(this.saveRemarkInput);
  }
  skillSetRemove(i) {
    this.saveServiceActivityInput.SkillCategoryList.splice(i, 1);
    let makeSkillData = []
    this.saveServiceActivityInput.SkillCategoryList.map(res => {
      makeSkillData.push(
        {
          SkillCategoryId: res.SkillCategoryId,
          Department: res.Department,
          SkillCategoryName: res.SkillCategoryName,
          SkillCategoryCode: res.SkillCategoryCode,
        }
      )
    });
    this.skillSelectField.update.emit(makeSkillData);
  }

  getSBHName(event) {
    this.saveServiceCategoryInput.CostCenterCategory.StrategicBusinessHead = event.value.StrategicBusinessHead;
  }
  //Submit Deliveable
  onSubmitDel() {
    this.dialogRef.close(this.modelDeliver);
  }
  addMoreComment() {
    if (this.riskForm.valid) {
      this.modelDeliver.Risks.push({});
    }
  }

  removeComment(array, i) {
    array.splice(i, 1)

  }
  UploadMultiple(event, data) {
    this.appService.imageUpload(event, data).subscribe(res => {
      if (res.errorText == '') {
        if (res.percentDone == 100) {
          const document: any = {}
          document.DocumentId = 0
          document.DocumentFileName = res.DocumentFileName;
          const fileExe = document.DocumentFileName.split('.').pop();
          document.FileData = res.FileData;
          document.imagePreview = res.imagePreview;
          document.FileExe = fileExe;
          this.modelDeliver.AttachFiles.push({ ...document });
          this.fileSizeError = '';
        }
      } else {
        this.fileSizeError = res.errorText;
      }
    });
  }
  uploadRemoveMultiple(data, i) {
    data.splice(i, 1)
  }
  downloadMyFile(data, b) {
    console.log(data);
    console.log(b);

    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    if (data?.FileData) {
      link.setAttribute('href', data?.Preview ? data?.Preview : data?.imagePreview);
    } else {
      this.masterService.getDocument({ DocumentId: data?.DocumentId }).subscribe(res => {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'data:image/png;base64,' + res?.body?.documentData);
        // link.setAttribute('href', data.DocumentPath);
        link.setAttribute('download', data?.DocumentFileName ? data?.DocumentFileName : b);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
    }
    link.setAttribute('download', data?.DocumentFileName ? data?.DocumentFileName : b);
    document.body.appendChild(link)
    link.click();
    link.remove();
  }


  downloadMyFileOther(data) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    if (data.FileData) {
      link.setAttribute('href', data.Preview);
    } else {
      this.coreService.getDocument({ DocumentId: data?.DocumentId }).subscribe(res => {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'data:image/png;base64,' + res?.body?.documentData);
        // link.setAttribute('href', data.DocumentPath);
        link.setAttribute('download', data.DocumentFileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
    }
    link.setAttribute('download', data.DocumentFileName);
    document.body.appendChild(link)
    link.click();
    link.remove();
  }
  onClick(event) {
    this.appService.filesizeValidation(event).subscribe(res => this.fileSizeError = res);

    if (this.fileSizeError == '') {

      const files = event.target.files;
      const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
      // fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({
          data: file, state: 'in',
          inProgress: false, progress: 0, canRetry: false, canCancel: true
        });
      }
      // this.uploadFiles();
      // };
      //fileUpload.click();
    }
  }
  public files: any = [];

  copyTextValue() {
    this.copyText = 'Copied';
    setTimeout(() => {
      this.copyText = 'Copy';
    }, 2000)
  }
  nextTab(event) {
    this.selectedIndex += 1;


  }
  SaveRequest(Type) {
    this.spinner.show();
    let data = {
      "LeaveId": this.leaveId,
      "ButtonType": Type
    }
    let ApproveUrl
    if (this.modalData.type == 'ApprovebyPM') {
      ApproveUrl = 'PMLeaveResponce'
    } else {
      ApproveUrl = 'ReportingPersonLeaveResponce'
    }
    this.leaveservice.postService({ ...data }, ApproveUrl)
      .subscribe(response => {
        this.dialogRef.close(response);
        this.spinner.hide();

      });
  }
  getLastDate() {

    let dates = new Date(this.modalData.data.DateOfJoining)
    this.modalData.data.ProbationEndDate = new Date(dates.setMonth(dates.getMonth() + parseInt(this.ExtendMonth.Month)))

  }
  Extend() {
    this.spinner.show();
    let data = {
      "EmployeeId": this.modalData.data.EmployeeId,
      EmployeeOfficialDetail: {
        ProbationPeriod: this.ExtendMonth.Month,
        ProbationEndDate: this.modalData.data.ProbationEndDate

      }
    }
    this.campaignService.postEmployee(data, 'UpdateProbationPeriod')
      .subscribe(response => {
        this.spinner.hide();
        if (response.body.Success) {
          this.dialogRef.close(response);

        }
      })

  }
  sandwichcancel(sandlist) {
    let payload =
    {
      "LeaveYear": this.modalData.data.LeaveYear,
      "LeaveMonth": this.modalData.data.LeaveMonth,
      "Company": {
        "CompanyId": this.input.CompanyId

      },
      "SandWichDate": [
        {
          "SandWichId": sandlist.SandWichId,
          "FromDate": sandlist.FromDate,
          "EndDate": sandlist.EndDate,
          "Status": sandlist.Status
        }
      ],
      "Employee": {
        "EmployeeId": this.modalData.data.Employee.EmployeeId
      }
    }
    this.spinner.show()
    this.campaignService.postServiceDirect(payload, 'EmployeeCore/api/InOut/SandwichCancel').subscribe(response => {
      this.spinner.hide()

      if (response.body.Success) {

        this.dialogRef.close()
        this.dialogre = this.dialog.open(CommonModalComponent, {
          width: "300px",
          disableClose: true,
          panelClass: 'statusClass',
          autoFocus: false,
          data: { action: 'success', statusText: response.body.Records.TransactionMessage.Message }

        })

      }
    })
  }
  mainfrequency() {
    this.saveAssetItemInput.MaintenanceFrequency.push({})
    console.log(this.saveAssetItemInput.MaintenanceFrequency);
  }
  removeDetail(MaintenanceFreq, i) {
    console.log(MaintenanceFreq);

    const dialogRef = this.dialog.open(CommonModalComponent, {
      width: '300px',
      disableClose: true,
      autoFocus: false,
      panelClass: 'statusClass',
      data: { action: 'delete', statusText: 'Are you sure you want to delete record' }
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        MaintenanceFreq.splice(i, 1);
      }
    })
  }
  add(event: MatChipInputEvent, i): void {
    const value = (event.value || '').trim();
    console.log(event);

    // Add our fruit
    if (value) {
      this.auditadd[i].scopelist.push({ name: value });
    }

    // Clear the input value
    event.input.value = ''
    event.value = ''
    // event.chipInput!.clear();
  }
  remove(scope: scopelist, i): void {
    const index = this.auditadd[i].scopelist.indexOf(scope);

    if (index >= 0) {
      this.auditadd[i].scopelist.splice(index, 1);
    }
  }
  freqadd(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();


    // Add our fruit
    if (value) {
      this.freqscopelist.push({ AssetAttributeName: value });
    }
    console.log(this.freqscopelist);

    // Clear the input value
    event.input.value = ''
    event.value = ''
    // event.chipInput!.clear();
  }

  freqremove(scope: freqscopelist): void {
    const index = this.freqscopelist.indexOf(scope);

    if (index >= 0) {
      this.freqscopelist.splice(index, 1);
    }
  }
  addmonthlist() {
    this.saveExpenseEntitlementInput.monthlylLmitEntitlementModels.push({})
  }
  rmvmonthlist = (index: number) => {
    if (this.saveExpenseEntitlementInput.monthlylLmitEntitlementModels[index]?.MonthlyEntitlementCodeid) {
      this.deleteMonthlyEntitlementList.push(this.saveExpenseEntitlementInput.monthlylLmitEntitlementModels[index]?.MonthlyEntitlementCodeid)
      console.log(this.deleteMonthlyEntitlementList);

    }
    this.saveExpenseEntitlementInput.monthlylLmitEntitlementModels.splice(index, 1)
  }
  DeleteMonthlyEntitlement(input, api) {
    this.spinner.show()
    this.apiService.postService(input, api)
      .subscribe(response => {
        this.spinner.hide()
        if (response.body.Result.TransactionMessage.Status = 200) {

          this.resultPopup('success', response.body.Result.TransactionMessage.Message);
        } else {

          this.resultPopup('Alert', response.body.Result.TransactionMessage.Message)
        }
      });
  }
  async savedisposeData() {
    this.spinner.show()
    let input = {
      "DisposeRemark": this.disposeData.DisposeRemark,
      "AssetId": this.disposeData.AssetId,
      "DisposeReasonId": this.disposeData.DisposeReasonId,
      "DisposedValue": this.disposeData.DisposedValue,
      "DisposeAttachment": this.disposeData.DisposeAttachment
    }
    this.response = await this.masterService.asyncPostService(input, 'AssetDisposeSave', 'Assetregistration')
    this.spinner.hide()
    console.log(this.response);

    if (this.response.data.Status == 200) {
      this.resultPopup('success', this.response.data.Message);
      this.dialogRef.close(true)
    } else {
      this.resultPopup('Alert', this.response.data.Message)
    }
  }
  resultPopup(action, message) {
    const dialogRef = this.dialog.open(CommonModalComponent, {
      width: '300px',
      disableClose: true,
      autoFocus: false,
      panelClass: 'statusClass',
      data: { action: action, statusText: message }
    });
  }

  auditfrequency(e) {
    console.log(e)
    this.saveAssetItemInput.MaintenanceFrequencyDuration = 0
    this.saveAssetItemInput.MaintenanceFrequencyunit = 0
    this.saveAssetItemInput.ScopeOfWork = null
  }
  async changeAssetClass(e) {
    this.response = await this.masterService.asyncPostService({ AssetClassId: e }, 'GetAssetCategoryDropDownList?AssetClass=' + e, this.baseUrlType)
    this.AssetCategoryList = this.response.data
  }
  async changeCategory(e) {
    this.saveAssetAttributeInput.AssetCategoryId = parseInt(e)
    if (this.saveAssetAttributeInput.AssetCategoryId == 0) {
      this.AssetItemDroplist = []
    }
    if (this.saveAssetAttributeInput.AssetCategoryId > 0) {
      this.response = await this.masterService.asyncPostService({}, 'GetAssetItemDropDownList?AssetCategoryId=' + e, this.baseUrlType)
      this.AssetItemDroplist = this.response.data
    }
  }
  async changeItem(e) {
    this.saveAssetAttributeInput.AssetItemId = parseInt(e.value)
  }
  auditfrcheck(e, data) {

    if (e?.checked) {
      if (data == 'isCounting') {
        // this.isCounting = true;
        this.saveAssetItemInput.IsCountingAuditFrequency = true
      }
      if (data == 'isHealth') {
        // this.isHealth = true;
        this.saveAssetItemInput.IsHealthCheckAuditFrequency = true
      }
      if (data == 'isConf') {
        // this.isConf = true;
        this.saveAssetItemInput.IsConfigurationAuditFrequency = true
      }
    } else {
      if (data == 'isCounting') {
        // this.isCounting = false;
        this.saveAssetItemInput.IsCountingAuditFrequency = false
        this.saveAssetItemInput.CountingAuditFrequency = {}
      }
      if (data == 'isHealth') {
        // this.isHealth = false;
        this.saveAssetItemInput.IsHealthCheckAuditFrequency = false
        this.saveAssetItemInput.HealthCheckAuditFrequency = {}
      }
      if (data == 'isConf') {
        // this.isConf = false;
        this.saveAssetItemInput.IsConfigurationAuditFrequency = false
        this.saveAssetItemInput.ConfigurationAuditFrequency = {}
      }
    }
  }
  reAssign(supportExtend, modaldata) {
    this.dialogRefP = this.dialog.open(AddContactModalComponent, {
      disableClose: true,
      panelClass: "modal-scroll",
      data: { action: 'supportReassign', data: supportExtend, data2: modaldata }
    });

    this.dialogRefP.afterClosed()
      .subscribe(res => {
        console.log(res);
        if (res) {
          if (res?.body?.Success) {
            this.spinner.hide();

            this.resultPopup('success', res?.body?.Message)
            this.dialogRef.close(res)

          } else {
            this.resultPopup('Alert', res?.body?.Message)

          }
        }

      });

  }
  saveextend(data) {
    this.spinner.show()
    this.campaignService.SupportMember({ srextendtimeid: data.srextendtimeid, srid: data.srid }, 'ExtendTimeRequestAccept').subscribe(res => {
      this.spinner.hide()
      res.case = "approve"
      this.dialogRef.close(res)
      console.log(res)
      // this.SupportMember = res.body.Item2
    })
  }

  selectedRating(sIndex) {
    this.selectedIndexRate = [];
    this.reviewNumber = sIndex + 1;
    for (let k = 0; k <= sIndex; k++) {
      this.selectedIndexRate.push(k)
    }
    this.employeeFeedBackSave.EmployeeRating = this.reviewNumberOver;

  }

  selectedRatingOver(sIndex) {
    this.selectedIndexOver = [];
    this.reviewNumberOver = sIndex + 1;
    for (let k = 0; k <= sIndex; k++) {
      this.selectedIndexOver.push(k)
    }
  }

  selectedRatingOverQty(sIndex) {
    this.selectedIndexOverQty = [];
    this.reviewNumberOverQty = sIndex + 1;
    for (let k = 0; k <= sIndex; k++) {
      this.selectedIndexOverQty.push(k)
    }
  }
  onSubmitEmpFeedback() {
    this.spinner.hide()
    this.feedbackRemark.EmployeeFeedbackId = this.pasModalData.id;
    this.apiService.postService(this.feedbackRemark, 'EmployeeCore/api/EmployeeFeedback/EmployeeRemarkSave').subscribe(response => {
      this.spinner.hide();
      if (response.body.Success) {
        this.resultPopup('success', response.body.Result.TransactionMessage.Message);
        this.dialogRef.close(this.feedbackRemark);
      } else {
        this.resultPopup('Alert', response.body.Result.TransactionMessage.Message)
      }
    })
  }
  DepartmentDropList(id) {
    let depId;
    if (id.value && id.value.DepartmentId) {
      depId = id.value.DepartmentId;
    } else {
      depId = 0;
    }
    this.spinner.show();
    this.campaignService.postPms(null, 'GetDepartmentWiseEmployeeList?DepartmentId=' + depId)
      .subscribe(response => {
        console.log(response)
        this.spinner.hide();
        if (response.body) {
          this.campainEmployeeList = response.body;
          /* const a = this.campainEmployee.sort((a) => {
            if (a.EmployeeName) { return -1; }
            if (a.EmployeeName) { return 1; }
            return 0;
          }) */
        }
      })
  }
  GetPoList(id:any){
    this.spinner.show();
    this.coreService.PurchaseRequestPO(null, 'PurchaseOrderDropDownList?VendorId=' + id)
      .subscribe(response => {
        console.log(response)
        this.spinner.hide();
        if (response.body) {
          this.polist = response.body.Result || [];
        }
      })
  }
  saveAddNew() {
    this.spinner.show();
    const payload = {
      ...this.addNewInput,
      RawDataId: this.rawId
    }
    this.CampaignService.postService(payload, 'AddNewContact')
      .subscribe(response => {
        this.spinner.hide();
        this.deviceclose();
        this.DesignationDropDownList();
        if (response.body.Success) {
          this.dialogRefs = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'success', statusText: response.body.Result.TransactionMessage.Message }
          });
        } else {
          this.dialogRefs = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'delete', statusText: response.body.Result.TransactionMessage.Message }
          });
        }
      });
  }
  Countrylist(): Promise<any> {
    return this.dropdownService.CountryDropDownlist({}).toPromise()
  }
  findCountryCode(event) {
    const id = this.CountryDropList.filter(x => x.CountryCode == event)[0];
    this.countryId = id.CountryId;
    this.mobileDigit = id.MobileDigit;
  }
  saveSpamData() {
    console.log(this.mspam);
    console.log(this.rawData);
    this.spinner.show();
    const payload = {
      ...this.modalData.data,
      ...this.mspam,
      IsSpam: true
    }
    this.CampaignService.postService(payload, 'MoveToSpam')
      .subscribe(response => {
        this.spinner.hide();
        console.log(response)
        this.deviceclose();
        if (response.body.Result.Success) {
          this.router.navigate(['/app/campaign/data-verification/spam-list']);
          this.dialogRefs = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'success', statusText: response.body.Result.Message }
          });
        } else {
          this.dialogRefs = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'delete', statusText: response.body.Result.Message }
          });
        }
      });
  }
  addDetailData(array, i) {
    if (array[i].scops) {
      array.push({});
    } else {
      array[i].value = true;
    }
  }

  removeDetailData(array, i) {
    array.splice(i, 1);
  }
  priority() {
    var d = new Date();
    this.assetitem.RequireDate = this.assetitem.RequisitionPriorityId == 2 ? moment(d).add(7, 'days') : this.assetitem.RequisitionPriorityId == 3 ? moment(d).add(2, 'days') : d
    console.log(this.assetitem.RequireDate);
  }
  changeisgov(eve) {
    console.log(eve)
    if (eve == 'true') {
      this.saveComplinceInput.ISGoverment = true
    }
    else {
      this.saveComplinceInput.ISGoverment = false

    }
  }
  saveTransferLocation() {
    this.dialogRef.close(this.transferToId)
  }

}
