export const TableColumn = [
    { data: '', Title: '#', width: 8, position: 1 },
    { data: '', Title: 'Code',width: 100, position: 2 },
    { data: '', Title: 'Item',width: 100, position: 3 },
    { data: '', Title: 'Item Name',width: 300, position: 4 },
    { data: '', Title: 'Cost Of Acquisition',width: 300, position: 5 },
    { data: '', Title: 'WDV',width: 85, position: 6 },
    { data: '', Title: 'Disposed Value',width: 85, position: 7 },
    { data: '', Title: 'Attachment',width: 85, position: 8 },
    { data: '', Title: 'Status',width: 85, position: 9},
    { data: 'Action', Title: 'Action', position: 10 },
];