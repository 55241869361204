import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { planworkConfig } from 'app/planwork.config';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonModalComponent } from './common-modal/common-modal.component';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  config: any;
  SpinnerTimeout = 500;
  ClientIP: any;
  IPAddress: any;
  apiUrl: any;
  apiUrlCampaign: any;
  private baseUrl: any;
  private otherActivityUrl: any;
  dialogRef: any;
  exceptionText = 'There are some exception error, Please contact to support team.';

  sharingData:any;
  supportUrl: string;
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) {
    this.apiUrl = planworkConfig;
    this.baseUrl = this.apiUrl.baseUrl + this.apiUrl.VendorBaseUrl + 'Vendor/';
    this.supportUrl = this.apiUrl.baseUrl + this.apiUrl.SupportBaseUrl + 'Master/';

  }

  commonService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrl + currentUrl, saveData, {
      headers: httpHeaders,
      observe: 'response'
    }).pipe(
      catchError(err => {
        this.spinner.hide();
        this.dialogRef = this.dialog.open(CommonModalComponent, {
          width: '300px',
          disableClose: true,
          autoFocus: false,
          panelClass: 'statusClass',
          data: { action: 'delete', statusText: this.exceptionText }
        });
        return throwError(err);
      })
    );
  }
  SupportService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.supportUrl + currentUrl, saveData, {
      headers: httpHeaders,
      observe: 'response'
    }).pipe(
      catchError(err => {
        this.spinner.hide();
        this.dialogRef = this.dialog.open(CommonModalComponent, {
          width: '300px',
          disableClose: true,
          autoFocus: false,
          panelClass: 'statusClass',
          data: { action: 'delete', statusText: this.exceptionText }
        });
        return throwError(err);
      })
    );
  }
  saveData(Data){
    this.sharingData = Data;
  }
  deleteData(){
    this.sharingData = [];
  }
  getData(){
    return this.sharingData;
  }
}
