import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonModalComponent } from 'app/main/core/common/common-modal/common-modal.component';
import { Router } from '@angular/router';
import { RegistercompanyService } from './registercompany.service';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ServiceService } from 'app/main/core/masterservice/service.service';
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};
@Component({
  selector: 'app-register-company',
  templateUrl: './register-company.component.html',
  styleUrls: ['./register-company.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class RegisterCompanyComponent implements OnInit {
  dialogRef: any;
  imagePreview: any;
  RegisterCompanyInput: any = {
    CompanyType: {
      CompanyTypeId: '',
      CompanyTypeName: ''
    },
    IndustryType: {
      IndustryTypeId: '',
      IndustryTypeName: ''
    },
    NatureOfBusiness: {
      NatureOfBusinessId: '',
      NatureOfBusinessName: ''
    },
    Incorporation: {},
    BusinessProcessList: [],
  }
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  @ViewChild('f', { static: false }) firstForm: any;
  @ViewChild('f2', { static: false }) SecondForm: any;
  @ViewChild('f3', { static: false }) thirdForm: any;
  ShowBasic = true;
  ShowConfiguration = false;
  BusinessProcessList: {};
  NatureOfBusinessList: {};
  CompanyTypeList: {};
  IndustryTypeList: {};
  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private coreService: ServiceService,
    private service: RegistercompanyService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.ShowBasic = true;
    this.ShowConfiguration = false;
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });
    this.getBusinessProcessList();
  }
  checkOption(evt, item, i) {
    if (evt.checked == true) {
      this.RegisterCompanyInput.BusinessProcessList.push(item);
      console.log(this.RegisterCompanyInput.BusinessProcessList);
    }
    else {
      for (let k = 0; k < this.RegisterCompanyInput.BusinessProcessList.length; k++) {
        if (this.RegisterCompanyInput.BusinessProcessList[k].BusinessProcessId == item.BusinessProcessId) {
          this.RegisterCompanyInput.BusinessProcessList.splice(k, 1);
        }
      }
    }
  }
  getBusinessProcessList() {
    this.service.CommonService(null, 'LicenseDropDownList')
      .subscribe(response => {
        this.BusinessProcessList = response.body.BusinessProcessList;
        this.NatureOfBusinessList = response.body.NatureOfBusinessList;
        this.CompanyTypeList = response.body.CompanyTypeList;
        this.IndustryTypeList = response.body.IndustryTypeList;
      });
  }
  firstSaveData() {
    this.firstForm = true;
  }
  secondSaveData() {
    this.SecondForm = true;
  }
  thirdSaveData() {
    if (this.thirdForm.valid) {
      this.ShowBasic = false;
      this.ShowConfiguration = true;
      this.thirdForm = true;
    }
  }
  finalSubmit() {
    this.RegisterCompanyInput.DealsIn = '';
    this.RegisterCompanyInput.LicenceDepartment = '';
    this.service.postService(this.RegisterCompanyInput, 'CompanySignUpSave')
      .subscribe(response => {
        if (response.body.Success) {
          this.dialogRef = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'success', statusText: response.body.Result.TransactionMessage.Message }
          });
          this.dialogRef.afterClosed().subscribe((response) => {
            if (response) {
              this.router.navigate['/']
            }
          });
        }
      })
  }
  resumeUpload(event, data) {
    const files = event.target.files;
    const file = files[0];
    data.CertificateImageName = file.name;
    if (files && file) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagePreview = e.target.result;
        let b;
        b = this.imagePreview.split('base64,');
        data.imagePreview = this.imagePreview;
        data.CertificateImagePath = b[1];
      }
      reader.readAsDataURL(file);
    }
  }
  downloadMyFile(data, b) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    if (data.FileData) {
      link.setAttribute('href', data.Preview);
    } else {
      this.coreService.getDocument({ DocumentId: data?.DocumentId }).subscribe(res => {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'data:image/png;base64,' + res?.body?.documentData);
        // link.setAttribute('href', data.DocumentPath);
        link.setAttribute('download', data.DocumentFileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
    }
    link.setAttribute('download', data.DocumentFileName);
    document.body.appendChild(link)
    link.click();
    link.remove();
  }
  CompanyTypeSelect(e) {
    this.RegisterCompanyInput.CompanyType.CompanyTypeId = e.value;
    this.RegisterCompanyInput.CompanyType.CompanyTypeName = e.source.selected._element.nativeElement.innerText.trim();
  }
  IndustryTypeSelect(e) {
    this.RegisterCompanyInput.IndustryType.IndustryTypeId = e.value;
    this.RegisterCompanyInput.IndustryType.IndustryTypeName = e.source.selected._element.nativeElement.innerText.trim();
  }
  NatureOfBusinessSelect(e) {
    this.RegisterCompanyInput.NatureOfBusiness.NatureOfBusinessId = e.value;
    this.RegisterCompanyInput.NatureOfBusiness.NatureOfBusinessName = e.source.selected._element.nativeElement.innerText.trim();
  }
}
