import { Pipe, PipeTransform } from '@angular/core';
import { FuseUtils } from '@planwork/utils';
//import { FuseUtils } from '@planwork/utils';

@Pipe({name: 'filter'})
export class FilterPipe implements PipeTransform
{                                               /* property: string */
    /* transform(mainArr: any[], searchText: string): any
    {
        return FuseUtils.filterArrayByString(mainArr, searchText);
    } */
    transform(mainArr: any[], searchText: string): any
    {
        return FuseUtils.filterArrayByString(mainArr, searchText);
    }
    /* transform(value: any, args?: any): any {

        if(!value)return null;
        if(!args)return value;
    
        args = args.toLowerCase();
    
        return value.filter(function(item){
            return JSON.stringify(item).toLowerCase().includes(args);
        });
    } */
    /* transform(items: any[], searchText: string): any[] {
        if (!items) {
          return [];
        }
        if (!searchText) {
          return items;
        }
        searchText = searchText.toLocaleLowerCase();
    
        return items.filter(it => {
          return it.toLocaleLowerCase().includes(searchText);
        });
      } */

    /*   transform(items: any[], args: any[]): any {
        return items.filter(item => item.id.indexOf(args[0]) !== -1);
    } */
    /* transform(mainArr: any, searchText: string): any {
        return mainArr.filter(
          item => item.first_name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
       );
      } */
}
