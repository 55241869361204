import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class DataStorage {
    private permission$ = new BehaviorSubject<any>({});
    _permission = this.permission$.asObservable();
    private Masterpermission$ = new BehaviorSubject<any>({});
    _Masterpermission = this.Masterpermission$.asObservable();
    private Reportpermission$ = new BehaviorSubject<any>({});
    _Reportpermission = this.Reportpermission$.asObservable();



    constructor() { }

    userRoleList(): void {
        let localData = JSON.parse(localStorage.getItem('userid'));
        let myRoleId = localData.UserRole.UserRoleId;

        let getRols = localData.UserRoleList[localData.UserRoleList.findIndex(res => res.UserRoleId == myRoleId)];
        getRols.UserRolePermissionList.map(res => {
            //Pages
            res.Pages.map(pages => {

                pages.Buttons.map(button => {
                    let findId = button.RoleId.findIndex(id => id == myRoleId);
                    if (findId != -1) {
                        button.type = true
                    } else {
                        button.type = false
                    }
                })
                pages.Tabs.map(Tabs => {
                    let findId = Tabs.RoleId.findIndex(id => id == myRoleId);
                    if (findId != -1) {
                        Tabs.type = true
                    } else {
                        Tabs.type = false
                    }
                    Tabs.Buttons.map(button => {
                        let findId = button.RoleId.findIndex(id => id == myRoleId);
                        if (findId != -1) {
                            button.type = true
                        } else {
                            button.type = false
                        }
                    })
                })
            })
            //Children
            res.children.map(pages => {
                pages.children.map(child => {
                    child.Buttons.map(button => {
                        let findId = button.RoleId.findIndex(id => id == myRoleId);
                        if (findId != -1) {
                            button.type = true
                        } else {
                            button.type = false
                        }
                    })
                })
            })
        })
        this.permission$.next(getRols.UserRolePermissionList)
    }

    masterRoleList(): void {
        const tokenData = JSON.parse(localStorage.getItem('userid'));
        const primaryRole = tokenData.UserRoleList.filter(x => x.IsPrimaryRole === true)[0];
        let getRolsMaster = primaryRole.UserRolePermissionList.filter(x => x.Mid === 'configure')[0];
        if (getRolsMaster) {
            getRolsMaster.children.forEach(element => {
                let checkRoll = element.RoleId.findIndex(res => res == tokenData.UserRole.UserRoleId);
                if (checkRoll != -1) {
                    element.type = true;
                } else {
                    element.type = false;
                }
                element.children.forEach(secmap => {
                    let checkRoll = secmap.RoleId.findIndex(res => res == tokenData.UserRole.UserRoleId);
                    if (checkRoll != -1) {
                        secmap.type = true;
                    } else {
                        secmap.type = false;
                    }
                    secmap.Buttons.forEach(secmapbutton => {
                        let checkRoll = secmapbutton.RoleId.findIndex(res => res == tokenData.UserRole.UserRoleId);
                        if (checkRoll != -1) {
                            secmapbutton.type = true;
                        } else {
                            secmapbutton.type = false;
                        }
                    })
                })
            })
        }
        this.Masterpermission$.next(getRolsMaster)


        let getRolsReport = primaryRole.UserRolePermissionList.filter(x => x.Mid === 'reports')[0];
        if (getRolsReport) {
            getRolsReport.children.forEach(element => {
                let checkRoll = element.RoleId.findIndex(res => res == tokenData.UserRole.UserRoleId);
                if (checkRoll != -1) {
                    element.type = true;
                } else {
                    element.type = false;
                }
                element.children.forEach(secmap => {
                    let checkRoll = secmap.RoleId.findIndex(res => res == tokenData.UserRole.UserRoleId);
                    if (checkRoll != -1) {
                        secmap.type = true;
                    } else {
                        secmap.type = false;
                    }
                    secmap.Buttons.forEach(secmapbutton => {
                        let checkRoll = secmapbutton.RoleId.findIndex(res => res == tokenData.UserRole.UserRoleId);
                        if (checkRoll != -1) {
                            secmapbutton.type = true;
                        } else {
                            secmapbutton.type = false;
                        }
                    })
                })
            })
        }
        this.Reportpermission$.next(getRolsReport)
    }
}