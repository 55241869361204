<div class="content inOutLogPage">
    <div class="page-heading">
        <div fxFlex fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
                <h1>Dispose Approval</h1>
            </div>
        </div>
    </div>
    <div class="content_body" [class.show-search]="isShow">
        <div class="filter_area pb-20">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex="1 0 auto">
                    <div class="top_drop" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                         <div class="input_group filter_box mr-3">
                            <p class="left_label">Status</p>
                            <mat-form-field appearance="outline">
                                <mat-select name="" [(value)]="selected">
                                    <mat-option value="0">All</mat-option>
                                    <mat-option *ngFor="let list of status" [value]="list">{{list.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="mini_btn" fxFlex="0 1 auto">
                    <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <div class="search-outer position-relative">
                            <button mat-mini-fab class="search_btn" (click)="toggleSearch('show')" *ngIf="!isShow">
                                <mat-icon>search</mat-icon>
                            </button>
                            <button mat-mini-fab class="search_btn" (click)="toggleSearch('hide')" *ngIf="isShow">
                                <mat-icon>clear</mat-icon>
                            </button>
                            <div class="search_form">
                                <input type="text" matInput placeholder="Search by keyword">
                            </div>
                        </div>
                        <button mat-mini-fab color="accent" class="gray_btn">
                            <img src="assets/icons/table.svg">
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-white table-responsive list_view server_side_page">
            <table id="table_lead" datatable [dtOptions]="dtOptions"
                class="row-border hover list_table table table-bordered table-hover table-compact">
                <thead>
                    <tr>
                        <th *ngFor="let item of theadColumn" (click)="sortItem = item.position">
                            <div>
                                <span>{{item.Title}}</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody [attr.data_sort]="'sort_' + sortItem">
                    <tr *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]; let i = index;">
                        <td>{{item}}</td>
                        <td>REQ-463/2/2022</td>
                        <td> Laptop</td>
                        <td>Conditioner</td>
                        <td>Important</td>
                        <td>Administrator VertexPlus</td>
                        <td>Message</td>
                        <td>Justification:</td>
                        <td>Status</td>
                        <td>
                            <mat-drawer-container class="example-container text-center" autosize>
                                <span class="action_icons">
                                    <a class="icon" title="View Detail">
                                        <mat-icon>download</mat-icon>
                                    </a>
                                </span>
                            </mat-drawer-container>
                        </td>
                    </tr>
                    <tr>
                        <td align-item="center" colspan="10">No matching records found</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>