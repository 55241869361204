import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatatableService } from '@planwork/datatable-settings';
import { DataTableDirective } from 'angular-datatables';
import { CommonModalComponent } from 'app/main/core/common/common-modal/common-modal.component';
import { BallparkModalsComponent } from 'app/main/lead/lead-business-head/ballpark-modals/ballpark-modals.component';
import { TableColumn } from './Table.Column';

@Component({
  selector: 'app-services-utility-approval',
  templateUrl: './services-utility-approval.component.html',
  styleUrls: ['./services-utility-approval.component.scss']
})
export class ServicesUtilityApprovalComponent implements OnInit {

  selected = '0'
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  tableSetting: any = {};
  isShow = false;
  theadColumn = [];
  sortItem = 1;
  EmployeeOutput: any
  status = [
    { name: 'To Be Approved' },
    { name: 'Approved' },
    { name: 'Rework' },
    { name: 'Declined' }
  ];
  Requisition = [
    { name: 'New' },
    { name: 'Repair & Maintenance' },
    { name: 'Customize' },
    { name: 'Deposit' }
  ]
  dialogRef: any;
  currentStatus
  constructor(private datatableServise: DatatableService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.theadColumn = TableColumn;
    this.tableSetting = this.datatableServise.tablePagination();
  }

  ngOnInit(): void {
  }
  toggleSearch(text) {
    if (text == 'show') {
      this.isShow = true;
    } else {
      this.isShow = false;
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.search('');
        dtInstance.draw();
      });
    }
  }
  approveModal(type) {
    const dialogRef = this.dialog.open(CommonModalComponent, {
      width: '300px',
      disableClose: true,
      autoFocus: false,
      panelClass: 'statusClass',
      data: { action: 'delete', statusText: 'Are you sure You want to Approve !' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (type == 'new') {
          this.router.navigate(['/purchase-and-procurement/services-utility-pr-list']);
        } else {
          console.log('aaaa');

          this.router.navigate(['/purchase-and-procurement/services-utility-pr-list']);
        }
      }
    })
  }
  addRemark() {
    this.dialogRef = this.dialog.open(BallparkModalsComponent, {
      disableClose: false,
      data: { action: 'remarkEpica', title: 'Rework Remark' }
    });
  }
  addDecline() {
    this.dialogRef = this.dialog.open(BallparkModalsComponent, {
      disableClose: false,
      data: { action: 'remarkEpica', title: 'Decline Remark' }
    });
  }
  statusType(type) {
    this.currentStatus = type;
  }
}
