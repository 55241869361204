import { DataStorage } from './store/storage';

import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticatedHttpService } from '@planwork/services/authenticated-http.service';
import { PlanworkConfigService } from '@planwork/services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
 
  title = 'planwork';
  public counter: number;
  public timer: any;

  constructor(
    private store: DataStorage,
    public router: Router,
    private plService: PlanworkConfigService
  ) {
    let user = JSON.parse(localStorage.getItem('userid'));
    if (user) {
      this.store.userRoleList();
      this.store.masterRoleList();
    }
  }
  ngOnInit() {
    this.plService.setHrsCount.subscribe(res => {
      this.counter = res;
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.counter--;
        if (this.counter === 0) {
          clearInterval(this.timer);
          this.plService.logout();
        }
      }, 1000);
    })
  }
}
