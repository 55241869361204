<div class="content inOutLogPage">
  <div class="page-heading">
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
        <h1>Asset Approval</h1>
      </div>
    </div>
  </div>
  <div class="content_body" [class.show-search]="isShow">
    <div class="filter_area pb-20">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="1 0 auto">
          <div class="top_drop" fxFlex="row" fxLayoutGap="10px" fxLayoutAlign="start center">
            <div class="input_group filter_box mr-3">
              <p class="left_label">Status</p>
              <mat-form-field appearance="outline" class="mb-0 mt-15">
                <mat-select placeholder="All Type" (selectionChange)="organizationfilter($event,'status')"
                  [(value)]="selected">
                  <mat-option value="all"> All </mat-option>
                  <mat-option *ngFor="let item of status" [value]="item">
                    {{item.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- <div class="input_group filter_box mr-3">
              <p class="left_label">Requisition Type</p>
              <mat-form-field appearance="outline" class="mb-0 mt-15">
                <mat-select placeholder="All Type" (selectionChange)="organizationfilter($event,'requisiton')"
                  [(value)]="selected2">
                  <mat-option value="all"> All </mat-option>
                  <mat-option *ngFor="let item of Requisition" [value]="item">
                    {{item.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->

          </div>
        </div>

        <div class="mini_btn" fxFlex="0 1 auto">
          <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <div class="search-outer position-relative">
              <button mat-mini-fab class="search_btn" (click)="toggleSearch('show')" *ngIf="!isShow">
                <mat-icon>search</mat-icon>
              </button>
              <button mat-mini-fab class="search_btn" (click)="toggleSearch('hide')" *ngIf="isShow">
                <mat-icon>clear</mat-icon>
              </button>
              <div class="search_form">
                <input type="text" matInput placeholder="Search by keyword">
              </div>
            </div>
            <button mat-mini-fab color="accent" class="gray_btn">
              <img src="assets/icons/table.svg">
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-white table-responsive list_view server_side_page">
      <table id="table_lead" datatable [dtOptions]="dtOptions"
        class="row-border hover list_table table table-bordered table-hover table-compact">
        <thead>
          <tr>
            <th *ngFor="let item of theadColumn" (click)="sortItem = item.position">
              <div>
                <span>{{item.Title}}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody [attr.data_sort]="'sort_' + sortItem">
          <tr *ngFor=" let element of DataOutput; let i = index">
            <td>{{i+1+tabledata.start}}</td>
            <td><strong>{{element?.RequisitionNumber}}</strong> <br>
              <small>{{element?.CreatedDate | date}}</small>
            </td>
            <!-- <td><strong>{{element?.RequisitionTypeid == 1 ? 'NEW' :element?.RequisitionTypeid == 2 ? 'Repair &
                Maintenance ':
                element?.RequisitionTypeid == 3?'Customize':'Deposit' }}</strong></td> -->
            <td> {{element?.AssetItemName}}</td>
            <td>
              <mat-chip-list aria-label="Dog selection">
                <mat-chip style="font-size: 11px; padding: 0 8px; min-height: 20px; margin-bottom: 8px; color: #fff;"
                  class="bg-danger">
                  {{element?.RequisitionPriorityId == 1? 'Scheduled' : element?.RequisitionPriorityId == 2 ?
                  'Important':
                  element?.RequisitionPriorityId == 3? 'Urgent' : element?.RequisitionPriorityId == 4 ?
                  'Critical':'Immediate'
                  }}
                </mat-chip>
              </mat-chip-list> {{element?.RequireDate | date}}
            </td>
            <td>{{element?.RequestedBy}}</td>
            <td>{{element?.status == 1 ? 'To Be Approved' : element?.status == 3 ? 'Rework' : element?.status == 4 ? 'Declined' : 'Approved' }}</td>

            <td>
              <span class="action_icons">
                <a class="icon" *ngIf="element?.status == 1" matTooltip="Approve" (click)="reworksave(element?.RequisitionId, '1')">
                  <mat-icon>done</mat-icon>
                </a>
                <a class="icon" matTooltip="Decline" *ngIf="element?.status == 1"  (click)="remark(element?.RequisitionId, '1')">
                  <mat-icon>close</mat-icon>
                </a>
                <a class="icon" matTooltip="Rework" *ngIf="element?.status == 1"  (click)="addRemark(element?.RequisitionId, '1')">
                  <mat-icon>sync</mat-icon>
                </a>
                <a class="icon" matTooltip="View Detail"
                  (click)="sidenav.toggle(); clientGetById(element.RequisitionId)">
                  <mat-icon>visibility</mat-icon>
                </a>
              </span>
            </td>
          </tr>

        </tbody>
        <tbody *ngIf="DataOutput?.length == 0">
          <tr>
            <td colspan="8" class="text-center">No Record Found</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- Mat Drawer -->
<mat-drawer-container class="example-container epica-sidebar">
  <mat-drawer #sidenav class="sidebar taskdetail-sidebar" mode="over" position="end">
    <mat-icon class="sidebar-closeicon" (click)="sidenav.toggle()">highlight_off</mat-icon>

    <div class="taskdetail-dv">
      <h2>Detail</h2>
      <div class="task_detail_box">
        <div class="row">
          <!-- <div class="col-md-6 dtl_text">
            <label>Requisition Type</label>
            <p>{{ReqGetdata?.RequisitionTypeid == 1 ? 'NEW' :ReqGetdata?.RequisitionTypeid == 2 ? 'Repair & Maintenance':
              ReqGetdata?.RequisitionTypeid == 3?'Customize':'Deposit' }}</p>
          </div> -->
          <div class="col-md-6 dtl_text">
            <label>Item Type</label>
            <p>{{ReqGetdata?.AssetItemName}}</p>
          </div>
          <div class="col-md-6 dtl_text">
            <label>Requisition No.</label>
            <p>{{ReqGetdata?.RequisitionNumber}}<br>
              <small>{{ReqGetdata?.CreatedDate | date}}</small>
          </div>
          <div class="col-md-6 dtl_text">
            <label>Required On/Before</label>
            <p> {{ReqGetdata?.RequisitionPriorityId == 1? 'Scheduled' : ReqGetdata?.RequisitionPriorityId == 2 ?
              'Important':
              ReqGetdata?.RequisitionPriorityId == 3? 'Urgent' : ReqGetdata?.RequisitionPriorityId == 4 ?
              'Critical':'Immediate'
              }}
              <br>
              <small>{{ReqGetdata?.RequireDate |date}}</small>
            </p>
          </div>
          <div class="col-md-6 dtl_text">
            <label>Requested By</label>
            <p>{{ReqGetdata?.RequestedBy}} </p>
          </div>
          <div class="col-md-12 dtl_text">
            <label>Specific Recommendation</label>
            <ul style="margin: 0 0 0 -20px; list-style: auto;">
              <li *ngFor="let list of ReqGetdata?.PreferenceName">{{list}}</li>
            </ul>
          </div>
          <!-- <div class="col-md-12 dtl_text">
            <label>Problem</label>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
          </div> -->

          <div class="col-md-12 dtl_text">
            <label>Description</label>
            <p>{{ReqGetdata?.Description}}</p>
          </div>
          <div class="col-md-12 dtl_text">
            <label>Justification</label>
            <p>{{ReqGetdata?.Justification}}</p>
          </div>
        </div>
        <div class="task_full_dtl dtl_text mb-3 mt-0" *ngIf="ReqGetdata?.status == 3 || ReqGetdata?.status == 4">
          <label>{{ReqGetdata?.status == 3 ? 'Rework' : 'Declined'}}</label>
          <p>{{ReqGetdata?.status == 3 ? ReqGetdata?.ReworkJustification   :ReqGetdata?.Declinejustification}}</p>
        </div>
        <div class="row">

          <div class="col-md-6 dtl_text">
            <label>Status</label>
            <p>{{ReqGetdata?.status == 1 ? 'Pending' : ReqGetdata?.status == 3 ? 'Rework' : ReqGetdata?.status == 4 ? 'Declined' : 'Approved' }}</p>

          </div>
        </div>
      </div>
      <div class="mt-5" *ngIf="ReqGetdata?.status == 1">
        <button class="add-lead-btn mr-3" mat-flat-button color="accent" matTooltip="Approve"
          (click)="reworksave(ReqGetdata?.RequisitionId, '2')">
          Approve
        </button>
        <button mat-stroked-button class="mr-3" color="warn" matTooltip="Decline" (click)="remark(ReqGetdata?.RequisitionId, '2')"
         >Decline</button>
        <button mat-stroked-button color="basic" matTooltip="Rework" (click)="addRemark(ReqGetdata?.RequisitionId, '2')">Rework</button>
      </div>
    </div>

  </mat-drawer>
</mat-drawer-container>
