import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consumable-classes',
  templateUrl: './consumable-classes.component.html',
  styleUrls: ['./consumable-classes.component.scss']
})
export class ConsumableClassesComponent {

  obj = {
    heading: "Consumable",
    addModalTitle: "Consumable",
    addModelAction: "AddConsumable",
    baseUrlType: 'Consumable',
    editModalTitle: "Consumable",
    editModelAction: "EditConsumable",
    endPoint: "ConsumableClassList",
    addColumns: 1,
    colHeadings: { col1: 'Consumable Class Name' },
    sortableColumns: [
      { data: '0', Title: '#' },
      { data: 'ConsumableClassName', Title: 'Consumable Class Name' },
      { data: '2', Title: 'Created on' },
      { data: '3', Title: 'Action' },
    ],
    deleteEndPoint: 'ConsumableClassDelete',
    getListObj: {
      ConsumableClassName: '_blank'
    }
  }
}
