import { FuseMatSelectSearchComponent } from './mat-select-search.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';



@NgModule({ 
    imports: [
      CommonModule,
      MatButtonModule,
      MatIconModule,
      MatInputModule,
    ],
    declarations: [
        FuseMatSelectSearchComponent
    ],
    exports: [
      MatButtonModule,
      MatInputModule,
      FuseMatSelectSearchComponent
    ]
  })
export class FuseMatSelectSearchModule
{
}