export const TableColumn = [
    { data: '0', Title: '#',  position: 1 },
    { data: '1', Title: 'Requisition No.', position: 4 },
    // { data: '2', Title: 'Requisition Type', position: 2 },
    { data: '3', Title: 'Item', position: 3 },
    { data: '4', Title: 'Required On/Before', position: 6 },
    { data: '5', Title: 'Requested By', position: 7 },
    { data: '6  ', Title: 'Status', position: 10 },
    { data: 'Action', Title: 'Action', position: 11 },
];
