import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-asset-items',
  templateUrl: './asset-items.component.html',
  styleUrls: ['./asset-items.component.scss']
})
export class AssetItemsComponent implements OnInit {
  obj = {
    heading: "Asset Item",
    addModalTitle: "Asset Item",
    addModelAction: "Addassetitem",
    baseUrlType: 'Asset',
    editModalTitle: "Asset Item",
    editModelAction: "Editassetitem",
    endPoint: "AssetItemList",
    endPoint2: "assetitem",
    addColumns: 3,
    colHeadings: {col1: 'Asset Item Name',col2: 'Asset Name', col3: 'Asset Category Name',  },
    sortableColumns: [
      { data: '0', Title: '#' },
      { data: 'assetitemName', Title: 'Item' },
      { data: 'assetcategoryname', Title: 'Asset Category' },
      { data: 'assetname', Title: 'Asset Class' },
      { data: 'createddate', Title: 'Created on' },
      { data: '5', Title: 'Action' },
    ],
    deleteEndPoint: 'AssetItemDelete',
    getListObj: {
      AssetItemName: '_blank',
    }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
