import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetClassesComponent } from './asset-classes/asset-classes.component';
import { AssetCategoriesComponent } from './asset-categories/asset-categories.component';
import { AssetItemsComponent } from './asset-items/asset-items.component';
import { AssetAttributesComponent } from './asset-attributes/asset-attributes.component';
import { RouterModule } from '@angular/router';
import { ModeOfConveyanceComponent } from './mode-of-conveyance/mode-of-conveyance.component';
import { MasterCompnentModule } from '../master-component/master-compnent.module';
import { EntitlementsComponent } from './entitlements/entitlements.component';
import { ConsumableClassesComponent } from './consumable-classes/consumable-classes.component';
import { UomComponent } from './uom/uom.component';
import { AddEntitlementsComponent } from './add-entitlements/add-entitlements.component';
import { PlanworkSharedModule } from '@planwork/planwork.shared.module';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { AccommodationTypeComponent } from './accommodation-type/accommodation-type.component';
import { ExpenseTypeComponent } from './expense-type/expense-type.component';
import { ConsumableCategoriesComponent } from './consumable-categories/consumable-categories.component';
import { ConsumableItemsComponent } from './consumable-items/consumable-items.component';


const routes = [
  {
    path: 'asset-classes',
    component: AssetClassesComponent,
  },
  {
    path: 'terms-condition',
    component: TermsConditionComponent,
  },
  {
    path: 'asset-attributes',
    component: AssetAttributesComponent,
  },
  {
    path: 'asset-categories',
    component: AssetCategoriesComponent,
  },
  {
    path: 'asset-items',
    component: AssetItemsComponent,
  },
  {
    path: 'mode-of-conveyance',
    component: ModeOfConveyanceComponent,
  },
  {
    path: 'entitlements',
    component: EntitlementsComponent,
  },
  {
    path: 'consumable-classes',
    component: ConsumableClassesComponent,
  },
  {
    path: 'consumable-categories',
    component: ConsumableCategoriesComponent,
  },
  {
    path: 'consumable-items',
    component: ConsumableItemsComponent,
  },
  {
    path: 'uom',
    component: UomComponent,
  },
  {
    path: 'add-entitlements',
    component: AddEntitlementsComponent,
  },
  {
    path: 'add-entitlements/:id',
    component: AddEntitlementsComponent,
  },
  {
    path: 'accommodation-type',
    component: AccommodationTypeComponent,
  },
  {
    path: 'expense-type',
    component: ExpenseTypeComponent,
  }
]
@NgModule({
  declarations: [AssetClassesComponent, AssetCategoriesComponent, AssetItemsComponent, AssetAttributesComponent, ModeOfConveyanceComponent, EntitlementsComponent, ConsumableClassesComponent, UomComponent, AddEntitlementsComponent, TermsConditionComponent, AccommodationTypeComponent, ExpenseTypeComponent, ConsumableCategoriesComponent, ConsumableItemsComponent],
  imports: [RouterModule.forChild(routes),
    CommonModule, MasterCompnentModule, PlanworkSharedModule
  ]

})

export class SettingsModule { }
