<div *ngIf="dialogAction == 'add_new_contact'" class="prsn_dialog">
    <button class="closeModal" (click)="deviceclose()"><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <h2>Add New Contact</h2>
        <form #f="ngForm">
            <div class="form_layout">
                <div fxLayout="row wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <div class="position: relative;">
                            <mat-form-field appearance="outline">
                                <mat-label>First Name</mat-label>
                                <input matInput name="FirstName" [class.error]="f.submitted && FirstName.invalid" #FirstName="ngModel" [(ngModel)]="newContact.ContactDetails[0].DecisionMaker.FirstName" pattern="[a-zA-Z]+" required>
                            </mat-form-field>
                            <div class="position: absolute errorMessage" *ngIf="f.submitted && FirstName.invalid">
                                <mat-error class="error-mat-Display">Please enter first name</mat-error>
                            </div>
                        </div>
                        <div class="position: relative;">
                            <mat-form-field appearance="outline">
                                <mat-label>Last Name</mat-label>
                                <input matInput name="LastName" [class.error]="f.submitted && LastName.invalid" #LastName="ngModel" [(ngModel)]="newContact.ContactDetails[0].DecisionMaker.LastName" pattern="[a-zA-Z]+" required>
                            </mat-form-field>
                            <div class="position: absolute errorMessage" *ngIf="f.submitted && LastName.invalid">
                                <mat-error class="error-mat-Display">Please enter last name</mat-error>
                            </div>
                        </div>
                        <div fxLayout="row wrap">
                            <div fxFlex="0 1 100">
                                <div class="position: relative;">
                                    <mat-form-field appearance="outline" class="padd_rig" fxFlex="0 1 20">
                                        <mat-label>ISD</mat-label>
                                        <input matInput type="text" class="form-control" name="ISDCode" #ISDCode="ngModel" [class.error]="f.submitted && ISDCode.invalid" [(ngModel)]="newContact.ContactDetails[0].Mobile.ISDCode" required>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Phone Number</mat-label>
                                        <input matInput type="text" class="form-control" [class.error]="f.submitted && MobileNo.invalid" name="MobileNo" [(ngModel)]="newContact.ContactDetails[0].Mobile.MobileNo" #MobileNo="ngModel" required>
                                    </mat-form-field>

                                </div>
                                <div class="position: absolute errorMessage" *ngIf="(f.submitted && MobileNo.invalid) || f.submitted && ISDCode.invalid">
                                    <mat-error class="error-mat-Display">Please enter a mobile number</mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="position: relative;">
                            <mat-form-field appearance="outline">
                                <mat-label>Email</mat-label>
                                <input matInput name="Email" [(ngModel)]="newContact.ContactDetails[0].Email" [class.error]="f.submitted && Email.invalid" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" #Email="ngModel" required>
                            </mat-form-field>
                            <div class="position: absolute errorMessage" *ngIf="f.submitted && Email.invalid">
                                <mat-error class="error-mat-Display">Please enter an email</mat-error>
                            </div>
                        </div>
                        <div class="position: relative;">
                            <!-- <mat-form-field appearance="outline">
                                <mat-label>Designation</mat-label>
                                <mat-select name="Designation" [(ngModel)]="newContact.ContactDetails[0].Designation"
                                    [class.error]="f.submitted && Designation.invalid" #Designation="ngModel" required>
                                    <mat-option *ngFor="let itemData of reminderBefore" [value]="itemData">
                                        {{itemData.DesignationName}}</mat-option>
                                </mat-select>
                            </mat-form-field> -->
                            <mat-form-field appearance="outline" floatLabel="never">
                                <mat-select class="form-control" [(ngModel)]="newContact.ContactDetails[0].Designation" name="Designation" matNativeControl #Designation="ngModel" [class.error]="f.submitted && Designation.invalid" required>
                                    <mat-option *ngFor="let itemData of reminderBefore" [value]="itemData">
                                        {{itemData.DesignationName}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div class="position: absolute " *ngIf="f.submitted && Designation.invalid">
                                <mat-error class="error-mat-Display">Please enter an email</mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button color="primary" (click)="saveContact()">Save</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div *ngIf="dialogAction == 'add_new_contacts'" class="prsn_dialog">
    <button class="closeModal" (click)="deviceclose()"><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <h2>Add New Contact</h2>
        <form #f="ngForm">
            <div class="form_layout">
                <div fxLayout="row wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <div class="position: relative;">
                            <mat-form-field appearance="outline">
                                <mat-label>First Name</mat-label>
                                <input matInput name="FirstName" [class.error]="f.submitted && FirstName.invalid" #FirstName="ngModel" [(ngModel)]="leadnewContact.RegisteredAddress.ContactDetailList[0].ContactPerson.FirstName" pattern="[a-zA-Z]+" required>
                            </mat-form-field>
                            <div class="position: absolute errorMessage" *ngIf="f.submitted && FirstName.invalid">
                                <mat-error class="error-mat-Display">Please enter first name</mat-error>
                            </div>
                        </div>
                        <div class="position: relative;">
                            <mat-form-field appearance="outline">
                                <mat-label>Last Name</mat-label>
                                <input matInput name="LastName" [class.error]="f.submitted && LastName.invalid" #LastName="ngModel" [(ngModel)]="leadnewContact.RegisteredAddress.ContactDetailList[0].ContactPerson.LastName" pattern="[a-zA-Z]+" required>
                            </mat-form-field>
                            <div class="position: absolute errorMessage" *ngIf="f.submitted && LastName.invalid">
                                <mat-error class="error-mat-Display">Please enter last name</mat-error>
                            </div>
                        </div>
                        <div fxLayout="row wrap">
                            <div fxFlex="0 1 100">
                                <div class="position: relative;">
                                    <mat-form-field appearance="outline" class="padd_rig" fxFlex="0 1 20">
                                        <mat-label>ISD</mat-label>
                                        <input matInput type="text" class="form-control" name="ISDCode" #ISDCode="ngModel" [class.error]="f.submitted && ISDCode.invalid" [(ngModel)]="leadnewContact.RegisteredAddress.ContactDetailList[0].MobileNumber.ISDCode" required>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Phone Number</mat-label>
                                        <input matInput type="text" class="form-control" [class.error]="f.submitted && MobileNo.invalid" name="MobileNo" [(ngModel)]="leadnewContact.RegisteredAddress.ContactDetailList[0].MobileNumber.MobileNo" #MobileNo="ngModel" required>
                                    </mat-form-field>

                                </div>
                                <div class="position: absolute errorMessage" *ngIf="(f.submitted && MobileNo.invalid) || f.submitted && ISDCode.invalid">
                                    <mat-error class="error-mat-Display">Please enter a mobile number</mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="position: relative;">
                            <mat-form-field appearance="outline">
                                <mat-label>Email</mat-label>
                                <input matInput name="Email" [(ngModel)]="leadnewContact.RegisteredAddress.ContactDetailList[0].EmailId" [class.error]="f.submitted && Email.invalid" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" #Email="ngModel" required>
                            </mat-form-field>
                            <div class="position: absolute errorMessage" *ngIf="f.submitted && Email.invalid">
                                <mat-error class="error-mat-Display">Please enter an email</mat-error>
                            </div>
                        </div>
                        <div class="position: relative;">
                            <!-- <mat-form-field appearance="outline">
                                <mat-label>Designation</mat-label>
                                <mat-select name="Designation" [(ngModel)]="leadnewContact.RegisteredAddress.ContactDetailList[0].Designation"
                                    [class.error]="f.submitted && Designation.invalid" #Designation="ngModel" required>
                                    <mat-option *ngFor="let itemData of reminderBefore" [value]="itemData">
                                        {{itemData.DesignationName}}</mat-option>
                                </mat-select>
                            </mat-form-field> -->
                            <mat-form-field appearance="outline" floatLabel="never">
                                <mat-select class="form-control" [(ngModel)]="leadnewContact.RegisteredAddress.ContactDetailList[0].Designation" name="Designation" matNativeControl #Designation="ngModel" [class.error]="f.submitted && Designation.invalid" required>
                                    <mat-option *ngFor="let itemData of reminderBefore" [value]="itemData">
                                        {{itemData.DesignationName}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div class="position: absolute " *ngIf="f.submitted && Designation.invalid">
                                <mat-error class="error-mat-Display">Please enter an email</mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button color="primary" (click)="saveContactLead()">Save</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div *ngIf="dialogAction == 'add-contact' || dialogAction == 'edit-contact'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2>Add Contact</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <div fxLayout="row wrap" class="grid_row" fxLayoutWrap fxLayoutAlign="start start" fxLayoutGap="0px">

                            <div class="flex-item" fxLayout="column" fxFlex="0 1 50">
                                <mat-form-field appearance="outline">
                                    <mat-label>First Name</mat-label>
                                    <input matInput [class.error]="f.submitted && FirstName.invalid" [(ngModel)]="addContactDetail.ContactPerson.FirstName" name="FirstName" #FirstName="ngModel" pattern="^(\s)*[A-Za-z]+((\s)?((\'|\-|\.)?([A-Za-z])+))*(\s)*$" required>
                                </mat-form-field>
                            </div>
                            <div class="flex-item" fxLayout="column" fxFlex="0 1 50">
                                <mat-form-field appearance="outline">
                                    <mat-label>Last Name</mat-label>
                                    <input matInput [class.error]="f.submitted && LastName.invalid" [(ngModel)]="addContactDetail.ContactPerson.LastName" name="LastName" #LastName="ngModel" pattern="[a-zA-Z]+" required>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Department</mat-label>
                            <mat-select [compareWith]="compareFn" [(ngModel)]="addContactDetail.Department" name="Department" #Department="ngModel" class="form-control">
                                <mat-option *ngFor="let Uni of DepartmentDropDown" [value]="Uni">
                                    {{Uni?.DepartmentName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Designation</mat-label>
                            <mat-select [compareWith]="compareFn" [(ngModel)]="addContactDetail.Designation" name="Designation" #Designation="ngModel" class="form-control">
                                <mat-option *ngFor="let Uni of DesignationList " [value]="Uni">
                                    {{Uni?.DesignationName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Email</mat-label>
                            <input type="email" matInput [class.error]="f.submitted && EmailId.invalid" [(ngModel)]="addContactDetail.EmailId" name="EmailId" #EmailId="ngModel" pattern='^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'>
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <div fxLayout="row wrap" class="grid_row" fxLayoutWrap fxLayoutAlign="start start" fxLayoutGap="0px">
                            <!-- <mat-form-field appearance="outline" class="padd_rig" fxFlex="0 1 20">
                                    <input type="text" matInput placeholder="+91"
                                        [class.error]="f.submitted && ISDCode.invalid"
                                        [(ngModel)]="addContactDetail.Mobile.ISDCode" name="ISDCode" #ISDCode="ngModel"
                                        required>
                                </mat-form-field> -->
                            <div class="flex-item" fxLayout="column" fxFlex="0 1 35">
                                <mat-form-field appearance="outline">
                                    <mat-label>Code</mat-label>
                                    <mat-select [(ngModel)]="addContactDetail.Mobile.ISDCode" name="ISDCode" #ISDCode="ngModel" class="form-control" required (selectionChange)="findCountryCode($event.value)">
                                        <fuse-mat-select-search name="search" [(ngModel)]="countryCodeSearch">
                                        </fuse-mat-select-search>
                                        <mat-option *ngFor="let isd of  CountryDropDownlist | filter: countryCodeSearch" [value]="isd.CountryCode">
                                            {{isd.CountryCode}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="flex-item" fxLayout="column" fxFlex="0 1 65">
                                <mat-form-field appearance="outline">
                                    <mat-label>Contact Number</mat-label>
                                    <input type="number" matInput [class.error]="f.submitted && MobileNo.invalid" [(ngModel)]="addContactDetail.Mobile.MobileNo" name="MobileNo" #MobileNo="ngModel" [maxlength]="mobileDigit" [minlength]="mobileDigit" numbersOnly pattern="\d*" required>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'add-contact'" [disabled]="f.form.invalid" (click)="addContactDetails()" color="primary">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'edit-contact'" [disabled]="f.form.invalid" (click)="addContactDetails()" color="primary">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="dialogAction == 'add-contact-client' || dialogAction == 'edit-contact-client'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2>Add Contact</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <div fxLayout="row wrap" class="grid_row" fxLayoutWrap fxLayoutAlign="start start" fxLayoutGap="0px">

                            <div class="flex-item" fxLayout="column" fxFlex="0 1 50">
                                <mat-form-field appearance="outline">
                                    <mat-label>First Name</mat-label>
                                    <input matInput [class.error]="f.submitted && FirstName.invalid" [(ngModel)]="addContactDetail.ContactPerson.FirstName" name="FirstName" #FirstName="ngModel" pattern="^(\s)*[A-Za-z]+((\s)?((\'|\-|\.)?([A-Za-z])+))*(\s)*$" required>
                                </mat-form-field>
                            </div>
                            <div class="flex-item" fxLayout="column" fxFlex="0 1 50">
                                <mat-form-field appearance="outline">
                                    <mat-label>Last Name</mat-label>
                                    <input matInput [class.error]="f.submitted && LastName.invalid" [(ngModel)]="addContactDetail.ContactPerson.LastName" name="LastName" #LastName="ngModel" pattern="^(\s)*[A-Za-z]+((\s)?((\'|\-|\.)?([A-Za-z])+))*(\s)*$" required>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Department</mat-label>
                            <mat-select [compareWith]="compareFn" [(ngModel)]="addContactDetail.Department" name="Department" #Department="ngModel" class="form-control">
                                <mat-option *ngFor="let Uni of DepartmentDropDown" [value]="Uni">
                                    {{Uni?.DepartmentName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Designation</mat-label>
                            <mat-select [compareWith]="compareFn" [(ngModel)]="addContactDetail.Designation" name="Designation" #Designation="ngModel" class="form-control">
                                <mat-option *ngFor="let Uni of DesignationList " [value]="Uni">
                                    {{Uni?.DesignationName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Email</mat-label>
                            <input type="email" matInput pattern='^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$' [class.error]="f.submitted && EmailId.invalid" [(ngModel)]="addContactDetail.EmailId" name="EmailId" #EmailId="ngModel">
                        </mat-form-field>
                        <div fxLayout="row wrap">
                            <div fxFlex="0 1 100" class="d-flex">
                                <div fxLayout="row wrap" class="grid_row" fxLayoutWrap fxLayoutAlign="start start" fxLayoutGap="0px">
                                    <div class="flex-item" fxLayout="column" fxFlex="0 1 35">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Code</mat-label>
                                            <mat-select [class.error]="f.submitted && ISDCode.invalid" [(ngModel)]="addContactDetail.Mobile.ISDCode" name="ISDCode" #ISDCode="ngModel" class="form-control" required (selectionChange)="findCountryCode($event.value)">
                                                <fuse-mat-select-search name="search" [(ngModel)]="countryCodeSearch">
                                                </fuse-mat-select-search>
                                                <mat-option *ngFor="let isd of CountryDropDownlist | filter: countryCodeSearch" [value]="isd.CountryCode">
                                                    {{isd.CountryCode}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="flex-item" fxLayout="column" fxFlex="0 1 65">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Contact Number</mat-label>
                                            <input type="text" matInput [class.error]="f.submitted && MobileNo.invalid" [(ngModel)]="addContactDetail.Mobile.MobileNo" name="MobileNo" #MobileNo="ngModel" required [maxlength]="mobileDigit" [minlength]="mobileDigit">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row wrap" class="mb-3">
                            <div fxFlex="0 1 100">
                                <mat-checkbox class="example-margin" [(ngModel)]="addContactDetail.IsKeyContactPerson" name="IsKeyContactPerson" color="primary">Key Contact Person</mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'add-contact-client'" [disabled]="f.form.invalid" (click)="addContactDetails()" color="primary">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'edit-contact-client'" [disabled]="f.form.invalid" (click)="addContactDetails()" color="primary">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="dialogAction == 'Decline-Lead-head'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2>Decline Lead</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Reason</mat-label>
                            <mat-select required [compareWith]="compareFn" [(ngModel)]="DeclineDetail.PMRejectReason" name="PMRejectReason" #Department="ngModel" class="form-control">
                                <mat-option *ngFor="let Uni of ReasonDropDown.ReasonList " [value]="Uni">
                                    {{Uni?.ReasonName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" >
                            <mat-label>Comment</mat-label>
                            <textarea matInput type="textarea"  style="height: 102px;" [(ngModel)]="DeclineDetail.PMRejectReason.Description" name="Description" required #Description="ngModel" [class.error]="f.submitted && Description.invalid"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button [disabled]="f.form.invalid" (click)="DeclineLeadbyhead()" color="primary">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="dialogAction == 'Decline-Lead'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2>Decline Lead</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Reason</mat-label>
                            <mat-select required [compareWith]="compareFn" [(ngModel)]="DeclineDetail.PMRejectReason" name="PMRejectReason" #Department="ngModel" class="form-control">
                                <mat-option *ngFor="let Uni of ReasonDropDown.ReasonList " [value]="Uni">
                                    {{Uni?.ReasonName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline"  style="height: 115px;">
                            <mat-label>Comment</mat-label>
                            <textarea matInput type="textarea" style="height: 102px;" [(ngModel)]="DeclineDetail.PMRejectReason.Description" name="Description" required #Description="ngModel" [class.error]="f.submitted && Description.invalid"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button [disabled]="f.form.invalid" (click)="DeclineLead()" color="primary">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="dialogAction == 'add-document' || dialogAction == 'edit-document'" class="prsn_dialog document_add">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2>Add Document</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <mat-form-field appearance="outline">
                            <mat-label>Document Type</mat-label>
                            <mat-select [compareWith]="compareFn" [(ngModel)]="addDocumentDetail.DocumentType" name="DocumentType" #DocumentType="ngModel" class="form-control" required [class.error]="f.submitted && DocumentType.invalid">
                                <mat-option *ngFor="let Uni of DocumentDropDownList " [value]="Uni">
                                    {{Uni?.DocumentTypeName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="h_auto">
                            <mat-label>Remark</mat-label>
                            <textarea matInput type="textarea" rows="5" style="height: 50px;" [(ngModel)]="addDocumentDetail.Remark" name="Remark" required #Content="ngModel" [class.error]="f.submitted && Content.invalid"></textarea>
                        </mat-form-field>
                        <div class="form-section">
                            <h5 class="fs-16">Attach File *</h5>

                            <div fxLayout="row wrap">

                                <div class="flex-item px-0 pb-3" fxFlex="0 1 100">
                                    <div class="attach_file" style="margin-top:.25em">
                                        <img src="/assets/icons/attach_icon.svg" alt=""> Browse or drop file here
                                        <input type="file" (change)="fileUpload($event, addDocumentDetail?.DocumentFile)" class="upload" #myFileInput>
                                    </div>
                                    <mat-error>{{fileSizeError}}</mat-error>
                                    <mat-error *ngIf="!addDocumentDetail.DocumentFile?.DocumentFileName && !this.addDocumentDetail.valid">Please upload Document</mat-error>
                                    <div class="files_add" *ngIf="addDocumentDetail.DocumentFile?.DocumentFileName">
                                        <div class="add_file">
                                            <div class="left_file justify-content-between" style="width: 100%;">
                                                <a (click)="downloadMyFile(addDocumentDetail, addDocumentDetail.DocumentFile, addDocumentDetail.DocumentFile?.DocumentFileName)" style="overflow: hidden;
                                                width: 387px;">
                                                    <span class="icon">
                                                        <img
                                                            src="/assets/images/{{addDocumentDetail.DocumentFile?.FileExe}}-icon.png" />
                                                    </span>
                                                    <span class="name">{{addDocumentDetail?.DocumentFile?.DocumentFileName}}</span>
                                                </a>
                                                <button mat-icon-button type="button" (click)="fileUploadRemove(addDocumentDetail.DocumentFile)">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <!-- <div class="flex-item px-0" fxFlex="0 1 100">
                                    <div class="fileUploaded fi1 col-sm-12 p-0">
                                        <div class="pull-left attach_file">
                                            <a href="javascript:void(0);" (change)="onClick($event)" class="atch-btn addattach-file">
                                                <mat-icon>attach_file</mat-icon> Browse or drop file here
                                                <input required type="file" id="fileUploaded" name="fileUploaded" class="upload">
                                            </a>
                                        </div>
                                        <mat-error class="mb-5">{{fileSizeError}}</mat-error>
                                    </div>

                                    <div class="col-sm-12 p-0 pull-left">
                                        <ul class="bar-text">
                                            <li class="mt-3" *ngFor="let file of files">
                                                <mat-progress-bar [value]="file.progress"></mat-progress-bar>
                                                <span class="bar-value">Uploading .... {{file.progress}}%</span>
                                                <span id="file-label">
                                                    <a title="Retry" class="retry" (click)="retryFile(file)"
                                                        *ngIf="file.canRetry">Retry</a>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-sm-12 pull-left p-0 file-info">
                                        <ul style="list-style: none;padding: 0px;width:100%; margin-bottom: 0px;">
                                            <li class="col-sm-12 p-0 pull-left mt-3" *ngFor="let file of finalFile; let i = index">
                                                <span class="col-sm-9 pull-left pl-0">
                                                    <span class="mr-3">
                                                        <img src="/assets/images/{{file.fileType}}-icon.png" />
                                                    </span>
                                                <span (click)="downloadMyFile(file)">{{file.name}}</span>
                                                </span>
                                                <span class="col-sm-3 pull-left txt-right pr-0 d-flex justify-content-end">
                                                    <span><a title="Delete" class="retry" (click)="deleteFile(i)">
                                                            <span class="material-icons"
                                                                style="cursor: pointer;">clear</span>
                                                </a>
                                                </span>
                                                <span style="display:none;">{{file.type}}</span>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button *ngIf="dialogAction == 'add-document'" [disabled]="f.form.invalid" (click)="addDocumentDetails()" color="primary">Save</button>
                        <button mat-flat-button *ngIf="dialogAction == 'edit-document'" [disabled]="f.form.invalid" (click)="addDocumentDetails()" color="primary">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="dialogAction == 'move_spam'" class="prsn_dialog spam_dialog">
    <button class="closeModal" (click)="deviceclose()"><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <h2>Move to Spam</h2>
        <div class="form_layout">
            <div fxLayout="row wrap">
                <div class="flex-item" fxFlex="0 1 100">
                    <label for="">Reason to mark as spam</label>
                    <mat-form-field appearance="outline">
                        <mat-label>Start writing here...</mat-label>
                        <textarea matInput type="textarea" name="Address" style="height: 102px;"></textarea>
                    </mat-form-field>
                </div>
                <div class="flex-item" fxFlex="0 1 100">
                    <button mat-flat-button color="primary">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- change  Passsword -->
<div *ngIf="dialogAction == 'changepassword'" class="prsn_dialog ">
    <button class="closeModal" (click)="deviceclose()"><img src="/assets/icons/close_mpdal_icon.svg" alt=""></button>
    <div class="modal_section">
        <h2>Change Password</h2>
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <mat-form-field appearance="outline">
                <mat-label>Current Password</mat-label>
                <input type="password" matInput formControlName="currentPassword">

            </mat-form-field>
            <div *ngIf="submitted && registerForm.get('currentPassword').errors">

                <mat-error *ngIf="registerForm.get('currentPassword').errors.required" class="text-left">Current Password is required</mat-error>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>New Password</mat-label>
                <input type="password" matInput formControlName="password">
            </mat-form-field>
            <div *ngIf="submitted && registerForm.get('password').errors" class="alert alert-danger">

                <mat-error *ngIf="registerForm.get('password').errors.required" class="text-left">New Password is required</mat-error>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Confirm Password</mat-label>
                <input type="password" matInput formControlName="confirmPassword">
            </mat-form-field>

            <div *ngIf="submitted && registerForm.get('confirmPassword').errors" class="alert alert-danger">
                <mat-error *ngIf="registerForm.get('confirmPassword').errors.required" class="text-left">Confirm Password is required</mat-error>

                <mat-error *ngIf="registerForm.get('confirmPassword').errors.confirmPasswordValidator" class="text-left">Passsword and Confirm Password didn't match.</mat-error>
            </div>
            <div class="text-left">
                <button mat-raised-button type="submit" color="primary">Submit</button>
            </div>
        </form>
    </div>
</div>
<!-- add module -->
<div *ngIf="dialogAction == 'add-module'" class="prsn_dialog">
    <button class="closeModal" (click)="dialogRef.close(false)"><img src="/assets/icons/close_mpdal_icon.svg"
            alt=""></button>
    <form #f="ngForm">
        <div class="modal_section">
            <h2>Add Module</h2>
            <div class="form_layout">
                <div fxLayout="column wrap">
                    <div class="flex-item" fxFlex="0 1 100">
                        <div class="mtRadio">
                            <mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1">Module</mat-radio-button>
                                <mat-radio-button value="2">Phase</mat-radio-button>
                              </mat-radio-group>
                        </div>
                        <mat-form-field appearance="outline">
                            <mat-label>Name</mat-label>
                            <input type="text" matInput>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="h_auto">
                            <mat-label>Description</mat-label>
                            <textarea matInput type="textarea" style="height: 60px;"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="flex-item" fxFlex="0 1 100">
                        <button mat-flat-button color="primary">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- add module -->
