<mat-tree [dataSource]="SIDEBARDATA" [treeControl]="treeControl" class="example-tree bg">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle routerLinkActive="active">
    <ng-container *ngIf="node.type">
      <li class="mat-tree-node">
        <button mat-icon-button disabled></button>
        <!-- <a routerLink="/app/core/common/{{node.url}}" *ngIf="!node.isShowLink">{{node.name}}</a> -->
        <a [routerLink]="node.url">{{node.title}}</a>
      </li>
    </ng-container>
  </mat-tree-node>

  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <ng-container *ngIf="node.type">
      <li (click)="clicked($event)" class="listDrop">
        <div class="mat-tree-node">
          <span>{{node.title}}</span>
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.title">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>

        </div>
        <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)" class="nopadding">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </ng-container>
  </mat-nested-tree-node>
</mat-tree>