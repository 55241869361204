import { Inject, Injectable, InjectionToken } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import { BehaviorSubject, observable, Observable, Subject } from 'rxjs';

import * as _ from 'lodash';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { ServiceService } from 'app/main/core/masterservice/service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ReasonModalComponent } from 'app/login/reason-modal/reason-modal.component';
import { CommonModalComponent } from 'app/main/core/common/common-modal/common-modal.component';

// Create the injection token for the custom config
export const PLANWORK_CONFIG = new InjectionToken('planworkCustomConfig');

@Injectable()
export class PlanworkConfigService {
    config: any;
    defaultConfig: any;
    isSetConfigRan = false;
    viewType = new BehaviorSubject('normal');
    onConfigChanged: BehaviorSubject<any>;
    percentDone: number;

    timeFormate: any = [
        { Time: '10:00 AM', Value: '10:00:00' },
        { Time: '10:15 AM', Value: '10:15:00' },
        { Time: '10:30 AM', Value: '10:30:00' },
        { Time: '10:45 AM', Value: '10:45:00' },

        { Time: '11:00 AM', Value: '11:00:00' },
        { Time: '11:15 AM', Value: '11:15:00' },
        { Time: '11:30 AM', Value: '11:30:00' },
        { Time: '11:45 AM', Value: '11:45:00' },

        { Time: '12:00 PM', Value: '12:00:00' },
        { Time: '12:15 PM', Value: '12:15:00' },
        { Time: '12:30 PM', Value: '12:30:00' },
        { Time: '12:45 PM', Value: '12:45:00' },

        { Time: '01:00 PM', Value: '13:00:00' },
        { Time: '01:15 PM', Value: '13:15:00' },
        { Time: '01:30 PM', Value: '13:30:00' },
        { Time: '01:45 PM', Value: '13:45:00' },

        { Time: '02:00 PM', Value: '14:00:00' },
        { Time: '02:15 PM', Value: '14:15:00' },
        { Time: '02:30 PM', Value: '14:30:00' },
        { Time: '02:45 PM', Value: '14:45:00' },

        { Time: '03:00 PM', Value: '15:00:00' },
        { Time: '03:15 PM', Value: '15:15:00' },
        { Time: '03:30 PM', Value: '15:30:00' },
        { Time: '03:45 PM', Value: '15:45:00' },

        { Time: '04:00 PM', Value: '16:00:00' },
        { Time: '04:15 PM', Value: '16:15:00' },
        { Time: '04:30 PM', Value: '16:30:00' },
        { Time: '04:45 PM', Value: '16:45:00' },

        { Time: '05:00 PM', Value: '17:00:00' },
        { Time: '05:15 PM', Value: '17:15:00' },
        { Time: '05:30 PM', Value: '17:30:00' },
        { Time: '05:45 PM', Value: '17:45:00' },

        { Time: '06:00 PM', Value: '18:00:00' },
        { Time: '06:15 PM', Value: '18:15:00' },
        { Time: '06:30 PM', Value: '18:30:00' },
        { Time: '06:45 PM', Value: '18:45:00' },

        { Time: '07:00 PM', Value: '19:00:00' },
        { Time: '07:15 PM', Value: '19:15:00' },
        { Time: '07:30 PM', Value: '19:30:00' },
        { Time: '07:45 PM', Value: '19:45:00' },

        { Time: '08:00 PM', Value: '20:00:00' },
        { Time: '08:15 PM', Value: '20:15:00' },
        { Time: '08:30 PM', Value: '20:30:00' },
        { Time: '08:45 PM', Value: '20:45:00' },

        { Time: '09:00 PM', Value: '21:00:00' },
        { Time: '09:15 PM', Value: '21:15:00' },
        { Time: '09:30 PM', Value: '21:30:00' },
        { Time: '09:45 PM', Value: '21:45:00' },

        { Time: '10:00 PM', Value: '22:00:00' },
        { Time: '10:15 PM', Value: '22:15:00' },
        { Time: '10:30 PM', Value: '22:30:00' },
        { Time: '10:45 PM', Value: '22:45:00' },

        { Time: '11:00 PM', Value: '23:00:00' },
        { Time: '11:15 PM', Value: '23:15:00' },
        { Time: '11:30 PM', Value: '23:30:00' },
        { Time: '11:45 PM', Value: '23:45:00' },

        { Time: '12:00 AM', Value: '00:00:00' },
        { Time: '12:15 AM', Value: '00:15:00' },
        { Time: '12:30 AM', Value: '00:30:00' },
        { Time: '12:45 AM', Value: '00:45:00' },

        { Time: '01:00 AM', Value: '01:00:00' },
        { Time: '01:15 AM', Value: '01:15:00' },
        { Time: '01:30 AM', Value: '01:30:00' },
        { Time: '01:45 AM', Value: '01:45:00' },

        { Time: '02:00 AM', Value: '02:00:00' },
        { Time: '02:15 AM', Value: '02:15:00' },
        { Time: '02:30 AM', Value: '02:30:00' },
        { Time: '02:45 AM', Value: '02:45:00' },

        { Time: '03:00 AM', Value: '03:00:00' },
        { Time: '03:15 AM', Value: '03:15:00' },
        { Time: '03:30 AM', Value: '03:30:00' },
        { Time: '03:45 AM', Value: '03:45:00' },

        { Time: '04:00 AM', Value: '04:00:00' },
        { Time: '04:15 AM', Value: '04:15:00' },
        { Time: '04:30 AM', Value: '04:30:00' },
        { Time: '04:45 AM', Value: '04:45:00' },

        { Time: '05:00 AM', Value: '05:00:00' },
        { Time: '05:15 AM', Value: '05:15:00' },
        { Time: '05:30 AM', Value: '05:30:00' },
        { Time: '05:45 AM', Value: '05:45:00' },

        { Time: '06:00 AM', Value: '06:00:00' },
        { Time: '06:15 AM', Value: '06:15:00' },
        { Time: '06:30 AM', Value: '06:30:00' },
        { Time: '06:45 AM', Value: '06:45:00' },

        { Time: '07:00 AM', Value: '07:00:00' },
        { Time: '07:15 AM', Value: '07:15:00' },
        { Time: '07:30 AM', Value: '07:30:00' },
        { Time: '07:45 AM', Value: '07:45:00' },

        { Time: '08:00 AM', Value: '08:00:00' },
        { Time: '08:15 AM', Value: '08:15:00' },
        { Time: '08:30 AM', Value: '08:30:00' },
        { Time: '08:45 AM', Value: '08:45:00' },

        { Time: '09:00 AM', Value: '09:00:00' },
        { Time: '09:15 AM', Value: '09:15:00' },
        { Time: '09:30 AM', Value: '09:30:00' },
        { Time: '09:45 AM', Value: '09:45:00' },
    ]
    setHrsCount = new Subject<number>();
    logoutInput: any = { Company: {} };
    user: any;
    dialogRef: any;
    sidebarHide = new BehaviorSubject(0);
    constructor(
        private router: Router,
        public platform: Platform,
        @Inject(PLANWORK_CONFIG) config,
        public _http: HttpClient,
        private dashboardService: ServiceService,
        private spinner: NgxSpinnerService,
        public dialog: MatDialog,
    ) {

        // Set the default config from the user provided one (forRoot)
        this.defaultConfig = config;

        /**
         * Disable Custom Scrollbars if Browser is Mobile
         */
        if (this.platform.ANDROID || this.platform.IOS) {
            this.defaultConfig.customScrollbars = false;
        }

        // Set the config from the default config
        this.config = _.cloneDeep(this.defaultConfig);

        // Reload the default settings for the
        // layout on every navigation start
        router.events.subscribe(
            (event) => {
                if (event instanceof NavigationStart) {
                    this.isSetConfigRan = false;
                }

                if (event instanceof NavigationEnd) {
                    if (this.isSetConfigRan) {
                        return;
                    }

                    this.setConfig({
                        layout: this.defaultConfig.layout
                    }
                    );
                }
            }
        );

        // Create the behavior subject
        this.onConfigChanged = new BehaviorSubject(this.config);
        this.user = JSON.parse(localStorage.getItem('userid'));
    }


    setConfig(config): void {
        // Set the SetConfigRan true
        this.isSetConfigRan = true;

        // Merge the config
        this.config = _.merge({}, this.config, config);

        // Trigger the event
        this.onConfigChanged.next(this.config);
    }
    //FileSizeLimit Validation
    filesizeValidation(fileEvent: any): Observable<any> {

        const file = fileEvent.target.files[0];
        const fileSize = file.size / (1024 * 1024);
        if (fileSize > 5) {
            return new Observable(
                res => {
                    res.next('File size should be less than 5MB')
                }
            )
        } else {
            return new Observable(
                res => {
                    res.next('')

                }
            )
        }
    }
    //Multiple Image Upload
    imageUpload(fileEvent: any, data): Observable<any> {
        console.log(fileEvent);

        const file = fileEvent.target.files[0];
        const fileSize = file.size / (1024 * 1024);
        if (fileSize > 5) {
            return new Observable(
                res => {
                    data.DocumentFileName = '';
                    data.imagePreview = '';
                    data.FileData = '';
                    data.FileExe = '';
                    data.percentDone = 0;
                    data.errorText = 'File size should be less than 5MB';
                    res.next(data);
                }
            )
        } else {
            return new Observable(
                res => {
                    //Process bar
                    var formData = new FormData();
                    Array.from(fileEvent.target.files).forEach((faa: any) => { formData.append('file', faa) })

                    this._http.post('https://file.io', formData, { reportProgress: true, observe: 'events' })
                        .subscribe(event => {
                            if (event.type === HttpEventType.UploadProgress) {
                                //this.percentDone = Math.round(100 * event.loaded / event.total);
                                console.log(this.percentDone);

                                const files = fileEvent.target.files;

                                const file = files[0];
                                if (files && file) {
                                    const fileName = file.name;
                                    const fileExe = fileName.split('.').pop();
                                    let reader = new FileReader();
                                    reader.onload = (e: any) => {
                                        let imagePreview = e.target.result;
                                        let b;
                                        b = imagePreview.split('base64,');
                                        data.DocumentFileName = fileName;
                                        data.imagePreview = imagePreview;
                                        data.FileData = b[1];
                                        data.FileExe = fileExe;
                                        data.percentDone = Math.round(100 * event.loaded / event.total);
                                        data.errorText = '';
                                        res.next(data)
                                    }
                                    reader.readAsDataURL(file);
                                }

                            } else if (event instanceof HttpResponse) {
                                //data.uploadSuccess = true;
                            }
                        });


                }
            )
        }
    }
    //Duration
    dateTimeDuration(StartDate, EndDate): Observable<any> {
        StartDate = new Date(StartDate);
        EndDate = new Date(EndDate);
        return new Observable(
            res => {
                res.next(Math.floor((Date.UTC(EndDate.getFullYear(), EndDate.getMonth(), EndDate.getDate()) - Date.UTC(StartDate.getFullYear(), StartDate.getMonth(), StartDate.getDate())) / (1000 * 60 * 60 * 24)) + 1);
            })
    }
    //Logout
    logout() {
        this.logoutInput.Company = {
            "CompanyId": 1,
            "CompanyName": "VertexPlus Technologies Private Limited",
            "CompanyCode": "VTPL03"
        }
        this.logoutInput.IPAddress = "111.93.59.126:";
        this.logoutInput.TimeZone = "+05:30";
        this.logoutInput.LoginId = this.user.LoginId;
        this.logoutInput.IsActive = true;
        this.logoutInput.Reason = 'Dummy Logout Reason';

        this.dashboardService.LogoutService(this.logoutInput, 'Login/Logout')
            .subscribe(response => {
                if (response.body.Result.IsUserAccess && response.body.Result.IsUserActive) {
                    localStorage.clear();
                    this.router.navigate(['/time-out'])
                        .then(() => {
                            this.spinner.hide();
                            this.user = {};
                        });
                }
                // else if (!response.body.Result.IsUserAccess && response.body.Result.IsUserActive) {
                //     this.dialogRef = this.dialog.open(ReasonModalComponent, {
                //         panelClass: 'modal-sm',
                //         data: { action: 'LogoutReason' },
                //         autoFocus: false,
                //     });
                //     this.dialogRef.afterClosed()
                //         .subscribe(result => {
                //             if (!result) {
                //                 return;
                //             } else {
                //                 this.logoutInput.Reason = result.Reason;
                //                 this.logout();
                //             }
                //         })
                // } else {
                //     this.dialogRef = this.dialog.open(CommonModalComponent, {
                //         width: '300px',
                //         disableClose: true,
                //         autoFocus: false,
                //         panelClass: 'statusClass',
                //         data: { action: 'Alert', statusText: response.body.Result.TransactionMessage.Message }
                //     });
                // }
            });
    }
    //Common Modal
    resultPopup(action, message) {
        const dialogRef = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: action, statusText: message }
        });
    }
}

