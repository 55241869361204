<button mat-icon-button class="menum-btn d-block d-md-none">
    <mat-icon>menu</mat-icon>
</button>
<mat-nav-list class="sidebar">
    <mat-list-item *ngFor="let item of menu" (click)="itemTitle(item)" [class.active]="item.activeClass">
        <img src="{{item.icon}}">
        <a>{{item.title}}</a>
        <mat-nav-list class="submenu sidebr-sidemenu" [class.d-block]="item.isShow">
            <div class="d-flex justify-content-between align-items-center heading-close">
                <h3 class="mb-0">{{item.title}}</h3>
                <button mat-icon-button (click)="closeBar(item)">
                    <mat-icon>clear</mat-icon>
                </button>
            </div>

            <div class="sidebar-children">
                <div class="insbox" *ngFor="let child of item.children">
                    <ng-container *ngIf="child.type">
                        <h4>{{child.title}}</h4>
                        <ng-container *ngFor="let inchild of child.children">
                            <mat-list-item (click)="closeBar(item)" *ngIf="inchild.type">
                                <ng-container>
                                    <a (click)="chooseRoute(inchild, item)">{{inchild.title}}</a>
                                </ng-container>
                            </mat-list-item>
                        </ng-container>
                    </ng-container>
                </div>
            </div>

            <ng-container *ngFor="let sub of item.Pages">
            <mat-list-item *ngIf="sub.type" (click)="closeBar(item)">
                    <a (click)="chooseRoute(sub, item)">{{sub.title}}</a>
                </mat-list-item>
            </ng-container>
        </mat-nav-list>
    </mat-list-item>
</mat-nav-list>
<!-- Overlay Mobile -->
<div class="overlaym-all"></div>