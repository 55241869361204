import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatatableService } from '@planwork/datatable-settings';
import { DataTableDirective } from 'angular-datatables';
import { LeadService } from 'app/main/lead/lead.service';
import { planworkConfig } from 'app/planwork.config';
import { DataStorage } from 'app/store/storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { TableColumn } from './Table.Column';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  Result: any[];
}
@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {
  RoleId: any;
  MId: any;
  businessHead: any = {}
  dropList: any;
  sortItem = 1;
  dtOptions: DataTables.Settings = {};
  tableSetting: any = {};
  search = new Object();
  searchText: any;
  CompanyData: any;
  tabledata: any = {};
  tblClm: any[] = [];
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  isShow = false;
  showTable = true;
  buttonViews = []
  datatableFilter = []
  dialogRef: any;
  constructor(
    private http: HttpClient,
    private datatableServise: DatatableService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) {
    this.tableSetting = this.datatableServise.tablePagination();
    this.tblClm = TableColumn;
  }

  ngOnInit(): void {
    this.spinner.show();
    const that = this;
    this.dtOptions = {
      language: { searchPlaceholder: 'Search by keyword' },
      ...this.tableSetting.pagination,
      ajax: (dataTablesParameters: any, callback) => {
        that.http.post<DataTablesResponse>(
          planworkConfig.baseUrl + 'License/api/Company' + '/CompanyList',
          { ...dataTablesParameters, ...this.search }, { headers: this.tableSetting.dataTables }
        ).subscribe(resp => {
          this.spinner.hide();
          this.searchText = dataTablesParameters.search.value;
          this.tabledata = dataTablesParameters;
          that.CompanyData = resp.Result;
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });

        });
      },
      columns: this.tblClm,
      ...this.tableSetting.domSetting
    };
  }
  toggleSearch(text) {
    if (text == 'show') {
      this.isShow = true;
    } else {
      this.isShow = false;
      this.spinner.show();
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.search('');
        dtInstance.draw();
      });
    }
  }
}
