import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { planworkConfig } from 'app/planwork.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  config: any;
  SpinnerTimeout = 500;
  ClientIP: any;
  IPAddress: any;
  apiUrl: any;
  baseUrlPms: any;
  baseUrlglobal: any;
  baseUrlCompany: any;
  apiUrlCampaign: any;
  private baseUrl: any;
  private baseUrlConfigration: any;
  private baseUrlEmployee: any;
  baseUrlEmpCam: any;
  baseSupportUrl: string;
  SupportRequest: string;
  constructor(
    private http: HttpClient,
  ) {
    // this.http.get<any>('https://jsonip.com')
    //   .subscribe(data => {
    //     const serverIp = data.ip;
    //     const privateIP = this.ClientIP;
    //     console.log(privateIP);
    //     const IP = serverIp + ':' + privateIP;
    //     this.IPAddress = IP;
    //   });
    this.apiUrl = planworkConfig;
    this.baseUrl = this.apiUrl.baseUrl + this.apiUrl.CampaignBaseUrl + 'campaigns/';
    this.baseSupportUrl = this.apiUrl.baseUrl + this.apiUrl.SupportBaseUrl + 'Master/';
    this.SupportRequest = this.apiUrl.baseUrl + this.apiUrl.SupportBaseUrl + 'SupportRequest/';
    this.baseUrlglobal = this.apiUrl.baseUrl + this.apiUrl.GlobalBaseUrl + 'campaign/';
    this.baseUrlCompany = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + 'campaign/';
    // this.baseUrl= 'http://localhost:57857/Campaign/api/campaigns/'
    this.baseUrlConfigration = this.apiUrl.baseUrl + this.apiUrl.CampaignBaseUrl + 'Configuration/';
    this.baseUrlEmpCam = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'Campaign/';
    this.baseUrlEmpCam = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'Campaign/';
    this.baseUrlEmployee = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'Employee/';
    this.baseUrlPms = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'PMS/';

  }
  postEmployee(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrlEmployee + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  postPms(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrlPms + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  postService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    console.log(currentToken)
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  SupportService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    console.log(currentToken)
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.baseSupportUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  SupportMember(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    console.log(currentToken)
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.SupportRequest + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  globalpostService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    console.log(currentToken)
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrlglobal + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  companypostService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    console.log(currentToken)
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrlCompany + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  // sourcedata api
  postServiceSource(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    console.log(currentToken)
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrlConfigration + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  postServiceEmpCam(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    console.log(currentToken)
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrlEmpCam + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  postServiceDirect(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    console.log(currentToken)
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.apiUrl.baseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
}

