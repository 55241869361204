<div class="content eseprofile">
    <!-- Heading -->
    <div class="page-heading d-flex align-items-center">
        <div fxFlex fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
                <h1>Profile</h1>
            </div>
        </div>
    </div>

    <div class="content_body">

        <div class="editpic text-center mb-3 pb-3">
            <figure class="position-relative mx-auto mb-2">
                <img class="rounded-circle"
                    src="https://sdm-vp.s3.ap-south-1.amazonaws.com/DMS/Documents/21278/1_21278_pk.jpg" alt="">
                <div
                    class="upload position-absolute rounded-circle d-flex align-items-center justify-content-center text-white">
                    <mat-icon>edit</mat-icon>
                    <input type="file" class="position-absolute">
                </div>
            </figure>
            <h2 class="mb-0"><strong>Piyush Khatarkar</strong></h2>
            <p>EMP03/22/02385</p>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="card profile-card">
                    <div class="card-body">
                        <h3 class="card-title"><img src="assets/images/skill-set.png" alt=""> Skill Set</h3>
                        <mat-chip-list>
                            <mat-chip>HTML <button matChipRemove>
                                    <mat-icon>close</mat-icon>
                                </button></mat-chip>
                            <mat-chip>Java Script <button matChipRemove>
                                    <mat-icon>close</mat-icon>
                                </button></mat-chip>
                            <mat-chip>Angular <button matChipRemove>
                                    <mat-icon>close</mat-icon>
                                </button></mat-chip>
                            <mat-chip class="addskill">+ Add Skill</mat-chip>
                        </mat-chip-list>
                    </div>
                </div>
                <div class="card profile-card">
                    <div class="card-body">
                        <h3 class="card-title"><img src="assets/images/basic-info.png" alt=""> Basic Information</h3>
                        <div class="row">
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Name</label>
                                <p class="mb-0">Mahesh Mehta </p>
                            </div>
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Email Address</label>
                                <p class="mb-0">xyz@gmail.com </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card profile-card">
                    <div class="card-body">
                        <h3 class="card-title"><img src="assets/images/personal-info.png" alt=""> Personal Information</h3>
                        <div class="row">
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Date of Birth</label>
                                <p class="mb-0">12 July 1995 </p>
                            </div>
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Gender</label>
                                <p class="mb-0">Male </p>
                            </div>
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Marital Status</label>
                                <p class="mb-0">Single </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card profile-card">
                    <div class="card-body">
                        <h3 class="card-title"><img src="assets/images/professional-ref.png" alt=""> Professional References</h3>
                        <div class="row">
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Name</label>
                                <p class="mb-0">Parul Jain</p>
                            </div>
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Designation</label>
                                <p class="mb-0">Angular Developer</p>
                            </div>
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Relationship</label>
                                <p class="mb-0">Collegue</p>
                            </div>
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Organization</label>
                                <p class="mb-0">Aamod IT solution</p>
                            </div>
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Occupation/Profession</label>
                                <p class="mb-0">IT Professional</p>
                            </div>
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Email Id</label>
                                <p class="mb-0">parulj1992@gmail.com</p>
                            </div>
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Mobile No</label>
                                <p class="mb-0">+91 7737089862</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card profile-card">
                    <div class="card-body">
                        <h3 class="card-title"><img src="assets/images/work-info.png" alt=""> Work Information</h3>
                        <div class="row">
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Department</label>
                                <p class="mb-0">Angular </p>
                            </div>
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Location</label>
                                <p class="mb-0">Jaipur </p>
                            </div>
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Designation</label>
                                <p class="mb-0">Web developer </p>
                            </div>
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Employment Type</label>
                                <p class="mb-0">Permanent </p>
                            </div>
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Date of Joining</label>
                                <p class="mb-0">15 July 2023 </p>
                            </div>
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Reporting Manager</label>
                                <p class="mb-0">Prashant Pareek </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card profile-card">
                    <div class="card-body">
                        <h3 class="card-title"><img src="assets/images/contact-info.png" alt=""> Contact Details</h3>
                        <div class="row">
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Phone Number</label>
                                <p class="mb-0">7549604525 </p>
                            </div>
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Present Address</label>
                                <p class="mb-0">B-19, 10-B Scheme, Gopalpura Road, Jaipur</p>
                            </div>
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Personal Mobile Number</label>
                                <p class="mb-0">7549604525</p>
                            </div>
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Personal Email Address</label>
                                <p class="mb-0">xyz@gmail.com</p>
                            </div>
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Ask me about/Expertise</label>
                                <p class="mb-0">NA</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card profile-card">
                    <div class="card-body">
                        <h3 class="card-title"><img src="assets/images/identity.png" alt=""> Identity Information</h3>
                        <div class="row">
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">UAN</label>
                                <p class="mb-0">5465465876 </p>
                            </div>
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">PAN</label>
                                <p class="mb-0">PAN545849847 </p>
                            </div>
                            <div class="col-md-6 dtl_text mb-3">
                                <label class="mb-0">Aadhar</label>
                                <p class="mb-0">68767545454 </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card profile-card">
                    <div class="card-body">
                        <h3 class="card-title"><img src="assets/images/pay-slip.png" alt=""> Pay Slip</h3>
                        <div class="taskdetail-dv">
                            <span
                                style="color: #2075E9; font-size: 13px; width: 25%; cursor: pointer; display: flex; align-items: center;"
                                (click)="downloadslip()">
                                <mat-icon class="back_arrow"
                                    style="height: 20px;  width: 20px; font-size: 18px; line-height: 1.5;">download</mat-icon>Download
                                Pay Slip </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>