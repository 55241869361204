import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PlanworkConfigService } from '@planwork/services/config.service';
import { DropdownService } from '@planwork/services/dropdown.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-add-attributes',
  templateUrl: './add-attributes.component.html',
  styleUrls: ['./add-attributes.component.scss']
})
export class AddAttributesComponent implements OnInit {

  @Input() attributeIdList;
  @Input() attributeKeyList;
  @Input() attributeClassId;
  @Input() attributeCategorieId;
  @Input() attributeItemId;
  @Input() attributeClassName;
  @Input() attributeCategorieName;
  @Input() attributeItemName;
  @Output() saveAtt = new EventEmitter();
  searchText = '';
  checkedList: any[] = [];

  attributesList: any[] = [];
  classList: any[] = [];
  categoryList: any[] = [];
  itemList: any[] = [];

  constructor(
    private dropdownService: DropdownService,
    private spinner: NgxSpinnerService,
    private modalService: PlanworkConfigService
  ) { }

  ngOnInit(): void {
    this.getAttributes();
  }

  getAttributes() {
    this.spinner.show();
    let payload: any = {};
    if (this.attributeKeyList == 'assetClass') {
      payload.AssetClassId = this.attributeClassId;
    } else if (this.attributeKeyList == 'assetCategory') {
      payload.AssetClassId = this.attributeClassId;
      payload.AssetCategoryId = this.attributeCategorieId;
    } else if (this.attributeKeyList == 'assetItem') {
      payload.AssetClassId = this.attributeClassId;
      payload.AssetCategoryId = this.attributeCategorieId;
      payload.AssetItemId = this.attributeItemId;
    }
    this.dropdownService.postService(payload, 'Asset/api/Asset/AttributeGetByIdForAsset').subscribe(res => {
      this.spinner.hide();
      console.log(res);
      this.attributesList = res?.body?.Result?.AssetAttribute;
      this.classList = res?.body?.Result?.ClassAttribute;
      this.categoryList = res?.body?.Result?.CategoryAttribute;
      this.itemList = res?.body?.Result?.ItemAttribute;
      if (this.attributeKeyList == 'assetClass') {
        this.checkClass(this.classList ? this.classList : []);
      } else if (this.attributeKeyList == 'assetCategory') {
        this.checkClass(this.classList ? this.classList : []);
        this.checkCategory(this.categoryList ? this.categoryList : []);
      } else if (this.attributeKeyList == 'assetItem') {
        this.checkClass(this.classList ? this.classList : []);
        this.checkCategory(this.categoryList ? this.categoryList : []);
        this.checkItem(this.itemList ? this.itemList : []);
      }
    })
  }
  checkClass(arr) {
    arr.map(item => item.isSelected = true)
  }
  checkCategory(arr) {
    arr.map(item => item.isSelected = true)
  }
  checkItem(arr) {
    arr.map(item => item.isSelected = true)
  }
  // isAllSelected(array, list) {
  //   // console.log(array);
  //   // console.log(list);
  //   // // this.checkedList = [];

  //   // const index = array.findIndex(x => x.AssetAttributeId == list.AssetAttributeId);
  //   // console.log(index);
  //   // // debugger

  //   // // for (var i = 0; i < array.length; i++) {
  //   //   if (array[index].isSelected) {
  //   //     // this.checkedList.push(array[index].AssetAttributeId);
  //   //   } else {
  //   //     array[index].isSelected = false
  //   //   }
  //   // // }
  //   // console.log(this.checkedList);

  // }
  isDefultSelect(arr) {
    this.checkedList = [];
    let data = [];
    let data1 = [];
    if (arr?.length > 0) {
      data = arr.filter(x => x.isSelected == true);
    }
    if (this.attributesList?.length > 0) {
      data1 = this.attributesList.filter(x => x.isSelected == true);
    }
    const dataFinal = [...data, ...data1];
    console.log(dataFinal);

    if (dataFinal?.length > 0) {
      for (let list of dataFinal) {
        this.checkedList.push(list?.AssetAttributeId);
      }
    }

    console.log(this.checkedList);

  }
  attributeSave() {
    console.log(this.checkedList);
    console.log(this.attributeIdList);
    let payload: any = {};
    if (this.attributeKeyList == 'assetClass') {
      payload.AssetClassId = this.attributeClassId;
      this.isDefultSelect(this.classList);
    } else if (this.attributeKeyList == 'assetCategory') {
      payload.AssetClassId = this.attributeClassId;
      payload.AssetCategoryId = this.attributeCategorieId;
      this.isDefultSelect(this.categoryList);
    } else if (this.attributeKeyList == 'assetItem') {
      payload.AssetClassId = this.attributeClassId;
      payload.AssetCategoryId = this.attributeCategorieId;
      payload.AssetItemId = this.attributeItemId;
      this.isDefultSelect(this.itemList);
    }
    payload.AssetAttributeIds = this.checkedList;
    this.spinner.show();
    this.dropdownService.postService(payload, 'Asset/api/Asset/AttributeSaveForAsset').subscribe(res => {
      console.log(res);

      this.spinner.hide();
      if (res?.body?.Success) {
        this.modalService.resultPopup('success', res?.body?.Message);
        this.saveAtt.emit('true');
      } else {
        this.modalService.resultPopup('Alert', res?.body?.Message);
      }
    })
  }
}
