import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'singlefilter'
})
export class SinglefilterPipe implements PipeTransform {

  transform(items: any[], value: string, label: string): any[] {
    if (!value) {
      return items;
    }
    if (value == '' || value == null) return items;
     {
      return items.filter(e => e[label].toLowerCase().indexOf(value.toLowerCase()) > -1);
    }
  }


}
