<div class="content">
    <div class="page-heading">
        <div fxFlex fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
                <h1>Company</h1>
            </div>
            <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
                <button mat-flat-button color="accent" class="add-lead-btn" routerLink="/company-list/register-company">
                    <mat-icon>add</mat-icon> Add New Company
                </button>
            </div>
        </div>
    </div>

    <div class="content_body">
        <div class="filter_area pb-20">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="mini_btn" fxFlex="0 1 auto">
                    <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <div class="search-outer position-relative">
                            <button mat-mini-fab class="search_btn" (click)="toggleSearch('show')" *ngIf="!isShow">
                                <mat-icon>search</mat-icon>
                            </button>
                            <button mat-mini-fab class="search_btn" (click)="toggleSearch('hide')" *ngIf="isShow">
                                <mat-icon>clear</mat-icon>
                            </button>
                            <div class="search_form">
                                <input type="text" matInput placeholder="Search by keyword">
                            </div>
                        </div>
                        <button mat-mini-fab color="accent" class="gray_btn">
                            <img src="assets/icons/table.svg">
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-white table-responsive list_view server_side_page">
            <ng-container *ngIf="showTable">
                <table id="table_lead" datatable [dtOptions]="dtOptions"
                    class="row-border hover list_table table table-bordered table-hover table-compact">
                    <thead>
                        <tr>
                            <th *ngFor="let item of tblClm" (click)="sortItem = item.position">
                                <div>
                                    <span>{{item.Title}}</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody [attr.data_sort]="'sort_' + sortItem">
                        <tr #tr *ngFor=" let element of CompanyData; let i = index"
                            [class.font-weight-bold]="!element.IsView">
                            <td class="text-center ind_0">{{i+1+tabledata.start}}</td>
                            <td class="ind_1">
                                {{element.CompanyName}}
                            </td>
                            <td class="ind_2">
                                {{element.CompanyCode}}
                            </td>
                            <td class="ind_3">
                                {{element.CompanyType.CompanyTypeName}}
                            </td>
                            <td class="ind_3">
                                {{element.CreatedBy.CreatedByName}}
                            </td>
                            <td class="ind_4">
                                <div class="action_icons">
                                    <div class="icon" matTooltip="View Company"
                                        routerLink="/company-list/register-company"
                                        [queryParams]="{CompanyId:element.CompanyId}">
                                        <mat-icon>visibility</mat-icon>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="CompanyData?.length == 0">
                            <td align="center" colspan="6">No matching records found</td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
        </div>
    </div>
</div>