export const TableColumn1 = [
    { data: '', Title: '#', width: 20, position: 1 },
    { data: 'CreatedDate', Title: 'Requested by', position: 2 },
    { data: 'CreatedDate', Title: 'Requested On', width: 120, position: 2 },
    { data: 'TypeId', Title: 'Type', width: 100, position: 3 },
    { data: 'FromDate', Title: 'Duration', position: 4 },
    { data: 'BillAmount', Title: 'Amount', width: 120, position: 5 },
    { data: '', Title: 'Status', position: 8 },
    { data: '', Title: 'Action', position: 9 }
];
export const TableColumn2 = [
    { data: '', Title: '#', width: 20, position: 1 },
    { data: 'CreatedDate', Title: 'Requested by', position: 2 },
    { data: 'CreatedDate', Title: 'Requested On', width: 120, position: 2 },
    { data: 'TravelDate', Title: 'Travel Date', width: 100, position: 4 },
    { data: 'VisitFrom', Title: 'Visit From - To', position: 5, width: 120 },
    { data: 'BillAmount', Title: 'Amount', width: 100, position: 7 },
    { data: '', Title: 'Status', position: 9 },
    { data: '', Title: 'Action', position: 10 }
];

export const TableColumn3 = [
    { data: '', Title: '#', width: 20, position: 1 },
    { data: 'CreatedDate', Title: 'Requested by', width: 120, position: 2 },
    { data: 'CreatedDate', Title: 'Requested On', width: 120, position: 2},
    { data: 'TypeId', Title: 'Type', width: 100, position: 3 },
    { data: 'FromDate', Title: 'Duration', position: 4},
    { data: 'BillAmount', Title: 'Amount', width: 120, position: 6},
    { data: 'IsApproved', Title: 'Status', position: 9 },
    { data: '', Title: 'Action', position: 10 }
];

export const TableColumn4 = [    
    { data: '', Title: '#', width: 20, position: 1 },
    { data: 'CreatedDate', Title: 'Requested by', width: 200, position: 2 },
    { data: 'CreatedDate', Title: 'Requested On', width: 200, position: 2 },
    { data: 'FromDate', Title: 'Date', width: 200, position: 3 },
    { data: 'BillAmount', Title: 'Amount', width: 200, position: 4 },
    { data: 'BillDocumentId', Title: 'Bill', width: 200, position: 6 },
    { data: 'IsApproved', Title: 'Status', position: 7 },
    { data: '', Title: 'Action', position: 7 },
];

export const TableColumn5 = [
    { data: '', Title: '#', width: 20, position: 1 },
    { data: 'CreatedDate', Title: 'Requested by', width: 200, position: 2 },
    { data: 'CreatedDate', Title: 'Requested On', width: 200, position: 2 },
    { data: 'FromDate', Title: 'Date', width: 200, position: 3 },
    { data: 'Title', Title: 'Title', width: 200, position: 4 },
    { data: 'BillAmount', Title: 'Amount', width: 200, position: 5 },
    { data: 'IsApproved', Title: 'Status', position: 9 },
    { data: '', Title: 'Action', position: 9 },
];
