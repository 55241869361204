import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { planworkConfig } from 'app/planwork.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { CommonModalComponent } from '../core/common/common-modal/common-modal.component';

@Injectable({
  providedIn: 'root'
})
export class LeadService {
  config: any;
  SpinnerTimeout = 500;
  ClientIP: any;
  IPAddress: any;
  apiUrl: any;
  apiUrlCampaign: any;
  private baseUrl: any;
  private WorkFlowUrl: any;
  private baseLeadUrl: any;
  private baseUrlConfigration: any;
  private ProjectManager: any;
  private businessHead: any;
  private baseSalesManager: any;
  private dropLeadBH: any;
  private CommonServicebaseUrl: any;
  private leadCommon: any;
  private coreBaseUrl: any;
  private globleCore: any;
  private globleCorelead: any;
  private EmployeeCore : any;
  private CampaignCoreurl : any;
  dialogRef: any;
  exceptionText = 'There are some exception error, Please contact to support team.';
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) {
    // this.http.get<any>('https://jsonip.com').subscribe(data => {
    //   const serverIp = data.ip;
    //   const privateIP = this.ClientIP;
    //   const IP = serverIp + ':' + privateIP;
    //   this.IPAddress = IP;
    // });
    this.apiUrl = planworkConfig;
    /* this.baseUrl = this.apiUrl.baseUrl + 'Campaign/api/campaigns/';
    this.baseUrlConfigration = this.apiUrl.baseUrl + 'Campaign/api/Configuration/'; */
    this.WorkFlowUrl = this.apiUrl.baseUrl + this.apiUrl.LeadBaseUrl + 'WorkFlow/'
    this.baseUrl = this.apiUrl.baseUrl + this.apiUrl.LeadBaseUrl + 'CampaignEnquiry/';
    this.baseLeadUrl = this.apiUrl.baseUrl + this.apiUrl.LeadBaseUrl + 'SalesExecutive/';
    this.baseSalesManager = this.apiUrl.baseUrl + this.apiUrl.LeadBaseUrl + 'SalesManager/';
    this.businessHead = this.apiUrl.baseUrl + this.apiUrl.LeadBaseUrl + 'BusinessHead/';
    this.CommonServicebaseUrl = this.apiUrl.baseUrl + this.apiUrl.ServiceBaseUrl;
    this.dropLeadBH = this.apiUrl.baseUrl + this.apiUrl.LeadBaseUrl + 'DropLeadBH/';
    this.baseUrlConfigration = this.apiUrl.baseUrl + this.apiUrl.LeadBaseUrl + 'Configuration/';
    this.ProjectManager = this.apiUrl.baseUrl + this.apiUrl.LeadBaseUrl + 'ProjectManager/';
    this.leadCommon = this.apiUrl.baseUrl + this.apiUrl.LeadBaseUrl;
    this.coreBaseUrl = this.apiUrl.baseUrl + this.apiUrl.coreBaseUrl;
    this.globleCore = this.apiUrl.baseUrl + this.apiUrl.GlobalBaseUrl + 'Configuration/'; 
    this.globleCorelead = this.apiUrl.baseUrl + this.apiUrl.GlobalBaseUrl + 'Lead/'; 
    this.EmployeeCore = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'Lead/'; 
    this.CampaignCoreurl = this.apiUrl.baseUrl + this.apiUrl.CampaignBaseUrl + 'Lead/'; 
  }
  
  CommonService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.CommonServicebaseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  postCompany(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.apiUrl.baseUrl + currentUrl, saveData, {
      headers: httpHeaders,
      observe: 'response'
    }).pipe(
      catchError(err => {
      this.spinner.hide();
      this.dialogRef = this.dialog.open(CommonModalComponent, {
        width: '300px',
        disableClose: true,
        autoFocus: false,
        panelClass: 'statusClass',
        data: { action: 'delete', statusText: this.exceptionText }
      });
      return throwError(err);
    })
    );
  }
  postService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrl + currentUrl, saveData, {
      headers: httpHeaders,
      observe: 'response'
    }).pipe(
      catchError(err => {
      this.spinner.hide();
      this.dialogRef = this.dialog.open(CommonModalComponent, {
        width: '300px',
        disableClose: true,
        autoFocus: false,
        panelClass: 'statusClass',
        data: { action: 'delete', statusText: this.exceptionText }
      });
      return throwError(err);
    })
    );
  }
  CampaignCore(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.EmployeeCore + currentUrl, saveData, {
      headers: httpHeaders,
      observe: 'response'
    }).pipe(
      catchError(err => {
      this.spinner.hide();
      this.dialogRef = this.dialog.open(CommonModalComponent, {
        width: '300px',
        disableClose: true,
        autoFocus: false,
        panelClass: 'statusClass',
        data: { action: 'delete', statusText: this.exceptionText }
      });
      return throwError(err);
    })
    );
  }
  CampaignService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.CampaignCoreurl + currentUrl, saveData, {
      headers: httpHeaders,
      observe: 'response'
    }).pipe(
      catchError(err => {
      this.spinner.hide();
      this.dialogRef = this.dialog.open(CommonModalComponent, {
        width: '300px',
        disableClose: true,
        autoFocus: false,
        panelClass: 'statusClass',
        data: { action: 'delete', statusText: this.exceptionText }
      });
      return throwError(err);
    })
    );
  }

  projectmanager(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.ProjectManager + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
        catchError(err => {
          this.spinner.hide();
          this.dialogRef = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'delete', statusText: this.exceptionText }
          });
          return throwError(err);
        })
      )
  }

  fullPostApi(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.apiUrl.baseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
        catchError(err => {
          this.spinner.hide();
          this.dialogRef = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'delete', statusText: this.exceptionText }
          });
          return throwError(err);
        })
      )
  }

  workFlowService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.WorkFlowUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  // sourcedata api
  postServiceSource(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrlConfigration + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  globlePost(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.globleCore + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  
  /* lead Sales Service */
  leadSalesService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.baseLeadUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  /*   lead Sales Service */
  leadSalesManager(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.baseSalesManager + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  businessHeadService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.businessHead + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  businessHeadServicelead(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.globleCorelead + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }


  dropLeadForBH(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.dropLeadBH + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  commonLeadService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.leadCommon + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  coreService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentToken
    });
    return this.http.post(this.coreBaseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
}
